import { Fragment } from "react";
import "./recorded_live_stream_video_view.scss";

import images from "../../../../../../utilities/images/images";

const RecordedLiveStreamVideoView = (props) => {
  let { selectedVideoId, userId, handleBack } = props;

  const handleOnLoad = () => {
    let frame = document.querySelector("#iframe");
    frame.contentWindow.postMessage(
      `{"activate": true,"userId":"${userId}"}`,
      "*"
    );
  };

  const renderFrame = (videoId) => {
    return (
      <iframe
        id="iframe"
        src={`https://speed.mogiio.com/embed3/${videoId}`}
        onLoad={handleOnLoad}
        allow="accelerometer; autoplay; encrypted-media;gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    );
  };

  return (
    <Fragment>
      <div className="recordedLiveStreamVideoView_wrapper">
        <div className="recordedLiveStreamVideoView_inner_wrapper">
          <div className="sec_1_wrapper">
            <div className="sec_1_inner_wrapper">
              <div className="btn_wrapper">
                <button onClick={() => handleBack()}>
                  <img src={images.user_activity_1} />
                </button>
              </div>
              <div className="text_content_wrapper">
                <p className="text_content">Video View</p>
              </div>
            </div>
          </div>
          <div className="video_frame_wrapper">
            {renderFrame(selectedVideoId)}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default RecordedLiveStreamVideoView;
