import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import {
  renderButton,
  REFER_AND_EARN_MODALS,
  validateBankFields,
  setDetailsInLocalStorage,
} from "../../utils";
import { saveUpiBankDetails } from "../Api";
import "./BankDetails.scss";

const BankDetails = ({
  show,
  onCloseModal,
  setModalType,
  token,
  tokenType,
}) => {
  const [bankDetails, setBankDetails] = useState({
    accountHolderName: "",
    ifscCode: "",
    accountNumber: "",
    confirmAccountNumber: "",
  });

  const [validationMessages, setValidationMessages] = useState({
    accountHolderName: "",
    ifscCode: "",
    accountNumber: "",
    confirmAccountNumber: "",
  });

  useEffect(() => {
    const storedBankDetails = localStorage.getItem("bankDetails");
    if (storedBankDetails) {
      setBankDetails(JSON.parse(storedBankDetails));
    }
  }, []);

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    let updatedValue = value;

    switch (id) {
      case "accountHolderName":
        updatedValue = value.replace(/[^a-zA-Z\s]/g, ""); // Only allow letters and spaces
        break;
      case "ifscCode":
        updatedValue = value.replace(/[^a-zA-Z0-9]/g, ""); // Only allow alphanumeric characters
        break;
      case "accountNumber":
      case "confirmAccountNumber":
        updatedValue = value.replace(/[^0-9]/g, ""); // Only allow digits
        break;
      default:
        break;
    }

    setBankDetails({ ...bankDetails, [id]: updatedValue });
  };

  const handleSubmit = () => {
    if (validateBankFields(bankDetails, setValidationMessages)) {
      const data = {
        type: 2,
        acc_name: bankDetails.accountHolderName,
        ifsc_code: bankDetails.ifscCode,
        acc_no: bankDetails.accountNumber,
        cacc_no: bankDetails.confirmAccountNumber,
        upi_id: "",
      };

      localStorage.setItem("bankDetails", JSON.stringify(bankDetails));
      setModalType(REFER_AND_EARN_MODALS.VERIFY_DETAILS);
      setDetailsInLocalStorage("TYPE", JSON.stringify("BANK"));
      saveUpiBankDetails(data, token, tokenType);
    } 
  };

  return (
    <Modal
      show={show}
      onHide={onCloseModal}
      dialogClassName="bank_details_wrapper"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <div className="bank_details_container">
          <h3>Enter Bank Account Details</h3>
          <div className="inputs_wrapper">
            <div className="input_container">
              <label htmlFor="accountHolderName">
                Account Holder Name<span>*</span>
              </label>
              <input
                type="text"
                id="accountHolderName"
                value={bankDetails.accountHolderName}
                onChange={handleInputChange}
                placeholder="Full Name"
              />
              {validationMessages.accountHolderName && (
                <div className="error_message">
                  {validationMessages.accountHolderName}
                </div>
              )}
            </div>
            <div className="input_container">
              <label htmlFor="ifscCode">
                IFSC Code<span>*</span>
              </label>
              <input
                type="text"
                id="ifscCode"
                value={bankDetails.ifscCode}
                onChange={handleInputChange}
                placeholder="ABCD0123456"
              />
              {validationMessages.ifscCode && (
                <div className="error_message">
                  {validationMessages.ifscCode}
                </div>
              )}
            </div>
            <div className="input_container">
              <label htmlFor="accountNumber">
                Account Number<span>*</span>
              </label>
              <input
                type="text"
                id="accountNumber"
                value={bankDetails.accountNumber}
                onChange={handleInputChange}
                placeholder="0000000000"
              />
              {validationMessages.accountNumber && (
                <div className="error_message">
                  {validationMessages.accountNumber}
                </div>
              )}
            </div>
            <div className="input_container">
              <label htmlFor="confirmAccountNumber">
                Confirm Account Number<span>*</span>
              </label>
              <input
                type="text"
                id="confirmAccountNumber"
                value={bankDetails.confirmAccountNumber}
                onChange={handleInputChange}
                placeholder="0000000000"
              />
              {validationMessages.confirmAccountNumber && (
                <div className="error_message">
                  {validationMessages.confirmAccountNumber}
                </div>
              )}
            </div>
          </div>

          <div className="text">
            Don’t have Bank Details?{" "}
            <span
              onClick={() => setModalType(REFER_AND_EARN_MODALS.UPI_DETAILS)}
            >
              Add UPI ID
            </span>
          </div>

          <div className="save_btnn" onClick={handleSubmit}>
            {renderButton("Save & Continue")}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default BankDetails;
