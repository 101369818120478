import { Fragment, useEffect, useState } from "react";
import "./user_dashboard_content_component.scss";

import DashboardSlider from "./dashboard_utlility_components/dashboard_slider/dashboard_slider";
import GraphStatistics from "./dashboard_utlility_components/graph_statistics/graph_statistics";
import SubjectCollectionView from "./dashboard_utlility_components/subject_collection_view/subject_collection_view";
import LastPausedVideo from "./dashboard_utlility_components/last_paused_video/last_paused_video";
import FreeVideos from "./dashboard_utlility_components/free_videos/free_videos";
import ScheduleClass from "./dashboard_utlility_components/schedule_class/schedule_class";
import NewOfferingsSlider from "./dashboard_utlility_components/dashboard_slider/new_offerings_slider";
import AitsBanner from "./dashboard_utlility_components/aits_banner/aits_banner";

import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import * as rdd from "react-device-detect";
import CustomModal from "../../../../components/Modal/CustomModal";
import MultipleLoginDetectModal from "../../../multiple_login_detected_popup/MultipleLoginDetectModal";
import {
  url_8,
  url_172,
  url_173,
  url_54,
  url_211,
} from "../../../../custom_utilities/api_services";
import images from "../../../../utilities/images/images";
import { Link } from "react-router-dom";
import Our_course_package from "./dashboard_utlility_components/our_course_package/Our_course_package";
import UserRegistrationModal from "../../../homepage/UserRegistrationModal/UserRegistrationModal";
import { toastifyMessage } from "../../../../custom_utilities/toastify_config";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FreeWatchVideosModal from "../../../../components/FreeWatchVideosModal/FreeWatchVideosModal";
import TenHoursFreeVideosBanner from "./dashboard_utlility_components/ten_hours_free_videos_banner/TenHoursFreeVideosBanner";

const UserDashboardContentComponent = (props) => {
  let {
    history,
    startLoading,
    finishLoading,
    setBlockedStatusAction,
    setMultipleLoginStatusAction,
  } = props;
  const [state, setState] = useState({
    feedbackModal: false,
    successModal: false,
    showUserRegistrationModal: 0,
    is_complete_profile: 1,
    isActive: 1,
    roll_no: "",
    mobile_no: "",
    error: false,
    responseData: {},
    is_Amrit: false,
    aits_pdf: "",
    amrit_pdf: "",
    feedbackData: {
      teaching_method: "0",
      punctuality: "0",
      behaviour: "0",
      theory_quality: "0",
      discussion_quality: "0",
      overall: "0",
    },
    isPaid: null,
    remainingUserTimeToWatchVideo: null,
    isPaid1: null,
    // ifFreeUserToWatchVideos: null,
  });

  // const id = uuidv4();
  // console.log("id uuid", id);

  const [isMultipleLogin, setIsMultipleLogin] = useState(false);

  useEffect(() => {
    set_record();
    getFeedBackPopup();

    getUserProfile();
  }, []);

  // const [deviceId, setDeviceId] = useState("");

  console.log("isMultipleLogin", isMultipleLogin);

  useEffect(() => {
    // let id = window.localStorage.getItem("device_id");
    // setDeviceId(id);
    // console.log("getId from localStorage", id);
    // if (!id) {
    //   id = uuidv4();
    //   window.localStorage.setItem("device_id", id);
    // }
    // setDeviceId(id);
  }, []);

  const getUserProfile = async () => {
    // startLoading();

    let id = window.localStorage.getItem("device_id");
    // setDeviceId(id);

    console.log("getId from localStorage", id);
    if (!id) {
      id = uuidv4();
      window.localStorage.setItem("device_id", id);
      console.log("going inside IF and setting id", id);
    }
    // setDeviceId(id);

    // try {
    // let id = localStorage.getItem("device_id");

    const response = await axios.get(`${url_8}?device_id=${id}`);

    console.log("response of response", response.data.status);

    console.log("i am in try block");

    if (response.data.status === 200) {
      console.log("going to if so popup false");
      setState((prevState) => ({
        ...prevState,
        is_Amrit: response.data.data.is_Amrit,
        aits_pdf: response.data.data.aits_pdf,
        amrit_pdf: response.data.data.amrit_pdf,
        showUserRegistrationModal: response.data.data.is_user_registration,
        is_complete_profile: response.data.data.is_form_submit,
        isActive: response.data.data.active,
        roll_no: response.data.data.roll_no,
        mobile_no: response.data.data.mobile,
        isPaid: response.data.data.is_paid,
        remainingUserTimeToWatchVideo: response.data.data.remaining_user_time,
        isPaid1: response.data.data.is_paid,
      }));

      setBlockedStatusAction({
        isBlocked: response.data.data.ls_block,
        remark: response.data.data.remark,
      });

      // setMultipleLoginStatusAction({
      //   isMultipleLogin : false
      // })

      setIsMultipleLogin(false);

      // finishLoading();
      // return { result: 1, data: { apiData: response.data.data } };
    } else {
      // finishLoading();
      // return { result: 0, data: { apiData: {} } };
      console.log("going to else in try block ");
      setState((prevState) => ({
        ...prevState,
        showUserRegistrationModal: 0,
      }));
      setBlockedStatusAction({
        isBlocked: false,
        remark: null,
      });
      setIsMultipleLogin(true);
    }
    // }
  };

  const handleCloseUserRegistrationModal = () => {
    setState((prevState) => ({
      ...prevState,
      showUserRegistrationModal: 0,
    }));
  };

  const set_record = async () => {
    let data = {
      apk_version: "Web",
    };

    try {
      const response = await axios.post(url_54, data);
    } catch (error) {
      console.log(error);
    }
  };
  const handleClose = () => {
    setState((prev) => {
      return {
        ...prev,
        feedbackModal: false,
        successModal: false,
        error: false,
      };
    });
  };

  const handleCloseFreeWatchVideos = () => {
    setState((prev) => {
      return {
        ...prev,
        isPaid: 1,
      };
    });
  };

  const getFeedBackPopup = async () => {
    try {
      const response = await axios.post(url_172);
      console.log("length", Object.keys(response.data.data).length);
      if (Object.keys(response.data.data).length > 0) {
        if (response.data.data.feedback !== null) {
          const feedback = response.data.data.feedback;
          setState((prev) => {
            return {
              ...prev,
              responseData: feedback,
              feedbackModal: true,
              feedbackData: {
                ...prev.feedbackData,
                student_id: feedback.stud_rollno,
                batch_id: feedback.batch_id,
                batch_code: feedback.batch_code,
                topic_title: feedback.topic_title,
                topic_no_count: feedback.topic_no_count,
                session_id: feedback.session_id,
                center_id: feedback.center_id,
                faculty_id: feedback.faculty_id,
                st_feedback_show_id: feedback.id,
              },
            };
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const feedbackValidation =
    state.feedbackData.teaching_method === "0" ||
    state.feedbackData.punctuality === "0" ||
    state.feedbackData.behaviour === "0" ||
    state.feedbackData.theory_quality === "0" ||
    state.feedbackData.discussion_quality === "0" ||
    state.feedbackData.overall === "0";

  const FeedBackArr = [
    {
      title: "Teaching Method",
      name: "teaching_method",
    },
    {
      title: "Punctuality",
      name: "punctuality",
    },
    {
      title: "Behaviour",
      name: "behaviour",
    },
    {
      title: "Theory Quality",
      name: "theory_quality",
    },
    {
      title: "Discussion Quality",
      name: "discussion_quality",
    },
    {
      title: "Overall",
      name: "overall",
    },
  ];
  const feedBackInput = (e) => {
    setState((prevState) => {
      return {
        ...prevState,
        feedbackData: {
          ...prevState.feedbackData,
          [e.target.name]: e.target.value,
        },
      };
    });
  };
  const feedbackSubmit = async (e) => {
    e.preventDefault();
    console.log("feedbackData", state.feedbackData);
    try {
      const response = await axios.post(url_173, state.feedbackData);
      // console.log("response",response.status)
      if (response.status == 200) {
        console.log("response", response);
        setState((prev) => {
          return {
            ...prev,
            feedbackModal: false,
            successModal: true,
            error: false,
          };
        });
      }
    } catch (error) {
      console.log(error);
      setState((prev) => {
        return {
          ...prev,
          error: true,
        };
      });
    }
  };

  const handleCloseProfileform = () => {
    setState((prevState) => ({
      ...prevState,
      is_complete_profile: 1,
    }));
  };

  const handleCloseFeePendingModal = () => {
    setState((prevState) => {
      return {
        ...prevState,
        isActive: 1,
      };
    });
  };

  const handleWatchNow = () => {
    history.push("/subject_component");
    handleCloseFreeWatchVideos();
  };

  const sendSmsToStudent = async () => {
    startLoading();
    try {
      const data = {
        method_name: "send_link_api",
        mobile: state.mobile_no,
        roll_no: state.roll_no,
        // mobile: 8779703996,
        // roll_no: 213910702,
      };
      const response = await axios.post(url_211, data);
      if (response.data.response.status.toLowerCase() === "success") {
        finishLoading();
        handleCloseProfileform();
        toastifyMessage({
          status: 200,
          message: "You will get an SMS shortly",
        });
      } else {
        finishLoading();
        handleCloseProfileform();
        toastifyMessage({
          status: 201,
          message: response.data.response.message,
        });
      }
    } catch (error) {
      console.log(error);
      finishLoading();
      handleCloseProfileform();
      toastifyMessage({
        status: 201,
        message: "Error",
      });
    }
  };

  return (
    <Fragment>
      <CustomModal show={state.feedbackModal} onHide={handleClose}>
        <p className="text-muted text-center mb-0">
          Share your valuable Feedback for today's Topic
        </p>
        <p className="text-center">
          <b>{`${state.responseData.topic_title} By ${state.responseData.faculty_name} in Batch ${state.responseData.batch_code}`}</b>
        </p>
        <form onSubmit={feedbackSubmit}>
          {FeedBackArr.map((element, index) => {
            return (
              <div className="mb-2">
                <h6>{element.title}</h6>
                <div className="emojiContainer">
                  <div className="emojiLine"></div>
                  {/* <div className="emojiDiv">
                <input type="radio" className="emoji_Bad" name={`${element.title}${index}`} />
                <p className="emojitext emojiBad">Bad</p>
              </div> */}
                  <div className="emojiDiv">
                    <input
                      type="radio"
                      className="emoji_Ave"
                      value={"1"}
                      name={element.name}
                      onChange={feedBackInput}
                    />
                    <p className="emojitext emojiAve">Average</p>
                  </div>
                  <div className="emojiDiv">
                    <input
                      type="radio"
                      className="emoji_Good"
                      value={"2"}
                      name={element.name}
                      onChange={feedBackInput}
                    />
                    <p className="emojitext emojiGood">Good</p>
                  </div>
                  <div className="emojiDiv">
                    <input
                      type="radio"
                      className="emoji_Great"
                      value={"3"}
                      name={element.name}
                      onChange={feedBackInput}
                    />
                    <p className="emojitext emojiGreat">Great</p>
                  </div>
                </div>
              </div>
            );
          })}
          {state.error && (
            <p className="text-danger text-center mb-0">
              Something went wrong, try again !!
            </p>
          )}
          <button
            disabled={feedbackValidation ? true : false}
            className="feedbackSubmit"
            type="submit"
          >
            Submit Feedback
          </button>
        </form>
      </CustomModal>
      <CustomModal show={state.successModal} onHide={handleClose}>
        <div className="text-center">
          <h6 className="mb-3">Feedback submitted successfully.</h6>
          <button className="feedbackSuccess" onClick={handleClose}>
            Ok
          </button>
        </div>
      </CustomModal>

      <CustomModal
        show={!state.is_complete_profile}
        clsClass="btn-hide"
        dialogClassName="complete-profile-modal"
      >
        <img src={images.profile} className="complete-profile-image" />
        <div className="modal-heading">Complete Profile</div>
        <div className="profile-modal-data">
          To help us know you better, please complete your profile
        </div>
        <div className="profile-more-info">
          You will receive an SMS containing an one time link to fill out the
          form.
        </div>

        <div className="profile-buttons">
          <button className="ask-ltr-btn" onClick={handleCloseProfileform}>
            Ask Later
          </button>
          <button className="get-sms-btn" onClick={sendSmsToStudent}>
            Get SMS
          </button>
        </div>
      </CustomModal>

      <CustomModal
        show={!state.isActive && state.remark}
        clsClass="btn-hide"
        dialogClassName="fee-pending-modal"
      >
        <img src={images.warning} className="fee-pending-image" />
        <div className="fee-pending-heading">Fee Pending</div>
        <div className="fee-data">{state.remark}</div>
        <div className="fee-more-info">
          Please pay your fee to avoid restriction of access to the App
        </div>

        <div
          className="fee-pending-button"
          onClick={handleCloseFeePendingModal}
        >
          <button className="fee-pending-btn">OK</button>
        </div>
      </CustomModal>

      <CustomModal
        show={!state.isActive && state.remark}
        clsClass="btn-hide"
        dialogClassName="fee-pending-modal"
      >
        <img src={images.warning} className="fee-pending-image" />
        <div className="fee-pending-heading">Fee Pending</div>
        <div className="fee-data">{state.remark}</div>
        <div className="fee-more-info">
          Please pay your fee to avoid restriction of access to the App
        </div>

        <div
          className="fee-pending-button"
          onClick={handleCloseFeePendingModal}
        >
          <button className="fee-pending-btn">OK</button>
        </div>
      </CustomModal>

      {state.isPaid == 0 && state.remainingUserTimeToWatchVideo > 0 && (
        <FreeWatchVideosModal
          show={true}
          onHide={handleCloseFreeWatchVideos}
          handleWatchNow={handleWatchNow}
        />
      )}

      <MultipleLoginDetectModal isMultipleLogin={isMultipleLogin} />

      {/* {state.showUserRegistrationModal ? (
        <UserRegistrationModal
          showUserRegistrationModal={state.showUserRegistrationModal}
          handleCloseUserRegistrationModal={handleCloseUserRegistrationModal}
          startLoading={startLoading}
          finishLoading={finishLoading}
        />
      ) : null} */}

      <div className="userDashboardContentComponent_wrapper">
        <div className="userDashboardContentComponent_inner_wrapper">
          {state.isPaid1 == 0 && state.remainingUserTimeToWatchVideo > 0 && (
            <TenHoursFreeVideosBanner history={history} />
          )}
          <div className="slider_container">
            <div className="container">
              <DashboardSlider />
            </div>
          </div>
          {/* <div className="graphStatistics_container">
                <GraphStatistics />
              </div> */}
          <div className="subject_collection_container">
            <SubjectCollectionView history={history} />
          </div>
          <div className="our_course_target">
            <div className="container">
              <div className="btn_wrapper">
                <a href="#OurCourses">
                  <span>Explore our Courses</span>
                  <span className="icon">
                    <img src={images.m2_target} alt="img" />
                  </span>
                </a>
              </div>
            </div>
          </div>
          <div className="aits_banner_container">
            <div className="container">
              <AitsBanner
                is_Amrit={state.is_Amrit}
                aits_pdf={state.aits_pdf}
                amrit_pdf={state.amrit_pdf}
              />
            </div>
          </div>

          <div className="slider_container">
            <div className="container">
              <NewOfferingsSlider />
            </div>
          </div>
          <div className="scheduleClassBx_wrapper">
            <div className="container">
              <ScheduleClass history={history} isPaid={state.isPaid} />
            </div>
          </div>
          <div className="our_course_package" id="OurCourses">
            <div className="container">
              <Our_course_package history={history} />
            </div>
          </div>
          <div className="last_paused_video_container">
            <LastPausedVideo history={history} />
          </div>
          {/* <div className="free_videos_container">
            <FreeVideos history={history} />
          </div> */}
          <div className="institute_wrapper">
            <div className="container">
              <div className="institute_title">
                <h4>Institute</h4>
                {/* <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry simply dummy text of the printing and
                  typesetting industry.
                </p> */}
              </div>
              <div className="institute_box_wrapper">
                <div className="institute_box">
                  <div className="img_wrapper">
                    <img src={images.user_dashboard_7} alt="institute" />
                  </div>
                  <div className="name_desc">
                    <h4>KOTA</h4>
                    <p>
                      Offline Classes by India's Best Teachers with Doubt
                      Solving Facility and One to One Interaction
                    </p>
                  </div>
                  <div className="apply_btn">
                    <Link
                      className="button"
                      to={{
                        pathname:
                          "https://motion.ac.in/online-offline-classroom/?utm_source=mobile_app&utm_medium=mobile_app&utm_campaign=&utm_term=mobile_app",
                      }}
                      target="_blank"
                    >
                      Apply Now
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </Fragment>
  );
};

export default UserDashboardContentComponent;
