import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./ReferalList.scss";
import images from "../../../../utilities/images/images";
import calender from "../../../../utilities/images/refer&earn/calender.png";
import ReferalCard from "./ReferalCard/ReferalCard";
import {
  REFER_AND_EARN_MODALS,
  REFER_AND_EARN_SIZE,
  renderButton,
} from "../utils";
import UpiDetails from "../ReferAndEarnModals/UpiDetails/UpiDetails";
import BankDetails from "../ReferAndEarnModals/BankDetails/BankDetails";
import VerifyDetails from "../ReferAndEarnModals/VerifyDetails/VerifyDetails";
import Successful from "../ReferAndEarnModals/Successful/Successful";
import Cancel from "../ReferAndEarnModals/Cancel/Cancel";
import { setCheckedCards } from "../../../../redux/refer_earn/ReferAndEarnSlice";

const ReferalList = ({
  tokenType,
  token,
  setReferAndEarnType,
  setReferalListSize,
  referalListSize,
  handleBackBtn,
  checkedReferalList,
  handleCheckboxChange,
  referalList,
  setReferAndSize,
  dispatch,
  setCheckedReferalList,
  startLoading,
  finishLoading,
}) => {
  console.log("referalList", referalList);

  const [modalType, setModalType] = useState(REFER_AND_EARN_MODALS.INIT);

  const checkTotalCashMoreOneRupee = () => {
    return checkedReferalList
      .map((ref) => parseFloat(ref.reward_cash))
      .reduce((acc, curr) => acc + curr, 0);
  };

  const handleClickReedemCashBtn = () => {
    if (checkedReferalList.length === 0) {
      alert("Please Select the Refferal");
      return;
    }

    if (checkTotalCashMoreOneRupee() < 1) {
      alert(
        "Please ensure that the referrals have a minimum value of 1 rupee."
      );
      return;
    }

    dispatch(setCheckedCards(checkedReferalList));

    setModalType(REFER_AND_EARN_MODALS.UPI_DETAILS);
  };

  const handleClickViewAllReferalList = () => {
    setReferAndSize(REFER_AND_EARN_SIZE.REFER_AND_EARN_BIG);
    dispatch(setCheckedCards([]));
    setReferalListSize("referal_list_large");
    setCheckedReferalList([]);
  };

  const onCloseModal = () => {
    setModalType(REFER_AND_EARN_MODALS.INIT);
  };

  return (
    <div className={`referalListwrppr ${referalListSize}`}>
      <div className="referalListInnerWrapper">
        <div className="redeem_cash_btn" onClick={handleClickReedemCashBtn}>
          {renderButton("Reedem Cash")}
        </div>
        <div className="referalListTitle">
          <div className="left">
            {referalListSize === "referal_list_large" && (
              <div className="backBtnContent">
                <div className="backBtn" onClick={handleBackBtn}>
                  <img src={images.newBackBtn} alt="backBtn" />
                </div>
              </div>
            )}
            <div className="text">Referral List</div>
          </div>
          <div className="right" onClick={handleClickViewAllReferalList}>
            {referalListSize === "referal_list_small" && referalList?.length ? (
              "View All"
            ) : (
              <div></div>
            )}
          </div>
        </div>

        <div className="lists">
          {referalList?.length ? (
            referalList.map((referal) => {
              return (
                <React.Fragment key={referal.id}>
                  <ReferalCard
                    referal={referal}
                    setModalType={setModalType}
                    handleCheckboxChange={handleCheckboxChange}
                    checkedReferalList={checkedReferalList}
                  />
                </React.Fragment>
              );
            })
          ) : (
            <div className="noreferal_container">
              <img
                src={images.noReferals}
                className="noreferal_icon"
                alt="no-referal-img"
              />
              <div className="noreferal_text">No referrals found... </div>
            </div>
          )}
        </div>

        {modalType === REFER_AND_EARN_MODALS.UPI_DETAILS && (
          <UpiDetails
            show={true}
            onCloseModal={onCloseModal}
            setModalType={setModalType}
            token={token}
            tokenType={tokenType}
          />
        )}

        {modalType === REFER_AND_EARN_MODALS.BANK_DETAILS && (
          <BankDetails
            show={true}
            onCloseModal={onCloseModal}
            setModalType={setModalType}
            token={token}
            tokenType={tokenType}
          />
        )}

        {modalType === REFER_AND_EARN_MODALS.VERIFY_DETAILS && (
          <VerifyDetails
            show={true}
            onCloseModal={onCloseModal}
            setModalType={setModalType}
            token={token}
            tokenType={tokenType}
            setCheckedCards={setCheckedCards}
          />
        )}

        {modalType === REFER_AND_EARN_MODALS.SUCCESSFULL && (
          <Successful
            show={true}
            onCloseModal={onCloseModal}
            setModalType={setModalType}
            setReferAndEarnType={setReferAndEarnType}
            token={token}
            tokenType={tokenType}
            dispatch={dispatch}
            setCheckedReferalList={setCheckedReferalList}
            startLoading={startLoading}
            finishLoading={finishLoading}
            setCheckedCards={setCheckedCards}
          />
        )}

        {modalType === REFER_AND_EARN_MODALS.CANCEL_MODIFY && (
          <Cancel
            show={true}
            onCloseModal={onCloseModal}
            setModalType={setModalType}
            token={token}
            tokenType={tokenType}
          />
        )}
      </div>
    </div>
  );
};

export default ReferalList;
