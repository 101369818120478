import { Fragment } from "react";
import "./examCrackerTestInstruction.scss";

import images from "../../../../../../../utilities/images/images";
import { useLocation, useHistory } from "react-router-dom";
import { connect } from "react-redux";

const ExamCrackerTestInstructionView = (props) => {
  let { startBtn, testData } = props;

  const history = useHistory();
  const location = useLocation();
  let package_name = location.state.package_name;

  const handleBackBtn = () => {
    console.log("handleBackBtn");
    history.push({
      pathname: "/exam_cracker_subject_topic",
      state: {
        package_name: package_name,
        courseId: location.state,
      },
    });
  };

  return (
    <Fragment>
      <div className="test_intructions_view_wrapper">
        <div className="container">
          <div className="test_intructions_view_inner_wrapper">
            <div className="instructionScreenBox">
              <button className="backBtn" onClick={handleBackBtn}>
                <img src={images.m2_backBtn} alt="backBtn" />
              </button>

              <div className="instruction_title">Exam Cracker</div>
              <div className="instruction_topicName">{testData.topic_name}</div>

              <div className="instruction_detailBox">
                <div className="instructions">
                  <h3>Test Instructions</h3>
                  <div className="instPoint">
                    <p>This Section contains a set of questions for practice</p>
                    <p>
                      Users can go to previous question by clicking on the
                      previous button
                    </p>
                    <p>
                      Users can go to next question by clicking on the next
                      button
                    </p>
                    <h6>Best of luck</h6>
                  </div>
                </div>

                <div className="startTestBtn">
                  <button onClick={() => startBtn()}>Start Test</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    testData: state.examCrackerTestsData.examCrackerTestData,
  };
};

export default connect(mapStateToProps)(ExamCrackerTestInstructionView);
