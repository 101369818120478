import React, { useEffect, useState } from "react";
import axios from "axios";
import { url_225 } from "../../../../custom_utilities/api_services";
import "./Animation.scss";

const Animation = ({ token, tokenType }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [cashbackReceiptList, setCashbackReceivedList] = useState([]);

  useEffect(() => {
    getCashbackReceiptList();
  }, []);

  useEffect(() => {
    if (cashbackReceiptList.length > 0) {
      const interval = setInterval(() => {
        setCurrentIndex(
          (prevIndex) => (prevIndex + 1) % cashbackReceiptList.length
        );
      }, 2000);

      return () => clearInterval(interval);
    }
  }, [cashbackReceiptList]);

  const getCashbackReceiptList = async () => {
    const headers = {
      Authorization: `${tokenType} ${token}`,
    };

    try {
      const response = await axios.get(url_225, { headers });

      if (response.data.status === 200) {
        setCashbackReceivedList(response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="card">
      {cashbackReceiptList.map((elem, index) => {
        if (index === currentIndex) {
          return (
            <div key={index} className="card_content">
              <img src={elem.image} alt="icon" />
              <div className="name">
                {elem.name} has won{" "}
                <span className="reward_cash">
                  {elem.reward_cash} cash reward
                </span>
                <div className="time_ago">{elem.time_diff} ago</div>
              </div>
            </div>
          );
        }
        return null;
      })}
    </div>
  );
};

export default Animation;
