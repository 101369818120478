import React from "react";
import { Modal } from "react-bootstrap";
import "./TermsAndConditionModal.scss";

const TermsAndConditionModal = ({
  termsAndCondition,
  termsAndConditionModal,
  handleCloseTermsAndConditions,
}) => {
  const paragraphs = termsAndCondition?.value
    ?.split("\r\n")
    .map((paragraph, index) => <p key={index}>{paragraph}</p>);

  return (
    <Modal
      show={termsAndConditionModal}
      onHide={handleCloseTermsAndConditions}
      dialogClassName="store-terms-and-conditions"
      centered
    >
      <Modal.Header closeButton>
        <h3>Terms And Conditions</h3>
      </Modal.Header>

      <Modal.Body className="modal-body">
        <div className="store_terms_condition_wrapper">
          <div className="store_terms_conditions_inner_wrapper">
            {paragraphs}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default TermsAndConditionModal;
