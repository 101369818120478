import { singularSdk, SingularConfig } from "singular-sdk";

function initSingular() {
  const config = new SingularConfig(
    "motion_education_private_limited_faac25b6",
    "bfa6dbce82faa0dbb9fc9e0bd2d9288d",
    "learning.motion.ac.in"
  ).withInitFinishedCallback((initParams) => {
    const singularDeviceId = initParams.singularDeviceId;

    console.log("singular device id: " + singularDeviceId);
  });
  singularSdk.init(config);
}

export default initSingular;

export const singularLogin = (userId) => {
  console.log("singular id function", userId);
  singularSdk.login(userId);
};

export const handleRegisterEvent = (user_id) => {
  console.log("handleRegisterEvent", user_id);
  singularSdk.event("Register", {
    userID: user_id || "",
  });
};
