import { Fragment, useEffect, useState } from "react";

//Redux
import { connect } from "react-redux";
import { ZoomMtg } from "@zoomus/websdk";

import {
    isEmpty,
    sliceString,
    splitString,
  } from "../../../../custom_utilities/custom_useful_functions";


const ZoomContentComponent = (props) =>{

    let {history, userData, liveStreamData} = props;

    useEffect(() => {
        
        ZoomMtg.setZoomJSLib('https://source.zoom.us/2.5.0/lib', '/av');
        ZoomMtg.preLoadWasm();
        ZoomMtg.prepareJssdk();

        joinMeeting(liveStreamData);
        
      }, []);

      const showZoomDiv =() =>{
        document.getElementById("zmmtg-root").style.display = "block";
      }



    const decrypt_functionality = (val) =>{

        const CryptoJS = require("crypto-js");
        
        let encrypted = val;
        let key = "0CiuKpLCZbX7gBCyYTka4USxTnh7ssXKk8bJ6Y3TXPc=";
    
        encrypted = atob(encrypted);
    
        encrypted = JSON.parse(encrypted);
    
        // console.log('Laravel encryption result', encrypted);
    
        // IV is base64 encoded in Laravel, expected as word array in cryptojs
        const iv = CryptoJS.enc.Base64.parse(encrypted.iv);
    
        // Value (chipher text) is also base64 encoded in Laravel, same in cryptojs
        const value = encrypted.value;
    
    
        // Key is base64 encoded in Laravel, word array expected in cryptojs
        key = CryptoJS.enc.Base64.parse(key);
    
        // Decrypt the value, providing the IV. 
        var decrypted = CryptoJS.AES.decrypt(value, key, {
          iv: iv
        });
    
        // CryptoJS returns a word array which can be 
        // converted to string like this
        decrypted = decrypted.toString(CryptoJS.enc.Utf8);
    
        //console.log(decrypted); // Voilà! Prints "Hello world!"
    
        return decrypted.split('*')[1];
    
      }

      const crypto = require('crypto'); // crypto comes with Node.js

        function generateSignature(apiKey, apiSecret, meetingNumber) {

            console.log(meetingNumber);

            return new Promise((res, rej) => {

            const role = "0";
            const timestamp = new Date().getTime() - 30000;

            const msg = Buffer.from(apiKey + meetingNumber + timestamp + role).toString('base64');

            const hash = crypto.createHmac('sha256', apiSecret).update(msg).digest('base64');

            const signature = Buffer.from(`${apiKey}.${meetingNumber}.${timestamp}.${role}.${hash}`).toString('base64');
            
            
            res(signature);

            });
            
        }


  const joinMeeting = (element) => {
    console.log('elementt in zoom',element)
  
        

        const meetConfig = {
          meetingNumber: decrypt_functionality(element.zoom_id),
          leaveUrl: "https://learning.motion.ac.in/user_dashboard",
          userEmail: decrypt_functionality(element.zoom_email),
          passWord: decrypt_functionality(element.password),
          apiKey: decrypt_functionality(element.api_key),
          apiSecretKey: decrypt_functionality(element.api_secret_key),
          sdkKey: decrypt_functionality(element.sdk_key),
          sdkSecretKey: decrypt_functionality(element.sdk_secret_key),
        };
      
    
        let my_signature  = '';
      
        generateSignature(meetConfig.apiKey, meetConfig.apiSecretKey, meetConfig.meetingNumber).then((res) => {
          my_signature = res
          showZoomDiv();
          ZoomMtg.init({
            leaveUrl: meetConfig.leaveUrl,
            isSupportAV: true,
            success: (success) => {
              console.log(success);
              ZoomMtg.join({
                signature: my_signature,
                meetingNumber: meetConfig.meetingNumber,
                userName: ((!isEmpty(userData.roll_no) ? userData.roll_no  : "Not Roll")+"-"+(!isEmpty(userData.name) ? userData.name  : "Not Registered")),
                apiKey: meetConfig.apiKey,
                userEmail: meetConfig.userEmail,
                passWord: meetConfig.passWord,
                success: (success) => {
                  console.log(success)
                },
                error: (error) => {
                  console.log(error)
                }
              })
          
            },
            error: (error) => {
              console.log(error)
            }
          });
        });
      
      }
  

      return (
        <div className="App">
          Loading Class
          </div>
      );

};


export default ZoomContentComponent;

