import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReferFrndBanner from "./referFrndBanner/ReferFrndBanner";
import HowItWorks from "./howItWorks/HowItWorks";
import Faqs from "./faqs/Faqs";
import CopyCode from "./copyCode/CopyCode";
import ReferalList from "./referalList/ReferalList";
import { Link } from "react-router-dom";
import images from "../../../utilities/images/images";
import axios from "axios";
import { url_221 } from "../../../custom_utilities/api_services";
import { setPaymentDetails } from "../../../redux/refer_earn/ReferAndEarnSlice";
import { REFER_AND_EARN_SIZE } from "./utils";
import Animation from "./Animation/Animation";
import { getReferalLists } from "./ReferAndEarnModals/Api";
import uiActions from "../../../redux/ui/action";
import { setCheckedCards } from "../../../redux/refer_earn/ReferAndEarnSlice";
import "./ReferAndEarnContent.scss";

const ReferAndEarnContent = ({
  data,
  referAndEarnData,
  windowWidth,
  handleTermsClick,
  token,
  tokenType,
  setReferAndEarnType,
  referalListSize,
  setReferalListSize,
}) => {
  const dispatch = useDispatch();

  const referalList = useSelector(
    (state) => state.ReferAndEarnSlice.referalList
  );

  const [referAndEarnSize, setReferAndSize] = useState(
    REFER_AND_EARN_SIZE.REFER_AND_EARN_SMALL
  );

  const [checkedReferalList, setCheckedReferalList] = useState([]);

  const startLoading = () => {
    dispatch(uiActions.startLoading());
  };

  const finishLoading = () => {
    dispatch(uiActions.finishLoading());
  };

  useEffect(() => {
    getReferalLists(token, tokenType, dispatch, startLoading, finishLoading);

    dispatch(setCheckedCards([]));
  }, []);

  const handleCheckboxChange = (referal) => {
    setCheckedReferalList((prevState) => {
      const referalExists = prevState.some((item) => item.id === referal.id);

      if (referalExists) {
        return prevState.filter((item) => item.id !== referal.id);
      } else {
        return [...prevState, referal];
      }
    });
  };

  const handleBackBtn = () => {
    setReferAndSize(REFER_AND_EARN_SIZE.REFER_AND_EARN_SMALL);
    setReferalListSize("referal_list_small");
    setCheckedReferalList([]);
  };

  return referAndEarnSize === REFER_AND_EARN_SIZE.REFER_AND_EARN_BIG ? (
    <ReferalList
      token={token}
      tokenType={tokenType}
      setReferAndEarnType={setReferAndEarnType}
      setReferalListSize={setReferalListSize}
      referalListSize={referalListSize}
      checkedReferalList={checkedReferalList}
      handleCheckboxChange={handleCheckboxChange}
      referalList={referalList}
      setReferAndSize={setReferAndSize}
      handleBackBtn={handleBackBtn}
      dispatch={dispatch}
      setCheckedReferalList={setCheckedReferalList}
      startLoading={startLoading}
      finishLoading={finishLoading}
    />
  ) : (
    <div className="ReferandEarn_container">
      <div className="refer_and_earn_inner_container">
        <div className="backBtnContent">
          <Link
            className="backBtn"
            to={{
              pathname: "/user_dashboard",
            }}
          >
            <img src={images.newBackBtn} alt="backBtn" />
          </Link>
          <div className="referAndEarnText">Refer & Earn</div>
        </div>
        <div className="referandEarn_Content">
          <div className="contentLeft">
            <section>
              <ReferFrndBanner
                data={data}
                referAndEarnData={referAndEarnData}
              />
            </section>
            <section>
              <HowItWorks
                works={data.works}
                referAndEarnData={referAndEarnData}
              />
            </section>
            {windowWidth > 578 && (
              <section>
                <Faqs faqsList={referAndEarnData.faq} />
              </section>
            )}
          </div>

          <div className="right_side_content">
            <Animation token={token} tokenType={tokenType} />
            <div className="contentRight">
              <div className="copyCodeContainer">
                <div className="sectionTitle">
                  Copy the code below and share it out!
                </div>
                <CopyCode data={data} />
              </div>

              <div className="total_earned">
                <img src={images.cashIcon} alt="cash-icon" className="first" />
                <div className="middle">Total Cash Rewards Redeemed</div>
                <div className="last">
                  {referAndEarnData?.cash_rewards_earned}
                </div>
              </div>

              <ReferalList
                token={token}
                tokenType={tokenType}
                setReferAndEarnType={setReferAndEarnType}
                setReferalListSize={setReferalListSize}
                referalListSize={referalListSize}
                checkedReferalList={checkedReferalList}
                handleCheckboxChange={handleCheckboxChange}
                referalList={referalList}
                setReferAndSize={setReferAndSize}
                handleBackBtn={handleBackBtn}
                dispatch={dispatch}
                setCheckedReferalList={setCheckedReferalList}
                startLoading={startLoading}
                finishLoading={finishLoading}
              />

              <div className="termsAndconditions">
                <img src={images.exclamationIcon} alt="exclamationIcon" />
                <div className="text">
                  View
                  <span onClick={handleTermsClick}>Terms & Conditions</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReferAndEarnContent;
