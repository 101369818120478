

import React from "react";
import "./RegistrationModal.scss";
import { Button, Modal } from "react-bootstrap";
import { isEmpty } from "../../../../../../../../custom_utilities/custom_useful_functions";
import axios from "axios";
import uiActions from "../../../../../../../../redux/ui/action";
import { connect } from "react-redux";

const RegistrationModal = (props) => {
  const {
    formValues,
    setFormValues,
    showRegistration,
    setShowRegistration,
    setIsFormSubmitted,
    handleCloseRegistration,
    isDisable,
    startLoading,
    finishLoading,
    target,
  } = props;
  const handleSubmitForm = (event) => {
    event.preventDefault();

    const isValidForm = validateForm();

    if (isValidForm) {
      // Set isFormSubmitted to true and submit the form data
      setIsFormSubmitted(true);

      // You can also make an API call to submit the form data here
      handlePostData();

      // Clear the form values after submission
      setFormValues({
        rollNo: "",
        registrationNo: "",
        dob: "",
      });

      // Close the registration modal directly
      setShowRegistration(false);
    } else {
      // Display an error message or handle the case where the form is not valid
    }
  };

  const handlePostData = () => {
    let data = {
      roll_no: formValues.rollNo,
      registration_no: formValues.registrationNo,
      dob: formValues.dob,
    };

    startLoading();
    try {
      axios
        .post(
          "https://learning.motion.ac.in/motioneducation/api/ots/get/saveform",
          data
        )
        .then((response) => {
          if (response.status === 200) {
            finishLoading();
          }
        });
    } catch (error) {}
  };

  const validateForm = () => {
    if (
      isEmpty(formValues.rollNo) ||
      isEmpty(formValues.registrationNo) ||
      isEmpty(formValues.dob)
    ) {
      // If any of the fields are empty, return false to indicate invalid form
      return false;
    }

    return true;
  };

  return (
    <Modal
      show={showRegistration}
      onHide={handleCloseRegistration}
      className="detailsModal"
    >
      <Modal.Header>
        <h3>Provide your {target == 2 ? "JEE" : "NEET"} Details</h3>
      </Modal.Header>
      <Modal.Body>
        <div className="details_Popup">
          <form>
            <div className="form-group">
              <label htmlFor="roll-no">
                Roll Number <span>*</span>
              </label>
              <input
                type="number"
                className="form-control"
                id="roll-no"
                name="roll-no"
                required
                value={formValues.rollNo}
                onChange={(e) =>
                  setFormValues((prevValues) => ({
                    ...prevValues,
                    rollNo: e.target.value,
                  }))
                }
              />
            </div>
            <div className="form-group">
              <label htmlFor="registration-no">
                Registration Number <span>*</span>
              </label>
              <input
                type="text"
                className="form-control"
                id="registration-no"
                name="registration-no"
                required
                value={formValues.registrationNo}
                onChange={(e) =>
                  setFormValues((prevValues) => ({
                    ...prevValues,
                    registrationNo: e.target.value,
                  }))
                }
              />
            </div>

            <div className="form-group">
              <label htmlFor="dob">
                Date Of Birth <span>*</span>
              </label>
              <input
                type="date"
                className="form-control"
                id="dob"
                name="dob"
                required
                pattern="\d{2}/\d{2}/\d{4}"
                placeholder="dd/mm/yyyy"
                value={formValues.dob}
                onChange={(e) =>
                  setFormValues((prevValues) => ({
                    ...prevValues,
                    dob: e.target.value,
                  }))
                }
              />
            </div>

            <Button
              className="submit_btn"
              disabled={isDisable}
              onClick={handleSubmitForm}
            >
              Submit
            </Button>
          </form>
        </div>
      </Modal.Body>
    </Modal>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => {
      dispatch(uiActions.startLoading());
    },
    finishLoading: () => {
      dispatch(uiActions.finishLoading());
    },
  };
};

export default connect(null, mapDispatchToProps)(RegistrationModal);
