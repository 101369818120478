import { Fragment } from "react";
import "./cps_test_component.scss";

import LoaderPopUp from "../../../../../components/loader/loader";

////////
import HeaderComponentTwo from "../../../structure_components/desktop_screens/header_component/header_top/Header_top";
import TestContentComponent from "./test_content_component/cps_test_content_component";

//Redux
import { connect } from "react-redux";

const CpsTestComponent = (props) => {
  let { history, loader, userData} = props;
  const isMob = () => {
    if (window.innerWidth <= 480) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Fragment>
      <div className="test_component_wrapper">
        <div className="test_component_inner_wrapper">
          {/* {isMob() ? null : (
            <Fragment> */}
              <TestContentComponent history={history} 
              userData ={userData}/>
            {/* </Fragment>
          )} */}
        </div>
      </div>
      {loader && <LoaderPopUp />}
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    loader: state.ui.loader,
    userData: state.auth.user,
  };
};

export default connect(mapStateToProps)(CpsTestComponent);
