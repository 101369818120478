import React from "react";
import VIdeoPlayer from "../NewVideoPlayer/videoshow";

const ShakaSolutionViewVideoPlayer = ({ id_video }) => {
  return (
    <div style={{ height: "300px", width: "700px" }}>
      <VIdeoPlayer id_video={id_video} />
    </div>
  );
};

export default ShakaSolutionViewVideoPlayer;
