import React, { useEffect } from "react";
import { Modal, CloseButton } from "react-bootstrap";
import CcAvenuePayment from "../../../../../../custom_utilities/ccavence_payment/CcAvenuePayment";
import "./PaymentModal.scss";
import { checkXSSAttackThroughInput } from "../../../../../../custom_utilities/custom_useful_functions";

const PaymentModal = (props) => {
  const {
    packageDetails,
    show,
    handleClose,
    cpnAmt,
    pkgID,
    cpnInput,
    setCpnInput,
    couponCollection,
    applyAvailableCoins,
    applyCoinData,
    isEmpty,
    availableCoins,
    couponDetails,
    coupanApplied,
    selectedEl,
    saveUserTransactions,
    originalAmt,
  } = props;

  const handleInputchange = async (value) => {
    const response = await checkXSSAttackThroughInput(value);

    if (!response) {
      return;
    } else {
      setCpnInput(value);
    }

    // if (value.includes("script")) {
    //   alert("Script-related content not allowed!");
    //   return;
    // }

    if (value.length == 0) {
      couponCollection(value, applyCoinData.data);
    }
  };

  return (
    <Modal show={show} onHide={handleClose} className="payment_modal" centered>
      <Modal.Header closeButton className="mdl_hdr"></Modal.Header>
      <Modal.Body className="mbd">
        <div className="coupon_wrapper">
          {packageDetails?.is_subscribe ? (
            <p className="already_purchased">
              You have already purchased this course
            </p>
          ) : (
            <div className="coupon_wraper_inr">
              <h2>{`₹ ${originalAmt}`}</h2>
              <p>
                If you have coupon code or discount code provided by motion
                education
              </p>
              {pkgID != 148 && pkgID != 149 ? (
                <div className="coupon_input">
                  <input
                    type="text"
                    placeholder="Coupon code"
                    value={cpnInput}
                    onChange={(e) => handleInputchange(e.target.value)}
                  />
                  <button
                    onClick={() =>
                      couponCollection(cpnInput, applyCoinData.data)
                    }
                  >
                    Apply Code
                  </button>
                </div>
              ) : null}
              {!applyCoinData.isError ? (
                <div className="d-flex alig-items-center gap-2 applyCoin">
                  <input
                    type="checkbox"
                    id="coupenUseCheckbox"
                    className="form-check-input mt-0"
                    onChange={applyAvailableCoins}
                  />
                  <label htmlFor="coupenUseCheckbox">
                    Apply {availableCoins} Motion Coins
                  </label>
                </div>
              ) : (
                <div className="d-flex align-items-center gap-2 applyCoinError">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="30px"
                    viewBox="0 0 512 512"
                  >
                    <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm0-384c13.3 0 24 10.7 24 24V264c0 13.3-10.7 24-24 24s-24-10.7-24-24V152c0-13.3 10.7-24 24-24zM224 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z" />
                  </svg>
                  <p className="coinErrorMsg">{applyCoinData.errorMessage}</p>
                </div>
              )}
              <div className="updated_amount">
                Updated Amount
                {/* {!isEmpty(couponDetails) ? (
                <span>
                  {coupanApplied === 1
                    ? applyCoinData.coinsApplied === 2
                      ? applyCoinData.data.updated_amount
                      : couponDetails.updated_amount
                    : couponDetails.updated_amount}
                </span>
              ) : (
                <span>{`₹ ${
                  isEmpty(applyCoinData.data)
                    ? cpnAmt
                    : applyCoinData.data.updated_amount
                }`}</span>
              )}
            </div> */}
                <span>{`₹ ${cpnAmt}`}</span>
              </div>

              {cpnAmt == 0 ? (
                <div className="pay_now" closeButton>
                  <button onClick={() => saveUserTransactions(selectedEl)}>
                    Active
                  </button>
                </div>
              ) : (
                // <div className="pay_now">
                //   <button onClick={() => pricePurchaseBtn(selectedEl)}>
                //     Pay Now
                //   </button>
                // </div>
                <CcAvenuePayment
                  packageDetails={selectedEl}
                  // amount={isEmpty(applyCoinData.data)? (couponDetails.updated_amount || cpnAmt) : applyCoinData.data.updated_amount }
                  amount={
                    !isEmpty(couponDetails)
                      ? coupanApplied === 1
                        ? applyCoinData.coinsApplied === 2
                          ? applyCoinData.data.updated_amount
                          : couponDetails.updated_amount
                        : couponDetails.updated_amount
                      : isEmpty(applyCoinData.data)
                      ? cpnAmt
                      : applyCoinData.data.updated_amount
                  }
                  buttonText={"Pay Now"}
                  buttonClass={"pay_now"}
                />
                // <div>nothing</div>
              )}
            </div>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default PaymentModal;
