import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import {
  renderButton,
  REFER_AND_EARN_MODALS,
  isValidUpiId,
  setDetailsInLocalStorage,
  getDetailsFromLocalStorage,
  removeString,
} from "../../utils";
import { saveUpiBankDetails } from "../Api";
import "./UpiDetails.scss";

const UpiDetails = ({ show, onCloseModal, setModalType, token, tokenType }) => {
  const [upiId, setUpiId] = useState("");

  useEffect(() => {
    const localStorageUpiDetails =
      getDetailsFromLocalStorage("upiDetails") ?? "";
    setUpiId(removeString(localStorageUpiDetails));
  }, []);

  const handleUpiIdChange = (e) => {
    setUpiId(e.target.value);
  };

  const handleSubmitUpi = () => {
    const response = isValidUpiId(removeString(upiId));

    if (response) {
      const data = {
        type: 1,
        acc_name: "",
        ifsc_code: "",
        acc_no: "",
        cacc_no: "",
        upi_id: upiId,
      };
      setDetailsInLocalStorage("upiDetails", removeString(upiId));
      setModalType(REFER_AND_EARN_MODALS.VERIFY_DETAILS);
      setDetailsInLocalStorage("TYPE", JSON.stringify("UPI"));

      saveUpiBankDetails(data, token, tokenType);
    } else {
      alert("Please Enter a Valid UPI ID");
    }
  };
  return (
    <Modal
      show={show}
      onHide={onCloseModal}
      dialogClassName="upi_details_wrapper"
    >
      <Modal.Header closeButton></Modal.Header>

      <Modal.Body>
        <div className="upi_details_container">
          <h3>Enter Bank Account Details</h3>
          <div className="input_container">
            <label htmlFor="upi_input">
              UPI ID<span>*</span>
            </label>
            <input
              type="text"
              onChange={handleUpiIdChange}
              value={removeString(upiId)}
              name="upi_input"
              id="upi_input"
              placeholder="Enter UPI ID"
            />
          </div>

          <div className="text">
            Don’t have UPI iD?{" "}
            <span
              onClick={() => {
                onCloseModal();
                setModalType(REFER_AND_EARN_MODALS.BANK_DETAILS);
              }}
            >
              Add Bank Details
            </span>
          </div>

          <div className="save_btnn" onClick={handleSubmitUpi}>
            {renderButton("Save & Continue")}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default UpiDetails;
