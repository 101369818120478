import React, { useState } from "react";
import ShakaSolutionViewVideoPlayer from "../../../../../../../../../../../custom_utilities/shakaVideoPlayer/ShakaSolutionViewVideoPlayer";

const QVideoSolution = ({
  handleVideoShow,
  videoShow,
  currentSolutionDetails,
  lang,
  userId,
}) => {
  const handleOnLoad = () => {
    const frame = document.getElementById("iframe");
    frame.contentWindow.postMessage(
      `{"activate": true, "userId": "${userId}"}`,
      "*"
    );

    // Jump to a particular time
    // frame.contentWindow.postMessage(JSON.stringify({ seekTo: 1 }), "*");

    // Handle message event for receiving video timing details
    const eventMethod = window.addEventListener
      ? "addEventListener"
      : "attachEvent";
    const eventer = window[eventMethod];
    const messageEvent =
      eventMethod === "attachEvent" ? "onmessage" : "message";

    eventer(messageEvent, function (e) {
      if (e.data && e.data.currentTime) {
        const videoTimingDetails = { currentTime: e.data.currentTime };
        sessionStorage.setItem(
          "videoTimingDetails",
          JSON.stringify(videoTimingDetails)
        );
      }
    });
  };

  const renderVideo = (currentQuestionDetails) => {
    const videoIdEnglish = currentQuestionDetails.video_id_english;

    const urlPattern = /^https:\/\/speed\.mogiio\.com\/embed3\/[0-9a-f]{24}$/;

    const buttonLabel =
    videoShow && !lang
    ? "वीडियो समाधान बंद करें" // Close Video Solution
    : videoShow && lang
    ? "Close Video Solution"
    : !videoShow && !lang
    ? "वीडियो समाधान देखें" // View Video Solution
    : "View Video Solution";

    if (videoIdEnglish && urlPattern.test(videoIdEnglish)) {
      // Render iframe with selectedVideoId
      return (
        <div className="videoWrapper">
          <button className="view_videoSolution_btn" onClick={handleVideoShow}>
            {buttonLabel}
          </button>
          {videoShow && (
            <iframe
              id="iframe"
              src={videoIdEnglish}
              onLoad={handleOnLoad}
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          )}
        </div>
      );
    } else {
      // Render ShakaSolutionViewVideoPlayer
      return (
        <div className="videoWrapper">
          <button className="view_videoSolution_btn" onClick={handleVideoShow}>
            {buttonLabel}
          </button>
          {videoShow && (
            <ShakaSolutionViewVideoPlayer
              id_video={videoIdEnglish}
              className="shakaPlayer"
            />
          )}
        </div>
      );
    }
  };

  return <div>{renderVideo(currentSolutionDetails)}</div>;
};

export default QVideoSolution;
