import React, { Fragment, useState, useRef, useEffect } from "react";
import "./test_questions_view.scss";
import ProgressBar from "react-bootstrap/ProgressBar";
import { Button, Modal } from "react-bootstrap";
import images from "../../../../../../../utilities/images/images";
import useStopwatch from "../../../../../../../hooks/useStopwatch";
import useMathjax from "../../../../../../../hooks/useMathjax";
import {
  url_101,
  url_102,
} from "../../../../../../../custom_utilities/api_services";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { toastifyMessage } from "../../../../../../../custom_utilities/toastify_config";
import ShakaSolutionViewVideoPlayer from "../../../../../../../custom_utilities/shakaVideoPlayer/ShakaSolutionViewVideoPlayer";

const TestQuestionView = (props) => {
  let {
    testQuestionsDetails,
    currentQuestionDetails,
    page,
    currentQuestion,
    countDownTimer,
    handlePreviousQuestionBtn,
    handleNextQuestionBtn,
    handleSaveQuestion,
    handleSingleSelectAnswer,
    handleMultiSelectAnswer,
    handleInputFieldChange,
    handleReview,
    handleSaved,
    handleGuessedAnswer,
    handleQuestionJump,
    handleSubmitTest,
    handleSectionChange,
    handleSubjectChange,
    selectedSubject,
    selectedSection,
    checkQuestion,
    userId,
    startLoading,
    finishLoading,
    handleCurrentOption,
    subjecSectiontWiseTestQuestions,
  } = props;

  const { stopwatchText, updateTime, updateSolution, getClicked, solDisabled, showSolution, textInputSolution} = useStopwatch(currentQuestion, testQuestionsDetails[currentQuestion].integer_answer, currentQuestionDetails);

  const questionsCollectionRef = useRef(null);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showError, setShowError] = useState(false);
  const [reportErrorData, setReportErrorData] = useState([]);
  const [lang, setLang] = useState(true);
  const [sol, setSol] = useState(true);
  // const [disabled1, setDisabled] = useState(false);
  const [videoShow, setVideoShow] = useState(false);

  const [saveReportErrorData, setSaveReportErrorData] = useState({
    user_id: userId,
    q_id: testQuestionsDetails[currentQuestion].qid,
    option_id: "",
    description: "",
  });

  useMathjax(currentQuestionDetails, lang, getClicked());

  //  condition for Report Error modal submit button
  const isDisable = saveReportErrorData.option_id.length == "" ? true : false;

  const handleGetReportErrorOptions = () => {
    startLoading();

    try {
      axios.get(url_101).then((response) => {
        setReportErrorData([...response.data.data]);

        if (response.status === 200) {
          finishLoading();
          handleShowReportError();
        }
      });
    } catch (error) {
      finishLoading();
    }
  };

  // function for posting user error data to api

  const handlePostReportErrorData = () => {
    startLoading();
    try {
      axios.post(url_102, saveReportErrorData).then((response) => {
        if (response.status === 200) {
          finishLoading();
          handleCloseReportError();
          toastifyMessage("Your Report has Submitted Successfully");
          setSaveReportErrorData({ option_id: "" });
        }
      });
    } catch (error) {}
  };

  const scrollUp = () => {
    const nextQuestionIndex = currentQuestion + 1;
    const questionElement =
      questionsCollectionRef.current.children[nextQuestionIndex];
    questionElement.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  const scrollUp2 = () => {
    const prevQuestionIndex = currentQuestion - 1;
    const questionElement =
      questionsCollectionRef.current.children[prevQuestionIndex];
    questionElement.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  const handleCloseReportError = () => {
    setShowError(false);
    setSaveReportErrorData({ option_id: "" });
  };
  const handleShowReportError = () => setShowError(true);

  const handleSol = () => {
    setSol(true);
    // setDisabled(false);
  };
  const handleSolution = () => {
    setSol(false);
    setVideoShow(false);
    //classEditor();
  };
  // const disableSol = () => {
  //   setDisabled(true);
  // };

  const renderSolutionss = () => {
      let solutionText = currentQuestionDetails.sol_text;
      let solutionHeading = "Solution :";

      if (!lang && currentQuestionDetails.sol_text_hindi) {
        const hindiSolutionText = currentQuestionDetails.sol_text_hindi;
        const hindiSolutionHeading = "समाधान :";

        const isHindiSolutionEmpty =
          !hindiSolutionText.trim() || hindiSolutionText.includes("<html>");

        if (!isHindiSolutionEmpty) {
          solutionText = hindiSolutionText;
          solutionHeading = hindiSolutionHeading;
        }
      }

      return (
        <>
        {(getClicked() && 
        <>
          <p>{solutionHeading}</p>
          <p
            className="solution-text"
            dangerouslySetInnerHTML={{
              __html: solutionText,
            }}
          ></p>
          {renderVideo(currentQuestionDetails)}
        </>)}
        </>
      );
    
  };

  const renderOptions = (currentQuestionDetails, lang) => {
    if (currentQuestionDetails) {
      let step,
        type = currentQuestionDetails.answer_type;

      if (type.toLowerCase() === "single correct") {
        step = 1;
      } else if (type.toLowerCase() === "integer correct") {
        step = 2;
      } else {
        step = 3;
      }

      switch (step) {
        case 1:
          return renderSingleSelect(
            currentQuestionDetails,
            currentQuestion,
            lang
          );
        case 2:
          return renderInputField(currentQuestionDetails, currentQuestion);
        case 3:
          return renderMultiSelect(
            currentQuestionDetails,
            currentQuestion,
            lang
          );
        default:
          return null;
      }
    } else {
      return null;
    }
  };

  const classEditor = (s, wc, optionId) => {
    if (Number(s) && getClicked()) {
      return "single_option_wrapper classCorrect";
    } else if (wc && getClicked() && wc.indexOf(Number(optionId)) > -1) {
      return "single_option_wrapper classWrong";
    } else {
      return "single_option_wrapper";
    }
  };

  // const handlePurticularQuestion = (currentQuestion) => {
  //   if (
  //     testQuestionsDetails[currentQuestion - 1].userAnswerCollection.length ||
  //     testQuestionsDetails[currentQuestion - 1].isAttempted === true
  //   ) {
  //     handleSolution();
  //   } else {
  //     handleSol();
  //   }
  // };

  const handlePurticularQuestion4 = () => {
    if (
      testQuestionsDetails[currentQuestion + 1].userAnswerCollection.length ||
      testQuestionsDetails[currentQuestion + 1].isAttempted === true
    ) {
      handleSolution();
    } else {
      handleSol();
    }
  };

  const handlePurticularQuestion3 = (currentQuestion) => {
    if (testQuestionsDetails[currentQuestion].userAnswerCollection.length) {
      handleSol();
    } else {
      handleSolution();
    }
  };

  const handlePurticularQuestion2 = (currentQuestion) => {
    if (testQuestionsDetails[currentQuestion].userAnswerCollection.length) {
      handleSolution();
    } else {
      handleSol();
    }
  };

  const renderSingleSelect = (questionData, currentQuestion2, lang) => {
    const renderOptionInitial = (index) => {
      const alphabetArr = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      return alphabetArr[index];
    };

    return (
      <Fragment>
        <div
          className={
            currentQuestion2 === currentQuestion
              ? "single_select_option_collection_wrapper show"
              : "single_select_option_collection_wrapper hide"
          }
        >
          {questionData.option && questionData.option.length
            ? questionData.option.map((element, index) => {
                let optionText = element.option;

                if (
                  !lang &&
                  questionData.option_hindi &&
                  questionData.option_hindi[index]
                ) {
                  optionText =
                    questionData.option_hindi[index].option || element.option;
                }

                return (
                  <div key={index} className="single_select">
                    <input
                      type="checkbox"
                      id={element.option_id}
                      name={`current_question_${currentQuestion2}`}
                      // disabled={sol ? false : true}
                      checked={
                        testQuestionsDetails[currentQuestion2]
                          .userAnswerCollection.length
                          ? testQuestionsDetails[currentQuestion2]
                              .userAnswerCollection[0].option_id ===
                            element.option_id
                          : element.is_user_correct_ans == 1
                          ? true
                          : false
                      }
                      onChange={(event) => {
                        handleSingleSelectAnswer(event, element.option_id);
                        handleCurrentOption(element.option_id);
                        // disableSol();
                        showSolution(currentQuestion);
                      }}
                    />
                    <label
                      htmlFor={element.option_id}
                      className={classEditor(
                        element.is_correct,
                        testQuestionsDetails[currentQuestion2]
                          .wrongAnswerIdCollection,
                        element.option_id
                      )}
                    >
                      <div className="option_initial">
                        <p className="text_content_2">
                          {`${renderOptionInitial(index)}.`}
                        </p>
                      </div>
                      <div className="option_final">
                        <p
                          className="text_content_3"
                          dangerouslySetInnerHTML={{ __html: optionText }}
                        ></p>
                      </div>
                    </label>
                  </div>
                );
              })
            : null}
        </div>
      </Fragment>
    );
  };

  const renderMultiSelect = (questionData, currentQuestion2, lang) => {
    const renderOptionInitial = (index) => {
      const alphabetArr = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      return alphabetArr[index];
    };

    const renderOptionText = (optionText) => {
      return { __html: optionText };
    };

    return (
      <Fragment>
        <div
          className={
            currentQuestion2 === currentQuestion
              ? "multi_select_option_collection_wrapper show"
              : "multi_select_option_collection_wrapper hide"
          }
        >
          {questionData.option && questionData.option.length
            ? questionData.option.map((element, index) => {
                let optionText = element.option;

                if (
                  !lang &&
                  questionData.option_hindi &&
                  questionData.option_hindi.length > 0
                ) {
                  const hindiOption = questionData.option_hindi[index];
                  if (hindiOption && hindiOption.option) {
                    optionText = hindiOption.option;
                  }
                }

                return (
                  <div key={index} className="single_select">
                    <input
                      type="checkbox"
                      id={element.option_id}
                      name={`current_question_${currentQuestion2}`}
                      // disabled={sol ? false : true}
                      checked={
                        testQuestionsDetails[currentQuestion2]
                          .userAnswerCollection.length
                          ? testQuestionsDetails[
                              currentQuestion2
                            ].userAnswerCollection.find(
                              (item) => item.option_id === element.option_id
                            )
                          : false
                      }
                      onChange={(event) => {
                        handleMultiSelectAnswer(event, element.option_id);
                        // disableSol();
                        showSolution(currentQuestion);
                      }}
                    />
                    <label
                      htmlFor={element.option_id}
                      className={classEditor(
                        element.is_correct,
                        testQuestionsDetails[currentQuestion2]
                          .wrongAnswerIdCollection,
                        element.option_id
                      )}
                    >
                      <div className="option_initial">
                        <p className="text_content_2">{`${renderOptionInitial(
                          index
                        )}.`}</p>
                      </div>
                      <div className="option_final">
                        <p
                          className="text_content_3"
                          dangerouslySetInnerHTML={renderOptionText(optionText)}
                        ></p>
                      </div>
                    </label>
                  </div>
                );
              })
            : null}
        </div>
      </Fragment>
    );
  };

  const renderInputField = (currentQuestionDetails, currentQuestion2) => {
    return (
      <Fragment>
        <div
          className={
            currentQuestion2 == currentQuestion
              ? "input_wrapper show2"
              : "input_wrapper hide"
          }
        >
          <div className="input_inner_wrapper">
            <input
              key={currentQuestion2}
              // type="number"
              type="text"
              placeholder="Enter your answer"
              // disabled={checkQuestion ? false : true}
              disabled={sol ? false : true}
              value={testQuestionsDetails[currentQuestion2].integer_answer}
              // value={currentQuestionDetails.integer_answer}
              onChange={(event) => {
                handleInputFieldChange(event);
                textInputSolution(event.target.value);
              }}
            />
          </div>
        </div>
      </Fragment>
    );
  };

  const renderQuestionLabel = (question, index) => {
    const { q_text, q_text_hindi } = question;
    const isTable = q_text.includes("<table");
    const isImage = q_text.includes("<img");
    let textToDisplay = "";

    if (lang) {
      textToDisplay = q_text;
    } else {
      textToDisplay = q_text_hindi || q_text; // Show Hindi if available, otherwise fallback to English
    }

    if (currentQuestion === index) {
      return (
        <label
          className="unanswered_lbl current_lbl"
          onClick={() => {
            updateTime();
            handleQuestionJump(index);
            handlePurticularQuestion3(index);
          }}
        >
          <div className="questionBox">
            {`${index + 1}.`}
            <div className={`QBox${isImage ? " twoLines" : ""}`}>
              {isTable ? (
                <div
                  className="tableContainer"
                  dangerouslySetInnerHTML={{ __html: textToDisplay }}
                />
              ) : (
                <p dangerouslySetInnerHTML={{ __html: textToDisplay }} />
              )}
            </div>
          </div>
          <img className="arrow" src={images.right_Ar} />
        </label>
      );
    } else if (question.isAttempted) {
      return (
        <label
          className="unanswered_lbl answered_lbl"
          onClick={() => {
            updateTime();
            handleQuestionJump(index);
            handlePurticularQuestion2(index);
          }}
        >
          <div className="questionBox">
            {`${index + 1}.`}
            <div className={`QBox${isImage ? " twoLines" : ""}`}>
              {isTable ? (
                <div
                  className="tableContainer"
                  dangerouslySetInnerHTML={{ __html: textToDisplay }}
                />
              ) : (
                <p dangerouslySetInnerHTML={{ __html: textToDisplay }} />
              )}
            </div>
          </div>
          <img className="arrow" src={images.right_Ar} />
        </label>
      );
    } else {
      return (
        <label
          className="unanswered_lbl"
          onClick={() => {
            updateTime();
            handleQuestionJump(index);
            handlePurticularQuestion2(index);
          }}
        >
          <div className="questionBox">
            {`${index + 1}.`}
            <div className={`QBox${isImage ? " twoLines" : ""}`}>
              {isTable ? (
                <div
                  className="tableContainer"
                  dangerouslySetInnerHTML={{ __html: textToDisplay }}
                />
              ) : (
                <p dangerouslySetInnerHTML={{ __html: textToDisplay }} />
              )}
            </div>
          </div>
          <img className="arrow" src={images.right_Ar} />
        </label>
      );
    }
  };

  const handleOnLoad = () => {
    let frame = document.querySelector("#iframe");
    frame.contentWindow.postMessage(
      `{"activate": true, "userId": "${userId}"}`,
      "*"
    );

    // Jump to a particular time
    // frame.contentWindow.postMessage(JSON.stringify({ seekTo: 1 }), "*");

    // Handle message event for receiving video timing details
    let eventMethod = window.addEventListener
      ? "addEventListener"
      : "attachEvent";
    let eventer = window[eventMethod];
    let messageEvent = eventMethod === "attachEvent" ? "onmessage" : "message";

    eventer(messageEvent, function (e) {
      if (e.data && e.data.currentTime) {
        let videoTimingDetails = { currentTime: e.data.currentTime };
        sessionStorage.setItem(
          "videoTimingDetails",
          JSON.stringify(videoTimingDetails)
        );
      }
    });
  };

  const handleVideoShow = () => {
    setVideoShow(!videoShow);
    const targetDiv = document.querySelector(".sub_section_wrapper");
      setTimeout(() => {
        targetDiv.scrollTop = targetDiv.scrollHeight;
    }, 1);
  };

  const renderVideo = () => {

    const videoId = currentQuestionDetails?.video_id_english;
    const hindiVideoId = currentQuestionDetails?.video_id_hindi;

    const isHindi = !lang && hindiVideoId;

    
    const selectedVideoId = isHindi ? hindiVideoId : videoId;

    const urlPattern = /^https:\/\/speed\.mogiio\.com\/embed3\/[0-9a-f]{24}$/;

    const isIframe = urlPattern.test(selectedVideoId);

    // const buttonLabel = lang ? "View Video Solution" : "वीडियो समाधान देखें";
    const buttonLabel =
    videoShow && !lang
    ? "वीडियो समाधान बंद करें" // Close Video Solution
    : videoShow && lang
    ? "Close Video Solution"
    : !videoShow && !lang
    ? "वीडियो समाधान देखें" // View Video Solution
    : "View Video Solution";

    if (selectedVideoId && selectedVideoId.trim() !== "") {
      return (
        <div className="videoWrapper">
          <button className="view_videoSolution_btn" onClick={handleVideoShow}>
            {buttonLabel}
          </button>


          {videoShow && isIframe ? (
            <iframe
              id="iframe"
              src={selectedVideoId}
              onLoad={handleOnLoad}
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          ) : null}
          {videoShow && !isIframe ? (
            <ShakaSolutionViewVideoPlayer
              id_video={selectedVideoId}
              className="shakaPlayer"
            />
          ) : null}
        </div>
      );
    } else {
      return null;
    }
  };

  let ConvertStringToHTML = function (str) {
    if (!str) {
      return false;
    }
    let parser = new DOMParser();
    let doc = parser.parseFromString(str, "text/html");

    return doc.body.children.length > 0;
  };

  const renderSingleQuestion = () => {
    const { q_text, q_text_hindi } = currentQuestionDetails || {}; // Destructure with default empty object
    const isImage = q_text && q_text.includes("<img");
    const isTable = q_text && q_text.includes("<table");
    const isMTable = q_text && q_text.includes("<mtable");
    let imageSrc = "";

    if (isImage) {
      const imgTagRegex = /<img[^>]+src="?([^"\s]+)"?\s*\/>/g;
      const match = imgTagRegex.exec(q_text);
      if (match && match[1]) {
        imageSrc = match[1];
      }
    }

    if (lang) {
      return (
        <div>
          {q_text && (
            <p
              className={`${isImage ? "image-style" : ""} ${
                isTable ? "table-style" : ""
              } ${isMTable ? "mtable-style" : ""}`}
              dangerouslySetInnerHTML={{
                __html: q_text,
              }}
            ></p>
          )}

          {isTable && <table className="table-style"></table>}
          {isMTable && <mtable className="mtable-style"></mtable>}
        </div>
      );
    } else {
      if (ConvertStringToHTML(q_text_hindi)) {
        return (
          <div>
            {q_text_hindi && (
              <p
                className={`${isImage ? "image-style" : ""} ${
                  isTable ? "table-style" : ""
                } ${isMTable ? "mtable-style" : ""}`}
                dangerouslySetInnerHTML={{
                  __html: q_text_hindi,
                }}
              ></p>
            )}

            {isTable && <table className="table-style"></table>}
            {isMTable && <mtable className="mtable-style"></mtable>}
          </div>
        );
      } else {
        return (
          <div>
            {q_text && (
              <p
                className={`${isImage ? "image-style" : ""} ${
                  isTable ? "table-style" : ""
                } ${isMTable ? "mtable-style" : ""}`}
                dangerouslySetInnerHTML={{
                  __html: q_text,
                }}
              ></p>
            )}
            {isTable && <table className="table-style"></table>}
            {isMTable && <mtable className="mtable-style"></mtable>}
          </div>
        );
      }
    }
  };

  let progressCount = (100 / testQuestionsDetails.length) * currentQuestion + 1;

  return (
    <Fragment>
      <div className="testPractice_questions_view_wrapper">
        <div className="test_questions_view_inner_wrapper">
          <div className="sec_2_wrapper">
            <div className="sec_2_inner_wrapper">
              <div className="section_content_wrapper">
                <div className="left_side_content_wrapper">
                  <div className="sub_section_1_wrapper">
                    <div className="text_content_wrapper">
                      <p className="text_content">All Questions</p>
                      <button
                        onClick={() => {
                          handleSaveQuestion();
                          handleShow();
                        }}
                      >
                        Submit Test
                      </button>
                    </div>
                    <div className="sub_section_2_wrapper">
                      <div className="question_marking_wrapper">
                        <div className="single_marking">
                          <label className="box_pic box_pick_background_1"></label>
                          <label className="question_marking_text">
                            Completed
                          </label>
                        </div>
                        <div className="single_marking">
                          <label className="box_pic box_pick_background_3"></label>
                          <label className="question_marking_text">
                            Pending
                          </label>
                        </div>
                      </div>
                    </div>
                    <div
                      className="questions_serial_number_collection"
                      ref={questionsCollectionRef}
                    >
                      {testQuestionsDetails.length
                        ? testQuestionsDetails.map((element, index) => {
                            return (
                              <div
                                key={index}
                                className="single_question_number_wrapper"
                                id="myDiv"
                              >
                                {renderQuestionLabel(element, index)}
                              </div>
                            );
                          })
                        : null}
                    </div>
                  </div>
                </div>

                <div className="right_side_content_wrapper">
                  <div className="sub_section_wrapper">
                    <div className="sub_sub_section_wrapper_1">
                      <div className="right_side_content_wrapper"></div>
                    </div>

                    <div className="testProgressIndicator">
                      <h4>Test Question progress Indicator</h4>
                      <div className="ProgressBar_Line">
                        <ProgressBar
                          now={progressCount}
                          className="progressBar"
                        />
                        <span style={{ left: progressCount - 1 + "%" }}>{`${
                          currentQuestion + 1
                        }`}</span>
                      </div>
                      <div className="questionPercentage">
                        <span>1</span>
                        <span>{testQuestionsDetails.length}</span>
                      </div>
                    </div>

                    <div className="sectionWiseTestWrapper">
                      <div className="subjectWiseTest">
                        {Object.keys(subjecSectiontWiseTestQuestions).map(
                          (subjectName, index) => {
                            return (
                              <div key={index} class="single_select_subject">
                                <input
                                  type="radio"
                                  id={subjectName}
                                  name="subject"
                                  value={subjectName}
                                  checked={subjectName === selectedSubject}
                                  onChange={() => {
                                    updateTime();
                                    handleSubjectChange(subjectName);
                                    handlePurticularQuestion2(index);
                                  }}
                                />
                                <label for={subjectName}>{subjectName}</label>
                              </div>
                            );
                          }
                        )}
                      </div>

                      <div className="subjectWiseTest sectionWiseTest">
                        {selectedSubject &&
                          Object.keys(
                            subjecSectiontWiseTestQuestions[selectedSubject]
                          ).map((sectionName, index) => {
                            return (
                              <div key={index} class="single_select_subject">
                                <input
                                  type="radio"
                                  id={sectionName}
                                  name="section"
                                  value={sectionName}
                                  checked={sectionName === selectedSection}
                                  onChange={() => {
                                    updateTime();
                                    handleSectionChange(sectionName);
                                    handlePurticularQuestion2(index);
                                  }}
                                />
                                <label for={sectionName}>{sectionName}</label>
                              </div>
                            );
                          })}
                      </div>
                    </div>

                    <div className="sub_sub_section_wrapper_2">
                      <h2>Practice Test</h2>
                      <div className="text_content_wrapper">
                        <div className="typeLanguageWrapper">
                          <span>
                            Question type:{" "}
                            {testQuestionsDetails[currentQuestion].answer_type}
                          </span>
                          <span className="stopwatch_timer">{stopwatchText}</span>
                          <div className="markReview">
                            {testQuestionsDetails[currentQuestion] &&
                            testQuestionsDetails[currentQuestion]
                              .q_text_hindi ? (
                              <button onClick={() => setLang(!lang)}>
                                <img src={images.m2_language} />
                              </button>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        <div className="questionBox">
                          <span className="text_content">
                            {`${Number(currentQuestion) + 1}.`}
                          </span>
                          {renderSingleQuestion()}
                        </div>
                      </div>
                    </div>
                    <div className="sub_sub_section_wrapper_3">
                      {currentQuestionDetails !== null &&
                      currentQuestionDetails.option !== undefined
                        ? renderOptions(currentQuestionDetails, lang)
                        : ""}
                    </div>

                    {/* toaster for  successfull submission of Report error  */}

                    <ToastContainer />
                    <Modal
                      show={show}
                      onHide={handleClose}
                      className="onSubmitModal"
                      centered
                    >
                      <Modal.Header closeButton></Modal.Header>
                      <Modal.Body>
                        <div className="submit_Popup">
                          <div className="exit_img">
                            <img src={images.m2_exit} alt="" />
                          </div>
                          <h5>Are you Sure?</h5>
                          <p>
                            Do you want to continue the exam or you want to
                            Submit.
                          </p>
                          <div className="submitCancelBtn">
                            <button className="cancel" onClick={handleClose}>
                              Cancel
                            </button>
                            <button
                              onClick={() =>
                                handleSubmitTest(currentQuestionDetails)
                              }
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </Modal.Body>
                    </Modal>

                    <Modal
                      show={showError}
                      onHide={handleCloseReportError}
                      className="errorModal"
                    >
                      <Modal.Header closeButton>
                        {" "}
                        <h3>Report an Error</h3>
                      </Modal.Header>
                      <Modal.Body className="modal-body">
                        <div className="error_Popup">
                          <p className="text_content">
                            You can just write to us, If there is any incorrect
                            or error in question and given options.
                          </p>
                          <ul className="errorList">
                            {reportErrorData?.map((element, index) => {
                              return (
                                <li className="errorListItem" key={element.id}>
                                  <input
                                    type="radio"
                                    id={element.id}
                                    name="error"
                                    value={element.option_name}
                                    onChange={(event) => {
                                      setSaveReportErrorData({
                                        ...saveReportErrorData,
                                        option_id: event.target.id,
                                      });
                                    }}
                                  />{" "}
                                  <label htmlFor={element.id}>
                                    {element.option_name}
                                  </label>
                                </li>
                              );
                            })}
                          </ul>
                          <textarea
                            className="textarea_content"
                            type="text"
                            onChange={(event) =>
                              setSaveReportErrorData({
                                ...saveReportErrorData,
                                description: event.target.value,
                              })
                            }
                            placeholder="Write to Us..."
                          />{" "}
                          <br />
                          <Button
                            disabled={isDisable}
                            onClick={handlePostReportErrorData}
                          >
                            Submit
                          </Button>
                        </div>
                      </Modal.Body>
                    </Modal>

                    <div className="sub_sub_section_wrapper_5">
                      <div className="left_side_wrapper">
                        {currentQuestion > 0 && (
                          <div className="btn_wrapper">
                            <button
                              onClick={() => {
                                
                                updateTime();
                                handlePreviousQuestionBtn(currentQuestion - 1);
                                showSolution(currentQuestion-1);
                                scrollUp2();
                                // handlePurticularQuestion(currentQuestion);
                              }}
                            >
                              Previous
                            </button>
                          </div>
                        )}
                      </div>

                      <div className="right_side_wrapper">
                        {testQuestionsDetails.length === currentQuestion + 1 ? (
                          <div className="btn_wrapper d-flex">
                            
                              <button
                                disabled={!solDisabled ? false : true}
                                className={solDisabled ? "opacity solution" : "true solution"}
                                onClick={() => {updateSolution(true); handleSolution()}}
                              >
                                Solution
                              </button>
                            
                              <button
                                onClick={() => {
                                  handleSaveQuestion();
                                  handleShow();
                                }}
                              >
                                Submit
                              </button>
                            
                          </div>
                        ) : (
                          <div className="btn_wrapper d-flex">
                            
                              <button
                                disabled={!solDisabled ? false : true}
                                className={solDisabled ? "opacity solution" : "true solution"}
                                onClick={() => {updateSolution(true); handleSolution()}}
                              >
                                Solution
                              </button>
                            
                              <button
                                onClick={() => {
                                  
                                  updateTime();
                                  handleNextQuestionBtn(currentQuestion + 1);
                                  showSolution(currentQuestion+1);
                                  // handleSol();
                                  scrollUp();
                                  // scrollToNextQuestion();
                                  // handlePurticularQuestion4();
                                }}
                              >
                                Next
                              </button>
                            
                          </div>
                        )}
                      </div>
                    </div>
                    <div
                      style={{
                        marginTop: "40px",
                        marginBottom: "20px",
                        color: "#FF8724",
                        fontSize: "16px",
                        cursor: "pointer",
                      }}
                      onClick={handleGetReportErrorOptions}
                    >
                      <span style={{ marginRight: "5px" }}>
                        <img src={images.user_activity_14}></img>
                      </span>{" "}
                      <span style={{ textDecoration: "underline" }}>
                        Report an error
                      </span>
                    </div>

                    {renderSolutionss()}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default TestQuestionView;
