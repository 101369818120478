import { Fragment } from "react";

///////Canvas Chart//////////////////
import { CanvasJSChart } from "canvasjs-react-charts";
const defaultObj = { correctPoint: [], wrongPoint: [] };
const ScatterChart = (props) => {
  let { dataPoints } = props;
  let { wrongPoint, correctPoint } = dataPoints ? dataPoints : defaultObj;
  const options = {
    animationEnabled: true,
    zoomEnabled: true,
    backgroundColor: "#fff",
    axisY: {
      title: "Time per Question(in sec)",
      fontSize: 12,
      crosshair: {
        enabled: true,
        snapToDataPoint: true,
      },
    },
    axisX: {
      gridThickness: 0,
      tickLength: 0,
      lineThickness: 0,
      labelFormatter: function () {
        return " ";
      },
    },
    data: [
      {
        type: "scatter",
        fontColor: "#000",
        markerSize: 15,
        markerColor: "red",
        toolTipContent: "Wrong",
        dataPoints: [...wrongPoint],
      },
      {
        type: "scatter",
        fontColor: "#000",
        markerColor: "green",
        markerSize: 15,
        toolTipContent: "Correct",
        dataPoints: [...correctPoint],
      },
    ],
  };
  return (
    <Fragment>
      <div className="canvasDoughnutChart_wrapper">
        <CanvasJSChart options={options} />
      </div>
    </Fragment>
  );
};

export default ScatterChart;
