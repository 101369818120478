import { Fragment, useState } from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
import images from "../../../../../../../../utilities/images/images";
import "react-toastify/dist/ReactToastify.css";

const BookmarkTestViewQuestions = (props) => {
  let {
    testQuestionsDetails,
    currentQuestion,
    handlePreviousQuestionBtn,
    handleNextQuestionBtn,
    handleQuestionJump,
    userId,
    handleBookmarkTestQuestion,
  } = props;

  const scrollUp2 = () => {
    var element = document.getElementsByClassName(
      "questions_serial_number_collection"
    )[0];
    element.scrollTo({
      top: `${(currentQuestion + 1) * 80 - 160}`,
      behavior: "smooth",
    });
  };
  const [videoShow, setVideoShow] = useState(false);

  const [lang, setLang] = useState(true);

  const renderOptions = (currentSolutionDetails, lang) => {
    if (currentSolutionDetails) {
      let step,
        type = currentSolutionDetails.answer_type;

      if (type.toLowerCase() === "single correct") {
        step = 1;
      } else if (type.toLowerCase() === "integer correct") {
        step = 2;
      } else {
        step = 3;
      }

      switch (step) {
        case 1:
          return renderSingleSelect(currentSolutionDetails, lang);
        case 2:
          return renderInputField(currentSolutionDetails, lang);
        case 3:
          return renderMultiSelect(currentSolutionDetails, lang);
        default:
          return null;
      }
    } else {
      return null;
    }
  };

  const classEditor = (s, u) => {
    if (s) {
      return "single_option_wrapper classCorrect";
    } else if (!s && u) {
      return "single_option_wrapper classWrong";
    } else {
      return "single_option_wrapper";
    }
  };

  const renderSingleSelect = (questionData, lang) => {
    if (lang == true) {
      return (
        <Fragment>
          <div className="single_select_option_collection_wrapper">
            {questionData?.option.length
              ? questionData.option.map((element, index) => {
                  return (
                    <div key={index} className="single_select">
                      <input
                        type="checkbox"
                        id={element.option_id}
                        name={`current_question_${currentQuestion}`}
                        disabled
                      />
                      <label
                        htmlFor={element.option_id}
                        className={classEditor(
                          element.is_correct,
                          element.is_user_correct_ans
                          // element.option_id
                        )}
                      >
                        <div className="option_initial">
                          <p className="text_content_2">{`${renderOptionInitial(
                            index
                          )}.`}</p>
                        </div>
                        <div className="option_final">
                          <p
                            className="text_content_3"
                            dangerouslySetInnerHTML={renderOptionText(
                              element.option
                            )}
                          ></p>
                        </div>
                      </label>
                    </div>
                  );
                })
              : null}
          </div>
        </Fragment>
      );
    } else if (lang == false) {
      return (
        <Fragment>
          <div className="single_select_option_collection_wrapper">
            {questionData?.option_hindi.length
              ? questionData.option_hindi.map((element, index) => {
                  return (
                    <div key={index} className="single_select">
                      <input
                        type="checkbox"
                        id={element.option_id}
                        name={`current_question_${currentQuestion}`}
                        disabled
                      />
                      <label
                        htmlFor={element.option_id}
                        className={classEditor(
                          element.is_correct,
                          element.is_user_correct_ans
                          // element.option_id
                        )}
                      >
                        <div className="option_initial">
                          <p className="text_content_2">{`${renderOptionInitial(
                            index
                          )}.`}</p>
                        </div>
                        <div className="option_final">
                          <p
                            className="text_content_3"
                            dangerouslySetInnerHTML={renderOptionText(
                              element.option
                            )}
                          ></p>
                        </div>
                      </label>
                    </div>
                  );
                })
              : null}
          </div>
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <div className="single_select_option_collection_wrapper">
            {questionData?.option.length
              ? questionData.option.map((element, index) => {
                  return (
                    <div key={index} className="single_select">
                      <input
                        type="checkbox"
                        id={element.option_id}
                        name={`current_question_${currentQuestion}`}
                        disabled
                      />
                      <label
                        htmlFor={element.option_id}
                        className={classEditor(
                          element.is_correct,
                          element.is_user_correct_ans
                          // element.option_id
                        )}
                      >
                        <div className="option_initial">
                          <p className="text_content_2">{`${renderOptionInitial(
                            index
                          )}.`}</p>
                        </div>
                        <div className="option_final">
                          <p
                            className="text_content_3"
                            dangerouslySetInnerHTML={renderOptionText(
                              element.option
                            )}
                          ></p>
                        </div>
                      </label>
                    </div>
                  );
                })
              : null}
          </div>
        </Fragment>
      );
    }
  };

  const renderMultiSelect = (questionData, lang) => {
    if (lang == true) {
      return (
        <Fragment>
          <div className="multi_select_option_collection_wrapper">
            {questionData?.option.length
              ? questionData.option.map((element, index) => {
                  return (
                    <div key={index} className="single_select">
                      <input
                        type="checkbox"
                        id={element.option_id}
                        name={`current_question_${currentQuestion}`}
                        disabled
                      />
                      <label
                        htmlFor={element.option_id}
                        className={classEditor(
                          element.is_correct,
                          element.is_user_correct_ans
                          // element.option_id
                        )}
                      >
                        <div className="option_initial">
                          <p className="text_content_2">{`${renderOptionInitial(
                            index
                          )}.`}</p>
                        </div>
                        <div className="option_final">
                          <p
                            className="text_content_3"
                            dangerouslySetInnerHTML={renderOptionText(
                              element.option
                            )}
                          ></p>
                        </div>
                      </label>
                    </div>
                  );
                })
              : null}
          </div>
        </Fragment>
      );
    } else if (lang == false) {
      return (
        <Fragment>
          <div className="multi_select_option_collection_wrapper">
            {questionData?.option_hindi.length
              ? questionData.option_hindi.map((element, index) => {
                  return (
                    <div key={index} className="single_select">
                      <input
                        type="checkbox"
                        id={element.option_id}
                        name={`current_question_${currentQuestion}`}
                        disabled
                      />
                      <label
                        htmlFor={element.option_id}
                        className={classEditor(
                          element.is_correct,
                          element.is_user_correct_ans
                          // element.option_id
                        )}
                      >
                        <div className="option_initial">
                          <p className="text_content_2">{`${renderOptionInitial(
                            index
                          )}.`}</p>
                        </div>
                        <div className="option_final">
                          <p
                            className="text_content_3"
                            dangerouslySetInnerHTML={renderOptionText(
                              element.option
                            )}
                          ></p>
                        </div>
                      </label>
                    </div>
                  );
                })
              : null}
          </div>
        </Fragment>
      );
    }
  };

  const renderInputField = () => {
    return (
      <Fragment>
        <div className="input_wrapper">
          <div className="input_inner_wrapper">
            <input
              key={currentQuestion}
              type="number"
              placeholder="Enter your answer"
              value={testQuestionsDetails[currentQuestion]?.integer_answer}
              //onChange={(event) => handleInputFieldChange(event)}
              disabled
            />
          </div>
        </div>
      </Fragment>
    );
  };

  const handleOnLoad = () => {
    let frame = document.querySelector("#iframe");
    frame.contentWindow.postMessage(
      `{"activate": true,"userId":"${userId}"}`,
      "*"
    );

    // //////jump to particular time///////////////////////
    // frame.contentWindow.postMessage(JSON.stringify({ seekTo: 1 }), "*");

    ////////////////testing///////////////////////////////////
    let eventMethod = window.addEventListener
      ? "addEventListener"
      : "attachEvent";
    let eventer = window[eventMethod];
    let messageEvent = eventMethod === "attachEvent" ? "onmessage" : "message";

    eventer(messageEvent, function (e) {
      if (e.data && e.data.currentTime) {
        let videoTimmingDetails = { currentTime: e.data.currentTime };
        sessionStorage.setItem(
          "videoTimmingDetails",
          JSON.stringify(videoTimmingDetails)
        );
      }
    });
  };

  const handleVideoShow = (videoShow) => {
    setVideoShow(!videoShow);
  };

  const renderVideo = () => {
    if (
      testQuestionsDetails[currentQuestion].video_id_english === "" ||
      testQuestionsDetails[currentQuestion].video_id_english === null
    ) {
      return " ";
    } else {
      return (
        <div className="videoWrapper">
          <button className="view_videoSolution_btn" onClick={handleVideoShow}>
            view video solution
          </button>

          {!videoShow ? (
            <iframe
              id="iframe"
              src={testQuestionsDetails[currentQuestion]?.video_id_english}
              onLoad={handleOnLoad}
              allow="accelerometer; autoplay; encrypted-media;gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          ) : null}
        </div>
      );
    }
  };

  const renderOptionInitial = (index) => {
    let alphabetArr = [
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
      "M",
      "N",
      "O",
      "P",
      "Q",
      "R",
      "S",
      "T",
      "U",
      "V",
      "W",
      "X",
      "Y",
      "Z",
    ];

    return alphabetArr[index];
  };

  const renderOptionText = (optionText) => {
    return { __html: optionText };
  };

  const renderQuestionLabel = (question, index) => {
    if (currentQuestion === index) {
      return (
        <label
          className="unanswered_lbl current_lbl"
          onClick={() => {
            handleQuestionJump(index);
            handleVideoShow(false);
          }}
        >
          <div className="questionBox">
            {`${index + 1}.`}
            <div className="QBox">
              <p
                dangerouslySetInnerHTML={{
                  __html: lang
                    ? question.q_text
                    : question.q_text_hindi !== null
                    ? question.q_text_hindi
                    : question.q_text,
                }}
              ></p>
            </div>
          </div>
          <img className="arrow" src={images.right_Ar} />
        </label>
      );
    } else {
      if (question.isAttempted) {
        if (question.isReview) {
          return (
            <label
              className="unanswered_lbl review_lbl"
              onClick={() => {
                handleQuestionJump(index);
                handleVideoShow(false);
              }}
            >
              <div className="questionBox">
                <div className="questionBox">
                  {`${index + 1}.`}
                  <div className="QBox">
                    <p
                      dangerouslySetInnerHTML={{
                        __html: question.q_text,
                      }}
                    ></p>
                  </div>
                </div>
                <img className="arrow" src={images.right_Ar} />
              </div>
            </label>
          );
        } else if (question.isAnswerGuessed) {
          return (
            <label
              className="unanswered_lbl isGussedAnswer"
              onClick={() => {
                handleQuestionJump(index);
                handleVideoShow(false);
              }}
            >
              <div className="questionBox">
                {`${index + 1}.`}
                <div className="QBox">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: question.q_text,
                    }}
                  ></p>
                </div>
              </div>
              <img className="arrow" src={images.right_Ar} />
            </label>
          );
        } else {
          return (
            <label
              className="unanswered_lbl answered_lbl"
              onClick={() => {
                handleQuestionJump(index);
                handleVideoShow(false);
              }}
            >
              <div className="questionBox">
                {`${index + 1}.`}
                <div className="QBox">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: question.q_text,
                    }}
                  ></p>
                </div>
              </div>
              <img className="arrow" src={images.right_Ar} />
            </label>
          );
        }
      } else if (question.isAnswerGuessed) {
        return (
          <label
            className="unanswered_lbl isGussedAnswer"
            onClick={() => {
              handleQuestionJump(index);
              handleVideoShow(false);
            }}
          >
            <div className="questionBox">
              {`${index + 1}.`}
              <div className="QBox">
                <p
                  dangerouslySetInnerHTML={{
                    __html: question.q_text,
                  }}
                ></p>
              </div>
            </div>
            <img className="arrow" src={images.right_Ar} />
          </label>
        );
      } else {
        if (question.isReview) {
          return (
            <label
              className="unanswered_lbl review_lbl"
              onClick={() => {
                handleQuestionJump(index);
                handleVideoShow(false);
              }}
            >
              <div className="questionBox">
                {`${index + 1}.`}
                <div className="QBox">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: question.q_text,
                    }}
                  ></p>
                </div>
              </div>
              <img className="arrow" src={images.right_Ar} />
            </label>
          );
        } else if (question.isSkipped) {
          return (
            <label
              className="unanswered_lbl skip_lbl"
              onClick={() => {
                handleQuestionJump(index);
                handleVideoShow(false);
              }}
            >
              <div className="questionBox">
                {`${index + 1}.`}
                <div className="QBox">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: question.q_text,
                    }}
                  ></p>
                </div>
              </div>
              <img className="arrow" src={images.right_Ar} />
            </label>
          );
        } else if (
          question.option?.filter((m) => m.is_user_correct_ans == 1).length ||
          question.integer_answer?.length
        ) {
          return (
            <label
              className="unanswered_lbl answered_lbl"
              onClick={() => {
                handleQuestionJump(index);
                handleVideoShow(false);
              }}
            >
              <div className="questionBox">
                {`${index + 1}.`}
                <div className="QBox">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: question.q_text,
                    }}
                  ></p>
                </div>
              </div>
              <img className="arrow" src={images.right_Ar} />
            </label>
          );
        } else {
          return (
            <label
              className="unanswered_lbl"
              onClick={() => {
                handleQuestionJump(index);
                handleVideoShow(false);
              }}
            >
              <div className="questionBox">
                {`${index + 1}.`}
                <div className="QBox">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: question.q_text,
                    }}
                  ></p>
                </div>
              </div>
              <img className="arrow" src={images.right_Ar} />
            </label>
          );
        }
      }
    }
  };

  const handleQuestionType = () => {
    if (testQuestionsDetails.length) {
      let questionsArr = testQuestionsDetails;
      return questionsArr[currentQuestion].answer_type;
    } else {
      return null;
    }
  };

  let progressCount =
    (100 / testQuestionsDetails.length + 1) * currentQuestion + 1;

  return (
    <Fragment>
      <div className="bookMark_test_questions_view_wrapper">
        <div className="test_questions_view_inner_wrapper">
          <div className="sec_2_wrapper">
            <div className="sec_2_inner_wrapper">
              <div className="section_content_wrapper">
                <div className="left_side_content_wrapper">
                  <div className="sub_section_1_wrapper">
                    <div className="text_content_wrapper">
                      <p className="text_content">All Bookmark Questions</p>
                    </div>

                    <div className="questions_serial_number_collection">
                      {testQuestionsDetails.length
                        ? testQuestionsDetails.map((element, index) => {
                            return (
                              <div
                                key={index}
                                className="single_question_number_wrapper"
                                id="myDiv"
                              >
                                {renderQuestionLabel(element, index)}
                              </div>
                            );
                          })
                        : null}
                    </div>
                  </div>
                </div>
                <div className="right_side_content_wrapper">
                  <div className="sub_section_wrapper">
                    <div className="testProgressIndicator">
                      <h4>Test Question progress Indicator</h4>
                      <div className="ProgressBar_Line">
                        <ProgressBar
                          now={progressCount}
                          className="progressBar"
                        />
                        <span style={{ left: progressCount - 1 + "%" }}>{`${
                          currentQuestion + 1
                        }`}</span>
                      </div>
                      <div className="questionPercentage">
                        <span>1</span>
                        <span>{testQuestionsDetails.length}</span>
                      </div>
                    </div>
                    <div className="text_content_wrapper">
                      <h4 className="text_content_wrapper_heading">
                        Saved Bookmark's
                      </h4>
                      <div className="typeLanguageWrapper">
                        <span>Question type: {handleQuestionType()}</span>

                        <div className="markReview">
                          {/* <div className="bookmark_image_wrapper">
                              <span>Mark For Review</span>
                              {testQuestionsDetails[currentQuestion] &&
                              testQuestionsDetails[currentQuestion]
                                .isAnswerGuessed ? (
                                <img
                                  src={images.user_activity_4}
                                  onClick={() => handleReview()}
                                />
                              ) : (
                                <img
                                  src={images.user_activity_3}
                                  onClick={() => handleReview()}
                                />
                              )}
                            </div> */}

                          <div className="image_wrapper" style={{cursor:"pointer"}}>
                            {testQuestionsDetails.map((element, index) => {
                              return (
                                <div key={index}>
                                  {index === currentQuestion && (
                                    <img
                                      src={images.user_activity_4}
                                      onClick={() =>
                                        handleBookmarkTestQuestion(element)
                                      }
                                    />
                                  )}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>

                      <div className="questionBox">
                        <span className="text_content">
                          {`${Number(currentQuestion) + 1}.`}
                        </span>
                        {testQuestionsDetails.length
                          ? testQuestionsDetails.map((element, index) => {
                              return (
                                <>
                                  <p
                                    className={
                                      index == currentQuestion && lang
                                        ? "show"
                                        : "hide"
                                    }
                                    key={element.qid + "en"}
                                    dangerouslySetInnerHTML={{
                                      __html: element.q_text,
                                    }}
                                  ></p>
                                  <p
                                    className={
                                      index == currentQuestion && !lang
                                        ? "show"
                                        : "hide"
                                    }
                                    key={element.qid + "hi"}
                                    dangerouslySetInnerHTML={{
                                      __html: element.q_text_hindi,
                                    }}
                                  ></p>
                                </>
                              );
                            })
                          : null}
                      </div>
                    </div>

                    <div className="sub_sub_section_wrapper_3">
                      {renderOptions(testQuestionsDetails[currentQuestion])}
                    </div>
                    <div className="sub_sub_section_wrapper_5">
                      <div className="left_side_wrapper">
                        {currentQuestion > 0 && (
                          <div className="btn_wrapper">
                            <button
                              onClick={() => {
                                handlePreviousQuestionBtn();
                                handleVideoShow(false);
                                scrollUp2();
                              }}
                            >
                              Previous
                            </button>
                          </div>
                        )}
                      </div>

                      <div className="right_side_wrapper">
                        <div className="btn_wrapper">
                          <button
                            onClick={() => {
                              handleNextQuestionBtn();
                              handleVideoShow(false);
                              scrollUp2();
                            }}
                          >
                            Next
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="solutionBoxWrapper">
                      <p className="text_content">Explanation:</p>
                      {testQuestionsDetails.length
                        ? testQuestionsDetails.map((element, index) => {
                            return (
                              <>
                                <p
                                  className={
                                    index == currentQuestion && lang
                                      ? "show"
                                      : "hide"
                                  }
                                  key={element.qid + "en"}
                                  dangerouslySetInnerHTML={{
                                    __html: element.sol_text,
                                  }}
                                ></p>
                                <p
                                  className={
                                    index == currentQuestion && !lang
                                      ? "show"
                                      : "hide"
                                  }
                                  key={element.qid + "hi"}
                                  dangerouslySetInnerHTML={{
                                    __html: element.solution_hindi,
                                  }}
                                ></p>
                              </>
                            );
                          })
                        : null}
                      {testQuestionsDetails.length && renderVideo()}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default BookmarkTestViewQuestions;
