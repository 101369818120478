import React from "react";
import { connect } from "react-redux";

import { useHistory } from "react-router-dom";

import CustomModal from "../../components/Modal/CustomModal";
import images from "../../utilities/images/images";
import "./MultipleLoginDetectModal.scss";

import uiActions from "../../redux/ui/action";
import authActions from "../../redux/auth/action";
import userPreferenceActions from "../../redux/user_preference/action";
import userActivityActions from "../../redux/user_activity/action";
import userTestsExercisesAction from "../../redux/user_tests_exercises/action";
import userTwilioDetailsAction from "../../redux/twilio_chat/action";
import userBookmarkAction from "../../redux/user_bookmark/action";

const MultipleLoginDetectModal = (props) => {

  const { 
    isMultipleLogin,

    logoutUser,
    removeAnimation,
    removePreferenceData,
    removeActivityData,
    removeTestsExercisesData,
    removeTwilioDetails,
    removeBookmarkData,


  } = props;

  const history = useHistory();

  const handleUserLogout = () => {
    logoutUser();
    removeAnimation();
    removePreferenceData();
    removeActivityData();
    removeTestsExercisesData();
    removeTwilioDetails();
    removeBookmarkData();
    history.push("/");
  };
  return (
    <CustomModal
      show={isMultipleLogin}
      clsClass="btn-hide"
      dialogClassName="multiple-login-modal"
    >
      <img src={images.MutipleLogin} className="multiple-login-image" alt="multiple-login-popup" />
      <div className="modal-text">Multiple Login detected</div>
      <div className="multiple-login-more-info">
      You've been logged out of this device as you've logged in from another device. Please log in again.
      </div>

      <div  className="multipleLoginLogoutBtn" onClick={handleUserLogout}>
        Logout
      </div>
    </CustomModal>
  );
};

const mapStateToProps = (state) => {
  return {
    // isMultipleLogin: state.MultipleLoginDetectSlice.isMultipleLogin,
  };
};


 
const mapDispatchToProps = (dispatch) => {
  return {
    logoutUser: () => {
      dispatch(authActions.logoutUser());
    },
    removeAnimation: () => {
      dispatch(uiActions.removeAnimation());
    },
    removeActivityData: () => {
      dispatch(userActivityActions.removeActivityData());
    },
    removePreferenceData: () => {
      dispatch(userPreferenceActions.removePreferenceData());
    },
    removeTestsExercisesData: () => {
      dispatch(userTestsExercisesAction.removeTestsExercisesData());
    },
    removeTwilioDetails: () => {
      dispatch(userTwilioDetailsAction.removeTwilioDetails());
    },
    removeBookmarkData: () => {
      dispatch(userBookmarkAction.removeBookmarkData());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MultipleLoginDetectModal);
