import React, { useState, useEffect } from "react";
import "./FillingAddress.scss";
import { useHistory } from "react-router-dom";
import images from "../../../../../../../utilities/images/images";
import YourOrderComponent from "../yourOrderComponent/YourOrderComponent";
import axios from "axios";
import OrderReview from "../orderReview/OrderReview";
import { isEmpty } from "../../../../../../../custom_utilities/custom_useful_functions";
import {
  url_166,
  url_165,
} from "../../../../../../../custom_utilities/api_services";
import { connect } from "react-redux";
import uiActions from "../../../../../../../redux/ui/action";
import { Button } from "react-bootstrap";

const FillingAddress = (props) => {
  const {
    startLoading,
    finishLoading,
    handleClickNext,
    handleClickPrev,
    singleProduct,
    handleStep1,
    cartDetails,
    handleStep2,
    flag,
    step,
    tokenType,
    token,
  } = props;

  const [formView, setFormView] = useState(false);
  const [btnView, setBtnView] = useState(false);
  const [overReview, setOverReview] = useState(false);

  const [state, setState] = useState({
    savedAddress: [],
    selectedAddress: "",
    formSubmit: {},
    lastTwo: [],
    success: "",
    step: step,
  });

  const [checkoutInput, setCheckoutInput] = useState({
    fullname: "",
    phone: "",
    address: "",
    landMark: "",
    city: "",
    state: "",
    zipcode: "",
  });

  const isDisable = state.selectedAddress.length == "" ? true : false;

  useEffect(() => {
    getAddress();
  }, [state.stopAddress]);

  const handleInput = (e) => {
    e.persist();
    setCheckoutInput({ ...checkoutInput, [e.target.name]: e.target.value });
  };

  const selectData = () => {
    let { fullname, phone, address, landMark, city, state, zipcode } =
      checkoutInput;

    if (fullname.trim().length === 0) {
      alert("Please enter a fullname");
      return 0;
    } else if (phone.trim().length === 0) {
      alert("Please enter a phone number");
      return 0;
    } else if (address.trim().length === 0) {
      alert("Please enter a address");
      return 0;
    } else if (landMark.trim().length === 0) {
      alert("Please enter a landMark");
      return 0;
    } else if (city.trim().length === 0) {
      alert("Please enter a city");
      return 0;
    } else if (state.trim().length === 0) {
      alert("Please enter a state");
      return 0;
    } else if (zipcode.trim().length === 0) {
      alert("Please enter a zipcode");
      return 0;
    } else {
      return 1;
    }
  };

  const submitOrder = async () => {
    startLoading();
    const headers = {
      Authorization: `${tokenType} ${token}`,
    };
    const result = await selectData();
    if (result) {
      var data = {
        name: checkoutInput.fullname,
        mobile: checkoutInput.phone,
        address: checkoutInput.address,
        landmark: checkoutInput.landMark,
        city: checkoutInput.city,
        state: checkoutInput.state,
        pincode: checkoutInput.zipcode,
      };
      try {
        const response = await axios.post(url_166, data, { headers });
        if (response.data.status === 200) {
          finishLoading();
          setState((prevState) => ({
            ...prevState,
            success: response.data.status,
            formSubmit: response.data.data,
          }));
        }
        getAddress();
      } catch (error) {
        finishLoading();
        console.log(error);
      }
    }
  };

  const getAddress = async () => {
    startLoading();
    const headers = {
      Authorization: `${tokenType} ${token}`,
    };
    try {
      const response = await axios.post(url_165, { headers });
      if (response.data.status === 200) {
        finishLoading();
        setState((prevState) => ({
          ...prevState,
          savedAddress: response.data,
          lastTwo: response.data.address.slice(-3),
        }));
      }
    } catch (error) {
      finishLoading();
      console.log(error);
    }
  };

  const handleForm = () => {
    setFormView(!formView);
    setBtnView(!btnView);
  };

  const handleChangeAddress = (item) => {
    setState((prevState) => ({
      ...prevState,
      selectedAddress: item,
    }));
  };

  const renderForm = () => {
    return (
      <div className="card-body">
        <div className="row">
          <div className="col-md-6">
            <div className="form-group mb-3">
              <label> Full Name</label>
              <input
                type="text"
                name="fullname"
                onChange={handleInput}
                value={checkoutInput.firstname}
                className="form-control"
                // placeholder="Ran Billing"
              />
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group mb-3">
              <label> Phone Number</label>
              <input
                type="number"
                name="phone"
                onChange={handleInput}
                value={checkoutInput.phone}
                className="form-control"
              />
            </div>
          </div>

          <div className="col-md-12">
            <div className="form-group mb-3">
              <label> Full Address</label>
              <textarea
                rows="2"
                name="address"
                onChange={handleInput}
                value={checkoutInput.address}
                className="form-control"
              ></textarea>
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group mb-3">
              <label>Land Mark</label>
              <input
                type="text"
                name="landMark"
                onChange={handleInput}
                value={checkoutInput.landMark}
                className="form-control"
              />
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group mb-3">
              <label>City</label>
              <input
                type="text"
                name="city"
                onChange={handleInput}
                value={checkoutInput.city}
                className="form-control"
              />
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group mb-3">
              <label>State</label>
              <input
                type="text"
                name="state"
                onChange={handleInput}
                value={checkoutInput.state}
                className="form-control"
              />
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group mb-3">
              <label>Pin Code</label>
              <input
                type="text"
                name="zipcode"
                onChange={handleInput}
                value={checkoutInput.zipcode}
                className="form-control"
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const handleReview = async () => {
    getAddress();
    handleClickNext();
    setOverReview(!overReview);
    setFormView(!formView);
    setBtnView(!btnView);
  };

  const handleReview3 = () => {
    handleClickPrev();
    setOverReview(!overReview);
    setFormView(!formView);
    setBtnView(!btnView);
  };

  const handleReview2 = async () => {
    const result = await selectData();
    if (result) {
      setState((prevState) => ({
        ...prevState,
        selectedAddress: "",
      }));
      submitOrder();
      handleClickNext();
      setOverReview(!overReview);
      setFormView(formView);
    }
  };

  return (
    <div>
      {!overReview && (
        <div>
          <div className="backBtn_content">
            <div className="backBtn" onClick={handleStep1}>
              {" "}
              <img src={images.m2_backBtn} alt="backBtn" />
            </div>
            <div className="backBtn_text">Back to Cart</div>
          </div>

          <section className="filling_address_container">
            <div className="left">
              <div className="section_heading">Delivery Address</div>
              <div className="form">{formView && renderForm()}</div>
              {!btnView && (
                <div>
                  <div className="savedAddress">
                    {!isEmpty(state.lastTwo)
                      ? state.lastTwo.length
                        ? state.lastTwo.map((item) => {
                            return (
                              <div className="card">
                                <div className="card-body">
                                  <input
                                    type="checkbox"
                                    className="larger"
                                    key={item.id}
                                    value={item.id}
                                    onChange={() => handleChangeAddress(item)}
                                    checked={
                                      item.id === state.selectedAddress.id
                                    }
                                  ></input>

                                  <div className="savedAddress_content">
                                    <div className="top">
                                      <span className="fullName">
                                        {item.fullname} |
                                      </span>
                                      <span className="phoneNum">
                                        {item.mobile}
                                      </span>
                                    </div>
                                    <div className="bottom">
                                      <span>{item.address}/ </span>
                                      <span>{item.landmark}, </span>
                                      <span>{item.city}, </span>
                                      <span>{item.state}, </span>
                                      <span>{item.pincode}, </span>
                                    </div>
                                  </div>

                                  {/* <div className="edit_del">
                                  <div className="edit">edit</div>
                                  <div className="del">del</div>
                                </div> */}
                                </div>
                              </div>
                            );
                          })
                        : null
                      : null}
                  </div>
                  <div className="addAddressBtn">
                    <button onClick={handleForm}>Add New Address</button>
                  </div>
                </div>
              )}
            </div>

            <div className="right">
              {flag ? (
                <YourOrderComponent cartDetails={cartDetails} />
              ) : (
                <YourOrderComponent singleProduct={singleProduct} />
              )}

              <div className="checkOutBtn">
                {/* {singleProduct ? (
                  !formView ? (
                    <Button disabled={isDisable} onClick={handleReview}>
                      Proceed
                    </Button>
                  ) : (
                    <Button onClick={handleReview2}>
                      Proceed to check out
                    </Button>
                  )
                ) : !formView ? (
                  <Button disabled={isDisable} onClick={handleReview}>
                    Proceed
                  </Button>
                ) : (
                  <Button onClick={handleReview2}>Proceed to check out</Button>
                )} */}

                {!formView ? (
                  <Button disabled={isDisable} onClick={handleReview}>
                    Proceed
                  </Button>
                ) : (
                  <Button onClick={handleReview2}>Proceed to check out</Button>
                )}
              </div>
            </div>
          </section>
        </div>
      )}

      {overReview && (
        <OrderReview
          handleReview={handleReview3}
          selectedAddress={state.selectedAddress}
          checkoutInput={checkoutInput}
          singleProduct={singleProduct}
          cartDetails={cartDetails}
          handleStep1={handleStep1}
          handleStep2={handleStep2}
          flag={flag}
          getAddress={getAddress}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loader: state.ui.loader,
    token: state.auth.authToken,
    tokenType: state.auth.tokenType,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => {
      dispatch(uiActions.startLoading());
    },
    finishLoading: () => {
      dispatch(uiActions.finishLoading());
    },
  };
};

export default connect(null, mapDispatchToProps)(FillingAddress);
