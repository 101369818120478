import React, { memo } from "react";
import "./QueSwitcher.scss";

const QuestionSwitcher = memo(
  ({
    showSolution,
    updateSolution,
    solDisabled,
    currentQuestion,
    totalQuestions,
    handlePreviousQuestionBtn,
    handleNextQuestionBtn,
    scrollUp,
    scrollUp2,
    sol,
    handleSaveQuestion,
    handleShow,
    handleSolution,
    disabled1,
    testQuestionsDetails,
    handleSol,
    setVideoShow,
  }) => {
    const handlePurticularQuestion = () => {
      if (
        testQuestionsDetails[currentQuestion - 1].userAnswerCollection.length ||
        testQuestionsDetails[currentQuestion - 1].isAttempted === true
      ) {
        handleSolution();
      } else {
        handleSol();
      }
    };

    const handlePurticularQuestion3 = () => {
      if (
        testQuestionsDetails[currentQuestion + 1].userAnswerCollection.length ||
        testQuestionsDetails[currentQuestion + 1].isAttempted === true
      ) {
        handleSolution();
      } else {
        handleSol();
      }
    };

    const handlePurticularQuestion2 = (currentQuestion) => {
      if (testQuestionsDetails[currentQuestion].userAnswerCollection.length) {
        handleSolution();
      } else {
        handleSol();
      }
    };

    return (
      <div className="sub_sub_section_wrapper_5">
        <div style={{width:"30%"}} className="left_side_wrapper">
          {currentQuestion > 0 && (
            <div className="btn_wrapper">
              <button
                onClick={() => {
                  handlePreviousQuestionBtn();
                  scrollUp2();
                  handlePurticularQuestion();
                  setVideoShow(false);
                  showSolution(currentQuestion-1);
                }}
              >
                Previous
              </button>
            </div>
          )}
        </div>

        <div style={{width:"70%"}} className="right_side_wrapper">
          {totalQuestions === currentQuestion + 1 ? (
            <div className="btn_wrapper d-flex">
                <button
                  disabled={solDisabled}
                  className={solDisabled ? "opacity solution" : "true solution"}
                  onClick={() => {
                    updateSolution(true);
                    handleSolution();
                  }}
                >
                  Solution
                </button>
                <button
                  onClick={() => {
                    handleSaveQuestion();
                    handleShow();
                  }}
                >
                  Submit
                </button>
            </div>
          ) : (
            <div className="btn_wrapper d-flex">
                <button
                  disabled={solDisabled}
                  className={solDisabled ? "opacity solution" : "true solution"}
                  onClick={() => {
                    updateSolution(true);
                    handleSolution();
                  }}
                >
                  Solution
                </button>
                <button
                  onClick={() => {
                    handleNextQuestionBtn();
                    scrollUp();
                    handlePurticularQuestion3();
                    setVideoShow(false);
                    showSolution(currentQuestion+1);
                  }}
                >
                  Next
                </button>
            </div>
          )}
        </div>
      </div>
    );
  }
);

export default QuestionSwitcher;
