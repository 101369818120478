import React, { useEffect, useState, useLocation } from "react";
import "./SubjectWiseMarks.scss";
import axios from "axios";
import SubjectWiseMarkChart from "../../../../../../../../components/canvas_charts/canvas_doughnut_chart/SubjectWiseMarks";
import RankDoughnutChart from "../../../../../../../../components/canvas_charts/canvas_doughnut_chart/RankDoughnutChart";

import { isEmpty } from "../../../../../../../../custom_utilities/custom_useful_functions";

import { url_70 } from "../../../../../../../../custom_utilities/api_services";
import uiActions from "../../../../../../../../redux/ui/action";
import { connect } from "react-redux";

const SubjectWiseMarks = (props) => {
  const { location, startLoading, finishLoading } = props;

  const [state, setState] = useState({
    subjectWiseMarks: {},
  });

  useEffect(() => {
    getSbjctWiseMarks();
  }, []);

  const formatTime = (time) => {
    time = time.replace("minutes", "min");
    time = time.replace("seconds", "sec");
    return time;
  };

  const getSbjctWiseMarks = async () => {
    let data = {
      paper_id: location.state.reportDataDetails.PaperData.paper_id,
    };
    startLoading();
    try {
      const response = await axios.post(url_70, data);
      if (response.data.status === 200) {
        setState((prevState) => ({
          ...prevState,
          subjectWiseMarks: response.data.data,
        }));
        finishLoading();
      }
    } catch (error) {
      console.log(error);
      finishLoading();
    }
  };
  return (
    <div className="subjectWiseMarksBox">
      <div className="subjectMarksSmry">
        <div className="marksChart">
          {!isEmpty(state.subjectWiseMarks) ? (
            state.subjectWiseMarks.UserSubjectMarksAnalysis.length ? (
              <SubjectWiseMarkChart
                dataPoints={state.subjectWiseMarks.UserSubjectMarksAnalysis}
              />
            ) : null
          ) : null}
        </div>
        <div className="marksSummary">
          <ul>
            {state.subjectWiseMarks.Summary &&
              state.subjectWiseMarks.Summary.map((element, index) => {
                return (
                  <li key={index}>
                    <span>{element.label}</span>
                    <span>
                      {element.label === "Time Spent"
                        ? formatTime(element.value)
                        : element.label === "Average Score"
                        ? element.value.toFixed(2)
                        : element.value}
                    </span>
                  </li>
                );
              })}
          </ul>
        </div>
        <div className="rank">
          {!isEmpty(state.subjectWiseMarks) ? (
            <RankDoughnutChart dataPoints={location.state.reportDataDetails} />
          ) : null}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loader: state.ui.loader,
    userData: state.auth.user,
    userPreference: state.userPreference,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => {
      dispatch(uiActions.startLoading());
    },
    finishLoading: () => {
      dispatch(uiActions.finishLoading());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SubjectWiseMarks);
