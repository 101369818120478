import * as actionTypes from "./action_type";

const initialState = {
  mockTestData: {},
  mockResultData: {},
};

const mockTestsReducer = (state = initialState, { type, payload = null }) => {
  switch (type) {
    case actionTypes.SET_MOCK_TESTS_DATA:
      return setMockTestsData(state, payload);

    case actionTypes.SET_MOCK_TESTS_RESULT_DATA:
      return setMockTestsResultData(state, payload);
    default:
      return state;
  }
};

const setMockTestsData = (state, payload) => {
  state = { ...state, mockTestData: payload };
  return state;
};

const setMockTestsResultData = (state, payload) => {
  state = { ...state, mockResultData: payload };
  return state;
};

export default mockTestsReducer;
