import React, { useState, useEffect, useMemo } from "react";
import "./QuestionViewComponent.scss";
import QuestionViewContentComponent from "./questionViewContentComponent/QuestionViewContentComponent";
import axios from "axios";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import uiActions from "../../../../../redux/ui/action";
import LoaderPopUp from "../../../../../components/loader/loader";

const QuestionViewComponent = ({
  parentUserId,
  startLoading,
  finishLoading,
  loader,
}) => {
  const location = useLocation();

  const currentSubject = location.state.currentSubject;
  const planner_test_id = location?.state?.plannerTestId;
  const parentPaperId = location?.state?.parentPaperId;
  const paperIdd = location?.state?.paperIdd;
  const userId = location?.state?.userId;

  const [state, setState] = useState({
    testQuestionsDetails: {},
    currentSolutionDetails: {},
    page: 1,
    currentQuestion: 0,
  });

  const { testQuestionsDetails, currentQuestion } = state;

  useEffect(() => {
    getQuestionList();
  }, [parentUserId, planner_test_id]);

  const memoizedTestQuestionsDetails = useMemo(
    () => state.testQuestionsDetails,
    [state.testQuestionsDetails]
  );

  const memoizedCurrentSolutionDetails = useMemo(
    () => state.currentSolutionDetails,
    [state.currentSolutionDetails]
  );

  const getQuestionList = async () => {
    startLoading();

    const data = {
      subject: currentSubject,
      user_id: parentUserId || userId,
      paper_id: parentPaperId || paperIdd,
      planner_test_id,
    };

    console.log("getQuestionList", data);

    try {
      const response = await axios.post(
        "https://learning.motion.ac.in/motioneducation/api/getquestionlist",
        data
      );

      if (response.data.status === 200) {
        const currentQuestionData = response.data.data[state.currentQuestion];
        getCurrentSolutionDetails(
          currentQuestionData.paper_id,
          currentQuestionData.qid
        );

        setState((prevState) => ({
          ...prevState,
          testQuestionsDetails: response.data,
        }));
        finishLoading();
      }
    } catch (error) {
      finishLoading();
      console.log(error);
    }
  };

  const getCurrentSolutionDetails = async (paper_id, qid) => {
    startLoading();
    const data = {
      subject: currentSubject,
      user_id: parentUserId || userId,
      paper_id: parentPaperId || paperIdd,
      planner_test_id,
      qid,
    };

    try {
      const response = await axios.get(
        `https://learning.motion.ac.in/motioneducation/api/getviewsolution?subject=${data.subject}&paper_id=${data.paper_id}&planner_test_id=${data.planner_test_id}&user_id=${data.user_id}&qid=${data.qid}`
      );

      if (response.data.status === 200) {
        setState((prevState) => ({
          ...prevState,
          currentSolutionDetails: response.data.data[0],
        }));
        finishLoading();
      }
    } catch (error) {
      finishLoading();
      console.log(error);
    }
  };

  const handleNextQuestionBtn = (currentQuestion2) => {
    const { paper_id, qid } = testQuestionsDetails.data[currentQuestion2];
    getCurrentSolutionDetails(paper_id, qid);
    setState((prevState) => ({
      ...prevState,
      currentQuestion: currentQuestion + 1,
    }));
    window.scrollTo(0, 0);
  };

  const handlePreviousQuestionBtn = (currentQuestion2) => {
    const { paper_id, qid } = testQuestionsDetails.data[currentQuestion2];
    getCurrentSolutionDetails(paper_id, qid);
    setState((prevState) => ({
      ...prevState,
      currentQuestion: currentQuestion - 1,
    }));
    window.scrollTo(0, 0);
  };

  const handleQuestionJump = (questionNumber) => {
    const { paper_id, qid } = testQuestionsDetails.data[questionNumber];
    getCurrentSolutionDetails(paper_id, qid);

    setState((prevState) => ({
      ...prevState,
      currentQuestion: questionNumber,
    }));
  };

  return (
    <div className="test_component_wrapper">
      <div className="test_component_inner_wrapper">
        <QuestionViewContentComponent
          testQuestionsDetails={memoizedTestQuestionsDetails}
          currentSolutionDetails={memoizedCurrentSolutionDetails}
          currentQuestion={currentQuestion}
          handleNextQuestionBtn={handleNextQuestionBtn}
          handlePreviousQuestionBtn={handlePreviousQuestionBtn}
          handleQuestionJump={handleQuestionJump}
          paperIdd={paperIdd}
          userId={userId}
        />
        {loader && <LoaderPopUp />}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loader: state.ui.loader,
    parentUserId: state.parentrootReducer.parentAuthReducer.userId,
  };
};

const mapDipatchToProps = (dispatch) => {
  return {
    startLoading: () => {
      dispatch(uiActions.startLoading());
    },
    finishLoading: () => {
      dispatch(uiActions.finishLoading());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDipatchToProps
)(QuestionViewComponent);
