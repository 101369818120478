import { Fragment } from "react";
import "./left_sideBar_component.scss";

import images from "../../../../../utilities/images/images";
import { Link, NavLink } from "react-router-dom";

const LeftSideBarComponent = () => {
  return (
    <Fragment>
      <div className="left_sideBar_wrapper">
        <div className="left_sideBar_inner_wrapper">
          <div className="sec_1_wrppaer">
            <div className="sec_1_inner_wrapper">
              <div className="image_wrapper">
                <Link to="/user_dashboard">
                  <img src={images.motion_logo_svg} />
                </Link>
              </div>
            </div>
          </div>
          <div className="sec_2_wrapper">
            <div className="sec_2_inner_wrapper">
              <ul>
                <li>
                  <NavLink to="/user_dashboard">
                    <div className="wrapping_container">
                      <img className="active_icon" src={images.active_home} />
                      <img
                        className="inactive_icon"
                        src={images.inactive_home}
                      />
                      <span className="heading_tag">Home</span>
                    </div>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/user_exercise_test">
                    <div className="wrapping_container">
                      <img
                        className="active_icon"
                        src={images.active_exercise}
                      />
                      <img
                        className="inactive_icon"
                        src={images.inactive_exercise}
                      />
                      <span className="heading_tag">Exercise & Test</span>
                    </div>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/doubts">
                    <div className="wrapping_container">
                      <img className="active_icon" src={images.active_doubts} />
                      <img
                        className="inactive_icon"
                        src={images.inactive_doubts}
                      />
                      <span className="heading_tag">Doubts</span>
                    </div>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/user_analytics">
                    <div className="wrapping_container">
                      <img
                        className="active_icon"
                        src={images.active_analytics}
                      />
                      <img
                        className="inactive_icon"
                        src={images.inactive_analytics}
                      />
                      <span className="heading_tag">Analytics</span>
                    </div>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/user_bookmarks">
                    <div className="wrapping_container">
                      <img
                        className="active_icon"
                        src={images.active_bookmark}
                      />
                      <img
                        className="inactive_icon"
                        src={images.inactive_bookmark}
                      />
                      <span className="heading_tag">Bookmarks</span>
                    </div>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/store">
                    <div className="wrapping_container">
                      <img className="active_icon" src={images.active_store} />
                      <img
                        className="inactive_icon"
                        src={images.inactive_store}
                      />
                      <span className="heading_tag">Store</span>
                    </div>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/transactions">
                    <div className="wrapping_container">
                      <img
                        className="active_icon"
                        src={images.active_transaction}
                      />
                      <img
                        className="inactive_icon"
                        src={images.inactive_transaction}
                      />
                      <span className="heading_tag">Transactions</span>
                    </div>
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
          <div className="sec_3_wrapper">
            <div className="sec_3_inner_wrapper">
              <ul>
                <li>
                  <NavLink to="/about_us">
                    <div className="wrapping_container">
                      <span className="heading_tag">About Us</span>
                    </div>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/privacy_policy">
                    <div className="wrapping_container">
                      <span className="heading_tag">Privacy Policy</span>
                    </div>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/terms_n_condition">
                    <div className="wrapping_container">
                      <span className="heading_tag">Terms & Condition</span>
                    </div>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/faqs">
                    <div className="wrapping_container">
                      <span className="heading_tag">FAQ's</span>
                    </div>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/contact_us">
                    <div className="wrapping_container">
                      <span className="heading_tag">Contact Us</span>
                    </div>
                  </NavLink>
                </li>
                <li>
                  <NavLink to={{pathname:"https://play.google.com/store/apps/details?id=www.motion.ac.in.student.parent"}} target="_blank">
                    <div className="wrapping_container">
                      <span className="heading_tag">Parent Connect</span>
                    </div>
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default LeftSideBarComponent;
