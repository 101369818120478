// import firebase from "firebase/app";
// import "firebase/messaging";

// // For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: "AIzaSyCtfAms4B6vHtMqxlN6m3PGYEMFPDfPmUE",
//   authDomain: "motion-d4e51.firebaseapp.com",
//   projectId: "motion-d4e51",
//   storageBucket: "motion-d4e51.appspot.com",
//   messagingSenderId: "952067818762",
//   appId: "1:952067818762:web:af332a108d71cd71f366f3",
//   measurementId: "G-4C4KS5D4S0",
// };

// firebase.initializeApp(firebaseConfig);

// export default firebase;


// import firebase from "firebase/app";
// import "firebase/messaging";

// // For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: "AIzaSyCtfAms4B6vHtMqxlN6m3PGYEMFPDfPmUE",
//   authDomain: "motion-d4e51.firebaseapp.com",
//   projectId: "motion-d4e51",
//   storageBucket: "motion-d4e51.appspot.com",
//   messagingSenderId: "952067818762",
//   appId: "1:952067818762:web:af332a108d71cd71f366f3",
//   measurementId: "G-4C4KS5D4S0",
// };

// firebase.initializeApp(firebaseConfig);

// export default firebase;



// Import the functions you need from the SDKs you need
import firebase from "firebase/app";
import "firebase/analytics";

import "firebase/messaging";
import { getAnalytics } from 'firebase/analytics';




// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAcpbyhqxwd2qwu0ZASHu9rdDeKptCjzr0",
  authDomain: "motion-web-99974.firebaseapp.com",
  projectId: "motion-web-99974",
  storageBucket: "motion-web-99974.appspot.com",
  messagingSenderId: "862110803396",
  appId: "1:862110803396:web:c240f4669b014ee2487091",
  measurementId: "G-S420HTJTPR"
};

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);
const analytics = firebase.analytics();

export default firebase;