import { Fragment } from "react";
import { useHistory } from "react-router-dom";
import LoaderPopUp from "../../../../../../components/loader/loader";
import "./OtsTestResultContentComponent.scss";

//mobileDevice
import MobileLeftSideBarComponent from "../../../../structure_components/mobile_screens/mobile_left_sideBar_component/mobile_left_sideBar_component";
import MobileHeaderComponent from "../../../../structure_components/mobile_screens/mobile_header_component/mobile_header_component";
import MobileRightSideBarComponent from "../../../../structure_components/mobile_screens/mobile_right_sideBar_component/mobile__right_sideBar_component";
import HeaderComponent from "../../../../structure_components/desktop_screens/header_component/header_component";
import OtsTestResultContentComponent from "./OtsTestResultContentComponent";
//Redux
import { connect } from "react-redux";

const OtsTestResultComponent = (props) => {
  const history = useHistory();
  let { loader, userData, userPreference } = props;

  const isMob = () => {
    if (window.innerWidth <= 480) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Fragment>
      <div className="test_result_component_wrapper">
        <div className="test_result_component_inner_wrapper">
          {isMob() ? (
            <Fragment>
              <MobileLeftSideBarComponent />
              <MobileHeaderComponent
                userPreference={userPreference}
                userData={userData}
              />
              <MobileRightSideBarComponent history={history} />
              <OtsTestResultContentComponent history={history} />
            </Fragment>
          ) : (
            <Fragment>
              <HeaderComponent />
              <OtsTestResultContentComponent history={history} />
            </Fragment>
          )}
        </div>
      </div>
      {loader && <LoaderPopUp />}
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    loader: state.ui.loader,
    userData: state.auth.user,
    userPreference: state.userPreference,
  };
};

export default connect(mapStateToProps)(OtsTestResultComponent);
