import React, { useState } from "react";
import AdaptiveCpsTestQuestionsView from "./adaptiveCpsTestQuestionsView/AdaptiveCpsTestQuestionsView";

const AdaptiveCpsTestQuestions = (props) => {
  const {
    testQuestionsDetails,
    selectedSubject,
    selectedSection,
    handleSubjectChange,
    handleSectionChange,
    currentQuestion,
    handlePreviousQuestionBtn,
    handleNextQuestionBtn,
    handleSaveQuestion,
    handleSingleSelectAnswer,
    handleMultiSelectAnswer,
    handleInputFieldChange,
    handleReview,
    handleSaved,
    handleQuestionJump,
    handleSubmitTest,
    checkQuestion,
    userId,
    startLoading,
    finishLoading,
  } = props;

  return (
    <AdaptiveCpsTestQuestionsView
      testQuestionsDetails={testQuestionsDetails}
      selectedSubject={selectedSubject}
      selectedSection={selectedSection}
      handleSubjectChange={handleSubjectChange}
      handleSectionChange={handleSectionChange}
      currentQuestion={currentQuestion}
      handlePreviousQuestionBtn={handlePreviousQuestionBtn}
      handleNextQuestionBtn={handleNextQuestionBtn}
      handleSaveQuestion={handleSaveQuestion}
      handleSingleSelectAnswer={handleSingleSelectAnswer}
      handleMultiSelectAnswer={handleMultiSelectAnswer}
      handleInputFieldChange={handleInputFieldChange}
      handleReview={handleReview}
      handleSaved={handleSaved}
      handleQuestionJump={handleQuestionJump}
      handleSubmitTest={handleSubmitTest}
      checkQuestion={checkQuestion}
      userId={userId}
      startLoading={startLoading}
      finishLoading={finishLoading}
    />
  );
};

export default AdaptiveCpsTestQuestions;
