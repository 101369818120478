import React, { Fragment, useState, useEffect } from "react";
import "./ReferAndEarn.scss";
import { url_184, url_220 } from "../../../custom_utilities/api_services";
import CourseEnrollmentTermsAndConditions from "./courseEnrollmentTermsAndConditions/CourseEnrollmentTermsAndConditions";
import axios from "axios";
import { connect } from "react-redux";
import Footer_component from "../structure_components/desktop_screens/footer_component/Footer_component";
import Header_component from "../structure_components/desktop_screens/header_component/header_component";
import Mobile__right_sideBar_component from "../structure_components/mobile_screens/mobile_right_sideBar_component/mobile__right_sideBar_component";
import Mobile_header_component from "../structure_components/mobile_screens/mobile_header_component/mobile_header_component";
import MobileLeftSideBarComponent from "../structure_components/mobile_screens/mobile_left_sideBar_component/mobile_left_sideBar_component";
import { REFER_AND_EARN_TYPES } from "./utils";
import LoaderPopUp from "../../../components/loader/loader";
import ReferAndEarnContent from "./ReferAndEarnContent";

const ReferAndEarn = ({
  token,
  tokenType,
  userData,
  userPreference,
  history,
  loader,
}) => {
  const [data, setData] = useState({});
  const [referAndEarnData, setReferAndEarnData] = useState({});
  const [referAndEarnType, setReferAndEarnType] = useState(
    "refer_and_earn_data"
  );
  const [referalListSize, setReferalListSize] = useState("referal_list_small");

  const handleTermsClick = () => {
    setReferAndEarnType(REFER_AND_EARN_TYPES.TERMS_AND_CONDITION);
  };

  const handleBackBtn = () => {
    setReferAndEarnType(REFER_AND_EARN_TYPES.REFER_AND_EARN_DATA);
    setReferalListSize("referal_list_small");
  };

  const getReferalCode = async () => {
    const headers = {
      Authorization: `${tokenType} ${token}`,
    };
    try {
      const response = await axios.get(url_184, headers);

      if (response.status === 200) {
        setData(response.data.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getReferAndEarnData = async () => {
    const headers = {
      Authorization: `${tokenType} ${token}`,
    };

    try {
      const response = await axios.get(url_220, { headers });

      if (response.data.status === 200) {
        setReferAndEarnData(response.data.data);
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  useEffect(() => {
    getReferalCode();

    getReferAndEarnData();

    // Scroll to the top of the page after a short delay
    const scrollToTop = () => {
      setTimeout(() => {
        document.documentElement.scrollTop = 0;
        document.body.scrollTop = 0;
      }, 0);
    };

    // Scroll to the top when the component mounts
    scrollToTop();

    // Restore original scroll behavior when the component unmounts
    return () => {
      document.documentElement.scrollTop = 0;
      document.body.scrollTop = 0;
    };
  }, []);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const setWidth = () => {
    setWindowWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", setWidth);

    return () => {
      window.removeEventListener("resize", setWidth);
    };
  }, [windowWidth]);

  return (
    <Fragment>
      {windowWidth <= 480 ? (
        /////for Mobile Screens////////////////////
        <Fragment>
          <MobileLeftSideBarComponent />
          <Mobile_header_component
            userPreference={userPreference}
            userData={userData}
          />
          <Mobile__right_sideBar_component history={history} />
        </Fragment>
      ) : (
        /////for Screens greater than Mobile Screens////////////////////
        <Fragment>
          <Header_component history={history} />
        </Fragment>
      )}
      <div className="ReferandEarn_wrapper">
        <div className="refer_and_earn_inner_wrapper">
          {referAndEarnType === REFER_AND_EARN_TYPES.REFER_AND_EARN_DATA && (
            <ReferAndEarnContent
              data={data}
              referAndEarnData={referAndEarnData}
              handleTermsClick={handleTermsClick}
              windowWidth={windowWidth}
              token={token}
              tokenType={tokenType}
              setReferAndEarnType={setReferAndEarnType}
              referalListSize={referalListSize}
              setReferalListSize={setReferalListSize}
              handleBackBtn={handleBackBtn}
              referAndEarnType={referAndEarnType}
            />
          )}

          {referAndEarnType === REFER_AND_EARN_TYPES.TERMS_AND_CONDITION && (
            <div className="CourseEnrollmentTermsAndConditions">
              <CourseEnrollmentTermsAndConditions
                handleBackBtn={handleBackBtn}
                referAndEarnData={referAndEarnData}
              />
            </div>
          )}
        </div>
      </div>
      <Footer_component />
      {loader && <LoaderPopUp />}
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    loader: state.ui.loader,
    token: state.auth.authToken,
    tokenType: state.auth.tokenType,
    userData: state.auth.user,
    userPreference: state.userPreference,
  };
};

export default connect(mapStateToProps, null)(ReferAndEarn);
