import React, { useState, useEffect } from "react";
import "./Parent.scss";
import ParentHeader from "./parentHeader/ParentHeader";
import Footer from "./footer/Footer";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { isEmpty } from "../custom_utilities/custom_useful_functions";
import images from "../utilities/images/images";

//// redux ////
import uiActions from "../redux/ui/action";
import { connect } from "react-redux";
import {
  setUserId,
  setStudentProfileDetails,
  setParentTarget,
} from "../parent/redux/authSlice/AuthSlice";

import LoaderPopUp from "../components/loader/loader";
import {
  setPaperId,
  setPlannerTestId,
  setPapers,
  setSinglePaper
} from "./redux/testDataSlice/TestDataSlice";
import ParentContentComponent from "./parentContentComponent/ParentContentComponent";
const Parent = (props) => {
  let {
    finishLoading,
    startLoading,
    loader,
    studentRollNum,
    setUserId,
    setPaperId,
    setSinglePaper,
    setPlannerTestId,
    setPapers,
    setStudentProfileDetails,
    parentUserId,
    setParentTarget,
  } = props;
  let history = useHistory();

  let [state, setState] = useState({
    studentData: {},
  });

  let { studentData } = state;

  useEffect(() => {
    // Call the necessary functions when the component mounts
    getStudentData();
  }, []);

  const handleRedirect = () => {
    // Redirect to the student-tests page with necessary parameters
    history.push({
      pathname: "/student-tests",
      state: { step: 1, roll_no: studentRollNum },
    });
  };

  const handleRedirect2 = (element) => {
    // Set necessary parameters and redirect to the student-tests page
    setPaperId(element.paper_id);
    setPlannerTestId(element.planner_test_id);
    setSinglePaper(element);


    setPapers(element.papers);
    history.push({
      pathname: "/student-tests",
      state: { step: 2 },
    });
  };

  const handleRedirect3 = (paperId) => {
    // Set the paperId parameter and redirect to the student-report-pdf page
    setPaperId(paperId);
    history.push({
      pathname: "/student-report-pdf",
      state: { paper_id: paperId },
    });
  };

  const getStudentData = async () => {
    // Get the student data
    let data = {
      roll_no: studentRollNum,
      device: "Web",
    };

    try {
      const response = await axios.post(
        "https://learning.motion.ac.in/motioneducation/api/SPR_StudentOverallSummary",
        data
      );

      if (response.data.status === 200) {
        setState((prevState) => ({
          ...prevState,
          studentData: response.data.student_data,
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.toLocaleString("en-US", { day: "2-digit" });
    const month = date.toLocaleString("en-US", { month: "short" });
    const year = date.toLocaleString("en-US", { year: "numeric" });
    return `${day}-${month}-${year}`;
  };

  return (
    <>
      <div className="containers">
        <ParentHeader
          studentRollNum={studentRollNum}
          studentData={studentData}
        />
        <div className="container">
          <ParentContentComponent
            finishLoading={finishLoading}
            startLoading={startLoading}
            studentRollNum={studentRollNum}
            setUserId={setUserId}
            setStudentProfileDetails={setStudentProfileDetails}
            setParentTarget={setParentTarget}
            loader={loader}
            LoaderPopUp={LoaderPopUp}
            parentUserId={parentUserId}
          />

          <div className="topicWiseWrapper">
            <div className="section-heading">Test-wise Analysis</div>
            {!isEmpty(state.studentData)
              ? state.studentData.data.length
                ? state.studentData.data.map((element, index) => {
                    return (
                      <div key={index} className="topicWiseCard">
                        <div className="card-body">
                          <div className="card-title">
                            <div className="text">RBTS Full Test</div>
                            <div className="date">
                              {formatDate(
                                element.student_summary.to_be_held_on
                              )}
                            </div>
                          </div>

                          <div className="card-contain">
                            <div className="lft">
                              <div className="lft-top">
                                <ul>
                                  <li>
                                    <div className="TestReportCard">
                                      <div className="text">
                                        <div className="text-top">Marks</div>
                                        <div>
                                          <div className="text-btm ">
                                            Out of 300
                                          </div>
                                        </div>
                                      </div>
                                      <div className="num marks">
                                        {element.student_summary.total_marks}
                                      </div>
                                    </div>
                                  </li>

                                  <li>
                                    <div className="TestReportCard">
                                      <div className="text">
                                        <div className="text-top">Rank</div>
                                        <div>
                                          <div className="text-btm">
                                            600 Students
                                          </div>
                                        </div>
                                      </div>
                                      <div className="num rank">
                                        {element.student_summary.rank}
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="TestReportCard">
                                      <div className="text">
                                        <div className="text-top">Accuracy</div>
                                      </div>
                                      <div className="num">
                                        {element.student_summary.Accuracy}
                                      </div>
                                    </div>
                                  </li>

                                  <li>
                                    <div className="TestReportCard">
                                      <div className="text">
                                        <div className="text-top">
                                          Highest Marks
                                        </div>
                                      </div>
                                      <div className="num">
                                        {element.student_summary.highest_marks}
                                      </div>
                                    </div>
                                  </li>

                                  <li>
                                    <div className="TestReportCard">
                                      <div className="text">
                                        <div className="text-top">
                                          Average Marks
                                        </div>
                                      </div>
                                      <div className="num">
                                        {element.student_summary.avg_score}
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                              <div className="lft-btm">
                                <div
                                  className="percentageBox"
                                  style={{
                                    backgroundColor:
                                      element.student_summary.performance,
                                  }}
                                >
                                  <div>
                                    <div className="text">Percentile</div>
                                    <div className="num">
                                      {element.student_summary.percentile}
                                    </div>
                                  </div>
                                  <div className="img">
                                    <img
                                      src={images.user_activity_46}
                                      alt="img"
                                    />
                                  </div>

                                  <div>
                                    <div className="text">Percentage</div>
                                    <div className="num">
                                      {element.student_summary.percentage}
                                    </div>
                                  </div>
                                </div>

                                <div className="subjs">
                                  <ul>
                                    {element.student_summary?.Physics
                                      ?.user_score !== null &&
                                    element.student_summary?.Physics
                                      ?.user_score !== 0 ? (
                                      <li className="li-1">
                                        <div className="text">Physics</div>
                                        <div className="num">
                                          {
                                            element.student_summary?.Physics
                                              ?.rank
                                          }
                                        </div>
                                      </li>
                                    ) : null}

                                    {element.student_summary?.Chemistry
                                      ?.user_score !== null &&
                                    element.student_summary?.Chemistry
                                      ?.user_score !== 0 ? (
                                      <li className="li-2">
                                        <div className="text">Chemistry</div>
                                        <div className="num">
                                          {
                                            element.student_summary?.Chemistry
                                              ?.rank
                                          }
                                        </div>
                                      </li>
                                    ) : null}

                                    {element.student_summary?.Maths
                                      ?.user_score !== null &&
                                    element.student_summary?.Maths
                                      ?.user_score !== 0 ? (
                                      <li className="li-3">
                                        <div className="text">Maths</div>
                                        <div className="num">
                                          {element.student_summary?.Maths?.rank}
                                        </div>
                                      </li>
                                    ) : null}

                                    {element.student_summary?.Botany
                                      ?.user_score !== null &&
                                    element.student_summary?.Botany
                                      ?.user_score !== 0 ? (
                                      <li className="li-1">
                                        <div className="text">Botany</div>
                                        <div className="num">
                                          {
                                            element.student_summary?.Botany
                                              ?.rank
                                          }
                                        </div>
                                      </li>
                                    ) : null}

                                    {element.student_summary?.Zoology
                                      ?.user_score !== null &&
                                    element.student_summary?.Botany
                                      ?.user_score !== 0 ? (
                                      <li>
                                        <div className="text">Zoology</div>
                                        <div className="num">
                                          {
                                            element.student_summary?.Zoology
                                              ?.rank
                                          }
                                        </div>
                                      </li>
                                    ) : null}
                                  </ul>
                                </div>
                              </div>
                            </div>

                            <div className="rght">
                              <div className="viewReport">
                                <button
                                  onClick={() => handleRedirect2(element)}
                                >
                                  <span className="text">View report</span>
                                  <span>
                                    <div className="img">
                                      <img
                                        src={images.user_activity_47}
                                        alt="img"
                                      />
                                    </div>
                                  </span>
                                </button>
                              </div>
                              <div className="downloadReport">
                                <button
                                  onClick={() =>
                                    handleRedirect3(element.paper_id)
                                  }
                                >
                                  <span className="text">Download report</span>
                                  <span>
                                    <div className="img">
                                      <img
                                        src={images.user_activity_48}
                                        alt="img"
                                      />
                                    </div>
                                  </span>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                : null
              : null}
            {loader && <LoaderPopUp />}
          </div>

          <div className="viewAllTestBtn">
            <button onClick={handleRedirect}>View all Tests</button>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};

// Redux state mapping to component props
const mapStateToProps = (state) => {
  return {
    loader: state.ui.loader,
    studentRollNum: state.parentrootReducer.parentAuthReducer.user.st_rollno,
    parentUserId: state.parentrootReducer.parentAuthReducer.userId,
  };
};

// Redux dispatch mapping to component props
const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => {
      dispatch(uiActions.startLoading());
    },
    finishLoading: () => {
      dispatch(uiActions.finishLoading());
    },
    setUserId: (payload) => {
      dispatch(setUserId(payload));
    },

    setPaperId: (payload) => {
      dispatch(setPaperId(payload));
    },

    setSinglePaper: (payload) => {
      dispatch(setSinglePaper(payload));
    },


    setPlannerTestId: (payload) => {
      dispatch(setPlannerTestId(payload));
    },

    setPapers: (payload) => {
      dispatch(setPapers(payload));
    },
    setStudentProfileDetails: (payload) => {
      dispatch(setStudentProfileDetails(payload));
    },

    setParentTarget: (payload) => {
      dispatch(setParentTarget(payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Parent);
