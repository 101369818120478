import { Fragment } from "react";
import LoaderPopUp from "../../../../../components/loader/loader";
import BitSatTestContentComponent from "../bitSatTestComponent/bitSatTestContentComponent/bitSatTestContentComponent";
import { useLocation } from "react-router-dom";

//Redux
import { connect } from "react-redux";

const BitSatTestComponent = (props) => {
  let { history, loader,userData } = props;
  let location = useLocation()
  let paperId = location?.state.testData.paper_id
  return (
    <Fragment>
      <div className="bit_sat_test_component_wrapper">
        <div className="bit_sat_test_component_inner_wrapper">
          <Fragment>
              <BitSatTestContentComponent  userData={userData} history={history} paperId={paperId}/>
            </Fragment>
        </div>
      </div>
      {loader && <LoaderPopUp />}
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    loader: state.ui.loader,
    userData: state.auth.user,
  };
};

export default connect(mapStateToProps)(BitSatTestComponent);
