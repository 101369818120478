import React from "react";
import "./Pyq_subject_topic.scss";

///////////////////Desktop
import HeaderComponent from "../../../structure_components/desktop_screens/header_component/header_component";
import PyqSubjectTopicContent from "./pyqSubjectTopicContent/PyqSubjectTopicContent";
//////Mobile////////////////////
import MobileLeftSideBarComponent from "../../../structure_components/mobile_screens/mobile_left_sideBar_component/mobile_left_sideBar_component";
import MobileHeaderComponent from "../../../structure_components/mobile_screens/mobile_header_component/mobile_header_component";
import MobileRightSideBarComponent from "../../../structure_components/mobile_screens/mobile_right_sideBar_component/mobile__right_sideBar_component";

import LoaderPopUp from "../../../../../components/loader/loader";
//Redux
import { connect } from "react-redux";
import Footer_component from "../../../structure_components/desktop_screens/footer_component/Footer_component";

const Pyq_subject_topic = (props) => {
  let { loader, history, userData, userPreference } = props;
  const isMob = () => {
    if (window.innerWidth <= 480) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <>
      <div className="PyqSubjectTopicWrapper">
        <div className="PyqSubjectTopicInrWrpr">
          {isMob() ? <>
            <MobileLeftSideBarComponent />
              <MobileHeaderComponent
                userPreference={userPreference}
                userData={userData}
              />
              <MobileRightSideBarComponent history={history} />
          </>
            :<HeaderComponent history={history} />}
          <PyqSubjectTopicContent userPreference={userPreference}/>
        </div>
      </div>
      <Footer_component />
      {loader && <LoaderPopUp />}
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    loader: state.ui.loader,
    userData: state.auth.user,
    userPreference: state.userPreference,
  };
};
export default connect(mapStateToProps)(Pyq_subject_topic);
