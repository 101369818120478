import { Fragment, useState } from "react";
import "./mobile_left_sideBar_component.scss";

import images from "../../../../../utilities/images/images";
import { mobileLeftSideBarClose } from "../../../../../custom_utilities/sideBar_functions";
import { NavLink, Link, useLocation } from "react-router-dom";

const MobileLeftSideBarComponent = () => {
  const location = useLocation();
  const dropdownCondition = location.pathname == "/pyq" ||
  location.pathname == "/cps" ||
  location.pathname == "/exam" ||
  location.pathname == "/ots" ||
  location.pathname == "/exam_cracker" ||
  location.pathname == "/mock_test" ||
  location.pathname == "/bit_sat"
  const [dropdown, setDropdown] = useState(dropdownCondition ? true : false)
  return (
    <Fragment>
      <div
        id="mobile_left_sideBar_wrapper"
        className="mobile_left_sideBar_wrapper"
      >
        <div
          id="mobile_left_sideBar_inner_wrapper"
          className="mobile_left_sideBar_inner_wrapper"
        >
          <div className="sec_1_wrppaer">
            <div className="sec_1_inner_wrapper">
              <div
                className="image_wrapper"
                onClick={() => mobileLeftSideBarClose()}
              >
                <Link to="/user_dashboard">
                  <img src={images.motion_logo_svg} />
                </Link>
              </div>
            </div>
          </div>
          <div className="sec_2_wrapper">
            <div className="sec_2_inner_wrapper">
              <ul>
                <li onClick={() => mobileLeftSideBarClose()}>
                  <NavLink to="/user_dashboard">
                    <div className="wrapping_container">
                      <img className="active_icon" src={images.active_home} />
                      <img
                        className="inactive_icon"
                        src={images.inactive_home}
                      />
                      <span className="heading_tag">Home</span>
                    </div>
                  </NavLink>
                </li>
                <li>
                  {/* <NavLink to={{
                pathname: "/exam",
                state: { tab: "exam" }
              }}> */}
                  <a
                    href="javascript:void(0)"
                    className={
                      dropdownCondition ? "active" : ""
                    }
                  >
                    <div
                      className="wrapping_container"
                      onClick={() => setDropdown((prev) => !prev)}
                    >
                      <img
                        className="active_icon"
                        src={images.active_exercise}
                      />
                      <img
                        className="inactive_icon"
                        src={images.inactive_exercise}
                      />
                      <span className="heading_tag">Practice <img style={{transform: dropdown ? 'rotatez(-90deg)' : 'rotatez(90deg)'}} src={images.right_Ar} /></span>
                      {dropdown && (
                        <ul>
                          <li>
                            <NavLink
                            activeClassName="active_link"
                              to={{
                                pathname: "/pyq",
                                state: { tab: "pyq" },
                              }}
                            >
                             <img src={images.m2_backBtn} alt="backBtn"  />
                              PYQ
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                            activeClassName="active_link"
                              to={{
                                pathname: "/cps",
                                state: { tab: "cps" },
                              }}
                            >
                            <img src={images.m2_backBtn} alt="backBtn"  />
                              CPS
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                            activeClassName="active_link"
                              to={{
                                pathname: "/exam",
                                state: { tab: "exam" },
                              }}
                            >
                            <img src={images.m2_backBtn} alt="backBtn"  />
                              Exams
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                            activeClassName="active_link"
                              to={{
                                pathname: "/ots",
                                state: { tab: "ots" },
                              }}
                            >
                            <img src={images.m2_backBtn} alt="backBtn"  />
                              AITS
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                            activeClassName="active_link"
                              to={{
                                pathname: "/exam_cracker",
                                state: { tab: "exam_cracker" },
                              }}
                            >
                            <img src={images.m2_backBtn} alt="backBtn"  />
                              Exam Cracker
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                            activeClassName="active_link"
                              to={{
                                pathname: "/bit_sat",
                                state: { tab: "bit_sat" },
                              }}
                            >
                            <img src={images.m2_backBtn} alt="backBtn"  />
                              BITSAT
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                            activeClassName="active_link"
                              to={{
                                pathname: "/mock_test",
                                state: { tab: "mock_test" },
                              }}
                            >
                            <img src={images.m2_backBtn} alt="backBtn"  />
                              Mock Tests
                            </NavLink>
                          </li>
                        </ul>
                      )}
                    </div>
                  </a>
                  {/* </NavLink> */}
                </li>
                <li onClick={() => mobileLeftSideBarClose()}>
                  <NavLink to="/doubts">
                    <div className="wrapping_container">
                      <img className="active_icon" src={images.active_doubts} />
                      <img
                        className="inactive_icon"
                        src={images.inactive_doubts}
                      />
                      <span className="heading_tag">Doubts</span>
                    </div>
                  </NavLink>
                </li>

                <li onClick={() => mobileLeftSideBarClose()}>
                  <NavLink to="/question_bank">
                    <div className="wrapping_container">
                      <img className="active_icon qstnbank" src={images.active_exercise} />
                      <img
                        className="inactive_icon qstnbank"
                        src={images.inactive_exercise}
                      />
                      <span className="heading_tag">Question Bank</span>
                    </div>
                  </NavLink>
                </li>
                {/* <li>
                  <NavLink to="/user_analytics">
                    <div className="wrapping_container">
                      <img
                        className="active_icon"
                        src={images.active_analytics}
                      />
                      <img
                        className="inactive_icon"
                        src={images.inactive_analytics}
                      />
                      <span className="heading_tag">Analytics</span>
                    </div>
                  </NavLink>
                </li> */}
                <li onClick={() => mobileLeftSideBarClose()}>
                  <NavLink to="/user_bookmarks">
                    <div className="wrapping_container">
                      <img
                        className="active_icon"
                        src={images.active_bookmark}
                      />
                      <img
                        className="inactive_icon"
                        src={images.inactive_bookmark}
                      />
                      <span className="heading_tag">Bookmarks</span>
                    </div>
                  </NavLink>
                </li>
                <li onClick={() => mobileLeftSideBarClose()}>
                  <NavLink to="/store">
                    <div className="wrapping_container">
                      <img className="active_icon" src={images.active_store} />
                      <img
                        className="inactive_icon"
                        src={images.inactive_store}
                      />
                      <span className="heading_tag">Store</span>
                    </div>
                  </NavLink>
                </li>
                <li onClick={() => mobileLeftSideBarClose()}>
                  <NavLink to="/transactions">
                    <div className="wrapping_container">
                      <img
                        className="active_icon"
                        src={images.active_transaction}
                      />
                      <img
                        className="inactive_icon"
                        src={images.inactive_transaction}
                      />
                      <span className="heading_tag">Transactions</span>
                    </div>
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
          <div className="sec_3_wrapper">
            <div className="sec_3_inner_wrapper">
              <ul>
                <li onClick={() => mobileLeftSideBarClose()}>
                  <NavLink to="/about_us">
                    <div className="wrapping_container">
                      <span className="heading_tag">About Us</span>
                    </div>
                  </NavLink>
                </li>
                <li onClick={() => mobileLeftSideBarClose()}>
                  <NavLink to="/privacy_policy">
                    <div className="wrapping_container">
                      <span className="heading_tag">Privacy Policy</span>
                    </div>
                  </NavLink>
                </li>
                <li onClick={() => mobileLeftSideBarClose()}>
                  <NavLink to="/terms_n_condition">
                    <div className="wrapping_container">
                      <span className="heading_tag">Terms & Condition</span>
                    </div>
                  </NavLink>
                </li>
                <li onClick={() => mobileLeftSideBarClose()}>
                  <NavLink to="/faqs">
                    <div className="wrapping_container">
                      <span className="heading_tag">FAQ's</span>
                    </div>
                  </NavLink>
                </li>
                <li onClick={() => mobileLeftSideBarClose()}>
                  <NavLink to="/contact_us">
                    <div className="wrapping_container">
                      <span className="heading_tag">Contact Us</span>
                    </div>
                  </NavLink>
                </li>
                <li onClick={() => mobileLeftSideBarClose()}>
                  <NavLink
                    to={{
                      pathname:
                        "https://play.google.com/store/apps/details?id=www.motion.ac.in.student.parent",
                    }}
                    target="_blank"
                  >
                    <div className="wrapping_container">
                      <span className="heading_tag">Parent Connect</span>
                    </div>
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default MobileLeftSideBarComponent;
