import axios from "axios";
import { Fragment, useEffect, useState } from "react";
import uiActions from "../../../../redux/ui/action";
import { url_215 } from "../../../../custom_utilities/api_services";
import { connect } from "react-redux";
import MobileLeftSideBarComponent from "../../structure_components/mobile_screens/mobile_left_sideBar_component/mobile_left_sideBar_component";
import Header_component from "../../structure_components/desktop_screens/header_component/header_component";
import Mobile__right_sideBar_component from "../../structure_components/mobile_screens/mobile_right_sideBar_component/mobile__right_sideBar_component";
import Mobile_header_component from "../../structure_components/mobile_screens/mobile_header_component/mobile_header_component";
import LoaderPopUp from "../../../../components/loader/loader";
import { useHistory } from "react-router-dom";
import Footer_component from "../../structure_components/desktop_screens/footer_component/Footer_component";
import "./recorded_video_watch_component.scss";
import images from "../../../../utilities/images/images";

const RecordedVideoWatchComponent = (props) => {
  let {
    loader,
    userPreference,
    recordedVideoData,
    userData,
    startLoading,
    finishLoading,
  } = props;

  const history = useHistory();

  const [playbackUrl, setPlaybackUrl] = useState("");

  useEffect(() => {
    getRecordedLiveClassUrl();
  }, []);

  const getRecordedLiveClassUrl = async () => {
    const data = {
      id: recordedVideoData,
      type: "record",
      isMobile: false,
    };
    startLoading();
    try {
      const response = await axios.post(url_215, data);
      if (response.status === 200) {
        setPlaybackUrl(response.data.data);
        console.log("live recorded video list:", response.data.data);
        finishLoading();
      } else {
        alert(response.data.message);
        finishLoading();
      }
    } catch (error) {
      finishLoading();
      console.log(error);
    }
  };

  const isMob = () => {
    if (window.innerWidth <= 480) {
      return true;
    } else {
      return false;
    }
  };

  const handleRedirect = () => {
    history.goBack();
  };

  return (
    <Fragment>
      <div className="recorded_video_component_wrapper">
        <div className="recorded_video_component_inner_wrapper">
          {isMob() ? (
            <Fragment>
              <MobileLeftSideBarComponent />
              <Mobile_header_component
                userPreference={userPreference}
                userData={userData}
              />
              <Mobile__right_sideBar_component history={history} />
              <div className="iframe-container">
                <div className="container">
                  <div className="d-flex justify-content-start heading-wrapper">
                    <div className="btn_wrapper">
                      <button onClick={() => handleRedirect()}>
                        <img src={images.user_activity_1} />
                      </button>
                    </div>
                    <div className="text_content_wrapper">
                      <p className="text_content">
                        {playbackUrl.meeting_topic}
                      </p>
                    </div>
                  </div>
                  <div className="video_frame_wrapper">
                      <iframe
                        id="iframe"
                        src={playbackUrl.playbackurl}
                        title="Live Recorded Video"
                        allow="accelerometer; autoplay; encrypted-media;gyroscope; picture-in-picture"
                        allowFullScreen
                      ></iframe>
                  </div>
                </div>
              </div>
            </Fragment>
          ) : (
            <Fragment>
              <Header_component />
              <div className="iframe-container">
                <div className="container">
                  <div className="d-flex justify-content-start heading-wrapper">
                    <div className="btn_wrapper">
                      <button onClick={() => handleRedirect()}>
                        <img src={images.user_activity_1} />
                      </button>
                    </div>
                    <div className="text_content_wrapper">
                      <p className="text_content">
                        {playbackUrl.meeting_topic}
                      </p>
                    </div>
                  </div>
                  <div className="video_frame_wrapper">
                      <iframe
                        id="iframe"
                        src={playbackUrl.playbackurl}
                        title="Live Recorded Video"
                        allow="accelerometer; autoplay; encrypted-media;gyroscope; picture-in-picture"
                        allowFullScreen
                      ></iframe>
                  </div>
                </div>
              </div>
            </Fragment>
          )}
        </div>
      </div>
      <Footer_component />
      {loader && <LoaderPopUp />}
    </Fragment>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => {
      dispatch(uiActions.startLoading());
    },
    finishLoading: () => {
      dispatch(uiActions.finishLoading());
    },
  };
};

const mapStateToProps = (state) => {
  console.log(state);
  return {
    loader: state.ui.loader,
    userData: state.auth.user,
    userPreference: state.userPreference,
    recordedVideoData: state.userActivity.recordedVideoData,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RecordedVideoWatchComponent);
