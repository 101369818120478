import { Fragment, useState, useEffect } from "react";
import axios from "axios";
// import "./doubtDetailsView.scss";
import "./ScannedDoubtDetailsView.scss";
import { isEmpty } from "../../../../../custom_utilities/custom_useful_functions";
import ShakaSolutionViewVideoPlayer from "../../../../../custom_utilities/shakaVideoPlayer/ShakaSolutionViewVideoPlayer";
import useMathjax from "../../../../../hooks/useMathjax";

const ScannedDoubtDetailsView = ({
  doubtDetails,
  handleBackBtn,
  renderQuestion,
  convertDate,
  doubtType,
}) => {
  console.log("Details", doubtDetails);

  const [scannedDoubts, setScannedDoubts] = useState({});
  const [showVideoSol, setShowVideoSol] = useState(false);

  useEffect(() => {
    if (doubtType === "scanned") {
      getScannedDoubt();
    }
  }, []);

  const getScannedDoubt = async () => {
    const data = {
      doubt_id: doubtDetails.user_doute_question_id,
    };
    try {
      const response = await axios.post(
        "https://learning.motion.ac.in/motioneducation/api/user/scannuserdoubt/sol/lists",
        data
      );

      console.log("response of getScanneddbout", response);

      if (response.data.status === 200) {
        setScannedDoubts(response.data.data);
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  const toggleShowVideoSol = () => {
    setShowVideoSol(!showVideoSol);
  };

  useMathjax(
    !isEmpty(scannedDoubts)
      ? !isEmpty(scannedDoubts.question_list.length)
        ? renderQuestion(scannedDoubts.question_list[0]?.solution?.solution)
        : null
      : null
  );

  useMathjax(doubtDetails.question);

  return (
    <Fragment>
      <div className="scanned_doubtDetailsView_wrapper">
        <div className="doubtDetailsView_inner_wrapper">
          <div className="sec_1_wrapper">
            <div className="sec_1_inner_wrapper">
              <div className="btn_wrapper"></div>
            </div>
          </div>
          <div className="sec_2_wrapper">
            <div className="sec_2_inner_wrapper">
              <div className="content_wrapper">
                <div className="sub_section_wrapper_1">
                  <div className="left_side_content_wrapper">
                    <div className="text_content_wrapper">
                      <p className="text_content">Question:</p>
                    </div>
                  </div>
                  <div className="right_side_content_wrapper"></div>
                </div>
                <div className="sub_section_wrapper_2">
                  <div className="text_content_wrapper">
                    <p className="text_content">
                      {renderQuestion(doubtDetails.question)}
                    </p>
                  </div>
                </div>
                {/* <div className="sub_section_wrapper_3">
                  <div className="images_collection_wrapper">
                    <div className="answered_by">
                      {!isEmpty(doubtDetails.Answer)
                        ? doubtDetails.Answer.name
                        : null}
                    </div>
                    {doubtDetails.Questionfiles.length
                      ? doubtDetails.Questionfiles.map((element, index) => {
                          return (
                            <div key={index} className="image_wrapper">
                              {element.files && <img src={element.files} />}
                            </div>
                          );
                        })
                      : null}
                  </div>
                </div> */}

                <div className="sol_text">
                  <p className="text_content">Solution:</p>
                </div>

                <div className="sub_section_wrapper_4">
                  <div className="text_content_wrapper_2">
                    <p className="text_content_2">
                      {!isEmpty(scannedDoubts)
                        ? !isEmpty(scannedDoubts.question_list.length)
                          ? renderQuestion(
                              scannedDoubts.question_list[0]?.solution?.solution
                            )
                          : null
                        : null}
                    </p>
                  </div>
                </div>

                <div onClick={toggleShowVideoSol} className="video-sol-btn">
                  Video Solution
                </div>
                {showVideoSol && (
                  <ShakaSolutionViewVideoPlayer
                    id_video={
                      scannedDoubts.question_list[0]?.solution?.video_cript_id
                    }
                    className="shakaPlayer"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ScannedDoubtDetailsView;
