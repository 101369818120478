
import * as actionTypes from "./action_type";

const setBitSatTestsData = (payload) => {
  return {
    type: actionTypes.SET_BIT_SAT_TESTS_DATA,
    payload,
  };
};

const setBitSatTestsResultData = (payload) => {
  return {
    type: actionTypes.SET_BIT_SAT_TESTS_RESULT_DATA,
    payload,
  };
};

export default {
    setBitSatTestsData,
    setBitSatTestsResultData,
};

