import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import {
  renderButton,
  getDetailsFromLocalStorage,
  removeString,
  handleReedem,
  handleModify,
  totalRewardCash,
} from "../../utils";

import "./VerifyDetails.scss";

const VerifyDetails = ({
  show,
  onCloseModal,
  setModalType,
  token,
  tokenType,
  dispatch,
}) => {
  const checkedRefferals = useSelector(
    (state) => state.ReferAndEarnSlice.checkedCards
  );

  const [upiId, setUpiId] = useState("");
  const [type, setType] = useState("");
  const [bankDetails, setBankDetails] = useState({
    accountHolderName: "",
    ifscCode: "",
    accountNumber: "",
    confirmAccountNumber: "",
  });

  useEffect(() => {
    const localStorageUpiDetails =
      getDetailsFromLocalStorage("upiDetails") ?? "";
    setUpiId(removeString(localStorageUpiDetails));

    const type = getDetailsFromLocalStorage("TYPE");

    setType(removeString(type));

    const storedBankDetails = localStorage.getItem("bankDetails");
    if (storedBankDetails) {
      setBankDetails(JSON.parse(storedBankDetails));
    }
  }, []);

  return (
    <Modal
      show={show}
      onHide={onCloseModal}
      dialogClassName="verify_details_modal_wrapper"
    >
      <Modal.Header closeButton></Modal.Header>

      <Modal.Body>
        <div className="verify_details_wrapper">
          <h3>Verify your details</h3>
          <h2>Please confirm your cash reward redemption details.</h2>

          <div className="verify_details_container">
            <div className="cash_details detail bg">
              <div className="left">Cash Redeemed</div>
              <div className="right amnt">
                {`₹${totalRewardCash(checkedRefferals)}`}
              </div>
            </div>

            {type !== "UPI" ? (
              <>
                <div className="acnt_num_details detail">
                  <div className="left">Account Number</div>
                  <div className="right">{bankDetails?.accountNumber}</div>
                </div>
                <div className="ifsc_details detail bg">
                  <div className="left">IFSC Code</div>
                  <div className="right">{bankDetails?.ifscCode}</div>
                </div>
                <div className="acnt_name_details detail">
                  <div className="left">Name</div>
                  <div className="right">{bankDetails?.accountHolderName}</div>
                </div>
              </>
            ) : (
              <div className="upi_details detail">
                <div className="left">UPI ID</div>
                <div className="right">{upiId}</div>
              </div>
            )}
          </div>

          <div className="verify_details_button">
            <div
              className="modify btnn"
              onClick={() => handleModify(setModalType)}
            >
              {renderButton("Modify")}
            </div>

            <div
              className="redeem btnn"
              onClick={() => {
                handleReedem(
                  checkedRefferals,
                  token,
                  tokenType,
                  setModalType,
                  dispatch
                );
              }}
            >
              {renderButton("Redeem")}
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default VerifyDetails;
