import React, { useState, useEffect } from "react";
import "./CartDetails.scss";
import images from "../../../../../../utilities/images/images";
import HeaderComponent from "../../../../structure_components/desktop_screens/header_component/header_component";
import { Link, useLocation, useHistory } from "react-router-dom";
import axios from "axios";
import uiActions from "../../../../../../redux/ui/action";
import LoaderPopUp from "../../../../../../components/loader/loader";
import {
  url_162,
  url_160,
  url_164,
} from "../../../../../../custom_utilities/api_services";

import { connect } from "react-redux";
import Footer_component from "../../../../structure_components/desktop_screens/footer_component/Footer_component";
import Header_component from "../../../../structure_components/desktop_screens/header_component/header_component";
import Mobile__right_sideBar_component from "../../../../structure_components/mobile_screens/mobile_right_sideBar_component/mobile__right_sideBar_component";
import Mobile_header_component from "../../../../structure_components/mobile_screens/mobile_header_component/mobile_header_component";
import MobileLeftSideBarComponent from "../../../../structure_components/mobile_screens/mobile_left_sideBar_component/mobile_left_sideBar_component";

const CartDetails = (props) => {
  let { startLoading, finishLoading, loader, tokenType, token, history, userData, userPreference } = props;

  const [state, setState] = useState({
    addToCart: [],
    cartDetails: {},
    wishList: [],
    cartBtnFlag: false,
  });
  let location = useLocation();
  let product = location.state.product;
  let historys = useHistory();

  useEffect(() => {
    getCartDetails();
    getWishList();
  }, []);

  const handleRedirect = () => {
    historys.push({
      pathname: "/cart_collection",
      // state: { cartDetails: "sharath" },
    });
  };

  const handleWishList = () => {
    historys.push("/wishList_collection");
  };

  const getCartDetails = async () => {
    const headers = {
      Authorization: `${tokenType} ${token}`,
    };
    startLoading();
    try {
      const response = await axios.post(url_162, { headers });
      if (response.data.status === 200) {
        finishLoading();
        setState((prevState) => ({
          ...prevState,
          cartDetails: response.data,
        }));
      }
    } catch (error) {
      finishLoading();
      console.log(error);
    }
  };

  const addToCart = async (p_id) => {
    startLoading();
    const headers = {
      Authorization: `${tokenType} ${token}`,
    };
    let data = {
      product_id: p_id,
    };
    try {
      const response = await axios.post(url_160, data, { headers });

      if (response.data.status === 200) {
        finishLoading();
        setState((prevState) => ({
          ...prevState,
          addToCart: response.data,
        }));
      }
    } catch (error) {
      finishLoading();
      console.log(error);
    }
  };

  const handleBuyNow = (product) => {
    historys.push({
      pathname: "/cart_collection",
      state: { step: 2, singleProduct: product },
    });
  };

  const handleAddToCart = (p_id) => {
    handleAlert(p_id);
    addToCart(p_id);
    getCartDetails();
    setState((prevState) => ({
      ...prevState,
      cartBtnFlag: true,
    }));
  };

  const handleAlert = (p_id) => {
    const newArr = state.cartDetails?.data?.filter((element) => {
      return element.p_id === p_id;
    });
    if (newArr[0]) {
      return alert("You have this item in your cart");
    }
  };

  const getWishList = async () => {
    startLoading();
    try {
      const response = await axios.post(url_164);
      if (response.data.status === 200) {
        finishLoading();
        setState((prevState) => ({
          ...prevState,
          wishList: response.data.data,
        }));
      }
    } catch (error) {
      finishLoading();
      console.log(error);
    }
  };

  const renderCardTotal = () => {
    if (state.addToCart.total) {
      return <div className="badge">{state.addToCart.total}</div>;
    } else {
      return <div className="badge">{state.cartDetails.total}</div>;
    }
  };

  const check = (p_id) => {
    const newArr = state.cartDetails?.data?.filter((element) => {
      return element.p_id === p_id;
    });
    console.log("check", newArr[0]);
    return newArr[0];
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const setWidth = () => {
    setWindowWidth(window.innerWidth)
  }
  useEffect(() => {
    window.addEventListener( 'resize',setWidth)

    return (() => {
      window.removeEventListener('resize',setWidth)
    })
  },[windowWidth])

  return (
    <>
      {/* <HeaderComponent /> */}
      {windowWidth <= 480 ? (
            /////for Mobile Screens////////////////////
            <>
              <MobileLeftSideBarComponent />
              <Mobile_header_component
                userPreference={userPreference}
                userData={userData}
              />
              <Mobile__right_sideBar_component history={history} />
            </>
          ) : (
            /////for Screens greater than Mobile Screens////////////////////
            <>
              <div className="user_dashboard_left_wrapper">
                {/* <LeftSideBarComponent /> */}
              </div>
              <div className="user_dashboard_right_wrapper">
                <Header_component history={history} />
              </div>
            </>
          )}
      <div className="cart_details">
        <div className="container">
          <div className="header">
            <div className="left">Course Details</div>

            <div className="right">
              <div className="wishLst_addCart">
                <div className="wishList" onClick={handleWishList}>
                  <img src={images.user_activity_39} alt="wish-list" />
                  <div className="badge">{state.wishList.length}</div>
                  <div className="wishlist">Wishlist</div>
                </div>
                <div className="addCart" onClick={handleRedirect}>
                  <img src={images.user_activity_38} alt="add-cart" />
                  {renderCardTotal()}
                  <div className="cart">Cart</div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="backBtn_content">
              <Link
                className="backBtn"
                to={{
                  pathname: "/store",
                  state: {
                    tab: "hand_books",
                    addToCart: state.addToCart,
                  },
                }}
              >
                <img src={images.m2_backBtn} alt="backBtn" />
              </Link>
              <div className="backBtn_text">Back to Store</div>
            </div>

            <section className="single_cartDetails_container">
              <div className="left">
                <div class="card">
                  <img src={product.image} class="card-img-top h-5" alt="..." />
                  <div class="card-body">
                    <p class="card-text">{product.product_name}</p>
                  </div>
                </div>
              </div>
              <div className="right">
                <div className="priceDetails">
                  <div className="priceDetailsContent">
                    <h4>Price Details</h4>

                    <div className="itemsBox">
                      <div className="item">
                        <div className="item_left">
                          <img src={product.image} alt="item_img" />
                          <div className="item_content">
                            <div className="subName">
                              {product.product_name}
                            </div>
                            <small>Solution book</small>
                          </div>
                        </div>
                        <div className="item_right">
                          {parseInt(product.price).toLocaleString("en-US")}
                        </div>
                      </div>
                    </div>

                    <div className="discountBox">
                      <div className="dicountName">Discount</div>
                      <div className="dicountPrice">
                        {/* ₹{" "}
                        {product.price -
                          product.discounted_price.toLocaleString("en-US")}
                        /- */}
                        ₹
                        {parseInt(
                          product.price - product.discounted_price
                        ).toLocaleString("en-US")}
                      </div>
                    </div>

                    <div className="totalPriceContent">
                      <div className="totalPriceText">Total Price</div>
                      <div className="totalPrice">
                        Rs{" "}
                        {parseInt(product.discounted_price).toLocaleString(
                          "en-US"
                        )}
                        /-
                      </div>
                    </div>
                  </div>
                </div>

                <div className="btns">
                  {/* {state.cartDetails?.data?.length ? (
                    <div>
                      {check(product.p_id) ? (
                        <div className="addCartBtn">
                          <button onClick={handleRedirect}>
                            Go to cart...
                          </button>
                        </div>
                      ) : (
                        <div className="addCartBtn">
                          <button
                            onClick={() => handleAddToCart2(product.p_id)}
                          >
                            Add to cart....
                          </button>
                        </div>
                      )}
                    </div>
                  ) : state.cartBtnFlag ? (
                    <div className="addCartBtn">
                      <button onClick={handleRedirect}>Go to cart</button>
                    </div>
                  ) : (
                    <div className="addCartBtn">
                      <button onClick={() => handleAddToCart(product.p_id)}>
                        Add to cart
                      </button>
                    </div>
                  )} */}

                  {state.cartBtnFlag == false ? (
                    <div className="addCartBtn">
                      <button onClick={() => handleAddToCart(product.p_id)}>
                        Add to cart
                      </button>
                    </div>
                  ) : (
                    <div className="addCartBtn">
                      <button onClick={handleRedirect}>Go to cart</button>
                    </div>
                  )}

                  <div className="buyNowBtn">
                    <button onClick={() => handleBuyNow(product)}>
                      Buy Now
                    </button>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
      {loader && <LoaderPopUp />}
      <Footer_component />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loader: state.ui.loader,
    token: state.auth.authToken,
    tokenType: state.auth.tokenType,
    storeCartDetailsData: state.userStore.storeCartDetailsData,
    userData: state.auth.user,
    userPreference: state.userPreference,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => {
      dispatch(uiActions.startLoading());
    },
    finishLoading: () => {
      dispatch(uiActions.finishLoading());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CartDetails);
