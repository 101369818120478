import React, { useState, useEffect } from "react";
import axios from "axios";
import "./QuestionBankCustomPapers.scss";
import { isEmpty } from "../../../../custom_utilities/custom_useful_functions";
import questionBankActions from "../../../../redux/question_bank/action";
import { connect } from "react-redux";
import uiActions from "../../../../redux/ui/action";
import { url_149 } from "../../../../custom_utilities/api_services";
import { Modal } from "react-bootstrap";
import images from "../../../../utilities/images/images";

const QuestionBankCustomPapers = (props) => {
  const {
    setQuestionBankIdAction,
    history,
    startLoading,
    finishLoading,
    setQuestionBankNameAction,
  } = props;

  const [state, setState] = useState({
    questionBankTestList: [],
    show: false,
    topics: [],
  });

  useEffect(() => {
    getQuestionBankTestList();
  }, []);

  //  to get list of test papers which are already created
  const getQuestionBankTestList = async () => {
    try {
      startLoading();
      const response = await axios.post(url_149);
      if (response.data.status === 200) {
        finishLoading();
        setState((prevState) => {
          return {
            ...prevState,
            questionBankTestList: response.data.data.Qbank,
          };
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleStart = (qBank) => {
    setQuestionBankIdAction(qBank.question_bank_id);
    setQuestionBankNameAction(qBank.name);

    history.push("/question_bank_test");
  };

  const handleViewSolution = (questionBankId) => {
    setQuestionBankIdAction(questionBankId);
    history.push("/question_bank_solution");
  };

  const handleRenderTopics = (topics) => {
    setState((prevState) => ({
      ...prevState,
      topics,
    }));
  };

  const handleCloseTopics = () => {
    setState((prevState) => ({
      ...prevState,
      show: false,
    }));
  };

  return (
    <div className="QuestionBankCustomPaperWrppr">
      <div className="QuestionBankCustomPaperInrWrppr">
        <div className="heading">Your Custom papers</div>
        <div className="QuestionBankCustomPaperContent">
          {!isEmpty(state.questionBankTestList) ? (
            state.questionBankTestList.map((qBank, index) => {
              if (qBank.is_attempt) {
                return (
                  <div
                    className="questionBankCustomPaperBox"
                    key={qBank.question_bank_id}
                  >
                    <div className="questionBankCustomPaperName">
                      <div className="paperName">{qBank.name}</div>
                    </div>
                    <div className="questions_data_count">
                      <ul>
                        <li className="qb_unattempt">
                          <span>
                            {qBank.no_of_question -
                              (qBank.right_questions + qBank.wrong_questions)}
                          </span>{" "}
                          Unattempted |
                        </li>{" "}
                        <li className="qb_wrong">
                          <span> {qBank.wrong_questions} </span>Wrong |
                        </li>{" "}
                        <li className="qb_correct">
                          <span> {qBank.right_questions} </span>Correct
                        </li>{" "}
                      </ul>
                    </div>

                    <div className="qb_topics">
                      <div className="qb_topics_list">
                        {qBank.topic.map((topic) => {
                          return (
                            <span className="qb_topic">{topic.topic}</span>
                          );
                        })}
                      </div>
                      <div
                        className="qb_length"
                        onClick={() => handleRenderTopics(qBank.topic)}
                      >
                        <button
                          type="button"
                          className="topic_content"
                          data-bs-toggle="modal"
                          data-bs-target="#staticBackdrop"
                        >
                          {qBank.topic.length > 3 ? (
                            <>{qBank.topic.length - 3}+</>
                          ) : (
                            <></>
                          )}
                        </button>
                      </div>
                    </div>

                    <div
                      class="modal fade"
                      id="staticBackdrop"
                      data-bs-backdrop="static"
                      data-bs-keyboard="false"
                      tabindex="-1"
                      aria-labelledby="staticBackdropLabel"
                      aria-hidden="true"
                    >
                      <div class="modal-dialog modal-dialog-scrollable">
                        <div class="modal-content">
                          <div class="modal-header">
                            <div className="modal-header-left">
                              <p
                                class="modal-title fs-5"
                                id="staticBackdropLabel"
                              >
                                Topics
                              </p>
                            </div>
                            <div className="modal-header-right">
                              <button
                                type="button"
                                class="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                              ></button>
                            </div>
                          </div>
                          <div class="modal-body">
                            <div>
                              {state.topics.map((element) => {
                                return (
                                  <div className="qb_topic">
                                    {element.topic}
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                          <div class="modal-footer">
                            <button
                              type="button"
                              className="done_btn"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                            >
                              Done
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="no_of_quests_startBtn">
                      <div className="no_of_questions">
                        {qBank.no_of_question}{" "}
                        <span className="question_text">Questions</span>
                      </div>
                      <div className="startBtn">
                        <div
                          onClick={() =>
                            handleViewSolution(qBank.question_bank_id)
                          }
                          className="view_sol_btn"
                        >
                          View Solution{" "}
                        </div>{" "}
                        <span>{">"}</span>
                      </div>
                    </div>
                  </div>
                );
              } else {
                return (
                  <div
                    className="questionBankCustomPaperBox"
                    key={qBank.question_bank_id}
                  >
                    <div className="questionBankCustomPaperName">
                      <div className="paperName">{qBank.name}</div>
                    </div>
                    <div className="qb_topics">
                      <div className="qb_topics_list">
                        {qBank.topic.map((topic) => {
                          return (
                            <span className="qb_topic">{topic.topic}</span>
                          );
                        })}
                      </div>
                      <div
                        className="qb_length"
                        onClick={() => handleRenderTopics(qBank.topic)}
                      >
                        <button
                          type="button"
                          className="topic_content"
                          data-bs-toggle="modal"
                          data-bs-target="#staticBackdrop"
                        >
                          {qBank.topic.length > 3 ? (
                            <>{qBank.topic.length - 3}+</>
                          ) : (
                            <></>
                          )}
                        </button>
                      </div>
                      <div
                        class="modal fade"
                        id="staticBackdrop"
                        data-bs-backdrop="static"
                        data-bs-keyboard="false"
                        tabindex="-1"
                        aria-labelledby="staticBackdropLabel"
                        aria-hidden="true"
                      >
                        <div class="modal-dialog modal-dialog-scrollable">
                          <div class="modal-content">
                            <div class="modal-header">
                              <div className="modal-header-left">
                                <p
                                  class="modal-title fs-5"
                                  id="staticBackdropLabel"
                                >
                                  Topics
                                </p>
                              </div>
                              <div className="modal-header-right">
                                <button
                                  type="button"
                                  class="btn-close"
                                  data-bs-dismiss="modal"
                                  aria-label="Close"
                                ></button>
                              </div>
                            </div>
                            <div class="modal-body">
                              <div>
                                {/* {index} */}
                                {/* {qBank.indexOf(qBank.topic) == index} */}
                                {state.topics.map((element) => {
                                  return (
                                    <div className="qb_topic">
                                      {element.topic}
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                            <div class="modal-footer">
                              <button
                                type="button"
                                className="done_btn"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                              >
                                Done
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="num_of_questions">
                      {qBank.no_of_question}{" "}
                      <span className="question_tex">Questions</span>
                    </div>
                    <div className="date_startBtn">
                      <div className="created_date">{qBank.create_on}</div>
                      <div
                        className="startBtn"
                        onClick={() => handleStart(qBank)}
                      >
                        <div className="start_pract_btn"> Start Practice </div>{" "}
                        <span>{">"}</span>
                      </div>
                    </div>
                  </div>
                );
              }
            })
          ) : (
            <div className="no_custom_tests_img">
              <img src={images.user_activity_37}></img>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => {
      dispatch(uiActions.startLoading());
    },
    finishLoading: () => {
      dispatch(uiActions.finishLoading());
    },
    setQuestionBankIdAction: (payload) => {
      dispatch(questionBankActions.setQuestionBankId(payload));
    },
    setQuestionBankNameAction: (payload) => {
      dispatch(questionBankActions.setQuestionBankName(payload));
    },
  };
};

export default connect(null, mapDispatchToProps)(QuestionBankCustomPapers);
