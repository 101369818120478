import React from "react";
import "./Footer.scss";

const Footer = () => {
  return (
    <div className="ftr">
      <div className="container">
        <div class="content">
          <ul>
            <li>
              <span className="letter">C:</span>
              <span className="text"> Chemistry</span>
            </li>
            <li>
              <span className="letter">P:</span>
              <span className="text"> Physics</span>
            </li>
            <li>
              <span className="letter">M:</span>
              <span className="text"> Maths</span>
            </li>
            <li>
              <span className="letter">B:</span>
              <span className="text"> Biology</span>
            </li>
          </ul>
          <ul>
            <li>
              <span className="letter">A:</span>
              <span className="text"> Average</span>
            </li>
            <li>
              <span className="letter">R:</span>
              <span className="text"> Rank</span>
            </li>
            <li>
              <span className="letter">%:</span>
              <span className="text"> Percentage</span>
            </li>
            <li>
              <span className="letter">E:</span>
              <span className="text"> English</span>
            </li>
          </ul>
          <ul>
            <li>
              <span className="letter">OM:</span>
              <span className="text"> Over all Marks Obtained</span>
            </li>
            <li>
              <span className="letter">CRS:</span>
              <span className="text"> Total Class Room Students</span>
            </li>
            <li>
              <span className="letter">CR:</span>
              <span className="text"> Class Rank</span>
            </li>
            <li>
              <span className="letter">AIR:</span>
              <span className="text"> All India Rank</span>
            </li>
          </ul>

          <ul>
            <li>
              <span className="letter">MM:</span>
              <span className="text"> Maximum Marks</span>
            </li>
            <li>
              <span className="letter">H:</span>
              <span className="text"> Highest</span>
            </li>
            <li>
              <span className="letter">AIS:</span>
              <span className="text"> Total Students All Over India</span>
            </li>
            <li>
              <span className="letter">ER:</span>
              <span className="text"> Expected Rank </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Footer;
