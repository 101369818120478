import { createSlice } from "@reduxjs/toolkit";

const ReferAndEarnSlice = createSlice({
  name: "referAndEarn",

  initialState: {
    cancelRequestData: {},
    paymentDetails: {},
    checkedRefferals: [],
    referalList: [],
  },
  reducers: {
    setCancelRequestData: (state, action) => {
      state.cancelRequestData = action.payload;
    },
    setPaymentDetails: (state, action) => {
      state.paymentDetails = action.payload;
    },

    setCheckedCards: (state, action) => {
      state.checkedCards = [...action.payload];
    },

    setReferallList: (state, action) => {
      state.referalList = [...action.payload];
    },
  },
});

export const {
  setCancelRequestData,
  setPaymentDetails,
  setCheckedCards,
  setReferallList,
} = ReferAndEarnSlice.actions;
export default ReferAndEarnSlice.reducer;
