// import React from "react";
// import {} from "./Header_left_new.scss";
// import images from "../../../../../../utilities/images/images";
// import { NavLink, Link } from "react-router-dom";
// const Header_left_new = ({ userPreference }) => {
//   // targetDetails
//   return (
//     <>
//       <div className="header_left_wrapper">
//         <div className="logo">
//           <NavLink to="/user_dashboard">
//             <img src={images.motion_logo} alt="" />
//           </NavLink>
//         </div>

//         <div className="left_menu">
//           <ul>
//             <li>
//               <NavLink className="menu-link" to="/user_dashboard">
//                 <span>Home</span>
//               </NavLink>
//             </li>
//             <li>
//               <Link
//                 className="menu-link"
//                 to={{
//                   pathname: "/pyq_cps_exam",
//                   state: { tab: "exam" },
//                 }}
//               >
//                 <span>Practice</span>
//               </Link>
//             </li>
//             <li>
//               <NavLink className="menu-link" to="/doubts">
//                 <span>Doubt</span>
//               </NavLink>
//             </li>
//             <li>
//               <NavLink className="menu-link" to="/user_analytics">
//                 <span>Analytics</span>
//               </NavLink>
//             </li>

//             {(userPreference?.targetDetails[0].target_id == 1 ||
//               userPreference?.targetDetails[0].target_id == 2 ||
//               userPreference?.targetDetails[0].target_id == 3) && (
//               <li>
//                 <NavLink className="menu-link" to="/question_bank">
//                   <span>Question Bank</span>
//                 </NavLink>
//               </li>
//             )}
//             <li>
//               <NavLink className="menu-link" to="/user_bookmarks">
//                 <span>Bookmarks</span>
//               </NavLink>
//             </li>
//             <li>
//               <Link
//                 className="menu-link"
//                 to={{
//                   pathname: "/store",
//                   state: { tab: "online_learning" },
//                 }}
//               >
//                 <span>Store</span>
//               </Link>
//             </li>
//             <li>
//               <NavLink className="menu-link" to="/transactions">
//                 <span>Transaction</span>
//               </NavLink>
//             </li>
//           </ul>
//         </div>
//       </div>
//     </>
//   );
// };

// export default Header_left_new;

import React from "react";
import {} from "./Header_left_new.scss";
import images from "../../../../../../utilities/images/images";
import { NavLink, Link, useLocation } from "react-router-dom";
const Header_left_new = ({ userPreference }) => {
  // targetDetails
  const location = useLocation();
  return (
    <>
      <div className="header_left_wrapper">
        <div className="logo">
          <NavLink to="/user_dashboard">
            {/* <img src={images.motion_logo} alt="" /> */}
            <img src={images.motion_logo_new} alt="" />
          </NavLink>
        </div>

        <div className="left_menu">
          <ul>
            <li>
              <NavLink
                activeClassName="current"
                className="menu-link"
                exact
                to="/user_dashboard"
              >
                <span>Home</span>
              </NavLink>
            </li>
            <li>|</li>
            <li className="dropdown">
              {/* <NavLink
                activeClassName="current"
                className="menu-link"
                to={{
                  pathname: "/pyq_cps_exam",
                  state: { tab: "exam" },
                }}
              >
                <span>Practice</span>
              </NavLink> */}
              <a
                // activeClassName="current"
                className={`dropdown-toggle menu-link ${
                  location.pathname == "/pyq" ||
                  location.pathname == "/cps" ||
                  location.pathname == "/exam" ||
                  location.pathname == "/ots" ||
                  location.pathname == "/exam_cracker" ||
                  location.pathname == "/mock_test" ||
                  location.pathname == "/bit_sat"
                    ? "current"
                    : ""
                }`}
                href="javascript:void(0)"
                role="button"
                id="dropdownMenuLink"
                // data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <span>Practice</span>
              </a>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                <NavLink
                  activeClassName="current-dropdown"
                  className="dropdown-item menu-link-dropdown"
                  to={{
                    pathname: "/pyq",
                    state: { tab: "pyq" },
                  }}
                >
                  <span>PYQ</span>
                </NavLink>
                <NavLink
                  activeClassName="current-dropdown"
                  className="dropdown-item menu-link-dropdown"
                  to={{
                    pathname: "/cps",
                    state: { tab: "cps" },
                  }}
                >
                  <span>CPS</span>
                </NavLink>
                <NavLink
                  activeClassName="current-dropdown"
                  className="dropdown-item menu-link-dropdown"
                  to={{
                    pathname: "/exam",
                    state: { tab: "exam" },
                  }}
                >
                  <span>Exams</span>
                </NavLink>
                <NavLink
                  activeClassName="current-dropdown"
                  className="dropdown-item menu-link-dropdown"
                  to={{
                    pathname: "/ots",
                    state: { tab: "ots" },
                  }}
                >
                  <span>Test Series</span>
                </NavLink>
                <NavLink
                  activeClassName="current-dropdown"
                  className="dropdown-item menu-link-dropdown"
                  to={{
                    pathname: "/exam_cracker",
                    state: { tab: "exam_cracker" },
                  }}
                >
                  <span>Exam Cracker</span>
                </NavLink>
                <NavLink
                  activeClassName="current-dropdown"
                  className="dropdown-item menu-link-dropdown"
                  to={{
                    pathname: "/bit_sat",
                    state: { tab: "bit_sat" },
                  }}
                >
                  <span>BITSAT</span>
                </NavLink>
                <NavLink
                  activeClassName="current-dropdown"
                  className="dropdown-item menu-link-dropdown"
                  to={{
                    pathname: "/mock_test",
                    state: { tab: "mock_test" },
                  }}
                >
                  <span>Mock Test</span>
                </NavLink>
                <NavLink
                  activeClassName="current-dropdown"
                  className="dropdown-item menu-link-dropdown"
                  to={{
                    pathname: "/create-adaptive-cps",
                    state: { tab: "adaptive_cps" },
                  }}
                >
                  <span>Adaptive CPS</span>
                </NavLink>
              </div>
            </li>
            <li>|</li>
            <li>
              <NavLink
                activeClassName="current"
                className="menu-link"
                to="/doubts"
              >
                <span>Doubt</span>
              </NavLink>
            </li>
            {/* <li>|</li>
            <li>
              <NavLink
                activeClassName="current"
                className="menu-link"
                to="/user_analytics"
              >
                <span>Analytics</span>
              </NavLink>
            </li> */}
            <li>|</li>
            {(userPreference?.targetDetails[0].target_id == 1 ||
              userPreference?.targetDetails[0].target_id == 2 ||
              userPreference?.targetDetails[0].target_id == 3) && (
              <>
                <li>
                  <NavLink
                    activeClassName="current"
                    className="menu-link"
                    to="/question_bank"
                  >
                    <span>Question Bank</span>
                  </NavLink>
                </li>
                <li>|</li>
              </>
            )}
            <li>
              <NavLink
                activeClassName="current"
                className="menu-link"
                to="/user_bookmarks"
              >
                <span>Bookmarks</span>
              </NavLink>
            </li>
            <li>|</li>
            <li>
              <NavLink
                activeClassName="current"
                className="menu-link"
                to={{
                  pathname: "/store",
                  state: { tab: "online_learning" },
                }}
              >
                <span>Store</span>
              </NavLink>
            </li>
            <li></li>
            <li className="mtse-button">
              <Link
                // activeClassName="current"
                // className="menu-link"
                to="/mts-result"
              >
                <span>MTSE Results</span>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Header_left_new;
