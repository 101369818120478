import React, { useEffect, useState } from "react";
import Lottie from "lottie-react";
import payment_successfull from "./payment_successfull.json";
import payment_failed from "./payment_failed.json";
import axios from "axios";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { url_19, url_8, url_148 } from "../api_services";
import uiActions from "../../redux/ui/action";
import authActions from "../../redux/auth/action";

import { Analytics } from "aws-amplify";
const CcAvenueResponse = (props) => {
  const {
    orderId,
    userData,
    token,
    tokenType,
    transactionData,
    startLoading,
    finishLoading,
    setUser,
    refferalCode,
  } = props;

  const {
    course_id,
    batch_id,
    package_id,
    txn_amount,
    txn_discount_amount,
    txn_date,
    package_duration,
    txnid,
  } = transactionData;

  const [ccAvenueResponse, setccAvenueResponse] = useState({});
  const history = useHistory();

  useEffect(() => {
    getCcAvenueResponse();
  }, []);

  const getCcAvenueResponse = async () => {
    const headers = {
      Authorization: `${tokenType} ${token}`,
    };

    const response = await axios.post(
      `${url_148}?user_id=${
        userData.user_id
      }&orderId=${orderId}&referral_code=${refferalCode}&coins_used=${"0"}&coin_discount=${"0"}&mode=web`,
      { headers }
    );

    if (response.data.status === 200) {
      setccAvenueResponse(response.data);

      if (response.data.payment_status.toLowerCase() === "completed") {
        saveUserTransactions();
        await Analytics.record({
          name: "web_purchased_success",
          attributes: {
            course_id: course_id,
            batch_id: batch_id,
            itemprice: txn_amount,
            product_id: package_id,
            transactionDate: txn_date,
            user_name: userData.name,
            mobile: userData.mobile,
            userId: userData.user_id,
          },
        });
      }
    } else {
      await Analytics.record({
        name: "web_purchased_failed",
        attributes: {
          course_id: course_id,
          batch_id: batch_id,
          itemprice: txn_amount,
          product_id: package_id,
          transactionDate: txn_date,
          user_name: userData.name,
          mobile: userData.mobile,
          userId: userData.user_id,
        },
      });
    }
  };

  setTimeout(() => {
    history.push("/user_dashboard");
  }, [10000]);

  const saveUserTransactions = async () => {
    const headers = {
      Authorization: `${tokenType} ${token}`,
    };
    let dataDetails = {
      course_id,
      batch_id,
      package_id,
      txn_amount,
      txn_discount_amount,
      txn_date,
      package_duration,
      txnid,
    };

    startLoading();
    try {
      const response = await axios.post(url_19, dataDetails, { headers });
      if (response.data.status === 200) {
        finishLoading();
        let returnedResponse = await updateUser();
        if (returnedResponse) {
          history.push("/user_dashboard");
        } else {
          history.push("/user_dashboard");
        }
      }
    } catch (error) {
      console.log(error);
      finishLoading();
    }
  };

  const updateUser = async () => {
    startLoading();
    try {
      const response = await axios.get(url_8);
      if (response.data.status === 200) {
        setUser(response.data.data);
        finishLoading();
        return 1;
      } else {
        return 0;
      }
    } catch (error) {
      console.log(error);
      return 0;
    }
  };

  const renderLottie = () => {
    if (ccAvenueResponse.payment_status.toLowerCase() === "completed") {
      return <Lottie animationData={payment_successfull} loop={true} />;
    } else {
      return (
        <>
          <Lottie animationData={payment_failed} loop={true} />
          <div style={{ fontSize: "35", color: "red", textAlign: "center" }}>
            Your Payment has Failed.
          </div>
        </>
      );
    }
  };

  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div style={{ width: "500px" }}>
        {Object.keys(ccAvenueResponse).length && renderLottie()}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    orderId: state.paymentReducer.orderId,
    userData: state.auth.user,
    token: state.auth.authToken,
    tokenType: state.auth.authType,
    transactionData: state.paymentReducer.transactionDetails,
    refferalCode: state.PackageSliceReducer.refferalCode,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => {
      dispatch(uiActions.startLoading());
    },
    finishLoading: () => {
      dispatch(uiActions.finishLoading());
    },
    setUser: (payload) => {
      dispatch(authActions.setUser(payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CcAvenueResponse);
