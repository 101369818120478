import { useEffect } from "react";
import "./aits_banner.scss";
import { useHistory } from "react-router-dom";

const AitsBanner = (props) => {
    let {
        is_Amrit,
        aits_pdf,
        amrit_pdf,
    } = props;
    
    let history = useHistory();

    const startBtn = () => {
        history.push({
            pathname: "/ots",
            state: { tab: "ots" },
          });
    };

    return (
        is_Amrit ? 
        (<div className="banner_wrapper_2 d-flex justify-content-between">
            <div className="left_col d-flex col-8">
                <div className="left_inner col-6">
                    <p>All India Test Series</p>
                    <p>Planners available with detailed analytics</p>
                </div>
                <div className="col-6 text-end my-auto">
                <button onClick={startBtn}>Start Test</button>
                </div>
            </div>
            <div className="right_col col-4">
                <div>
                    <a href= {`/${aits_pdf}`} target="_blank">AITS Planner</a>
                    <a href= {`/${amrit_pdf}`} target="_blank">AMRIT Planner</a>
                </div>
            </div>
        </div>)
        :
        (<div className="d-flex banner_wrapper">
            <div className="col-6 left_col">
                <div>
                    <p>All India Test Series</p>
                    <p>Planners available with detailed analytics</p>
                    <button onClick={startBtn}>Start Test</button>
                </div>
            </div>
            <div className="col-6 right_col">
                <div>
                    <p>Comprehensive Question Variety</p>
                    <p>Realistic Exam Simulation</p>
                    <p>Complete Exam Coverage</p>
                </div>
            </div>
        </div>)
    );
}

  export default AitsBanner;
