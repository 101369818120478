import React, { useEffect, useState } from "react";
import VideoJsPlayer from "./VideoPlayer/VideoJsPlayer.component";

import axios from "axios";
// for Test

export default function VIdeoPlayer({ id_video }) {
  console.log("newVideoPlayer0000");

  const [source, setSource] = useState(null);
  const [loading, setLoading] = useState();
  const [dType, setdType] = useState();
  const [key, setkey] = useState();
  function getSupportedDRMSystems() {
    if (typeof navigator.requestMediaKeySystemAccess === "function") {
      // Define the supported configurations
      const supportedConfig = [
        {
          initDataTypes: ["cenc"],
          // audioCapabilities: [{
          //     contentType: '"audio/mp4;codecs="mp4a.40.2"'
          // }],
          videoCapabilities: [
            {
              contentType: 'video/mp4;codecs="avc1.42E01E"',
            },
          ],
        },
      ];
      const drmSystems = [
        // 'org.w3.clearkey',
        // 'com.widevine.alpha',
        "com.microsoft.playready",
        "com.apple.fps",
        "com.apple.fps.1_0",
        // 'com.adobe.primetime'
        // Add more DRM system identifiers as needed
      ];

      const supportedDRM = [];

      // Use Promise.all to check support for each DRM system
      return Promise.all(
        drmSystems.map((drmSystem) => {
          return navigator
            .requestMediaKeySystemAccess(drmSystem, supportedConfig)
            .then(() => {
              supportedDRM.push(drmSystem);
            })
            .catch((error) => {
              //DRM system is not supported, do nothing
            });
        })
      ).then(() => {
        return supportedDRM; // Return the list of supported DRM systems
      });
    } else {
      // EME is not supported
      return Promise.resolve([]);
    }
  }

  //const DRM = "4062415_0_6229345180670315"; //iOS DRM File Id
  const DRM = id_video;
  const url = "https://api.videocrypt.com/getVideoDetailsDrm"; // api url
  const token = DRM;

  const formData = new FormData();
  formData.append("flag", 1);
  formData.append("name", token);

  function axioFun(D_id) {
    console.log(D_id);
    axios({
      url: url,
      method: "POST",
      headers: {
        // 'accessKey': "7YKSVBMIGX5EWFPTQ0DL", //client accesskey
        // 'secretKey': "+Jc1Rp/fLvz2HQF0m4wXaYnOiyThM5GWVZ=BKjU7",
        // 'device-type': D_id,
        // 'user-id': "10002380sddsds",
        // 'device-id': '71d3548555586126ed7071102e663619',
        // 'version': '2',
        // 'device-name': 'ChromeCDM',
        // 'account-id': '10002380',
        // "Content-Type": 'multipart/form-data'

        accessKey: "NUIzQVhZRDFGUkhFOU44UTcwTDI=",
        secretKey: "NS80OFJ5RUl0dz1CbE5VNmczYVRvTVN4dnVLY1haWW43MkhiVnJlVw==",
        "user-id": "TESTing_10000903_97",
        "device-type": D_id,
        "device-id": "71d3548555586126ed7071102e663619",
        version: "2",
        "device-name": "ChromeCDM",
        "account-id": "10000903",
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    })
      .then((res) => {
        setSource(res.data.data); // set video response

        setLoading(false);
      })
      .catch((err) => console.log(err));
  }
  useEffect(() => {
    setLoading(true);
    getSupportedDRMSystems().then(function (supportedDRM) {
      if (supportedDRM.length > 0) {
        console.log("Supported DRM systems:");
        supportedDRM.forEach((system) => {
          if (system === "com.apple.fps") {
            axioFun(2);
            setdType(2);
            setkey("com.apple.fps.1_0");
            return false;
          } else if (system === "com.microsoft.playready") {
            console.log("Edge supported DRM");
            axioFun(3);
            setdType(3);
            setkey("com.microsoft.playready");
            return false;
          } else {
          }
          console.log(system);
        });
      } else {
        console.log("com.widevine.alpha");
        axioFun(1);
        setdType(1);
        setkey("com.widevine.alpha");

        console.log("No supported DRM systems found.");
        setLoading(false);
        return false;
      }
    });
  }, []);

  //video custom option
  const videoSrc = {
    autoplay: false,
    controls: true,
    responsive: true,
    playbackRates: [0.5, 1, 1.5, 2],
    aspectRatio: "21:9",
    controlBar: {
      remainingTimeDisplay: true,
    },
  };

  return (
    <>
      {loading
        ? "Loading..."
        : source && (
            <VideoJsPlayer
              source={source}
              keySystem={key}
              options={videoSrc}
              dType={dType}
            />
          )}
    </>
  );
}

// import React, { useEffect, useState } from "react";
// import VideoJsPlayer from "./VideoPlayer/VideoJsPlayer.component";

// import axios from "axios";
// // for Test

// export default function VIdeoPlayer() {
//   const [source, setSource] = useState(null);
//   const [loading, setLoading] = useState();

//   const [key, setkey] = useState();
//   function getSupportedDRMSystems() {
//     if (typeof navigator.requestMediaKeySystemAccess === "function") {
//       // Define the supported configurations
//       const supportedConfig = [
//         {
//           initDataTypes: ["cenc"],
//           // audioCapabilities: [{
//           //     contentType: '"audio/mp4;codecs="mp4a.40.2"'
//           // }],
//           videoCapabilities: [
//             {
//               contentType: 'video/mp4;codecs="avc1.42E01E"',
//             },
//           ],
//         },
//       ];
//       const drmSystems = [
//         // 'org.w3.clearkey',
//         // 'com.widevine.alpha',
//         "com.microsoft.playready",
//         "com.apple.fps",
//         "com.apple.fps.1_0",
//         // 'com.adobe.primetime'
//         // Add more DRM system identifiers as needed
//       ];

//       const supportedDRM = [];

//       // Use Promise.all to check support for each DRM system
//       return Promise.all(
//         drmSystems.map((drmSystem) => {
//           return navigator
//             .requestMediaKeySystemAccess(drmSystem, supportedConfig)
//             .then(() => {
//               supportedDRM.push(drmSystem);
//             })
//             .catch((error) => {
//               //DRM system is not supported, do nothing
//             });
//         })
//       ).then(() => {
//         return supportedDRM; // Return the list of supported DRM systems
//       });
//     } else {
//       // EME is not supported
//       return Promise.resolve([]);
//     }
//   }

//   const DRM = "4062415_0_6229345180670315";
//   const url = "https://api.videocrypt.com/getVideoDetailsDrm"; // api url
//   const token = DRM;

//   const formData = new FormData();
//   formData.append("flag", 1);
//   formData.append("name", token);

//   function axioFun(D_id) {
//     console.log(D_id);
//     axios({
//       url: url,
//       method: "POST",
//       headers: {
//         // 'accessKey': "7YKSVBMIGX5EWFPTQ0DL", //client accesskey
//         // 'secretKey': "+Jc1Rp/fLvz2HQF0m4wXaYnOiyThM5GWVZ=BKjU7",
//         // 'device-type': D_id,
//         // 'user-id': "10002380sddsds",
//         // 'device-id': '71d3548555586126ed7071102e663619',
//         // 'version': '2',
//         // 'device-name': 'ChromeCDM',
//         // 'account-id': '10002380',
//         // "Content-Type": 'multipart/form-data'

//         accessKey: "NUIzQVhZRDFGUkhFOU44UTcwTDI=",
//         secretKey: "NS80OFJ5RUl0dz1CbE5VNmczYVRvTVN4dnVLY1haWW43MkhiVnJlVw==",
//         "user-id": "TESTing_10000903_97",
//         "device-type": D_id,
//         "device-id": "71d3548555586126ed7071102e663619",
//         version: "2",
//         "device-name": "ChromeCDM",
//         "account-id": "10000903",
//         "Content-Type": "multipart/form-data",
//       },
//       data: formData,
//     })
//       .then((res) => {
//         setSource(res.data.data); // set video response

//         setLoading(false);
//       })
//       .catch((err) => console.log(err));
//   }
//   useEffect(() => {
//     setLoading(true);
//     getSupportedDRMSystems().then(function (supportedDRM) {
//       if (supportedDRM.length > 0) {
//         console.log("Supported DRM systems:");
//         supportedDRM.forEach((system) => {
//           if (system === "com.apple.fps") {
//             axioFun(2);
//             setdType(2);
//             setkey("com.apple.fps");
//             return false;
//           } else if (system === "com.microsoft.playready") {
//             console.log("Edge supported DRM");
//             axioFun(3);
//             setdType(3);
//             setkey("com.microsoft.playready");
//             return false;
//           } else {
//           }
//           console.log(system);
//         });
//       } else {
//         console.log("com.widevine.alpha");
//         axioFun(1);
//         setdType(1);
//         setkey("com.widevine.alpha");

//         console.log("No supported DRM systems found.");
//         setLoading(false);
//         return false;
//       }
//     });
//   }, []);

//   //video custom option
//   const videoSrc = {
//     autoplay: false,
//     controls: true,
//     responsive: true,
//     playbackRates: [0.5, 1, 1.5, 2],
//     aspectRatio: "21:9",
//     controlBar: {
//       remainingTimeDisplay: true,
//     },
//   };

//   return (
//     <>
//       {loading
//         ? "Loading..."
//         : source && (
//             <VideoJsPlayer
//               source={source}
//               keySystem={key}
//               options={videoSrc}

//             />
//           )}
//     </>
//   );
// }
