import { Fragment } from "react";
import "./footer_component.scss";

//Redux
import { connect } from "react-redux";
import { Link } from "react-router-dom";

const FooterComponent = (props) => {
  let { userPreference, userData, history } = props;
  return (
    <Fragment>
      <div className="footer_component_wrapper">
        <div className="footerTopMenuWrapper">
          <div className="container upper_layer">
            <div className="footerTopMenuLeft">
              <div className="footer_top_wrapper">
                <div className="container">
                  <div className="hdrTpInr">
                    <div className="hdrTopLeft">
                      <ul>
                        <li>
                          <Link
                            to={{
                              pathname: "/All_page_content",
                              state: { tab: "privacy_policy" },
                            }}
                            className="hdr_link"
                          >
                            <span>Privacy Policy</span>
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={{
                              pathname: "/All_page_content",
                              state: { tab: "terms" },
                            }}
                            className="hdr_link"
                          >
                            <span>Terms & Conditions</span>
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={{
                              pathname: "/All_page_content",
                              state: { tab: "faqs" },
                            }}
                            className="hdr_link"
                          >
                            <span>FAQS</span>
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={{
                              pathname:
                                "https://play.google.com/store/apps/details?id=www.motion.ac.in.student.parent",
                            }}
                            target="_blank"
                            className="hdr_link"
                          >
                            <span>Parent Guide</span>
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={{
                              pathname: "/All_page_content",
                              state: { tab: "AboutUs" },
                            }}
                            className="hdr_link"
                          >
                            <span>About Us</span>
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={{
                              pathname: "/All_page_content",
                              state: { tab: "contactUs" },
                            }}
                            className="hdr_link"
                          >
                            <span>Contact Us</span>
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={{
                              pathname: "/All_page_content",
                              state: { tab: "help-support" },
                            }}
                            className="hdr_link"
                          >
                            <span>Help & support</span>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.auth.user,
    userPreference: state.userPreference,
  };
};

export default connect(mapStateToProps)(FooterComponent);
