import { Fragment, useState, useEffect } from "react";
import "./subject_content_component.scss";

import images from "../../../../../utilities/images/images";
import axios from "axios";
import { isEmpty } from "../../../../../custom_utilities/custom_useful_functions";
import { BASE_URL } from "../../../../../custom_utilities/baseURL";
import { url_9, url_10 } from "../../../../../custom_utilities/api_services";
import { useLocation } from "react-router-dom";
//Redux
import { connect } from "react-redux";
import uiActions from "../../../../../redux/ui/action";
import userActivityActions from "../../../../../redux/user_activity/action";

const SubjectContentComponent = (props) => {
  let {
    history,
    userPreference,
    subjectData,
    setSubjectData,
    setTopicData,
    startLoading,
    finishLoading,
  } = props;
  let { courseDetails, classDetails } = userPreference;
  const location = useLocation();
  let freeCourseId = location.courseId;
  const [state, setState] = useState({
    subjectDataDetails: {
      subjectId: "",
      subjectName: "",
      subjectIcon: "",
    },
    subjectList: {},
    topicList: {},
    initialSubjectCheck: true,
  });

  useEffect(() => {
    getSubjectData();
    getSubjectList();
  }, []);

  useEffect(() => {
    if (
      !isEmpty(state.subjectList) &&
      state.subjectList.subject.length &&
      state.initialSubjectCheck
    ) {
      getInitialData();
    }
  }, [state.subjectList]);

  const getInitialData = () => {
    let { subjectId } = subjectData;
    let { subjectList } = state;
    setState((prevState) => ({ ...prevState, initialSubjectCheck: false }));


    if (subjectId) {
      getSubjectData();
      getTopics(courseDetails[0].course_id, subjectId);
    } else {
      setSubjectData({
        subjectId: subjectList.subject[0].sub_id,
        subjectName: subjectList.subject[0].sub_name,
        subjectIcon: subjectList.subject[0].icon,
      });
      setState((prevState) => ({
        ...prevState,
        subjectDataDetails: {
          ...prevState.subjectDataDetails,
          subjectId: subjectList.subject[0].sub_id,
          subjectName: subjectList.subject[0].sub_name,
          subjectIcon: subjectList.subject[0].icon,
        },
      }));
      getTopics(courseDetails[0].course_id, subjectList.subject[0].sub_id);
    }
  };

  const getSubjectData = () => {
    setState((prevState) => ({
      ...prevState,
      subjectDataDetails: {
        ...prevState.subjectDataDetails,
        subjectId: subjectData.subjectId,
        subjectName: subjectData.subjectName,
        subjectIcon: subjectData.subjectIcon,
      },
    }));
  };

  const getSubjectList = async () => {
    let data = {
      course_id: location.courseId?freeCourseId:courseDetails[0].course_id,
      class_id: classDetails.class_id,
    };
    startLoading();
    try {
      const response = await axios.post(url_9, data);
      // const response = await axios.post(
      //   `${BASE_URL}/api/user/UserSubject`,
      //   data
      // );

      if (response.data.status === 200) {
        finishLoading();
        setState((prevState) => ({
          ...prevState,
          subjectList: response.data.data,
        }));
        if (location.courseId) {
          const { sub_id, sub_name, icon } = response.data.data.subject[0];
           handleChangeSubject("",sub_id,sub_name,icon)
        }
      }
    } catch (error) {
      finishLoading();
      console.log(error);
    }
  };

  const getTopics = async (course_id, sub_id) => {
    let data = {
      course_id: location.courseId?freeCourseId:course_id,
      subject_id: sub_id,
    };
    startLoading();
    try {
      const response = await axios.post(url_10, data);
      // const response = await axios.post(
      //   `${BASE_URL}/api/user/UserSubjectTopic`,
      //   data
      // );

      if (response.data.status === 200) {
        finishLoading();
        setState((prevState) => ({
          ...prevState,
          topicList: response.data.data,
        }));
      }
    } catch (error) {
      finishLoading();
      console.log(error);
    }
  };

  const handleChangeSubject = (event, sub_id, sub_name, icon) => {
    setSubjectData({
      subjectId: sub_id,
      subjectName: sub_name,
      subjectIcon: icon,
    });
    setState((prevState) => ({
      ...prevState,
      subjectDataDetails: {
        ...prevState.subjectDataDetails,
        subjectId: sub_id,
        subjectName: sub_name,
        subjectIcon: icon,
      },
    }));

    getTopics(courseDetails[0].course_id, sub_id);
  };

  const handleTopicChange = (topic_id, topic_name, icon, is_video_available, is_live_class_available, is_excersice_available, is_notes_available, is_dpp_available, is_additional_video_available) => {
    setTopicData({ topicId: topic_id, topicName: topic_name, topicIcon: icon, topicVideo: is_video_available,is_live_class_available, is_excersice_available, is_notes_available, is_dpp_available, is_additional_video_available });
    history.push({
      pathname: "/topic_component",
      state: location.courseId,
    });
  };

  
  const handleBackBtn = () => {
    history.push("/user_dashboard");
  };

 

  return (
    <Fragment>
      <div className="subjectContentComponent_wrapper">
        <div className="container">
          <div className="subjectContentComponent_inner_wrapper">
            <div className="sec_2_wrapper">
              <div className="sec_2_inner_wrapper">
                <div className="content_wrapper">
                  <div className="left_side_content_wrapper">
                    <div className="subject_collection_wrapper">
                      {!isEmpty(state.subjectList)
                        ? state.subjectList.subject.length
                          ? state.subjectList.subject.map((element, index) => {
                              return (
                                <div
                                  key={index}
                                  className="single_select_subject"
                                >
                                  <div className="subject_icon">
                                    <img src={element.icon} alt="icon" />
                                  </div>
                                  <input
                                    type="radio"
                                    id={`subject_${element.sub_id}`}
                                    name="subject"
                                    value={element.sub_id}
                                    checked={
                                      element.sub_id ===
                                      parseInt(
                                        state.subjectDataDetails.subjectId
                                      )
                                    }
                                    onChange={(event) =>
                                      handleChangeSubject(
                                        event,
                                        element.sub_id,
                                        element.sub_name,
                                        element.icon
                                      )
                                    }
                                  />
                                  <label htmlFor={`subject_${element.sub_id}`}>
                                    {element.sub_name}
                                  </label>
                                </div>
                              );
                            })
                          : null
                        : null}
                    </div>
                  </div>
                  <div className="right_side_content_wrapper">
                    <div className="topic_collection_wrapper">
                      {!isEmpty(state.topicList) ? (
                        state.topicList.topic.length ? (
                          state.topicList.topic.map((element, index) => {
                            return (
                              <div
                                key={index}
                                className="single_topic_wrapper"
                                onClick={() =>
                                  handleTopicChange(
                                    element.topic_id,
                                    element.topic_name,
                                    element.icon,
                                    element.is_video_available,
                                    element.is_live_class_available, 
                                    element.is_excersice_available, 
                                    element.is_notes_available, 
                                    element.is_dpp_available,
                                    element.is_additional_video_available,
                                  )
                                }
                              >
                                <div className="topic_description_wrapper">
                                  <div className="topic_details_wrapper">
                                    <div className="text_content_wrapper">
                                      <p className="text_content">
                                        {element.topic_name}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="caret_wraper">
                                    <img src={images.right_Ar} alt="arrow" />
                                  </div>
                                </div>
                              </div>
                            );
                          })
                        ) : (
                          <div className="no_data_found_wrapper">
                            <img src={images.no_data} />
                          </div>
                        )
                      ) : (
                        <div className="no_data_found_wrapper">
                          <img src={images.no_data} />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    subjectData: state.userActivity.subjectData,
  };
};

const mapDipatchToProps = (dispatch) => {
  return {
    startLoading: () => {
      dispatch(uiActions.startLoading());
    },
    finishLoading: () => {
      dispatch(uiActions.finishLoading());
    },
    setSubjectData: (payload) => {
      dispatch(userActivityActions.setSubjectData(payload));
    },
    setTopicData: (payload) => {
      dispatch(userActivityActions.setTopicData(payload));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDipatchToProps
)(SubjectContentComponent);
