import React, { useState } from "react";
import "./HelpAndSupport.scss";
import SupportCenterInfo from "./supportCenterInfo/SupportCenterInfo";
import Chatbox from "./chatbox/Chatbox";

const HelpAndSupport = () => {
  const [state, setState] = useState({
    step: 1,
  });

  const handleStepTwo = () => {
    setState((prevState) => ({
      ...prevState,
      step: 2,
    }));
  };

  const handleStepOne = () => {
    setState((prevState) => ({
      ...prevState,
      step: 1,
    }));
  };

  return (
    <div className="help-supportWrpr">
      {state.step === 1 && <SupportCenterInfo handleStepTwo={handleStepTwo} />}
      {state.step === 2 && (
        <Chatbox handleStepOne={handleStepOne} handleStepTwo={handleStepTwo} />
      )}
    </div>
  );
};

export default HelpAndSupport;
