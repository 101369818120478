import { React, useEffect, useState } from "react";
import "./Customized_practise_sheet.scss";
import images from "../../../../../../utilities/images/images";
import { isEmpty } from "../../../../../../custom_utilities/custom_useful_functions";
import { url_83 } from "../../../../../../custom_utilities/api_services";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";
//Redux
import { connect } from "react-redux";
import uiActions from "../../../../../../redux/ui/action";
import userActivityActions from "../../../../../../redux/user_activity/action";

const Customized_practise_sheet = (props) => {
  let { userPreference, setPyqTopicData, setPyqTestsData } = props;
  let history = useHistory();
  const [state, setState] = useState({
    pyqPaperList: {},
    topicSubjectList: {},
  });

  const pyqSubjectTopics = (sub_id) => {
    setPyqTopicData({ subId: sub_id });
    history.push("/Cps_subjectTopic");
  };

  useEffect(() => {
    getTopicSubjectList();
  }, []);

  //subjectList
  const getTopicSubjectList = async () => {
    let { courseDetails, classDetails } = userPreference;
    let data = {
      course_id: courseDetails[0].course_id,
      class_id: classDetails.class_id,
    };
    // startLoading();
    try {
      const response = await axios.post(url_83, data);
      if (response.data.status === 200) {
        // finishLoading();
        setState((prevState) => ({
          ...prevState,
          topicSubjectList: response.data.data,
        }));
      }
    } catch (error) {
      // finishLoading();
      console.log(error);
    }
  };
  let { subject: subjectList } = state.topicSubjectList;
  return (
    <>
      <div className="topicWiseWrapper">
        {!isEmpty(subjectList)
          ? subjectList.length
            ? subjectList.map((elem, index) => {
                return (
                  <div
                    key={index}
                    className="topicBox"
                    onClick={() => pyqSubjectTopics(elem.sub_name)}
                  >
                    <div className="topicNameImg">
                      <div className="img_wrp">
                        <img src={elem.icon} alt="icon" />
                      </div>
                      <h5>{elem.sub_name}</h5>
                    </div>
                    <div className="arrow">
                      <img src={images.right_Ar} alt="arrow" />
                    </div>
                  </div>
                );
              })
            : null
          : null}
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    userPreference: state.userPreference,
  };
};
const mapDipatchToProps = (dispatch) => {
  return {
    startLoading: () => {
      dispatch(uiActions.startLoading());
    },
    finishLoading: () => {
      dispatch(uiActions.finishLoading());
    },
    setPyqTopicData: (payload) => {
      dispatch(userActivityActions.setPyqTopicData(payload));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDipatchToProps
)(Customized_practise_sheet);
