import { Fragment } from "react";
import "./recorded_live_stream_video_list_view.scss";

const RecordedLiveStreamVideoListView = (props) => {
  let { recordedVideoCollection, handleClickRecordedVideo } = props;

  return (
    <Fragment>
      <div className="recordedLiveStreamVideoListView_wrapper">
        <div className="recordedLiveStreamVideoListView_inner_wrapper">
          <div className="recordedVideoCollection_wrapper">
            {recordedVideoCollection.length
              ? recordedVideoCollection.map((element, index) => {
                  return (
                    <div key={index} className="single_recorded_video_wrapper">
                      <div className="left_side_content_wrapper">
                        <div className="text_content_wrapper">
                          <p className="text_content">{`Recording ${
                            index + 1
                          }`}</p>
                        </div>
                      </div>
                      <div className="right_side_content_wrapper">
                        <div className="btn_wrapper">
                          <button
                            type="button"
                            onClick={() => handleClickRecordedVideo(element)}
                          >
                            Watch
                          </button>
                        </div>
                      </div>
                    </div>
                  );
                })
              : "No recordings"}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default RecordedLiveStreamVideoListView;
