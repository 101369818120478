import { Fragment, useState, useEffect } from "react";
import "./practiceTest_questions_view.scss";
import ProgressBar from "react-bootstrap/ProgressBar";
import { Button, Modal } from "react-bootstrap";
import images from "../.././../../../../../utilities/images/images";
import { isEmpty } from "../../../../../../../custom_utilities/custom_useful_functions";
import ShakaSolutionViewVideoPlayer from "../../../../../../../custom_utilities/shakaVideoPlayer/ShakaSolutionViewVideoPlayer";
import useStopwatch from "../../../../../../../hooks/useStopwatch";
import useMathjax from "../../../../../../../hooks/useMathjax";

const TestQuestionsView = (props) => {
  let {
    testQuestionsDetails,
    currentQuestion,
    countDownTimer,
    handlePreviousQuestionBtn,
    handleNextQuestionBtn,
    handleSaveQuestion,
    handleSingleSelectAnswer,
    handleMultiSelectAnswer,
    handleInputFieldChange,
    handleReview,
    handleSaved,
    handleGuessedAnswer,
    handleQuestionJump,
    handleSubmitTest,
    subjecSectiontWiseTestQuestions,
    handleSectionChange,
    handleSubjectChange,
    selectedSubject,
    selectedSection,
    checkQuestion,
    userData,
    viewVideo,
    handleViewVideo,
    setViewVideoFalse,
  } = props;

  const {
    stopwatchText,
    updateTime,
    updateSolution,
    getClicked,
    solDisabled,
    showSolution,
    textInputSolution,
  } = useStopwatch(
    currentQuestion,
    testQuestionsDetails[currentQuestion].integer_answer
  );

  const scrollUp = () => {
    var element = document.getElementsByClassName(
      "questions_serial_number_collection"
    )[0];
    element.scrollTo({
      top: `${(currentQuestion + 1) * 80}`,
      behavior: "smooth",
    });
  };
  const scrollUp2 = () => {
    var element = document.getElementsByClassName(
      "questions_serial_number_collection"
    )[0];
    element.scrollTo({
      top: `${(currentQuestion + 1) * 80 - 160}`,
      behavior: "smooth",
    });
  };
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [lang, setLang] = useState(true);
  const [sol, setSol] = useState(true);
  const [disabled1, setDisabled] = useState(false);

  useMathjax(currentQuestion, lang, getClicked());

  const handleSol = () => {
    setSol(true);
    setDisabled(false);
  };
  const handleSolution = () => {
    setSol(false);
    //classEditor();
  };
  const disableSol = () => {
    setDisabled(true);
  };
  const renderOptions = () => {
    if (testQuestionsDetails.length) {
      let questionsArr = testQuestionsDetails;

      return questionsArr.map((val, i) => {
        let step, type;
        type = val.answer_type;
        if (type.toLowerCase() === "single correct") {
          step = 1;
        } else if (type.toLowerCase() === "integer correct") {
          step = 2;
        } else {
          step = 3;
        }
        switch (step) {
          case 1:
            return renderSingleSelect(questionsArr[i], i);
          case 2:
            return renderInputField(i);
          case 3:
            return renderMultiSelect(questionsArr[i], i);
          default:
            return null;
        }
      });
    } else {
      return null;
    }
  };
  const classEditor = (s, wc, optionId) => {
    if (!sol && s && getClicked()) {
      return "single_option_wrapper classCorrect";
    } else if (!sol && wc && wc.indexOf(optionId) > -1 && getClicked()) {
      return "single_option_wrapper classWrong";
    } else {
      return "single_option_wrapper";
    }
  };

  // const handlePurticularQuestion = () => {
  //   if (testQuestionsDetails[currentQuestion - 1].userAnswerCollection.length) {
  //     handleSolution();
  //   }
  // };

  const handlePurticularQuestion = () => {
    if (
      testQuestionsDetails[currentQuestion - 1].userAnswerCollection.length ||
      testQuestionsDetails[currentQuestion - 1].isAttempted === true
    ) {
      handleSolution();
    } else {
      handleSol();
    }
  };

  const handlePurticularQuestion3 = () => {
    if (
      testQuestionsDetails[currentQuestion + 1].userAnswerCollection.length ||
      testQuestionsDetails[currentQuestion + 1].isAttempted === true
    ) {
      handleSolution();
    } else {
      handleSol();
    }
  };

  const handlePurticularQuestion2 = (currentQuestion) => {
    if (testQuestionsDetails[currentQuestion].userAnswerCollection.length) {
      handleSolution();
    } else {
      handleSol();
    }
  };

  // const renderSingleSelect = (questionData, currentQuestion2) => {
  //   console.log("renderSingleSelect option", questionData);
  //   return (
  //     <Fragment>
  //       <div
  //         className={
  //           currentQuestion2 === currentQuestion
  //             ? "single_select_option_collection_wrapper show"
  //             : "single_select_option_collection_wrapper hide"
  //         }
  //       >
  //         {questionData.option.length
  //           ? questionData.option.map((element, index) => {
  //               return (
  //                 <div key={index} className="single_select">
  //                   <input
  //                     type="checkbox"
  //                     id={element.option_id}
  //                     name={`current_question_${currentQuestion2}`}
  //                     checked={
  //                       testQuestionsDetails[currentQuestion2]
  //                         .userAnswerCollection.length
  //                         ? testQuestionsDetails[currentQuestion2]
  //                             .userAnswerCollection[0].option_id ===
  //                           element.option_id
  //                         : element.is_user_correct_ans == 1
  //                         ? true
  //                         : false
  //                     }
  //                     // checked={element.is_user_correct_ans == 1}
  //                     onChange={(event) => {
  //                       handleSingleSelectAnswer(event, element.option_id);
  //                       disableSol();
  //                     }}
  //                   />
  //                   <label
  //                     htmlFor={element.option_id}
  //                     className={classEditor(
  //                       element.is_correct,
  //                       testQuestionsDetails[currentQuestion2]
  //                         .wrongAnswerIdCollection,
  //                       element.option_id
  //                     )}
  //                   >
  //                     <div className="option_initial">
  //                       <p className="text_content_2">{`${renderOptionInitial(
  //                         index
  //                       )}.`}</p>
  //                     </div>
  //                     <div className="option_final">
  //                       <p
  //                         className="text_content_3"
  //                         dangerouslySetInnerHTML={renderOptionText(
  //                           element.option
  //                         )}
  //                       ></p>
  //                     </div>
  //                   </label>
  //                 </div>
  //               );
  //             })
  //           : null}
  //       </div>
  //     </Fragment>
  //   );
  // };

  const renderSingleSelect = (questionData, currentQuestion2) => {
    const options = lang ? questionData?.option : questionData.option_hindi;
    // console.log("renderSingleSelect options", options);

    return (
      <Fragment>
        <div
          className={
            currentQuestion2 === currentQuestion
              ? "single_select_option_collection_wrapper show"
              : "single_select_option_collection_wrapper hide"
          }
        >
          {options.length
            ? options.map((element, index) => {
                return (
                  <div key={index} className="single_select">
                    <input
                      type="checkbox"
                      id={element.option_id}
                      name={`current_question_${currentQuestion2}`}
                      checked={
                        testQuestionsDetails[currentQuestion2]
                          .userAnswerCollection.length
                          ? testQuestionsDetails[currentQuestion2]
                              .userAnswerCollection[0].option_id ===
                            element.option_id
                          : element.is_user_correct_ans === 1
                          ? true
                          : false
                      }
                      // checked={element.is_user_correct_ans == 1}
                      onChange={(event) => {
                        handleSingleSelectAnswer(event, element.option_id);
                        showSolution(currentQuestion);
                        disableSol();
                      }}
                    />
                    <label
                      htmlFor={element.option_id}
                      className={classEditor(
                        element.is_correct,
                        testQuestionsDetails[currentQuestion2]
                          .wrongAnswerIdCollection,
                        element.option_id
                      )}
                    >
                      <div className="option_initial">
                        <p className="text_content_2">{`${renderOptionInitial(
                          index
                        )}.`}</p>
                      </div>
                      <div className="option_final">
                        <p
                          className="text_content_3"
                          dangerouslySetInnerHTML={renderOptionText(
                            element.option
                          )}
                        ></p>
                      </div>
                    </label>
                  </div>
                );
              })
            : null}
        </div>
      </Fragment>
    );
  };

  const renderMultiSelect = (questionData, currentQuestion2) => {
    const options = lang ? questionData?.option : questionData.option_hindi;
    return (
      <Fragment>
        <div
          className={
            currentQuestion2 == currentQuestion
              ? "multi_select_option_collection_wrapper show"
              : "multi_select_option_collection_wrapper hide"
          }
        >
          {options.length
            ? options.map((element, index) => {
                return (
                  <div key={index} className="single_select">
                    <input
                      type="checkbox"
                      id={element.option_id}
                      name={`current_question_${currentQuestion2}`}
                      checked={
                        testQuestionsDetails[currentQuestion2]
                          .userAnswerCollection.length
                          ? testQuestionsDetails[
                              currentQuestion2
                            ].userAnswerCollection.find((item) => {
                              return item.option_id === element.option_id;
                            })
                          : false
                      }
                      onChange={(event) => {
                        handleMultiSelectAnswer(event, element.option_id);
                        showSolution(currentQuestion);
                        disableSol();
                      }}
                    />
                    <label
                      htmlFor={element.option_id}
                      className={classEditor(
                        element.is_correct,
                        testQuestionsDetails[currentQuestion2]
                          .wrongAnswerIdCollection,
                        element.option_id
                      )}
                    >
                      <div className="option_initial">
                        <p className="text_content_2">{`${renderOptionInitial(
                          index
                        )}.`}</p>
                      </div>
                      <div className="option_final">
                        <p
                          className="text_content_3"
                          dangerouslySetInnerHTML={renderOptionText(
                            element.option
                          )}
                        ></p>
                      </div>
                    </label>
                  </div>
                );
              })
            : null}
        </div>
      </Fragment>
    );
  };

  const renderInputField = (currentQuestion2) => {
    return (
      <Fragment>
        <div
          className={
            currentQuestion2 == currentQuestion
              ? "input_wrapper show2"
              : "input_wrapper hide"
          }
        >
          <div className="input_inner_wrapper">
            <input
              key={currentQuestion2}
              //type="number"
              placeholder="Enter your answer"
              value={testQuestionsDetails[currentQuestion2].integer_answer}
              onChange={(event) => {
                textInputSolution(event.target.value);
                handleInputFieldChange(
                  event.target.value.replace(/[^0-9.-]/g, "").slice(0, 12)
                );
                disableSol();
              }}
              disabled={checkQuestion ? false : true}
            />
          </div>
        </div>
      </Fragment>
    );
  };

  const renderQuestionText = () => {
    if (testQuestionsDetails.length) {
      let questionsArr = testQuestionsDetails;
      return {
        __html: lang
          ? questionsArr[currentQuestion].q_text
          : questionsArr[currentQuestion].q_text_hindi !== null
          ? questionsArr[currentQuestion].q_text_hindi
          : questionsArr[currentQuestion].q_text,
      };
    } else {
      return null;
    }
  };

  const renderOptionInitial = (index) => {
    let alphabetArr = [
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
      "M",
      "N",
      "O",
      "P",
      "Q",
      "R",
      "S",
      "T",
      "U",
      "V",
      "W",
      "X",
      "Y",
      "Z",
    ];

    return alphabetArr[index];
  };

  const renderOptionText = (optionText) => {
    return { __html: optionText };
  };

  const renderQuestionNumber = (index) => {
    if (index <= 8) {
      return `${index + 1}.`;
    } else {
      return `${index + 1}.`;
    }
  };

  const renderQuestionLabel = (question, index) => {
    if (currentQuestion === index) {
      return (
        <label
          className="unanswered_lbl current_lbl"
          onClick={() => {
            updateTime();
            handleQuestionJump(index);
            handlePurticularQuestion2(index);
          }}
        >
          <div className="questionBox">
            {`${index + 1}.`}
            <p
              dangerouslySetInnerHTML={{
                __html: lang
                  ? question.q_text
                  : question.q_text_hindi !== null
                  ? question.q_text_hindi
                  : question.q_text,
              }}
            ></p>
          </div>
          <img className="arrow" src={images.right_Ar} />
        </label>
      );
    } else {
      if (question.isAttempted) {
        if (question.isReview) {
          return (
            <label
              className="unanswered_lbl review_lbl"
              onClick={() => {
                updateTime();
                handleQuestionJump(index);
                handleSol();
              }}
            >
              <div className="questionBox">
                <div className="questionBox">
                  {`${index + 1}.`}
                  <p
                    dangerouslySetInnerHTML={{
                      __html: question.q_text,
                    }}
                  ></p>
                </div>
                <img className="arrow" src={images.right_Ar} />
              </div>
            </label>
          );
        } else if (question.isAnswerGuessed) {
          return (
            <label
              className="unanswered_lbl isGussedAnswer"
              onClick={() => {
                updateTime();
                handleQuestionJump(index);
                handleSol();
              }}
            >
              <div className="questionBox">
                {`${index + 1}.`}
                <p
                  dangerouslySetInnerHTML={{
                    __html: question.q_text,
                  }}
                ></p>
              </div>
              <img className="arrow" src={images.right_Ar} />
            </label>
          );
        } else {
          return (
            <label
              className="unanswered_lbl answered_lbl"
              onClick={() => {
                updateTime();
                handleQuestionJump(index);
                handlePurticularQuestion2(index);
              }}
            >
              <div className="questionBox">
                {`${index + 1}.`}
                <p
                  dangerouslySetInnerHTML={{
                    __html: question.q_text,
                  }}
                ></p>
              </div>
              <img className="arrow" src={images.right_Ar} />
            </label>
          );
        }
      } else if (question.isAnswerGuessed) {
        return (
          <label
            className="unanswered_lbl isGussedAnswer"
            onClick={() => {
              updateTime();
              handleQuestionJump(index);
              handleSol();
            }}
          >
            <div className="questionBox">
              {`${index + 1}.`}
              <p
                dangerouslySetInnerHTML={{
                  __html: question.q_text,
                }}
              ></p>
            </div>
            <img className="arrow" src={images.right_Ar} />
          </label>
        );
      } else {
        if (question.isReview) {
          return (
            <label
              className="unanswered_lbl review_lbl"
              onClick={() => {
                updateTime();
                handleQuestionJump(index);
                handleSol();
              }}
            >
              <div className="questionBox">
                {`${index + 1}.`}
                <p
                  dangerouslySetInnerHTML={{
                    __html: question.q_text,
                  }}
                ></p>
              </div>
              <img className="arrow" src={images.right_Ar} />
            </label>
          );
        } else if (question.isSkipped) {
          return (
            <label
              className="unanswered_lbl skip_lbl"
              onClick={() => {
                updateTime();
                handleQuestionJump(index);
                handleSol();
              }}
            >
              <div className="questionBox">
                {`${index + 1}.`}
                <p
                  dangerouslySetInnerHTML={{
                    __html: question.q_text,
                  }}
                ></p>
              </div>
              <img className="arrow" src={images.right_Ar} />
            </label>
          );
        } else if (
          question.option?.filter((m) => m.is_user_correct_ans == 1).length ||
          question.integer_answer?.length
        ) {
          return (
            <label
              className="unanswered_lbl answered_lbl"
              onClick={() => {
                updateTime();
                handleQuestionJump(index);
                handlePurticularQuestion2(index);
              }}
            >
              <div className="questionBox">
                {`${index + 1}.`}
                <p
                  dangerouslySetInnerHTML={{
                    __html: question.q_text,
                  }}
                ></p>
              </div>
              <img className="arrow" src={images.right_Ar} />
            </label>
          );
        } else {
          return (
            <label
              className="unanswered_lbl"
              onClick={() => {
                updateTime();
                handleQuestionJump(index);
                handlePurticularQuestion2(index);
              }}
            >
              <div className="questionBox">
                {`${index + 1}.`}
                <p
                  dangerouslySetInnerHTML={{
                    __html: question.q_text,
                  }}
                ></p>
              </div>
              <img className="arrow" src={images.right_Ar} />
            </label>
          );
        }
      }
    }
  };

  const handleQuestionType = () => {
    if (testQuestionsDetails.length) {
      let questionsArr = testQuestionsDetails;
      return questionsArr[currentQuestion].answer_type;
    } else {
      return null;
    }
  };

  const handleSubTopicText = () => {
    if (testQuestionsDetails.length) {
      let questionsArr = testQuestionsDetails;

      if (questionsArr[currentQuestion].hasOwnProperty("sub_topic")) {
        return questionsArr[currentQuestion].sub_topic;
      } else {
        return null;
      }
    } else {
      return null;
    }
  };
  let progressCount = (100 / testQuestionsDetails.length) * currentQuestion + 1;
  // const config = {
  //   //loader: { load: ["[tex]/html"] },
  //   loader: {
  //     load: [
  //       "input/asciimath",
  //       "output/chtml",
  //       "ui/menu",
  //       "[mml]/mml3",
  //       "[tex]/html",
  //     ],
  //   },
  // };

  const config = {
    "fast-preview": {
      disabled: true
    },
    tex2jax: {
      inlineMath: [
        ["$", "$"],
        ["\\(", "\\)"]
      ],
      displayMath: [
        ["$$", "$$"],
        ["\\[", "\\]"]
      ]
    },
    messageStyle: "none"
  };

  const handleOnLoad = () => {
    let frame = document.querySelector("#iframe");
    frame.contentWindow.postMessage(
      `{"activate": true,"userId":"${userData.user_id}"}`,
      "*"
    );
  };

  const renderFrame = (videoIdEnglish) => {
    const urlPattern = /^https:\/\/speed\.mogiio\.com\/embed3\/[0-9a-f]{24}$/;

    if (videoIdEnglish && urlPattern.test(videoIdEnglish)) {
      // Render iframe with selectedVideoId
      return (
        <div className="videoWrapper">
          <button className="view_videoSolution_btn" onClick={handleViewVideo}>
            View Video Solution
          </button>
          {viewVideo && (
            <iframe
              id="iframe"
              src={videoIdEnglish}
              onLoad={handleOnLoad}
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          )}{" "}
        </div>
      );
    } else {
      // Render ShakaSolutionViewVideoPlayer
      return (
        <div className="videoWrapper">
          <button className="view_videoSolution_btn" onClick={handleViewVideo}>
            View Video Solution
          </button>
          {viewVideo && (
            <ShakaSolutionViewVideoPlayer
              id_video={videoIdEnglish}
              className="shakaPlayer"
            />
          )}
        </div>
      );
    }
  };

  return (
    <Fragment>
      <div className="Exercise_questions_view_wrapper">
        <div className="test_questions_view_inner_wrapper">
          <div className="sec_2_wrapper">
            <div className="sec_2_inner_wrapper">
              <div className="section_content_wrapper">
                <div className="left_side_content_wrapper">
                  <div className="sub_section_1_wrapper">
                    <div className="text_content_wrapper">
                      <p className="text_content">All Questions</p>
                      <button
                        onClick={() => {
                          //handleSaveQuestion();
                          handleShow();
                        }}
                      >
                        Submit Test
                      </button>
                      {/* <div className="countdown_timer_container">
                        <span>
                          <label className="time_number">
                            {countDownTimer.hours}
                          </label>
                          <label className="time_partition">:</label>
                          <label className="time_number">
                            {countDownTimer.minutes}
                          </label>
                          <label className="time_partition">:</label>
                          <label className="time_number">
                            {countDownTimer.seconds > 9
                              ? countDownTimer.seconds
                              : `${"0" + countDownTimer.seconds}`}
                          </label>
                        </span>
                      </div> */}
                    </div>
                    <div className="sub_section_2_wrapper">
                      <div className="question_marking_wrapper">
                        <div className="single_marking">
                          <label className="box_pic box_pick_background_1"></label>
                          <label className="question_marking_text">
                            Completed
                          </label>
                        </div>
                        <div class="single_marking">
                          <label class="box_pic box_pick_background_3"></label>
                          <label class="question_marking_text">Pending</label>
                        </div>
                        <div className="single_marking">
                          <label className="box_pic box_pick_background_5"></label>
                          <label className="question_marking_text">
                            Mark For Review
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="questions_serial_number_collection">
                      {testQuestionsDetails.length
                        ? testQuestionsDetails.map((element, index) => {
                            return (
                              <div
                                key={index}
                                className="single_question_number_wrapper"
                              >
                                {renderQuestionLabel(element, index)}
                              </div>
                            );
                          })
                        : null}
                    </div>
                    {/* <div className="questions_serial_number_collection">
                      {subjecSectiontWiseTestQuestions[selectedSubject][
                        selectedSection
                      ]
                        ? subjecSectiontWiseTestQuestions[selectedSubject][
                            selectedSection
                          ].map((element, index) => {
                            return (
                              <div
                                key={index}
                                className="single_question_number_wrapper"
                              >
                                {renderQuestionLabel(element, index)}
                              </div>
                            );
                          })
                        : null}
                    </div> */}
                  </div>
                </div>
                <div className="right_side_content_wrapper">
                  <div className="sub_section_wrapper">
                    <div className="sub_sub_section_wrapper_1">
                      <div className="right_side_content_wrapper">
                        {/* <div className="review_image_wrapper">
                          {testQuestionsDetails[currentQuestion] &&
                          testQuestionsDetails[currentQuestion].isAnswerGuessed ? (
                            <img
                              src={images.evaluation_2}
                              onClick={() => handleReview()}
                            />
                          ) : (
                            <img
                              src={images.evaluation_4}
                              onClick={() => handleReview()}
                            />
                          )}
                        </div> */}
                        {/* <div className="bookmark_image_wrapper">
                          {testQuestionsDetails[currentQuestion] &&
                          testQuestionsDetails[currentQuestion].is_saved ? (
                            <img
                              src={images.user_activity_4}
                              onClick={() => handleSaved()}
                            />
                          ) : (
                            <img
                              src={images.user_activity_3}
                              onClick={() => handleSaved()}
                            />
                          )}
                        </div> */}
                      </div>
                    </div>
                    {/* <div className="sub_sub_section_wrapper_6">
                      <div className="text_content_wrapper">
                        <p className="text_content">{`Sub-Topic: ${handleSubTopicText()}`}</p>
                      </div>
                      <div className="text_content_wrapper">
                        <p className="text_content">{`Question Type: ${handleQuestionType()}`}</p>
                      </div>
                    </div> */}
                    <Modal
                      show={show}
                      onHide={handleClose}
                      className="onSubmitModal"
                      centered
                    >
                      <Modal.Header closeButton></Modal.Header>
                      <Modal.Body>
                        <div className="submit_Popup">
                          <div className="exit_img">
                            <img src={images.m2_exit} alt="" />
                          </div>
                          <h5>Are you Sure?</h5>
                          <p>
                            Do you want to continue the exam or you want to
                            Submit.
                          </p>
                          <div className="submitCancelBtn">
                            <button className="cancel" onClick={handleClose}>
                              Cancel
                            </button>
                            <button onClick={() => handleSubmitTest()}>
                              Submit
                            </button>
                          </div>
                        </div>
                      </Modal.Body>
                    </Modal>
                    <div className="testProgressIndicator">
                      <h4>Test Question progress Indicator</h4>
                      <div className="ProgressBar_Line">
                        <ProgressBar
                          now={progressCount}
                          className="progressBar"
                        />
                        <span style={{ left: progressCount - 1 + "%" }}>{`${
                          currentQuestion + 1
                        }`}</span>
                      </div>
                      <div className="questionPercentage">
                        <span>1</span>
                        <span>{testQuestionsDetails.length}</span>
                      </div>
                    </div>
                    <div className="sub_sub_section_wrapper_2">
                      <h2>Practice Test</h2>
                      <div className="text_content_wrapper">
                        <div className="typeLanguageWrapper">
                          <span>
                            Question type:{" "}
                            {testQuestionsDetails[currentQuestion]?.answer_type}
                          </span>
                          <span className="stopwatch_timer">
                            {stopwatchText}
                          </span>
                          <div className="markReview">
                            <div className="bookmark_image_wrapper">
                              <span>Mark For Review</span>
                              {testQuestionsDetails[currentQuestion] &&
                              testQuestionsDetails[currentQuestion]
                                .isAnswerGuessed ? (
                                <img
                                  src={images.user_activity_4}
                                  onClick={() => handleReview()}
                                  alt=""
                                />
                              ) : (
                                <img
                                  src={images.user_activity_3}
                                  onClick={() => handleReview()}
                                  alt=""
                                />
                              )}
                            </div>
                            {testQuestionsDetails[currentQuestion] &&
                            testQuestionsDetails[currentQuestion]
                              .q_text_hindi ? (
                              <button onClick={() => setLang(!lang)}>
                                <img src={images.m2_language} alt="" />
                              </button>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        <div className="questionBox">
                          <span className="text_content">
                            {`${Number(currentQuestion) + 1}.`}
                          </span>
                          {testQuestionsDetails.length
                            ? testQuestionsDetails.map((element, index) => {
                                return (
                                  <>
                                    <p
                                      className={
                                        index == currentQuestion && lang
                                          ? "show"
                                          : "hide"
                                      }
                                      key={element.qid + "en"}
                                      dangerouslySetInnerHTML={{
                                        __html: element.q_text,
                                      }}
                                    ></p>
                                    <p
                                      className={
                                        index == currentQuestion && !lang
                                          ? "show"
                                          : "hide"
                                      }
                                      key={element.qid + "hi"}
                                      dangerouslySetInnerHTML={{
                                        __html: element.q_text_hindi,
                                      }}
                                    ></p>
                                  </>
                                );
                              })
                            : null}
                        </div>
                      </div>
                    </div>
                    <div className="sub_sub_section_wrapper_3">
                      {renderOptions()}
                    </div>
                    {/* <div className="sub_sub_section_wrapper_4">
                    <div className="checkbox_wrapper">
                      <input
                        type="checkbox"
                        checked={
                          testQuestionsDetails[currentQuestion] &&
                          testQuestionsDetails[currentQuestion].isAnswerGuessed
                        }
                        onChange={(event) => handleGuessedAnswer(event)}
                      />
                      <label>Have you guessed the answer?</label>
                    </div>
                  </div> */}
                    <div className="sub_sub_section_wrapper_5">
                      <div className="left_side_wrapper">
                        {currentQuestion > 0 && (
                          <div className="btn_wrapper">
                            <button
                              onClick={() => {
                                updateTime();
                                scrollUp2();
                                handlePreviousQuestionBtn();
                                handlePurticularQuestion();
                                showSolution(currentQuestion - 1);
                              }}
                            >
                              Previous
                            </button>
                          </div>
                        )}
                      </div>
                      <div className="right_side_wrapper">
                        {testQuestionsDetails.length === currentQuestion + 1 ? (
                          <div className="btn_wrapper d-flex">
                            <button
                              disabled={solDisabled}
                              className={
                                solDisabled
                                  ? "opacity solution"
                                  : "true solution"
                              }
                              onClick={() => {
                                updateSolution(true);
                                handleSolution();
                              }}
                            >
                              Solution
                            </button>

                            <button
                              onClick={() => {
                                // handleSaveQuestion();
                                handleShow();
                              }}
                            >
                              Submit
                            </button>
                          </div>
                        ) : (
                          <div className="btn_wrapper d-flex">
                            {testQuestionsDetails[
                              currentQuestion
                            ]?.answer_type.toLowerCase() === "subjective" ? (
                              <>
                                <button
                                  className="solution"
                                  onClick={() => {
                                    updateSolution(true);
                                    handleSolution();
                                  }}
                                >
                                  Solution
                                </button>

                                <button
                                  onClick={() => {
                                    handleNextQuestionBtn();

                                    scrollUp();
                                    handlePurticularQuestion3();
                                    showSolution(currentQuestion + 1);
                                  }}
                                >
                                  Next
                                </button>
                              </>
                            ) : (
                              <>
                                <button
                                  disabled={solDisabled}
                                  className={
                                    solDisabled
                                      ? "opacity solution"
                                      : "true solution"
                                  }
                                  onClick={() => {
                                    updateSolution(true);
                                    handleSolution();
                                  }}
                                >
                                  Solution
                                </button>

                                <button
                                  onClick={() => {
                                    updateTime();
                                    handleNextQuestionBtn();

                                    scrollUp();
                                    handlePurticularQuestion3();
                                    showSolution(currentQuestion + 1);
                                  }}
                                >
                                  Next
                                </button>
                              </>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                    {getClicked() && (
                      <div className="solutionBoxWrapper">
                        {testQuestionsDetails[currentQuestion]?.answer_type ===
                        "Subjective" ? (
                          <>
                            <div className="QuestionSolution">
                              <h5>Solution:</h5>
                              {testQuestionsDetails.length
                                ? testQuestionsDetails.map((element, index) => {
                                    if (element.answer_type === "Subjective") {
                                      return (
                                        <>
                                          <p
                                            className={
                                              index == currentQuestion && lang
                                                ? "show"
                                                : "hide"
                                            }
                                            key={element.qid + "en"}
                                            dangerouslySetInnerHTML={{
                                              __html: element.solution,
                                            }}
                                          ></p>
                                          <p
                                            className={
                                              index == currentQuestion && !lang
                                                ? "show"
                                                : "hide"
                                            }
                                            key={element.qid + "hi"}
                                            dangerouslySetInnerHTML={{
                                              __html: element.solution_hindi,
                                            }}
                                          ></p>
                                        </>
                                      );
                                    } else {
                                      return <></>;
                                    }
                                  })
                                : null}

                              {/* <p dangerouslySetInnerHTML={{__html:testQuestionsDetails[currentQuestion].solution}}></p>
                      <p dangerouslySetInnerHTML={{__html:testQuestionsDetails[currentQuestion].solution_hindi}}></p> */}
                            </div>

                            {testQuestionsDetails[currentQuestion]?.video_id
                              ?.length
                              ? renderFrame(
                                  testQuestionsDetails[currentQuestion]
                                    ?.video_id
                                )
                              : ""}
                          </>
                        ) : (
                          <>
                            {
                              <>
                                <div className="QuestionSolution">
                                  <h5>Solution:</h5>

                                  {testQuestionsDetails.length
                                    ? testQuestionsDetails.map(
                                        (element, index) => {
                                          // console.log("elementelement", element);
                                          return (
                                            <div key={element.qid}>
                                              <p
                                                className={
                                                  index === currentQuestion &&
                                                  lang
                                                    ? "show"
                                                    : "hide"
                                                }
                                                dangerouslySetInnerHTML={{
                                                  __html: element.solution,
                                                }}
                                              ></p>

                                              {/* <p
                                            className={
                                              index === currentQuestion &&
                                              !lang
                                                ? "show"
                                                : "hide"
                                            }
                                            dangerouslySetInnerHTML={{
                                              __html: element.solution_hindi,
                                            }}
                                          ></p> */}

                                              <p
                                                className={
                                                  index === currentQuestion &&
                                                  (!lang ||
                                                    !element.solution_hindi) // Check if lang is false or Hindi solution is empty
                                                    ? "show"
                                                    : "hide"
                                                }
                                                dangerouslySetInnerHTML={{
                                                  __html:
                                                    !lang ||
                                                    !element.solution_hindi
                                                      ? element.solution
                                                      : element.solution_hindi,
                                                }}
                                              ></p>
                                            </div>
                                          );
                                        }
                                      )
                                    : null}
                                </div>

                                {testQuestionsDetails[currentQuestion]?.video_id
                                  ?.length
                                  ? renderFrame(
                                      testQuestionsDetails[currentQuestion]
                                        ?.video_id
                                    )
                                  : ""}
                              </>
                            }
                          </>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default TestQuestionsView;
