import * as actionTypes from "./action_type";

const initialState = {
  questionBankId: null,
  questionBankName: "",
  questionBankTestsData: {},
  questionBankResultData: {},
};

const QuestionBankReducer = (
  state = initialState,
  { type, payload = null }
) => {
  switch (type) {
    case actionTypes.SET_QUESTION_BANK_TESTS_DATA:
      return setQuestionBankTestsData(state, payload);

    case actionTypes.SET_QUESTION_BANK_TESTS_RESULT_DATA:
      return setQuestionBankTestsResultData(state, payload);

    case actionTypes.SET_QUESTION_BANK_ID:
      return setQuestionBankId(state, payload);

    case actionTypes.SET_QUESTION_BANK_NAME:
      return setQuestionBankName(state, payload);

    default:
      return state;
  }
};

const setQuestionBankTestsData = (state, payload) => {
  return (state = { ...state, questionBankTestsData: payload });
};

const setQuestionBankTestsResultData = (state, payload) => {
  state = { ...state, questionBankResultData: payload };
  return state;
};

const setQuestionBankId = (state, payload) => {
  state = {
    ...state,
    questionBankId: payload,
  };
  return state;
};

const setQuestionBankName = (state, payload) => {
  state = {
    ...state,
    questionBankName: payload,
  };
  return state;
};

export default QuestionBankReducer;
