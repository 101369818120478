import * as actionTypes from "./action_type";

const setOtsTestsData = (payload) => {
  return {
    type: actionTypes.SET_OTS_TESTS_DATA,
    payload,
  };
};

const setOtsTestsResultData = (payload) => {
  return {
    type: actionTypes.SET_OTS_TESTS_RESULT_DATA,
    payload,
  };
};

const setOtsTestsPaperResultData = (payload) => {
  return {
    type: actionTypes.SET_OTS_TESTS_PAPER_RESULT_DATA,
    payload,
  };
};

const setOtsPlannerData = (payload) => {
  return {
    type: actionTypes.SET_OTS_PLANNER_DATA,
    payload,
  };
};

const setOtsPremiumData = (payload) => {
  return {
    type: actionTypes.SET_OTS_PREMIUM_DATA,
    payload,
  };
};

const setOtsFreeData = (payload) => {
  return {
    type: actionTypes.SET_OTS_FREE_DATA,
    payload,
  };
};

export default {
  setOtsTestsData,
  setOtsTestsResultData,
  setOtsTestsPaperResultData,
  setOtsPlannerData,
  setOtsPremiumData,
  setOtsFreeData,
};
