import React from "react";
import "./QueSolution.scss";

const QuestionSolution = ({ currentSolutionDetails, lang }) => {
  return (
    <div className="solutionBoxWrapper">
      <h5>Solution:</h5>
      <p
        key={currentSolutionDetails.qid + "en"}
        dangerouslySetInnerHTML={{
          __html: currentSolutionDetails.sol_text,
        }}
      ></p>
      <p
        key={currentSolutionDetails.qid + "hi"}
        dangerouslySetInnerHTML={{
          __html: currentSolutionDetails.sol_text_hindi,
        }}
      ></p>
    </div>
  );
};

export default QuestionSolution;
