import { Fragment, useState, useEffect } from "react";
import "./user_create_custom_question_bank_content_component.scss";

import images from "../../../../utilities/images/images";
import {
  url_44,
  url_9,
  url_10,
  url_45,
} from "../../../../custom_utilities/api_services";
import { isEmpty } from "../../../../custom_utilities/custom_useful_functions";
import axios from "axios";

//Redux
import { connect } from "react-redux";
import uiActions from "../../../../redux/ui/action";

const UserCreateCustomQuestionBankContentComponent = (props) => {
  let { history, userPreference, startLoading, finishLoading } = props;

  const [state, setState] = useState({
    questionBankDetails: {
      questionBankTitle: "",
      questionBankType: 1,
      questionsCount: 5,
      difficultyLevel: 1,
      questionFromMcqBank: 5,
      selectedSubjectId: null,
      selectedTopics: [],
      selectAll: false,
    },
    difficultyLevelDetails: {},
    subjectList: {},
    topicList: {},
  });

  useEffect(() => {
    getSubjectlist();
  }, []);

  useEffect(() => {
    if (state.questionBankDetails.questionBankType === 1) {
      getDifficultyLevels();
    }
  }, [state.questionBankDetails.questionBankType]);

  const getSubjectlist = async () => {
    let requestPayload = {
      course_id: userPreference.courseDetails[0].course_id,
      class_id: userPreference.classDetails.class_id,
    };
    startLoading();
    try {
      const response = await axios.post(url_9, requestPayload);
      // console.log(response);
      if (response.data.status === 200) {
        finishLoading();
        setState((prevState) => ({
          ...prevState,
          subjectList: response.data.data,
        }));
      } else {
        finishLoading();
      }
    } catch (error) {
      finishLoading();
      console.log(error);
    }
  };

  const getDifficultyLevels = async () => {
    startLoading();
    try {
      const response = await axios.get(url_44);
      // console.log(response);
      if (response.data.status === 200) {
        finishLoading();
        setState((prevState) => ({
          ...prevState,
          difficultyLevelDetails: response.data.data,
        }));
      } else {
        finishLoading();
      }
    } catch (error) {
      finishLoading();
      console.log(error);
    }
  };

  const handleChangeQuestionBankTitle = (event) => {
    let { value } = event.target;

    setState((prevState) => ({
      ...prevState,
      questionBankDetails: {
        ...prevState.questionBankDetails,
        questionBankTitle: value,
      },
    }));
  };

  const handleChangeQuestionBankType = (event) => {
    let { value } = event.target;

    setState((prevState) => ({
      ...prevState,
      questionBankDetails: {
        ...prevState.questionBankDetails,
        questionBankType: Number(value),
        questionBankTitle: "",
        questionsCount: 10,
        difficultyLevel: 1,
        questionFromMcqBank: 5,
        selectedSubjectId: null,
        selectedTopics: [],
        selectAll: false,
      },
      topicList: {},
    }));
  };

  const handleChangeQuestionBankQuestionsCount = (event) => {
    let { value } = event.target;
    // console.log(value);

    setState((prevState) => ({
      ...prevState,
      questionBankDetails: {
        ...prevState.questionBankDetails,
        questionsCount: Number(value),
      },
    }));
  };

  const handleChangeDifficultyLevel = (event) => {
    let { value } = event.target;

    setState((prevState) => ({
      ...prevState,
      questionBankDetails: {
        ...prevState.questionBankDetails,
        difficultyLevel: Number(value),
      },
    }));
  };

  const handleChangeQuestionFromMcqBank = (event) => {
    let { value } = event.target;

    switch (Number(value)) {
      case 1:
      case 4:
      case 5:
        setState((prevState) => ({
          ...prevState,
          questionBankDetails: {
            ...prevState.questionBankDetails,
            questionFromMcqBank: Number(value),
            questionBankType: 1,
          },
        }));
        break;
      case 2:
      case 3:
        setState((prevState) => ({
          ...prevState,
          questionBankDetails: {
            ...prevState.questionBankDetails,
            questionFromMcqBank: Number(value),
            questionBankType: 2,
            difficultyLevel: 1,
          },
        }));
        break;
      default:
        break;
    }
  };

  const handleChangeSubject = (event) => {
    let { value } = event.target;

    setState((prevState) => ({
      ...prevState,
      questionBankDetails: {
        ...prevState.questionBankDetails,
        selectedSubjectId: Number(value),
        selectedTopics: [],
        selectAll: false,
      },
    }));
    getTopicsList(Number(value));
  };

  const getTopicsList = async (subjectId) => {
    let requestPayload = {
      course_id: userPreference.courseDetails[0].course_id,
      subject_id: subjectId,
    };
    startLoading();
    try {
      const response = await axios.post(url_10, requestPayload);
      // console.log(response);
      if (response.data.status === 200) {
        finishLoading();
        setState((prevState) => ({
          ...prevState,
          topicList: response.data.data,
        }));
      } else {
        finishLoading();
      }
    } catch (error) {
      finishLoading();
      console.log(error);
    }
  };

  const handleChangeTopic = (event) => {
    let { value } = event.target;
    let selectedTopics = [...state.questionBankDetails.selectedTopics];

    let findedIndex = selectedTopics.findIndex((element) => {
      return element === Number(value);
    });

    if (findedIndex === -1) {
      selectedTopics.splice(selectedTopics.length + 1, 0, Number(value));
    } else {
      selectedTopics.splice(findedIndex, 1);
    }

    // console.log(selectedTopics);

    setState((prevState) => ({
      ...prevState,
      questionBankDetails: {
        ...prevState.questionBankDetails,
        selectedTopics: selectedTopics,
      },
    }));
  };

  const handleCreateQuestionBankModule = async () => {
    let questionBankDetails = { ...state.questionBankDetails };

    // console.log(questionBankDetails);
    if (questionBankDetails.questionBankTitle) {
      if (
        questionBankDetails.selectedSubjectId &&
        questionBankDetails.selectedTopics.length
      ) {
        if (questionBankDetails.questionsCount > 0) {
          let requestPayload = {
            title: questionBankDetails.questionBankTitle.trim(),
            qb_type: questionBankDetails.questionBankType, // 1->Test 2->Exercise 3->Dpp
            no_of_question: questionBankDetails.questionsCount,
            question_type: questionBankDetails.questionFromMcqBank, //1->Bookmark 2->Incorrect 3->Correct 4->UnAttempt 5->All
            subject_id: questionBankDetails.selectedSubjectId,
            topic_id: questionBankDetails.selectedTopics,
          };

          if (questionBankDetails.questionBankType === 1) {
            requestPayload.toughness_id = questionBankDetails.difficultyLevel;
          } else {
            delete requestPayload.toughness_id;
          }
          startLoading();
          let returnedResponse = await createCustomQuestionBankModule(
            requestPayload
          );
          if (returnedResponse.result === 1) {
            finishLoading();
            history.push("/user_exercise_test");
          } else {
            finishLoading();
            alert(returnedResponse.data.message);
          }
        } else {
          alert("Question count should be greater than 0.");
        }
      } else {
        alert("Please select subject and topics properly");
      }
    } else {
      alert("Please fill the 'Question Bank Title' its mandatory.");
    }
  };

  const createCustomQuestionBankModule = async (requestPayload) => {
    try {
      const response = await axios.post(url_45, requestPayload);
      // console.log(response);
      if (response.data.status === 200) {
        return {
          result: 1,
          data: {
            message: response.data.message,
          },
        };
      } else {
        return {
          result: 0,
          data: {
            message: response.data.message,
          },
        };
      }
    } catch (error) {
      console.log(error);
      return {
        result: 0,
        data: { message: "Something Went wrong" },
      };
    }
  };

  const handleRedirect = () => {
    history.push("/user_exercise_test");
  };

  const handleSelectAllTopics = (event) => {
    let { checked } = event.target;
    let topicList = { ...state.topicList };
    let topics = [];

    if (checked) {
      topicList.topic.forEach((element) => {
        topics.push(element.topic_id);
      });
      setState((prevState) => ({
        ...prevState,
        questionBankDetails: {
          ...prevState.questionBankDetails,
          selectedTopics: topics,
          selectAll: checked,
        },
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        questionBankDetails: {
          ...prevState.questionBankDetails,
          selectedTopics: [],
          selectAll: checked,
        },
      }));
    }
  };

  return (
    <Fragment>
      <div className="userCreateCustomQuestionBankContentComponent_wrapper">
        <div className="userCreateCustomQuestionBankContentComponent_inner_wrapper">
          <div className="sec_1_wrapper">
            <div className="sec_1_inner_wrapper">
              <div className="btn_wrapper">
                <button onClick={() => handleRedirect()}>
                  <img src={images.user_activity_1} />
                </button>
              </div>
              <div className="text_content_wrapper">
                <p className="text_content">Create Own Question Bank</p>
              </div>
            </div>
          </div>
          <div className="sec_2_wrapper">
            <div className="sec_2_inner_wrapper">
              <div className="sub_content_wrapper_1">
                <div className="text_content_wrapper">
                  <p className="text_content">Question Bank Title</p>
                </div>
                <div className="input_wrapper">
                  <input
                    type="text"
                    placeholder="Enter your title..."
                    value={state.questionBankDetails.questionBankTitle}
                    onChange={(event) => handleChangeQuestionBankTitle(event)}
                  />
                </div>
              </div>
              {/* <div className="sub_content_wrapper_2">
                <div className="text_content_wrapper">
                  <p className="text_content">Choose Questions From</p>
                </div>
                <div className="format_collection_wrapper">
                  <div className="single_format_wrapper">
                    <input
                      type="radio"
                      id="dpp"
                      name="format"
                      value="3"
                      onChange={(event) => handleChangeQuestionBankType(event)}
                      checked={state.questionBankDetails.questionBankType === 3}
                    />
                    <label htmlFor="dpp">DPP</label>
                  </div>
                  <div className="single_format_wrapper">
                    <input
                      type="radio"
                      id="exercise"
                      name="format"
                      value="2"
                      onChange={(event) => handleChangeQuestionBankType(event)}
                      checked={state.questionBankDetails.questionBankType === 2}
                    />
                    <label htmlFor="exercise">Exercise</label>
                  </div>
                  <div className="single_format_wrapper">
                    <input
                      type="radio"
                      id="test"
                      name="format"
                      value="1"
                      onChange={(event) => handleChangeQuestionBankType(event)}
                      checked={state.questionBankDetails.questionBankType === 1}
                    />
                    <label htmlFor="test">Test</label>
                  </div>
                </div>
              </div> */}
              <div className="sub_content_wrapper_3">
                <div className="text_content_wrapper">
                  <p className="text_content">No. Of Questions You Want</p>
                </div>
                <div className="questions_count__wrapper">
                  <div className="questions_count_content_wrapper_1">
                    <div className="content_wrapper">
                      <label>{`${state.questionBankDetails.questionsCount} Questions`}</label>
                    </div>
                  </div>
                  <div className="questions_count_content_wrapper_2">
                    <div className="content_wrapper">
                      <label>0</label>
                    </div>
                    <div className="range_wrapper">
                      <input
                        type="range"
                        min="0"
                        max="100"
                        value={state.questionBankDetails.questionsCount}
                        onChange={(event) =>
                          handleChangeQuestionBankQuestionsCount(event)
                        }
                      />
                    </div>
                    <div className="content_wrapper">
                      <label>100</label>
                    </div>
                  </div>

                  {/* <div className="single_count_wrapper">
                    <input
                      type="radio"
                      id="10_questions"
                      name="questions_count"
                      value="10"
                      onChange={(event) =>
                        handleChangeQuestionBankQuestionsCount(event)
                      }
                      checked={state.questionBankDetails.questionsCount === 10}
                    />
                    <label htmlFor="10_questions">10</label>
                  </div>
                  <div className="single_count_wrapper">
                    <input
                      type="radio"
                      id="25_questions"
                      name="questions_count"
                      value="25"
                      onChange={(event) =>
                        handleChangeQuestionBankQuestionsCount(event)
                      }
                      checked={state.questionBankDetails.questionsCount === 25}
                    />
                    <label htmlFor="25_questions">25</label>
                  </div>
                  <div className="single_count_wrapper">
                    <input
                      type="radio"
                      id="50_questions"
                      name="questions_count"
                      value="50"
                      onChange={(event) =>
                        handleChangeQuestionBankQuestionsCount(event)
                      }
                      checked={state.questionBankDetails.questionsCount === 50}
                    />
                    <label htmlFor="50_questions">50</label>
                  </div>
                  <div className="single_count_wrapper">
                    <input
                      type="radio"
                      id="100_questions"
                      name="questions_count"
                      value="100"
                      onChange={(event) =>
                        handleChangeQuestionBankQuestionsCount(event)
                      }
                      checked={state.questionBankDetails.questionsCount === 100}
                    />
                    <label htmlFor="100_questions">100</label>
                  </div> */}
                </div>
              </div>
              {state.questionBankDetails.questionBankType === 1 ? (
                <div className="sub_content_wrapper_4">
                  <div className="text_content_wrapper">
                    <p className="text_content">Difficulty Level</p>
                  </div>
                  <div className="difficulty_level_collection_wrapper">
                    {!isEmpty(state.difficultyLevelDetails)
                      ? state.difficultyLevelDetails.DifficultyLevel.length
                        ? state.difficultyLevelDetails.DifficultyLevel.map(
                            (element, index) => {
                              return (
                                <div
                                  key={index}
                                  className="single_difficulty_level_wrapper"
                                >
                                  <input
                                    type="radio"
                                    id={`${element.toughness_id}_level`}
                                    name="difficulty_level"
                                    value={element.toughness_id}
                                    onChange={(event) =>
                                      handleChangeDifficultyLevel(event)
                                    }
                                    checked={
                                      state.questionBankDetails
                                        .difficultyLevel ===
                                      element.toughness_id
                                    }
                                  />
                                  <label
                                    htmlFor={`${element.toughness_id}_level`}
                                  >
                                    {element.toughness}
                                  </label>
                                </div>
                              );
                            }
                          )
                        : null
                      : null}
                  </div>
                </div>
              ) : null}
              <div className="sub_content_wrapper_5">
                <div className="text_content_wrapper">
                  <p className="text_content">Questions From MCQ Banks</p>
                </div>
                <div className="questions_type_collection_wrapper">
                  <div className="single_question_type_wrapper">
                    <input
                      type="radio"
                      id="all_questions"
                      name="questions_type"
                      value="5"
                      onChange={(event) =>
                        handleChangeQuestionFromMcqBank(event)
                      }
                      checked={
                        state.questionBankDetails.questionFromMcqBank === 5
                      }
                    />
                    <label htmlFor="all_questions">All</label>
                  </div>
                  <div className="single_question_type_wrapper">
                    <input
                      type="radio"
                      id="bookmarked_questions"
                      name="questions_type"
                      value="1"
                      onChange={(event) =>
                        handleChangeQuestionFromMcqBank(event)
                      }
                      checked={
                        state.questionBankDetails.questionFromMcqBank === 1
                      }
                    />
                    <label htmlFor="bookmarked_questions">Bookmarked</label>
                  </div>
                  <div className="single_question_type_wrapper">
                    <input
                      type="radio"
                      id="incorrect_questions"
                      name="questions_type"
                      value="2"
                      onChange={(event) =>
                        handleChangeQuestionFromMcqBank(event)
                      }
                      checked={
                        state.questionBankDetails.questionFromMcqBank === 2
                      }
                    />
                    <label htmlFor="incorrect_questions">Incorrect</label>
                  </div>
                  <div className="single_question_type_wrapper">
                    <input
                      type="radio"
                      id="correct_questions"
                      name="questions_type"
                      value="3"
                      onChange={(event) =>
                        handleChangeQuestionFromMcqBank(event)
                      }
                      checked={
                        state.questionBankDetails.questionFromMcqBank === 3
                      }
                    />
                    <label htmlFor="correct_questions">Correct</label>
                  </div>
                  <div className="single_question_type_wrapper">
                    <input
                      type="radio"
                      id="unattempt_questions"
                      name="questions_type"
                      value="4"
                      onChange={(event) =>
                        handleChangeQuestionFromMcqBank(event)
                      }
                      checked={
                        state.questionBankDetails.questionFromMcqBank === 4
                      }
                    />
                    <label htmlFor="unattempt_questions">Unattempt</label>
                  </div>
                </div>
              </div>
              <div className="sub_content_wrapper_6">
                <div className="text_content_wrapper">
                  <p className="text_content">Select Subject</p>
                </div>
                <div className="subject_collection_wrapper">
                  {!isEmpty(state.subjectList)
                    ? state.subjectList.subject.length
                      ? state.subjectList.subject.map((element, index) => {
                          return (
                            <div key={index} className="single_subject_wrapper">
                              <input
                                type="radio"
                                id={`subject_${element.sub_id}`}
                                name="subject"
                                value={element.sub_id}
                                onChange={(event) => handleChangeSubject(event)}
                                checked={
                                  state.questionBankDetails
                                    .selectedSubjectId === element.sub_id
                                }
                              />
                              <label htmlFor={`subject_${element.sub_id}`}>
                                {element.sub_name}
                              </label>
                            </div>
                          );
                        })
                      : null
                    : null}
                </div>
              </div>
              {!isEmpty(state.topicList) ? (
                <div className="sub_content_wrapper_7">
                  <div className="text_content_wrapper">
                    <p className="text_content">Select Topics</p>
                  </div>
                  <div className="btn_container">
                    <div className="single_btn_wrapper">
                      <input
                        type="checkbox"
                        id="select_all"
                        name="topics"
                        value="all"
                        onChange={(event) => handleSelectAllTopics(event)}
                        checked={state.questionBankDetails.selectAll}
                      />
                      <label htmlFor="select_all">Select All</label>
                    </div>
                  </div>
                  <div className="topic_collection_wrapper">
                    {state.topicList.topic.length
                      ? state.topicList.topic.map((element, index) => {
                          return (
                            <div key={index} className="single_topic_wrapper">
                              <input
                                type="checkbox"
                                id={`topic_${element.topic_id}`}
                                name="topic"
                                value={element.topic_id}
                                onChange={(event) => handleChangeTopic(event)}
                                checked={state.questionBankDetails.selectedTopics.find(
                                  (e) => {
                                    return e === element.topic_id;
                                  }
                                )}
                              />
                              <label htmlFor={`topic_${element.topic_id}`}>
                                {element.topic_name}
                              </label>
                            </div>
                          );
                        })
                      : null}
                  </div>
                </div>
              ) : null}
              <div className="sub_content_wrapper_8">
                <div className="btn_wrapper">
                  <button onClick={() => handleCreateQuestionBankModule()}>
                    Create Module
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => {
      dispatch(uiActions.startLoading());
    },
    finishLoading: () => {
      dispatch(uiActions.finishLoading());
    },
  };
};

export default connect(
  null,
  mapDispatchToProps
)(UserCreateCustomQuestionBankContentComponent);
