import React from "react";
import TopperComparisonContentComponent from "./topperComparisonContentComponent/TopperComparisonContentComponent";
import { connect } from "react-redux";
import uiActions from "../../../../redux/ui/action";

const TopperComparision = (props) => {
  const { startLoading, finishLoading, loader, parentUserId, paperId } = props;
  return (
    <div>
      <TopperComparisonContentComponent
        startLoading={startLoading}
        finishLoading={finishLoading}
        loader={loader}
        parentUserId={parentUserId}
        paperId={paperId}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loader: state.ui.loader,
    parentUserId: state.parentrootReducer.parentAuthReducer.userId,
    paperId: state.parentrootReducer.parentTestReducer.paperId,
  };
};

const mapDipatchToProps = (dispatch) => {
  return {
    startLoading: () => {
      dispatch(uiActions.startLoading());
    },
    finishLoading: () => {
      dispatch(uiActions.finishLoading());
    },
  };
};

export default connect(mapStateToProps, mapDipatchToProps)(TopperComparision);
