import { React, useEffect, useState } from "react";
import "./Our_course_package.scss";
import images from "../../../../../../utilities/images/images";
import axios from "axios";
import {
  url_56,
  url_57,
} from "../../../../../../custom_utilities/api_services";
import LoaderPopUp from "../../../../../../components/loader/loader";
import { isEmpty } from "../../../../../../custom_utilities/custom_useful_functions";
//Redux
import { connect } from "react-redux";
import { log } from "devtools-detector";
import { useHistory } from "react-router-dom";
//Redux
import uiActions from "../../../../../../redux/ui/action";
import packageListActions from "../../../../../../redux/auth/action";
//slick-carousel
import Slider from "react-slick";
function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
}
function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
}

const Our_course_package = (props) => {
  let {
    startLoading,
    finishLoading,
    loader,
    setPaidCoursePackages,
    setFreeCoursePackages,
  } = props;
  let { courseDetails, targetDetails, classDetails, batchDetails } =
    props.userPreference;
  let history = useHistory();
  const courseType = ["Paid Course Packages", "Free Course Packages"];
  const [courseName, setCourseName] = useState(courseType[0]);
  const [state, setState] = useState([]);

  const getCourseDetails = async (type) => {
    setCourseName(type);
    let data = {
      search: "",
      course_id: courseDetails[0].course_id,
      batch_id: batchDetails.batch_id,
      target_id: targetDetails[0].target_id,
    };
    if (type === "Paid Course Packages") {
      startLoading();
      try {
        const response = await axios.get(
          `${url_57}?course_id=${data.course_id}&batch_id=${data.batch_id}`
        );
        if (response.data.status === 200) {
          setState(response.data.data.Package);
          setPaidCoursePackages(response.data.data.Package);
          finishLoading();
        }
      } catch (error) {
        console.log(error);
        finishLoading();
      }
    } else if (type === "Free Course Packages") {
      startLoading();
      try {
        const response = await axios.post(url_56, data);
        if (response.data.status === 200) {
          setState(response.data.data.courses);
          setFreeCoursePackages(response.data.data.courses);
          finishLoading();
        }
      } catch (error) {
        console.log(error);
        finishLoading();
      }
    }
  };
  useEffect(() => {
    getCourseDetails(courseType[0]);
  }, []);
  const storePage = () => {
    history.push("/store");
  };

  const settings1 = {
    dots: false,
    infinite: true,
    autoplay:true,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow className="slick-next" />,
    prevArrow: <SamplePrevArrow className="slick-prev" />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          // infinite: true,
          // dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          // initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          infinite: true,
        },
      },
    ],
  };

  return (
    <>
      <div className="our_course_wrapper">
        <div className="course_title">
          <h4>Our Course Packages</h4>
          {/* <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry simply dummy text of the printing and typesetting industry.
          </p> */}
        </div>
        <div className="freePaid_courseTab">
          <div className="freePaidTab">
            {courseType.map((element, index) => {
              return (
                <div key={index} className="single_select_subject">
                  <input
                    type="radio"
                    id={element}
                    name="subject"
                    value={element}
                    checked={element === courseName}
                    onClick={() => getCourseDetails(element)}
                  />
                  <label htmlFor={element}>{element}</label>
                </div>
              );
            })}
          </div>
          <span className="seeAll" onClick={storePage}>
            See all <img src={images.m2_seeAll} alt="" />
          </span>
        </div>
        {loader && <LoaderPopUp />}
        <div className="course_box_wrapper">
          <Slider {...settings1}>
            {state &&
              state.map((curElem, index) => {
                return (
                  <div className="course_bx_wrap" key={curElem.package_id}>
                    <div className="course_box">
                      <div className="course_img">
                        <img src={curElem.icon} alt="Course_img" />
                        {/* <div className="course_price">
                          <span>
                            {curElem && curElem.amount
                              ? `₹ ${curElem.amount}`
                              : "View"}
                          </span>
                        </div> */}
                      </div>
                      <div className="course_detail">
                        <h4>
                          {curElem && curElem.package_name
                            ? curElem.package_name
                            : "N/A"}
                        </h4>
                        <h6>
                          {curElem && curElem.course ? curElem.course : "N/A"}
                        </h6>
                        <div className="course_price">
                          <s>
                            {curElem && curElem.amount
                              ? `₹ ${curElem.amount}`
                              : "View"}
                          </s>
                          <span>
                            {curElem && curElem.amount
                              ? `₹ ${curElem.final_amount}`
                              : "View"}
                          </span>
                        </div>
                        <p>
                          {curElem && curElem.description
                            ? curElem.description
                            : "N/A"}
                        </p>
                      </div>
                      <div className="enroll_btn">
                        <button className="button" onClick={storePage}>
                          {curElem && curElem.amount ? "Enroll Now" : "Free"}
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })}
          </Slider>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userPreference: state.userPreference,
    loader: state.ui.loader,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => {
      dispatch(uiActions.startLoading());
    },
    finishLoading: () => {
      dispatch(uiActions.finishLoading());
    },
    setPaidCoursePackages: (payload) => {
      dispatch(packageListActions.setPaidCoursePackages(payload));
    },
    setFreeCoursePackages: (payload) => {
      dispatch(packageListActions.setFreeCoursePackages(payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Our_course_package);
