import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { url_182, url_183 } from "../../../../custom_utilities/api_services";
import images from "../../../../utilities/images/images";
import { responseMessageFromMotion } from "./utils/refundCustomUtils";
import { generateRandomId } from "../../../../custom_utilities/custom_useful_functions";

import RefundModal from "./RefundModal";

const RefundContainer = (props) => {
  const {
    closeRefundModal,
    isRefundModalOpen,
    token,
    tokenType,
    currentRefundPackage,
    openRefundSuccessModal,
  } = props;

  const [refund, setRefund] = useState({
    refundOptions: [],
    userSelectedRefundOption: {},
    userDescription: "",
    isRenderRefundOptions: true,
    isUserSubmittedDescript: false,
    isRenderResponseMsgFromMotion: false,
    userSelectingOptions: [],
    isRefundSuccessModalOpen: false,
  });
  const textareaRef = useRef(null);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [refund.userDescription]);

  useEffect(() => {
    getRefundOptions();
  }, []);

  const getRefundOptions = async () => {
    const headers = {
      Authorization: `${tokenType} ${token}`,
    };

    try {
      const response = await axios.get(url_182, { headers });

      if (response.data.status === 200) {
        setRefund((prevState) => ({
          ...prevState,
          refundOptions: [...response.data.data.parents],
        }));
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const initiateRefund = async () => {
    const headers = {
      Authorization: `${tokenType} ${token}`,
    };

    const data = {
      parent_id: refund.userSelectedRefundOption.id,
      package_id: currentRefundPackage?.package_id,
      description: refund.userDescription,
    };

    try {
      const response = await axios.post(url_183, data, { headers });

      if (response.data.status === 200) {
        openRefundSuccessModal();
        closeRefundModal();
      } else {
        alert(response?.data?.message);
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  const handleDescriptionChange = (e) => {
    setRefund((prevState) => ({
      ...prevState,
      userDescription: e.target.value,
    }));
  };

  const handleSubmitDescript = () => {
    if (refund.userDescription.length > 0) {
      const userCustomMessage = {
        id: generateRandomId(),
        name: refund.userDescription,
      };

      setRefund((prevState) => ({
        ...prevState,
        userSelectingOptions: [
          ...prevState.userSelectingOptions,
          userCustomMessage,
          responseMessageFromMotion(),
        ],
        isUserSubmittedDescript: true,
      }));
    }
  };

  const selectRefundOption = (selectedOption) => {
    const newOption = {
      id: generateRandomId(),
      name: selectedOption.name,
    };

    setRefund((prevState) => ({
      ...prevState,
      userSelectedRefundOption: selectedOption,
      isRenderRefundOptions: false,
      userSelectingOptions: [...prevState.userSelectingOptions, newOption],
    }));

    alert("please explain in detail!");
  };

  const renderRefundOptions = () => {
    return (
      <div className="refund-options">
        {refund.refundOptions.map((element) => {
          return (
            <div
              className="single-refund-option"
              onClick={() => selectRefundOption(element)}
              key={element.id}
            >
              <p>{element.name}</p>
            </div>
          );
        })}
      </div>
    );
  };

  const renderUserSelectedOptions = () => {
    return (
      <div className="some">
        {refund.userSelectingOptions.map((element) => {
          return (
            <div
              key={element.id}
              className={`${
                !element.type
                  ? "user-selected-refund-option"
                  : "motion-assistant-msg"
              }`}
              dangerouslySetInnerHTML={{ __html: element.name }}
            ></div>
          );
        })}
      </div>
    );
  };

  const renderRefundButtons = () => {
    return (
      <div className="refund-btns">
        <div className="ask-refund-btn" onClick={initiateRefund}>
          <button>Ask Refund</button>
        </div>

        <div className="bck-to-prvs-menu-btn" onClick={backToOptions}>
          <button>Back To Previous Menu</button>
        </div>
      </div>
    );
  };

  const renderInputAndSubmit = () => {
    const isTextareaEmpty = refund.userDescription.trim().length === 0;
    return (
      <div className="input-submit">
        <textarea
          ref={textareaRef}
          placeholder="Type message..."
          onChange={(e) => handleDescriptionChange(e)}
          value={refund.userDescription}
          id="descript"
          name="descript"
          rows="1" // Initial rows
          style={{ minHeight: "30px" }} // Set a minimum height
        />

        <button
          className={isTextareaEmpty ? "disabled" : "enabled"}
          onClick={isTextareaEmpty ? null : handleSubmitDescript}
        >
          <img src={images.refund_submit} alt="submit" />
        </button>
      </div>
    );
  };

  const backToOptions = () => {
    setRefund((prevState) => ({
      ...prevState,
      isRenderRefundOptions: true,
      isUserSubmittedDescript: false,
      userDescription: "",
    }));
  };

  return (
    <>
      {isRefundModalOpen && (
        <RefundModal
          refund={refund}
          renderRefundOptions={renderRefundOptions}
          renderRefundButtons={renderRefundButtons}
          renderUserSelectedOptions={renderUserSelectedOptions}
          renderInputAndSubmit={renderInputAndSubmit}
          isRefundModalOpen={isRefundModalOpen}
          closeRefundModal={closeRefundModal}
        />
      )}
    </>
  );
};

export default RefundContainer;
