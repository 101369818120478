import { Fragment, useEffect, useState } from "react";
import "./package_offering_component.scss";
import { Button, Modal } from "react-bootstrap";
import { isEmpty } from "../../../../../../custom_utilities/custom_useful_functions";
import images from "../../../../../../utilities/images/images";
import { log } from "devtools-detector";
import { Link } from "react-router-dom";
import axios from "axios";
import CcAvenuePayment from "../../../../../../custom_utilities/ccavence_payment/CcAvenuePayment";
import { url_177 } from "../../../../../../custom_utilities/api_services";
import PaymentModal from "../modal/PaymentModal";
import { useLocation } from "react-router-dom";


const pageEqualToStorecourseDetails = {
  active: 1,
  advantages: null,
  allow_installment: "Yes",
  amount: "2199.00",
  batch: [],
  batch_id: null,
  bg_image: "/storage/package/course_banner/381.png",
  coin_discount_amt: "1099.00",
  course: "JEE 12th English",
  course_benifits: "Text and video solutions,Topic-wise tests,100+ Practice papers",
  course_id: 13,
  created_on: "2023-12-16 13:39:06",
  description: `Features:
    90+ Past year papers,
    34 Subtopic wise Minor test,
    9 Major test,
    17 Full Syllabus test,
    5 BITSAT test,
    3 WBJEE test,
    2 IAT test,
    One-stop solution for all engineering exams,
    Video and text solutions of each test,
    Detailed report and analysis after every test,
    Created and crafted by expert Kota faculties as per latest updated syllabus,
    Validity till JEE Advanced 2025,
    Use 50% motion coins for an extra discount.`,
  discount: "0.00",
  duration: "12 months",
  expiry_date: "2025-07-05",
  final_amount: "2199",
  icon: "https://learning.motion.ac.in/motioneducation//storage/package/app_icon.png",
  is_amirt: 0,
  is_delete: 0,
  is_referral: null,
  is_refundable: null,
  is_subscribe: false,
  package_id: 381,
  package_include_module: [
    { module: 'Live Classess' },
    { module: 'Video' },
    { module: 'Zoom Classess' }
  ],
  package_name: "Motion Targeted Abhyas (MAT) - Phase 1.0",
  package_sequence: null,
  package_tag: "2",
  package_thumbnail: "/storage/package/thumbnail/381.png",
  package_type: 3,
  tags: null,
  txn_expire_date: "",
  u_programId: null,
  video_url: null,
  web_thumbnail: "/storage/package/web_thumbnail/381.png"
};







const PackageOfferingComponent = (props) => {
  let {
    freePackagesCollection,
    packagesCollection,
    pricePurchaseBtn,
    learnMoreBtn,
    handlePackageSelect,
    couponCollection,
    couponDetails,
    updateCoupon,
    saveUserTransactions,
    availableCoins,
    // applyAvailableCoins,
    startLoading,
    finishLoading,
    coupanApplied,
    packageDetails,
    orderId,
    token,
    tokenType,
    user_id,
    setPackageDetailsForPayment,
    getUpdatedAmountFromBackend,
    cpnAmt,
    setCpnAmt,
    setOriginalAmt,
    originalAmt,
  } = props;
  //bootstarp modal
  console.log("packageDetails", packageDetails);

  const [show, setShow] = useState(false);
  const [pkgID, setPkgID] = useState("");



  
  const handleClose = () => {
    setShow(false);
    setApplyCoinData(initialApplyCoinData);
  };
  const handleShow = (element) => {
    updateCoupon();
    setCpnInput("");
    setEl(element);
    pricePurchaseBtn(element);
    setCpnAmt(element.final_amount);
    setPkgID(element.package_id);
    handlePackageSelect(element.package_id, element.final_amount);
    setShow(true);
    setPackageDetailsForPayment(element);
    setOriginalAmt(element.final_amount);
  };
  // const [cpnAmt, setCpnAmt] = useState("");
  const [selectedEl, setEl] = useState({});
  const [cpnInput, setCpnInput] = useState("");
  const initialApplyCoinData = {
    isError: false,
    errorMessage: "",
    data: {},
    coinsApplied: 0, // 0,1,2
  };
  const [applyCoinData, setApplyCoinData] = useState(initialApplyCoinData);

  const applyAvailableCoins = async (e) => {
    await getUpdatedAmountFromBackend();
    let target = e.target;
    console.log(target.checked);
    if (target.checked) {
      console.log("state", selectedEl);
      // ****/
      let data = {
        cart_amount: isEmpty(couponDetails)
          ? selectedEl.final_amount
          : couponDetails.updated_amount,
        coins_count: availableCoins,
        // transaction_id: "",
        type: "enquiry",
        order_id: orderId,
        checked: true,
      };
      startLoading();
      try {
        const response = await axios.post(url_177, data);
        if (response.data.status === 200) {
          console.log(response.data.data);
          setApplyCoinData((prev) => {
            return {
              ...prev,
              isError: false,
              errorMessage: "",
              data: response.data.data,
              coinsApplied: coupanApplied === 1 ? 2 : 1,
            };
          });

          setCpnAmt(response.data.data.updated_amount);

          finishLoading();
        } else if (response.data.status === 422) {
          finishLoading();
          setApplyCoinData((prev) => {
            return {
              ...prev,
              isError: true,
              errorMessage: response.data.message,
              data: response.data.data,
            };
          });
        }
      } catch (error) {
        console.log(error);
        finishLoading();
      }
      // ***/
    } else {
      let data = {
        cart_amount: isEmpty(couponDetails)
          ? selectedEl.final_amount
          : couponDetails.updated_amount,
        coins_count: availableCoins,
        // transaction_id: "",
        type: "enquiry",
        order_id: orderId,
        checked: false,
      };
      startLoading();
      try {
        const response = await axios.post(url_177, data);
        if (response.data.status === 200) {
          console.log(response.data.data);
          setApplyCoinData((prev) => {
            return {
              ...prev,
              isError: false,
              errorMessage: "",
              data: response.data.data,
              coinsApplied: coupanApplied === 1 ? 2 : 1,
            };
          });

          setCpnAmt(response.data.data.updated_amount);

          finishLoading();
        } else if (response.data.status === 422) {
          finishLoading();
          setApplyCoinData((prev) => {
            return {
              ...prev,
              isError: true,
              errorMessage: response.data.message,
              data: response.data.data,
            };
          });
        }
      } catch (error) {
        console.log(error);
        finishLoading();
      }

      // setApplyCoinData(initialApplyCoinData);
    }
  };

  return (
    <Fragment>
      <div className="package_offering_component_wrapper">
        <div className="package_offering_component_inner_wrapper">
          {!isEmpty(packagesCollection) &&
          packagesCollection.Package.filter((m) => m.is_subscribe == true)
            .length ? (
            <section className="sec_2_wrapper">
              <div className="text_content_wrapper">
                <p className="text_content">Your Packages</p>
              </div>
              <div className="package_collection_wrapper">
                <div className="package_collection">
                  {!isEmpty(packagesCollection)
                    ? packagesCollection.Package.length
                      ? packagesCollection.Package.map((element, index) => {
                          return (
                            <>
                              {element.is_subscribe && (
                                <div
                                  key={index}
                                  className="single_package_wrapper"
                                >
                                  <div className="single_package_inner_wrapper">
                                    <div className="sec_1_wrapper">
                                      <div className="text_content_wrapper_1">
                                        <p className="text_content_1">
                                          {element.package_name}

                                          {/* {element.course} */}
                                        </p>
                                      </div>
                                      {/* <div className="text_content_wrapper_2">
                                  <p className="text_content_2">
                                    {element.batch.length
                                      ? element.batch[0].batch
                                      : null}
                                  </p>
                                </div> */}
                                      <div className="text_content_wrapper_3">
                                        <p className="text_content_3">
                                          {/* {element.package_name} */}
                                          {element.course}
                                        </p>
                                      </div>
                                      <div className="btn_wrapper_1">
                                        <span>{`₹ ${element.final_amount}`}</span>
                                      </div>
                                    </div>
                                    <div className="sec_2_wrapper">
                                      <div
                                        className="content_wrapper_2"
                                        onClick={() => learnMoreBtn(element)}
                                      >
                                        <div className="btn_wrapper_2">
                                          <button type="button">
                                            Learn more
                                          </button>
                                        </div>
                                      </div>
                                      <div className="content_wrapper_2">
                                        <div className="btn_wrapper_2">
                                          <span className="course_type">
                                            Gold
                                          </span>
                                        </div>
                                      </div>

                                      <div className="purchase_btn">
                                        {/* <button
                                    type="button"
                                    onClick={() => pricePurchaseBtn(element)}
                                  >
                                    Purchase Now
                                  </button> */}

                                        {element.txn_expire_date && (
                                          <span className="expiry_date">
                                            {/* Expiry at 14 Mar, 2023 */}
                                            Expiry at {element.txn_expire_date}
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </>
                          );
                        })
                      : "No Courses available"
                    : "No Courses available"}
                </div>
              </div>
            </section>
          ) : null}
          <section className="sec_2_wrapper">
            <div className="text_content_wrapper">
              <p className="text_content">Paid Course Packages</p>
            </div>
            <div className="package_collection_wrapper">
              <div className="package_collection">
                {!isEmpty(packagesCollection)
                  ? packagesCollection.Package.length
                    ? packagesCollection.Package.map((element, index) => {
                        return (
                          <>
                            {!element.is_subscribe && (
                              <div
                                key={index}
                                className="single_package_wrapper"
                              >
                                <div className="single_package_inner_wrapper">
                                  <div className="sec_1_wrapper">
                                    <div className="text_content_wrapper_1">
                                      <p className="text_content_1">
                                        {element.package_name}
                                      </p>
                                    </div>
                                    {/* <div className="text_content_wrapper_2">
                                  <p className="text_content_2">
                                    {element.batch.length
                                      ? element.batch[0].batch
                                      : null}
                                  </p>
                                </div> */}
                                    <div className="text_content_wrapper_3">
                                      <p className="text_content_3">
                                        {element.course}
                                      </p>
                                    </div>
                                    <div className="btn_wrapper_1">
                                      <span>{`₹ ${element.final_amount}`}</span>
                                    </div>
                                  </div>
                                  <div className="sec_2_wrapper">
                                    <div
                                      className="content_wrapper_2"
                                      onClick={() => learnMoreBtn(element)}
                                    >
                                      <div className="btn_wrapper_2">
                                        <button type="button">
                                          Learn more
                                        </button>
                                      </div>
                                    </div>
                                    <div className="purchase_btn">
                                      {/* <button
                                        type="button"
                                        onClick={() =>
                                          pricePurchaseBtn(element)
                                        }
                                      >
                                        Purchase Noww
                                      </button> */}
                                      <button
                                        onClick={() => handleShow(element)}
                                      >
                                        Purchase Now
                                      </button>

                                      {/* <button>Purchase Now my</button> */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </>
                        );
                      })
                    : "No Courses available"
                  : "No Courses available"}
              </div>
            </div>
          </section>
          <section className="sec_2_wrapper free_course_package">
            <div className="text_content_wrapper">
              <p className="text_content">Free Course Packages</p>
            </div>
            <div className="package_collection">
              {!isEmpty(freePackagesCollection)
                ? freePackagesCollection.courses.length
                  ? freePackagesCollection.courses.map((element, index) => {
                      return (
                        <div key={index} className="single_package_wrapper">
                          <div className="single_package_inner_wrapper">
                            <div className="sec_1_wrapper">
                              {element.is_subscribe ? (
                                <div className="image_wrapper">
                                  <img src={images.user_activity_11} />
                                </div>
                              ) : null}
                              <div className="text_content_wrapper_1">
                                <p className="text_content_1">
                                  {element.course}
                                </p>
                              </div>
                              {/* <div className="text_content_wrapper_2">
                                  <p className="text_content_2">
                                    {element.batch.length
                                      ? element.batch[0].batch
                                      : null}
                                  </p>
                                </div> */}
                              <div className="text_content_wrapper_3">
                                <p className="text_content_3">
                                  {element.package_name}
                                </p>
                              </div>
                              {/* <div className="btn_wrapper_1">
                                <span>{`₹ ${element.final_amount}`}</span>
                              </div> */}
                            </div>
                            <div className="sec_2_wrapper">
                              <div className="content_wrapper_2">
                                {/* <div className="btn_wrapper_2">
                                  <button
                                    type="button"
                                    onClick={() => learnMoreBtn(element)}
                                  >
                                    Learn more
                                  </button>
                                </div> */}
                              </div>
                              <div className="purchase_btn">
                                <Link
                                  to={{
                                    pathname: "/subject_component",
                                    courseId: element.course_id,
                                  }}
                                >
                                  View
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  : "No Courses available"
                : "No Courses available"}
            </div>
          </section>
        </div>
      </div>

      {show && (
        <PaymentModal
          show={show}
          handleClose={handleClose}
          cpnAmt={cpnAmt}
          pkgID={pkgID}
          cpnInput={cpnInput}
          setCpnInput={setCpnInput}
          couponCollection={couponCollection}
          applyCoinData={applyCoinData}
          applyAvailableCoins={applyAvailableCoins}
          isEmpty={isEmpty}
          couponDetails={couponDetails}
          coupanApplied={coupanApplied}
          selectedEl={selectedEl}
          saveUserTransactions={saveUserTransactions}
          originalAmt={originalAmt}
          availableCoins={availableCoins}
        />
      )}
    </Fragment>
  );
};

export default PackageOfferingComponent;
