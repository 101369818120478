import React from "react";
import "./ReferFrndBanner.scss";

const ReferFrndBanner = ({ referAndEarnData }) => {
  return (
    <div className="referFrndBannerWrppr">
      <img src={referAndEarnData.webbanner_image1} alt="refer-and-earn-img" />

      <div className="banner_message">
        <div className="earn-together">Earn Together!</div>
        {referAndEarnData.banner_message}
      </div>
    </div>
  );
};

export default ReferFrndBanner;
