import React, { useState, useEffect } from "react";
import axios from "axios";
import { Modal } from "react-bootstrap";
import "./SyllabusModal.scss";
import { url_122 } from "../../../../../../../../custom_utilities/api_services";
import { connect } from "react-redux";

const SyllabusModal = (props) => {
  const {
    test,
    token,
    tokenType,
    startLoading,
    finishLoading,
    loader,
    target_id,
  } = props;
  useEffect(() => {
    getSyllabusData();
  }, []);

  const [modalSyllabusData, setModalSyllabusData] = useState({
    showSyllabusModal: false,
    syllabusData: {},
  });

  const handleCloseSyllabusModal = () => {
    setModalSyllabusData((prevState) => {
      return {
        ...prevState,
        showSyllabusModal: false,
      };
    });

    props.setSyllabusModalFlag(false);
  };

  const handleShowSyllabusModal = () =>
    setModalSyllabusData((prevState) => ({
      ...prevState,
      showSyllabusModal: true,
    }));

  const getSyllabusData = () => {
    const headers = {
      Authorization: `${tokenType} ${token}`,
    };

    const data = {
      paper_id: test.paper_id,
      test_id: test.test_id,
      planner_id: test.planner_id,
    };

    startLoading();
    try {
      axios.post(url_122, data, { headers }).then((response) => {
        if (response.status === 200) {
          finishLoading();
          setModalSyllabusData((prevState) => ({
            ...prevState,
            syllabusData: response.data.data.paper,
          }));
          handleShowSyllabusModal();
        }
      });
    } catch (error) {
      finishLoading();
      console.log(error);
    }
  };

  return (
    <Modal
      show={modalSyllabusData.showSyllabusModal}
      onHide={handleCloseSyllabusModal}
      dialogClassName="my-modal"
      // centered
    >
      <Modal.Header closeButton>
        {" "}
        <h3>Test Syllabus</h3>
      </Modal.Header>

      <Modal.Body className="modal-body">
        <div className="ots-syllabus-modal-wrapper">
          <div class="header" className="ots-syllabus-modal_header">
            {modalSyllabusData?.syllabusData?.test_name}
          </div>

          <ul>
            <div class="row">
              <div class="col-6">
                <li>
                  Date & Time :{" "}
                  <span>{modalSyllabusData?.syllabusData?.test_date}</span>
                </li>
                <li>
                  Test Duration :{" "}
                  <span>
                    {modalSyllabusData?.syllabusData?.test_duration_min} Mins
                  </span>
                </li>
              </div>
              <div class="col-6">
                <li>
                  Total Questions :{" "}
                  <span>
                    {modalSyllabusData?.syllabusData?.total_questions}
                  </span>
                </li>
                <li>
                  Total Marks :{" "}
                  <span>{modalSyllabusData?.syllabusData?.max_marks}</span>
                </li>
              </div>
            </div>
          </ul>
        </div>

        <table>
          <thead>
            <tr>
              <th>Physics</th>
              <th>Chemistry</th>

              {target_id == 3 ? (
                <>
                  <th>Zoology</th>
                  <th>Botany</th>
                </>
              ) : (
                <>
                  <th>Maths</th>
                </>
              )}
            </tr>
          </thead>

          <tbody>
            <tr>
              <td>
                {modalSyllabusData.syllabusData
                  ? modalSyllabusData?.syllabusData?.subjects
                    ? modalSyllabusData?.syllabusData?.subjects.Physics
                    : null
                  : null}
              </td>
              <td>
                {modalSyllabusData.syllabusData
                  ? modalSyllabusData?.syllabusData?.subjects
                    ? modalSyllabusData?.syllabusData?.subjects?.Chemistry
                    : null
                  : null}
              </td>

              {target_id == 3 ? (
                <>
                  <td>
                    {modalSyllabusData.syllabusData
                      ? modalSyllabusData?.syllabusData?.subjects
                        ? modalSyllabusData?.syllabusData?.subjects.Zoology
                        : null
                      : null}
                  </td>

                  <td>
                    {modalSyllabusData.syllabusData
                      ? modalSyllabusData?.syllabusData?.subjects
                        ? modalSyllabusData?.syllabusData?.subjects.Botany
                        : null
                      : null}
                  </td>
                </>
              ) : (
                <>
                  <td>
                    {modalSyllabusData.syllabusData
                      ? modalSyllabusData?.syllabusData?.subjects
                        ? modalSyllabusData?.syllabusData?.subjects.Maths
                        : null
                      : null}
                  </td>
                </>
              )}

       
            </tr>
          </tbody>
        </table>
      </Modal.Body>

      <div className="modal-footer-btn">
        <button onClick={handleCloseSyllabusModal}>Done</button>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.authToken,
    authType: state.auth.authType,
    target_id: state.userPreference?.targetDetails[0].target_id,
  };
};

export default connect(mapStateToProps, null)(SyllabusModal);
