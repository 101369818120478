import React, { useState, useEffect } from "react";
import "./HandBooks.scss";
import { isEmpty } from "../../../../../custom_utilities/custom_useful_functions";
import axios from "axios";
import { connect } from "react-redux";
import uiActions from "../../../../../redux/ui/action";
import images from "./../../../../../utilities/images/images";
import { useHistory } from "react-router-dom";
import userProductList from "../../../../../redux/new_store/action";
import {
  url_158,
  url_159,
  url_161,
  url_162,
  url_164,
} from "../../../../../custom_utilities/api_services";

const HandBooks = (props) => {
  let { startLoading, finishLoading, setProductList, tokenType, token } = props;
  const [state, setState] = useState({
    productType: [],
    productList: [],
    selectedType: "",
    wishListItem: [],
    cartDetails: [],
  });

  const [wishList, setWishList] = useState(false);

  let history = useHistory();
  useEffect(() => {
    getProducType();
    getWishList();
    getCartDetails();
  }, []);

  useEffect(() => {
    getProductlist(state.selectedType);
  }, [state.selectedType]);

  const getProducType = async () => {
    startLoading();
    try {
      const response = await axios.post(url_158);
      if (response.data.status === 200) {
        finishLoading();
        setState((prevState) => ({
          ...prevState,
          productType: response.data.data,
        }));
        getProductlist(response.data.data[0].id);
      }
    } catch (error) {
      finishLoading();
      console.log(error);
    }
  };

  const getProductlist = async (id) => {
    startLoading();
    const headers = {
      Authorization: `${tokenType} ${token}`,
    };
    let data = {
      product_id: id,
    };
    try {
      const response = await axios.post(url_159, data, { headers });
      if (response.data.status === 200) {
        finishLoading();
        setState((prevState) => ({
          ...prevState,
          productList: response.data.data,
        }));
        setProductList(response.data.data);
      }
    } catch (error) {
      finishLoading();
      console.log(error);
    }
  };

  const handleSelectedType = (event) => {
    setState((prevState) => ({
      ...prevState,
      selectedType: event.target.value,
    }));
  };

  const handlecartDetails = (product) => {
    startLoading();
    history.push({
      pathname: "/cart_details",
      state: { product: product },
    });
    finishLoading();
  };

  const handleAddWishList = (p_id) => {
    setWishList(true);
    addWishlist(p_id);
  };

  const addWishlist = async (p_id) => {
    startLoading();
    const headers = {
      Authorization: `${tokenType} ${token}`,
    };
    let data = {
      product_id: p_id,
      type: 1,
    };
    try {
      const response = await axios.post(url_161, data, { headers });
      if (response.data.status === 200) {
        finishLoading();
      }
      getWishList();
    } catch (error) {
      finishLoading();
      console.log(error);
    }
  };

  const handleRemoveWishList = (p_id) => {
    setWishList(false);
    removeWishlist(p_id);
  };

  const removeWishlist = async (p_id) => {
    startLoading();
    let data = {
      product_id: p_id,
      type: 0,
    };
    try {
      const response = await axios.post(url_161, data);
      if (response.data.status === 200) {
        finishLoading();
      }

      getWishList();
    } catch (error) {
      finishLoading();
      console.log(error);
    }
  };

  const handleRedirect = () => {
    history.push({
      pathname: "/cart_collection",
      state: { cartTotal: state.cartDetails },
    });
  };

  const handleWishListPage = () => {
    history.push("/wishList_collection");
  };

  const getCartDetails = async () => {
    startLoading();
    try {
      const response = await axios.post(url_162);
      if (response.data.status === 200) {
        finishLoading();
        setState((prevState) => ({
          ...prevState,
          cartDetails: response.data,
        }));
      }
    } catch (error) {
      finishLoading();
      console.log(error);
    }
  };

  const getWishList = async () => {
    startLoading();
    const headers = {
      Authorization: `${tokenType} ${token}`,
    };
    try {
      const response = await axios.post(url_164, { headers });
      if (response.data.status === 200) {
        finishLoading();
        setState((prevState) => ({
          ...prevState,
          wishListItem: response.data.data,
        }));
      } else {
        finishLoading();
      }
    } catch (error) {
      finishLoading();
      console.log(error);
    }
  };

  const check = (p_id) => {
    const newArr = state.wishListItem.filter((element) => {
      return element.p_id == p_id;
    });
    return newArr[0];
  };

  return (
    <>
      <div className="wishList_addCart">
        <div className="wishList" onClick={handleWishListPage}>
          <img src={images.user_activity_39} alt="wish-list" />
          <div className="badge">{state.wishListItem.length}</div>
          <div className="text">Wishlist</div>
        </div>
        <div className="addCart" onClick={handleRedirect}>
          <img src={images.user_activity_38} alt="add-cart" />
          <div className="badge">{state.cartDetails.total}</div>
          <div className="text">Cart</div>
        </div>
      </div>
      <div className="handBooks">
        <div className="container">
          <section>
            <select
              className="courses_dropDown"
              onChange={handleSelectedType}
              value={state.selectedType}
            >
              {!isEmpty(state.productType)
                ? state.productType.length
                  ? state.productType.map((element) => {
                      return (
                        <>
                          <option key={element.id} value={element.id}>
                            {element.catagery}
                          </option>
                        </>
                      );
                    })
                  : null
                : null}
            </select>
          </section>

          <section className="select_product">
            <div className="section_header">Select Product</div>
            <div className="select_product_container">
              <div className="row row-cols-3 row-cols-md-4 g-4">
                {!isEmpty(state.productList)
                  ? state.productList.length
                    ? state.productList.map((element) => {
                        return (
                          <>
                            <div key={element.p_id} className="col">
                              <div
                                onClick={() => handlecartDetails(element)}
                                className="card text-bg-dark"
                              >
                                <img
                                  className="card_img"
                                  src={element.image}
                                  alt="card_img"
                                />

                                <div className="bottom_text">
                                  <p className="card-text">
                                    {element.product_name}
                                  </p>
                                  <div className="price">
                                    <span className="disc_price">
                                      ₹{" "}
                                      {parseInt(
                                        element.discounted_price
                                      ).toLocaleString("en-US")}
                                      /-
                                    </span>
                                    <span>
                                      <strike>₹ {element.price}/-</strike>
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="img-overlay">
                                {!check(element.p_id) ? (
                                  <img
                                    onClick={() =>
                                      handleAddWishList(element.p_id)
                                    }
                                    className="wishlist_icon"
                                    src={images.user_activity_40}
                                    alt="wishlist_icon"
                                  />
                                ) : (
                                  <img
                                    onClick={() =>
                                      handleRemoveWishList(element.p_id)
                                    }
                                    className="wishlist_icon_filled"
                                    src={images.user_activity_44}
                                    alt="wishlist_icon"
                                  />
                                )}
                              </div>
                            </div>
                          </>
                        );
                      })
                    : null
                  : null}
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loader: state.ui.loader,
    token: state.auth.authToken,
    tokenType: state.auth.tokenType,
    userId: state.auth.user.user_id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => {
      dispatch(uiActions.startLoading());
    },
    finishLoading: () => {
      dispatch(uiActions.finishLoading());
    },
    setProductList: (payload) => {
      dispatch(userProductList.setProductList(payload));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(HandBooks);
