import React from 'react';
import Avatar from 'react-avatar';

const Avatarr = ({ src, name }) => {

  return (
    <Avatar name={name} size="24" round={true} />   
  );
};

export default Avatarr;
