import { combineReducers } from "@reduxjs/toolkit";
import reducers from "../authSlice/AuthSlice";
// import parentTestReducer from "../testDataSlice/TestDataSlice";
import { parentTestReducer } from "../testDataSlice/TestDataSlice";

const appReducer = combineReducers({
  parentAuthReducer: reducers,
  parentTestReducer: parentTestReducer,
});

const parentrootReducer = (state, action) => {
  return appReducer(state, action);
};

export default parentrootReducer;
