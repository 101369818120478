import React from "react";
const Circle = ({ className, children }) => {
  return (
    <div className={className}>
      <div className="item" style={{ visibility: "hidden" }}>
        {children}
      </div>
    </div>
  );
};

export default Circle;
