import * as actionTypes from "./action_type";

const initialState = {
    day: "",
    subject: [],
    questionsList: {},
    pyqResultData: {},

}

const PyqDailyChallengeReducer = (
    state = initialState,
  { type, payload = null }
  ) => {
    switch (type) {
        case actionTypes.SET_DAY:
          return setDay(state, payload);
        case actionTypes.SET_SUBJECT:
          return setSubject(state, payload);
        case actionTypes.SET_QUESTIONS_LIST:
          return setQuestionsList(state, payload);
        case actionTypes.SET_PYQ_TESTS_RESULT_DATA:
          return setPyqTestsResultData(state, payload);
    default:
      return state;
    }
}

  const setDay = (state, payload) => {
    state = {
      ...state,
    day: payload
    };
    return state;
  };

  const setSubject = (state, payload) => {
    state = {
      ...state,
      subject: payload
    };
    return state;
  };

  const setQuestionsList = (state, payload) => {
    state = { ...state, questionsList: payload };
    return state;
  };

  const setPyqTestsResultData = (state, payload) => {
    state = { ...state, pyqResultData: payload };
    return state;
  };

  export default PyqDailyChallengeReducer;