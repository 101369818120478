import { generateRandomId } from "../../../../../custom_utilities/custom_useful_functions";

export const responseMessageFromMotion = () => {
  const responseText =
    "<div class='msg'> We have noted your reason and are assuring that, our team will work on it.</div><div class=msg> Click on “Ask Refund” button below to initiate the refund process.</div>";

  return {
    id: generateRandomId(),
    name: responseText,
    type: "motion",
  };
};
