import { Fragment } from "react";
import "./notes_feature.scss";

///////////////////Desktop
import LeftSideBarComponent from "../../structure_components/desktop_screens/left_sideBar_component/left_sideBar_component";
import HeaderComponent from "../../structure_components/desktop_screens/header_component/header_component";

///////Mobile////////////////////
import MobileLeftSideBarComponent from "../../structure_components/mobile_screens/mobile_left_sideBar_component/mobile_left_sideBar_component";
import MobileHeaderComponent from "../../structure_components/mobile_screens/mobile_header_component/mobile_header_component";
import MobileRightSideBarComponent from "../../structure_components/mobile_screens/mobile_right_sideBar_component/mobile__right_sideBar_component";

import NotesFeatureContentComponent from "./notes_feature_content_component/notes_feature_content_component";
import { connect } from "react-redux";

const NotesFeature = (props) => {
  let { notesData, history, userData, userPreference, token, tokenType } =
    props;
  const isMob = () => {
    if (window.innerWidth <= 480) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <Fragment>
      <div className="notes_feature_wrapper">
        <div className="notes_feature_inner_wrapper">
          {isMob() ? (
            /////for Mobile Screens////////////////////
            <Fragment>
              <MobileLeftSideBarComponent />
              <MobileHeaderComponent
                userPreference={userPreference}
                userData={userData}
              />
              <MobileRightSideBarComponent history={history} />
              <NotesFeatureContentComponent
                history={history}
                notesData={notesData}
                tokenType={tokenType}
                token={token}
              />
            </Fragment>
          ) : (
            /////for Screens greater than Mobile Screens////////////////////
            <Fragment>
              <div className="notes_feature_right_wrapper">
                <HeaderComponent history={history} />
                <NotesFeatureContentComponent
                  history={history}
                  notesData={notesData}
                  tokenType={tokenType}
                  token={token}
                />
              </div>
            </Fragment>
          )}
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    notesData: state.userActivity.notesData,
    userData: state.auth.user,
    userPreference: state.userPreference,
    token: state.auth.authToken,
    tokenType: state.auth.tokenType,
  };
};

export default connect(mapStateToProps)(NotesFeature);
