import { Fragment, useEffect, useState } from "react";
import "./user_notifications_content_component.scss";

import axios from "axios";
import { isEmpty } from "../../../../custom_utilities/custom_useful_functions";
import { url_37 } from "../../../../custom_utilities/api_services";
import images from "../../../../utilities/images/images";

const UserNotificationsContentComponent = () => {
  const [state, setState] = useState({
    notificationsList: [],
  });

  useEffect(() => {
    getNotificationsList();
  }, []);

  const getNotificationsList = async () => {
    try {
      const response = await axios.get(url_37);
      // console.log(response);
      if (response.data.status == 200) {
        setState((prevState) => ({
          ...prevState,
          notificationsList: response.data.data,
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Fragment>
      <div className="userNotificationsContentComponent_wrapper">
        <div className="userNotificationsContentComponent_inner_wrapper">
          <div className="heading_wrapper">
            <p className="heading_text_content">Notifications</p>
          </div>
          <div className="notifications_collection_wrapper">
            {state.notificationsList.length
              ? state.notificationsList.map((element, index) => {
                  return (
                    <div key={index} className="single_notification_wrapper">
                      <div className="icon_wrapper">
                        <img src={images.user_dashboard_10} />
                      </div>
                      <div className="text_content_wrapper">
                        <p className="text_content">
                          {element.notification_title}
                        </p>
                      </div>
                    </div>
                  );
                })
              : "No new notification"}
          </div>
          {/* <div className="btns_wrapper">
            <div className="btn_wrapper">
              <button onClick={() => handlePrevious()}>Previous</button>
            </div>
            <div className="btn_wrapper">
              <button onClick={() => handleNext()}>Next</button>
            </div>
          </div> */}
        </div>
      </div>
    </Fragment>
  );
};

export default UserNotificationsContentComponent;
