import { Fragment, useEffect, useState } from "react";
import "./package_details_component.scss";
import { Button, Modal } from "react-bootstrap";
import images from "../../../../../../utilities/images/images";
import {
  checkXSSAttackThroughInput,
  isEmpty,
} from "../../../../../../custom_utilities/custom_useful_functions";
import CcAvenuePayment from "../../../../../../custom_utilities/ccavence_payment/CcAvenuePayment";
import { url_177 } from "../../../../../../custom_utilities/api_services";
import axios from "axios";
import PaymentModal from "../modal/PaymentModal";
import { toastifyMessage } from "../../../../../../custom_utilities/toastify_config";

const PackageDetailsComponent = (props) => {
  let {
    subjectList,
    packageDetails,
    facultyList,
    handleStepNavigation,
    //handlePaymentInformationPayBtn,
    handlePackageSelect,
    pricePurchaseBtn,
    couponCollection,
    couponDetails,
    updateCoupon,
    saveUserTransactions,
    getFacultyDetail,
    facultyDetail,
    availableCoins,
    startLoading,
    finishLoading,
    coupanApplied,
    cpnAmt,
    setCpnAmt,
    setOriginalAmt,
    originalAmt,
    getUpdatedAmountFromBackend,
    orderId,
  } = props;

  //bootstarp modal
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [coinsDiscount, setCoinsDiscount] = useState(false);
  const [pkgID, setPkgID] = useState("");

  useEffect(() => {
    handleShow();
  }, [packageDetails]);

  const handleClose = () => {
    setShow(false);
    setApplyCoinData(initialApplyCoinData);
  };
  const handleClose2 = () => setShow2(false);
  const handleShow = () => {
    updateCoupon();
    setCpnInput("");
    setEl(packageDetails);
    setCpnAmt(packageDetails.final_amount);
    handlePackageSelect(packageDetails.package_id, packageDetails.final_amount);
    setShow(true);
    pricePurchaseBtn(packageDetails);
    setPkgID(packageDetails.package_id);
    setOriginalAmt(packageDetails.final_amount);
  };
  const handleShow2 = () => {
    setShow2(true);
  };
  const [selectedEl, setEl] = useState({});
  const [cpnInput, setCpnInput] = useState("");
  const initialApplyCoinData = {
    isError: false,
    errorMessage: "",
    data: {},
    coinsApplied: 0, // 0,1,2
  };
  const [applyCoinData, setApplyCoinData] = useState(initialApplyCoinData);

  const handleInputchange = async (value) => {
    const response = await checkXSSAttackThroughInput(value);

    if (!response) {
      return;
    } else {
      setCpnInput(value);
    }
    if (value.length == 0) {
      couponCollection(value, applyCoinData.data);
    }
  };

  const applyAvailableCoins = async (e) => {
    await getUpdatedAmountFromBackend();
    let target = e.target;
    console.log(target.checked);
    if (target.checked) {
      console.log("state", selectedEl);
      // ****/
      let data = {
        cart_amount: isEmpty(couponDetails)
          ? selectedEl.final_amount
          : couponDetails.updated_amount,
        coins_count: availableCoins,
        // transaction_id: "",
        type: "enquiry",
        order_id: orderId,
        checked: true,
      };
      startLoading();
      try {
        const response = await axios.post(url_177, data);
        if (response.data.status === 200) {
          console.log(response.data.data);
          setApplyCoinData((prev) => {
            return {
              ...prev,
              isError: false,
              errorMessage: "",
              data: response.data.data,
              coinsApplied: coupanApplied === 1 ? 2 : 1,
            };
          });

          setCpnAmt(response.data.data.updated_amount);

          finishLoading();
        } else if (response.data.status === 422) {
          finishLoading();
          setApplyCoinData((prev) => {
            return {
              ...prev,
              isError: true,
              errorMessage: response.data.message,
              data: response.data.data,
            };
          });
        }
      } catch (error) {
        console.log(error);
        finishLoading();
      }
      // ***/
    } else {
      let data = {
        cart_amount: isEmpty(couponDetails)
          ? selectedEl.final_amount
          : couponDetails.updated_amount,
        coins_count: availableCoins,
        // transaction_id: "",
        type: "enquiry",
        order_id: orderId,
        checked: false,
      };
      startLoading();
      try {
        const response = await axios.post(url_177, data);
        if (response.data.status === 200) {
          console.log(response.data.data);
          setApplyCoinData((prev) => {
            return {
              ...prev,
              isError: false,
              errorMessage: "",
              data: response.data.data,
              coinsApplied: coupanApplied === 1 ? 2 : 1,
            };
          });

          setCpnAmt(response.data.data.updated_amount);

          finishLoading();
        } else if (response.data.status === 422) {
          finishLoading();
          setApplyCoinData((prev) => {
            return {
              ...prev,
              isError: true,
              errorMessage: response.data.message,
              data: response.data.data,
            };
          });
        }
      } catch (error) {
        console.log(error);
        finishLoading();
      }

      // setApplyCoinData(initialApplyCoinData);
    }
  };
  // const applyAvailableCoins = async (e) => {
  //   let target = e.target;
  //   console.log(target.checked);
  //   if (target.checked) {
  //     console.log("state", selectedEl);
  //     // ****/
  //     let data = {
  //       cart_amount: isEmpty(couponDetails)
  //         ? selectedEl.final_amount
  //         : couponDetails.updated_amount,
  //       coins_count: availableCoins,
  //       // transaction_id: "",
  //       type: "enquiry",
  //     };
  //     startLoading();
  //     try {
  //       const response = await axios.post(url_177, data);
  //       if (response.data.status === 200) {
  //         console.log(response.data.data);
  //         setApplyCoinData((prev) => {
  //           return {
  //             ...prev,
  //             isError: false,
  //             errorMessage: "",
  //             data: response.data.data,
  //             coinsApplied: coupanApplied === 1 ? 2 : 1,
  //           };
  //         });
  //         finishLoading();
  //       } else if (response.data.status === 422) {
  //         finishLoading();
  //         setApplyCoinData((prev) => {
  //           return {
  //             ...prev,
  //             isError: true,
  //             errorMessage: response.data.message,
  //             data: response.data.data,
  //           };
  //         });
  //       }
  //     } catch (error) {
  //       console.log(error);
  //       finishLoading();
  //     }
  //     // ***/
  //   } else {
  //     setApplyCoinData(initialApplyCoinData);
  //   }
  // };

  const renderSubjectImage = (icon) => {
    if (icon) {
      return <img src={icon} />;
    } else {
      return <img src={images.user_dashboard_5} />;
    }
  };

  //console.log(packageDetails.description);

  return (
    <>
      {/* <HeaderComponent /> */}
      {/* {windowWidth <= 480 ? (
            /////for Mobile Screens////////////////////
            <Fragment>
              <MobileLeftSideBarComponent />
              <Mobile_header_component
                userPreference={userPreference}
                userData={userData}
              />
              <Mobile__right_sideBar_component history={history} />
            </Fragment>
          ) : (
            /////for Screens greater than Mobile Screens////////////////////
            <Fragment>
                <HeaderComponent history={history} />
            </Fragment>
          )} */}

      <div className="package-details-container" style={{ padding: "0" }}>
        <div
          className="bck-btn-course-overview flex-vertical-center"
          style={{ marginLeft: "1rem" }}
        >
          <button
            type="button"
            onClick={() => {
              handleStepNavigation(1, true);
            }}
          >
            <img src={images.m2_backBtn} alt="" />
          </button>
          <h3>Course Overview</h3>
        </div>

        <div className="package-details-wrapper flex">
          <div
            className="package-details-inner-wrapper flex"
            style={{ width: "100%" }}
          >
            <div className="package-left-wrapper">
              <div className="package-heading">
                <div className="package-title">
                  <img src={images.store_activiy_1} alt="heading-icon" />
                  <p className="package-title-name">
                    {!isEmpty(packageDetails)
                      ? `${packageDetails.package_name}`
                      : null}
                  </p>
                </div>
                <p className="package-descrp">
                  {!isEmpty(packageDetails)
                    ? `${packageDetails.description}`
                    : null}
                </p>
                <div className="package-tags">
                  <ul className="package-tags-list">
                    {!isEmpty(subjectList)
                      ? subjectList.subject.length
                        ? subjectList.subject.map((element, index) => {
                            return (
                              <>
                                <li key={index}>{element.sub_name}</li>
                              </>
                            );
                          })
                        : null
                      : null}
                  </ul>
                </div>
              </div>
              <div className="course-features-wrapper">
                <div className="course-features-inner-wrapper">
                  <h2>Course Features</h2>
                  <ul className="course-features-list flex">
                    {facultyList.PackageInstallmentDetails.course_benefits?.map(
                      (item) => {
                        return (
                          <li className="course-feature-list-item">
                            <img src={item.icon} alt={item.name} />
                            <p>{item.name}</p>
                          </li>
                        );
                      }
                    )}
                  </ul>
                </div>
              </div>
              {/* <div className="amrit">
                <img
                  src={images.amrit_gurantee}
                  className="amrit-image"
                  alt="amrit"
                />
              </div> */}
            </div>
            <div className="package-right-wrapper flex-hori-center">
              <div
                className="package-price-details"
                style={{ position: "fixed" }}
              >
                <div className="referal flex-vertical-center">
                  <img src={images.store_link} alt="referal-link-img" />
                  <div className="referal-descript">
                    <p className="hve-referal-code">Have a Referral Code?</p>
                    <p className="about-coins">
                      You can Earn{" "}
                      <span className="coins-count"> 500 Motion Coins </span>{" "}
                      for this Purchase
                    </p>
                  </div>
                </div>

                <div className="referal-input flex">
                  {/* <input
            type="text"
            placeholder="Enter Referral code"
            // onChange={handleInputChange}
            // value={state.refferalCode}
            id="refer-code"
            name="refer-code"
            maxlength="6"
          /> */}

                  {/* <div className="referal-status">
            {loading ? (
              <CustomSpinner
                variant={"custom"}
                className={"referal-custom-spinner"}
              />
            ) : state.isValidRefferal ? (
              <img src={images.store_tick} alt="referral-applied-img" />
            ) : state.refferalCode.length > 0 ? (
              <span onClick={applyReferalCode}>APPLY</span>
            ) : (
              <span
                onClick={() =>
                  toastifyMessage({
                    status: 201,
                    message: "Required Refferal Code",
                  })
                }
              >
                APPLY
              </span>
            )}
          </div> */}
                  <div className="referal-input flex">
                    {pkgID != 148 && pkgID != 149 ? (
                      <>
                        <input
                          type="text"
                          placeholder="Coupon code"
                          value={cpnInput}
                          onChange={(e) => handleInputchange(e.target.value)}
                        />
                        <div className="referal-status">
                          <button
                            onClick={() => {
                              cpnInput
                                ? couponCollection(cpnInput, applyCoinData.data)
                                : toastifyMessage({
                                    message: "Please enter Coupon Code",
                                  });
                            }}
                          >
                            APPLY
                          </button>
                        </div>
                      </>
                    ) : null}
                  </div>
                </div>
                {!applyCoinData.isError ? (
                  <div className="d-flex align-items-center gap-2 mt-1 applyCoin">
                    <input
                      type="checkbox"
                      id="coupenUseCheckbox"
                      className="form-check-input mt-0"
                      onChange={(e) => {
                        applyAvailableCoins(e);
                        setCoinsDiscount(!coinsDiscount);
                      }}
                    />
                    <label
                      htmlFor="coupenUseCheckbox"
                      style={{ fontWeight: "600" }}
                    >
                      Apply {Math.floor(availableCoins)} Motion Coins
                    </label>
                  </div>
                ) : (
                  <div className="d-flex align-items-center gap-2 applyCoinError">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="30px"
                      viewBox="0 0 512 512"
                    >
                      <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm0-384c13.3 0 24 10.7 24 24V264c0 13.3-10.7 24-24 24s-24-10.7-24-24V152c0-13.3 10.7-24 24-24zM224 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z" />
                    </svg>
                    <p className="coinErrorMsg">{applyCoinData.errorMessage}</p>
                  </div>
                )}
                <div className="price-details">
                  <p className="price-details-text">Price details</p>
                  <div className="package-price flex">
                    <p>Course Price</p>₹ {packageDetails.amount}
                  </div>
                  <div className="package-discount flex border-0">
                    <p>Discount</p>-₹ {packageDetails.discount}
                  </div>
                  {couponDetails.discount && (
                    <div className="package-discount flex border-0">
                      <p>Coupon Discount</p>-₹{" "}
                      {Math.floor(
                        (couponDetails.package_amount *
                          couponDetails.discount) /
                          100
                      ).toFixed(2)}
                    </div>
                  )}
                  {coinsDiscount && (
                    <div className="package-discount flex">
                      <p>Motion Coins</p>-₹{" "}
                      {Math.floor(availableCoins).toFixed(2)}
                    </div>
                  )}
                  <div className="package-final-price flex">
                    <p>Final Price</p>₹{" "}
                    {!isEmpty(couponDetails)
                      ? coupanApplied === 1
                        ? applyCoinData.coinsApplied === 2
                          ? applyCoinData.data.updated_amount
                          : couponDetails.updated_amount
                        : couponDetails.updated_amount
                      : isEmpty(applyCoinData.data)
                      ? cpnAmt
                      : applyCoinData.data.updated_amount}
                  </div>
                </div>

                {cpnAmt == 0 ? (
                  <div className="pay_now_in_pkg_details" closeButton>
                    <button onClick={() => saveUserTransactions(selectedEl)}>
                      Active
                    </button>
                  </div>
                ) : (
                  <CcAvenuePayment
                    packageDetails={packageDetails}
                    amount={
                      !isEmpty(couponDetails)
                        ? coupanApplied === 1
                          ? applyCoinData.coinsApplied === 2
                            ? applyCoinData.data.updated_amount
                            : couponDetails.updated_amount
                          : couponDetails.updated_amount
                        : isEmpty(applyCoinData.data)
                        ? cpnAmt
                        : applyCoinData.data.updated_amount
                    }
                    buttonText={`BUY NOW @ ₹ ${
                      !isEmpty(couponDetails)
                        ? coupanApplied === 1
                          ? applyCoinData.coinsApplied === 2
                            ? applyCoinData.data.updated_amount
                            : couponDetails.updated_amount
                          : couponDetails.updated_amount
                        : isEmpty(applyCoinData.data)
                        ? cpnAmt
                        : applyCoinData.data.updated_amount
                    }`}
                    buttonClass={"store_pay_now"}
                  />
                )}
                <div className="package-valid-date"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PackageDetailsComponent;
