import * as actionTypes from "./action_type";

const initialState = {
  otsTestsData: {},
  otsResultData: {},
  otsPaperResultData: {},
  otsPlannerData: {},
  otsPremiumData: [],
  otsFreeData: [],
};

const OtsReducer = (state = initialState, { type, payload = null }) => {
  switch (type) {
    case actionTypes.SET_OTS_TESTS_DATA:
      return setOtsTestsData(state, payload);

    case actionTypes.SET_OTS_TESTS_RESULT_DATA:
      return setOtsTestsResultData(state, payload);

    case actionTypes.SET_OTS_TESTS_PAPER_RESULT_DATA:
      return setOtsTestsPaperResultData(state, payload);

    case actionTypes.SET_OTS_PLANNER_DATA:
      return setOtsPlannerData(state, payload);

    case actionTypes.SET_OTS_PREMIUM_DATA:
      return setOtsPremiumData(state, payload);

    case actionTypes.SET_OTS_FREE_DATA:
      return setOtsFreeData(state, payload);

    default:
      return state;
  }
};

const setOtsTestsData = (state, payload) => {
  return (state = { ...state, otsTestsData: payload });
};

const setOtsTestsResultData = (state, payload) => {
  state = { ...state, otsResultData: payload };
  return state;
};

const setOtsTestsPaperResultData = (state, payload) => {
  const newState = { ...state, otsPaperResultData: payload };
  return newState;
};

const setOtsPlannerData = (state, payload) => {
  state = { ...state, otsPlannerData: payload };
  return state;
};

const setOtsPremiumData = (state, payload) => {
  state = { ...state, otsPremiumData: payload };
  return state;
};

const setOtsFreeData = (state, payload) => {
  state = { ...state, otsFreeData: payload };
  return state;
};

export default OtsReducer;
