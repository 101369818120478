import { Fragment, useState } from "react";
import "./doubtsView.scss";
import { Button, Modal } from "react-bootstrap";
import images from "../../../../../utilities/images/images";
import { isEmpty } from "../../../../../custom_utilities/custom_useful_functions";
import Usercreatedoubt from "../../../user_create_doubt/user_create_doubt";
import DoubtsViews from "../doubtDetailsView/doubtDetailsView";
import ScannedDoubtDetailsView from "../doubtDetailsView/ScannedDoubtDetailsView";

const DoubtsView = ({
  subjectCollection,
  answeredDoubts,
  scannedDoubts,
  unAnsweredDoubts,
  subjectDetails,
  handleCreateDoubtBtn,
  handleChangeSubject,
  handleTabChange,
  handleSaveDoubtDetails,
  selectedDetail,
  getQuestionList,
  doubtType,
}) => {
  //bootstarp modal
  const [show, setShow] = useState(false);
  const [selected, setSelected] = useState(null);
  const handClick = (el) => {
    setSelected(el);
    setShowDbtDetail(true);
  };
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showDbtDetail, setShowDbtDetail] = useState(false);
  const handleDtlClose = () => setShowDbtDetail(false);
  const handleDtlShow = () => setShowDbtDetail(true);

  const renderSubjectCollection = (subjectCollection) => {
    const newLocal = (
      <div className="subject_collection_wrapper">
        {!isEmpty(subjectCollection)
          ? subjectCollection.subject.length
            ? subjectCollection.subject.map((element, index) => {
                return (
                  <div>
                    {element.is_show_others === 1 ? (
                      <div>
                        <div key={index} className="single_select_subject">
                          <input
                            type="radio"
                            id={`subject_${element.sub_id}`}
                            name="subject"
                            value={element.sub_id}
                            checked={
                              element.sub_id ===
                              parseInt(subjectDetails.subjectId)
                            }
                            onChange={(event) =>
                              handleChangeSubject(
                                event,
                                element.sub_id,
                                element.sub_name,
                                element.icon
                              )
                            }
                          />

                          <label htmlFor={`subject_${element.sub_id}`}>
                            {element.sub_name}
                          </label>
                        </div>
                      </div>
                    ) : null}
                  </div>
                );
              })
            : null
          : null}
      </div>
    );
    return <Fragment>{newLocal}</Fragment>;
  };

  const renderQuestion = (question) => {
    return <p dangerouslySetInnerHTML={{ __html: question }}></p>;
  };

  const convertDate = (inputDate) => {
    const date = new Date(inputDate);
    const monthNames = [
      "jan",
      "feb",
      "mar",
      "apr",
      "may",
      "jun",
      "jul",
      "aug",
      "sep",
      "oct",
      "nov",
      "dec",
    ];

    const day = date.getDate();
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();

    return `${day} ${month}, ${year}`;
  };

  const renderDoubts = () => {
    return (
      <Fragment>
        <div className="doubtsCollectionView_wrapper">
          <div className="doubtsCollectionView_inner_wrapper">
            <div className="section_wrapper">
              <div className="section_inner_wrapper">
                <div className="options_wrapper">
                  <ul
                    className="nav nav-pills custom_nav_pills"
                    id="pills-tab"
                    role="tablist"
                  >
                    <li
                      className="nav-item custom_nav_item"
                      role="presentation"
                    >
                      <button
                        className="nav-link active custom_nav_link"
                        id="pills-answered-doubts-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-answered-doubts"
                        type="button"
                        role="tab"
                        aria-controls="pills-answered-doubts"
                        aria-selected="true"
                        onClick={() => handleTabChange(1, "answered")}
                      >
                        Answered
                      </button>
                    </li>
                    <li
                      className="nav-item custom_nav_item"
                      role="presentation"
                    >
                      <button
                        className="nav-link custom_nav_link"
                        id="pills-unanswered-doubts-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-unanswered-doubts"
                        type="button"
                        role="tab"
                        aria-controls="pills-unanswered-doubts"
                        aria-selected="false"
                        onClick={() => handleTabChange(0, "unanswered")}
                      >
                        Unanswered
                      </button>
                    </li>
                    <li
                      className="nav-item custom_nav_item"
                      role="presentation"
                    >
                      <button
                        className="nav-link custom_nav_link"
                        id="pills-scanned-doubts-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-scanned-doubts"
                        type="button"
                        role="tab"
                        aria-controls="pills-scanned-doubts"
                        aria-selected="false"
                        onClick={() => handleTabChange(0, "scanned")}
                      >
                        Scanned
                      </button>
                    </li>
                  </ul>
                </div>
                <div className="tab_wrapper">
                  <div
                    className="tab-content custom_tab_content"
                    id="pills-tabContent"
                  >
                    {/* //////////////answered doubts tab//////// */}
                    <div
                      className="tab-pane fade show active custom_tab_pane"
                      id="pills-answered-doubts"
                      role="tabpanel"
                      aria-labelledby="pills-answered-doubts-tab"
                    >
                      <div className="answered_doubts_tab_wrapper">
                        <div className="outer_content_wrapper">
                          <div className="doubts_collection_wrapper">
                            {!isEmpty(answeredDoubts) ? (
                              answeredDoubts.SaveDouteQuestionlist.data
                                .length ? (
                                answeredDoubts.SaveDouteQuestionlist.data.map(
                                  (element, index) => {
                                    return (
                                      <div
                                        key={index}
                                        className="single_doubt_wrapper"
                                      >
                                        <div className="content_wrapper_1">
                                          <div className="left_side_content_wrapper">
                                            <div className="text_content_wrapper">
                                              <p className="text_content">
                                                {`Posted at ${element.post_date}`}
                                              </p>
                                            </div>
                                          </div>
                                          <div className="right_side_content_wrapper">
                                            <div className="image_wrapper">
                                              {/* <img
                                                src={images.user_activity_3}
                                              /> */}
                                            </div>
                                          </div>
                                        </div>
                                        <div className="content_wrapper_2">
                                          <div className="text_content_wrapper">
                                            <p className="text_content">
                                              {element.question}
                                            </p>
                                          </div>
                                        </div>
                                        <div className="content_wrapper_3">
                                          <div className="text_content_wrapper">
                                            <p className="text_content">
                                              <label
                                                // onClick={handleDtlShow}
                                                onClick={() =>
                                                  handClick(element)
                                                }
                                                // onClick={() =>
                                                //   handleSaveDoubtDetails(
                                                //     element
                                                //   )
                                                // }
                                              >
                                                Answered by
                                                <span>
                                                  {element.Answer.name}
                                                </span>
                                              </label>
                                            </p>
                                          </div>
                                          {/* /////popup */}
                                        </div>
                                      </div>
                                    );
                                  }
                                )
                              ) : (
                                <>
                                  <div className="no_data_found_wrapper">
                                    <img src={images.M2Doubts} />
                                    <p>
                                      You haven't got an answer on your Doubts
                                      please wait till faculties will answer on
                                      your doubts.
                                    </p>
                                  </div>
                                  <div
                                    className="content_inner_wrapper"
                                    // onClick={() => handleCreateDoubtBtn()}
                                    onClick={handleShow}
                                  >
                                    <div className="add_btn">+</div>
                                    <div className="text_content_wrapper">
                                      <p className="text_content">
                                        Create New Doubt
                                      </p>
                                    </div>
                                  </div>
                                </>
                              )
                            ) : (
                              <>
                                <div className="no_data_found_wrapper">
                                  <img src={images.M2Doubts} />
                                  <p>
                                    You haven't got an answer on your Doubts
                                    please wait till faculties will answer on
                                    your doubts.
                                  </p>
                                </div>
                                <div
                                  className="content_inner_wrapper"
                                  // onClick={() => handleCreateDoubtBtn()}
                                  onClick={handleShow}
                                >
                                  <div className="add_btn">+</div>
                                  <div className="text_content_wrapper">
                                    <p className="text_content">
                                      Create New Doubt
                                    </p>
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* //////////////unanswered doubts tab//////// */}
                    <div
                      className="tab-pane fade custom_tab_pane"
                      id="pills-unanswered-doubts"
                      role="tabpanel"
                      aria-labelledby="pills-unanswered-doubts-tab"
                    >
                      <div className="unanswered_doubts_tab_wrapper">
                        <div className="outer_content_wrapper">
                          <div className="doubts_collection_wrapper">
                            {!isEmpty(unAnsweredDoubts) ? (
                              unAnsweredDoubts.SaveDouteQuestionlist.data
                                .length ? (
                                unAnsweredDoubts.SaveDouteQuestionlist.data.map(
                                  (element, index) => {
                                    return (
                                      <div
                                        key={index}
                                        className="single_doubt_wrapper"
                                        onClick={() => handClick(element)}
                                      >
                                        <div className="content_wrapper_1">
                                          <div className="left_side_content_wrapper">
                                            <div className="text_content_wrapper">
                                              <p className="text_content">
                                                {`Posted at ${element.post_date}`}
                                              </p>
                                            </div>
                                          </div>
                                          <div className="right_side_content_wrapper">
                                            <div className="image_wrapper">
                                              {/* <img
                                                src={images.user_activity_3}
                                              /> */}
                                            </div>
                                          </div>
                                        </div>
                                        <div className="content_wrapper_2">
                                          <div className="text_content_wrapper">
                                            <p className="text_content">
                                              {element.question}
                                            </p>
                                          </div>
                                        </div>
                                        <div className="content_wrapper_3">
                                          <div className="text_content_wrapper">
                                            <p className="text_content">
                                              <label>
                                                View Doubt...
                                                {/* <span>
                                                  {element.Answer.name}
                                                </span> */}
                                              </label>
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  }
                                )
                              ) : (
                                <>
                                  <div className="no_data_found_wrapper">
                                    <img src={images.M2Doubts2} />
                                    <p>
                                      Looks like you haven't uploaded any doubts
                                      so please upload your doubts and get the
                                      answers from faculties.
                                    </p>
                                  </div>
                                  <div
                                    className="content_inner_wrapper"
                                    // onClick={() => handleCreateDoubtBtn()}
                                    onClick={handleShow}
                                  >
                                    <div className="add_btn">+</div>
                                    <div className="text_content_wrapper">
                                      <p className="text_content">
                                        Create New Doubt
                                      </p>
                                    </div>
                                  </div>
                                </>
                              )
                            ) : (
                              <>
                                <div className="no_data_found_wrapper">
                                  <img src={images.M2Doubts2} />
                                  <p>
                                    Looks like you haven't uploaded any doubts
                                    so please upload your doubts and get the
                                    answers from faculties.
                                  </p>
                                </div>
                                <div
                                  className="content_inner_wrapper"
                                  // onClick={() => handleCreateDoubtBtn()}
                                  onClick={handleShow}
                                >
                                  <div className="add_btn">+</div>
                                  <div className="text_content_wrapper">
                                    <p className="text_content">
                                      Create New Doubt
                                    </p>
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* ///////////////scanned doubts tab */}
                    <div
                      className="tab-pane fade custom_tab_pane"
                      id="pills-scanned-doubts"
                      role="tabpanel"
                      aria-labelledby="pills-scanned-doubts-tab"
                    >
                      <div className="answered_doubts_tab_wrapper">
                        <div className="outer_content_wrapper">
                          <div className="doubts_collection_wrapper">
                            {!isEmpty(scannedDoubts) ? (
                              scannedDoubts.SaveDouteQuestionlist.data
                                .length ? (
                                scannedDoubts.SaveDouteQuestionlist.data.map(
                                  (element, index) => {
                                    return (
                                      <div
                                        key={index}
                                        className="single_doubt_wrapper"
                                        onClick={() => handClick(element)}
                                      >
                                        <div className="content_wrapper_1">
                                          <div className="left_side_content_wrapper">
                                            <div className="text_content_wrapper">
                                              <p className="text_content">
                                                {`Posted at ${convertDate(
                                                  element.post_date
                                                )}`}
                                              </p>
                                            </div>
                                          </div>
                                          <div className="right_side_content_wrapper">
                                            <div className="image_wrapper">
                                              {/* <img
                                                src={images.user_activity_3}
                                              /> */}
                                            </div>
                                          </div>
                                        </div>
                                        <div className="content_wrapper_2">
                                          <div className="text_content_wrapper">
                                            <p className="text_content">
                                              {/* {element.question} */}
                                              {renderQuestion(element.question)}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  }
                                )
                              ) : (
                                <>
                                  <div className="no_data_found_wrapper">
                                    <img src={images.M2Doubts} alt="doubts" />
                                    <p>
                                      You haven't got an answer on your Doubts
                                      please wait till faculties will answer on
                                      your doubts.
                                    </p>
                                  </div>
                                  <div
                                    className="content_inner_wrapper"
                                    // onClick={() => handleCreateDoubtBtn()}
                                    onClick={handleShow}
                                  >
                                    <div className="add_btn">+</div>
                                    <div className="text_content_wrapper">
                                      <p className="text_content">
                                        Create New Doubt
                                      </p>
                                    </div>
                                  </div>
                                </>
                              )
                            ) : (
                              <>
                                <div className="no_data_found_wrapper">
                                  <img src={images.M2Doubts} />
                                  <p>
                                    You haven't got an answer on your Doubts
                                    please wait till faculties will answer on
                                    your doubts.
                                  </p>
                                </div>
                                <div
                                  className="content_inner_wrapper"
                                  // onClick={() => handleCreateDoubtBtn()}
                                  onClick={handleShow}
                                >
                                  <div className="add_btn">+</div>
                                  <div className="text_content_wrapper">
                                    <p className="text_content">
                                      Create New Doubt
                                    </p>
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="douts_detailModal">
          <Modal
            show={showDbtDetail}
            onHide={handleDtlClose}
            className="view_douts_modal"
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body style={{ marginTop: "30px" }}>
              {doubtType !== "scanned" ? (
                <DoubtsViews
                  doubtDetails={selected}
                  renderQuestion={renderQuestion}
                  convertDate={convertDate}
                  doubtType={doubtType}
                />
              ) : (
                <ScannedDoubtDetailsView
                  doubtDetails={selected}
                  renderQuestion={renderQuestion}
                  convertDate={convertDate}
                  doubtType={doubtType}
                />
              )}
            </Modal.Body>
          </Modal>
        </div>
      </Fragment>
    );
  };
  return (
    <Fragment>
      <div className="doubtsView_wrapper">
        <div className="doubtsView_inner_wrapper">
          <div className="sec_2_wrapper">
            <div className="sec_2_inner_wrapper">
              <div className="content_wrapper">
                <div className="left_side_content_wrapper">
                  {renderSubjectCollection(subjectCollection)}
                </div>
                <div className="right_side_content_wrapper">
                  {renderDoubts()}
                  <div className="sec_1_wrapper">
                    {!isEmpty(answeredDoubts) ? (
                      answeredDoubts.SaveDouteQuestionlist.data.length ? (
                        <>
                          {" "}
                          <div
                            className="content_inner_wrapper"
                            // onClick={() => handleCreateDoubtBtn()}
                            onClick={handleShow}
                          >
                            <div className="add_btn">+</div>
                            <div className="text_content_wrapper">
                              <p className="text_content">Create New Doubt</p>
                            </div>
                          </div>
                        </>
                      ) : (
                        <></>
                      )
                    ) : (
                      <></>
                    )}

                    {!isEmpty(unAnsweredDoubts) ? (
                      unAnsweredDoubts.SaveDouteQuestionlist.data.length ? (
                        <>
                          {" "}
                          <div
                            className="content_inner_wrapper"
                            // onClick={() => handleCreateDoubtBtn()}
                            onClick={handleShow}
                          >
                            <div className="add_btn">+</div>
                            <div className="text_content_wrapper">
                              <p className="text_content">Create New Doubt</p>
                            </div>
                          </div>
                        </>
                      ) : (
                        <></>
                      )
                    ) : (
                      <></>
                    )}

                    <div className="create_douts_modalWrap">
                      <Modal
                        show={show}
                        onHide={handleClose}
                        className="create_douts_modal"
                        centered
                      >
                        <Modal.Header closeButton></Modal.Header>
                        <Modal.Body>
                          <Usercreatedoubt
                            getQuestionList={getQuestionList}
                            handleClose={handleClose}
                            slctSubjectId={subjectDetails}
                          />
                        </Modal.Body>
                      </Modal>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default DoubtsView;
