import { Fragment } from "react";
import { useLocation } from "react-router-dom";
import "./exercise_test_component.scss";

import LoaderPopUp from "../../../../../components/loader/loader";

////////
import HeaderComponentTwo from "../../../structure_components/desktop_screens/header_component/header_top/Header_top";
import TestContentComponent from "./exerciseTest_content_component/test_content_component";

//Redux
import { connect } from "react-redux";

const ExerciseTestComponent = (props) => {
  const test_type = useLocation();

  
  let { history, loader, userData, state } = props;
  const isMob = () => {
    if (window.innerWidth <= 480) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Fragment>
      <div className="test_component_wrapper">
        <div className="test_component_inner_wrapper">
          {/* {isMob() ? null : (
            <Fragment>
              <TestContentComponent history={history} test_type={test_type} userData={userData}/>
            </Fragment>
          )} */}
              <TestContentComponent history={history} test_type={test_type} userData={userData}/>
        </div>
      </div>
      {loader && <LoaderPopUp />}
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    loader: state.ui.loader,
    userData: state.auth.user,
  };
};

export default connect(mapStateToProps)(ExerciseTestComponent);
