import React, {useEffect } from "react";
import { connect } from "react-redux";
import authActions from "../../redux/auth/action";

const InactivityLogout = ({ logoutUser }) => {
  const SESSION_TIMEOUT = 120 * 60 * 1000; // 30 minutes in milliseconds
    let logoutTimer;
    

  const resetTimer = () => {
    clearTimeout(logoutTimer);
    startTimer();
  };

    const startTimer = () => {
    logoutTimer = setTimeout(() => {
      // Call logout function when session times out
      logoutUser();
    }, SESSION_TIMEOUT);
  };

  useEffect(() => {
    startTimer();

    // Add event listeners to reset timer on user activity
    window.addEventListener("mousemove", resetTimer);
    window.addEventListener("keydown", resetTimer);

    return () => {
      clearTimeout(logoutTimer);
      window.removeEventListener("mousemove", resetTimer);
      window.removeEventListener("keydown", resetTimer);
    };
  }, []);

  return <div></div>;
};

const mapDispatchToProps = (dispatch) => {
  return {
    logoutUser: () => {
      dispatch(authActions.logoutUser());
    },
  };
};

export default connect(null, mapDispatchToProps)(InactivityLogout);
