import { Fragment } from "react";
import "./test_instructions_view.scss";

import images from "../../../../../../../utilities/images/images";
import { isEmpty } from "../../../../../../../custom_utilities/custom_useful_functions";
import { Link } from "react-router-dom";

const TestInstructionsView = (props) => {
  let { testDetails, testSectionsDetails, startBtn, handleRedirectBtn, testInstructionData } = props; 

  function testTime(minutes) {
    var h = Math.floor(minutes / 60);
    var m = minutes % 60;
    h = h < 10 ? "0" + h : h;
    m = m < 10 ? "0" + m : m;
    return h + ":" + m + " hrs";
  }
  return (
    <Fragment>
      <div className="test_intructions_view_wrapper">
        <div className="container">
          <div className="test_intructions_view_inner_wrapper">
            <div className="instructionScreenBox">
              <Link className="backBtn" to={{
                pathname: "/Pyq_subjectTopic",
              }}>
                <img src={images.m2_backBtn} alt="backBtn" />
              </Link>
              <div className="instruction_title">Instruction Screen</div>
              <div className="instruction_detailBox">
                <div className="examTime">
                  <div className="examTitle">{testDetails.topic_name}</div>
                  
                </div>
                <div className="instructions">
                  <h3>Set of Instructions</h3>
                  <div className="instPoint">
                    <p>{testInstructionData.Instructions}</p>
                    <h6>Best of luck</h6>
                  </div>
                </div>
                <div className="startTestBtn">
                  <button onClick={() => startBtn()}>Start Test</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default TestInstructionsView;
