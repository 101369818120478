import { Fragment, useEffect, useState } from "react";
import "./last_paused_video.scss";

import images from "../../../../../../utilities/images/images";
import axios from "axios";
import { url_51 } from "../../../../../../custom_utilities/api_services";
import { isEmpty } from "../../../../../../custom_utilities/custom_useful_functions";

//Redux
import { connect } from "react-redux";
import authActions from "../../../../../../redux/auth/action";
import userActivityActions from "../../../../../../redux/user_activity/action";

const LastPausedVideo = (props) => {
  let { history, setVideoData } = props;

  const [state, setState] = useState({
    videoDetails: {},
  });

  useEffect(() => {
    getLastPlayVideoDetails();
  }, []);

  const getLastPlayVideoDetails = async () => {
    try {
      const response = await axios.get(url_51);
      if (response.data.status === 200) {
        setState((prevState) => ({
          ...prevState,
          videoDetails: response.data.data,
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleStoreVideoData = (videoData) => {
    videoData.redirectedFrom = "last_paused_video";
    setVideoData(videoData);
    history.push("/videos");
  };

  return (
    <Fragment>
      {!isEmpty(state.videoDetails) ? (
        <div className="lastPausedVideoView_wrapper">
          <div className="container">
            <div className="lastPausedVideoView_inner_wrapper">
              <div className="pause_vd_title">
                <h4>Pause Videos</h4>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry simply dummy text of the printing and
                  typesetting industry.
                </p>
              </div>
              <div className="section_2_wrapper">
                <div className="section_2_inner_wrapper">
                  <div className="last_paused_video_wrapper">
                    {/* <div
                    className="single_video_wrapper"
                    onClick={() => handleStoreVideoData(state.videoDetails)}
                  >
                    <div className="video_thumbnail">
                      <div className="image_wrapper">
                        <img src={state.videoDetails.thumnail} />
                      </div>
                      <div className="video_play_btn">
                        <i className="fas fa-play-circle"></i>
                      </div>
                    </div>
                    <div className="video_details">
                      <div className="text_content_wrapper">
                        <p className="text_content">
                          {state.videoDetails.video_title}
                        </p>
                      </div>
                    </div>
                  </div> */}
                    <div
                      className="single_video_wrapper"
                      onClick={() => handleStoreVideoData(state.videoDetails)}
                    >
                      <div className="left_side_content_wrapper">
                        <div className="image_wrapper">
                          <img src={state.videoDetails.thumnail} />
                        </div>
                      </div>
                      <div className="right_side_content_wrapper">
                        <div className="text_content_wrapper_1">
                          <p className="text_content_1">
                            {state.videoDetails.video_title}
                          </p>
                        </div>
                        <div className="sub_content_wrapper">
                          <div className="label_content_wrapper">
                            <label>
                              <span>
                                <img src={images.user_activity_2} />
                              </span>
                              <span className="rating">
                                {state.videoDetails.video_rating}
                              </span>
                            </label>
                          </div>
                          <div className="right_side_content_wrapper">
                            {/* <div className="image_wrapper">
                            {element.IsSaved ? (
                              <img
                                src={images.user_activity_4}
                                onClick={() => handleBookmarkVideo(element)}
                              />
                            ) : (
                              <img
                                src={images.user_activity_3}
                                onClick={() => handleBookmarkVideo(element)}
                              />
                            )}
                          </div> */}
                          </div>
                        </div>
                        <div className="resume_btn">
                          <span>Resume</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    userPreference: state.userPreference,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setVideoData: (payload) => {
      dispatch(userActivityActions.setVideoData(payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LastPausedVideo);
