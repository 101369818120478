import { Fragment } from "react";
import "./QuestionBankTestComponent";

import LoaderPopUp from "../../../../components/loader/loader";

////////
import QuestionBankTestContentComponent from "./question_bank_test_content_components/QuestionBankTestContentComponent";

//Redux
import { connect } from "react-redux";

const QuestionBankTestComponent = (props) => {
  let { history, loader, userData } = props;
  const isMob = () => {
    if (window.innerWidth <= 480) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Fragment>
      <div className="test_component_wrapper">
        <div className="test_component_inner_wrapper">
          {isMob() ? null : (
            <Fragment>
              <QuestionBankTestContentComponent
                history={history}
                userData={userData}
              />
            </Fragment>
          )}
        </div>
      </div>
      {loader && <LoaderPopUp />}
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    loader: state.ui.loader,
    userData: state.auth.user,
  };
};

export default connect(mapStateToProps)(QuestionBankTestComponent);
