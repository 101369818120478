import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import axios from "axios";
import "./RefundSuccessModal.scss";
import images from "../../../../utilities/images/images";
import { url_8 } from "../../../../custom_utilities/api_services";

const RefundSuccessModal = (props) => {
  const { isRefundSuccessModalOpen, closeRefundSuccessModal } = props;
  const [userMobileNo, setUserMobileNo] = useState("");

  useEffect(() => {
    getUserData();
  }, []);

  const getUserData = async () => {
    try {
      const response = await axios.get(url_8);
      if (response.data.status === 200) {
        setUserMobileNo(response.data.data.mobile);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal
      show={isRefundSuccessModalOpen}
      onHide={closeRefundSuccessModal}
      dialogClassName="refund-initiated"
      centered
    >
      <Modal.Header closeButton className="refund-modal-header"></Modal.Header>

      <Modal.Body>
        <div className="refund-details">
          <img src={images.refund_img} alt="refund-img" />
          <h2>Refund Initiated!</h2>

          <p className="refund-info">
            You will receive the confirmation and further steps information from
            us on your registered mobile number.
          </p>

          <p className="refund-mbl-no">
            Your registered mobile number is +91 {userMobileNo}
          </p>

          <div className="bck-to-pkgs" onClick={closeRefundSuccessModal}>
            Back to Your Packages
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default RefundSuccessModal;
