import { React, useEffect, useState } from "react";
import "./CpsSubjectSubTopicContent.scss";
import images from "../../../../../../utilities/images/images";
import { Link, useHistory,useLocation } from "react-router-dom";
import axios from "axios";
import { isEmpty } from "../../../../../../custom_utilities/custom_useful_functions";
import {
  url_91,
} from "../../../../../../custom_utilities/api_services";
//Redux
import { connect } from "react-redux";
import uiActions from "../../../../../../redux/ui/action";
import userActivityActions from "../../../../../../redux/user_activity/action";
import userPyqTestsAction from "../../../../../../redux/pyq_test_exercises/action";

const CpsSubjectSubTopicContent = (props) => {
  let { userPreference, pyqtopicData, startLoading,finishLoading,setPyqTestsData,CommonBannerData,
  } = props;
  const location = useLocation();
  let subTopic = location.state.subTopic;
  const [state, setState] = useState({
    topicItemList: {},
    topicName: location.state.subTopic
  });
  let history = useHistory();
  useEffect(() => {
    getTopicList();
  }, []);

  const getTopicList = async() => {
    let data = {
      topic_name: subTopic,
    }
    startLoading();
    try { 
      const response = await axios.post(url_91, data);
      if (response.data.status === 200) {
        setState((prevState) => ({
          ...prevState, topicItemList: response.data.data,
        }))
        finishLoading();
      }
      finishLoading();
    }
    catch (error) {
      console.log(error);
    }
}
  
  const testModule = () => {
    history.push("/PyqCpsExamTest");
  };
  //...sendData
  const onHandleTakeTest = (testData) => {
   setPyqTestsData(testData);
   if(!CommonBannerData.packageActive) {
    history.push({
      pathname: "/store", state: { tab: "online_learning" }
    })
   } else {
    history.push({
      pathname: '/CpsTestComponent',
      state: { subTopic: subTopic }
        });
   }
    // else {
    //   setUserTestsResultData(testData);
    //   history.push("/test_result");
    // }
    // setUserTestsResultData(testData);
    // history.push("/test_result");
  };
  return (
    <>
      <div className="pyq_subjectSubTopicWrapper">
        <div className="container">
          <div className="pyq_subjectTopicInner">
            {state ? (
              <div className="pyqSbjTpcListBox">
                <Link className="backBtn" to={{
                  pathname: "/Cps_subjectTopic",
                  state: { tab: "pyq" }
              }}>
                  <img src={images.m2_backBtn} alt="backBtn" />
                </Link>
                <div className="subject_name">{state.topicName.topic_name}</div>
                <div className="subject_topic_list">
                  {!isEmpty(state.topicItemList) ? state.topicItemList.length ? state.topicItemList.map((elem,index) => {
                    return (
                    <div className="subject_topics" onClick={()=> onHandleTakeTest(elem)}>
                        {elem.is_attempt?<div className="sbjct_tpc_name">{`${elem.sub_topic_name} `}<span style={{fontWeight:"bold",fontSize:"15px"}}>(Attempted)</span></div>:
                        <div className="sbjct_tpc_name">{elem.sub_topic_name}</div>}
                    <div className="arrow">
                      <img src={images.m2_rgtArow} alt="arrow" />
                    </div>
                      </div>
                  )
                  })
                    : null
                    : null
                  }
                  
                </div>
              </div>
            ) : (
              <div className="instructionScreenBox">
                <div className="backBtn">
                  <img src={images.m2_backBtn} alt="backBtn" />
                </div>
                <div className="instruction_title">Instruction Screen</div>
                <div className="instruction_detailBox">
                  <div className="examTime">
                    <div className="examTitle">Exam Topic</div>
                    <div className="examDurations">Duration : 3:00:00</div>
                  </div>
                  <div className="instructions">
                    <h3>Set of Instructions</h3>
                    <div className="instPoint">
                      <p>
                        This Section contains a set of questions for practice
                      </p>
                      <p>
                        Users can go to previous question by clicking on the
                        previous button
                      </p>
                      <p>
                        Users can go to next question by clicking on the next
                        button
                      </p>
                      <h6>Best of luck</h6>
                    </div>
                  </div>
                  <div className="startTestBtn">
                    <button onClick={testModule}>Start Test</button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userPreference: state.userPreference,
    pyqtopicData: state.userActivity.pyqtopicData,
    CommonBannerData: state.userActivity.commonBannerData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => {
      dispatch(uiActions.startLoading());
    },
    finishLoading: () => {
      dispatch(uiActions.finishLoading());
    },
    setPyqTopicData: (payload) => {
      dispatch(userActivityActions.setPyqTopicData(payload));
    },
    setPyqTestsData: (payload) => {
      dispatch(userPyqTestsAction.setPyqTestsData(payload));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CpsSubjectSubTopicContent);