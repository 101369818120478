import axios from "axios";
import React from "react";
import { useState, useEffect } from "react";
import { connect } from "react-redux";

import { useHistory } from "react-router";
import "./HandBooksCcAvenuePayment.scss";
import { url_147 } from "../../../../../../custom_utilities/api_services";
import saveOrderId from "../../../../../../redux/handbook_payment_gateway/action";

const HandBookCcAvenuePayment = (props) => {
  const { packageDetails, amount, token, tokenType, paymentAction, userData } =
    props;
  const { name, mobile, email, address, batch_id, user_id, UsertargetDetail } =
    userData;

  const [ccAvenueData, setCcAvenueData] = useState({});

  const history = useHistory();

  useEffect(() => {
    getCcAvenueData();
  }, []);

  const currentData = new Date();
  let date =
    currentData.getFullYear() +
    "-" +
    (currentData.getMonth() + 1) +
    "-" +
    currentData.getDate();
  let time =
    currentData.getHours() +
    ":" +
    currentData.getMinutes() +
    ":" +
    currentData.getSeconds();
  var dateTime = date + " " + time;

  const getCcAvenueData = async () => {
    const headers = {
      Authorization: `${tokenType} ${token}`,
    };
    try {
      const response = await axios.get(`${url_147}?mode=web`, { headers });
      console.log("getCcAvenueData", response);
      if (response.data.status === 200) {
        setCcAvenueData(response.data);
        paymentAction(response.data.Order_Id);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleProfileData = () => {
    if (name == "" || name == null) {
      alert("You haven't update your name in your profile. Please update it");
      history.push("/user_edit_profile");
    } else if (mobile == "" || mobile == null) {
      alert(
        "You haven't update your mobile number in your profile. Please update it"
      );
      history.push("/user_edit_profile");
    } else if (email == "" || email == null) {
      alert("You haven't update your email in your profile. Please update it");
      history.push("/user_edit_profile");
    } else if (address.state == "" || address.state == null) {
      alert("You haven't update your state in your profile. Please update it");
      history.push("/user_edit_profile");
    } else if (address.city == "" || address.city == null) {
      alert("You haven't update your city in your profile. Please update it");
      history.push("/user_edit_profile");
    }
  };

  const ccAvenueFunction = () => {
    return (
      <>
        <div id="ccav-payment-form">
          <form
            name="frmPayment"
            action="https://learning.motion.ac.in/ccweb/ccavRequestHandler.php"
            method="post"
          >
            <input
              type="hidden"
              name="merchant_id"
              value={ccAvenueData.Merchant_ID}
            />
            <input type="hidden" name="language" value="EN" />
            <input type="hidden" name="amount" value={amount} />
            {/* <input type="hidden" name="amount" value="1" /> */}

            <input type="hidden" name="currency" value="INR" />
            <input
              type="hidden"
              name="redirect_url"
              value={`${
                ccAvenueData.Redirect_Url
              }?page=${"https://learning.motion.ac.in/payment_gateway"}`}

              // value={`${
              //   ccAvenueData.Redirect_Url
              // }?page=${"http://localhost:3000/payment_gateway_store"}`}
            />
            <input
              type="hidden"
              name="cancel_url"
              // value={`${
              //   ccAvenueData.Redirect_Url
              // }?page=${"https://webtesting.motion.ac.in/payment_gateway"}`}
              value={`${
                ccAvenueData.Redirect_Url
              }?page=${"https://learning.motion.ac.in/payment_gateway"}`}

            //   value={`${
            //     ccAvenueData.Redirect_Url
            //   }?page=${"http://localhost:3000/payment_gateway"}`}
            />
            <input
              type="hidden"
              name="order_id"
              value={ccAvenueData.Order_Id}
            />
            <input
              type="hidden"
              name="access_code"
              value={ccAvenueData.Access_code}
            />

            <input
              type="hidden"
              name="rsa_key_url"
              value={ccAvenueData.RSA_Url}
            />

            <input
              type="hidden"
              name="merchant_param4"
              value={packageDetails.package_id ? packageDetails.package_id : ""}
            />

            <input
              type="hidden"
              name="duration"
              value={packageDetails?.duration ? packageDetails?.duration : ""}
            />

            <input
              type="hidden"
              name="discountAmount"
              value={packageDetails.discount ? packageDetails.discount : ""}
            />

            <input
              type="hidden"
              name="merchant_param3"
              value={
                packageDetails.package_name ? packageDetails.package_name : ""
              }
            />

            <input
              type="hidden"
              name="packageAmt"
              value={packageDetails.amount ? packageDetails.amount : ""}
            />
            <input
              type="hidden"
              name="courseId"
              value={packageDetails.course_id ? packageDetails.course_id : ""}
            />

            <input type="hidden" name="finalAmount" value={amount} />

            <input type="hidden" name="billing_name" value={name} />
            <input type="hidden" name="billing_tel" value={mobile} />
            <input type="hidden" name="billing_email" value={email} />
            <input
              type="hidden"
              name="billing_address"
              value={address.address_type}
            />
            <input type="hidden" name="billing_city" value={address.city} />
            <input type="hidden" name="billing_state" value={address.state} />

            <input type="hidden" name="batchId" value={batch_id} />
            <input type="hidden" name="userId" value={user_id} />
            <input
              type="hidden"
              name="merchant_param2"
              value={UsertargetDetail.target_id}
            />

            <input type="hidden" name="txnId" value={ccAvenueData.Order_Id} />
            <div className="pay_now" onClick={handleProfileData}>
              <button>Confirm & Proceed</button>
            </div>
          </form>
        </div>
      </>
    );
  };
  return <>{Object.keys(ccAvenueData).length ? ccAvenueFunction() : ""}</>;
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.authToken,
    tokenType: state.auth.authType,
    userData: state.auth.user,
  };
};

const mapDipatchToProps = (dispatch) => {
  return {
    paymentAction: (payload) => {
      dispatch(saveOrderId.setOrderId(payload));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDipatchToProps
)(HandBookCcAvenuePayment);
