import React from "react";
import StudentProfile from "./studentProfile/StudentProfile";
import AllTestsPerformanceReportTabel from "./allTestsPerformanceReportTabel/AllTestsPerformanceReportTabel";

const ParentContentComponent = ({
  finishLoading,
  startLoading,
  studentRollNum,
  setUserId,
  setStudentProfileDetails,
  loader,
  LoaderPopUp,
  setParentTarget,
}) => {
  return (
    <>
      <StudentProfile
        finishLoading={finishLoading}
        startLoading={startLoading}
        studentRollNum={studentRollNum}
        setUserId={setUserId}
        setStudentProfileDetails={setStudentProfileDetails}
        setParentTarget={setParentTarget}
        loader={loader}
        LoaderPopUp={LoaderPopUp}
      />

      {/* <AllTestsPerformanceReportTabel
        finishLoading={finishLoading}
        startLoading={startLoading}
        loader={loader}
        LoaderPopUp={LoaderPopUp}
      /> */}
    </>
  );
};

export default ParentContentComponent;
