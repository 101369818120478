import { Fragment } from "react";
import "./user_create_doubt.scss";

///////////////////Desktop
import LeftSideBarComponent from "../structure_components/desktop_screens/left_sideBar_component/left_sideBar_component";
import HeaderComponent from "../structure_components/desktop_screens/header_component/header_component";

///////Mobile////////////////////
import MobileLeftSideBarComponent from "../structure_components/mobile_screens/mobile_left_sideBar_component/mobile_left_sideBar_component";
import MobileHeaderComponent from "../structure_components/mobile_screens/mobile_header_component/mobile_header_component";
import MobileRightSideBarComponent from "../structure_components/mobile_screens/mobile_right_sideBar_component/mobile__right_sideBar_component";

import UserCreateDoubtContentComponent from "./user_create_doubt_content_component/user_create_doubt_content_component";
import LoaderPopUp from "../../../components/loader/loader";

//Redux
import { connect } from "react-redux";

const UserCreateDoubt = (props) => {
  let {
    loader,
    history,
    userData,
    userPreference,
    slctSubjectId,
    handleClose,
    getQuestionList,
  } = props;

  const isMob = () => {
    if (window.innerWidth <= 480) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <Fragment>
      <div className="user_create_doubt_wrapper">
        <div className="user_create_doubt_inner_wrapper">
          {/* {isMob() ? (
            <Fragment>
              <MobileLeftSideBarComponent />
              <MobileHeaderComponent
                userPreference={userPreference}
                userData={userData}
              />
              <MobileRightSideBarComponent history={history} />
              <UserCreateDoubtContentComponent
                history={history}
                userPreference={userPreference}
              />
            </Fragment>
          ) : (
            <Fragment>
             
            </Fragment>
          )} */}
          <div className="user_create_doubt_right_wrapper">
                <UserCreateDoubtContentComponent
                  handleClose={handleClose}
                  history={history}
                  userPreference={userPreference}
                  slctSubjectId={slctSubjectId}
                  getQuestionList={getQuestionList}
                />
              </div>
        </div>
      </div>
      {loader && <LoaderPopUp />}
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    loader: state.ui.loader,
    userData: state.auth.user,
    userPreference: state.userPreference,
  };
};

export default connect(mapStateToProps)(UserCreateDoubt);
