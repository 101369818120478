import React, { useState, useEffect } from "react";
import YourOrderComponent from "../yourOrderComponent/YourOrderComponent";
import "./OrderReview.scss";
import images from "../../../../../../../utilities/images/images";
import HandBooksCcAvenuePayment from "../../handBooks_cc_avenue_payment/HandBooksCcAvenuePayment";
import {
  url_167,
  url_168,
} from "../../../../../../../custom_utilities/api_services";
import { connect } from "react-redux";
import axios from "axios";
import saveTransactionAction from "../../../../../../../redux/handbook_payment_gateway/action";
import { checkXSSAttackThroughInput } from "../../../../../../../custom_utilities/custom_useful_functions";

const OrderReview = (props) => {
  let {
    selectedAddress,
    handleReview,
    checkoutInput,
    cartDetails,
    singleProduct,
    handleStep2,
    flag,
    tokenType,
    token,
    userId,
    saveTransactionAction,
  } = props;

  const [cpnInput, setCpnInput] = useState("");
  const [couponDetails, setCouponDetails] = useState({});

  const [state, setState] = useState({
    payment: [],
  });

  const packageDetails = {};

  useEffect(() => {
    getPayment();
  }, []);

  const applyCouponMethod = async () => {
    const headers = {
      Authorization: `${tokenType} ${token}`,
    };
    const data = {
      coupon_code: cpnInput,
      user_id: userId,
      amount: flag ? cartDetails.total_price : singleProduct.discounted_price,
    };
    const response = await axios.post(url_167, data, { headers });

    if (response.data.status === 200) {
      setCouponDetails(response.data.data);
    } else {
      alert("Invalid Coupon");
    }
  };

  const getPayment = async () => {
    // startLoading();
    const headers = {
      Authorization: `${tokenType} ${token}`,
    };
    let data = {
      address:
        Object.keys(selectedAddress).length > 0
          ? selectedAddress
          : checkoutInput,
    };
    try {
      const response = await axios.post(url_168, data, { headers });

      if (response.data.status === 200) {
        // finishLoading();
        setState((prevState) => ({
          ...prevState,
          payment: response.data,
        }));
        saveTransactionAction(response.data);
      }
    } catch (error) {
      // finishLoading();
      console.log(error);
    }
  };

  const handleInputchange = async (value) => {
    const response = await checkXSSAttackThroughInput(value);

    if (!response) {
      return;
    } else {
      setCpnInput(value);
    }
  };

  return (
    <div>
      <div className="backBtn_content">
        <div className="backBtn" onClick={handleStep2}>
          {" "}
          <img src={images.m2_backBtn} alt="backBtn" />
        </div>
        <div className="backBtn_text">Back to Cart</div>
      </div>
      <section className="orderReview_container">
        <div className="left">
          <section className="deliveryAddress">
            <div className="section_heading">Delivery Address</div>
            {Object.keys(selectedAddress).length || undefined ? (
              <div class="card">
                <div class="card-body">
                  <div className="top">
                    <div className="name_num">
                      <span className="name">{selectedAddress.fullname}</span> |
                      <span className="num">{selectedAddress.mobile}</span>
                    </div>
                    <div className="changeAddressBtn">
                      <button onClick={handleReview}>Change</button>
                    </div>
                  </div>
                  <div className="bottom">
                    <div className="savedAddress">
                      <span>Door No</span>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div class="card">
                <div class="card-body">
                  <div className="top">
                    <div className="name_num">
                      <span className="name">{checkoutInput.fullname}..</span> |
                      <span className="num">{checkoutInput.phone}</span>
                    </div>

                    <div className="changeAddressBtn">
                      <button onClick={handleReview}>Change</button>
                    </div>
                  </div>
                  <div className="bottom">
                    <div className="savedAddress">
                      <span>Door No</span>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </section>
        </div>

        <div className="right">
          <div className="coupon_code">
            <div className="coupon_input">
              <input
                type="text"
                placeholder="Apply Coupon code"
                value={cpnInput}
                onChange={(e) => handleInputchange(e.target.value)}
              />
            </div>
            <button onClick={() => applyCouponMethod(cpnInput)}>
              Apply Code
            </button>
          </div>
          {flag ? (
            <YourOrderComponent
              cartDetails={cartDetails}
              couponDetails={couponDetails}
            />
          ) : (
            <YourOrderComponent
              singleProduct={singleProduct}
              couponDetails={couponDetails}
            />
          )}

          {flag ? (
            <HandBooksCcAvenuePayment
              packageDetails={packageDetails}
              amount={couponDetails.updated_amount || cartDetails.total_price}
            />
          ) : (
            <HandBooksCcAvenuePayment
              packageDetails={packageDetails}
              amount={
                couponDetails.updated_amount || singleProduct.discounted_price
              }
            />
          )}
        </div>
      </section>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    loader: state.ui.loader,
    token: state.auth.authToken,
    tokenType: state.auth.tokenType,
    userId: state.auth.user.user_id,
  };
};

const mapDipatchToProps = (dispatch) => {
  return {
    saveTransactionAction: (payload) => {
      dispatch(saveTransactionAction.setHandBookTransactionDetails(payload));
    },
  };
};

export default connect(mapStateToProps, mapDipatchToProps)(OrderReview);
