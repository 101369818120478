import React, { Fragment, useState, useRef } from "react";
import QuestionDetailPanel from "./questionDetailPanel/QuestionDetailPanel";
import QuestionListPanel from "./questionListPanel/QuestionListPanel";
import { Modal } from "react-bootstrap";
import images from "../../../../../../../../../utilities/images/images";

import useStopwatch from "../../../../../../../../../hooks/useStopwatch";
import useMathjax from "../../../../../../../../../hooks/useMathjax";

const AdaptiveCpsTestQuestionsView = ({
  testQuestionsDetails,
  currentQuestion,
  handlePreviousQuestionBtn,
  handleNextQuestionBtn,
  handleQuestionJump,
  userId,
  checkQuestion,
  handleSingleSelectAnswer,
  handleMultiSelectAnswer,
  handleInputFieldChange,
  handleSaveQuestion,
  handleSubmitTest,
  startLoading,
  finishLoading,
}) => {
  const questionsCollectionRef = useRef(null);

  const {updateSolution, getClicked, solDisabled, showSolution, textInputSolution} = useStopwatch(currentQuestion, testQuestionsDetails[currentQuestion].integer_answer);

  const [lang, setLang] = useState(true);
  const [sol, setSol] = useState(true);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [disabled1, setDisabled] = useState(false);

  const [videoShow, setVideoShow] = useState(false);

  useMathjax(currentQuestion, lang, getClicked());

  const handleVideoShow = () => {
    setVideoShow(!videoShow);
    const targetDiv = document.querySelector(".sub_section_wrapper");
      setTimeout(() => {
        targetDiv.scrollTop = targetDiv.scrollHeight;
    }, 1);
  };

  const handleSol = () => {
    setSol(true);
    setDisabled(false);
  };

  const handleSolution = () => {
    setSol(false);
  };

  const disableSol = () => {
    setDisabled(true);
    // setSol(false);
  };

  const scrollUp = () => {
    const nextQuestionIndex = currentQuestion + 1;
    const questionElement =
      questionsCollectionRef.current.children[nextQuestionIndex];
    questionElement.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  const scrollUp2 = () => {
    const prevQuestionIndex = currentQuestion - 1;
    const questionElement =
      questionsCollectionRef.current.children[prevQuestionIndex];
    questionElement.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  const progressCount =
    (100 / testQuestionsDetails.length) * (currentQuestion + 1);

  return (
    <Fragment>
      <div className="main-wrapper">
        <div className="content-wrapper">
          {/* ... LeftSideContent ... */}
          <div className="left-panel">
            <div className="question-list-panel">
              <QuestionListPanel
                testQuestionsDetails={testQuestionsDetails}
                currentQuestion={currentQuestion}
                handleQuestionJump={handleQuestionJump}
                questionsCollectionRef={questionsCollectionRef}
                lang={lang}
                handleSaveQuestion={handleSaveQuestion}
                handleShow={handleShow}
                handleSolution={handleSolution}
                handleSol={handleSol}
                setVideoShow={setVideoShow}
              />
            </div>
          </div>

          {/* ... RightSideContent ... */}
          <div className="right-panel">
            <div className="question-detail-panel">
              <QuestionDetailPanel
                showSolution={showSolution}
                getClicked={getClicked}
                textInputSolution={textInputSolution}
                updateSolution={updateSolution}
                solDisabled={solDisabled}
                testQuestionsDetails={testQuestionsDetails}
                currentQuestion={currentQuestion}
                lang={lang}
                handlePreviousQuestionBtn={handlePreviousQuestionBtn}
                handleNextQuestionBtn={handleNextQuestionBtn}
                progressCount={progressCount}
                scrollUp={scrollUp}
                scrollUp2={scrollUp2}
                handleVideoShow={handleVideoShow}
                videoShow={videoShow}
                disableSol={disableSol}
                checkQuestion={checkQuestion}
                handleSingleSelectAnswer={handleSingleSelectAnswer}
                handleInputFieldChange={handleInputFieldChange}
                handleMultiSelectAnswer={handleMultiSelectAnswer}
                handleSaveQuestion={handleSaveQuestion}
                handleShow={handleShow}
                handleSol={handleSol}
                sol={sol}
                disabled1={disabled1}
                handleSolution={handleSolution}
                setSol={setSol}
                startLoading={startLoading}
                finishLoading={finishLoading}
                userId={userId}
                setVideoShow={setVideoShow}
              />
            </div>
          </div>

          <Modal
            show={show}
            onHide={handleClose}
            className="onSubmitModal"
            centered
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <div className="submit_Popup">
                <div className="exit_img">
                  <img src={images.m2_exit} alt="" />
                </div>
                <h5>Are you Sure?</h5>
                <p>Do you want to continue the exam or you want to Submit.</p>
                <div className="submitCancelBtn">
                  <button className="cancel" onClick={handleClose}>
                    Cancel
                  </button>
                  <button onClick={() => handleSubmitTest()}>Submit</button>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </Fragment>
  );
};

export default AdaptiveCpsTestQuestionsView;
