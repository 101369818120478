import React from "react";
import "./PyqCpsExamTest.scss";

import LoaderPopUp from "../../../../components/loader/loader";
import HeaderComponent from "../../structure_components/desktop_screens/header_component/header_component";
import PyqCpsExamTestComponent from "./pyqCpsExamTestComponent/PyqCpsExamTestComponent";
//Redux
import { connect } from "react-redux";

const PyqCpsExamTest = (props) => {
  let { history, loader, userData, userPreference } = props;
  return (
    <>
      <div className="pyqCpsExamTestWrapper">
        <div className="pyqCpsExamTestInrWrapper">
          <HeaderComponent history={history} />
          <PyqCpsExamTestComponent
            history={history}
            userData={userData}
            userPreference={userPreference}
          />
        </div>
      </div>
      {loader && <LoaderPopUp />}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loader: state.ui.loader,
    userData: state.auth.user,
    userPreference: state.userPreference,
  };
};
export default connect(mapStateToProps)(PyqCpsExamTest);
