import { Fragment, useEffect, useState } from "react";
import "./user_transactions_content_component.scss";

import axios from "axios";
import { isEmpty } from "../../../../custom_utilities/custom_useful_functions";
import { url_36 } from "../../../../custom_utilities/api_services";
import OnlineTransactions from "./online_transactions/OnlineTransactions";
import OfflineTransactions from "./offline_transactions/OfflineTransactions";

const UserTransactionsContentComponent = () => {
  const [state, setState] = useState({
    transactionsList: [],
  });

  useEffect(() => {
    getTransactionsList();
  }, []);

  const getTransactionsList = async () => {
    try {
      const response = await axios.get(url_36);
      console.log(response);
      if (response.data.status == 200) {
        setState((prevState) => ({
          ...prevState,
          transactionsList: response.data.data,
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Fragment>
      <div className="userTransactionsContentComponent_wrapper">
        <div className="container">
          <div className="userTransactionsContentComponent_inner_wrapper">
            <div className="transactionTabBox">
              <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link active"
                    id="online-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#transactionOnline"
                    type="button"
                    role="tab"
                    aria-controls="transactionOnline"
                    aria-selected="true"
                  >
                    Online
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link"
                    id="offline-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#transactionOffline"
                    type="button"
                    role="tab"
                    aria-controls="transactionOffline"
                    aria-selected="false"
                  >
                    Offline
                  </button>
                </li>
              </ul>
            </div>

            <div className="transactionTabContent">
              <div class="tab-content" id="myTabContent">
                <div
                  class="tab-pane fade show active"
                  id="transactionOnline"
                  role="tabpanel"
                  aria-labelledby="online-tab"
                >
                  <OnlineTransactions />
                </div>
                <div
                  class="tab-pane fade"
                  id="transactionOffline"
                  role="tabpanel"
                  aria-labelledby="offline-tab"
                >
                  <OfflineTransactions />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default UserTransactionsContentComponent;
