import * as actionTypes from "./action_type";

const setQuestionBankTestsData = (payload) => {
  return {
    type: actionTypes.SET_QUESTION_BANK_TESTS_DATA,
    payload,
  };
};

const setQuestionBankName = (payload) => {
  return {
    type: actionTypes.SET_QUESTION_BANK_NAME,
    payload,
  };
};

const setQuestionBankTestsResultData = (payload) => {
  return {
    type: actionTypes.SET_QUESTION_BANK_TESTS_RESULT_DATA,
    payload,
  };
};

const setQuestionBankId = (payload) => {
  return {
    type: actionTypes.SET_QUESTION_BANK_ID,
    payload,
  };
};

export default {
  setQuestionBankTestsData,
  setQuestionBankTestsResultData,
  setQuestionBankId,
  setQuestionBankName,
};
