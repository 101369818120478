import { Bar } from "react-chartjs-2";
// import { CategoryScale } from "chart.js";
// import Chart from "chart.js/auto";
// Chart.register(CategoryScale);

function SubjectWiseMarks(props) {
  let { dataPoints } = props;

  const labels = [""];

  // this is for adding margin to the label( header)
  const legendMargin = [
    {
      id: "legendMargin",
      beforeInit(chart) {
        const fitValue = chart.legend.fit;
        chart.legend.fit = function fit() {
          fitValue.bind(chart.legend)();
          return (this.height += 20);
        };
      },
    },
  ];

  const data = {
    labels,
    datasets: [
      {
        label: dataPoints[0]?.symbol,
        backgroundColor: " #668DE1",
        data: [Number(dataPoints[0]?.Total)],
        barThickness: 70,
      },
      {
        label: dataPoints[1]?.symbol,
        backgroundColor: "#31D680",
        data: [Number(dataPoints[1]?.Total)],
        data: [40],

        barThickness: 70,
      },
      {
        label: dataPoints[2]?.symbol,
        backgroundColor: "#FF933A",
        data: [Number(dataPoints[2]?.Total)],
        data: [30],

        barThickness: 70,
      },
    ],
  };

  return (
    <div className="sec_1_inner_wrapper" style={{ height: "350px" }}>
      <Bar
        options={{ maintainAspectRatio: false }}
        plugins={legendMargin}
        data={data}
      />
    </div>
  );
}

export default SubjectWiseMarks;
