import * as actionTypes from "./action_types";

const initialState = {
  examCrackerTestData: {},
  //testResultData: {},
};

const examCrackerTestsReducer = (
  state = initialState,
  { type, payload = null }
) => {
  switch (type) {
    case actionTypes.SET_EXAM_CRACKER_TESTS_DATA:
      return setExamCrackerTestsData(state, payload);
    // case actionTypes.SET_USER_TESTS_RESULT_DATA:
    //   return setUserTestsResultData(state, payload);
    default:
      return state;
  }
};

const setExamCrackerTestsData = (state, payload) => {
  state = { ...state, examCrackerTestData: payload };
  return state;
};

export default examCrackerTestsReducer;
