// /*
// If you are using server-side rendering, remember that this component should be loaded on client-side
// shaka player needs to be loaded on client-side, loading it on server-side may lead to error or undesired results
// */
// /*
// importing dependencies and CSS file(s) required for UI customization
// */

// import React from "react";
// import axios from "axios";
// import "shaka-player/dist/controls.css"; // Importing the CSS file for shaka-player UI controls
// import { Analytics } from "aws-amplify"; // Importing the Analytics module from aws-amplify

// const shaka = require("shaka-player/dist/shaka-player.ui.js"); // Importing the shaka-player library

// // Function to delay execution by a specified number of milliseconds
// function delay(ms) {
//   return new Promise((resolve) => setTimeout(resolve, ms));
// }

// class VideoPlayer extends React.PureComponent {
//   constructor(props) {
//     super(props);
//     this.videoComponent = React.createRef(); // Creating a reference to the video component
//     this.videoContainer = React.createRef(); // Creating a reference to the video container
//     this.onErrorEvent = this.onErrorEvent.bind(this);
//     this.onError = this.onError.bind(this);
//     this.lastPlayedMinute = 0; // Initializing the last played minute to 0
//     this.lastPlayedSecond = 0; // Initializing the last played second to 0
//   }

//   // Event handler for shaka-player error event
//   onErrorEvent(event) {
//     this.onError(event.detail);
//   }

//   // Error handler for displaying error details
//   onError(error) {
//     console.error("Error code", error.code, "object", error);
//   }

//   componentDidMount() {
//     // Delaying the execution of code inside the componentDidMount method
//     delay(1500).then(async () => {
//       const { id_video } = this.props;
//       const videoToken = id_video;
//       const bodyFormData = new FormData();

//       // Appending data to the FormData object
//       bodyFormData.append("flag", "1");
//       bodyFormData.append("name", videoToken);

//       const axiosConfig = {
//         headers: {
//           accessKey: "NUIzQVhZRDFGUkhFOU44UTcwTDI=",
//           secretKey: "NS80OFJ5RUl0dz1CbE5VNmczYVRvTVN4dnVLY1haWW43MkhiVnJlVw==",
//           "user-id": "TESTing_10000903_97",
//           "device-type": "1",
//           "device-id": "71d3548555586126ed7071102e663619",
//           version: "2",
//           "device-name": "ChromeCDM",
//           "account-id": "10000903",
//         },
//       };

//       try {
//         const result = await axios.post(
//           "https://api.videocrypt.com/getVideoDetailsDrm",
//           bodyFormData,
//           axiosConfig
//         );

//         // Extracting data from the response
//         const manifestUri = result.data.data.link.file_url;
//         const licenseServer = "https://license.videocrypt.com/validateLicense";
//         const token = result.data.data.link.token;
//         const video = this.videoComponent.current;
//         const videoContainer = this.videoContainer.current;
//         const player = new shaka.Player(video);

//         // Configuring DRM settings for shaka-player
//         player.configure({
//           drm: {
//             servers: {
//               "com.widevine.alpha":
//                 licenseServer + "?pallycon-customdata-v2=" + token,
//             },
//           },
//         });

//         const ui = new shaka.ui.Overlay(player, videoContainer, video);
//         const config = {};

//         // Configuring UI settings for shaka-player
//         ui.configure(config);
//         ui.getControls();

//         // Adding event listeners for error and timeupdate events
//         player.addEventListener("error", this.onErrorEvent);
//         video.addEventListener("timeupdate", this.handleTimeUpdate);

//         // Loading the video using the manifest URI
//         player
//           .load(manifestUri)
//           .then(() => {
//             console.log("The video has now been loaded!");
//           })
//           .catch((error) => {
//             this.onError(error);
//           });
//       } catch (error) {
//         this.onError(error);
//       }
//     });
//   }

//   componentWillUnmount() {
//     // Removing event listener for timeupdate when the component is unmounted
//     if (this.videoComponent.current) {
//       // Remove the event listener for the "timeupdate" event
//       this.videoComponent.current.removeEventListener(
//         "timeupdate",
//         this.handleTimeUpdate
//       );
//     }

//     this.recordLastStoppedTime(); // Recording the last stopped time of the video
//   }

//   // Event handler for updating the last played time of the video
//   handleTimeUpdate = () => {
//     // Get a reference to the video element
//     const video = this.videoComponent.current;

//     // Get the current time of the video in seconds
//     const currentTime = video.currentTime;

//     // Calculate the current minute and second values
//     const currentMinute = Math.floor(currentTime / 60);
//     const currentSecond = Math.floor(currentTime % 60);

//     // Check if the minute or second has changed since the last update
//     if (
//       currentMinute !== this.lastPlayedMinute ||
//       currentSecond !== this.lastPlayedSecond
//     ) {
//       // Update the last played minute and second with the new values
//       this.lastPlayedMinute = currentMinute;
//       this.lastPlayedSecond = currentSecond;
//     }
//   };

//   // Function for recording the last stopped time of the video
//   recordLastStoppedTime = () => {
//     // Accessing props within the function
//     const { videoDetailsForTime, topicData, subjectData } = this.props;

//     // Formatting the time as MM:SS
//     const time = `${this.lastPlayedMinute}:${this.lastPlayedSecond
//       .toString()
//       .padStart(2, "0")}`;

//     // Recording the videoStarted event using AWS Amplify Analytics
//     Analytics.record({
//       name: "web_videoStarted",
//       attributes: {
//         video_id: videoDetailsForTime?.video_id,
//         video_name: videoDetailsForTime?.video_title,
//         topic_id: topicData?.topic_id,
//         topic_name: topicData?.topicName,
//         subject_id: subjectData?.subjectId,
//         subject_name: subjectData?.subjectName,
//         watch_time: time,
//       },
//     })
//       .then(() => {
//         // Logging a success message when the event is recorded
//         console.log(`Event recorded successfully: ${time}`);
//       })
//       .catch((error) => {
//         // Logging an error message if there's an issue recording the event
//         console.error("Error recording event:", error);
//       });
//   };

//   render() {
//     return (
//       // Video container
//       <div className="video-container" ref={this.videoContainer}>
//         {/* Video element */}
//         <video
//           // CSS class for video element
//           className="shaka-video"
//           // Reference to the video component
//           ref={this.videoComponent}
//           // Poster image for the video
//           // poster="//shaka-player-demo.appspot.com/assets/poster.jpg"
//           // Automatically start playing the video
//           autoPlay
//         />
//       </div>
//     );
//   }
// }

// export default VideoPlayer;

import React from "react";
import VideoJsPlayer1 from "../../../../../custom_utilities/NewVideoPlayer1/videoshow";
// import VideoJsPlayer1 from "../../../../../custom_utilities/NewVideoPlayer/videoshow";
import VideoJsPlayer from "../../../../../custom_utilities/NewVideoPlayer/videoshow";

const VideoDrmPlayer = ({ id_video, data }) => {
  console.log("data in video drm player", data);
  return (
    <div>
      {data?.is_free_user ? (
        <VideoJsPlayer1 id_video={id_video} />
      ) : (
        <VideoJsPlayer id_video={id_video} />
      )}
    </div>
  );
};

export default VideoDrmPlayer;
