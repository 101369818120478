import React from "react";
import images from "../../../../../../../../../utilities/images/images";

const QuestionStatusLabel = ({
  question,
  index,
  currentQuestion,
  handleQuestionJump,
  handlePurticularQuestion2,
  lang,
  setToggleMenu
}) => {
  const { q_text, q_text_hindi } = question;
  const isTable = q_text.includes("<table");
  const isImage = q_text.includes("<img");
  let textToDisplay = "";

  if (lang) {
    textToDisplay = q_text;
  } else {
    textToDisplay = q_text_hindi || q_text; // Show Hindi if available, otherwise fallback to English
  }

  if (currentQuestion === index) {
    return (
      <label
        className="unanswered_lbl current_lbl"
        onClick={() => {
          handleQuestionJump(index);
          handlePurticularQuestion2(index);
          setToggleMenu(false);
        }}
      >
        <div className="questionBox">
          {`${index + 1}.`}
          <div className={`QBox${isImage ? " twoLines" : ""}`}>
            {isTable ? (
              <div
                className="tableContainer"
                dangerouslySetInnerHTML={{ __html: textToDisplay }}
              />
            ) : (
              <p dangerouslySetInnerHTML={{ __html: textToDisplay }} />
            )}
          </div>
        </div>
        <img className="arrow" src={images.right_Ar} alt="" />
      </label>
    );
  } else if (question.isAttempted) {
    return (
      <label
        className="unanswered_lbl answered_lbl"
        onClick={() => {
          handleQuestionJump(index);
          handlePurticularQuestion2(index);
          setToggleMenu(false);
        }}
      >
        <div className="questionBox">
          {`${index + 1}.`}
          <div className={`QBox${isImage ? " twoLines" : ""}`}>
            {isTable ? (
              <div
                className="tableContainer"
                dangerouslySetInnerHTML={{ __html: textToDisplay }}
              />
            ) : (
              <p dangerouslySetInnerHTML={{ __html: textToDisplay }} />
            )}
          </div>
        </div>
        <img className="arrow" src={images.right_Ar} alt="" />
      </label>
    );
  } else {
    return (
      <label
        className="unanswered_lbl"
        onClick={() => {
          handleQuestionJump(index);
          handlePurticularQuestion2(index);
          setToggleMenu(false);
        }}
      >
        <div className="questionBox">
          {`${index + 1}.`}
          <div className={`QBox${isImage ? " twoLines" : ""}`}>
            {isTable ? (
              <div
                className="tableContainer"
                dangerouslySetInnerHTML={{ __html: textToDisplay }}
              />
            ) : (
              <p dangerouslySetInnerHTML={{ __html: textToDisplay }} />
            )}
          </div>
        </div>
        <img className="arrow" src={images.right_Ar} alt="" />
      </label>
    );
  }
};

export default QuestionStatusLabel;
