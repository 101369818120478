import { Fragment } from "react";

///////Canvas Chart//////////////////
import { CanvasJSChart } from "canvasjs-react-charts";

const CpsSummary1 = (props) => {
  let { dataPoints } = props;
  const options = {
    animationEnabled: true,
    backgroundColor: "transparent",
    height: 200,
    data: [
      {
        type: "doughnut",
        showInLegend: false,
        //indexLabel: "{name}: {y}",
        yValueFormatString: "#,###'%'",
        dataPoints: [
          {
            name: "Correct",
            y: dataPoints?.correct_answer,
            color: "#31D680",
          },
          {
            name: "Wrong",
            y: dataPoints?.incorrect_answer,
            color: "#F93030",
          },
          {
            name: "Unattempted",
            y: dataPoints?.unattempted,
            color: "#668DE1",
          },
        ],
      },
    ],
  };
  return (
    <Fragment>
      <div className="canvasDoughnutChart_wrapper">
        <CanvasJSChart options={options} />
      </div>
    </Fragment>
  );
};

export default CpsSummary1;
