import { Fragment, useState } from "react";
import "./Question_paper_solution.scss";
import ProgressBar from "react-bootstrap/ProgressBar";
// import { MathJaxContext, MathJax } from "better-react-mathjax";
import useMathjax from "../../../../../../hooks/useMathjax";
import { Link } from "react-router-dom";
import images from "../.././../../../../utilities/images/images";
import ShakaSolutionViewVideoPlayer from "../../../../../../custom_utilities/shakaVideoPlayer/ShakaSolutionViewVideoPlayer";

const Question_paper_solution = (props) => {
  let {
    testQuestionsDetails,
    currentQuestion,
    currentSolutionDetails,
    handlePreviousQuestionBtn,
    handleNextQuestionBtn,
    handleQuestionJump,
    userData,
    testResultData,
  } = props;

  let { userId } = userData;
  const [lang, setLang] = useState(true);
  const [toggleMenu, setToggleMenu] = useState(false);

  useMathjax(currentSolutionDetails, lang);

  const renderOptions = (currentSolutionDetails) => {
    if (currentSolutionDetails) {
      let step,
        type = currentSolutionDetails?.answer_type.toLowerCase();

      if (type === "single correct") {
        step = 1;
      } else if (type === "integer correct") {
        step = 2;
      } else {
        step = 3;
      }

      switch (step) {
        case 1:
          return renderSingleSelect(currentSolutionDetails);
        case 2:
          return renderInputField(currentSolutionDetails);
        case 3:
          return renderMultiSelect(currentSolutionDetails);
        default:
          return null;
      }
    } else {
      return null;
    }
  };
  const [videoShow, setVideoShow] = useState(false);

  const classEditor = (s, u) => {
    if (s) {
      return "single_option_wrapper classCorrect";
    } else if (!s && u) {
      return "single_option_wrapper classWrong";
    } else {
      return "single_option_wrapper";
    }
  };

  const config = {
    //loader: { load: ["[tex]/html"] },
    loader: {
      load: [
        "input/asciimath",
        "output/chtml",
        "ui/menu",
        "[mml]/mml3",
        "[tex]/html",
      ],
    },
  };

  const renderCorrectWrongIcon = (s, u) => {
    let flag = 0;

    if (s && u) {
      flag = 1;
    } else if (!s && u) {
      flag = 2;
    }

    if (flag === 1) {
      return (
        <div className="option_right_wrong_icon">
          {" "}
          <img src={images.user_activity_59} />
        </div>
      );
    } else if (flag === 2) {
      return (
        <div className="option_right_wrong_icon">
          {" "}
          <img src={images.user_activity_60} />
        </div>
      );
    } else {
      return "";
    }
  };

  const classEditorForInput = (currentQuestionDetails) => {
    const { check_answer } = currentQuestionDetails;

    if (check_answer == 1) {
      return "input_inner_wrapper classCorrect";
    } else if (check_answer == 0) {
      return "input_inner_wrapper classWrong";
    } else {
      return "input_inner_wrapper classUnattempted";
    }
  };

  const renderCorrectWrongInputIcon = (currentQuestionDetails) => {
    const { check_answer } = currentQuestionDetails;

    if (check_answer == 1) {
      return <img src={images.user_activity_59} />;
    } else if (check_answer == 0) {
      return <img src={images.user_activity_60} />;
    } else {
      return "";
    }
  };

  const renderSingleSelect = (questionData) => {
    return (
      <Fragment>
        <div className="single_select_option_collection_wrapper">
          {questionData?.option.length
            ? questionData.option.map((element, index) => {
                return (
                  <div key={index} className="single_select">
                    <input
                      type="checkbox"
                      id={element.option_id}
                      name={`current_question_${currentQuestion}`}
                      disabled
                    />
                    <label
                      htmlFor={element.option_id}
                      className={classEditor(
                        element.is_correct,
                        element.is_user_correct_ans
                        // element.option_id
                      )}
                    >
                      <div className="options">
                        <div className="option_initial">
                          <p className="text_content_2">{`${renderOptionInitial(
                            index
                          )}.`}</p>
                        </div>
                        <div className="option_final">
                          <p
                            className="text_content_3"
                            dangerouslySetInnerHTML={renderOptionText(
                              element.option
                            )}
                          ></p>
                        </div>
                      </div>

                      {renderCorrectWrongIcon(
                        element.is_correct,
                        element.is_user_correct_ans
                      )}
                    </label>
                  </div>
                );
              })
            : null}
        </div>
      </Fragment>
    );
  };

  const renderMultiSelect = (questionData) => {
    return (
      <Fragment>
        <div className="multi_select_option_collection_wrapper">
          {questionData?.option.length
            ? questionData.option.map((element, index) => {
                return (
                  <div key={index} className="single_select">
                    <input
                      type="checkbox"
                      id={element.option_id}
                      name={`current_question_${currentQuestion}`}
                      disabled
                    />
                    <label
                      htmlFor={element.option_id}
                      className={classEditor(
                        element.is_correct,
                        element.is_user_correct_ans
                        // element.option_id
                      )}
                    >
                      <div className="options">
                        <div className="option_initial">
                          <p className="text_content_2">{`${renderOptionInitial(
                            index
                          )}.`}</p>
                        </div>
                        <div className="option_final">
                          <p
                            className="text_content_3"
                            dangerouslySetInnerHTML={renderOptionText(
                              element.option
                            )}
                          ></p>
                        </div>
                      </div>

                      {renderCorrectWrongIcon(
                        element.is_correct,
                        element.is_user_correct_ans
                      )}
                    </label>
                  </div>
                );
              })
            : null}
        </div>
      </Fragment>
    );
  };

  const renderInputField = () => {
    return (
      <Fragment>
        <div className="input_wrapper">
          <div
            className={classEditorForInput(
              testQuestionsDetails[currentQuestion]
            )}
          >
            <div className="input_content">
              <div className="input_box">
                <input
                  key={currentQuestion}
                  type="number"
                  value={testQuestionsDetails[currentQuestion]?.integer_answer}
                  disabled
                />
              </div>
              <div className="input_right_wrong_icon">
                {renderCorrectWrongInputIcon(
                  testQuestionsDetails[currentQuestion]
                )}
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  };

  const renderQuestionText = (currentSolutionDetails) => {
    return {
      __html: currentSolutionDetails?.q_text.replace(
        'src="ckfinder',
        'src="http://quizmaster.motion.ac.in/ckfinder'
      ),
    };
  };

  const renderParagraphQuestionText = (currentSolutionDetails) => {
    return {
      __html: currentSolutionDetails?.p_text,
    };
  };

  const renderColumMatchQuestion = (currentSolutionDetails) => {
    return {
      __html: currentSolutionDetails?.m_p_text,
    };
  };

  const handleOnLoad = () => {
    let frame = document.querySelector("#iframe");
    frame.contentWindow.postMessage(
      `{"activate": true,"userId":"${userId}"}`,
      "*"
    );

    // //////jump to particular time///////////////////////
    // frame.contentWindow.postMessage(JSON.stringify({ seekTo: 1 }), "*");

    ////////////////testing///////////////////////////////////
    let eventMethod = window.addEventListener
      ? "addEventListener"
      : "attachEvent";
    let eventer = window[eventMethod];
    let messageEvent = eventMethod === "attachEvent" ? "onmessage" : "message";

    eventer(messageEvent, function (e) {
      if (e.data && e.data.currentTime) {
        let videoTimmingDetails = { currentTime: e.data.currentTime };
        sessionStorage.setItem(
          "videoTimmingDetails",
          JSON.stringify(videoTimmingDetails)
        );
      }
    });
  };

  const handleVideoShow = () => {
    setVideoShow(!videoShow);
    const targetDiv = document.querySelector(".sub_section_wrapper");
      setTimeout(() => {
        targetDiv.scrollTop = targetDiv.scrollHeight;
    }, 1);
  };

  const renderVideo = (currentQuestionDetails) => {
    const videoId = currentQuestionDetails?.video_id_english;

    const selectedVideoId = videoId;

    const urlPattern = /^https:\/\/speed\.mogiio\.com\/embed3\/[0-9a-f]{24}$/;

    const isIframe = urlPattern.test(selectedVideoId);

    const buttonLabel = videoShow ? "Close Video Solution" : "View Video Solution";

    if (selectedVideoId && selectedVideoId.trim() !== "") {
      return (
        <div className="videoWrapper">
          <button className="view_videoSolution_btn" onClick={handleVideoShow}>
            {buttonLabel}
          </button>

          {videoShow && isIframe ? (
            <iframe
              id="iframe"
              src={selectedVideoId}
              onLoad={handleOnLoad}
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          ) : null}
          {videoShow && !isIframe ? (
            <ShakaSolutionViewVideoPlayer
              id_video={selectedVideoId}
              className="shakaPlayer"
            />
          ) : null}
        </div>
      );
    } else {
      return null;
    }
  };

  const renderSolution = (currentSolutionDetails) => {
    const { sol_text } = currentSolutionDetails;
    return {
      __html: sol_text?.replaceAll(
        "/ckfinder" || "ckfinder",
        "http://quizmaster.motion.ac.in/ckfinder"
      ),
    };
  };

  const renderOptionInitial = (index) => {
    let alphabetArr = [
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
      "M",
      "N",
      "O",
      "P",
      "Q",
      "R",
      "S",
      "T",
      "U",
      "V",
      "W",
      "X",
      "Y",
      "Z",
    ];

    return alphabetArr[index];
  };

  const renderOptionText = (optionText) => {
    return { __html: optionText };
  };

  const renderQuestionNumber = (index) => {
    if (index <= 8) {
      return `${index + 1}.`;
    } else {
      return `${index + 1}.`;
    }
  };

  const renderQuestionLabel = (question, index) => {
    const { q_text } = question;
    const isTable = q_text.includes("<table");
    const isImage = q_text.includes("<img");
    let textToDisplay = q_text.replaceAll(
      'src="ckfinder',
      'src="http://quizmaster.motion.ac.in/ckfinder'
    );

    if (currentQuestion === index) {
      if (textToDisplay && textToDisplay.length > 0) {
        return (
          <label
            className="unanswered_lbl current_lbl"
            onClick={() => {
              handleQuestionJump(index);
              setVideoShow(false);
            }}
          >
            <div className="questionBox">
              {`${index + 1}.`}
              <div className={`QBox${isImage ? " twoLines" : ""}`}>
                {isTable ? (
                  <div
                    className="tableContainer"
                    dangerouslySetInnerHTML={{ __html: textToDisplay }}
                  />
                ) : (
                  <p dangerouslySetInnerHTML={{ __html: textToDisplay }} />
                )}
              </div>
            </div>
            <img className="arrow" src={images.right_Ar} />
          </label>
        );
      } else {
        return (
          <label
            className="unanswered_lbl current_lbl"
            onClick={() => {
              handleQuestionJump(index);
              setVideoShow(false);
            }}
          >
            <div className="questionBox">
              {`${index + 1}.`}
              <div className={`QBox${isImage ? " twoLines" : ""}`}>
                {isTable ? (
                  <div
                    className="tableContainer"
                    dangerouslySetInnerHTML={{
                      __html: q_text.replaceAll(
                        'src="ckfinder',
                        'src="http://quizmaster.motion.ac.in/ckfinder'
                      ),
                    }}
                  />
                ) : (
                  <p
                    dangerouslySetInnerHTML={{
                      __html: q_text.replaceAll(
                        'src="ckfinder',
                        'src="http://quizmaster.motion.ac.in/ckfinder'
                      ),
                    }}
                  />
                )}
              </div>
            </div>
            <img className="arrow" src={images.right_Ar} />
          </label>
        );
      }
    } else {
      let labelClass = "";

      if (question.check_answer == 1) {
        labelClass = "correct_answered_lbl";
      } else if (question.check_answer == 0) {
        labelClass = "incorrect_answered_lbl";
      } else {
        labelClass = "";
      }

      return (
        <label
          className={`unanswered_lbl ${labelClass}`}
          onClick={() => {
            handleQuestionJump(index);

            setVideoShow(false);
          }}
        >
          <div className="questionBox">
            {`${index + 1}.`}
            <div className={`QBox${isImage ? " twoLines" : ""}`}>
              {isTable ? (
                <div
                  className="tableContainer"
                  dangerouslySetInnerHTML={{ __html: textToDisplay }}
                />
              ) : (
                <p dangerouslySetInnerHTML={{ __html: textToDisplay }} />
              )}
            </div>
          </div>
          <img className="arrow" src={images.right_Ar} />
        </label>
      );
    }
  };

  let progressCount =
    (100 / testQuestionsDetails.length) * (currentQuestion + 1);
  return (
    <Fragment>
      <div className="ques_paper_test_questions_solution_wrapper">
        <div className="test_questions_view_inner_wrapper">
          <div className="sec_2_wrapper">
            <div className="sec_2_inner_wrapper">
              <div className="section_content_wrapper">
                {toggleMenu ? (
                  <div
                    className="section_content_wrapper_backdrop"
                    onClick={() => {
                      setToggleMenu(false);
                    }}
                  ></div>
                ) : (
                  ""
                )}
                <div
                  className={`left_side_content_wrapper ${
                    toggleMenu ? "left_side_content_wrapperToggle" : ""
                  }`}
                >
                  <div className="sub_section_1_wrapper">
                    <button
                      type="button"
                      class="btn-close closebtn"
                      aria-label="Close"
                      onClick={() => {
                        setToggleMenu(false);
                      }}
                    ></button>
                    <div className="text_content_wrapper">
                      <p className="text_content">All Questions</p>
                    </div>
                    <div className="sub_section_2_wrapper">
                      <div className="question_marking_wrapper">
                        <div className="single_marking">
                          <label className="box_pic box_pick_background_1"></label>
                          <label className="question_marking_text">
                            Correct
                          </label>
                        </div>

                        <div className="single_marking">
                          <label className="box_pic box_pick_background_4"></label>
                          <label className="question_marking_text">
                            Incorrect
                          </label>
                        </div>

                        <div className="single_marking">
                          <label className="box_pic box_pick_background_3"></label>
                          <label className="question_marking_text">
                            Unattempt
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="questions_serial_number_collection">
                      {testQuestionsDetails?.length
                        ? testQuestionsDetails?.map((element, index) => {
                            return (
                              <div
                                key={index}
                                className="single_question_number_wrapper"
                              >
                                {renderQuestionLabel(element, index)}
                              </div>
                            );
                          })
                        : null}
                      
                    </div>
                  </div>
                </div>
                <div className="right_side_content_wrapper">
                  <div className="right_btn_wrapper">
                    <button
                      className="togglebtn"
                      onClick={() => {
                        setToggleMenu(true);
                      }}
                    >
                      Question
                    </button>
                  </div>
                  <div className="sub_section_wrapper">
                    <div className="sub_sub_section_wrapper_1">
                      <div className="ots_right_side_content_wrapper">
                        <div className="ots-_correct_incorrect_unattempt_percent correct">
                          <div className="percent correct_percent"></div>{" "}
                          Correct
                        </div>
                        <div className="ots-_correct_incorrect_unattempt_percent incorrect">
                          <div className="percent incorrect_percent "></div>{" "}
                          Incorrect
                        </div>
                        <div className="ots-_correct_incorrect_unattempt_percent unattempt">
                          <div className="percent unattempt_percent"></div>
                          Unattempt
                        </div>
                      </div>
                    </div>

                    <div className="testProgressIndicator">
                      <h4>Test Question progress Indicator</h4>
                      <div className="ProgressBar_Line">
                        <ProgressBar
                          now={progressCount}
                          className="progressBar"
                        />
                        <span style={{ left: progressCount - 1 + "%" }}>{`${
                          currentQuestion + 1
                        }`}</span>
                      </div>
                      <div className="questionPercentage">
                        <span>1</span>
                        <span>{testQuestionsDetails?.length}</span>
                      </div>
                    </div>
                    <div className="sub_sub_section_wrapper_2">
                      <h2>{testResultData?.paper_name}</h2>
                      <div className="text_content_wrapper">
                        <div className="typeLanguageWrapper">
                          <span>
                            Question type: {currentSolutionDetails?.type}
                          </span>
                        </div>

                        <div className="questionBox">
                          <span className="text_content">
                            {`${Number(currentQuestion) + 1}.`}
                          </span>
                              {currentSolutionDetails !== null &&
                              currentSolutionDetails !== undefined &&
                              currentSolutionDetails !== "" ? (
                                <div>
                                  <p
                                    className="text_content"
                                    dangerouslySetInnerHTML={renderColumMatchQuestion(
                                      currentSolutionDetails
                                    )}
                                  ></p>

                                  <p
                                    className="text_content"
                                    dangerouslySetInnerHTML={renderParagraphQuestionText(
                                      currentSolutionDetails
                                    )}
                                  ></p>
                                  <div>
                                    <p
                                      className="text_content"
                                      dangerouslySetInnerHTML={renderQuestionText(
                                        testQuestionsDetails[currentQuestion]
                                      )}
                                    ></p>
                                  </div>
                                </div>
                              ) : (
                                ""
                              )}
                        </div>
                      </div>
                    </div>

                    <div className="sub_sub_section_wrapper_3">
                          {currentSolutionDetails !== null &&
                          currentSolutionDetails.option !== undefined
                            ? renderOptions(currentSolutionDetails)
                            : ""}
                    </div>

                    <div className="sub_sub_section_wrapper_5">
                      <div className="left_side_wrapper">
                        {currentQuestion > 0 && (
                          <div className="btn_wrapper">
                            <button
                              onClick={() => {
                                handlePreviousQuestionBtn(currentQuestion - 1);
                                setVideoShow(false);
                              }}
                            >
                              Previous
                            </button>
                          </div>
                        )}
                      </div>
                      <div className="right_side_wrapper">
                        {testQuestionsDetails.length === currentQuestion + 1 ? (
                          <div className="btn_wrapper">
                            <Link
                              className="backBtn"
                              to={{
                                pathname: "/ots_test_result",
                                // state: { tab: "exam" },
                              }}
                            >
                              Exit
                            </Link>
                          </div>
                        ) : (
                          <div className="btn_wrapper">
                            <button
                              onClick={() => {
                                handleNextQuestionBtn(currentQuestion + 1);
                                setVideoShow(false);
                              }}
                            >
                              Next
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                      {currentSolutionDetails !== null &&
                    currentSolutionDetails !== undefined &&
                    currentSolutionDetails !== "" ? (
                      <div className="QuestionSolution">
                        <h5>Solution:</h5>

                        <div>
                          <p
                            dangerouslySetInnerHTML={renderSolution(
                              currentSolutionDetails
                            )}
                          ></p>

                          {currentSolutionDetails?.video_id_english?.length
                            ? renderVideo(currentSolutionDetails)
                            : ""}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Question_paper_solution;
