import { Fragment } from "react";
import "./header_component_two.scss";

import HeaderLeft from "./header_left/header_left";
import HeaderRight from "./header_right/header_right";

/////React Toastify
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//Redux
import { connect } from "react-redux";

const HeaderComponentTwo = (props) => {
  let { userData, history } = props;
  return (
    <Fragment>
      <div className="header_component_two_wrapper">
        <div className="header_component_two_inner_wrapper">
          <div className="header_left_wrapper">
            <HeaderLeft />
          </div>
          <div className="header_right_wrapper">
            <HeaderRight userData={userData} history={history} />
          </div>
        </div>
      </div>
      <ToastContainer />
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.auth.user,
  };
};

export default connect(mapStateToProps)(HeaderComponentTwo);
