import React, { useEffect, useState } from "react";
import "./PriceAndPurchase.scss";
import images from "../../../../../../../utilities/images/images";
import TermsAndConditionModal from "./TermsAndConditionModal";
import axios from "axios";
import {
  url_180,
  url_181,
  url_177,
} from "../../../../../../../custom_utilities/api_services";
import CustomSpinner from "../../../../../../../custom_utilities/spinner/Spinner";
import CcAvenuePayment from "../../../../../../../custom_utilities/ccavence_payment/CcAvenuePayment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toastifyMessage } from "../../../../../../../custom_utilities/toastify_config";
import { connect } from "react-redux";

const PriceAndPurchase = ({
  package_overview,
  setRefferalCodeAction,
  tokenType,
  token,
}) => {
  const { amount, discount, final_amount, expiry_date } = package_overview;
  const [loading, setLoading] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  const [state, setState] = useState({
    termsAndCondition: {
      termsAndCondition: {},
      termsAndConditionModal: false,
    },
    isValidRefferal: false,
    refferalCode: "",
  });

  useEffect(() => {
    // Scroll to the top of the page after a short delay
    const scrollToTop = () => {
      setTimeout(() => {
        document.documentElement.scrollTop = 0;
        document.body.scrollTop = 0;
      }, 0);
    };

    // Scroll to the top when the component mounts
    scrollToTop();

    // Restore original scroll behavior when the component unmounts
    return () => {
      document.documentElement.scrollTop = 0;
      document.body.scrollTop = 0;
    };
  }, []);

  const handleOpenTermsAndCondition = () => {
    setState((prevState) => ({
      ...prevState,
      termsAndCondition: {
        ...prevState.termsAndCondition,
        termsAndConditionModal: true,
      },
    }));

    getTermsAndConditions();
  };

  const handleCloseTermsAndConditions = () => {
    setState((prevState) => ({
      ...prevState,
      termsAndCondition: {
        ...prevState.termsAndCondition,
        termsAndConditionModal: false,
      },
    }));
  };

  const applyReferalCode = async () => {
    setLoading(true);

    const headers = {
      Authorization: `${tokenType} ${token}`,
    };
    const data = {
      referral_code: state.refferalCode,
      package_id: package_overview?.package_id,
    };

    try {
      const response = await axios.post(url_180, data, { headers });

      if (parseInt(response.data.status) === 200) {
        setLoading(false);
        setState((prevState) => ({
          ...prevState,
          isValidRefferal: true,
        }));
        setRefferalCodeAction(state.refferalCode);
        toastifyMessage(response.data);
      } else {
        setLoading(false);
        toastifyMessage(response.data);
      }
    } catch (e) {
      setLoading(false);
      console.log(e.message);
    }
  };

  const handleInputChange = (e) => {
    setState((prevState) => ({
      ...prevState,
      refferalCode: e.target.value,
    }));
  };

  const getTermsAndConditions = async () => {
    const data = {
      name: "AMRIT_PACKAGE",
      group: "TERMS_AND_CONDITIONS",
    };

    try {
      const response = await axios.post(url_181, data);

      if (parseInt(parseInt(response.data.status)) === 200) {
        setState((prevState) => ({
          ...prevState,
          termsAndCondition: {
            ...prevState.termsAndCondition,
            termsAndCondition: response.data.data[0],
          },
        }));
      } else {
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  return (
    <>
      <div className="package-price-details">
        <div className="referal flex-vertical-center">
          <img src={images.store_link} alt="referal-link-img" />
          <div className="referal-descript">
            <p className="hve-referal-code">Have a Referral Code?</p>
            <p className="about-coins">
              You can Earn{" "}
              <span className="coins-count"> 500 Motion Coins </span> for this
              Purchase
            </p>
          </div>
        </div>

        <div className="referal-input flex">
          <input
            type="text"
            placeholder="Enter Referral code"
            onChange={handleInputChange}
            value={state.refferalCode}
            id="refer-code"
            name="refer-code"
            maxlength="6"
          />

          <div className="referal-status">
            {loading ? (
              <CustomSpinner
                variant={"custom"}
                className={"referal-custom-spinner"}
              />
            ) : state.isValidRefferal ? (
              <img src={images.store_tick} alt="referral-applied-img" />
            ) : state.refferalCode.length > 0 ? (
              <span onClick={applyReferalCode}>APPLY</span>
            ) : (
              <span
                onClick={() =>
                  toastifyMessage({
                    status: 201,
                    message: "Required Refferal Code",
                  })
                }
              >
                APPLY
              </span>
            )}
          </div>
        </div>

        <div className="price-details">
          <p className="price-details-text">Price details</p>
          <div className="package-price flex">
            <p>Course Price</p>₹ {amount}
          </div>
          <div className="package-discount flex">
            <p>Discount</p>₹ {discount}
          </div>
          <div className="package-final-price flex">
            <p>Final Price</p>₹ {final_amount}
          </div>
        </div>
        <div className="package-terms-conditions flex-vertical-center">
          <img
            src={images.terms_and_condition}
            alt="terms and condition icon"
          />
          View{" "}
          <span
            className="terms-condition"
            onClick={() => handleOpenTermsAndCondition()}
          >
            Terms & Condition
          </span>
        </div>

        <div className="package-valid-date">
          Valid up to <span className="pkg-date">{expiry_date}</span>
        </div>

        <CcAvenuePayment
          packageDetails={package_overview}
          amount={final_amount}
          buttonText={`BUY NOW @ ₹ ${final_amount}`}
          buttonClass={"store_pay_now"}
        />
      </div>

      {state.termsAndCondition.termsAndConditionModal && (
        <TermsAndConditionModal
          termsAndConditionModal={
            state.termsAndCondition.termsAndConditionModal
          }
          handleCloseTermsAndConditions={handleCloseTermsAndConditions}
          termsAndCondition={state.termsAndCondition.termsAndCondition}
        />
      )}

      <ToastContainer autoClose={1000} />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.authToken,
    tokenType: state.auth.authType,
  };
};

export default connect(mapStateToProps, null)(PriceAndPurchase);
