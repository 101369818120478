import { React, useState } from "react";
import "./PyqSummary.scss";
import { useLocation } from "react-router-dom";
import {
 url_143
} from "../../../../../../../../custom_utilities/api_services";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import images from "../../../../../../../../utilities/images/images";
import { isEmpty } from "../../../../../../../../custom_utilities/custom_useful_functions";
import HeaderComponent from "../../../../../../structure_components/desktop_screens/header_component/header_component";
import Summary1 from "../../../../../../../../components/canvas_charts/canvas_doughnut_chart/CpsSummary1";
import Summary2 from "../../../../../../../../components/canvas_charts/canvas_doughnut_chart/CpsSummary2";
import axios from "axios";
import { useEffect } from "react";
import LoaderPopUp from "../../../../../../../../components/loader/loader";
import { useHistory,Link } from "react-router-dom";
//Redux
import { connect } from "react-redux";
import uiActions from "../../../../../../../../redux/ui/action";
import Mobile__right_sideBar_component from "../../../../../../structure_components/mobile_screens/mobile_right_sideBar_component/mobile__right_sideBar_component";
import Mobile_header_component from "../../../../../../structure_components/mobile_screens/mobile_header_component/mobile_header_component";
import MobileLeftSideBarComponent from "../../../../../../structure_components/mobile_screens/mobile_left_sideBar_component/mobile_left_sideBar_component";

const PyqSummary = (props) => {
  let { startLoading, finishLoading, loader, testResultData, userData, userPreference,history } = props;
  let paperId = testResultData;
  const [state, setState] = useState();
  let historys = useHistory();
  const backBtn = () => {
    historys.push("/user_dashboard");
  };
  const location = useLocation();
  useEffect(() => {
    getPyqSummary();
  }, []);
  
    const getPyqSummary = async () =>  {
    let data = {
      paper_id:testResultData.paper_id,
    }
    startLoading();
    try {
      const response = await axios.post(url_143, data);
      if (response.data.status === 200) {
        setState(response.data);
        console.log("resultdata",response.data.data);
        finishLoading();
      }
    } catch (error) {
      console.log(error);
      finishLoading();
    } finally{
      
      finishLoading();
    }
  }
  const redirect5 = () => {
    historys.push({
      pathname: "/pyq-test-paper-solution",
      state: {
        paperId,
      },
    });
  };
  let data1 = state?.data;
  let data2 = state?.marks_analysis;
  let data3 = state?.score_analysis;

  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const setWidth = () => {
    setWindowWidth(window.innerWidth)
  }
  useEffect(() => {
    window.addEventListener( 'resize',setWidth)

    return (() => {
      window.removeEventListener('resize',setWidth)
    })
  },[windowWidth])
  return (
    <>
      <div className="behaviourWrapper">
      {windowWidth <= 480 ? (
            /////for Mobile Screens////////////////////
            <>
              <MobileLeftSideBarComponent />
              <Mobile_header_component
                userPreference={userPreference}
                userData={userData}
              />
              <Mobile__right_sideBar_component history={history} />
            </>
          ) : (
                <HeaderComponent history={history} />
          )}
        <div className="behaviourInrWrapper">
          <div className="container">
            <div className="sbjtWiseMarksInr">
              {/* <div className="backBtn" onClick={backBtn}>
                <img src={images.user_activity_1} />
              </div> */}
              <Link className="backBtn" to={{
                  pathname: "/pyq",
                  state: { tab: "pyq" }
              }}>
                  <img src={images.m2_backBtn} alt="backBtn" />
                </Link>
              <div className="difficultyLevel">
                <h3>Summary</h3>
                <div className="difficultyLevelTab">
                  {loader && <LoaderPopUp />}
                  <div className="difficultyLevelcontent">
                    <div className="scorePercentageBox margin0">
                      <div className="scoreChart hardResult">
                        <Summary1 dataPoints={data1} />
                      </div>
                      <div className="scoreDetail">
                        <p>Total Number of Questions { data1?.total_questions}</p>
                        <div className="percentageDiv correct">
                          <span></span>{ data1?.correct_answer} Correct
                        </div>
                        <div className="percentageDiv wrong">
                          <span></span>
                          { data1?.incorrect_answer} Wrong
                        </div>
                        <div className="percentageDiv unattempt">
                          <span></span>
                          { data1?.unattempted} unattempted
                        </div>
                      </div>
                    </div>
                    {/* <div className="scorePercentageBox">
                      <div className="scoreChart hardResult">
                        <Summary2 dataPoints={data2}/>
                      </div>
                      <div className="scoreDetail">
                        <p>Total Marks {data2?.total_marks}</p>
                        <div className="percentageDiv correct">
                          <span style={{ background: "#FF933A" }}></span>
                          <p className="maxSpan">{data2?.user_marks}</p> Your Score
                        </div>
                        <div className="percentageDiv wrong">
                          <span style={{background:"#4DD7D8"}}></span>
                          <p className="maxSpan">{data2?.topper_marks}</p> Toppers Score
                        </div>
                        <div className="percentageDiv unattempt">
                          <span style={{background:"#668DE1"}}></span>
                          <p className="maxSpan">{data2?.average_marks}</p> Average Score
                        </div>
                      </div>
                    </div> */}
                    <div className="scorePercentageBox">
                      <div className="scoreChart mediumResult">
                        <CircularProgressbar
                          value={data1?.score_percentage}
                          strokeWidth={10}
                        />
                      </div>
                      <div className="scoreDetail">
                        <p>Score Percentage</p>
                        <div className="percentageDiv">
                          <span></span>100%
                        </div>
                        <div className="percentageDiv medium">
                          <span style={{background:"#6D48EF"}}></span>
                          {/* {`attempted ${
                            state.behaviourTime?.[`Total Time`]
                              ? state.behaviourTime?.[`Total Time`]
                              : 0
                          }%`} */}
                          You got {data1?.score_percentage}%
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="marksSummary">
                    <ul>
                      <li><span>Total Questions</span><span>{data1?.total_questions }</span></li>
                      {/* <li>
                        <span>You Score</span><span>{data1?.user_marks}</span>
                      </li> */}
                      <li>
                        <span>Correct answer</span><span>{data1?.correct_answer}</span></li>
                        <li>
                        <span>Incorrect answer</span><span>{data1?.incorrect_answer}</span></li>
                      <li><span>Score Percentage</span><span>{ data1?.score_percentage}%</span></li>
                    </ul>
                  </div>
                  <div className="viewSolution" onClick={redirect5}><button>View Solution</button></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    loader: state.ui.loader,
     testResultData : state.pyqTestsData.pyqResultData,
     userData: state.auth.user,
     userPreference: state.userPreference,

  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => {
      dispatch(uiActions.startLoading());
    },
    finishLoading: () => {
      dispatch(uiActions.finishLoading());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PyqSummary);
