import * as actionTypes from "./action_type";

const initialState = {
  pyqTestData: {},
  pyqResultData: {},
  jeeMainsOrAdvance: "jee-mains",
};

const pyqTestsReducer = (state = initialState, { type, payload = null }) => {
  switch (type) {
    case actionTypes.SET_PYQ_TESTS_DATA:
      return setPyqTestsData(state, payload);

    case actionTypes.SET_PYQ_TESTS_RESULT_DATA:
      return setPyqTestsResultData(state, payload);

    case actionTypes.SET_PYQ_JEE_MAINS_OR_ADVANCED:
      return setPyqJeeMainsOrAdvance(state, payload);

    default:
      return state;
  }
};

const setPyqTestsData = (state, payload) => {
  state = { ...state, pyqTestData: payload };
  return state;
};

const setPyqTestsResultData = (state, payload) => {
  state = { ...state, pyqResultData: payload };
  return state;
};

const setPyqJeeMainsOrAdvance = (state, payload) => {
  state = { ...state, jeeMainsOrAdvance: payload };
  return state;
};

export default pyqTestsReducer;
