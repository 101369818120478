import { Fragment, useState, useEffect } from "react";
import "./ParentHeaderRight.scss";

import images from "../../../utilities/images/images";

import { isEmpty } from "../../../custom_utilities/custom_useful_functions";

//Redux
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { logoutParentUser } from "../../redux/authSlice/AuthSlice";
import { setLoginCandidate } from "../../../redux/login/LoginSlice";

const ParentHeaderRight = (props) => {
  let {
    userData,
    logoutUser,
    studentProfileDetails,
    parentProfileDtails,
    parentNum,
    setLoginCandidate,
  } = props;
  const history = useHistory();

  const handleUserLogout = () => {
    const confirmed = window.confirm("Are you sure you want to logout?");
    if (confirmed) {
      logoutUser();
      setLoginCandidate("student");
      history.push("/");
    }
  };

  return (
    <Fragment>
      <div className="headerRight_wrapper">
        <div className="headerRight_inner_wrapper">
          <div className="logout_wrapper">
            <div className="image_wrapper" onClick={handleUserLogout}>
              <img src={images.logout} alt="Logout" />
            </div>
          </div>

          <div className="userName_and_image_wrpr">
            <div className="userName_and_image_inner_wrapper">
              <div className="content_wrapper">
                <div className="dropdown image_container">
                  <button
                    className="btn dropdown-toggle custom_dropdown_toggle"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <span className="image_wrapper">
                      <span className="image_inner_wrapper">
                        {!isEmpty(studentProfileDetails) ? (
                          studentProfileDetails.image.length ? (
                            <img src={studentProfileDetails.image} alt="" />
                          ) : (
                            <img src={images.dummy_user_img} alt="" />
                          )
                        ) : (
                          <img src={images.dummy_user_img} alt="" />
                        )}
                      </span>
                    </span>
                  </button>

                  <div
                    className="dropdown-menu custom_dropdown_menu"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <div className="profile_option_wrapper">
                      <div className="profile_option_inner_wrapper">
                        <div className="student details_wrapper">
                          <div className="wrapper_heading">
                            <h1>Student Details</h1>
                          </div>

                          <div className="row first-row">
                            <div className="col-sm-6 text-left">Name</div>
                            <div className="col-sm-6 text-right">
                              {studentProfileDetails.name}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-sm-6 text-left">Roll No.</div>
                            <div className="col-sm-6 text-right">
                              {" "}
                              {studentProfileDetails.roll_no}
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-sm-6 text-left">Mbl. No.</div>
                            <div className="col-sm-6 text-right">
                              {studentProfileDetails.mobile}
                            </div>
                          </div>
                        </div>

                        <div className="details_wrapper">
                          <div className="wrapper_heading">
                            <h1>Parent Details</h1>
                          </div>

                          <div className="row first-row">
                            <div className="col-sm-6 text-left">Name</div>
                            <div className="col-sm-6 text-right">
                              {parentProfileDtails.f_name}
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-sm-6 text-left">Mbl. No.</div>
                            <div className="col-sm-6 text-right">
                              {parentNum}
                            </div>
                          </div>
                        </div>

                        <div className="details_wrapper">
                          <div className="wrapper_heading">
                            <h1>Course Details</h1>
                          </div>

                          <div className="row first-row">
                            <div className="col-sm-6 text-left">Course</div>
                            <div className="col-sm-6 text-right">
                              {studentProfileDetails.course}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-sm-6 text-left">Class</div>
                            <div className="col-sm-6 text-right">
                              {" "}
                              {studentProfileDetails.classes}
                            </div>
                          </div>

                          <div className="row last-child">
                            <div className="col-sm-6 text-left">
                              Study Centre
                            </div>
                            <div className="col-sm-6 text-right">
                              {" "}
                              {studentProfileDetails.center}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    studentProfileDetails:
      state.parentrootReducer.parentAuthReducer.studentProfileDetails,
    parentProfileDtails: state.parentrootReducer.parentAuthReducer.user,
    parentNum: state.parentrootReducer.parentAuthReducer.mobile_number,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    logoutUser: (payload) => {
      dispatch(logoutParentUser(payload));
    },

    setLoginCandidate: (payload) => {
      dispatch(setLoginCandidate(payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ParentHeaderRight);
