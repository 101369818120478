import * as actionTypes from "./action_type";

const setOrderId = (payload) => {
  return {
    type: actionTypes.HANDBOOK_SET_ORDER_ID,
    payload,
  };
};

const setHandBookTransactionDetails = (payload) => {
  return {
    type: actionTypes.HANDBOOKS_SAVE_TRANSACTION_DETAILS,
    payload,
  };
};

export default {
  setOrderId,
  setHandBookTransactionDetails,
};
