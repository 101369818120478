import React, { useState } from "react";
import images from "../../../../../utilities/images/images";

const SecondScreen = ({
  selectedOption,
  selectedParentId,
  messages,
  setMessages,
  childListings,
  handleOptionSelect,
  handleStepOne,
  showConfirmationMessage,
  inputMessage,
  setInputMessage,
  handleSendMessage,
  selectedChildId,
  showCustomMessageInput,
  showkidly,
  setCurrentScreen,
}) => {
  const [textareaRows, setTextareaRows] = useState(1);

  const handleChange = (e) => {
    const textareaLineHeight = 21; //
    const previousRows = e.target.rows;
    e.target.rows = 1;
    const currentRows = Math.floor(e.target.scrollHeight / textareaLineHeight);

    if (currentRows === previousRows) {
      e.target.rows = currentRows;
    }

    setInputMessage(e.target.value);
    setTextareaRows(currentRows);
  };

  const renderButtons = () => {
    return (
      <>
        <div className="buttons">
          {childListings.slice(0, childListings.length - 1).map((child) => (
            <button
              key={child.childid}
              onClick={() =>
                handleOptionSelect(
                  child.name,
                  selectedParentId,
                  child.childid,
                  child.name
                )
              }
            >
              {child.name}
            </button>
          ))}
        </div>

        <div className="other-button">
          {" "}
          {childListings.splice(childListings.length - 1, 1).map((child) => (
            <div
              className="other-btn"
              key={child.childid}
              onClick={() =>
                handleOptionSelect(
                  child.name,
                  selectedParentId,
                  child.childid,
                  child.name
                )
              }
            >
              {child.name.toLowerCase().trim() == "otherissue"
                ? "Other issue"
                : "Other issue"}{" "}
            </div>
          ))}
        </div>
      </>
    );
  };

  return (
    <div className="assistant">
      <div className="message">
        <div className="content">
          <p className="para1">
            Hi! Thank you for contacting Motion, I am Motion assistant how may i
            help you
          </p>

          <p className="para2">
            Please choose the options below related to your issue
          </p>
        </div>
        <img src={images.moitonchatLogo} alt="moitonchatLogo" />
      </div>

      {selectedOption && selectedParentId && !selectedChildId && (
        <div>
          <div className="selectedOption">
            <div>
              {messages?.map((message, index) => (
                <div key={index} className="option">
                  {message.text}
                </div>
              ))}
            </div>
          </div>

          {renderButtons()}

          <div className="backbtn">
            <button
              onClick={() => {
                setCurrentScreen("initial");
                setMessages([]);
              }}
            >
              Back to previous menu
            </button>
          </div>
        </div>
      )}

      {selectedChildId && (
        <>
          <div className="selectedOption2">
            {messages?.map((message, index) => (
              <div key={index} className="option2">
                {message.text}
              </div>
            ))}
          </div>

          {/* {showkidly && (
            <div className="kidlyState">Kindly state your problem clearly</div>
          )} */}

          {showConfirmationMessage ? (
            <>
              <div className="confirmationMessage">
                Your problem is registered, our team will solve & get back to
                you within 24 hours.
              </div>
              <div className="backbtn">
                <button onClick={handleStepOne}>Back to main menu</button>
              </div>
            </>
          ) : (
            <div className="backbtn">
              <button
                onClick={() => {
                  setMessages([]);
                  setCurrentScreen("initial");
                }}
              >
                Back to previous menu
              </button>
            </div>
          )}

          {!showCustomMessageInput && (
            <div class="custom-message-box">
              <textarea
                rows={textareaRows}
                placeholder="Type message..."
                value={inputMessage}
                onChange={handleChange}
              ></textarea>

              <img
                src={images.chatSendIcon}
                onClick={handleSendMessage}
                alt=""
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default SecondScreen;
