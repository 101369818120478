import { Fragment } from "react";
import "./chat_header.scss";

const ChatHeader = () => {
  return (
    <Fragment>
      <div className="chat_header_wrapper">
        <div className="chat_header_inner_wrapper">
          <div className="sec_1_wrapper">
            <div className="text_content_wrapper">
              <p className="text_content">Live Chat</p>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ChatHeader;
