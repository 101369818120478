import { Fragment, useState, useEffect } from "react";
import "./header_left.scss";
import images from "../../../../../../utilities/images/images";
import {
  isEmpty,
  sliceString,
} from "../../../../../../custom_utilities/custom_useful_functions";
import Bootstrap from "bootstrap/dist/js/bootstrap";
import PreferenceSelectionModal from "../../../../user_utility_components/preference_selection_modal/preference_selection_modal";
import axios from "axios";
import {
  url_5,
  url_6,
  url_8,
  url_32,
} from "../../../../../../custom_utilities/api_services";

//Redux
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import uiActions from "../../../../../../redux/ui/action";
import authActions from "../../../../../../redux/auth/action";
import userPreferenceActions from "../../../../../../redux/user_preference/action";

const HeaderLeft = (props) => {
  let {
    userPreference,
    userData,
    setUser,
    setUserPreference,
    startLoading,
    finishLoading,
  } = props;
  let { targetDetails, classDetails, courseDetails, batchDetails } =
    userPreference;

  const history = useHistory();

  ///////////////////////////////////////////////
  const [state, setState] = useState({
    userDetails: {},
    userPreference: {
      target_id: "",
      class_id: "",
      course_id: "",
      batch_id: "",
    },
    targets: {},
    classes: {},
    courses: {},
    batches: {},
    isTargets: false,
    isCourses: false,
    isClasses: false,
  });

  useEffect(() => {
    setUserData();
  }, []);

  useEffect(() => {
    if (!isEmpty(state.targets) && !state.isTargets) {
      getClasses();
      setState((prevState) => ({ ...prevState, isTargets: true }));
    }
  }, [state.targets]);

  useEffect(() => {
    if (!isEmpty(state.classes) && !state.isClasses) {
      getCourses();
      setState((prevState) => ({ ...prevState, isClasses: true }));
    }
  }, [state.classes]);

  useEffect(() => {
    if (!isEmpty(state.courses) && !state.isCourses) {
      getBatches();
      setState((prevState) => ({ ...prevState, isCourses: true }));
    }
  }, [state.courses]);

  const handlePreferenceModal = async (action) => {
    // if (action === "open") {
    //   preferenceModal.show();
    // } else if (action === "close") {
    //   preferenceModal.hide();
    // }
    let returnedResponse = await getUserProfile();
    if (returnedResponse.result === 1) {
      //////set user preference////////
      setUserPreference({
        targetDetails: returnedResponse.data.apiData.UsertargetDetail,
        classDetails: {
          class_id: returnedResponse.data.apiData.class_id,
          class: returnedResponse.data.apiData.class,
        },
        courseDetails: returnedResponse.data.apiData.targets,
        batchDetails: {
          batch_id: returnedResponse.data.apiData.batch_id,
          batch: returnedResponse.data.apiData.batch,
        },
      });

      setUserPreferences_1(returnedResponse.data.apiData);

      let returnedResponse_2 = await getTargets();

      if (returnedResponse_2.result === 1) {
        setState((prevState) => ({
          ...prevState,
          targets: returnedResponse_2.data.apiData,
          // current_batch_id: returnedResponse_2.data.apiData.target[0].selected[0].batch_id,
        }));
        getClasses();
        //////////////////////////
        let options = {
          keyboard: false,
          backdrop: false,
        };
        let preferenceModal = new Bootstrap.Modal(
          document.getElementById("preference_selection_modal"),
          options
        );
        preferenceModal.show();
      }
    }
  };

  const getUserProfile = async () => {
    // startLoading();
    try {
      const response = await axios.get(url_8);

      if (response.data.status === 200) {
        // finishLoading();
        return { result: 1, data: { apiData: response.data.data } };
      } else {
        // finishLoading();
        return { result: 0, data: { apiData: {} } };
      }
    } catch (error) {
      finishLoading();
      console.log(error);
      return { result: 0, data: { apiData: {} } };
    }
  };

  const setUserData = () => {
    setState((prevState) => ({ ...prevState, userDetails: userData }));
  };

  const setUserPreferences_1 = (apiData) => {
    if (apiData.UsertargetDetail.length) {
      setState((prevState) => ({
        ...prevState,
        userPreference: {
          ...prevState.userPreference,
          target_id: apiData.UsertargetDetail[0].target_id,
          course_id: apiData.targets[0].course_id,
          class_id: apiData.class_id,
          batch_id: apiData.batch_id,
        },
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        userPreference: {
          ...prevState.userPreference,
          target_id: "",
          course_id: "",
          class_id: "",
          batch_id: "",
        },
      }));
    }
  };

  const getTargets = async () => {
    startLoading();
    try {
      const response = await axios.get(url_5);

      if (response.data.status === 200) {
        finishLoading();
        return { result: 1, data: { apiData: response.data.data } };
      }
    } catch (error) {
      // finishLoading();
      console.log(error);
      return { result: 0, data: { apiData: {} } };
    }
  };

  const getClasses = () => {
    let targets = { ...state.targets };
    let userPreference = { ...state.userPreference };
    let classes = {};

    if (!isEmpty(targets)) {
      classes = targets.target.find((element) => {
        return element.target_id === parseInt(userPreference.target_id);
      });
    }

    if (!isEmpty(classes)) {
      setState((prevState) => ({ ...prevState, classes: classes }));
      getCourses();
    }
  };

  const getCourses = () => {
    let classes = { ...state.classes };
    let userPreference = { ...state.userPreference };
    let courses = {};

    if (!isEmpty(classes)) {
      courses = classes.classes.find((element) => {
        return element.class_id === parseInt(userPreference.class_id);
      });
    }

    if (!isEmpty(courses)) {
      setState((prevState) => ({ ...prevState, courses: courses }));
      getBatches();
    }
  };

  const getBatches = () => {
    let courses = { ...state.courses };
    let userPreference = { ...state.userPreference };
    let batches = {};

    if (!isEmpty(courses)) {
      batches = courses.course.find((element) => {
        return element.course_id === parseInt(userPreference.course_id);
      });
    }

    if (!isEmpty(batches)) {
      setState((prevState) => ({ ...prevState, batches: batches }));
    }
  };

  const handleChangeTarget = (event) => {
    let { value } = event.target;
    let targets = { ...state.targets };
    let classes = {};

    classes = targets.target.find((element) => {
      return element.target_id === parseInt(value);
    });

    if (!isEmpty(classes)) {
      setState((prevState) => ({
        ...prevState,
        classes: classes,
        courses: {},
        batches: {},
        userPreference: {
          ...prevState.userPreference,
          target_id: parseInt(value),
          class_id: "",
          course_id: "",
          batch_id: "",
        },
      }));
    }
  };

  const handleChangeClass = (event) => {
    let { value } = event.target;
    let classes = { ...state.classes };
    let courses = {};

    courses = classes.classes.find((element) => {
      return element.class_id === parseInt(value);
    });

    if (!isEmpty(courses)) {
      setState((prevState) => ({
        ...prevState,
        courses: courses,
        batches: {},
        userPreference: {
          ...prevState.userPreference,
          class_id: parseInt(value),
          course_id: "",
          batch_id: "",
        },
      }));
    }
  };

  const handleChangeCourse = (event) => {
    let { value } = event.target;
    let courses = { ...state.courses };
    let batches = {};

    batches = courses.course.find((element) => {
      return element.course_id === parseInt(value);
    });

    if (!isEmpty(batches)) {
      if (batches.batch.length) {
        const selectedBatch = batches.batch.find((element) => element.is_selected);
        const batchId = selectedBatch ? parseInt(selectedBatch.batch_id) : parseInt(batches.batch[0].batch_id);
      
        setState((prevState) => ({
          ...prevState,
          batches: batches,
          userPreference: {
            ...prevState.userPreference,
            course_id: parseInt(value),
            batch_id: batchId
          },
        }));
      } else {
        setState((prevState) => ({
          ...prevState,
          batches: batches,
          userPreference: {
            ...prevState.userPreference,
            course_id: parseInt(value),
            batch_id: "",
          },
        }));
      }
    }
  };

  const handleChangeBatch = (event) => {
    let { value } = event.target;
    setState((prevState) => ({
      ...prevState,
      userPreference: {
        ...prevState.userPreference,
        batch_id: parseInt(value),
      },
    }));
  };

  const upadateCourseApi = async (data) => {
    try {
      const response = await axios.post(url_32, data);
      if (response.status === 200) {
        if (response.data.status === 200) {
          return {
            result: 1,
            data: {
              message: response.data.message,
              apiData: response.data.data,
            },
          };
        } else {
          return { result: 0, data: { message: response.data.message } };
        }
      } else {
        return { result: 0, data: { message: "Something went wrong!!!" } };
      }
    } catch (error) {
      return {
        result: 0,
        data: { message: error.response.statusText },
      };
    }
  };

  const validCheckUserPreference = (userPreference) => {
    let valid = true;

    Object.values(userPreference).forEach((val) => {
      val === "" && (valid = false);
    });

    return valid;
  };

  const handleUpdateCourse = async () => {
    let userDetails = { ...state.userDetails };
    let userPreference = { ...state.userPreference };

    if (window.navigator.onLine) {
      if (validCheckUserPreference(userPreference)) {
        let data = {
          ///////////////////////////////////////////
          user_target_id: userPreference.target_id,
          class_id: userPreference.class_id,
          course_id: userPreference.course_id,
          batch_id: userPreference.batch_id,
        };

        startLoading();
        let returnedResponse = await upadateCourseApi(data);

        if (returnedResponse.result === 1) {
          finishLoading();
          setUser(returnedResponse.data.apiData);
          //////set user preference////////
          setUserPreference({
            targetDetails: returnedResponse.data.apiData.UsertargetDetail,
            classDetails: {
              class_id: returnedResponse.data.apiData.class_id,
              class: returnedResponse.data.apiData.class,
            },
            courseDetails: returnedResponse.data.apiData.targets,
            batchDetails: {
              batch_id: returnedResponse.data.apiData.batch_id,
              batch: returnedResponse.data.apiData.batch,
            },
          });
          if (history.location.pathname === "/user_dashboard") {
          window.onbeforeunload = null;
          window.location.reload();
          
          } else {
            history.push({
              pathname: "/user_dashboard",
            });
          }

        } else {
          finishLoading();
          alert(returnedResponse.data.message);
        }
      } else {
        alert("Please select target, course, class and batch carefully");
      }
    } else {
      alert("Please check your internet connection");
    }
  };

  const handleCloseModal = () => {
    setState((prevState) => ({
      ...prevState,
      userPreference: {
        ...prevState.userPreference,
        target_id: "",
        course_id: "",
        class_id: "",
        batch_id: "",
      },
      targets: {},
      courses: {},
      classes: {},
      batches: {},
      isTargets: false,
      isClasses: false,
      isCourses: false,
    }));
  };
  return (
    <Fragment>
      <div className="headerLeft_wrapper">
        <div className="headerLeft_inner_wrapper">
          <div className="hdrTopRight">
          <p className="text_content_1">
                {/* {!isEmpty(batchDetails)
                  ? "Batch - " + sliceString(batchDetails.batch, 0, 5)
                  : "Batch - "} */}
                {!isEmpty(batchDetails)
                  ? "Batch - " + batchDetails.batch
                  : "Batch"}
              </p>
            <button onClick={() => handlePreferenceModal("open")}>
                Update your Course
              <img src={images.m2_rgtArr} alt="" />
            </button>
          </div>
          {/* <div
            className="course_select_wrapper"
            onClick={() => handlePreferenceModal("open")}
          >
            <img src={images.header_6} />
            <p className="text_content_1">
              {courseDetails.length
                ? sliceString(courseDetails[0].course, 0, 4)
                : "Course"}
              {courseDetails.length ? courseDetails[0].course : "Course"}
            </p>
            <span className="caret_dropdown_icon">
              <i className="fas fa-angle-down"></i>
            </span>
          </div> */}
          {/* <div
            className="class_select_wrapper"
            onClick={() => handlePreferenceModal("open")}
          >
            <img src={images.header_7} />
            <p className="text_content_1">
               {!isEmpty(classDetails)
                ? sliceString(classDetails.class, 0, 4)
                : "Class"}
              {!isEmpty(classDetails) ? classDetails.class : "Class"}
            </p>
            <span className="caret_dropdown_icon">
              <i className="fas fa-angle-down"></i>
            </span>
          </div> */}
          {/* <div
            className="batch_select_wrapper"
            onClick={() => handlePreferenceModal("open")}
          >
            <img src={images.header_8} />
            {<span>Batch :</span>}
            <p className="text_content_1">
              {!isEmpty(batchDetails)
                ? "Batch - " + sliceString(batchDetails.batch, 0, 4)
                : "Batch - "}
              {!isEmpty(batchDetails) ? batchDetails.batch : "Batch"}
            </p>
            <span className="caret_dropdown_icon">
              <i className="fas fa-angle-down"></i>
            </span>
          </div> */}
        </div>
      </div>
      <PreferenceSelectionModal
        userPreference={state.userPreference}
        targets={state.targets}
        classes={state.classes}
        courses={state.courses}
        batches={state.batches}
        handleChangeTarget={handleChangeTarget}
        handleChangeClass={handleChangeClass}
        handleChangeCourse={handleChangeCourse}
        handleChangeBatch={handleChangeBatch}
        handleUpdateCourse={handleUpdateCourse}
        handleCloseModal={handleCloseModal}
        userData={userData}
      />
    </Fragment>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => {
      dispatch(uiActions.startLoading());
    },
    finishLoading: () => {
      dispatch(uiActions.finishLoading());
    },
    setUser: (payload) => {
      dispatch(authActions.setUser(payload));
    },
    setUserPreference: (payload) => {
      dispatch(userPreferenceActions.setUserPreference(payload));
    },
    /////////
  };
};

export default connect(null, mapDispatchToProps)(HeaderLeft);
