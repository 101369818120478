import React from "react";
import TopicWiseAnalysisContentComponent from "./topicWiseAnalysisContentComponent/TopicWiseAnalysisContentComponent";
import { connect } from "react-redux";
import uiActions from "../../../../redux/ui/action";

const TopicWiseAnalysis = (props) => {
  const {
    loader,
    parentUserId,
    paperId,
    plannerTestId,
    startLoading,
    finishLoading,
  } = props;

  return (
    <div>
      <TopicWiseAnalysisContentComponent
        loader={loader}
        parentUserId={parentUserId}
        paperId={paperId}
        plannerTestId={plannerTestId}
        startLoading={startLoading}
        finishLoading={finishLoading}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  console.log("statestate", state);
  return {
    loader: state.ui.loader,
    parentUserId: state.parentrootReducer.parentAuthReducer.userId,
    paperId: state.parentrootReducer.parentTestReducer.paperId,
    plannerTestId: state.parentrootReducer.parentTestReducer.plannerTestId,
  };
};

const mapDipatchToProps = (dispatch) => {
  return {
    startLoading: () => {
      dispatch(uiActions.startLoading());
    },
    finishLoading: () => {
      dispatch(uiActions.finishLoading());
    },
  };
};

export default connect(mapStateToProps, mapDipatchToProps)(TopicWiseAnalysis);
