export const setOrderId = (payload) => {
  return {
    type: "SET_ORDER_ID",
    payload,
  };
};

export const saveTrnsactionDetails = (payload) => {
  return {
    type: "SAVE_TRANSACTION_DETAILS",
    payload,
  };
};


export const setCcAvenueDataInStore = (payload) => {
  return {
      
    type: "SET_CC_AVENUE_DATA_IN_STORE",
     payload
    }
}

export const setPackageDetailsForPayment = (payload) => {
  return {
      
    type: "SET_PACKAGE_DETAILS_FOR_PAYMENT",
     payload
    }
}

