import { Fragment } from "react";
import "./QuestionBankTestInstructionView.scss";

import images from "../../../../../../utilities/images/images";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

const QuestionBankTestInstructionView = (props) => {
  let { startBtn, testInstructionData, questionBankTestName } = props;

  return (
    <Fragment>
      <div className="test_intructions_view_wrapper">
        <div className="container">
          <div className="test_intructions_view_inner_wrapper">
            <div className="instructionScreenBox">
              <Link
                className="backBtn"
                to={{
                  pathname: "/question_bank",
                }}
              >
                <img src={images.m2_backBtn} alt="backBtn" />
              </Link>

              <div className="instruction_title">Instruction Screen</div>
              <div className="instruction_detailBox">
                <div className="examTime">
                  <div className="examTitle">{questionBankTestName}</div>
                </div>
                <div className="instructions">
                  <h3>Set of Instructions</h3>
                  <div className="instPoint">
                    {testInstructionData.Instructions}

                    <h6>ALWAYS DO YOUR BEST. "BEST WISHES"</h6>
                  </div>
                </div>

                <div className="startTestBtn">
                  <button onClick={() => startBtn()}>Start Test</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    questionBankTestName: state.QuestionBank.questionBankName,
    userPreference: state.userPreference,
  };
};

export default connect(mapStateToProps, null)(QuestionBankTestInstructionView);
