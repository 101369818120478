import React from "react";
import PredictCollegeContentComponent from "./predictCollegeContentComponent/PredictCollegeContentComponent";
import { connect } from "react-redux";
import uiActions from "../../../../redux/ui/action";

const PredictCollege = (props) => {
  const {
    startLoading,
    finishLoading,
    loader,
    expectedRank,
    allIndiaRank,
    parentTarget,
    // studentTarget,
  } = props;
  return (
    <div>
      <PredictCollegeContentComponent
        startLoading={startLoading}
        finishLoading={finishLoading}
        loader={loader}
        allIndiaRank={allIndiaRank}
        expectedRank={expectedRank}
        parentTarget={parentTarget}
        // studentTarget={studentTarget}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loader: state.ui.loader,
    expectedRank: state.parentrootReducer.parentTestReducer.expectedRank,
    allIndiaRank: state.parentrootReducer.parentTestReducer.allIndiaRank,
    parentTarget: state.parentrootReducer.parentAuthReducer.parentTarget,
    // studentTarget: state.auth.user.UsertargetDetail[0].target_id,
  };
};

const mapDipatchToProps = (dispatch) => {
  return {
    startLoading: () => {
      dispatch(uiActions.startLoading());
    },
    finishLoading: () => {
      dispatch(uiActions.finishLoading());
    },
  };
};


export default connect(mapStateToProps, mapDipatchToProps)(PredictCollege);
