import { React, useEffect, useState } from "react";
import "./Test_summary_page.scss";

import images from "../../../../../../../utilities/images/images";
import { isEmpty } from "../../../../../../../custom_utilities/custom_useful_functions";
import CanvasDoughnutChart from "../../../../../../../components/canvas_charts/canvas_doughnut_chart/CanvasDoughnutChart3";
//
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import LoaderPopUp from "../../../../../../../components/loader/loader";
import { useHistory } from "react-router-dom";
import { NavLink, Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import axios from "axios";

const Test_summary_page = (props) => {

  const [loader, setLoader] = useState(true);
  let history = useHistory();
  const location = useLocation();
  let sm = location?.state?.tab;
  let { reportDataDetails, handleBackBtn, start } = props;
  let paperId = reportDataDetails.PaperData;
  let totalMarkPr =
    (reportDataDetails.Marks / reportDataDetails.Total_Paper_Marks) * 100;
  let totalMark = totalMarkPr.toFixed(2);
  //
  const redirect = () => {
    history.push({
      pathname: "/SubjectTopicWise",
      state: {
        reportDataDetails,
      },
    });
  };
  const redirect2 = () => {
    history.push({
      pathname: "/Attemted_Accuracy",
      state: {
        reportDataDetails,
      },
    });
  };
  const redirect3 = () => {
    history.push({
      pathname: "/DifficultyLevels",
      state: {
        reportDataDetails,
      },
    });
  };
  const redirect4 = () => {
    history.push({
      pathname: "/Behaviour",
      state: {
        reportDataDetails,
      },
    });
  };
  const redirect5 = () => {
    // location.state ? (
    //   history.push({
    //   pathname: "/Question_paper_solution",
    //   state: {
    //     paperId,
    //   },
    //   })
    // ): (
    //   alert("You can view the solution after 24 hrs.  Please check that later.")
    // )

    history.push({
      pathname: "/Question_paper_solution",
      state: {
        paperId,
      },
    });
  };
  
  
  useEffect(() => {
    setTimeout(() => {
      setLoader(false);
    }, 1500);
  }, []);

  return (
    <>
      <div className="testSummaryPageWrapper">
        <div className="container">
          <div className="testSummaryPageInrWrapper">
            <div className="test_summaryBox">
              <div
                className="backBtn"
                onClick={() => history.goBack()}
                // to={{
                //   pathname:sm === "pyq" ? "/pyq" : "/exam",
                //   state: { tab: sm === "pyq" ? "pyq" : "exam" },
                // }}
              >
                <img src={images.user_activity_1} />
              </div>

              <div className="rankMarksBox">
              
                <div className="rankMarksDiv">
                  <div className="rnkMrkBox">
                    <div className="rankIcon">
                      <img src={images.m2_rank} alt="" />
                      <h5>{reportDataDetails.Rank}</h5>
                      <span>Rank</span>
                    </div>
                    <div className="rankIcon">
                      <img src={images.m2_marks} alt="" />
                      <h5>{reportDataDetails.Marks}</h5>
                      <span>Marks</span>
                    </div>
                  </div>
                  <div className="scorePercentageMarksWrpr">
                    {/* <div className="scorePercentageBox">
                      <div className="scoreChart">
                        <CircularProgressbar
                          value={totalMark}
                          strokeWidth={10}
                        />
                      </div>
                      <div className="scoreDetail">
                        <p>Score Percentage in all Subjects</p>
                        <div className="percentageDiv">
                          <span></span>100%
                        </div>
                        <div className="percentageDiv gotPrcntg">
                          <span></span>
                          {`${totalMark}%`}
                        </div>
                      </div>
                    </div> */}
                    {/* <div className="scorePercentageBox">
                      <div className="scoreChart">
                        <CircularProgressbar
                          value={totalMark}
                          strokeWidth={10}
                        />
                      </div>
                      <div className="scoreDetail">
                        <p>Score Percentage in all Subjects</p>
                        <div className="percentageDiv">
                          <span></span>100%
                        </div>
                        <div className="percentageDiv gotPrcntg">
                          <span></span>
                          {`${totalMark}%`}
                        </div>
                      </div>
                    </div> */}
                    
                  </div>
                  <div className="marksChart">
                    <div className="marksTitle">
                      <h6>Marks</h6>
                      <div className="marksIndicator">
                        <div className="mrk_indc">
                          <span className="cutOff"></span>
                          {reportDataDetails.Cut_Off}
                        </div>
                        <div className="mrk_indc">
                          <span className="you"></span>
                          {reportDataDetails.User_Marks}
                        </div>
                        <div className="mrk_indc">
                          <span className="topper"></span>
                          {reportDataDetails.Topper_Marks}
                        </div>
                      </div>
                    </div>
                    <CanvasDoughnutChart dataPoints={reportDataDetails} />
                  </div>
                  <div className="testTypeOption">
                    <div className="testResultOption" onClick={redirect}>
                      <span>Subject & Topic Wise Marks</span>
                      <span className="arrow">
                        <img src={images.right_Ar} alt="arrow" />
                      </span>
                    </div>
                    <div className="testResultOption" onClick={redirect2}>
                      <span>Attempt and Accuracy</span>
                      <span className="arrow">
                        <img src={images.right_Ar} alt="arrow" />
                      </span>
                    </div>
                    <div className="testResultOption" onClick={redirect3}>
                      <span>Difficulty Levels</span>
                      <span className="arrow">
                        <img src={images.right_Ar} alt="arrow" />
                      </span>
                    </div>
                    <div className="testResultOption" onClick={redirect4}>
                      <span>Behavior</span>
                      <span className="arrow">
                        <img src={images.right_Ar} alt="arrow" />
                      </span>
                    </div>
                    <div className="testResultOption" onClick={redirect5}>
                      <span>Question Paper Sol.</span>
                      <span className="arrow">
                        <img src={images.right_Ar} alt="arrow" />
                      </span>
                    </div>
                    {/* <div className="testResultOption" onClick={redirect5}>
                      <span>Detailed Report</span>
                      <span className="arrow">
                        <img src={images.right_Ar} alt="arrow" />
                      </span>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loader && <LoaderPopUp />}
    </>
  );
};

export default Test_summary_page;
