import { Fragment, useState, useRef, useMemo } from "react";
import "./examCrackerTestQuestionView.scss";
import {
  url_101,
  url_102,
} from "../../../../../../../custom_utilities/api_services";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { toastifyMessage } from "../../../../../../../custom_utilities/toastify_config";

import QuestionListPanel from "./questionListPanel/QuestionListPanel";
import QuestionDetailPanel from "./questionDetailPanel/QuestionDetailPanel";

import useStopwatch from "../../../../../../../hooks/useStopwatch";
import useMathjax from "../../../../../../../hooks/useMathjax";

const ExamCrackerTestQuestionView = (props) => {
  let {
    testQuestionsDetails,
    currentQuestionDetails,
    page,
    currentQuestion,
    handlePreviousQuestionBtn,
    handleNextQuestionBtn,
    handleSaveQuestion,
    handleSingleSelectAnswer,
    handleMultiSelectAnswer,
    handleInputFieldChange,
    handleQuestionJump,
    handleSubmitTest,
    checkQuestion,
    userId,
    startLoading,
    finishLoading,
    handleCurrentOption,
  } = props;

  const {updateSolution, getClicked, solDisabled, showSolution, textInputSolution} = useStopwatch(currentQuestion, testQuestionsDetails[currentQuestion].integer_answer, currentQuestionDetails);

  const questionsCollectionRef = useRef(null);

  const [reportErrorData, setReportErrorData] = useState([]);

  const [saveReportErrorData, setSaveReportErrorData] = useState({
    user_id: userId,
    q_id: testQuestionsDetails[currentQuestion]?.qid,
    option_id: "",
    description: "",
  });

  const [videoShow, setVideoShow] = useState(false);

  //  condition for Report Error modal submit button

  const isDisable = saveReportErrorData.option_id.length === "" ? true : false;

  const handleGetReportErrorOptions = () => {
    startLoading();

    try {
      axios.get(url_101).then((response) => {
        setReportErrorData([...response.data.data]);

        if (response.status === 200) {
          finishLoading();
          handleShowReportError();
        }
      });
    } catch (error) {
      finishLoading();
    }
  };

  // function for posting user error data to api
  const handlePostReportErrorData = () => {
    startLoading();
    try {
      axios.post(url_102, saveReportErrorData).then((response) => {
        if (response.status === 200) {
          finishLoading();
          handleCloseReportError();
          toastifyMessage("Your Report has Submitted Successfully");
          setSaveReportErrorData({ option_id: "" });
        }
      });
    } catch (error) {}
  };

  const scrollUp = () => {
    const nextQuestionIndex = currentQuestion + 1;
    const questionElement =
      questionsCollectionRef.current.children[nextQuestionIndex];
    questionElement.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  const scrollUp2 = () => {
    const prevQuestionIndex = currentQuestion - 1;
    const questionElement =
      questionsCollectionRef.current.children[prevQuestionIndex];
    questionElement.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [toggleMenu,setToggleMenu] = useState(false)

  const [showError, setShowError] = useState(false);

  const handleCloseReportError = () => {
    setShowError(false);
    setSaveReportErrorData({ option_id: "" });
  };

  const handleShowReportError = () => setShowError(true);

  // for report error modal
  const [lang, setLang] = useState(true);
  const [sol, setSol] = useState(true);
  const [disabled1, setDisabled] = useState(false);

  useMathjax(currentQuestionDetails, lang, getClicked());

  const handleSol = () => {
    setSol(true);
    setDisabled(false);
  };

  const handleSolution = () => {
    setSol(false);
    setVideoShow(false);
  };
  const disableSol = () => {
    setDisabled(true);
  };

  const classEditor = (s, wc, optionId) => {
    if (Number(s) && getClicked()) {
      return "single_option_wrapper classCorrect";
    } else if (wc && getClicked() && wc.indexOf(Number(optionId)) > -1) {
      return "single_option_wrapper classWrong";
    } else {
      return "single_option_wrapper";
    }
  };

  const handlePurticularQuestion = () => {
    if (
      testQuestionsDetails[currentQuestion - 1].userAnswerCollection.length ||
      testQuestionsDetails[currentQuestion - 1].isAttempted === true
    ) {
      handleSolution();
    } else {
      handleSol();
    }
  };

  const handlePurticularQuestion3 = () => {
    if (
      testQuestionsDetails[currentQuestion + 1].userAnswerCollection.length ||
      testQuestionsDetails[currentQuestion + 1].isAttempted === true
    ) {
      handleSolution();
    } else {
      handleSol();
    }
  };

  const handlePurticularQuestion2 = (currentQuestion) => {
    if (testQuestionsDetails[currentQuestion].userAnswerCollection.length) {
      handleSolution();
    } else {
      handleSol();
    }
  };

  // const renderOptionText = (optionText) => {
  //   return { __html: optionText };
  // };

  const handleVideoShow = () => {
    setVideoShow(!videoShow);
    const targetDiv = document.querySelector(".questionDetailPanel_wrapper");
      setTimeout(() => {
        console.log("working");
        targetDiv.scrollTop = targetDiv.scrollHeight;
    }, 5000);
  };

  let ConvertStringToHTML = function (str) {
    if (!str) {
      return false;
    }
    let parser = new DOMParser();
    let doc = parser.parseFromString(str, "text/html");

    return doc.body.children.length > 0;
  };

  const renderNextBtn = () => {
    if (!testQuestionsDetails[currentQuestion].isAttempted) {
      return (
        <button
          onClick={() => {
            handleNextQuestionBtn(page + 1);
            handleSol();
            scrollUp();
          }}
        >
          Next
        </button>
      );
    } else {
      return (
        <button
          onClick={() => {
            handleNextQuestionBtn(page + 1);
            handleSol();
          }}
        >
          Next
        </button>
      );
    }
  };

  return (
    <Fragment>
      <div className="exam_cracker_test_questions_view_wrapper">
        <div className="test_questions_view_inner_wrapper">
          <div className="sec_2_wrapper">
            <div className="sec_2_inner_wrapper">
              <div className="section_content_wrapper">
              {toggleMenu ? (
                  <div
                    className="section_content_wrapper_backdrop"
                    onClick={() => {
                      setToggleMenu(false);
                    }}
                  ></div>
                ) : (
                  ""
                )}
                <QuestionListPanel
                  handleSaveQuestion={handleSaveQuestion}
                  handleShow={handleShow}
                  questionsCollectionRef={questionsCollectionRef}
                  testQuestionsDetails={testQuestionsDetails}
                  currentQuestion={currentQuestion}
                  handleQuestionJump={handleQuestionJump}
                  handlePurticularQuestion2={handlePurticularQuestion2}
                  lang={lang}
                  ConvertStringToHTML={ConvertStringToHTML}
                  toggleMenu={toggleMenu}
                  setToggleMenu={setToggleMenu}
                />

                <QuestionDetailPanel
                  updateSolution={updateSolution}
                  showSolution={showSolution}
                  solDisabled={solDisabled}
                  textInputSolution={textInputSolution}
                  getClicked={getClicked}
                  currentQuestion={currentQuestion}
                  testQuestionsDetails={testQuestionsDetails}
                  setLang={setLang}
                  lang={lang}
                  currentQuestionDetails={currentQuestionDetails}
                  ConvertStringToHTML={ConvertStringToHTML}
                  handleClose={handleClose}
                  handleSubmitTest={handleSubmitTest}
                  showError={showError}
                  show={show}
                  handleCloseReportError={handleCloseReportError}
                  reportErrorData={reportErrorData}
                  setSaveReportErrorData={setSaveReportErrorData}
                  saveReportErrorData={saveReportErrorData}
                  isDisable={isDisable}
                  handlePostReportErrorData={handlePostReportErrorData}
                  handlePreviousQuestionBtn={handlePreviousQuestionBtn}
                  page={page}
                  scrollUp2={scrollUp2}
                  handlePurticularQuestion={handlePurticularQuestion}
                  sol={sol}
                  disabled1={disabled1}
                  handleSolution={handleSolution}
                  handleSaveQuestion={handleSaveQuestion}
                  handleShow={handleShow}
                  handleNextQuestionBtn={handleNextQuestionBtn}
                  scrollUp={scrollUp}
                  handlePurticularQuestion3={handlePurticularQuestion3}
                  handleGetReportErrorOptions={handleGetReportErrorOptions}
                  handleSingleSelectAnswer={handleSingleSelectAnswer}
                  handleCurrentOption={handleCurrentOption}
                  disableSol={disableSol}
                  classEditor={classEditor}
                  handleMultiSelectAnswer={handleMultiSelectAnswer}
                  handleInputFieldChange={handleInputFieldChange}
                  checkQuestion={checkQuestion}
                  handleVideoShow={handleVideoShow}
                  videoShow={videoShow}
                  userId={userId}
                  toggleMenu={toggleMenu}
                  setToggleMenu={setToggleMenu}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ExamCrackerTestQuestionView;
