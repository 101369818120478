// import { Fragment, useState, useEffect } from "react";
// import SignUpStepOne from "./signUp_step_one/signUp_step_one";
// import SignUpStepTwo from "./signUp_step_two/signUp_step_two";
// import LoaderPopUp from "../../components/loader/loader";

// import axios from "axios";
// import { isEmpty } from "../../custom_utilities/custom_useful_functions";
// import { BASE_URL } from "../../custom_utilities/baseURL";
// import {
//   url_3,
//   url_4,
//   url_5,
//   url_6,
// } from "../../custom_utilities/api_services";

// import { Analytics } from "aws-amplify";
// /////Firebase
// import firebase from "../../firebase";

// /////Redux
// import { connect } from "react-redux";
// import uiActions from "../../redux/ui/action";
// import authActions from "../../redux/auth/action";
// import userPreferenceActions from "../../redux/user_preference/action";

// const SignUp = (props) => {
//   const [state, setState] = useState({
//     step: 1,
//     userDetails: {
//       profileImageDetails: {
//         imageData: {},
//         localImageURL: "",
//       },
//       fullName: "",
//       date_of_birth: "",
//       gender: "male",
//       email: "",
//       state: "",
//       city: "",
//     },
//     userPreference: {
//       target_id: "",
//       class_id: "",
//       course_id: "",
//       batch_id: "",
//     },
//     isTermsAndCondn: false,
//     states: {},
//     cities: {},
//     targets: {},
//     classes: {},
//     courses: {},
//     batches: {},
//     firebaseToken: "",
//     loginType: "Web",
//   });
//   console.log("state show", state);
//   let {
//     loader,
//     auth,
//     startLoading,
//     finishLoading,
//     setUser,
//     setAuth,
//     setUserPreference,
//   } = props;

//   useEffect(() => {
//     getFirebaseDeviceToken();
//     getStates();
//     getTargets();
//   }, []);

//   const getFirebaseDeviceToken = () => {
//     if (firebase.messaging.isSupported()) {
//       const messaging = firebase.messaging();
//       messaging
//         .getToken()
//         .then((token) => {
//           // console.log("Permitted");
//           // console.log(token);
//           setState((prevState) => ({ ...prevState, firebaseToken: token }));
//         })
//         .catch(() => {
//           // console.log("Not Permitted");
//         });
//     }
//   };

//   const getStates = async () => {
//     if (window.navigator.onLine) {
//       try {
//         const response = await axios.get(url_3);
//         // const response = await axios.get(`${BASE_URL}/api/user/states`);

//         if (response.data.status === 200) {
//           setState((prevState) => ({
//             ...prevState,
//             states: response.data.data,
//           }));
//         }
//       } catch (error) {
//         console.log(error);
//       }
//     } else {
//       alert("Please check your internet connection");
//     }
//   };

//   const getCities = async (selectedState) => {
//     let state = selectedState;
//     props.startLoading();
//     try {
//       const response = await axios.get(`${url_4}?state=${state}`);

//       if (response.data.status === 200) {
//         props.finishLoading();
//         setState((prevState) => ({
//           ...prevState,
//           cities: response.data.data,
//         }));
//       }
//     } catch (error) {
//       props.finishLoading();
//       console.log(error);
//     }
//   };

//   const getTargets = async () => {
//     try {
//       const response = await axios.get(url_5);

//       if (response.data.status === 200) {
//         setState((prevState) => ({
//           ...prevState,
//           targets: response.data.data,
//         }));
//       }
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   const handleNextBtn = () => {
//     let step = state.step;
//     setState((prevState) => ({ ...prevState, step: step + 1 }));
//   };

//   const handlePreviousBtn = () => {
//     let step = state.step;
//     setState((prevState) => ({ ...prevState, step: step - 1 }));
//   };

//   const createImageLocalURL = (imageDetails) => {
//     let objectURL = URL.createObjectURL(imageDetails);
//     return objectURL;
//   };

//   const handleProfileImage = (event) => {
//     let userDetails = { ...state.userDetails };
//     let profileImageDetails = { ...userDetails.profileImageDetails };
//     let { files } = event.target;
//     let localImageURL = createImageLocalURL(files[0]);

//     profileImageDetails.imageData = files[0];
//     profileImageDetails.localImageURL = localImageURL;

//     setState((prevState) => ({
//       ...prevState,
//       userDetails: {
//         ...prevState.userDetails,
//         profileImageDetails: profileImageDetails,
//       },
//     }));
//   };

//   const handleUserDetailsInputField = (event) => {
//     let { name, value } = event.target;
//     console.log(name, value);

//     switch (name) {
//       case "fullName":
//         setState((prevState) => ({
//           ...prevState,
//           userDetails: {
//             ...prevState.userDetails,
//             fullName: value,
//           },
//         }));
//         break;
//       case "date_of_birth":
//         setState((prevState) => ({
//           ...prevState,
//           userDetails: {
//             ...prevState.userDetails,
//             date_of_birth: value,
//           },
//         }));
//         break;
//       case "gender":
//         setState((prevState) => ({
//           ...prevState,
//           userDetails: {
//             ...prevState.userDetails,
//             gender: value,
//           },
//         }));
//         break;
//       case "email":
//         setState((prevState) => ({
//           ...prevState,
//           userDetails: {
//             ...prevState.userDetails,
//             email: value,
//           },
//         }));
//         break;
//       default:
//         break;
//     }
//   };

//   const handleStateDropdown = (event) => {
//     let { value } = event.target;
//     setState((prevState) => ({
//       ...prevState,
//       userDetails: { ...prevState.userDetails, state: value },
//     }));

//     getCities(value);
//   };

//   const handleCityDropdown = (event) => {
//     let { value } = event.target;
//     setState((prevState) => ({
//       ...prevState,
//       userDetails: { ...prevState.userDetails, city: value },
//     }));
//   };

//   const handleUserDetailsFormSubmit = (event) => {
//     event.preventDefault();
//     let userDetails = { ...state.userDetails };

//     if (userDetails.gender) {
//       handleNextBtn();
//     } else {
//       alert("Please select gender");
//     }
//   };

//   const handleSelectTarget = (event) => {
//     let { value } = event.target;
//     let targets = { ...state.targets };
//     let classes = {};

//     classes = targets.target.find((element) => {
//       return element.target_id === parseInt(value);
//     });

//     if (!isEmpty(classes)) {
//       setState((prevState) => ({
//         ...prevState,
//         classes: classes,
//         courses: {},
//         batches: {},
//         userPreference: {
//           ...prevState.userPreference,
//           target_id: parseInt(value),
//           class_id: "",
//           course_id: "",
//           batch_id: "",
//         },
//       }));
//     }
//   };

//   const handleSelectClass = (event) => {
//     let { value } = event.target;
//     let classes = { ...state.classes };
//     let courses = {};

//     courses = classes.classes.find((element) => {
//       return element.class_id === parseInt(value);
//     });

//     if (!isEmpty(courses)) {
//       setState((prevState) => ({
//         ...prevState,
//         courses: courses,
//         batches: {},
//         userPreference: {
//           ...prevState.userPreference,
//           class_id: parseInt(value),
//           course_id: "",
//           batch_id: "",
//         },
//       }));
//     }
//   };

//   const handleSelectCourse = (event) => {
//     let { value } = event.target;
//     let courses = { ...state.courses };
//     let batches = {};

//     batches = courses.course.find((element) => {
//       return element.course_id === parseInt(value);
//     });

//     console.log("printing batches when hitting courses", batches);

//     // console.log(batches.batch[0].batch_id)

//     if (!isEmpty(batches.batch)) {
//       setState((prevState) => ({
//         ...prevState,
//         batches: batches,
//         userPreference: {
//           ...prevState.userPreference,
//           course_id: parseInt(value),
//           batch_id: batches.batch[0].batch_id,
//         },
//       }));
//     } else {
//       alert("Batch is not available now, Please come back later");
//     }
//   };

//   const handleSelectBatch = (event) => {
//     let { value } = event.target;

//     setState((prevState) => ({
//       ...prevState,
//       userPreference: {
//         ...prevState.userPreference,
//         batch_id: parseInt(value),
//       },
//     }));

//     // console.log(userPreference.batch_id);
//   };

//   const validCheckUserPreference = (userPreference) => {
//     let valid = true;

//     Object.values(userPreference).forEach((val) => {
//       val === "" && (valid = false);
//     });

//     return valid;
//   };

//   const handleUserPreferenceFormSubmit = async (event) => {
//     event.preventDefault();
//     let {
//       userDetails,
//       userPreference,
//       isTermsAndCondn,
//       firebaseToken,
//       loginType,
//     } = state;

//     if (window.navigator.onLine) {
//       if (isTermsAndCondn) {
//         if (validCheckUserPreference(userPreference)) {
//           startLoading();
//           let courseId = [userPreference.course_id];
//           let formData = {
//             name: userDetails.fullName,
//             dob: userDetails.date_of_birth,
//             gender: userDetails.gender,
//             state: userDetails.state,
//             city: userDetails.city,
//             email: userDetails.email,
//             user_target_id: userPreference.target_id,
//             class_id: userPreference.class_id,
//             target_ids: courseId,
//             batch_id: userPreference.batch_id,
//             firebaseToken: firebaseToken,
//             loginType: loginType,
//           };
//           if (!isEmpty(userDetails.profileImageDetails.imageData)) {
//             formData = {...formData,image:userDetails.profileImageDetails.imageData};
//           }

//           // let formData = new FormData();

//           // formData.append("name", userDetails.fullName);
//           // formData.append("dob", userDetails.date_of_birth);
//           // formData.append("gender", userDetails.gender);
//           // formData.append("state", userDetails.state);
//           // formData.append("city", userDetails.city);
//           // formData.append("email", userDetails.email);

//           //if (!isEmpty(userDetails.profileImageDetails.imageData)) {
//             // formData.append("image", userDetails.profileImageDetails.imageData);
//           //}
//           // formData.append("user_target_id", userPreference.target_id);

//           // formData.append("class_id", userPreference.class_id);

//           // formData.append("target_ids", JSON.stringify(courseId)); /////Course Id

//           // formData.append("batch_id", userPreference.batch_id);

//           // console.log(userPreference.batch_id);

//           /////
//           // formData.append("firebaseToken", firebaseToken);
//           // formData.append("loginType", loginType);

//           // for (let pair of formData.entries()) {
//           //   console.log(pair[0] + ", " + pair[1]);
//           // }

//           let returnedResponse = await signUpApi(formData, auth);
//           // console.log(returnedResponse);
//           if (returnedResponse.result === 1) {
//             finishLoading();
//             setAuth(true);
//             setUser(returnedResponse.data.apiData);
//             //////set user preference////////
//             setUserPreference({
//               targetDetails: returnedResponse.data.apiData.UsertargetDetail,
//               classDetails: {
//                 class_id: returnedResponse.data.apiData.class_id,
//                 class: returnedResponse.data.apiData.class,
//               },
//               courseDetails: returnedResponse.data.apiData.targets,
//               batchDetails: {
//                 batch_id: returnedResponse.data.apiData.batch_id,
//                 batch: returnedResponse.data.apiData.batch,
//               },
//             });
//             ////////redirecting to the user dashboard//////
//             // props.history.push("/user_dashboard");
//             Analytics.record({
//               name: "_userauth.sign_up",
//             });
//             props.history.push({
//               pathname: '/user_dashboard',
//               state: { reg_mcoin: returnedResponse.data.apiData.reg_mcoin }
//             });
//           } else {
//             finishLoading();
//             alert(returnedResponse.data.message);
//           }
//         } else {
//           alert(
//             "Please select target, course and class carefully because they all are mandatory"
//           );
//         }
//       } else {
//         alert("Please check the Terms and Condition checkbox to move forward");
//       }
//     } else {
//       alert("Please check your internet connection");
//     }
//   };

//   const signUpApi = async (formData, auth) => {
//     try {
//       const headers = {
//         Authorization: `${auth.tokenType} ${auth.authToken}`,
//       };

//       const response = await axios.post(url_6, formData, {
//         headers,
//       });

//       if (response.status === 200) {
//         if (response.data.status === 200) {
//           return {
//             result: 1,
//             data: {
//               message: response.data.message,
//               apiData: response.data.data,
//             },
//           };
//         } else {
//           return { result: 0, data: { message: response.data.message } };
//         }
//       } else {
//         return { result: 0, data: { message: "Something went wrong!!!" } };
//       }
//     } catch (error) {
//       console.log(error.response);
//       return {
//         result: 0,
//         data: { message: error.response.statusText },
//       };
//     }
//   };

//   const handleTermsAndConditionsCheckbox = (event) => {
//     let { checked } = event.target;
//     setState((prevState) => ({ ...prevState, isTermsAndCondn: checked }));
//   };

//   const renderView = () => {
//     let step = state.step;

//     switch (step) {
//       case 1:
//         return (
//           <SignUpStepOne
//             mobile_number={auth.mobile_number}
//             userDetails={state.userDetails}
//             states={state.states}
//             cities={state.cities}
//             handleProfileImage={handleProfileImage}
//             handleUserDetailsInputField={handleUserDetailsInputField}
//             handleStateDropdown={handleStateDropdown}
//             handleCityDropdown={handleCityDropdown}
//             handleUserDetailsFormSubmit={handleUserDetailsFormSubmit}
//           />
//         );
//       case 2:
//         return (
//           <SignUpStepTwo
//             handlePreviousBtn={handlePreviousBtn}
//             targets={state.targets}
//             handleSelectTarget={handleSelectTarget}
//             classes={state.classes}
//             handleSelectClass={handleSelectClass}
//             courses={state.courses}
//             handleSelectCourse={handleSelectCourse}
//             batches={state.batches}
//             handleSelectBatch={handleSelectBatch}
//             userPreference={state.userPreference}
//             handleUserPreferenceFormSubmit={handleUserPreferenceFormSubmit}
//             termsAndCondition={state.isTermsAndCondn}
//             handleTermsAndConditionsCheckbox={handleTermsAndConditionsCheckbox}
//           />
//         );
//       default:
//         return null;
//     }
//   };

//   return (
//     <Fragment>
//       {renderView()}
//       {loader && <LoaderPopUp />}
//     </Fragment>
//   );
// };

// const mapStateToProps = (state) => {
//   return {
//     loader: state.ui.loader,
//     auth: state.auth,
//   };
// };

// const mapDispatchToProps = (dispatch) => {
//   return {
//     startLoading: () => {
//       dispatch(uiActions.startLoading());
//     },
//     finishLoading: () => {
//       dispatch(uiActions.finishLoading());
//     },
//     setUser: (payload) => {
//       dispatch(authActions.setUser(payload));
//     },
//     setAuth: (payload) => {
//       dispatch(authActions.setAuth(payload));
//     },
//     setUserPreference: (payload) => {
//       dispatch(userPreferenceActions.setUserPreference(payload));
//     },
//   };
// };

// export default connect(mapStateToProps, mapDispatchToProps)(SignUp);

import { Fragment, useState, useEffect } from "react";
import SignUpStepOne from "./signUp_step_one/signUp_step_one";
import SignUpStepTwo from "./signUp_step_two/signUp_step_two";
import LoaderPopUp from "../../components/loader/loader";

import axios from "axios";
import { isEmpty } from "../../custom_utilities/custom_useful_functions";
import { BASE_URL } from "../../custom_utilities/baseURL";
import { url_5, url_6 } from "../../custom_utilities/api_services";

import { Analytics } from "aws-amplify";
/////Firebase
import firebase from "../../firebase";

/////Redux
import { connect } from "react-redux";
import uiActions from "../../redux/ui/action";
import authActions from "../../redux/auth/action";
import userPreferenceActions from "../../redux/user_preference/action";
import { handleRegisterEvent } from "../../singular";
import initSingular from "../../singular";

const SignUp = (props) => {
  const [state, setState] = useState({
    step: 1,
    userDetails: {
      fullName: "",
    },
    userPreference: {
      target_id: "",
      class_id: "",
      course_id: "",
      batch_id: "",
    },

    classes: {},
    courses: {},
    targets: {},
    batches: {},
    firebaseToken: "",
    loginType: "Web",
  });

  let {
    loader,
    auth,
    startLoading,
    finishLoading,
    setUser,
    setAuth,
    setUserPreference,
  } = props;

  useEffect(() => {
    getFirebaseDeviceToken();
    getTargets();
    initSingular();
  }, []);

  const getFirebaseDeviceToken = () => {
    if (firebase.messaging.isSupported()) {
      const messaging = firebase.messaging();
      messaging
        .getToken()
        .then((token) => {
          // console.log("Permitted");
          // console.log(token);
          setState((prevState) => ({ ...prevState, firebaseToken: token }));
        })
        .catch(() => {
          // console.log("Not Permitted");
        });
    }
  };

  const getTargets = async () => {
    try {
      const response = await axios.get(url_5);

      if (response.data.status === 200) {
        setState((prevState) => ({
          ...prevState,
          targets: response.data.data,
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handlePrevious = () => {
    let step = state.step;
    setState((prevState) => ({ ...prevState, step: step - 1 }));
  };

  const handleUserDetailsInputField = (event) => {
    const { name, value } = event.target;

    if (name === "fullName") {
      setState((prevState) => ({
        ...prevState,
        userDetails: {
          ...prevState.userDetails,
          fullName: value,
        },
      }));
    }
  };

  // const handleUserDetailsFormSubmit = (event) => {
  //   event.preventDefault();
  //   const { fullName } = state.userDetails;
  //   const { target_id } = state.userPreference;

  //   if (fullName && target_id) {
  //     setState((prevState) => ({
  //       ...prevState,
  //       step: 2,
  //     }));
  //   } else {
  //     if (!fullName) {
  //       alert("Please fill in the name field.");
  //     }
  //     if (!target_id) {
  //       alert("Please select a target.");
  //     }
  //   }
  // };

  const handleUserDetailsFormSubmit = (event) => {
    event.preventDefault();
    const { fullName } = state.userDetails;
    const { target_id } = state.userPreference;

    if (fullName && target_id) {
      setState((prevState) => ({
        ...prevState,
        step: 2,
      }));
    } else {
      if (!fullName) {
        alert("Please fill in the name field.");
      }
      if (!target_id) {
        alert("Please select a target.");
      }
    }
  };

  const handleSelectTarget = (targetId) => {
    console.log("targetId", targetId);
    let targets = { ...state.targets };

    const classes = targets.target.find((element) => {
      return element.target_id === targetId;
    });

    if (!isEmpty(classes)) {
      setState((prevState) => ({
        ...prevState,
        classes: classes,
        userPreference: {
          ...prevState.userPreference,
          target_id: targetId,
          class_id: "",
        },
      }));
    }
  };

  const handleSelectClass = (classId) => {
    console.log("class_id", classId);

    const courses = state?.classes?.classes.find((element) => {
      return element.class_id === classId;
    });

    if (!isEmpty(courses)) {
      setState((prevState) => ({
        ...prevState,
        courses: courses,
        userPreference: {
          ...prevState.userPreference,
          class_id: classId,
        },
      }));
    }
  };

  const handleSelectCourse = (courseId) => {
    const batches = state?.courses?.course.find((element) => {
      return element.course_id === courseId;
    });
    if (!isEmpty(batches.batch)) {
      setState((prevState) => ({
        ...prevState,
        batches: batches,
        userPreference: {
          ...prevState.userPreference,
          course_id: courseId,
          batch_id: batches.batch[0].batch_id,
        },
      }));
    } else {
      alert("Batch is not available now, Please come back later");
    }
  };

  const validCheckUserPreference = (userPreference) => {
    let valid = true;

    Object.values(userPreference).forEach((val) => {
      val === "" && (valid = false);
    });

    return valid;
  };

  const handleUserPreferenceFormSubmit = async (event) => {
    event.preventDefault();

    const { userDetails, userPreference, firebaseToken, loginType } = state;

    if (window.navigator.onLine) {
      if (validCheckUserPreference(userPreference)) {
        if (
          userDetails.fullName &&
          userPreference.class_id &&
          userPreference.target_id
        ) {
          startLoading();
          let courseId = [userPreference.course_id];
          const formData = {
            name: userDetails.fullName,
            user_target_id: userPreference.target_id,
            class_id: userPreference.class_id,
            batch_id: userPreference.batch_id,
            firebaseToken: firebaseToken,
            target_ids: courseId,
            loginType: loginType,
          };

          const returnedResponse = await signUpApi(formData, auth);

          if (returnedResponse.result === 1) {
            finishLoading();
            setAuth(true);
            setUser(returnedResponse.data.apiData);
            handleRegisterEvent(returnedResponse.data.apiData.user_id);

            setUserPreference({
              targetDetails: returnedResponse.data.apiData.UsertargetDetail,
              classDetails: {
                class_id: returnedResponse.data.apiData.class_id,
                class: returnedResponse.data.apiData.class,
              },
              courseDetails: returnedResponse.data.apiData.targets,
              batchDetails: {
                batch_id: returnedResponse.data.apiData.batch_id,
                batch: returnedResponse.data.apiData.batch,
              },
            });

            Analytics.record({
              name: "_userauth.sign_up",
            });

            props.history.push({
              pathname: "/user_dashboard",
              state: { reg_mcoin: returnedResponse.data.apiData.reg_mcoin },
            });
          } else {
            finishLoading();
            alert(returnedResponse.data.message);
          }
        } else {
          alert("Please fill in the name and select a class");
        }
      } else {
        alert(
          "Please select class, and course carefully because they all are mandatory"
        );
      }
    } else {
      alert("Please check your internet connection");
    }
  };

  const signUpApi = async (formData, auth) => {
    try {
      const headers = {
        Authorization: `${auth.tokenType} ${auth.authToken}`,
      };

      const response = await axios.post(url_6, formData, {
        headers,
      });
      console.log("singUpApi", response);

      if (response.status === 200) {
        if (response.data.status === 200) {
          return {
            result: 1,
            data: {
              message: response.data.message,
              apiData: response.data.data,
            },
          };
        } else {
          return { result: 0, data: { message: response.data.message } };
        }
      } else {
        return { result: 0, data: { message: "Something went wrong!!!" } };
      }
    } catch (error) {
      console.log(error.response);
      return {
        result: 0,
        data: { message: error.response.statusText },
      };
    }
  };

  const renderView = () => {
    let step = state.step;

    switch (step) {
      case 1:
        return (
          <SignUpStepOne
            mobile_number={auth.mobile_number}
            userDetails={state.userDetails}
            states={state.states}
            cities={state.cities}
            handleUserDetailsInputField={handleUserDetailsInputField}
            handleUserDetailsFormSubmit={handleUserDetailsFormSubmit}
            userPreference={state.userPreference}
            targets={state.targets}
            handleSelectTarget={handleSelectTarget}
          />
        );
      case 2:
        return (
          <SignUpStepTwo
            handlePrevious={handlePrevious}
            targets={state.targets}
            handleSelectTarget={handleSelectTarget}
            classes={state.classes}
            handleSelectClass={handleSelectClass}
            courses={state.courses}
            batches={state.batches}
            userPreference={state.userPreference}
            handleUserPreferenceFormSubmit={handleUserPreferenceFormSubmit}
            handleSelectCourse={handleSelectCourse}
            step={state.step}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Fragment>
      {renderView()}
      {loader && <LoaderPopUp />}
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    loader: state.ui.loader,
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => {
      dispatch(uiActions.startLoading());
    },
    finishLoading: () => {
      dispatch(uiActions.finishLoading());
    },
    setUser: (payload) => {
      dispatch(authActions.setUser(payload));
    },
    setAuth: (payload) => {
      dispatch(authActions.setAuth(payload));
    },
    setUserPreference: (payload) => {
      dispatch(userPreferenceActions.setUserPreference(payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
