import actionTypes from "./actionTypes";

const setAdaptiveCpsSubject = (subject) => {
  return {
    type: actionTypes.SET_CPS_SUBJECT,
    payload: subject,
  };
};

const setAdaptiveCpsId = (cps_id) => {
  console.log("in action", cps_id);
  return {
    type: actionTypes.SET_ADAPTIVE_CPS_ID,
    payload: cps_id,
  };
};

const action = {
  setAdaptiveCpsSubject: setAdaptiveCpsSubject,
  setAdaptiveCpsId: setAdaptiveCpsId,
};

export default action;
