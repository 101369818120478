import { Fragment, useState, useEffect } from "react";
import "./topic_content_component.scss";
import { useLocation } from "react-router-dom";
import images from "../../../../../utilities/images/images";
import axios from "axios";
import { isEmpty } from "../../../../../custom_utilities/custom_useful_functions";
import { BASE_URL } from "../../../../../custom_utilities/baseURL";
import {
  url_11,
  url_12,
  url_12_1,
  url_13,
  url_33,
  url_39,
  url_55,
  url_140,
  url_214,
} from "../../../../../custom_utilities/api_services";

//Redux
import { connect } from "react-redux";
import uiActions from "../../../../../redux/ui/action";
import userActivityActions from "../../../../../redux/user_activity/action";
import * as rdd from "react-device-detect";
import InfiniteScroll from "react-infinite-scroll-component";
import { setLiveVideoStreamDetails } from "../../../../../redux/live_video_streaming/LiveVideoStreamingSlice";
import { setWatchedVideoDetails } from "../../../../../redux/watched_video_details/WatchedVideoDetailsSlice";

const TopicContentComponent = (props) => {
  let {
    history,
    userPreference,
    subjectData,
    topicData,
    // feature,
    startLoading,
    finishLoading,
    // setFeature,
    setVideoData,
    setRecordedVideoData,
    setNotesData,
    setLiveStreamData,
    setExerciseData,
    setLiveStreamVideoData,
    token,
    tokenType,
    setLiveVideoStreamDetailsAction,
    setWatchedVideoDetailsAction,
  } = props;
  let { courseDetails, batchDetails } = userPreference;
  const location = useLocation();
  let crsID = location.state;
  const [state, setState] = useState({
    featureType: topicData.topicVideo ? 1 : 2,
    videosData: [],
    additionalVideosData: [],
    facultyData: {},
    liveVideoData: [],
    liveClassesData: {},
    exercisesData: {},
    notesData: {},
    pageNumber: 1,
    recordedPageNumber: 1,
    currentFacultyName: {},
    additionalFacultyData: {},
    additionalCurrentFacultyName: {},
  });

  useEffect(() => {
    getFacultyData();
    getAdditionalFacultyData();
    getInitialRenderData();
    getRecordedLiveClassData();
  }, []);

  useEffect(() => {
    getVideosData();
  }, [state.currentFacultyName]);

  const handleChangeFaculty = (element) => {
    setState((prevState) => ({
      ...prevState,
      currentFacultyName: element,
    }));
    finishLoading();
  };

  const getRecordedLiveClassData = async (page = 1) => {
    let data = {
      subject_id: subjectData.subjectId,
      topic_id: topicData.topicId,
      type: "live",
      page: page,
    };
    startLoading();
    try {
      const response = await axios.get(
        `${url_214}/?subject_id=${data.subject_id}&topic_id=${data.topic_id}&type=live&page=${data.page}`
      );
      if (response.status === 200) {
        setState((prevState) => ({
          ...prevState,
          liveVideoData: response.data,
        }));
        finishLoading();
      } else {
        alert(response.data.message);
        finishLoading();
      }
    } catch (error) {
      finishLoading();
      console.log(error);
    }
  };

  const getInitialRenderData = () => {
    let featureType = state.featureType;

    // setState((prevState) => ({ ...prevState, featureType: featureType }));

    switch (featureType) {
      case 1:
        getFacultyData();
        break;
      case 2:
        let classesType = "1";
        getLiveClassesData(classesType);
        getRecordedLiveClassData();
        break;
      case 3:
        getExerciseList();
        break;
      case 4:
        getNotesData();
        break;
      case 5:
        getDppList();
        break;
      case 6:
        getAdditionalFacultyData();
        break;
      default:
        break;
    }
  };

  const renderFetaures = () => {
    let featureType = state.featureType;
    // 1 for videos
    // 2 for live class
    // 3 for exercise
    // 4 for notes
    // 5 for DPP
    // 6 for additional videos
    switch (featureType) {
      case 1:
        return renderVideosCollectionView();
      case 2:
        return renderLiveClassCollectionView();
      case 3:
        return renderExerciseCollectionView();
      case 4:
        return renderNotesCollectionView();
      case 5:
        return renderDppCollectionView();
      case 6:
        return renderAdditionalVideosCollectionView();
      default:
        return null;
    }
  };

  const setLiveClassRecord = async (element) => {
    let zoomId = decrypt_functionality(element.zoom_id);

    let data = {
      device_id: rdd.fullBrowserVersion,
      device_type: rdd.browserName,
      device_name: rdd.osName,
      zoom_id: zoomId,
      type: "Web",
    };
    startLoading();
    try {
      const response = await axios.post(url_55, data);
      if (response.data.status === 200) {
        data.redirectedFrom = "activity";
        setLiveStreamData(element);
        history.push("/zoom");

        finishLoading();
      } else {
        finishLoading();
        alert(response.data.message);
      }
    } catch (error) {
      console.log(error);
      finishLoading();
    }
  };

  const decrypt_functionality = (val) => {
    const CryptoJS = require("crypto-js");

    let encrypted = val;
    let key = "0CiuKpLCZbX7gBCyYTka4USxTnh7ssXKk8bJ6Y3TXPc=";

    encrypted = atob(encrypted);

    encrypted = JSON.parse(encrypted);

    // console.log('Laravel encryption result', encrypted);

    // IV is base64 encoded in Laravel, expected as word array in cryptojs
    const iv = CryptoJS.enc.Base64.parse(encrypted.iv);

    // Value (chipher text) is also base64 encoded in Laravel, same in cryptojs
    const value = encrypted.value;

    // Key is base64 encoded in Laravel, word array expected in cryptojs
    key = CryptoJS.enc.Base64.parse(key);

    // Decrypt the value, providing the IV.
    var decrypted = CryptoJS.AES.decrypt(value, key, {
      iv: iv,
    });

    // CryptoJS returns a word array which can be
    // converted to string like this
    decrypted = decrypted.toString(CryptoJS.enc.Utf8);

    //console.log(decrypted); // Voilà! Prints "Hello world!"

    return decrypted.split("*")[1];
  };

  const handleRecordedWatchNow = async (element) => {
    setLiveVideoStreamDetailsAction({
      zoomId: element?.zoom_id || element?.video_crypt_id,
      isLive: false,
      live_class_id: "",
      normalVideos: false,
    });

    history.push({
      pathname: "/streaming",
      state: {
        zoomId: element?.zoom_id || element?.video_crypt_id,
        isLive: false,
      },
    });

    setRecordedVideoData(element);
  };

  const handleStoreDataInStorage = (type, data) => {
    // setWatchedVideoDetailsAction(data);
    // console.log(data);testData
    if (type === "exercise") {
      if (!data.is_attempt) {
        data.redirectedFrom = "activity";
        data.exerciseType = "Exercise";
        setExerciseData(data);
        history.push({
          pathname: "/evaluation",
          exact: true,
          state: {
            type: "exercise",
            topicData: topicData,
            subjectData: subjectData,
          },
        });
      } else {
        // alert("You have already given this exercise");
        data.redirectedFrom = "activity";
        data.exerciseType = "Exercise";
        setExerciseData(data);
        history.push({
          pathname: "/evaluation",
          exact: true,
          state: { type: "exercise" },
        });
      }
    } else if (type === "dpp") {
      if (!data.is_attempt) {
        data.redirectedFrom = "activity";
        data.exerciseType = "Dpp";
        setExerciseData(data);
        history.push({
          pathname: "/evaluation",
          exact: true,
          state: {
            type: "dpp",
            topicData: topicData,
            subjectData: subjectData,
          },
        });
      } else {
        // alert("You have already given this exercise");
        data.redirectedFrom = "activity";
        data.exerciseType = "Dpp";
        setExerciseData(data);
        history.push({
          pathname: "/evaluation",
          exact: true,
          state: { type: "dpp" },
        });
      }
    } else {
      if (data.is_paid === 0) {
        // 0 means paid , 1 means not paid
        switch (type) {
          case "videos":
            data.redirectedFrom = "activity";
            setLiveVideoStreamDetailsAction({
              zoomId: data?.zoom_id || data?.video_crypt_id,
              isLive: false,
              live_class_id: "",
              normalVideos: true,
            });

            setVideoData(data);
            setWatchedVideoDetailsAction(data);
            history.push("/videos");
            break;
          case "live_class":
            setLiveClassRecord(data);
            break;
          case "live_class_video":
            data.redirectedFrom = "activity";
            // console.log(data);
            setLiveStreamVideoData(data);
            history.push("/live_stream_video");
            break;
          case "exercise":
            break;
          case "notes":
            data.redirectedFrom = "activity";
            setNotesData(data);
            history.push("/notes");
            break;
          default:
            break;
        }
      } else {
        alert("You haven't purchased a plan. Please purchase it from store.");
        history.push("/store");
      }
    }
  };

  const handleBookmarkVideo = async (videoDetails) => {
    let videoList = [...state.videosData];

    let videoIndex = videoList.findIndex((element) => {
      return element.video_id === videoDetails.video_id;
    });

    if (videoIndex !== -1) {
      videoList[videoIndex].IsSaved = !videoList[videoIndex].IsSaved;
      setState((prevState) => ({
        ...prevState,
        videosData: [...prevState.videosData],
      }));
    } else {
      return;
    }

    let data = {};
    if (!videoDetails.IsSaved) {
      data = {
        type: 4, // 4 for video
        reference_id: videoDetails.video_id,
        status: 0, // 0=>unsave
      };
    } else {
      data = {
        type: 4, // 4 for video
        reference_id: videoDetails.video_id,
        status: 1, //1=> save
      };
    }

    try {
      const response = await axios.post(url_39, data);
      // console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  const renderVideosCollectionView = () => {
    return (
      <Fragment>
        <div className="videosCollectionView_wrapper">
          <div className="videosCollectionView_inner_wrapper">
            <div className="sec_2_wrapper">
              <div className="faculty_tabs">
                {!isEmpty(state.facultyData)
                  ? state.facultyData.VideoList.length
                    ? state.facultyData.VideoList.map((element, index) => {
                        if (state.facultyData.VideoList.length > 1) {
                          return (
                            <div
                              key={index}
                              className="single_select_facultyName"
                            >
                              <input
                                type="radio"
                                id={`facultyName_${element.name}`}
                                name="facultyName"
                                value={element.name}
                                checked={
                                  state.currentFacultyName.name == element.name
                                }
                                onChange={() => handleChangeFaculty(element)}
                              />
                              <label htmlFor={`facultyName_${element.name}`}>
                                <span>
                                  <img src={element.thumnail} alt="icon" />
                                </span>
                                {element.name}
                              </label>
                            </div>
                          );
                        }
                      })
                    : null
                  : null}
              </div>

              <div className="sec_2_inner_wrapper">
                {" "}
                {state.videosData.length ? (
                  <div
                    id="parentScrollDiv"
                    className="videosListBox"
                    style={{
                      height: "60vh",
                      overflow: "auto",
                      overflowY: "scroll",
                    }}
                  >
                    <InfiniteScroll
                      dataLength={state.videosData.length}
                      next={fetchData}
                      hasMore={true}
                      scrollableTarget="parentScrollDiv"
                    >
                      <div className="videos_collection_wrapper">
                        {!isEmpty(state.videosData) ? (
                          state.videosData.length ? (
                            state.videosData.map((element, index) => {
                              return (
                                <div
                                  key={index}
                                  className="single_video_wrapper"
                                >
                                  <div
                                    className="left_side_content_wrapper"
                                    onClick={() =>
                                      handleStoreDataInStorage(
                                        "videos",
                                        element
                                      )
                                    }
                                  >
                                    <div className="image_wrapper">
                                      <img src={element.thumnail} />
                                    </div>
                                  </div>
                                  <div className="right_side_content_wrapper">
                                    <div className="right_side_content_wrapper_top">
                                      <div
                                        className="text_content_wrapper_1"
                                        onClick={() =>
                                          handleStoreDataInStorage(
                                            "videos",
                                            element
                                          )
                                        }
                                      >
                                        <p className="text_content_1">
                                          {element.video_title}
                                        </p>
                                      </div>
                                      <div className="image_wrapper">
                                        {element.IsSaved ? (
                                          <img
                                            src={images.user_activity_4}
                                            onClick={() =>
                                              handleBookmarkVideo(element)
                                            }
                                          />
                                        ) : (
                                          <img
                                            src={images.user_activity_3}
                                            onClick={() =>
                                              handleBookmarkVideo(element)
                                            }
                                          />
                                        )}
                                      </div>
                                    </div>
                                    <div className="text_content_wrapper_2">
                                      <p className="text_content_2">
                                        by {element.name}
                                      </p>
                                      <div className="label_content_wrapper">
                                        <label>
                                          <span>
                                            <img src={images.user_activity_2} />
                                          </span>
                                          <span>{element.video_rating}</span>
                                        </label>
                                      </div>
                                    </div>
                                    <div className="sub_content_wrapper">
                                      <div className="right_side_content_wrapper">
                                        <button
                                          // onClick={
                                          //   element?.vod_type == 2
                                          //     ? () =>
                                          //         handleRecordedWatchNow(
                                          //           element
                                          //         )
                                          //     : () =>
                                          //         handleStoreDataInStorage(
                                          //           "videos",
                                          //           element
                                          //         )
                                          // }
                                          onClick={() =>
                                            handleStoreDataInStorage(
                                              "videos",
                                              element
                                            )
                                          }
                                        >
                                          Watch Now
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })
                          ) : (
                            <div
                              className="no_data_found_wrapper"
                              style={{ textAlign: "center" }}
                            >
                              <img src={images.m2_video_ntF} />
                              <p>There is no video yet!</p>
                            </div>
                          )
                        ) : (
                          <div
                            className="no_data_found_wrapper"
                            style={{ textAlign: "center" }}
                          >
                            <img src={images.m2_video_ntF} />
                            <p>There is no Video yet!</p>
                          </div>
                        )}
                      </div>
                    </InfiniteScroll>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  };

  const renderAdditionalVideosCollectionView = () => {
    return (
      <Fragment>
        <div className="videosCollectionView_wrapper">
          <div className="videosCollectionView_inner_wrapper">
            <div className="sec_2_wrapper">
              <div className="faculty_tabs">
                {!isEmpty(state.additionalFacultyData)
                  ? state.additionalFacultyData.VideoList.length
                    ? state.additionalFacultyData.VideoList.map(
                        (element, index) => {
                          if (
                            state.additionalFacultyData.VideoList.length > 1
                          ) {
                            return (
                              <div
                                key={index}
                                className="single_select_facultyName"
                              >
                                <input
                                  type="radio"
                                  id={`facultyName_${element.name}`}
                                  name="facultyName"
                                  value={element.name}
                                  checked={
                                    state.currentFacultyName.name ==
                                    element.name
                                  }
                                  onChange={() => handleChangeFaculty(element)}
                                />
                                <label htmlFor={`facultyName_${element.name}`}>
                                  <span>
                                    <img src={element.thumnail} alt="icon" />
                                  </span>
                                  {element.name}
                                </label>
                              </div>
                            );
                          }
                        }
                      )
                    : null
                  : null}
              </div>

              <div className="sec_2_inner_wrapper">
                {" "}
                {state.additionalVideosData.length ? (
                  <div
                    id="parentScrollDiv"
                    className="videosListBox"
                    style={{
                      height: "60vh",
                      overflow: "auto",
                      overflowY: "scroll",
                    }}
                  >
                    <InfiniteScroll
                      dataLength={state.additionalVideosData.length}
                      next={fetchData}
                      hasMore={true}
                      scrollableTarget="parentScrollDiv"
                    >
                      <div className="videos_collection_wrapper">
                        {!isEmpty(state.additionalVideosData) ? (
                          state.additionalVideosData.length ? (
                            state.additionalVideosData.map((element, index) => {
                              return (
                                <div
                                  key={index}
                                  className="single_video_wrapper"
                                >
                                  <div
                                    className="left_side_content_wrapper"
                                    onClick={() =>
                                      handleStoreDataInStorage(
                                        "videos",
                                        element
                                      )
                                    }
                                  >
                                    <div className="image_wrapper">
                                      <img src={element.thumnail} />
                                    </div>
                                  </div>
                                  <div className="right_side_content_wrapper">
                                    <div className="right_side_content_wrapper_top">
                                      <div
                                        className="text_content_wrapper_1"
                                        onClick={() =>
                                          handleStoreDataInStorage(
                                            "videos",
                                            element
                                          )
                                        }
                                      >
                                        <p className="text_content_1">
                                          {element.video_title}
                                        </p>
                                      </div>
                                      {/* <div className="image_wrapper">
                                        {element.IsSaved ? (
                                          <img
                                            src={images.user_activity_4}
                                            onClick={() =>
                                              handleBookmarkVideo(element)
                                            }
                                          />
                                        ) : (
                                          <img
                                            src={images.user_activity_3}
                                            onClick={() =>
                                              handleBookmarkVideo(element)
                                            }
                                          />
                                        )}
                                      </div> */}
                                    </div>
                                    <div className="text_content_wrapper_2">
                                      <p className="text_content_2">
                                        by {element.name}
                                      </p>
                                      {/* <div className="label_content_wrapper">
                                        <label>
                                          <span>
                                            <img src={images.user_activity_2} />
                                          </span>
                                          <span>{element.video_rating}</span>
                                        </label>
                                      </div> */}
                                    </div>
                                    <div className="sub_content_wrapper">
                                      <div className="right_side_content_wrapper">
                                        <button
                                          onClick={() =>
                                            handleStoreDataInStorage(
                                              "videos",
                                              element
                                            )
                                          }
                                        >
                                          Watch Now
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })
                          ) : (
                            <div
                              className="no_data_found_wrapper"
                              style={{ textAlign: "center" }}
                            >
                              <img src={images.m2_video_ntF} />
                              <p>There is no video yet!</p>
                            </div>
                          )
                        ) : (
                          <div
                            className="no_data_found_wrapper"
                            style={{ textAlign: "center" }}
                          >
                            <img src={images.m2_video_ntF} />
                            <p>There is no Video yet!</p>
                          </div>
                        )}
                      </div>
                    </InfiniteScroll>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  };

  const renderLiveClassCollectionView = () => {
    return (
      <Fragment>
        <div className="liveClassCollectionView_wrapper">
          <div className="liveClassCollectionView_inner_wrapper">
            <div className="section_wrapper">
              <div className="section_inner_wrapper">
                {/* <div className="options_wrapper">
                  <ul
                    className="nav nav-pills custom_nav_pills"
                    id="pills-tab"
                    role="tablist"
                  >
                    <li
                      className="nav-item custom_nav_item"
                      role="presentation"
                    >
                      <button
                        className="nav-link active custom_nav_link"
                        id="pills-previous-class-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-previous-class"
                        type="button"
                        role="tab"
                        aria-controls="pills-previous-class"
                        aria-selected="true"
                        onClick={() => handleLiveClassTabChange("previous")}
                        // style={{ display: "none" }}
                      >
                        Recorded
                      </button>
                    </li>
                    <li
                      className="nav-item custom_nav_item"
                      role="presentation"
                    >
                      <button
                        className="nav-link custom_nav_link"
                        id="pills-upcoming-class-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-upcoming-class"
                        type="button"
                        role="tab"
                        aria-controls="pills-upcoming-class"
                        aria-selected="false"
                        onClick={() => handleLiveClassTabChange("upcoming")}
                      >
                        Live
                      </button>
                    </li>
                  </ul>
                </div> */}
                <div className="tab_wrapper">
                  <div
                    className="tab-content custom_tab_content"
                    id="pills-tabContent"
                  >
                    {/* //////////////upcoming class tab//////// */}
                    {/* <div
                      className="tab-pane fade custom_tab_pane"
                      id="pills-upcoming-class"
                      role="tabpanel"
                      aria-labelledby="pills-upcoming-class-tab"
                    >
                      <div className="upcoming_class_tab_wrapper">
                        <div className="outer_content_wrapper">
                          <div className="liveClasses_collection_wrapper">
                            {!isEmpty(state.liveClassesData.meeting) ? (
                              state.liveClassesData.meeting.length ? (
                                state.liveClassesData.meeting.map(
                                  (element, index) => {
                                    return (
                                      <div
                                        key={index}
                                        className="single_liveClass_wrapper"
                                      >
                                        <div className="left_side_content_wrapper">
                                          <div className="image_wrapper">
                                            <img
                                              src={element.faculty_image}
                                              style={{ width: "70px" }}
                                            />
                                          </div>
                                        </div>
                                        <div className="right_side_content_wrapper">
                                          <div className="text_content_wrapper_1">
                                            <p className="text_content_1">
                                              {element.meeting_topic}
                                            </p>
                                          </div>
                                          <div className="text_content_wrapper_2">
                                            <p className="text_content_2">
                                              {`by ${element.faculty}`}
                                            </p>
                                          </div>
                                          <div className="sub_content_wrapper">
                                            <div className="left_side_content_wrapper">
                                              <div className="label_content_wrapper">
                                                <label>
                                                  <span>
                                                    <img
                                                      src={
                                                        images.user_dashboard_3
                                                      }
                                                    />
                                                  </span>
                                                  <span
                                                    style={{
                                                      marginLeft: "10px",
                                                    }}
                                                  >
                                                    {element.meetdate}
                                                  </span>
                                                </label>
                                              </div>
                                            </div>
                                            <div className="right_side_content_wrapper">
                                              <div className="text_content_wrapper">
                                                <p
                                                  className="text_content"
                                                  onClick={() =>
                                                    handleStoreDataInStorage(
                                                      "live_class",
                                                      element
                                                    )
                                                  }
                                                >
                                                  Join now
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  }
                                )
                              ) : (
                                <div
                                  className="no_data_found_wrapper"
                                  style={{ textAlign: "center" }}
                                >
                                  <img src={images.m2_video_ntF} />
                                  <p>There is no Live Classes yet!</p>
                                </div>
                              )
                            ) : (
                              <div
                                className="no_data_found_wrapper"
                                style={{ textAlign: "center" }}
                              >
                                <img src={images.m2_video_ntF} />
                                <p>There is no Live Classes yet!</p>
                              </div>
                            )}
                          </div>
                          {!isEmpty(state.liveClassesData) ? (
                            state.liveClassesData.meeting.length ? (
                              <div className="sec_3_wrapper">
                                <div className="sec_3_inner_wrapper">
                                  <div className="btn_wrapper">
                                    <button
                                      type="button"
                                      onClick={() => handleLiveClassPrevBtn(1)}
                                    >
                                      Prev
                                    </button>
                                  </div>
                                  <div className="btn_wrapper">
                                    <button
                                      type="button"
                                      onClick={() => handleLiveClassNextBtn(1)}
                                    >
                                      Next
                                    </button>
                                  </div>
                                </div>
                              </div>
                            ) : null
                          ) : null}
                        </div>
                      </div>
                    </div> */}
                    {/* //////////////previous class tab//////// */}
                    <div
                      className="tab-pane fade show active custom_tab_pane"
                      id="pills-previous-class"
                      role="tabpanel"
                      aria-labelledby="pills-previous-class-tab"
                    >
                      <div className="previous_class_tab_wrapper">
                        <div className="outer_content_wrapper">
                          <div className="liveClasses_recorded_collection_wrapper">
                            {!isEmpty(state.liveVideoData.data) ? (
                              state.liveVideoData.data.length ? (
                                state.liveVideoData.data.map(
                                  (element, index) => {
                                    return (
                                      <div
                                        key={index}
                                        className="single_liveClass_wrapper"
                                      >
                                        <div className="left_side_content_wrapper_recorded">
                                          {element.faculty_image ? (
                                            <div className="image_wrapper">
                                              <img
                                                src={element.faculty_image}
                                                alt="faculty"
                                              />
                                            </div>
                                          ) : (
                                            <div
                                              className="image_wrapper"
                                              style={{ padding: "30px" }}
                                            >
                                              <img
                                                src={
                                                  images.play_thumbnail_image
                                                }
                                                style={{ width: "50px" }}
                                                alt="faculty"
                                              />
                                            </div>
                                          )}
                                        </div>
                                        <div className="right_side_content_wrapper_recorded">
                                          <div className="text_content_wrapper_1">
                                            <p className="text_content_1">
                                              {element.meeting_topic}
                                            </p>
                                          </div>
                                          <div className="text_content_wrapper_2">
                                            <span>
                                              <p className="text_content_2">
                                                {`by ${element.faculty}`}
                                              </p>
                                            </span>
                                          </div>
                                          <div className="d-flex">
                                            <span>
                                              <img
                                                src={
                                                  images.user_activity_calendar
                                                }
                                                alt="calendar"
                                              />
                                              <p className="text_content_2">
                                                {`${element.meetdate}`}
                                              </p>
                                            </span>
                                            <span>
                                              <img
                                                src={images.user_activity_31}
                                                alt="clock"
                                              />
                                              <p className="text_content_2">
                                                {`${element.meeting_duration}`}
                                              </p>
                                            </span>
                                          </div>
                                          <button
                                            className="text_content"
                                            onClick={() =>
                                              handleRecordedWatchNow(element)
                                            }
                                          >
                                            Watch now
                                          </button>
                                          {/* <div className="text_content_wrapper_2">
                                            <p className="text_content_2">
                                              {`${element.meetdate}`}
                                            </p>
                                          </div>
                                          <div className="text_content_wrapper_2 d-flex justify-content-between">
                                            <p className="text_content_2">
                                              {`${element.meeting_duration}`}
                                            </p>
                                            <button
                                                  className="text_content"
                                                  onClick={() =>
                                                    handleRecordedWatchNow(element.zoom_id)
                                                  }
                                                >
                                                  Watch now
                                                </button>
                                          </div> */}
                                        </div>
                                      </div>
                                    );
                                  }
                                )
                              ) : (
                                <div
                                  className="no_data_found_wrapper"
                                  style={{ textAlign: "center" }}
                                >
                                  <img src={images.m2_video_ntF} />
                                  <p>No Recoded class is available as of now</p>
                                </div>
                              )
                            ) : (
                              <div
                                className="no_data_found_wrapper"
                                style={{ textAlign: "center" }}
                              >
                                <img src={images.m2_video_ntF} />
                                <p>No Recoded class is available as of now</p>
                              </div>
                            )}
                          </div>
                          {!isEmpty(state.liveVideoData.data) ? (
                            state.liveVideoData.data.length ? (
                              <div className="sec_3_wrapper">
                                <div className="sec_3_inner_wrapper">
                                  <div className="btn_wrapper">
                                    {state.recordedPageNumber > 1 && (
                                      <button
                                        type="button"
                                        onClick={() => {
                                          setState((prevState) => ({
                                            ...prevState,
                                            recordedPageNumber:
                                              prevState.recordedPageNumber - 1,
                                          }));
                                          getRecordedLiveClassData(
                                            state.recordedPageNumber - 1
                                          );
                                        }}
                                      >
                                        Prev
                                      </button>
                                    )}
                                  </div>
                                  <div className="btn_wrapper">
                                    {state.recordedPageNumber <
                                      state.liveVideoData.last_page && (
                                      <button
                                        type="button"
                                        onClick={() => {
                                          setState((prevState) => ({
                                            ...prevState,
                                            recordedPageNumber:
                                              prevState.recordedPageNumber + 1,
                                          }));
                                          getRecordedLiveClassData(
                                            state.recordedPageNumber + 1
                                          );
                                        }}
                                      >
                                        Next
                                      </button>
                                    )}
                                  </div>
                                </div>
                              </div>
                            ) : null
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  };

  const renderExerciseCollectionView = () => {
    return (
      <Fragment>
        <div className="exerciseCollectionView_wrapper">
          <div className="exerciseCollectionView_inner_wrapper">
            <div className="sec_1_wrapper">
              <div className="options_wrapper">
                <ul
                  className="nav nav-pills custom_nav_pills"
                  id="pills-tab"
                  role="tablist"
                >
                  <li className="nav-item custom_nav_item" role="presentation">
                    <button
                      className="nav-link active custom_nav_link"
                      id="pills-upcoming-class-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-upcoming-class"
                      type="button"
                      role="tab"
                      aria-controls="pills-upcoming-class"
                      aria-selected="true"
                    >
                      Start Practice
                    </button>
                  </li>
                  <li className="nav-item custom_nav_item" role="presentation">
                    <button
                      className="nav-link custom_nav_link"
                      id="pills-previous-class-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-previous-class"
                      type="button"
                      role="tab"
                      aria-controls="pills-previous-class"
                      aria-selected="false"
                    >
                      Resume Practice
                    </button>
                  </li>
                </ul>
              </div>
              <div
                className="tab-content custom_tab_content"
                id="pills-tabContent"
              >
                <div
                  className="tab-pane fade show active custom_tab_pane"
                  id="pills-upcoming-class"
                  role="tabpanel"
                  aria-labelledby="pills-upcoming-class-tab"
                >
                  <div className="sec_1_inner_wrapper">
                    <div className="exercise_collection_wrapper">
                      {!isEmpty(state.exercisesData) ? (
                        state.exercisesData.ExerciseList.length ? (
                          state.exercisesData.ExerciseList.filter(
                            (ex) => ex.is_attempt == false
                          ).length ? (
                            state.exercisesData.ExerciseList.map(
                              (element, index) => {
                                return (
                                  <div
                                    key={index}
                                    className="single_exercise_wrapper"
                                    onClick={() =>
                                      handleStoreDataInStorage(
                                        "exercise",
                                        element
                                      )
                                    }
                                  >
                                    <div className="exercise_description_wrapper">
                                      <div className="exercise_details_wrapper">
                                        <div className="text_content_wrapper_1">
                                          <p className="text_content_1">
                                            {element.excersie_type}
                                          </p>
                                        </div>
                                        <div className="text_content_wrapper_2">
                                          <p className="text_content_2">{`${element.total_question} Questions`}</p>
                                        </div>
                                      </div>
                                      <div className="caret_wraper">
                                        <div className="text_content_wrapper">
                                          <span>Start Practice</span>
                                          <img
                                            src={images.m2_rgtArow}
                                            alt="arrow"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              }
                            )
                          ) : (
                            <div
                              className="no_data_found_wrapper"
                              style={{ textAlign: "center" }}
                            >
                              <img src={images.m2_video_ntF} />
                              <p>You don't have any exercise for Practice</p>
                            </div>
                          )
                        ) : (
                          <div
                            className="no_data_found_wrapper"
                            style={{ textAlign: "center" }}
                          >
                            <img src={images.m2_video_ntF} />
                            <p>There is no exercise yet!</p>
                          </div>
                        )
                      ) : (
                        <div
                          className="no_data_found_wrapper"
                          style={{ textAlign: "center" }}
                        >
                          <img src={images.m2_video_ntF} />
                          <p>There is no exercise yet!</p>
                        </div>
                      )}
                    </div>
                  </div>
                  {!isEmpty(state.exercisesData) ? (
                    state.exercisesData.ExerciseList.length ? (
                      <div className="sec_2_wrapper">
                        <div className="sec_2_inner_wrapper">
                          <div className="btn_wrapper">
                            <button
                              type="button"
                              onClick={() => handlePrevBtn()}
                            >
                              Prev
                            </button>
                          </div>
                          <div className="btn_wrapper">
                            <button
                              type="button"
                              onClick={() => handleNextBtn()}
                            >
                              Next
                            </button>
                          </div>
                        </div>
                      </div>
                    ) : null
                  ) : null}
                </div>
                <div
                  className="tab-pane fade custom_tab_pane"
                  id="pills-previous-class"
                  role="tabpanel"
                  aria-labelledby="pills-previous-class-tab"
                >
                  {/* <div className="pause_exercise"></div> */}
                  <div className="sec_1_inner_wrapper">
                    <div className="exercise_collection_wrapper">
                      {!isEmpty(state.exercisesData) ? (
                        state.exercisesData.ExerciseList.length ? (
                          state.exercisesData.ExerciseList.filter(
                            (ex) => ex.is_attempt == true
                          ).length ? (
                            state.exercisesData.ExerciseList.map(
                              (element, index) => {
                                return (
                                  <div
                                    key={index}
                                    className="single_exercise_wrapper"
                                    onClick={() =>
                                      handleStoreDataInStorage(
                                        "exercise",
                                        element
                                      )
                                    }
                                  >
                                    <div className="exercise_description_wrapper">
                                      <div className="exercise_details_wrapper">
                                        <div className="text_content_wrapper_1">
                                          <p className="text_content_1">
                                            {element.excersie_type}
                                          </p>
                                        </div>
                                        <div className="text_content_wrapper_2">
                                          <p className="text_content_2">{`${element.total_question} Questions`}</p>
                                        </div>
                                      </div>
                                      <div className="caret_wraper">
                                        <div className="text_content_wrapper">
                                          <span>Resume Practice</span>
                                          <img
                                            src={images.m2_rgtArow}
                                            alt="arrow"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              }
                            )
                          ) : (
                            <div
                              className="no_data_found_wrapper"
                              style={{ textAlign: "center" }}
                            >
                              <img src={images.m2_video_ntF} />
                              <p>You don't have any exercise for Resume</p>
                            </div>
                          )
                        ) : (
                          <div
                            className="no_data_found_wrapper"
                            style={{ textAlign: "center" }}
                          >
                            <img src={images.m2_video_ntF} />
                            <p>There is no exercise yet!</p>
                          </div>
                        )
                      ) : (
                        <div
                          className="no_data_found_wrapper"
                          style={{ textAlign: "center" }}
                        >
                          <img src={images.m2_video_ntF} />
                          <p>There is no exercise yet!</p>
                        </div>
                      )}
                    </div>
                  </div>
                  {!isEmpty(state.exercisesData) ? (
                    state.exercisesData.ExerciseList.length ? (
                      <div className="sec_2_wrapper">
                        <div className="sec_2_inner_wrapper">
                          <div className="btn_wrapper">
                            <button
                              type="button"
                              onClick={() => handlePrevBtn()}
                            >
                              Prev
                            </button>
                          </div>
                          <div className="btn_wrapper">
                            <button
                              type="button"
                              onClick={() => handleNextBtn()}
                            >
                              Next
                            </button>
                          </div>
                        </div>
                      </div>
                    ) : null
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  };

  const renderNotesCollectionView = () => {
    return (
      <Fragment>
        <div className="notesCollectionView_wrapper">
          <div className="notesCollectionView_inner_wrapper">
            <div className="notes_collection_wrapper">
              {!isEmpty(state.notesData) ? (
                state.notesData.NotesList.data.length ? (
                  state.notesData.NotesList.data.map((element, index) => {
                    return (
                      <div
                        key={index}
                        className="single_notes_wrapper"
                        onClick={() =>
                          handleStoreDataInStorage("notes", element)
                        }
                      >
                        <div className="notes_description_wrapper">
                          <div className="notes_details_wrapper">
                            <div className="text_content_wrapper_1">
                              <p className="text_content_1">
                                {element.notes_title}
                              </p>
                            </div>
                          </div>
                          <div className="arrow">
                            <img src={images.m2_rgtArow} alt="" />
                          </div>
                          {/* <div className="caret_wraper">
                            <div className="image_wrapper">
                              {element.IsSaved ? (
                                <img src={images.user_activity_4} />
                              ) : (
                                <img src={images.user_activity_3} />
                              )}
                            </div>
                          </div> */}
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div
                    className="no_data_found_wrapper"
                    style={{ textAlign: "center" }}
                  >
                    <img src={images.m2_video_ntF} />
                    <p>There is no notes yet!</p>
                  </div>
                )
              ) : (
                <div
                  className="no_data_found_wrapper"
                  style={{ textAlign: "center" }}
                >
                  <img src={images.m2_video_ntF} />
                  <p>There is no notes yet!</p>
                </div>
              )}
            </div>
            {!isEmpty(state.notesData) ? (
              state.notesData.NotesList.data.length ? (
                <div className="sec_3_wrapper">
                  <div className="sec_3_inner_wrapper">
                    <div className="btn_wrapper">
                      <button type="button" onClick={() => handlePrevBtn()}>
                        Prev
                      </button>
                    </div>
                    <div className="btn_wrapper">
                      <button type="button" onClick={() => handleNextBtn()}>
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              ) : null
            ) : null}
          </div>
        </div>
      </Fragment>
    );
  };

  const renderDppCollectionView = () => {
    return (
      <Fragment>
        <div className="dppCollectionView_wrapper">
          <div className="dppCollectionView_inner_wrapper">
            <div className="dpp_collection_wrapper">
              {!isEmpty(state.dppCollections) ? (
                state?.dppCollections?.DPPList?.length ? (
                  state.dppCollections.DPPList.map((element, index) => {
                    return (
                      <div
                        key={index}
                        className="single_dpp_wrapper"
                        onClick={() => handleStoreDataInStorage("dpp", element)}
                      >
                        <div className="serial_number_wrapper">
                          <div className="image_wrapper">
                            <img src={images.user_activity_13} />
                          </div>
                        </div>
                        <div className="dpp_description_wrapper">
                          <div className="dpp_details_wrapper">
                            <div className="text_content_wrapper_1">
                              <p className="text_content_1">
                                {element.excersie_type}
                              </p>
                            </div>
                            <div className="text_content_wrapper_2">
                              <p className="text_content_2">
                                {`${element.total_question} Questions`}
                              </p>
                            </div>
                          </div>
                          <div className="caret_wraper">
                            <div className="image_wrapper">
                              {/* {element.IsSaved ? (
                                  <img src={images.user_activity_4} />
                                ) : (
                                  <img src={images.user_activity_3} />
                                )} */}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div
                    className="no_data_found_wrapper"
                    style={{ textAlign: "center" }}
                  >
                    <img src={images.no_data} />
                  </div>
                )
              ) : (
                <div
                  className="no_data_found_wrapper"
                  style={{ textAlign: "center" }}
                >
                  <img src={images.no_data} />
                </div>
              )}
            </div>
          </div>
        </div>
      </Fragment>
    );
  };

  const handleChangeFeature = (event) => {
    let { value } = event.target;

    switch (value) {
      case "videos":
        setState((prevState) => ({
          ...prevState,
          featureType: 1,
          pageNumber: 1,
        }));
        // setFeature(1);
        getVideosData();
        break;
      case "live_class":
        setState((prevState) => ({
          ...prevState,
          featureType: 2,
          pageNumber: 1,
        }));
        let classesType = "1";
        // setFeature(2);
        getLiveClassesData(classesType);
        getRecordedLiveClassData();
        break;
      case "exercise":
        setState((prevState) => ({
          ...prevState,
          featureType: 3,
          pageNumber: 1,
        }));
        // setFeature(3);
        getExerciseList();
        break;
      case "notes":
        setState((prevState) => ({
          ...prevState,
          featureType: 4,
          pageNumber: 1,
        }));
        // setFeature(4);
        getNotesData();
        break;

      case "dpp":
        setState((prevState) => ({
          ...prevState,
          featureType: 5,
          pageNumber: 1,
        }));
        getDppList();
        break;

      case "additional_videos":
        setState((prevState) => ({
          ...prevState,
          featureType: 6,
          pageNumber: 1,
        }));
        getAdditionalVideosData();
        break;
      default:
        break;
    }
  };

  //////////Videos Data/////////////////
  const getVideosData = async () => {
    let data = {
      search: "",
      course_id: location.state ? crsID : courseDetails[0].course_id,
      subject_id: subjectData.subjectId,
      topic_id: topicData.topicId,
      faculty_id: state?.currentFacultyName?.user_id,
    };
    startLoading();
    try {
      const response = await axios.get(
        `${url_11}?page=1&search=${data.search}&course_id=${data.course_id}&subject_id=${data.subject_id}&topic_id=${data.topic_id}&faculty_id=${data.faculty_id}`
      );
      if (response.data.status === 200) {
        finishLoading();
        setState((prevState) => ({
          ...prevState,
          videosData: [...response.data.data.VideoList.data],
        }));
      }
    } catch (error) {
      // finishLoading();
      console.log(error);
    }
  };

  const getAdditionalVideosData = async () => {
    let data = {
      search: "",
      type: 2,
      course_id: location.state ? crsID : courseDetails[0].course_id,
      subject_id: subjectData.subjectId,
      topic_id: topicData.topicId,
      faculty_id: state?.additionalCurrentFacultyName?.user_id,
    };
    startLoading();
    try {
      const response = await axios.get(
        `${url_11}?page=1&search=${data.search}&course_id=${data.course_id}&subject_id=${data.subject_id}&topic_id=${data.topic_id}&faculty_id=${data.faculty_id}&type=${data.type}`
      );
      if (response.data.status === 200) {
        finishLoading();
        setState((prevState) => ({
          ...prevState,
          additionalVideosData: [...response.data.data.VideoList.data],
        }));
      }
    } catch (error) {
      // finishLoading();
      console.log(error);
    }
  };

  let page_limit = 10;

  const fetchVideosData = async () => {
    let pageNo = Math.ceil(state.videosData.length / page_limit) + 1;
    let data = {
      search: "",
      course_id: location.state ? crsID : courseDetails[0].course_id,
      subject_id: subjectData.subjectId,
      topic_id: topicData.topicId,
      faculty_id: state?.currentFacultyName?.user_id,
      page: pageNo,
    };
    startLoading();
    try {
      const response = await axios.get(
        `${url_11}?page=1&search=${data.search}&course_id=${data.course_id}&subject_id=${data.subject_id}&topic_id=${data.topic_id}&faculty_id=${data.faculty_id}&page=${data.page}`
      );
      if (
        response.data.status === 200 ||
        response.data.data.VideoList.last_page
      ) {
        finishLoading();
        setState((prevState) => ({
          ...prevState,
          videosData: [
            ...state.videosData,
            ...response.data.data.VideoList.data,
          ],
        }));
      }
    } catch (error) {
      // finishLoading();
      console.log(error);
    }
  };

  const fetchData = () => {
    fetchVideosData();
  };

  const getFacultyData = async () => {
    let data = {
      search: "",
      course_id: location.state ? crsID : courseDetails[0].course_id,
      subject_id: subjectData.subjectId,
      topic_id: topicData.topicId,
    };
    // startLoading();
    try {
      const response = await axios.get(
        `${url_140}?page=1&search=${data.search}&course_id=${data.course_id}&subject_id=${data.subject_id}&topic_id=${data.topic_id}`
      );

      if (response.data.status === 200) {
        setState((prevState) => ({
          ...prevState,
          facultyData: response.data.data,
          currentFacultyName: response.data.data.VideoList[0],
        }));
      } else {
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAdditionalFacultyData = async () => {
    let data = {
      search: "",
      course_id: location.state ? crsID : courseDetails[0].course_id,
      subject_id: subjectData.subjectId,
      topic_id: topicData.topicId,
      type: 2,
    };
    // startLoading();
    try {
      const response = await axios.get(
        `${url_140}?page=1&search=${data.search}&course_id=${data.course_id}&subject_id=${data.subject_id}&topic_id=${data.topic_id}&type=${data.type}`
      );

      if (response.data.status === 200) {
        setState((prevState) => ({
          ...prevState,
          additionalFacultyData: response.data.data,
          additionalCurrentFacultyName: response.data.data.VideoList[0],
        }));
      } else {
      }
    } catch (error) {
      console.log(error);
    }
  };

  /////////////Live classess////////////////
  // classes_type=>1 =>for upcoming classes
  // classes_type=>0 =>for previous classes

  const getLiveClassesData = async (classesType) => {
    let data = {
      search: "",
      course_id: location.state ? crsID : courseDetails[0].course_id,
      batch_id: batchDetails.batch_id,
      subject_id: subjectData.subjectId,
      topic_id: topicData.topicId,
      classes_type: classesType,
    };
    startLoading();
    try {
      const response = await axios.post(`${url_12_1}?page=1`, data);

      finishLoading();
      if (response.data.status === 200) {
        finishLoading();
        // setState((prevState) => ({
        //   ...prevState,
        //   liveClassesData: response.data.data,
        // }));
      }
    } catch (error) {
      finishLoading();
      console.log(error);
    }
  };

  ///////////Exercise///////////////////
  const getExerciseList = async () => {
    let requestPayload = {
      course_id: location.state ? crsID : courseDetails[0].course_id,
      topic_id: topicData.topicId,
      page: 0,
      type: "Exercise",
    };
    startLoading();
    try {
      const response = await axios.post(url_33, requestPayload);
      if (response.data.status === 200) {
        setState((prevState) => ({
          ...prevState,
          exercisesData: response.data.data,
        }));
        finishLoading();
      }
    } catch (error) {
      finishLoading();
      console.log(error);
    }
  };

  ////////////Notes////////////////////
  const getNotesData = async () => {
    let data = {
      search: "",
      course_id: location.state ? crsID : courseDetails[0].course_id,
      subject_id: subjectData.subjectId,
      topic_id: topicData.topicId,
    };

    startLoading();
    try {
      const response = await axios.get(
        `${url_13}?page=1&search=${data.search}&course_id=${data.course_id}&subject_id=${data.subject_id}&topic_id=${data.topic_id}`
      );

      if (response.data.status === 200) {
        finishLoading();
        setState((prevState) => ({
          ...prevState,
          notesData: response.data.data,
        }));
      }
    } catch (error) {
      finishLoading();
      console.log(error);
    }
  };

  ////// DPP //////////

  const getDppList = async () => {
    let data = {
      course_id: location.state ? crsID : courseDetails[0].course_id,
      type: "DPP",
      topic_id: topicData.topicId,
    };
    startLoading();
    try {
      const response = await axios.post(url_33, data);
      if (response.data.status === 200) {
        finishLoading();
        setState((prevState) => ({
          ...prevState,
          dppCollections: response.data.data,
        }));
      }
    } catch (error) {
      finishLoading();
      console.log(error);
    }
  };

  const handleLiveClassTabChange = (type) => {
    getRecordedLiveClassData();
    if (type === "upcoming") {
      let classesType = "1";
      setState((prevState) => ({ ...prevState, pageNumber: 1 }));
      getLiveClassesData(classesType);
    } else if ("previous") {
      let classesType = "0";
      setState((prevState) => ({ ...prevState, pageNumber: 1 }));
      getLiveClassesData(classesType);
    }
  };

  const handleRedirect = () => {
    history.push({
      pathname: "/subject_component",
      courseId: location.state,
    });
  };

  const handleNextBtn = () => {
    let { featureType, pageNumber } = state;

    switch (featureType) {
      case 1:
        // nextVideos(pageNumber);
        break;
      case 3:
        nextExercises(pageNumber);
        break;
      case 4:
        nextNotes(pageNumber);
      default:
        break;
    }
  };

  const handlePrevBtn = () => {
    let { featureType, pageNumber } = state;

    switch (featureType) {
      case 1:
        // prevVideos(pageNumber);
        break;
      case 3:
        prevExercises(pageNumber);
        break;
      case 4:
        prevNotes(pageNumber);
      default:
        break;
    }
  };
  ///////////////////////////Exercises//////////////////////
  const nextExercises = async (pageNumber) => {
    let data = {
      course_id: courseDetails[0].course_id,
      topic_id: topicData.topicId,
      pageNumber: state.pageNumber + 1,
    };
    startLoading();
    try {
      const response = await axios.post(url_33, data);

      if (response.data.status === 200) {
        finishLoading();
        if (response.data.data.ExerciseList.data.length) {
          setState((prevState) => ({
            ...prevState,
            exerciseData: response.data.data,
            pageNumber: pageNumber + 1,
          }));
        }
      }
    } catch (error) {
      finishLoading();
      console.log(error);
    }
  };

  const prevExercises = async (pageNumber) => {
    let data = {
      course_id: courseDetails[0].course_id,
      topic_id: topicData.topicId,
      pageNumber: state.pageNumber - 1,
    };

    if (pageNumber > 1) {
      startLoading();
      try {
        const response = await axios.post(url_33, data);
        if (response.data.status === 200) {
          finishLoading();
          if (response.data.data.ExerciseList.data.length) {
            setState((prevState) => ({
              ...prevState,
              exerciseData: response.data.data,
              pageNumber: pageNumber - 1,
            }));
          }
        }
      } catch (error) {
        finishLoading();
        console.log(error);
      }
    }
  };

  //////////////////////////Notes/////////////
  const nextNotes = async (pageNumber) => {
    let data = {
      search: "",
      course_id: courseDetails[0].course_id,
      subject_id: subjectData.subjectId,
      topic_id: topicData.topicId,
    };
    startLoading();
    try {
      const response = await axios.get(
        `${url_13}?page=${pageNumber + 1}&search=${data.search}&course_id=${
          data.course_id
        }&subject_id=${data.subject_id}&topic_id=${data.topic_id}`
      );

      if (response.data.status === 200) {
        finishLoading();
        if (response.data.data.NotesList.data.length) {
          setState((prevState) => ({
            ...prevState,
            notesData: response.data.data,
            pageNumber: pageNumber + 1,
          }));
        }
      }
    } catch (error) {
      finishLoading();
      console.log(error);
    }
  };

  const prevNotes = async (pageNumber) => {
    let data = {
      search: "",
      course_id: courseDetails[0].course_id,
      subject_id: subjectData.subjectId,
      topic_id: topicData.topicId,
    };

    if (pageNumber > 1) {
      startLoading();
      try {
        const response = await axios.get(
          `${url_13}?page=${pageNumber - 1}&search=${data.search}&course_id=${
            data.course_id
          }&subject_id=${data.subject_id}&topic_id=${data.topic_id}`
        );
        if (response.data.status === 200) {
          finishLoading();
          if (response.data.data.NotesList.data.length) {
            setState((prevState) => ({
              ...prevState,
              notesData: response.data.data,
              pageNumber: pageNumber - 1,
            }));
          }
        }
      } catch (error) {
        finishLoading();
        console.log(error);
      }
    }
  };
  /////////////////live Class/////////////////
  const handleLiveClassPrevBtn = async (type) => {
    let { pageNumber } = state;
    let data = {
      search: "",
      course_id: courseDetails[0].course_id,
      batch_id: batchDetails.batch_id,
      subject_id: subjectData.subjectId,
      topic_id: topicData.topicId,
      classes_type: type,
    };
    if (pageNumber > 1) {
      startLoading();
      try {
        const response = await axios.post(
          `${url_12}?page=${pageNumber + 1}`,
          data
        );

        if (response.data.status === 200) {
          finishLoading();
          if (response.data.data.LiveClassesList.data.length) {
            setState((prevState) => ({
              ...prevState,
              liveClassesData: response.data.data,
              pageNumber: pageNumber + 1,
            }));
          }
        }
      } catch (error) {
        finishLoading();
        console.log(error);
      }
    }
  };

  const handleLiveClassNextBtn = async (type) => {
    let { pageNumber } = state;
    let data = {
      search: "",
      course_id: courseDetails[0].course_id,
      batch_id: batchDetails.batch_id,
      subject_id: subjectData.subjectId,
      topic_id: topicData.topicId,
      classes_type: type,
    };
    startLoading();
    try {
      const response = await axios.post(
        `${url_12}?page=${pageNumber + 1}`,
        data
      );

      if (response.data.status === 200) {
        finishLoading();
        if (response.data.data.LiveClassesList.data.length) {
          setState((prevState) => ({
            ...prevState,
            liveClassesData: response.data.data,
            pageNumber: pageNumber + 1,
          }));
        }
      }
    } catch (error) {
      finishLoading();
      console.log(error);
    }
  };

  return (
    <Fragment>
      <div className="topicContentComponent_wrapper">
        <div className="container">
          <div className="topicContentComponent_inner_wrapper">
            <div className="sec_1_wrapper">
              <div className="sec_1_inner_wrapper">
                <div className="btn_wrapper">
                  <button onClick={() => handleRedirect()}>
                    <img src={images.user_activity_1} />
                  </button>
                </div>
                <div className="text_content_wrapper">
                  <p className="text_content" style={{ marginTop: "0.1px" }}>
                    {topicData.topicName}
                  </p>
                </div>
              </div>
            </div>
            <div className="sec_2_wrapper">
              <div className="sec_2_inner_wrapper">
                <div className="content_wrapper">
                  <div className="left_side_content_wrapper">
                    <div className="feature_collection_wrapper">
                      {topicData.topicVideo ? (
                        <div className="single_select_feature">
                          <input
                            type="radio"
                            id="videos"
                            name="feature"
                            value="videos"
                            onChange={(event) => handleChangeFeature(event)}
                            checked={state.featureType === 1}
                          />
                          <label htmlFor="videos">Videos</label>
                        </div>
                      ) : null}
                      {topicData.is_live_class_available ? (
                        <div className="single_select_feature">
                          <input
                            type="radio"
                            id="live_class"
                            name="feature"
                            value="live_class"
                            onChange={(event) => handleChangeFeature(event)}
                            checked={state.featureType === 2}
                          />
                          <label htmlFor="live_class">Live class</label>
                        </div>
                      ) : null}
                      {topicData.is_excersice_available ? (
                        <div className="single_select_feature">
                          <input
                            type="radio"
                            id="exercise"
                            name="feature"
                            value="exercise"
                            onChange={(event) => handleChangeFeature(event)}
                            checked={state.featureType === 3}
                          />
                          <label htmlFor="exercise">Exercise</label>
                        </div>
                      ) : null}

                      {topicData.is_dpp_available ? (
                        <div className="single_select_feature">
                          <input
                            type="radio"
                            id="dpp"
                            name="feature"
                            value="dpp"
                            onChange={(event) => handleChangeFeature(event)}
                            checked={state.featureType === 5}
                          />
                          <label htmlFor="dpp">DPP</label>
                        </div>
                      ) : null}

                      {topicData.is_notes_available ? (
                        <div className="single_select_feature">
                          <input
                            type="radio"
                            id="notes"
                            name="feature"
                            value="notes"
                            onChange={(event) => handleChangeFeature(event)}
                            checked={state.featureType === 4}
                          />
                          <label htmlFor="notes">Notes</label>
                        </div>
                      ) : null}

                      {topicData.is_additional_video_available ? (
                        <div className="single_select_feature">
                          <input
                            type="radio"
                            id="additional_videos"
                            name="feature"
                            value="additional_videos"
                            onChange={(event) => handleChangeFeature(event)}
                            checked={state.featureType === 6}
                          />
                          <label htmlFor="additional_videos">
                            Additional Videos
                          </label>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="right_side_content_wrapper">
                    {renderFetaures()}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  // console.log("feature----------->", state.userActivity.feature);
  return {
    userPreference: state.userPreference,
    subjectData: state.userActivity.subjectData,
    topicData: state.userActivity.topicData,
    token: state.auth.authToken,
    tokenType: state.auth.tokenType,

    // feature: state.userActivity.feature,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => {
      dispatch(uiActions.startLoading());
    },
    finishLoading: () => {
      dispatch(uiActions.finishLoading());
    },
    // setFeature: (payload) => {
    //   dispatch(userActivityActions.setFeature(payload));
    // },
    setVideoData: (payload) => {
      dispatch(userActivityActions.setVideoData(payload));
    },
    setRecordedVideoData: (payload) => {
      dispatch(userActivityActions.setRecordedVideoData(payload));
    },
    setNotesData: (payload) => {
      dispatch(userActivityActions.setNotesData(payload));
    },
    setLiveStreamData: (payload) => {
      dispatch(userActivityActions.setLiveStreamData(payload));
    },
    setExerciseData: (payload) => {
      dispatch(userActivityActions.setExerciseData(payload));
    },
    setLiveStreamVideoData: (payload) => {
      dispatch(userActivityActions.setLiveStreamVideoData(payload));
    },

    setLiveVideoStreamDetailsAction: (payload) => {
      dispatch(setLiveVideoStreamDetails(payload));
    },

    setWatchedVideoDetailsAction: (payload) => {
      dispatch(setWatchedVideoDetails(payload));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TopicContentComponent);
