import React from "react";
import "./QuestionClassWiseTable.scss";

const QuestionClassWiseTable = (props) => {
  let { questionClassWise, classFrom } = props;

  const filteredMarks = questionClassWise.filter(
    (mark) => mark.total_questions !== 0
  );
  const chemistrySubject = filteredMarks.find(
    (mark) => mark.subject === "Chemistry"
  );

  return (
    <div className="questionClassWise">
      <div className="section-heading">Question’s Class-wise</div>
      <div className="content">
        <section className="table_section">
          <table style={{ width: "100%" }}>
            <thead className="table_heading">
              <tr>
                <th className="firstTd">Questions from</th>
                {filteredMarks.map((mark) => (
                  <React.Fragment key={mark.subject}>
                    {mark.subject === "Chemistry" ? (
                      <th
                        className="chem"
                        colSpan={chemistrySubject.subsubject.length + 1}
                      >
                        <div>{mark.subject}</div>
                        <div className="line"></div>
                        {chemistrySubject.subsubject.length > 0 && (
                          <div className="sub_subjects">
                            {chemistrySubject.subsubject.map((sub) => (
                              <span key={sub.sub_subject}>
                                {sub.sub_subject}
                              </span>
                            ))}
                          </div>
                        )}
                      </th>
                    ) : (
                      <th>{mark.subject}</th>
                    )}
                  </React.Fragment>
                ))}
              </tr>
            </thead>

            <tbody>
              <tr>
                <td className="firstTd">Class {classFrom}</td>
                {filteredMarks.map((mark) => (
                  <td key={mark.subject}>
                    {mark.subject === "Chemistry" ? (
                      <div className="chemistrySubSubjects">
                        {chemistrySubject.subsubject.map((sub) => (
                          <span className="num" key={sub.sub_subject}>
                            {sub.total_questions}
                          </span>
                        ))}
                      </div>
                    ) : (
                      <div className="num">{mark.total_questions}</div>
                    )}
                  </td>
                ))}
              </tr>
            </tbody>
          </table>
        </section>
      </div>
    </div>
  );
};

export default QuestionClassWiseTable;
