import { Fragment, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import "./login_step_one2.scss";

import images from "../../../utilities/images/images";
const LoginStepOne = (props) => {
  let { phoneDetails } = props;

  const [clickCount, setClickCount] = useState(0);
  const [showCaptcha, setShowCaptcha] = useState(false);
  const [captchaVerified, setCaptchaVerified] = useState(false);
  const siteKey = "6Lcx-yAoAAAAAIqP73W-JK46kaKq-JGkJ533JvBA";

  const handleSendOTPClick = (event) => {
    if (clickCount >= 4) {
      setShowCaptcha(true);
    } else {
      props.submitMobileNumber(event);
    }
    setClickCount(clickCount + 1);
  };

  const handleCaptchaSubmit = (event) => {
    event.preventDefault();
    if (captchaVerified) {
      setClickCount(0);
      setShowCaptcha(false);
    }
  };

  const handleCaptchaVerify = (response) => {
    if (response) {
      setCaptchaVerified(true); // Set to true when captcha is verified
    } else {
      setCaptchaVerified(false);
    }
  };

  const handleMoveToNext = (current, nextFieldId) => {
    // console.log(current, nextFieldId);
    if (
      current.target.value.length ===
      parseInt(current.target.attributes["maxlength"].value)
    ) {
      document.getElementById(nextFieldId).focus();
    }
  };
  return (
    <Fragment>
      <div className="login_step_one2_wrapper">
        <div className="login2_left_part">
          <div className="logo">
            <img src={images.m2_logo} alt="logo" />
          </div>
        </div>
        <div className="login2_right_part">
          <div className="signup_form">
            <form
              onSubmit={showCaptcha ? handleCaptchaSubmit : handleSendOTPClick}
            >
              <div className="image_container">
                <img src={images.login_1} />
              </div>
              <h3>Signup</h3>
              <p>Please share your number for verification.</p>
              <div className="input_wrapper">
                <input
                  type="number"
                  name="phone_no"
                  maxLength="10"
                  pattern="^\d{10}$"
                  title="Only Ten Digit mobile number, no alphabet"
                  placeholder="Enter your mobile no."
                  value={phoneDetails.mobile_number}
                  onKeyUp={(event) => handleMoveToNext(event, "send_otp_btn")}
                  onChange={(event) => props.handleChangeMobileNumber(event)}
                  required
                />
              </div>
              {/* <div className="btn_wrapper">
                <button id="send_otp_btn" type="submit">
                  Send OTP
                </button>
              </div> */}
              <div className="btn_wrapper">
                {showCaptcha && (
                  <div className="captcha-container mb-4">
                    <ReCAPTCHA
                      sitekey={siteKey}
                      onChange={handleCaptchaVerify}
                    />
                  </div>
                )}

                <button
                  id="send_otp_btn"
                  type="button"
                  onClick={handleSendOTPClick}
                  disabled={showCaptcha || (showCaptcha && !captchaVerified)}
                >
                  Send OTP
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default LoginStepOne;
