import { React, useEffect, useState } from "react";
import "./AdaptiveCpsSubjects.scss";
import { isEmpty } from "../../../../../../../../custom_utilities/custom_useful_functions";
import { url_83 } from "../../../../../../../../custom_utilities/api_services";
import images from "../../../../../../../../utilities/images/images";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";
//Redux
import { connect } from "react-redux";

import uiActions from "../../../../../../../../redux/ui/action";

// import adaptiveCpsActions from "../../../../../../redux/adaptive_cps/action";

import adaptiveCpsActions from "../../../../../../../../redux/adaptive_cps/action";

const AdaptiveCpsSubjects = (props) => {
  let {
    userPreference,
    handleSubjectClick,
    handleSubjectClick2,
    is_subscribe,
    startLoading,
    finishLoading,
    CommonBannerData,
  } = props;
  let history = useHistory();
  const [state, setState] = useState({
    topicSubjectList: {},
  });

  useEffect(() => {
    getTopicSubjectList();
  }, []);

  //subjectList
  const getTopicSubjectList = async () => {
    let { courseDetails, classDetails } = userPreference;

    let data = {
      course_id: courseDetails[0].course_id,
      class_id: classDetails.class_id,
    };
    startLoading();
    try {
      const response = await axios.post(
        "https://learning.motion.ac.in/motioneducation/api/user/UserSubjectACPS",
        data
      );
      if (response.data.status === 200) {
        finishLoading();
        setState((prevState) => ({
          ...prevState,
          topicSubjectList: response.data.data,
        }));
      }
    } catch (error) {
      finishLoading();
      console.log(error);
    }
  };

  let { subject: subjectList } = state.topicSubjectList;

  const handleSubjectClickBoth = (sub_name) => {
    if(!CommonBannerData.packageActive) {
      history.push({
        pathname: "/store", state: { tab: "online_learning" }
      })
    } else {
      if (is_subscribe === true) {
      console.log("subscribe");
      handleSubjectClick2(sub_name);
    } else {
      handleSubjectClick(sub_name);
    }}
  };

  return (
    <>
      <div className="ACpsTopicWiseWrapper justify-content-between">
        <div className="section-heading col-12">Choose a Subject</div>
        {!isEmpty(subjectList)
          ? subjectList.length
            ? subjectList.map((elem, index) => {
                return (
                  <div
                    key={index}
                    className="ACpsTopicBox"
                    onClick={() => handleSubjectClickBoth(elem.sub_name)}
                  >
                    <div className="ACpsTopicNameImg">
                      <div className="ACpsImg_wrp">
                        <img src={elem.icon} alt="icon" />
                      </div>
                      <div className="name">{elem.sub_name}</div>
                    </div>

                    <div className="arrow">
                      <img src={images.right_Ar} alt="arrow" />
                    </div>
                  </div>
                );
              })
            : null
          : null}
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    userPreference: state.userPreference,
    CommonBannerData: state.userActivity.commonBannerData,
  };
};

const mapDipatchToProps = (dispatch) => {
  return {
    startLoading: () => {
      dispatch(uiActions.startLoading());
    },
    finishLoading: () => {
      dispatch(uiActions.finishLoading());
    },

    setAdaptiveCpsSubjectAction: (payload) => {
      dispatch(adaptiveCpsActions.setAdaptiveCpsSubject(payload));
    },
  };
};

export default connect(mapStateToProps, mapDipatchToProps)(AdaptiveCpsSubjects);
