import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import images from "../../../../../utilities/images/images";
import HeaderComponent from "../../../structure_components/desktop_screens/header_component/header_component";
import axios from "axios";
import { isEmpty } from "../../../../../custom_utilities/custom_useful_functions";
import { connect } from "react-redux";
import actions from "../../../../../redux/ui/action";
import actions2 from "../../../../../redux/online-test_series/action";
import InstructionModal from "../ots_tests/ots_test_content_component/ots_test_sps/modals/InstructionModal";
import SyllabusModal from "../ots_tests/ots_test_content_component/ots_test_sps/modals/SyllabusModal";
import { url_128 } from "../../../../../custom_utilities/api_services";
import LoaderPopUp from "../../../../../components/loader/loader";
import { Button } from "react-bootstrap";

import "./OtsAnalytics.scss";

const OtsAnalytics = (props) => {
  const {
    token,
    tokenType,
    class_id,
    target_id,
    setOtsTestsResultData,
    startLoading,
    finishLoading,
    loader,
  } = props;
  const history = useHistory();
  const [viewAnalyticsTests, setViewAnalyticsTests] = useState([]);
  const [test, setTest] = useState({});

  const [instructionModalFlag, setInstructionModalFlag] = useState(false);
  const [syllabusModalFlag, setSyllabusModalFlag] = useState(false);

  const [hasMore, setHasMore] = useState(true); // Track if there is more data to load
  const [pageNo, setPageNo] = useState(1); // Track the current page number

  useEffect(() => {
    getAnalyticsTests();
  }, []);

  const getAnalyticsTests = async () => {
    let page_limit = 5;

    let pageNo = Math.ceil(viewAnalyticsTests?.length / page_limit) + 1;

    const headers = {
      Authorization: `${tokenType} ${token}`,
    };

    const data = {
      target: target_id,
      class: class_id,
      page: pageNo,
    };

    startLoading();

    try {
      const response = await axios.post(url_128, data, { headers });

      console.log("response", response);
      if (response.data.status == 200) {
        finishLoading();
        setHasMore(response.data.data.paper.data.length === page_limit);
        setViewAnalyticsTests([
          ...viewAnalyticsTests,
          ...response.data.data.paper.data,
        ]);

        setPageNo(pageNo + 1);
      }
    } catch (error) {
      console.log(error);
      finishLoading();
    }
  };

  const handleRedirect = async (test) => {
    const { solution_visible_at, is_show_solution, target_type, papers } = test;

    if (!solution_visible_at || solution_visible_at.length === 0) {
      alert("Analytics or Solutions will be shown after Some Time");
      return;
    }

    if (is_show_solution > 0) {
      if (target_type === "JEE (A)" && papers === null) {
        alert("Analytics or Solutions will be shown after Some Time");
        return;
      }

      setOtsTestsResultData(test);
      history.push({
        pathname: "/ots_test_result",
      });
    } else {
      alert(
        `Analytics or Solutions will be shown after ${solution_visible_at}`
      );
    }
  };

  const handleInstructionModal = (test) => {
    setTest(test);

    setInstructionModalFlag(true);
  };

  const handleSyllabusModal = (test) => {
    setTest(test);

    setSyllabusModalFlag(true);
  };

  const fetchMoreData = () => {
    if (hasMore) {
      getAnalyticsTests();
    }
  };

  console.log("viewAnalyticsTests", viewAnalyticsTests);

  return (
    <>
      {instructionModalFlag && (
        <InstructionModal
          setInstructionModalFlag={setInstructionModalFlag}
          test={test}
          startLoading={startLoading}
          finishLoading={finishLoading}
        />
      )}

      {syllabusModalFlag && (
        <SyllabusModal
          setSyllabusModalFlag={setSyllabusModalFlag}
          test={test}
          startLoading={startLoading}
          finishLoading={finishLoading}
        />
      )}

      <div
        id="scrollableDiv"
        style={{
          height: "70vh",
          overflow: "auto",
          overflowY: "scroll",
        }}
      >
        <InfiniteScroll
          dataLength={viewAnalyticsTests.length}
          next={fetchMoreData}
          hasMore={true}
          scrollableTarget="scrollableDiv"
        >
          <div className="OtsAnalytics_container">
            {!isEmpty(viewAnalyticsTests) ? (
              viewAnalyticsTests.length ? (
                viewAnalyticsTests.map((test, index) => {
                  return (
                    <div className="OtsAnalytics_test_item">
                      <div className="OtsAnalytics_test_heading">
                        <div className="OtsAnalytics_test_name">
                          {test.test_name}
                        </div>

                        <div className="OtsAnalytics_test_icons">
                          <div
                            className="OtsAnalytics_test_instruction_icon"
                            onClick={() => handleInstructionModal(test)}
                          >
                            <img
                              src={images.user_activity_19}
                              alt="instruction icon"
                            />
                          </div>

                          <div
                            className="OtsAnalytics_test_syllabus_icon"
                            onClick={() => handleSyllabusModal(test)}
                          >
                            <img
                              src={images.user_activity_20}
                              alt="syllabus icon"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="OtsAnalytics_test_que_mins_marks">
                        <ul>
                          <li>{test.total_questions} Questions, </li>
                          <li> {test.test_duration_min} mins, </li>
                          <li> {test.max_marks} marks</li>
                        </ul>
                      </div>

                      <div className="OtsAnalytics_test_attemptedDate">
                        <p>Attempted on- {test.attempted_on}</p>
                      </div>

                      <div className="OtsAnalytics_test_buttons d-grid">
                        <Button
                          variant={
                            test.is_show_solution > 0 &&
                            (test.target_type == "JEE (A)"
                              ? test.papers !== null
                              : true)
                              ? "custom"
                              : "secondary"
                          }
                          className={`${
                            test.is_show_solution > 0 &&
                            (test.target_type == "JEE (A)"
                              ? test.papers !== null
                              : true)
                              ? "OtsAnalytics_test_ViewAnalytics_button"
                              : "OtsAnalytics_test_ViewAnalytics_button_fade"
                          }`}
                          onClick={() => handleRedirect(test)}
                        >
                          View Analytics
                        </Button>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="ots_no_data_found_wrapper">
                  <img src={images.m2_video_ntF} />
                  <p>There is no Analytics yet!</p>
                </div>
              )
            ) : null}
          </div>
        </InfiniteScroll>
      </div>

      {loader && <LoaderPopUp />}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.authToken,
    tokenType: state.auth.tokenType,
    class_id: state.auth.user.class_id,
    target_id: state.userPreference.targetDetails[0].target_id,
    loader: state.ui.loader,
    user_id: state.auth.user.user_id,
    otsTestsData: state.otsTestsData.otsTestsData,
  };
};

const mapDispatchToProps = (dispatch) => ({
  startLoading: () => {
    dispatch(actions.startLoading());
  },

  finishLoading: () => {
    dispatch(actions.finishLoading());
  },

  setOtsTestsResultData: (payload) => {
    dispatch(actions2.setOtsTestsResultData(payload));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(OtsAnalytics);
