import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import "./PackageOverview.scss";
import HeaderComponent from "../../../../../structure_components/desktop_screens/header_component/header_component";
import images from "../../../../../../../utilities/images/images";
import PriceAndPurchase from "./PriceAndPurchase";
import { Link } from "react-router-dom";
import { setRefferalCode } from "../../../../../../../redux/packages_store/PackageSlice";
import MobileLeftSideBarComponent from "../../../../../structure_components/mobile_screens/mobile_left_sideBar_component/mobile_left_sideBar_component";
import Mobile_header_component from "../../../../../structure_components/mobile_screens/mobile_header_component/mobile_header_component";
import Mobile__right_sideBar_component from "../../../../../structure_components/mobile_screens/mobile_right_sideBar_component/mobile__right_sideBar_component";

const PackageOverview = ({ package_overview, setRefferalCodeAction,history,userPreference,userData }) => {
  const { package_name, description } = package_overview;

  useEffect(() => {
    // Scroll to the top of the page after a short delay
    const scrollToTop = () => {
      setTimeout(() => {
        document.documentElement.scrollTop = 0;
        document.body.scrollTop = 0;
      }, 0);
    };

    // Scroll to the top when the component mounts
    scrollToTop();

    // Restore original scroll behavior when the component unmounts
    return () => {
      document.documentElement.scrollTop = 0;
      document.body.scrollTop = 0;
    };
  }, []);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const setWidth = () => {
    setWindowWidth(window.innerWidth)
  }
  useEffect(() => {
    window.addEventListener( 'resize',setWidth)

    return (() => {
      window.removeEventListener('resize',setWidth)
    })
  },[windowWidth])

  return (
    <>
      {/* <HeaderComponent /> */}
      {windowWidth <= 480 ? (
            /////for Mobile Screens////////////////////
            <Fragment>
              <MobileLeftSideBarComponent />
              <Mobile_header_component
                userPreference={userPreference}
                userData={userData}
              />
              <Mobile__right_sideBar_component history={history} />
            </Fragment>
          ) : (
            /////for Screens greater than Mobile Screens////////////////////
            <Fragment>
                <HeaderComponent history={history} />
            </Fragment>
          )}

      <div className="package-details-container">
        <div className="bck-btn-course-overview flex-vertical-center">
          <Link to={"/store"}>
            <img src={images.m2_backBtn} alt="" />
          </Link>

          <h3>Course Overview</h3>
        </div>

        <div className="package-details-wrapper flex">
          <div className="package-details-inner-wrapper flex">
            <div className="package-left-wrapper">
              <div className="package-heading">
                <div className="package-title">
                  <img src={images.store_activiy_1} alt="heading-icon" />
                  <p className="package-title-name">{package_name}</p>
                </div>

                <p className="package-descrp">{description}</p>

                <div className="package-tags">
                  <ul className="package-tags-list">
                    <li>Learn</li>
                    <li>Practice</li>
                    <li>Revise</li>
                    <li>Examine</li>
                    <li>Analyze</li>
                  </ul>
                </div>
              </div>

              <div className="course-features-wrapper">
                <div className="course-features-inner-wrapper">
                  <h2>Course Features</h2>
                  <ul className="course-features-list flex">
                    <li className="course-feature-list-item">
                      <img src={images.store_traning} alt="traning-icon" />
                      <p>100+ Video Lectures of class 11th</p>
                    </li>
                    <li className="course-feature-list-item">
                      <img src={images.store_learning} alt="learning-icon" />
                      <p>Daily Practice Papers (DPP’s)</p>
                    </li>
                    <li className="course-feature-list-item">
                      <img src={images.store_report} alt="report-icon" />
                      <p>e Study Material (PDF)</p>
                    </li>
                    <li className="course-feature-list-item">
                      <img src={images.store_e_learning} alt="elearning-icon" />
                      <p>All India Test Series (AITS)</p>
                    </li>
                    <li className="course-feature-list-item">
                      <img src={images.store_video_sol} alt="videosol-icon" />
                      <p>Text & Video Solutions</p>
                    </li>
                    <li className="course-feature-list-item">
                      <img src={images.store_analytics} alt="analytics-icon" />
                      <p>Student Performance Report (SPR)</p>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="amrit">
                <img
                  src={images.amrit_gurantee}
                  className="amrit-image"
                  alt="amrit-image"
                />
              </div>
            </div>
            <div className="package-right-wrapper flex-hori-center">
              <PriceAndPurchase
                package_overview={package_overview}
                setRefferalCodeAction={setRefferalCodeAction}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {

  return {
    package_overview: state.PackageSliceReducer.storeCurrentPackageDetails,
    userData: state.auth.user,
    userPreference: state.userPreference,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setRefferalCodeAction: (payload) => {
    dispatch(setRefferalCode(payload));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PackageOverview);
