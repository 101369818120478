import React, { useState, useEffect } from "react";
import images from "../../../../utilities/images/images";
import "./bit_sat.scss";
import axios from "axios";
import { url_145 } from "../../../../custom_utilities/api_services";
import { isEmpty } from "../../../../custom_utilities/custom_useful_functions";
import { useHistory } from "react-router-dom";
import uiActions from "../../../../redux/ui/action";
import bitSatActions from "../../../../redux/bit_sat_test_exercises/action";
import { connect } from "react-redux";

const BitSat = (props) => {
  let { startLoading, finishLoading, loader, setBitSatTestsData } = props;
  const [state, setState] = useState({
    mockTest: [],
  });

  let history = useHistory();

  useEffect(() => {
    getMockTests();
  }, []);

  const getMockTests = async () => {
    try {
      const response = await axios.post(url_145);
      if (response.data.status == 200) {
        setState((prevState) => ({
          ...prevState,
          mockTest: response.data.data,
        }));
      }
    } catch (error) {
      console.log("error");
    }
  };

  const handleTest = (testData) => {
    setBitSatTestsData(testData);
    history.push({
      pathname: "/bit_sat_test_component",
      state: { testData: testData },
    });
  };

  return (
    <>
      <div className="bitSat_wrapper">
        {!isEmpty(state.mockTest) ? (
          <h3 className="bitSatTestheading">Select Test</h3>
        ) : null}
        <div className="bitSat_InrWrapper">
          <div className="bitSat_WiseWrapper">
            {!isEmpty(state.mockTest) ? (
              state.mockTest.length ? (
                state.mockTest.map((elem, index) => {
                  return (
                    <div
                      className="bitSat_box"
                      key={index}
                      onClick={() => handleTest(elem)}
                    >
                      <div className="mock_test_content">
                        <div className="img_wrpr">
                          <img
                            src={images.mock_test_bit_sat_1}
                            alt="jee-main"
                          />
                        </div>

                        <div className="content">
                          <div className="mock_test_heading">
                            {elem.paper_name}
                          </div>
                          <div className="total_questions_time">
                            <div className="total_question">
                              <span>
                                {" "}
                                <img
                                  src={images.user_activity_30}
                                  alt="jee-main"
                                />
                              </span>
                              <span>{elem.total_questions}</span>
                              <span>Questions</span>
                            </div>
                            <div className="total_time">
                              <span>
                                {" "}
                                <img
                                  src={images.user_activity_31}
                                  alt="jee-main"
                                />
                              </span>
                              <span>{elem.duration_mins}</span>
                              <span>Mins</span>
                            </div>
                          </div>
                        </div>

                        <div className="arrow">
                          <img src={images.right_Ar} alt="arrow" />
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : null
            ) : (
              <div className="bitsat_no_data_found_wrapper">
                <img src={images.m2_video_ntF} />
                <p>There is no tests yet!</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loader: state.ui.loader,
    subjectData: state.userActivity.subjectData,
    userPreference: state.userPreference,
  };
};

const mapDipatchToProps = (dispatch) => {
  return {
    startLoading: () => {
      dispatch(uiActions.startLoading());
    },
    finishLoading: () => {
      dispatch(uiActions.finishLoading());
    },
    setBitSatTestsData: (payload) => {
      dispatch(bitSatActions.setBitSatTestsData(payload));
    },
  };
};

export default connect(mapStateToProps, mapDipatchToProps)(BitSat);
