import { Fragment } from "react";
import "./chat_footer.scss";

import images from "../../../../../../../utilities/images/images";

const ChatFooter = (props) => {
  let {
    messageDetails,
    handleChangeInputField,
    handleChatSubmit,
    handleImageUpload,
    handleMediaDiscard,
    handleMediaAccept,
  } = props;
  return (
    <Fragment>
      <div className="chat_footer_wrapper">
        <div className="chat_footer_inner_wrapper">
          {messageDetails.localImageURL.length ? (
            <div className="media_showing_wrapper">
              <div className="media_wrapper">
                <img src={messageDetails.localImageURL} />
              </div>
              <div className="media_action_btn_wrapper">
                <div className="btn_wrapper">
                  <button type="button" onClick={() => handleMediaDiscard()}>
                    <img src={images.user_activity_7} />
                  </button>
                </div>
                <div className="btn_wrapper">
                  <button type="button" onClick={() => handleMediaAccept()}>
                    <img src={images.user_activity_8} />
                  </button>
                </div>
              </div>
            </div>
          ) : null}

          <div className="chat_sending_actions_wrapper">
            <div className="left_side_content_wrapper">
              <div className="image_upload_wrapper">
                <label className="custom_file_upload">
                  <input
                    type="file"
                    name="chat_image"
                    id="upload_image"
                    accept="image/*"
                    onChange={(event) => handleImageUpload(event)}
                  />
                  <img src={images.user_chat_1} />
                </label>
              </div>
            </div>
            <div className="right_side_content_wrapper">
              <div className="form_wrapper">
                <form onSubmit={(event) => handleChatSubmit(event)}>
                  <div className="input_wrapper">
                    <input
                      type="text"
                      placeholder="Type here..."
                      value={messageDetails.msg}
                      onChange={(event) => handleChangeInputField(event)}
                    />
                  </div>
                  <div className="btn_wrapper">
                    <button type="submit">
                      <img src={images.user_chat_2} />
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ChatFooter;
