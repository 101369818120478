import React from "react";
import "./TestSummaryTabel.scss";

const TestSummaryTable = (props) => {
  let { testReport } = props;

  return (
    <div className="testSummary">
      <div className="section-heading">Test Summary</div>
      <div className="content">
        <div className="table_section">
          <table style={{ width: "100%" }}>
            <thead className="table_heading">
              <tr>
                <td className="firstTd">Analysis</td>
                {testReport?.marks?.map((item) => {
                  return <td>{item.subject}</td>;
                })}
              </tr>
            </thead>

            <tbody>
              <tr>
                <td className="firstTd">Total Questions</td>
                {testReport?.marks?.map((elem, key) => (
                  <td key={key} className="num">
                    {elem.total_questions}
                  </td>
                ))}
              </tr>

              <tr>
                <td className="firstTd">Attempted Questions</td>
                {testReport?.marks?.map((elem, key) => (
                  <td key={key} className="num">
                    {elem.attempted_questions}
                  </td>
                ))}
              </tr>

              <tr>
                <td className="firstTd">Correct Questions</td>
                {testReport?.marks?.map((elem, key) => (
                  <td key={key} className="num">
                    {elem.correct_questions}
                  </td>
                ))}
              </tr>

              <tr>
                <td className="firstTd">Incorrect Questions</td>
                {testReport?.marks?.map((elem, key) => (
                  <td key={key} className="num">
                    {elem.incorrect_questions}
                  </td>
                ))}
              </tr>

              <tr>
                <td className="firstTd">Negative Marks</td>
                {testReport?.marks?.map((elem, key) => (
                  <td key={key} className="num">
                    {elem.negative_marks.negative_marks}
                  </td>
                ))}
              </tr>

              <tr>
                <td className="firstTd">Unattempt Questions</td>
                {testReport?.marks?.map((elem, key) => (
                  <td key={key} className="num">
                    {elem.unattempted_questions}
                  </td>
                ))}
              </tr>

              <tr>
                <td className="firstTd">Tried not submitted</td>
                {testReport?.marks?.map((elem, key) => (
                  <td key={key} className="num">
                    {elem.tried_not_submitted}
                  </td>
                ))}
              </tr>

              <tr>
                <td className="firstTd">Not read/Tried</td>
                {testReport?.marks?.map((elem, key) => (
                  <td key={key} className="num">
                    {elem.not_read_tried}
                  </td>
                ))}
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default TestSummaryTable;
