import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  refundCurrentPackage: {},
};

const RefundPackageSlice = createSlice({
  name: "refund",
  initialState,
  reducers: {
    setCurrentRefundPackage: (state, action) => {
      state.refundCurrentPackage = action.payload;
    },

    clearRefundPackage: (state) => {
      state.refundCurrentPackage = {};
    },
  },
});

export const { setCurrentRefundPackage, clearRefundPackage } =
  RefundPackageSlice.actions;

export default RefundPackageSlice.reducer;
