// import { Fragment, useState, useEffect } from "react";

// import "./QuestionBankTestContentComponent.scss";

// import QuestionBankTestInstructionView from "./question_bank_test_intructions/QuestionBankTestInstructionView";
// import QuestionBankTestQuestionsView from "./question_bank_test_questions/QuestionBankTestQuestionsView";

// import {
//   url_153,
//   url_154,
//   url_155,
//   url_156,
// } from "../../../../../custom_utilities/api_services";
// import axios from "axios";
// import { isEmpty } from "../../../../../custom_utilities/custom_useful_functions";

// //Redux
// import { connect } from "react-redux";
// import uiActions from "../../../../../redux/ui/action";

// const QuestionBankTestContentComponent = (props) => {
//   let { history, startLoading, finishLoading, userData, questionBankTestId } =
//     props;
//   const [state, setState] = useState({
//     step: 1,
//     testDetails: {},
//     testInstructionData: {},
//     testQuestionsDetails: [],
//     subjecSectiontWiseTestQuestions: {},
//     checkQuestion: {},
//     currentQuestion: 0,
//     countDownTimer: {
//       hours: 0,
//       minutes: 0,
//       seconds: 0,
//     },
//     initialTime: 0,
//     useTime: 0,
//     left: 0,
//     is_paid: 0,
//     hours: 72,
//     minutes: 0,
//     seconds: 0,
//   });

//   // let topic_name = location.state.subTopic;
//   // const [selectedSubject, setSubject] = useState("");
//   // const [selectedSection, setSection] = useState("");

//   // const handleSubjectChange = (subject) => {
//   //   const section = Object.keys(
//   //     state.subjecSectiontWiseTestQuestions[subject]
//   //   )[0];
//   //   setSubject(subject);
//   //   setSection(section);
//   //   const currentQuestion =
//   //     state.subjecSectiontWiseTestQuestions[subject][section][0]["matchIndex"];
//   //   setState((prevState) => ({
//   //     ...prevState,
//   //     currentQuestion,
//   //   }));

//   //   //setSubject(subject);
//   // };

//   // const handleSectionChange = (section) => {
//   //   setSection(section);
//   //   const currentQuestion =
//   //     state.subjecSectiontWiseTestQuestions[selectedSubject][section][0][
//   //       "matchIndex"
//   //     ];
//   //   setState((prevState) => ({
//   //     ...prevState,
//   //     currentQuestion,
//   //   }));
//   // };

//   useEffect(() => {
//     getinstructionDetail();
//   }, []);

//   // const createCountDownTimer = (duration) => {
//   //   let useTime = state.useTime ?? 0;
//   //   useTime = useTime * 1000;

//   //   //duration  is in minutes, first convert it to milliseconds
//   //   let countdownTime = duration * 60 * 1000 + new Date().getTime();
//   //   countdownTime = countdownTime - useTime;

//   //   timeInterval = setInterval(() => {
//   //     let now = new Date().getTime();
//   //     let distance = countdownTime - now;
//   //     if (distance < 0) {
//   //       clearInterval(timeInterval);
//   //       //hidemyend    //handleSubmitTest();
//   //     }
//   //     let hours = Math.floor(
//   //       (distance % (1000 * 60 * 60 * 60)) / (1000 * 60 * 60)
//   //     );
//   //     let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
//   //     let seconds = Math.floor((distance % (1000 * 60)) / 1000);
//   //     setState((prevState) => ({
//   //       ...prevState,
//   //       countDownTimer: {
//   //         ...prevState.countDownTimer,
//   //         hours,
//   //         minutes,
//   //         seconds,
//   //       },
//   //     }));
//   //   }, 1000);
//   // };
//   //getinstructionDetail
//   const getinstructionDetail = async () => {
//     startLoading();
//     try {
//       const response = await axios.post(url_153);

//       if (response.data.status) {
//         setState((preState) => ({
//           ...preState,

//           testInstructionData: response.data.data,
//         }));
//       }

//       finishLoading();
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   const getTestQuestionsList = async (questionBankTestId) => {
//     let data = {
//       question_bank_id: questionBankTestId,
//     };
//     startLoading();
//     try {
//       const response = await axios.post(url_154, data);

//       if (response.data.status === 200) {
//         finishLoading();
//         return {
//           result: 1,
//           data: { apiData: response.data },
//         };
//       } else {
//         finishLoading();
//         return {
//           result: 0,
//           data: { apiData: {} },
//         };
//       }
//     } catch (error) {
//       console.log(error);
//       finishLoading();
//       return {
//         result: 0,
//         data: { apiData: {} },
//       };
//     }
//   };

//   // const subjectWiseData = (questions) => {
//   //   const subjectSectionWiseData = {};
//   //   for (let i = 0; i < questions.length; i++) {
//   //     questions[i]["matchIndex"] = i;
//   //     if (!subjectSectionWiseData[questions[i]["subject"]]) {
//   //       subjectSectionWiseData[questions[i]["subject"]] = {
//   //         [questions[i]["section_name"]]: [questions[i]],
//   //       };
//   //     } else if (
//   //       subjectSectionWiseData[questions[i]["subject"]] &&
//   //       !subjectSectionWiseData[questions[i]["subject"]][
//   //         questions[i]["section_name"]
//   //       ]
//   //     ) {
//   //       subjectSectionWiseData[questions[i]["subject"]][
//   //         questions[i]["section_name"]
//   //       ] = [questions[i]];
//   //     } else {
//   //       subjectSectionWiseData[questions[i]["subject"]][
//   //         questions[i]["section_name"]
//   //       ].push(questions[i]);
//   //     }
//   //   }
//   //   return subjectSectionWiseData;
//   // };

//   const getNowTime = () => {
//     let d = new Date();
//     //3 days from the time created
//     const date_created = new Date(userData.created_on);
//     const numOfHours = 72;
//     date_created.setTime(date_created.getTime() + numOfHours * 60 * 60 * 1000);
//     let diffInMilliseconds = Math.abs(Math.abs(date_created - d) / 1000);

//     // let diffInMilliSeconds = Math.abs(dateFuture - dateNow) / 1000;

//     // calculate days
//     const days = Math.floor(diffInMilliseconds / 86400);

//     // calculate hours
//     const hours = 24 * days + (Math.floor(diffInMilliseconds / 3600) % 24);
//     diffInMilliseconds -= hours * 3600;

//     // calculate minutes
//     const minutes = Math.floor(diffInMilliseconds / 60) % 60;
//     // diffInMilliseconds -= minutes * 60;

//     return d.getTime();
//   };

//   const get_time_diff = () => {
//     let d = new Date();
//     //3 days from the time created
//     const date_created = new Date(userData.created_on);
//     const numOfHours = 72;
//     date_created.setTime(date_created.getTime() + numOfHours * 60 * 60 * 1000);
//     let diffInMilliseconds = Math.abs(Math.abs(date_created - d) / 1000);

//     // let diffInMilliSeconds = Math.abs(dateFuture - dateNow) / 1000;

//     // calculate days
//     const days = Math.floor(diffInMilliseconds / 86400);

//     // calculate hours
//     const hours = 24 * days + (Math.floor(diffInMilliseconds / 3600) % 24);
//     diffInMilliseconds -= hours * 3600;

//     // calculate minutes
//     const minutes = Math.floor(diffInMilliseconds / 60) % 60;
//     // diffInMilliseconds -= minutes * 60;
//     return hours + " hrs and " + minutes + " minutes";
//   };

//   //exitTimeCurrentOption
//   const startBtn = async () => {
//     //let lastOption = Number(localStorage.getItem("current"));
//     // if (state.left == 0 && state.is_paid == 0) {
//     //   // history.push("/store");
//     //   let my_data = [{ is_coupon: false }];
//     //   props.history.push({
//     //     pathname: "/store",
//     //     my_state: my_data,
//     //   });
//     // }

//     // let testSectionsDetails = { ...state.testSectionsDetails };
//     // let testDetails = { ...state.testDetails };
//     if (!isEmpty(questionBankTestId)) {
//       let returnedResponse = await getTestQuestionsList(questionBankTestId);
//       // const _subjecSectiontWiseTestQuestions = subjectWiseData(
//       //   returnedResponse.data.apiData.data
//       // );
//       if (returnedResponse.result) {
//         returnedResponse.data.apiData.data.forEach((element) => {
//           element.isReview = false;
//           // element.isSkipped = false;
//           element.isAttempted = false;
//           element.isAnswerGuessed = false;
//           element.totalTimeTaken = 0;
//           element.isAnswerCorrect = 0;
//           element.correctAnswerIdCollection = [];
//           element.wrongAnswerIdCollection = [];
//           element.userAnswerCollection = [];
//           // element.q_text = element.q_text.replaceAll(
//           //   "ckfinder",
//           //   "http://quizmaster.motion.ac.in/ckfinder"
//           // );
//           element.q_text = element.q_text
//             .replaceAll(
//               'src="ckfinder',
//               'src="http://quizmaster.motion.ac.in/ckfinder'
//             )
//             .replaceAll(
//               'SRC="ckfinder',
//               'SRC="http://quizmaster.motion.ac.in/ckfinder'
//             )
//             .replaceAll(
//               'src="/ckfinder',
//               'src="http://quizmaster.motion.ac.in/ckfinder'
//             )
//             .replaceAll(
//               'SRC="/ckfinder',
//               'SRC="http://quizmaster.motion.ac.in/ckfinder'
//             )
//             .replaceAll(
//               'SRC="ckfinder',
//               'SRC="http://quizmaster.motion.ac.in/ckfinder'
//             )
//             .replaceAll(
//               'SRC="./ckfinder',
//               'SRC="http://quizmaster.motion.ac.in/ckfinder'
//             )
//             .replaceAll(
//               'SRC= "ckfinder',
//               'SRC="http://quizmaster.motion.ac.in/ckfinder'
//             );
//           element.sol_text = element.sol_text
//             ?.replaceAll(
//               'src="ckfinder',
//               'src="http://quizmaster.motion.ac.in/ckfinder'
//             )
//             .replaceAll(
//               'SRC="ckfinder',
//               'SRC="http://quizmaster.motion.ac.in/ckfinder'
//             )
//             .replaceAll(
//               'src="/ckfinder',
//               'src="http://quizmaster.motion.ac.in/ckfinder'
//             )
//             .replaceAll(
//               'SRC="/ckfinder',
//               'SRC="http://quizmaster.motion.ac.in/ckfinder'
//             )
//             .replaceAll(
//               'SRC="ckfinder',
//               'SRC="http://quizmaster.motion.ac.in/ckfinder'
//             )
//             .replaceAll(
//               'SRC="./ckfinder',
//               'SRC="http://quizmaster.motion.ac.in/ckfinder'
//             )
//             .replaceAll(
//               'SRC= "ckfinder',
//               'SRC="http://quizmaster.motion.ac.in/ckfinder'
//             );
//           element.option?.forEach((opt) => {
//             opt.option = opt.option
//               .replaceAll(
//                 'src="ckfinder',
//                 'src="http://quizmaster.motion.ac.in/ckfinder'
//               )
//               .replaceAll(
//                 'SRC="ckfinder',
//                 'SRC="http://quizmaster.motion.ac.in/ckfinder'
//               )
//               .replaceAll(
//                 'src="/ckfinder',
//                 'src="http://quizmaster.motion.ac.in/ckfinder'
//               )
//               .replaceAll(
//                 'SRC="/ckfinder',
//                 'SRC="http://quizmaster.motion.ac.in/ckfinder'
//               )
//               .replaceAll(
//                 'SRC="ckfinder',
//                 'SRC="http://quizmaster.motion.ac.in/ckfinder'
//               )
//               .replaceAll(
//                 'SRC="./ckfinder',
//                 'SRC="http://quizmaster.motion.ac.in/ckfinder'
//               )
//               .replaceAll(
//                 'SRC= "ckfinder',
//                 'SRC="http://quizmaster.motion.ac.in/ckfinder'
//               );
//           });
//         });
//         let time = getNowTime();

//         setState((prevState) => ({
//           ...prevState,
//           step: 2,
//           testQuestionsDetails: returnedResponse.data.apiData.data,
//           //subjecSectiontWiseTestQuestions: _subjecSectiontWiseTestQuestions,
//           initialTime: time,
//           useTime: returnedResponse.data.apiData.total_time,
//         }));
//         //handleQuestionJump(lastOption);
//       } else {
//         alert("No test questions found");
//       }
//     } else {
//       alert("You don't have Test Details");
//     }
//   };

//   const getCalculatedTime = (initialTime) => {
//     let d = new Date();
//     let time = d.getTime();

//     let calculatedTime = Math.round((time - initialTime) / 1000);

//     return calculatedTime;
//   };
//   const handlePreviousQuestionBtn = () => {
//     let { currentQuestion, initialTime, testQuestionsDetails } = state;
//     // let calculatedTime = getCalculatedTime(initialTime);

//     // testQuestionsDetails[currentQuestion].totalTimeTaken = calculatedTime;
//     let time = getNowTime();

//     handleSaveQuestion();
//     //handleCheckQuestion();
//     if (currentQuestion >= 1) {
//       setState((prevState) => ({
//         ...prevState,
//         currentQuestion: currentQuestion - 1,
//         testQuestionsDetails: testQuestionsDetails,
//         initialTime: time,
//       }));
//       window.scrollTo(0, 0);
//     }
//     // if (state.testQuestionsDetails[state.currentQuestion].option.filter((opt) => {
//     //   opt.
//     // }))
//   };
//   //save prticular question
//   const handleSaveQuestion = async () => {
//     let { currentQuestion, initialTime, testQuestionsDetails } = state;
//     let crntQuestionDetail = testQuestionsDetails[currentQuestion];
//     let calculatedTime = getCalculatedTime(initialTime);
//     // let dataa = {
//     //   question_bank_id: questionBankTestId,
//     //   negative_marks: crntQuestionDetail.negative_marks,
//     //   option_id: crntQuestionDetail.userAnswerCollection[0]
//     //     ? crntQuestionDetail.userAnswerCollection[0].option_id
//     //     : crntQuestionDetail.answer_type == "Integer Correct"
//     //     ? crntQuestionDetail.integer_answer
//     //     : "",
//     //   paper_id: crntQuestionDetail.paper_id,
//     //   partial_marks: crntQuestionDetail.partial_marks,
//     //   positive_marks: crntQuestionDetail.positive_marks,
//     //   qid: crntQuestionDetail.qid,
//     //   subject: crntQuestionDetail.subject,
//     //   time: crntQuestionDetail.totalTimeTaken,
//     //   topic_name: crntQuestionDetail.topic_name,
//     //   toughness: crntQuestionDetail.toughness,
//     //   format: "",
//     //   section: "",

//     // };

//     let data = {};
//     if (crntQuestionDetail.answer_type == "Integer Correct") {
//       data = {
//         question_bank_id: questionBankTestId,
//         negative_marks: parseInt(crntQuestionDetail.negative_marks),

//         option_int: crntQuestionDetail.userAnswerCollection[0]
//           ? crntQuestionDetail.userAnswerCollection[0]
//           : crntQuestionDetail.answer_type == "Integer Correct"
//           ? crntQuestionDetail.integer_answer
//           : "",

//         option_id: [],

//         paper_id: crntQuestionDetail.paper_id,
//         partial_marks: parseInt(crntQuestionDetail.partial_marks),
//         positive_marks: parseInt(crntQuestionDetail.positive_marks),
//         qid: crntQuestionDetail.qid,
//         subject: crntQuestionDetail.subject,
//         // time: 10,
//         time: calculatedTime ? calculatedTime : 0,
//         topic_name: crntQuestionDetail.topic_name,
//         toughness: crntQuestionDetail.toughness,

//         format: "",
//         section: "",
//       };
//     } else {
//       data = {
//         question_bank_id: questionBankTestId,

//         negative_marks: parseInt(crntQuestionDetail.negative_marks),

//         option_id: crntQuestionDetail.userAnswerCollection.length
//           ? crntQuestionDetail.userAnswerCollection
//           : [],

//         paper_id: crntQuestionDetail.paper_id,
//         partial_marks: parseInt(crntQuestionDetail.partial_marks),
//         positive_marks: parseInt(crntQuestionDetail.positive_marks),
//         qid: crntQuestionDetail.qid,
//         subject: crntQuestionDetail.subject,
//         // time: 10,
//         time: calculatedTime ? calculatedTime : 0,
//         topic_name: crntQuestionDetail.topic_name,
//         toughness: crntQuestionDetail.toughness,

//         format: "",
//         section: "",
//       };
//     }

//     startLoading();
//     try {
//       const response = await axios.post(url_155, data);
//       if (response.data.status === 200) {
//         finishLoading();
//       }
//       finishLoading();
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   const handleNextQuestionBtn = () => {
//     let { currentQuestion, initialTime, testQuestionsDetails } = state;
//     let calculatedTime = getCalculatedTime(initialTime);
//     // testQuestionsDetails[currentQuestion].totalTimeTaken = calculatedTime;
//     handleSaveQuestion();
//     //newline add

//     if (
//       testQuestionsDetails[currentQuestion].isAttempted ||
//       testQuestionsDetails[currentQuestion].option.filter(
//         (m) => m.is_user_correct_ans
//       ).length
//     ) {
//       testQuestionsDetails[currentQuestion].isReview = false;
//     } else {
//       testQuestionsDetails[currentQuestion].isReview = true;
//     }
//     let time = getNowTime();

//     setState((prevState) => ({
//       ...prevState,
//       currentQuestion: currentQuestion + 1,
//       testQuestionsDetails: testQuestionsDetails,
//       initialTime: time,
//     }));
//     window.scrollTo(0, 0);
//   };
//   const handleSingleSelectAnswer = (event, optionId) => {
//     let { checked } = event.target;
//     let { currentQuestion, testQuestionsDetails } = state;
//     //localStorage.setItem("current", currentQuestion);
//     if (checked) {
//       testQuestionsDetails[currentQuestion].isAttempted = true;
//       testQuestionsDetails[currentQuestion].userAnswerCollection[0] = optionId;

//       if (
//         testQuestionsDetails[currentQuestion].correctAnswerIdCollection.indexOf(
//           optionId
//         ) === -1
//       ) {
//         //if you not find the answer in backendCorrect answer array
//         testQuestionsDetails[currentQuestion].wrongAnswerIdCollection[0] =
//           Number(optionId);
//         testQuestionsDetails[currentQuestion].correctAnswerIdCollection = [];
//         testQuestionsDetails[currentQuestion].isAnswerCorrect = 0;
//       } else {
//         testQuestionsDetails[currentQuestion].correctAnswerIdCollection[0] =
//           optionId;
//         testQuestionsDetails[currentQuestion].wrongAnswerIdCollection = [];
//         testQuestionsDetails[currentQuestion].isAnswerCorrect = 1;
//       }
//     } else {
//       testQuestionsDetails[currentQuestion].isAttempted = false;
//       testQuestionsDetails[currentQuestion].userAnswerCollection = [];
//       testQuestionsDetails[currentQuestion].wrongAnswerIdCollection = [];
//       testQuestionsDetails[currentQuestion].correctAnswerIdCollection = [];
//       testQuestionsDetails[currentQuestion].isAnswerCorrect = 0;
//     }

//     setState((prevState) => ({ ...prevState, testQuestionsDetails }));
//   };

//   //   let { checked } = event.target;
//   //   let { currentQuestion, testQuestionsDetails } = state;
//   //   //localStorage.setItem("current", currentQuestion);
//   //   if (checked) {
//   //     testQuestionsDetails[currentQuestion].isAttempted = true;
//   //     testQuestionsDetails[currentQuestion].userAnswerCollection[0] = optionId;

//   //     if (
//   //       testQuestionsDetails[currentQuestion].correctAnswerIdCollection.indexOf(
//   //         optionId
//   //       ) === -1
//   //     ) {
//   //       //if you not find the answer in backendCorrect answer array
//   //       testQuestionsDetails[currentQuestion].wrongAnswerIdCollection[0] =
//   //         Number(optionId);
//   //       testQuestionsDetails[currentQuestion].correctAnswerIdCollection = [];
//   //       testQuestionsDetails[currentQuestion].isAnswerCorrect = 0;
//   //     } else {
//   //       testQuestionsDetails[currentQuestion].correctAnswerIdCollection[0] =
//   //         optionId;
//   //       testQuestionsDetails[currentQuestion].wrongAnswerIdCollection = [];
//   //       testQuestionsDetails[currentQuestion].isAnswerCorrect = 1;
//   //     }
//   //   } else {
//   //     testQuestionsDetails[currentQuestion].isAttempted = false;
//   //     testQuestionsDetails[currentQuestion].userAnswerCollection = [];
//   //     testQuestionsDetails[currentQuestion].wrongAnswerIdCollection = [];
//   //     testQuestionsDetails[currentQuestion].correctAnswerIdCollection = [];
//   //     testQuestionsDetails[currentQuestion].isAnswerCorrect = 0;
//   //   }

//   //   setState((prevState) => ({ ...prevState, testQuestionsDetails }));
//   // };

//   const handleMultiSelectAnswer = (_event, optionId) => {
//     let { currentQuestion, testQuestionsDetails } = state;

//     let findedIndex = testQuestionsDetails[
//       currentQuestion
//     ].userAnswerCollection.findIndex((element) => {
//       return element === optionId;
//     });

//     if (findedIndex === -1) {
//       testQuestionsDetails[currentQuestion].userAnswerCollection.splice(
//         testQuestionsDetails[currentQuestion].userAnswerCollection.length,
//         0,
//         optionId
//       );
//     } else {
//       testQuestionsDetails[currentQuestion].userAnswerCollection.splice(
//         findedIndex,
//         1
//       );
//     }

//     if (testQuestionsDetails[currentQuestion].userAnswerCollection.length) {
//       testQuestionsDetails[currentQuestion].isAttempted = true;
//     } else {
//       testQuestionsDetails[currentQuestion].isAttempted = false;
//     }

//     if (testQuestionsDetails[currentQuestion].wrongAnswerIdCollection.length) {
//       testQuestionsDetails[currentQuestion].isAnswerCorrect = false;
//     }

//     setState((prevState) => ({ ...prevState, testQuestionsDetails }));
//   };

//   const handleInputFieldChange = (event) => {
//     let { value } = event.target;
//     // value = value.replace(/[^0-9]/g, "")
//     let { currentQuestion, testQuestionsDetails } = state;
//     testQuestionsDetails[currentQuestion].integer_answer = value.trim();

//     if (testQuestionsDetails[currentQuestion].integer_answer.length) {
//       testQuestionsDetails[currentQuestion].isAttempted = true;
//     } else {
//       testQuestionsDetails[currentQuestion].isAttempted = false;
//     }

//     setState((prevState) => ({ ...prevState, testQuestionsDetails }));
//   };

//   const handleQuestionJump = (questionNumber) => {
//     handleSaveQuestion();
//     setState((prevState) => ({
//       ...prevState,
//       currentQuestion: questionNumber,
//     }));
//   };

//   const handleSubmitTest = async () => {
//     let { testQuestionsDetails } = state;

//     let questionArr = [];

//     testQuestionsDetails.forEach((element) => {
//       if (element.answer_type.toLowerCase() === "single correct") {
//         let data = {
//           qid: element.qid,
//           type_id: element.type_id,
//           is_saved: element.is_saved,
//           total_time: element.totalTimeTaken,
//           is_attempted: element.isAttempted,
//           is_AnswerCorrect: element.isAnswerCorrect,
//           is_GessTypeAnswer: element.isAnswerGuessed,
//           paragraph_answer: element.paragraph_answer,
//           integer_answer: element.integer_answer,
//           positive_marks: element.positive_marks,
//           negative_marks: element.negative_marks,
//           partial_marks: element.partial_marks,
//           CorrectAnswerId: element.correctAnswerIdCollection,
//           WrongeAnswerId: element.wrongAnswerIdCollection,
//           answer: element.userAnswerCollection,
//           toughness_id: element.toughness_id,
//           subject: element.subject,
//           topic_id: element.topic_id,
//         };
//         if (!data.type_id) {
//           data.type_id = 6;
//         }
//         questionArr.push(data);
//       } else if (element.answer_type.toLowerCase() === "multiple correct") {
//         let data = {
//           qid: element.qid,
//           type_id: element.type_id,
//           is_saved: element.is_saved,
//           total_time: element.totalTimeTaken,
//           is_attempted: element.isAttempted,
//           is_AnswerCorrect: element.isAnswerCorrect,
//           is_GessTypeAnswer: element.isAnswerGuessed,
//           paragraph_answer: element.paragraph_answer,
//           integer_answer: element.integer_answer,
//           positive_marks: element.positive_marks,
//           negative_marks: element.negative_marks,
//           partial_marks: element.partial_marks,
//           CorrectAnswerId: element.correctAnswerIdCollection,
//           WrongeAnswerId: element.wrongAnswerIdCollection,
//           answer: element.userAnswerCollection,
//           toughness_id: element.toughness_id,
//           subject: element.subject,
//           topic_id: element.topic_id,
//         };
//         if (!data.type_id) {
//           data.type_id = 6;
//         }
//         questionArr.push(data);
//       } else if (element.answer_type.toLowerCase() === "integer correct") {
//         let data = {
//           qid: element.qid,
//           type_id: element.type_id,
//           is_saved: element.is_saved,
//           total_time: element.totalTimeTaken,
//           is_attempted: element.isAttempted,
//           paragraph_answer: element.paragraph_answer,
//           integer_answer: element.integer_answer,
//           is_AnswerCorrect: element.isAnswerCorrect,
//           is_GessTypeAnswer: element.isAnswerGuessed,
//           positive_marks: element.positive_marks,
//           negative_marks: element.negative_marks,
//           partial_marks: element.partial_marks,
//           CorrectAnswerId: element.correctAnswerIdCollection,
//           WrongeAnswerId: element.wrongAnswerIdCollection,
//           answer: element.userAnswerCollection,
//           toughness_id: element.toughness_id,
//           subject: element.subject,
//           topic_id: element.topic_id,
//         };
//         if (!data.type_id) {
//           data.type_id = 6;
//         }
//         questionArr.push(data);
//       }
//     });
//     startLoading();
//     let returnedResponse = await submitTestApi();
//     if (returnedResponse.result) {
//       finishLoading();

//       history.push("/question_bank");
//     } else {
//       finishLoading();
//       alert(returnedResponse.data.message);
//     }
//   };


//   const submitTestApi = async () => {
//     let requestPayload = {
//       // course_id: userPreference.courseDetails[0].course_id,
//       // class_id: userPreference.classDetails.class_id,
//       // batch_id: userPreference.batchDetails.batch_id,
//       question_bank_id: questionBankTestId,
//       time: 84,
//       // paper_duration: testDetails.duration_mins,
//       // paper_marks: testDetails.total_marks,
//       // question: questionArr,
//     };

//     // let requestPayload = {
//     //   course_id: 36,
//     //   class_id: 2,
//     //   batch_id: 30,
//     //   paper_id: testDetails.paper_id,
//     //   paper_duration: testDetails.duration_mins,
//     //   paper_marks: testDetails.total_marks,
//     //   question: questionArr,
//     // };

//     try {
//       const response = await axios.post(url_156, requestPayload);
//       if (response.data.status === 200) {
//         return {
//           result: 1,
//           data: {
//             message: response.data.message,
//           },
//         };
//       } else {
//         return {
//           result: 0,
//           data: {
//             message: response.data.message,
//           },
//         };
//       }
//     } catch (error) {
//       console.log(error);
//       return {
//         result: 0,
//         data: {
//           message: "Something went wrong!!!",
//         },
//       };
//     }
//   };

//   const handleRedirectBtn = () => {
//     history.push("/pyq_cps_exam");
//   };

//   const renderView = (step) => {
//     switch (step) {
//       case 1:
//         return (
//           <QuestionBankTestInstructionView
//             startBtn={startBtn}
//             handleRedirectBtn={handleRedirectBtn}
//             testInstructionData={state.testInstructionData}
//             // history={history}
//           />
//         );
//       case 2:
//         return (
//           <QuestionBankTestQuestionsView
//             testQuestionsDetails={state.testQuestionsDetails}
//             subjecSectiontWiseTestQuestions={
//               state.subjecSectiontWiseTestQuestions
//             }
//             selectedSubject={selectedSubject}
//             // selectedSection={selectedSection}
//             // handleSubjectChange={handleSubjectChange}
//             // handleSectionChange={handleSectionChange}
//             currentQuestion={state.currentQuestion}
//             // countDownTimer={state.countDownTimer}
//             handlePreviousQuestionBtn={handlePreviousQuestionBtn}
//             handleNextQuestionBtn={handleNextQuestionBtn}
//             handleSaveQuestion={handleSaveQuestion}
//             handleSingleSelectAnswer={handleSingleSelectAnswer}
//             handleMultiSelectAnswer={handleMultiSelectAnswer}
//             handleInputFieldChange={handleInputFieldChange}
//             // handleReview={handleReview}
//             // handleSaved={handleSaved}
//             // handleGuessedAnswer={handleGuessedAnswer}
//             handleQuestionJump={handleQuestionJump}
//             handleSubmitTest={handleSubmitTest}
//             // checkQuestion={state.checkQuestion}
//             userId={userData.user_id}
//             startLoading={startLoading}
//             finishLoading={finishLoading}
//           />
//         );

//       default:
//         return null;
//     }
//   };

//   return (
//     <Fragment>
//       <div className="cps_test_content_component_wrapper">
//         <div className="test_content_component_inner_wrapper">
//           {renderView(state.step)}
//         </div>
//       </div>
//     </Fragment>
//   );
// };

// const mapStateToProps = (state) => {
//   return {
//     questionBankTestId: state.QuestionBank.questionBankId,
//     userPreference: state.userPreference,
//   };
// };

// const mapDispatchToProps = (dispatch) => {
//   return {
//     startLoading: () => {
//       dispatch(uiActions.startLoading());
//     },
//     finishLoading: () => {
//       dispatch(uiActions.finishLoading());
//     },

//     // setCpsTestsResultData: (payload) => {
//     //   dispatch(cpsUiActions.setCpsTestsResultData(payload));
//     // },
//   };
// };

// export default connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(QuestionBankTestContentComponent);




import { Fragment, useState, useEffect } from "react";

import "./QuestionBankTestContentComponent.scss";

import QuestionBankTestInstructionView from "./question_bank_test_intructions/QuestionBankTestInstructionView";
import QuestionBankTestQuestionsView from "./question_bank_test_questions/QuestionBankTestQuestionsView";

import {
  url_153,
  url_154,
  url_155,
  url_156,
} from "../../../../../custom_utilities/api_services";
import axios from "axios";
import { isEmpty } from "../../../../../custom_utilities/custom_useful_functions";

//Redux
import { connect } from "react-redux";
import uiActions from "../../../../../redux/ui/action";

const QuestionBankTestContentComponent = (props) => {
  let { history, startLoading, finishLoading, userData, questionBankTestId } =
    props;
  const [state, setState] = useState({
    step: 1,
    testInstructionData: {},
    testQuestionsDetails: [],
    currentQuestion: 0,
  
    initialTime: 0,
    useTime: 0,
    left: 0,
    is_paid: 0,
    hours: 72,
    minutes: 0,
    seconds: 0,
  });



  useEffect(() => {
    getinstructionDetail();
  }, []);


  const getinstructionDetail = async () => {
    startLoading();
    try {
      const response = await axios.post(url_153);

      if (response.data.status) {
        setState((preState) => ({
          ...preState,

          testInstructionData: response.data.data,
        }));
      }

      finishLoading();
    } catch (error) {
      console.log(error);
    }
  };

  const getTestQuestionsList = async (questionBankTestId) => {
    let data = {
      question_bank_id: questionBankTestId,
    };
    startLoading();
    try {
      const response = await axios.post(url_154, data);

      if (response.data.status === 200) {
        finishLoading();
        return {
          result: 1,
          data: { apiData: response.data },
        };
      } else {
        finishLoading();
        return {
          result: 0,
          data: { apiData: {} },
        };
      }
    } catch (error) {
      console.log(error);
      finishLoading();
      return {
        result: 0,
        data: { apiData: {} },
      };
    }
  };



  const getNowTime = () => {
    let d = new Date();
    //3 days from the time created
    const date_created = new Date(userData.created_on);
    const numOfHours = 72;
    date_created.setTime(date_created.getTime() + numOfHours * 60 * 60 * 1000);
    let diffInMilliseconds = Math.abs(Math.abs(date_created - d) / 1000);

    // let diffInMilliSeconds = Math.abs(dateFuture - dateNow) / 1000;

    // calculate days
    const days = Math.floor(diffInMilliseconds / 86400);

    // calculate hours
    const hours = 24 * days + (Math.floor(diffInMilliseconds / 3600) % 24);
    diffInMilliseconds -= hours * 3600;

    // calculate minutes
    const minutes = Math.floor(diffInMilliseconds / 60) % 60;
    // diffInMilliseconds -= minutes * 60;

    return d.getTime();
  };



  //exitTimeCurrentOption
  const startBtn = async () => {
  

    if (!isEmpty(questionBankTestId)) {
      let returnedResponse = await getTestQuestionsList(questionBankTestId);
   
      if (returnedResponse.result) {
        returnedResponse.data.apiData.data.forEach((element) => {
          element.isReview = false;
          element.isAttempted = false;
          element.isAnswerGuessed = false;
          element.totalTimeTaken = 0;
          element.isAnswerCorrect = 0;
          element.correctAnswerIdCollection = [];
          element.wrongAnswerIdCollection = [];
          element.userAnswerCollection = [];
   
          element.q_text = element.q_text
            .replaceAll(
              'src="ckfinder',
              'src="http://quizmaster.motion.ac.in/ckfinder'
            )
            .replaceAll(
              'SRC="ckfinder',
              'SRC="http://quizmaster.motion.ac.in/ckfinder'
            )
            .replaceAll(
              'src="/ckfinder',
              'src="http://quizmaster.motion.ac.in/ckfinder'
            )
            .replaceAll(
              'SRC="/ckfinder',
              'SRC="http://quizmaster.motion.ac.in/ckfinder'
            )
            .replaceAll(
              'SRC="ckfinder',
              'SRC="http://quizmaster.motion.ac.in/ckfinder'
            )
            .replaceAll(
              'SRC="./ckfinder',
              'SRC="http://quizmaster.motion.ac.in/ckfinder'
            )
            .replaceAll(
              'SRC= "ckfinder',
              'SRC="http://quizmaster.motion.ac.in/ckfinder'
            );
          element.sol_text = element.sol_text
            ?.replaceAll(
              'src="ckfinder',
              'src="http://quizmaster.motion.ac.in/ckfinder'
            )
            .replaceAll(
              'SRC="ckfinder',
              'SRC="http://quizmaster.motion.ac.in/ckfinder'
            )
            .replaceAll(
              'src="/ckfinder',
              'src="http://quizmaster.motion.ac.in/ckfinder'
            )
            .replaceAll(
              'SRC="/ckfinder',
              'SRC="http://quizmaster.motion.ac.in/ckfinder'
            )
            .replaceAll(
              'SRC="ckfinder',
              'SRC="http://quizmaster.motion.ac.in/ckfinder'
            )
            .replaceAll(
              'SRC="./ckfinder',
              'SRC="http://quizmaster.motion.ac.in/ckfinder'
            )
            .replaceAll(
              'SRC= "ckfinder',
              'SRC="http://quizmaster.motion.ac.in/ckfinder'
            );
          element.option?.forEach((opt) => {
            opt.option = opt.option
              .replaceAll(
                'src="ckfinder',
                'src="http://quizmaster.motion.ac.in/ckfinder'
              )
              .replaceAll(
                'SRC="ckfinder',
                'SRC="http://quizmaster.motion.ac.in/ckfinder'
              )
              .replaceAll(
                'src="/ckfinder',
                'src="http://quizmaster.motion.ac.in/ckfinder'
              )
              .replaceAll(
                'SRC="/ckfinder',
                'SRC="http://quizmaster.motion.ac.in/ckfinder'
              )
              .replaceAll(
                'SRC="ckfinder',
                'SRC="http://quizmaster.motion.ac.in/ckfinder'
              )
              .replaceAll(
                'SRC="./ckfinder',
                'SRC="http://quizmaster.motion.ac.in/ckfinder'
              )
              .replaceAll(
                'SRC= "ckfinder',
                'SRC="http://quizmaster.motion.ac.in/ckfinder'
              );
          });
        });
        let time = getNowTime();

        setState((prevState) => ({
          ...prevState,
          step: 2,
          testQuestionsDetails: returnedResponse.data.apiData.data,
          initialTime: time,
          useTime: returnedResponse.data.apiData.total_time,
        }));
      } else {
        alert("No test questions found");
      }
    } else {
      alert("You don't have Test Details");
    }
  };

  const getCalculatedTime = (initialTime) => {
    let d = new Date();
    let time = d.getTime();

    let calculatedTime = Math.round((time - initialTime) / 1000);

    return calculatedTime;
  };
  const handlePreviousQuestionBtn = () => {
    let { currentQuestion, initialTime, testQuestionsDetails } = state;

    let time = getNowTime();

    handleSaveQuestion();
    if (currentQuestion >= 1) {
      setState((prevState) => ({
        ...prevState,
        currentQuestion: currentQuestion - 1,
        testQuestionsDetails: testQuestionsDetails,
        initialTime: time,
      }));
      window.scrollTo(0, 0);
    }

  };
  //save prticular question
  const handleSaveQuestion = async () => {
    let { currentQuestion, initialTime, testQuestionsDetails } = state;
    let crntQuestionDetail = testQuestionsDetails[currentQuestion];
    let calculatedTime = getCalculatedTime(initialTime);
  

    let data = {};
    if (crntQuestionDetail.answer_type == "Integer Correct") {
      data = {
        question_bank_id: questionBankTestId,
        negative_marks: parseInt(crntQuestionDetail.negative_marks),

        option_int: crntQuestionDetail.userAnswerCollection[0]
          ? crntQuestionDetail.userAnswerCollection[0]
          : crntQuestionDetail.answer_type == "Integer Correct"
          ? crntQuestionDetail.integer_answer
          : "",

        option_id: [],

        paper_id: crntQuestionDetail.paper_id,
        partial_marks: parseInt(crntQuestionDetail.partial_marks),
        positive_marks: parseInt(crntQuestionDetail.positive_marks),
        qid: crntQuestionDetail.qid,
        subject: crntQuestionDetail.subject,
        time: calculatedTime ? calculatedTime : 0,
        topic_name: crntQuestionDetail.topic_name,
        toughness: crntQuestionDetail.toughness,

        format: "",
        section: "",
      };
    } else {
      data = {
        question_bank_id: questionBankTestId,

        negative_marks: parseInt(crntQuestionDetail.negative_marks),

        option_id: crntQuestionDetail.userAnswerCollection.length
          ? crntQuestionDetail.userAnswerCollection
          : [],

        paper_id: crntQuestionDetail.paper_id,
        partial_marks: parseInt(crntQuestionDetail.partial_marks),
        positive_marks: parseInt(crntQuestionDetail.positive_marks),
        qid: crntQuestionDetail.qid,
        subject: crntQuestionDetail.subject,
        time: calculatedTime ? calculatedTime : 0,
        topic_name: crntQuestionDetail.topic_name,
        toughness: crntQuestionDetail.toughness,

        format: "",
        section: "",
      };
    }

    startLoading();
    try {
      const response = await axios.post(url_155, data);
      if (response.data.status === 200) {
        finishLoading();
      }
      finishLoading();
    } catch (error) {
      console.log(error);
    }
  };

  const handleNextQuestionBtn = () => {
    let { currentQuestion, initialTime, testQuestionsDetails } = state;
    let calculatedTime = getCalculatedTime(initialTime);
    handleSaveQuestion();

    if (
      testQuestionsDetails[currentQuestion].isAttempted ||
      testQuestionsDetails[currentQuestion].option.filter(
        (m) => m.is_user_correct_ans
      ).length
    ) {
      testQuestionsDetails[currentQuestion].isReview = false;
    } else {
      testQuestionsDetails[currentQuestion].isReview = true;
    }
    let time = getNowTime();

    setState((prevState) => ({
      ...prevState,
      currentQuestion: currentQuestion + 1,
      testQuestionsDetails: testQuestionsDetails,
      initialTime: time,
    }));
    window.scrollTo(0, 0);
  };
  const handleSingleSelectAnswer = (event, optionId) => {
    let { checked } = event.target;
    let { currentQuestion, testQuestionsDetails } = state;
    if (checked) {
      testQuestionsDetails[currentQuestion].isAttempted = true;
      testQuestionsDetails[currentQuestion].userAnswerCollection[0] = optionId;

      if (
        testQuestionsDetails[currentQuestion].correctAnswerIdCollection.indexOf(
          optionId
        ) === -1
      ) {
        //if you not find the answer in backendCorrect answer array
        testQuestionsDetails[currentQuestion].wrongAnswerIdCollection[0] =
          Number(optionId);
        testQuestionsDetails[currentQuestion].correctAnswerIdCollection = [];
        testQuestionsDetails[currentQuestion].isAnswerCorrect = 0;
      } else {
        testQuestionsDetails[currentQuestion].correctAnswerIdCollection[0] =
          optionId;
        testQuestionsDetails[currentQuestion].wrongAnswerIdCollection = [];
        testQuestionsDetails[currentQuestion].isAnswerCorrect = 1;
      }
    } else {
      testQuestionsDetails[currentQuestion].isAttempted = false;
      testQuestionsDetails[currentQuestion].userAnswerCollection = [];
      testQuestionsDetails[currentQuestion].wrongAnswerIdCollection = [];
      testQuestionsDetails[currentQuestion].correctAnswerIdCollection = [];
      testQuestionsDetails[currentQuestion].isAnswerCorrect = 0;
    }

    setState((prevState) => ({ ...prevState, testQuestionsDetails }));
  };


  const handleMultiSelectAnswer = (_event, optionId) => {
    let { currentQuestion, testQuestionsDetails } = state;

    let findedIndex = testQuestionsDetails[
      currentQuestion
    ].userAnswerCollection.findIndex((element) => {
      return element === optionId;
    });

    if (findedIndex === -1) {
      testQuestionsDetails[currentQuestion].userAnswerCollection.splice(
        testQuestionsDetails[currentQuestion].userAnswerCollection.length,
        0,
        optionId
      );
    } else {
      testQuestionsDetails[currentQuestion].userAnswerCollection.splice(
        findedIndex,
        1
      );
    }

    if (testQuestionsDetails[currentQuestion].userAnswerCollection.length) {
      testQuestionsDetails[currentQuestion].isAttempted = true;
    } else {
      testQuestionsDetails[currentQuestion].isAttempted = false;
    }

    if (testQuestionsDetails[currentQuestion].wrongAnswerIdCollection.length) {
      testQuestionsDetails[currentQuestion].isAnswerCorrect = false;
    }

    setState((prevState) => ({ ...prevState, testQuestionsDetails }));
  };

  const handleInputFieldChange = (event) => {
    let { value } = event.target;
    let { currentQuestion, testQuestionsDetails } = state;
    testQuestionsDetails[currentQuestion].integer_answer = value.trim();

    if (testQuestionsDetails[currentQuestion].integer_answer.length) {
      testQuestionsDetails[currentQuestion].isAttempted = true;
    } else {
      testQuestionsDetails[currentQuestion].isAttempted = false;
    }

    setState((prevState) => ({ ...prevState, testQuestionsDetails }));
  };

  const handleQuestionJump = (questionNumber) => {
    handleSaveQuestion();
    setState((prevState) => ({
      ...prevState,
      currentQuestion: questionNumber,
    }));
  };

  const handleSubmitTest = async () => {
    let { testQuestionsDetails } = state;

    let questionArr = [];

    testQuestionsDetails.forEach((element) => {
      if (element.answer_type.toLowerCase() === "single correct") {
        let data = {
          qid: element.qid,
          type_id: element.type_id,
          is_saved: element.is_saved,
          total_time: element.totalTimeTaken,
          is_attempted: element.isAttempted,
          is_AnswerCorrect: element.isAnswerCorrect,
          is_GessTypeAnswer: element.isAnswerGuessed,
          paragraph_answer: element.paragraph_answer,
          integer_answer: element.integer_answer,
          positive_marks: element.positive_marks,
          negative_marks: element.negative_marks,
          partial_marks: element.partial_marks,
          CorrectAnswerId: element.correctAnswerIdCollection,
          WrongeAnswerId: element.wrongAnswerIdCollection,
          answer: element.userAnswerCollection,
          toughness_id: element.toughness_id,
          subject: element.subject,
          topic_id: element.topic_id,
        };
        if (!data.type_id) {
          data.type_id = 6;
        }
        questionArr.push(data);
      } else if (element.answer_type.toLowerCase() === "multiple correct") {
        let data = {
          qid: element.qid,
          type_id: element.type_id,
          is_saved: element.is_saved,
          total_time: element.totalTimeTaken,
          is_attempted: element.isAttempted,
          is_AnswerCorrect: element.isAnswerCorrect,
          is_GessTypeAnswer: element.isAnswerGuessed,
          paragraph_answer: element.paragraph_answer,
          integer_answer: element.integer_answer,
          positive_marks: element.positive_marks,
          negative_marks: element.negative_marks,
          partial_marks: element.partial_marks,
          CorrectAnswerId: element.correctAnswerIdCollection,
          WrongeAnswerId: element.wrongAnswerIdCollection,
          answer: element.userAnswerCollection,
          toughness_id: element.toughness_id,
          subject: element.subject,
          topic_id: element.topic_id,
        };
        if (!data.type_id) {
          data.type_id = 6;
        }
        questionArr.push(data);
      } else if (element.answer_type.toLowerCase() === "integer correct") {
        let data = {
          qid: element.qid,
          type_id: element.type_id,
          is_saved: element.is_saved,
          total_time: element.totalTimeTaken,
          is_attempted: element.isAttempted,
          paragraph_answer: element.paragraph_answer,
          integer_answer: element.integer_answer,
          is_AnswerCorrect: element.isAnswerCorrect,
          is_GessTypeAnswer: element.isAnswerGuessed,
          positive_marks: element.positive_marks,
          negative_marks: element.negative_marks,
          partial_marks: element.partial_marks,
          CorrectAnswerId: element.correctAnswerIdCollection,
          WrongeAnswerId: element.wrongAnswerIdCollection,
          answer: element.userAnswerCollection,
          toughness_id: element.toughness_id,
          subject: element.subject,
          topic_id: element.topic_id,
        };
        if (!data.type_id) {
          data.type_id = 6;
        }
        questionArr.push(data);
      }
    });
    startLoading();
    let returnedResponse = await submitTestApi();
    if (returnedResponse.result) {
      finishLoading();

      history.push("/question_bank");
    } else {
      finishLoading();
      alert(returnedResponse.data.message);
    }
  };


  const submitTestApi = async () => {
    let requestPayload = {
  
      question_bank_id: questionBankTestId,
      time: 84,

    };


    try {
      const response = await axios.post(url_156, requestPayload);
      if (response.data.status === 200) {
        return {
          result: 1,
          data: {
            message: response.data.message,
          },
        };
      } else {
        return {
          result: 0,
          data: {
            message: response.data.message,
          },
        };
      }
    } catch (error) {
      console.log(error);
      return {
        result: 0,
        data: {
          message: "Something went wrong!!!",
        },
      };
    }
  };
  const renderView = (step) => {
    switch (step) {
      case 1:
        return (
          <QuestionBankTestInstructionView
            startBtn={startBtn}
            testInstructionData={state.testInstructionData}
          />
        );
      case 2:
        return (
          <QuestionBankTestQuestionsView
            testQuestionsDetails={state.testQuestionsDetails}
      
            currentQuestion={state.currentQuestion}
            handlePreviousQuestionBtn={handlePreviousQuestionBtn}
            handleNextQuestionBtn={handleNextQuestionBtn}
            handleSaveQuestion={handleSaveQuestion}
            handleSingleSelectAnswer={handleSingleSelectAnswer}
            handleMultiSelectAnswer={handleMultiSelectAnswer}
            handleInputFieldChange={handleInputFieldChange}
        
            handleQuestionJump={handleQuestionJump}
            handleSubmitTest={handleSubmitTest}
            userId={userData.user_id}
            startLoading={startLoading}
            finishLoading={finishLoading}
          />
        );

      default:
        return null;
    }
  };

  return (
    <Fragment>
      <div className="cps_test_content_component_wrapper">
        <div className="test_content_component_inner_wrapper">
          {renderView(state.step)}
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    questionBankTestId: state.QuestionBank.questionBankId,
    userPreference: state.userPreference,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => {
      dispatch(uiActions.startLoading());
    },
    finishLoading: () => {
      dispatch(uiActions.finishLoading());
    },

  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QuestionBankTestContentComponent);
