import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { isEmpty } from "../../../../../../../../custom_utilities/custom_useful_functions";
import images from "../../../../../../../../utilities/images/images";
import "./PremiumOtsTest.scss";
import InstructionModal from "../modals/InstructionModal";
import SyllabusModal from "../modals/SyllabusModal";
import { connect } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import otsActions from "../../../../../../../../redux/online-test_series/action";
import {
  url_120,
  url_171,
  url_178,
} from "../../../../../../../../custom_utilities/api_services";
import useAlertHook, {
  CustomAlert,
} from "../../../../ots_custom_utilities/Alert/Alert";

const PremiumOtsTest = (props) => {
  const {
    tokenType,
    token,
    startLoading,
    finishLoading,
    loader,
    setOtsTestsData,
    setOtsPremiumData,
    otsPremiumData,
    history,
    userPreference,
    otsTestsData,
    plannerTestType,
    otsPlannerData,
    upcomingAndpreviousTestTypes,
    user_id,
  } = props;

  const [instructionModalFlag, setInstructionModalFlag] = useState(false);

  const [spsPremiumTestList, setSpsPremiumTestList] = useState({
    test: {},
    // page: 1,
  });

  const [syllabusModalFlag, setSyllabusModalFlag] = useState(false);

  const { showAlert, handleCloseAlert, handleShowAlert } = useAlertHook();

  useEffect(() => {
    getSpsTestsList(plannerTestType);
  }, [plannerTestType, upcomingAndpreviousTestTypes]);

  const getSpsTestsList = async (plannerTestType) => {
    const filterType = upcomingAndpreviousTestTypes == "Upcoming Tests" ? 1 : 0;
    let page_limit = 5;

    let pageNo = Math.ceil(otsPremiumData.length / page_limit) + 1;

    let data = {};


    data = {
      planner_id: otsPlannerData.planner_id,
      free_paid: 1,
      page: pageNo,
      planner_test_type: plannerTestType.planner_test_type,
      test_filter: filterType,
    };

    // if (otsPlannerData.planner_type == "Self Practice Test(SPT)") {
    //   data = {
    //     planner_id: otsPlannerData.planner_id,
    //     free_paid: 1,
    //     page: pageNo,
    //     planner_test_type: plannerTestType.planner_test_type,
    //     test_filter: filterType,
    //   };
    // } else if (otsPlannerData.planner_type == "All India Test") {
    //   data = {
    //     planner_id: otsPlannerData.planner_id,
    //     free_paid: 1,
    //     page: pageNo,
    //     planner_test_type: plannerTestType.planner_test_type,
    //     test_filter: filterType,
    //   };
    // }

    const headers = {
      Authorization: `${tokenType} ${token}`,
    };

    startLoading();

    try {
      const response = await axios.post(url_120, data, { headers });

      if (response.data.status === 200) {
        finishLoading();

        setOtsPremiumData([
          ...otsPremiumData,
          ...response.data.data.paper.data,
        ]);
      } else {
        finishLoading();
      }
    } catch (error) {
      finishLoading();
      console.log(error);
    }
  };

  const handleInstructionModal = (test) => {
    setSpsPremiumTestList((prevState) => ({
      ...prevState,
      test: test,
    }));

    setInstructionModalFlag(true);
  };

  const handleSyllabusModal = (test) => {
    setSpsPremiumTestList((prevState) => ({
      ...prevState,
      test: test,
    }));

    if (test?.paper_id == null || test?.paper_id.length == 0) {
      alert("Syllabus will come before Test Date");
    } else {
      setSyllabusModalFlag(true);
    }
  };

  const RedirectToPaymentPage = (test) => {
    props.history.push({
      pathname: "/ots_payment",
      state: { package_id: test.package_id },
    });
  };

  const onHandleTakeTest = async (testData) => {
    if (testData.is_start_test > 0) {
      if (testData.is_submited == 0) {
        setOtsTestsData(testData);
        history.push("/ots_test");
      } else {
        deletePreviousWholeTestData(testData);
      }
    } else {
      alert(`Test will be available at: ${testData.test_date_newformat}`);
    }
  };

  const deletePreviousWholeTestData = (testData) => {
    const data = {
      user_id,
      paper_id: testData.paper_id,
      test_id: testData.test_id,
    };
    try {
      axios.post(url_178, data).then((response) => {
        if (response.status === 200) {
          setOtsTestsData(testData);
          history.push("/ots_test");
          finishLoading();
        }
      });
    } catch (error) {
      console.log(error?.message);
    }
  };

  const fetchMoreData = () => {
    getSpsTestsList(plannerTestType);
  };

  const renderButtonText = (test) => {
    let buttonText;
    if (test.is_submited == 0) {
      buttonText = "Resume Test";
    } else {
      buttonText = "Start Test";
    }

    return buttonText;
  };

  return (
    <>
      {instructionModalFlag && (
        <InstructionModal
          setInstructionModalFlag={setInstructionModalFlag}
          test={spsPremiumTestList.test}
          startLoading={startLoading}
          finishLoading={finishLoading}
          loader={loader}
        />
      )}
      {syllabusModalFlag && (
        <SyllabusModal
          setSyllabusModalFlag={setSyllabusModalFlag}
          test={spsPremiumTestList.test}
          startLoading={startLoading}
          finishLoading={finishLoading}
          loader={loader}
        />
      )}

      <div className="ots_premium_test">
        <div
          id="parentScrollDiv"
          style={{
            height: "60vh",
            overflow: "auto",
            overflowY: "scroll",
          }}
        >
          <InfiniteScroll
            dataLength={otsPremiumData.length}
            next={fetchMoreData}
            hasMore={true}
            scrollableTarget="parentScrollDiv"
          >
            <div className="sps_test_list">
              {!isEmpty(otsPremiumData) ? (
                otsPremiumData.length ? (
                  otsPremiumData.map((test, index) => {
                    return (
                      <div className="sps_test_list_item" key={index}>
                        <div className="sps_test_heading">
                          <div className="sps_test_name">{test.test_name}</div>

                          <div className="sps_test_icons">
                            <div
                              className="sps_test_instruction_icon"
                              onClick={() => handleInstructionModal(test)}
                            >
                              <img
                                src={images.user_activity_19}
                                alt="instruction icon"
                              />
                            </div>

                            <div
                              className="sps_test_syllabus_icon"
                              onClick={() => handleSyllabusModal(test)}
                            >
                              <img
                                src={images.user_activity_20}
                                alt="syllabus icon"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="sps_test_class_course">
                          {test.target_type === "JEE (A)"
                            ? test.target
                            : test.target_type}
                        </div>
                        <div className="sps_test_description">
                          {test.description}
                        </div>

                        <div className="sps_test_que_mins_marks">
                          <ul>
                            <li>
                              {" "}
                              <span>{test.total_questions} </span> Questions
                            </li>
                            <li>
                              <span>{test.test_duration_min}</span> mins
                            </li>
                            <li>
                              <span>{test.max_marks}</span> marks
                            </li>
                          </ul>
                          <div className="sps_test_date_time">
                            <div className="date_time">
                              {test.test_date_newformat}
                            </div>
                          </div>
                        </div>

                        {/* {test.is_purchased == 0 ? (
                          <div className="sps_Premtest_buttons">
                            <div
                              className="sps_test_practice_button"
                              onClick={() => handleSyllabusModal(test)}
                            >
                              {" "}
                              <button>View Syllabus</button>
                            </div>
                            <div
                              className="sps_test_start_button"
                              onClick={() => RedirectToPaymentPage(test)}
                            >
                              <button>
                                <img src={images.user_activity_21}></img>{" "}
                                <span> Locked </span>
                              </button>
                            </div>
                          </div>
                        ) : test.is_start_test > 0 ? (
                          <div className="sps_Premtest_buttons">
                            <div
                              className="sps_test_practice_button"
                              onClick={() => handleSyllabusModal(test)}
                            >
                              {" "}
                              <button>View Syllabus</button>
                            </div>
                            <div
                              className="sps_test_start_button"
                              onClick={() => onHandleTakeTest(test)}
                            >
                              <button>
                                <span>{renderButtonText(test)}</span>
                              </button>
                            </div>
                          </div>
                        ) : (
                          <div className="sps_Premtest_buttons">
                            <div
                              className="sps_test_practice_button"
                              onClick={() => handleSyllabusModal(test)}
                            >
                              {" "}
                              <button>View Syllabus</button>
                            </div>
                            <div
                              className="sps_test_start_button"
                              onClick={() => onHandleTakeTest(test)}
                            >
                              <button>
                                <img src={images.user_activity_21}></img>{" "}
                                <span> Locked </span>
                              </button>
                            </div>
                          </div>
                        )} */}

                        <divs className="sps_Premtest_buttons">
                          <div
                            className="sps_test_practice_button"
                            onClick={() => handleSyllabusModal(test)}
                          >
                            <button>View Syllabus</button>
                          </div>
                          <div
                            className="sps_test_start_button"
                            onClick={
                              test.is_purchased === 0
                                ? () => RedirectToPaymentPage(test)
                                : () => onHandleTakeTest(test)
                            }
                          >
                            <button>
                              {test.is_purchased === 0 ||
                              test.is_start_test <= 0 ? (
                                <>
                                  <img src={images.user_activity_21} alt="" />
                                  <span>Locked</span>
                                </>
                              ) : (
                                <span>{renderButtonText(test)}</span>
                              )}
                            </button>
                          </div>
                        </divs>
                      </div>
                    );
                  })
                ) : (
                  <div className="ots_no_data_found_wrapper">
                    <img src={images.m2_video_ntF} />
                    <p>There is no tests yet!</p>
                  </div>
                )
              ) : (
                <div className="ots_no_data_found_wrapper">
                  <img src={images.m2_video_ntF} />
                  <p>There is no tests yet!</p>
                </div>
              )}
            </div>
          </InfiniteScroll>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.authToken,
    tokenType: state.auth.authType,
    userPreference: state.userPreference,
    otsTestsData: state.otsTestsData.otsTestsData,
    otsPremiumData: state.otsTestsData.otsPremiumData,
    pageNumber: state.otsTestsData.page,
    user_id: state.auth.user.user_id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setOtsTestsData: (payload) => {
      dispatch(otsActions.setOtsTestsData(payload));
    },

    setOtsPremiumData: (payload) => {
      dispatch(otsActions.setOtsPremiumData(payload));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(PremiumOtsTest);
