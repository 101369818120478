import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import "./ZeroAttemptsAlert.scss";

const ZeroAttemptsAlert = ({ show, handleClose, handleRedirectToStore }) => {
  return (
    <Modal show={show} onHide={handleClose} centered>
      <div className="alert_Modal">
        <Modal.Header closeButton>
          <Modal.Title>
            <div className="section_heading">Ooops!!!</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="alertBody">
          <p className="para1">All your free attempts have been completed!</p>
          <p className="para2">
            Please click on “<span>Get Now</span>” button to purchase Adaptive
            CPS package for attempting further tests.
          </p>
        </Modal.Body>
        <div className="getNowBtn">
          <button onClick={handleRedirectToStore}>Get Now</button>
        </div>
      </div>
    </Modal>
  );
};

export default ZeroAttemptsAlert;
