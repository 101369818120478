import "./CourseEnrollmentTermsAndConditions.scss";
import images from "../../../../utilities/images/images";
import { connect } from "react-redux";

const CourseEnrollmentTermsAndConditions = ({
  handleBackBtn,

  referAndEarnData,
}) => {
  return (
    <div className="termsAndConditions">
      <div className="terms_container">
        <div className="sectionTitle">
          <div className="backBtnContent">
            <div className="backBtn" onClick={handleBackBtn}>
              <img src={images.newBackBtn} alt="backBtn" />
            </div>
            <strong>Terms & Conditions</strong>
          </div>
        </div>

        <div className="contentWrppr">
          {referAndEarnData?.terms_and_condition?.map((term, index) => {
            return (
              <div key={index}>
                <h3>{term.label}</h3>
                <ul>
                  {term.value.map((termData) => {
                    return <li className="termData">{termData}</li>;
                  })}
                </ul>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.authToken,
    tokenType: state.auth.authType,
  };
};

export default connect(
  mapStateToProps,
  null
)(CourseEnrollmentTermsAndConditions);
