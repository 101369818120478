import { Fragment } from "react";

import LoaderPopUp from "../../../../../components/loader/loader";
import MockPracticeTestContentComponent from "./mockPracticeTestContentComponent/mockPracticeTestContentComponent";

//Redux
import { connect } from "react-redux";

const MockPracticeTestComponent = (props) => {
  let { history, loader, userData } = props;

  const isMob = () => {
    if (window.innerWidth <= 480) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Fragment>
      <div className="mock_test_component_wrapper">
        <div className="mock_test_component_inner_wrapper">
              <MockPracticeTestContentComponent
                history={history}
                userData={userData}
              />
        </div>
      </div>
      {loader && <LoaderPopUp />}   
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    loader: state.ui.loader,
    userData: state.auth.user,
  };
};

export default connect(mapStateToProps)(MockPracticeTestComponent);
