import React, { useState, useEffect } from "react";
import "./UserRegistrationModal.scss";
import { Button, Modal } from "react-bootstrap";
import { connect } from "react-redux";
import images from "../../../utilities/images/images";
import { url_210, url_8 } from "../../../custom_utilities/api_services";
import axios from "axios";
import { toastifyMessage } from "../../../custom_utilities/toastify_config";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { checkXSSAttackThroughInput } from "../../../custom_utilities/custom_useful_functions";

const UserRegistrationModal = (props) => {
  const {
    showUserRegistrationModal,
    handleCloseUserRegistrationModal,
    startLoading,
    finishLoading,
  } = props;

  const [formValues, setFormValues] = useState({
    name: "",
    rollNo: "",
    registrationNo: "",
    dob: "",
    selectedFile: null,
    isFormSubmitted: false,
  });

  useEffect(() => {
    validateForm();
  }, [formValues]);

  const [errorMessages, setErrorMessages] = useState({
    name: "",
    rollNo: "",
    registrationNo: "",
    dob: "",
    selectedFile: "",
  });

  useEffect(() => {
    getUserData();
  }, []);

  const getUserData = async () => {
    try {
      const response = await axios.get(url_8);
      if (response.data.status === 200) {
        setFormValues((prevState) => ({
          ...prevState,
          rollNo: response.data.data.roll_no,
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handlePostApiFormData = async () => {
    const { name, rollNo, registrationNo, dob, selectedFile } = formValues;

    // Array of allowed file extensions
    const allowedFileTypes = [
      "image/jpeg",
      "image/png",
      "image/jpg",
      "application/pdf",
      "application/msword",
    ];

    try {
      startLoading();

      // Validate file type
      if (!allowedFileTypes.includes(selectedFile.type)) {
        // If the selected file type is not allowed, display an error message or handle accordingly
        setErrorMessages((prevState) => ({
          ...prevState,
          selectedFile:
            "Please upload a file with the following types: jpeg, png, jpg, pdf, doc",
        }));
        console.log(
          "Invalid file type. Please upload a file with the following types: jpeg, png, jpg, pdf, doc"
        );
        finishLoading();
        return;
      }

      // Construct FormData and send the API request
      const formData = new FormData();
      formData.append("name", name);
      formData.append("roll_no", rollNo);
      formData.append("registration_no", registrationNo);
      formData.append("dob", dob);
      formData.append("file", selectedFile);

      const response = await axios.post(url_210, formData, {
        headers: {
          "Content-Type": "multipart/form-data", // Set content type to multipart form data
        },
      });

      if (response.data.status === 200) {
        finishLoading();
        handleCloseUserRegistrationModal();
        toastifyMessage({
          status: response.data.status,
          message: "Your Form has Submitted Successfully",
        });
      } else {
        finishLoading();
        handleCloseUserRegistrationModal();
        toastifyMessage({
          status: response.data.status,
          message: "Your Form Submission has Failed",
        });
      }
    } catch (err) {
      console.log(err.message);
      finishLoading();
      handleCloseUserRegistrationModal();
    }
  };

  const handleInputChange = async (e) => {
    const { name, value, files } = e.target;

    const response = await checkXSSAttackThroughInput(value);

    if (!response) {
      return;
    }

    if (name === "rollNo" && value.length > 11) {
      return;
    }

    if (name === "registrationNo" && value.length > 12) {
      return;
    }

    if (name === "selectedFile") {
      const selectedFile = files[0];
      const fileSizeInMB = selectedFile.size / (1024 * 1024); // Convert bytes to MB

      if (fileSizeInMB > 2 || !selectedFile.type.match(/(jpeg|jpg|png|pdf)$/)) {
        setErrorMessages((prevState) => ({
          ...prevState,
          selectedFile:
            "Please upload a file of less than 2MB in jpeg, png, jpg, or pdf format",
        }));

        return;
      } else {
        setErrorMessages((prevState) => ({
          ...prevState,
          selectedFile: "",
        }));

        setFormValues((prevState) => ({
          ...prevState,
          selectedFile: selectedFile,
        }));
      }

      validateInput(name, selectedFile);
    } else {
      if (name === "name") {
        const regex = /^[a-zA-Z\s]*$/;
        if (!regex.test(value)) {
          // If special characters are found, do not update the state
          return;
        }

        const newValue = value.replace(/\s{3,}/g, "  ");

        validateInput(name, newValue);

        setFormValues((prevState) => ({
          ...prevState,
          [name]: newValue,
        }));
      } else {
        setFormValues((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      }

      validateInput(name, value);
    }

    // validateForm();
  };

  const validateInput = (name, value) => {
    switch (name) {
      case "name":
        setErrorMessages((prevState) => {
          const trimmedValue = value.trim(); // Remove leading/trailing spaces
          console.log("setting name error Message");
          return {
            ...prevState,
            name:
              trimmedValue && trimmedValue.match(/^[a-zA-Z\s]{5,}$/)
                ? ""
                : "Name should contain only letters and spaces, minimum length of 5 characters",
          };
        });
        break;
      case "rollNo":
        setErrorMessages((prevState) => ({
          ...prevState,
          rollNo:
            value.length >= 9 && value.length <= 11
              ? ""
              : "Roll Number length should be between 9 - 11 characters",
        }));
        break;
      case "registrationNo":
        setErrorMessages((prevState) => ({
          ...prevState,
          registrationNo: /^[0-9]{12}$/.test(value)
            ? ""
            : "Registration Number should contain 12 numeric characters",
        }));
        break;
      case "dob":
        setErrorMessages((prevState) => ({
          ...prevState,
          dob:
            new Date(value) >= new Date("1995-01-01") &&
            new Date(value) <= new Date("2008-12-31")
              ? ""
              : "DOB should be between Jan 1995 and Dec 2008",
        }));
        break;
      case "selectedFile":
        setErrorMessages((prevState) => ({
          ...prevState,
          selectedFile: value ? "" : "Please upload a file",
        }));
        break;
      default:
        break;
    }
  };

  const checkDataIsThere = () => {
    const { name, rollNo, registrationNo, dob, selectedFile } = formValues;

    if (!name) {
      setErrorMessages((prevState) => ({
        ...prevState,
        name: "Name should contain only letters and spaces, minimum length of 5 characters",
      }));
    }

    if (!rollNo) {
      setErrorMessages((prevState) => ({
        ...prevState,
        rollNo: "Roll Number length should be between 9 - 11 characters",
      }));
    }
    if (!registrationNo) {
      setErrorMessages((prevState) => ({
        ...prevState,
        registrationNo:
          "Registration Number should contain 12 numeric characters",
      }));
    }
    if (!dob) {
      setErrorMessages((prevState) => ({
        ...prevState,
        dob: "DOB should be between Jan 1995 and Dec 2008",
      }));
    }
    if (!selectedFile) {
      setErrorMessages((prevState) => ({
        ...prevState,
        selectedFile: "Please upload a file",
      }));
    }
  };

  const handleSubmitForm = (event) => {
    event.preventDefault();

    checkDataIsThere();

    const isValidForm = validateForm();

    if (isValidForm) {
      setFormValues((prevState) => ({
        ...prevState,
        isFormSubmitted: true,
      }));

      handlePostApiFormData();
    }
  };

  const validateForm = () => {
    const { name, rollNo, registrationNo, dob, selectedFile } = formValues;

    let response1;

    if (name && rollNo && registrationNo && dob && selectedFile) {
      response1 = true;
    } else {
      response1 = false;
    }

    let response2;

    if (
      errorMessages.name ||
      errorMessages.rollNo ||
      errorMessages.registrationNo ||
      errorMessages.dob ||
      errorMessages.selectedFile
    ) {
      response2 = false;
    } else {
      response2 = true;
    }

    const finalResponse = response1 && response2;

    return finalResponse;
  };

  return (
    <>
      <Modal show={showUserRegistrationModal} dialogClassName="registration-modal">
        <Modal.Header>
          <h3>User Registration</h3>
        </Modal.Header>
        <Modal.Body>
          <div className="details_Popup">
            <form onSubmit={handleSubmitForm}>
              <div className="form-group">
                <label htmlFor="name">
                  Name <span>*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  name="name"
                  maxLength={40}
                  required
                  placeholder="Your Name"
                  value={formValues.name}
                  onChange={(e) => handleInputChange(e)}
                />

                {errorMessages.name && (
                  <p className="errMsg">{errorMessages.name}</p>
                )}
              </div>

              <div className="form-group">
                <label htmlFor="roll-no">
                  Roll Number <span>*</span>
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="roll-no"
                  name="rollNo"
                  required
                  placeholder="3733829929"
                  value={formValues.rollNo}
                  onChange={(e) => handleInputChange(e)}
                />

                {errorMessages.rollNo && (
                  <p className="errMsg"> {errorMessages.rollNo} </p>
                )}
              </div>

              <div className="form-group">
                <label htmlFor="reg-no">
                  2024 - JEE Application Number <span>*</span>
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="reg-no"
                  name="registrationNo"
                  required
                  placeholder="739838399"
                  value={formValues.registrationNo}
                  onChange={(e) => handleInputChange(e)}
                  maxLength={12}
                  max={12}
                />

                {errorMessages.registrationNo && (
                  <p className="errMsg">{errorMessages.registrationNo}</p>
                )}
              </div>

              <div className="form-group">
                <label htmlFor="dob">
                  DOB<span>*</span>
                </label>
                <input
                  type="date"
                  className="form-control"
                  id="dob"
                  name="dob"
                  required
                  pattern="\d{4}-\d{2}-\d{2}"
                  min="1995-01-01" // Set minimum date
                  max="2008-12-31" // Set maximum date
                  value={formValues.dob}
                  onChange={(e) => handleInputChange(e)}
                  placeholder="dd-mm-yyyy"
                />

                {errorMessages.dob && (
                  <p className="errMsg">{errorMessages.dob}</p>
                )}
              </div>

              <div className="upload-file">
                <label>
                  <input
                    type="file"
                    name="selectedFile"
                    accept=".jpeg, .png, .jpg, .pdf"
                    onChange={(e) => handleInputChange(e)}
                  />
                  <img src={images.uploadFile} alt="Upload" />
                  <span className="upload-file-text"> upload file/image</span>
                </label>

                {errorMessages.selectedFile ? (
                  <p className="errMsg">{errorMessages.selectedFile}</p>
                ) : !formValues.selectedFile ? (
                  <p className="errMsg">
                    {" "}
                    Note* : Images with .jpg,.jpeg and .png and Pdf are allowed
                    and file size must be smaller than 2mb
                  </p>
                ) : null}

                <div className="selected-file">
                  {formValues?.selectedFile?.name}
                </div>
              </div>

              <Button
                className="submit_btn"
                // disabled={!validateForm()}
                type="submit"
                onClick={handleSubmitForm}
              >
                Submit
              </Button>
            </form>
          </div>
        </Modal.Body>
      </Modal>
      <ToastContainer />
    </>
  );
};

export default connect(null, null)(UserRegistrationModal);
