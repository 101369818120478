import { useDispatch } from "react-redux";
import "./ReferalCard.scss";
import { REFER_AND_EARN_MODALS, checkDisable } from "../../utils";
import images from "../../../../../utilities/images/images";
import { setCancelRequestData } from "../../../../../redux/refer_earn/ReferAndEarnSlice";

const ReferalCard = ({
  referal,
  setModalType,
  handleCheckboxChange,
  checkedReferalList,
}) => {
  const dispatch = useDispatch();

  const classEditor = (referal) => {
    if (referal.is_cancelable === "true") {
      return "cancel";
    }

    return "";
  };

  const handleClickCancel = (referal) => {
    if (referal.is_cancelable === "true") {
      dispatch(setCancelRequestData(referal));
      setModalType(REFER_AND_EARN_MODALS.CANCEL_MODIFY);
    }
  };

  const colorEditor = (referal) => {
    if (referal.reward_status === "In Progress") {
      return "progresss";
    }

    if (referal.reward_status === "Redeemed") {
      return "redeem";
    }
  };

  const isChecked = (referal) => {
    return checkedReferalList.some((item) => item.id === referal.id);
  };

  const checkIfRejected = (status) => {
    return status === "Rejected" ? true : false;
  };

  return (
    <div className="referal_card_container">
      <div className="referal_card_content">
        <div className="referal_card">
          <input
            type="checkbox"
            onChange={() => handleCheckboxChange(referal)}
            name="input_checkbox"
            className="referal_input"
            checked={isChecked(referal)}
            disabled={checkDisable(
              referal.reward_status,
              referal.is_redeemable
            )}
          />
          <div
            className={`referal_details ${checkDisable(
              referal.reward_status,
              referal.is_redeemable
            )}`}
          >
            <div
              className={`referal_name ${checkDisable(
                referal.reward_status,
                referal.is_redeemable
              )}`}
            >
              {referal.name}
            </div>
            <div
              className={`course_name ${checkDisable(
                referal.reward_status,
                referal.is_redeemable
              )}`}
            >
              {referal.product_title}
            </div>

            <div className="referal_amnt_and_date">
              <div className="amnt">
                &#8377;{referal.discounted_price}
                <span> &#8377;{referal.price}</span>
              </div>
            </div>
          </div>
        </div>

        <div className="referal_date">{referal.formatted_date}</div>

        <div className="status_container">
          <div className="statuss">{referal.reward_status}</div>
          <div className={`amnt_received ${colorEditor(referal)}`}>
            &#8377;{referal.reward_cash}
          </div>

          <div
            className={`final_status ${classEditor(referal)}`}
            onClick={() => handleClickCancel(referal)}
          >
            {referal.is_cancelable === "true" ||
              (referal.reward_status === "In Progress" && "Cancel/Modify")}

            {referal.reject_reason && checkIfRejected(referal.reward_status) && (
              <>
                <img
                  src={images.invalidlIcon}
                  alt="icon"
                  className="invalid_icon"
                />{" "}
                {referal.reject_reason}{" "}
              </>
            )}
          </div>
        </div>
      </div>

      {referal.redeemable_date && (
        <div className="redeem_date">
          * Redeem option will be available after {referal.redeemable_date}
        </div>
      )}
    </div>
  );
};

export default ReferalCard;
