import React from "react";
import "./QuestionSwitcher.scss";

const QuestionSwitcher = ({
  updateSolution,
  showSolution,
  solDisabled,
  currentQuestion,
  handlePreviousQuestionBtn,
  page,
  scrollUp2,
  handlePurticularQuestion,
  testQuestionsDetails,
  sol,
  disabled1,
  handleSolution,
  handleSaveQuestion,
  handleShow,
  handleNextQuestionBtn,
  scrollUp,
  handlePurticularQuestion3,
}) => {
  return (
    <div className="questionSwitcherWrrpr">
      <div className="left_side_wrapper">
        {currentQuestion > 0 && (
          <div className="btn_wrapper">
            <button
              onClick={() => {
                handlePreviousQuestionBtn(page - 1);
                showSolution(currentQuestion-1);
                scrollUp2();
                handlePurticularQuestion();
              }}
            >
              Previous
            </button>
          </div>
        )}
      </div>
      <div className="right_side_wrapper">
        {testQuestionsDetails.length === currentQuestion + 1 ? (
          <div className="btn_wrapper d-flex">
            
            <button
              disabled={!solDisabled ? false : true}
              className={solDisabled ? "opacity solution" : "true solution"}
              onClick={() => {updateSolution(true); handleSolution()}}
            >
            Solution
            </button>
            
              <button
                onClick={() => {
                  handleSaveQuestion();
                  handleShow();
                }}
              >
                Submit
              </button>
            
          </div>
        ) : (
          <div className="btn_wrapper d-flex">
            
            <button
              disabled={!solDisabled ? false : true}
              className={solDisabled ? "opacity solution" : "true solution"}
              onClick={() => {updateSolution(true); handleSolution()}}
            >
            Solution
            </button>
            
              <button
                onClick={() => {
                  handleNextQuestionBtn(page + 1);
                  showSolution(currentQuestion+1);
                  scrollUp();
                  handlePurticularQuestion3();
                }}
              >
                Next
              </button>
            
          </div>
        )}
      </div>
    </div>
  );
};

export default QuestionSwitcher;
