import { React, useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import "./OtsBehaviour.scss";
import {
  url_132,
  url_134,
} from "../../../../../../../../custom_utilities/api_services";
import { CircularProgressbar } from "react-circular-progressbar";
import images from "../../../../../../../../utilities/images/images";
import { isEmpty } from "../../../../../../../../custom_utilities/custom_useful_functions";
import HeaderComponent from "../../../../../../../logged_user_components/structure_components/desktop_screens/header_component/header_component";
import OtsBehaviourChart from "./OtsBehaviourChart";
import ScatterChart from "../../../../../../../../components/canvas_charts/canvas_doughnut_chart/ScatterChart";
import axios from "axios";
import LoaderPopUp from "../../../../../../../../components/loader/loader";
// Mobile Components
import MobileLeftSideBarComponent from "../../../../../../structure_components/mobile_screens/mobile_left_sideBar_component/mobile_left_sideBar_component";
import MobileHeaderComponent from "../../../../../../structure_components/mobile_screens/mobile_header_component/mobile_header_component";
import MobileRightSideBarComponent from "../../../../../../structure_components/mobile_screens/mobile_right_sideBar_component/mobile__right_sideBar_component";
// Redux
import { connect } from "react-redux";
import uiActions from "../../../../../../../../redux/ui/action";

const OtsBehaviour = (props) => {
  let {
    startLoading,
    finishLoading,
    loader,
    userData,
    userPreference,
    testPaperResultData,
    token,
    tokenType,
  } = props;

  const history = useHistory();
  const [currentSubject, setCurrentSubject] = useState(null);
  const [state, setState] = useState({
    behaviourTime: {},
    scatterChartPoint: null,
    subjectList: {},
    isChartLoading: true, // Loading state for the ScatterChart
  });

  // Fetch subject list on mount
  useEffect(() => {
    getSubjectList();
  }, []);

  // Fetch difficulty result when subject changes
  useEffect(() => {
    if (currentSubject) {
      getDifficultyResult();
    }
  }, [currentSubject]);

  // Handle Back Button Click
  const backBtn = () => {
    history.push("/ots_test_result");
  };

  // Get Subject List API Call
  const getSubjectList = async () => {
    const headers = {
      Authorization: `${tokenType} ${token}`,
    };

    let data = {
      paper_id: testPaperResultData?.PaperData?.paper_id,
    };
    startLoading();
    try {
      const response = await axios.post(url_132, data, { headers });
      if (response.data.status === 200) {
        setState((preState) => ({
          ...preState,
          subjectList: response.data.data,
        }));
        setCurrentSubject(response.data.data.All_Subject[0]?.subject);
      }
      finishLoading();
    } catch (error) {
      console.error(error);
      finishLoading();
    }
  };

  // Get Difficulty Result API Call
  const getDifficultyResult = async () => {
    const headers = {
      Authorization: `${tokenType} ${token}`,
    };

    let data = {
      paper_id: testPaperResultData?.PaperData?.paper_id,
      test_id: testPaperResultData?.PaperData?.testid,
      subject: currentSubject,
    };
    startLoading();
    setState((prevState) => ({ ...prevState, isChartLoading: true })); // Set loading state
    try {
      const response = await axios.post(url_134, data, { headers });
      if (response.data.status === 200) {
        setState((prevState) => ({
          ...prevState,
          behaviourTime: response.data.data,
        }));
        convertDataPoints(response.data.data[`Time Per Question`]);
      } else {
        setState((prevState) => ({
          ...prevState,
          behaviourTime: "",
          scatterChartPoint: null, // Clear chart data if no valid response
        }));
      }
      finishLoading();
    } catch (error) {
      console.error(error);
      finishLoading();
    }
  };

  // Convert data points for ScatterChart
  const convertDataPoints = (array) => {
    let correctPoint = [];
    let wrongPoint = [];
    for (let i = 0; i < array.length; i++) {
      if (array[i].Type === "RIGHT") {
        correctPoint.push({ x: i + 1, y: parseInt(array[i].Time) });
      } else {
        wrongPoint.push({ x: i + 1, y: parseInt(array[i].Time) });
      }
    }
    setState((preState) => ({
      ...preState,
      scatterChartPoint: { correctPoint, wrongPoint },
      isChartLoading: false, // Set loading complete
    }));
  };

  // Handle subject change
  const handleChangeSubject = (event) => {
    let { value } = event.target;
    setCurrentSubject(value);
  };

  // Render ScatterChart with fallback/loading state
  const renderScatterChart = () => {
    if (state.isChartLoading) {
      return <div>Loading chart...</div>; // Show loading message
    } else if (
      !state.scatterChartPoint ||
      (state.scatterChartPoint.correctPoint.length === 0 &&
        state.scatterChartPoint.wrongPoint.length === 0)
    ) {
      return <div>No data available for the chart.</div>; // Show fallback message when no data
    } else {
      return <ScatterChart dataPoints={state.scatterChartPoint} />; // Render chart if data is available
    }
  };

  // Check if it's a mobile view
  const isMob = () => {
    return window.innerWidth <= 480;
  };

  return (
    <div className="otsBehaviourWrapper">
      {isMob() ? (
        <>
          <MobileLeftSideBarComponent />
          <MobileHeaderComponent
            userPreference={userPreference}
            userData={userData}
          />
          <MobileRightSideBarComponent history={history} />
        </>
      ) : (
        <HeaderComponent />
      )}

      <div className="behaviourInrWrapper">
        <div className="container">
          <div className="sbjtWiseMarksInr">
            <div className="backBtn" onClick={backBtn}>
              <img src={images.user_activity_1} alt="Back" />
            </div>

            <div className="difficultyLevel">
              <h3>Behaviour</h3>
              <div className="difficultyLevelTab">
                <div className="difficultyLevelTabBox">
                  {!isEmpty(state.subjectList)
                    ? state.subjectList.All_Subject.length
                      ? state.subjectList.All_Subject.map((element, index) => (
                          <div key={index} className="single_select_subject">
                            <input
                              type="radio"
                              id={element.subject}
                              name="subject"
                              value={element.subject}
                              checked={element.subject === currentSubject}
                              onChange={(event) => handleChangeSubject(event)}
                            />
                            <label htmlFor={element.subject}>
                              {element.subject}
                            </label>
                          </div>
                        ))
                      : null
                    : null}
                </div>

                <div className="difficultyLevelcontent">
                  <div className="scatterChart">
                    {renderScatterChart()} {/* Render ScatterChart */}
                  </div>
                  <div className="scorePercentageBox margin0">
                    <div className="scoreChart mediumResult">
                      <CircularProgressbar
                        value={
                          state.behaviourTime?.[`Total Time`]
                            ? state.behaviourTime?.[`Total Time`]
                            : 0
                        }
                        strokeWidth={10}
                      />
                    </div>
                    <div className="scoreDetail">
                      <p>Student Attempted Tough Questions</p>
                    </div>
                  </div>
                  <div className="scorePercentageBox">
                    <div className="scoreChart hardResult">
                      <OtsBehaviourChart dataPoints={state.behaviourTime} />
                    </div>
                    <div className="scoreDetail">
                      <p>Time Spent on C, W, U</p>
                      <div className="percentageDiv correct">
                        <span></span>Correct
                      </div>
                      <div className="percentageDiv wrong">
                        <span></span>Wrong
                      </div>
                      <div className="percentageDiv unattempt">
                        <span></span>Unattempted
                      </div>
                    </div>
                  </div>
                </div>

                {loader && <LoaderPopUp />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// Redux connection
const mapStateToProps = (state) => {
  return {
    loader: state.ui.loader,
    userData: state.auth.user,
    userPreference: state.userPreference,
    testPaperResultData: state?.otsTestsData?.otsPaperResultData,
    token: state.auth.authToken,
    tokenType: state.auth.tokenType,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => {
      dispatch(uiActions.startLoading());
    },
    finishLoading: () => {
      dispatch(uiActions.finishLoading());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OtsBehaviour);
