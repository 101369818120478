import React from "react";
import "./QuestionDetailPanel.scss";
import TestProgressIndicator from "./testProgress/TestPrgs";
import QuestionInfoSection from "./questionInfoSection/QueInfo";
import QuestionOptions from "./questionOptions/QueOptions";
import QuestionSwitcher from "./questionSwitcher/QueSwitcher";
import QuestionSolution from "./questionSolution/QueSolution";
import ReportErrorModal from "./reportErrorModal/RptErrorModal";
import QVideoSolution from "./QVideoSolution/QVideoSolution";

const QuestionDetailPanel = ({
  showSolution,
  getClicked,
  textInputSolution,
  updateSolution,
  solDisabled,
  testQuestionsDetails,
  currentQuestion,
  lang,
  handlePreviousQuestionBtn,
  handleNextQuestionBtn,
  progressCount,
  scrollUp,
  scrollUp2,
  handleVideoShow,
  videoShow,
  setVideoShow,
  disableSol,
  checkQuestion,
  handleSingleSelectAnswer,
  handleMultiSelectAnswer,
  handleInputFieldChange,
  handleSaveQuestion,
  handleShow,
  handleSol,
  sol,
  disabled1,
  handleSolution,
  setSol,
  handleGetReportErrorOptions,
  startLoading,
  finishLoading,
  userId,
}) => {
  return (
    <div className="right_side_content_wrapper">
      <div className="sub_section_wrapper">
        <TestProgressIndicator
          progressCount={progressCount}
          currentQuestion={currentQuestion}
          testQuestionsDetails={testQuestionsDetails}
        />

        <QuestionInfoSection
          lang={lang}
          testQuestionsDetails={testQuestionsDetails}
          currentQuestion={currentQuestion}
        />

        <div className="sub_sub_section_wrapper_3">
          {testQuestionsDetails.length > 0 && (
            <QuestionOptions
              showSolution={showSolution}
              getClicked={getClicked}
              textInputSolution={textInputSolution}
              lang={lang}
              questionData={testQuestionsDetails[currentQuestion]}
              currentQuestion={currentQuestion}
              testQuestionsDetails={testQuestionsDetails}
              disableSol={disableSol}
              checkQuestion={checkQuestion}
              handleSingleSelectAnswer={handleSingleSelectAnswer}
              handleMultiSelectAnswer={handleMultiSelectAnswer}
              handleInputFieldChange={handleInputFieldChange}
              sol={sol}
              setSol={setSol}
            />
          )}
        </div>

        <QuestionSwitcher
          showSolution={showSolution}
          updateSolution={updateSolution}
          solDisabled={solDisabled}
          currentQuestion={currentQuestion}
          totalQuestions={testQuestionsDetails.length}
          testQuestionsDetails={testQuestionsDetails}
          handlePreviousQuestionBtn={handlePreviousQuestionBtn}
          handleNextQuestionBtn={handleNextQuestionBtn}
          scrollUp={scrollUp}
          scrollUp2={scrollUp2}
          handleSaveQuestion={handleSaveQuestion}
          handleShow={handleShow}
          handleSol={handleSol}
          sol={sol}
          disabled1={disabled1}
          handleSolution={handleSolution}
          setVideoShow={setVideoShow}
        />

        <ReportErrorModal
          startLoading={startLoading}
          finishLoading={finishLoading}
          userId={userId}
          testQuestionsDetails={testQuestionsDetails}
          currentQuestion={currentQuestion}
        />
        {getClicked() ? (
          <QuestionSolution
            currentSolutionDetails={testQuestionsDetails[currentQuestion]}
            lang={lang}
            currentQuestion={currentQuestion}
          />
        ) : null}

        {getClicked() ? (
          <QVideoSolution
            currentSolutionDetails={testQuestionsDetails[currentQuestion]}
            lang={lang}
            // userData={userData}
            userId={userId}
            handleVideoShow={handleVideoShow}
            videoShow={videoShow}
          />
        ) : null}
      </div>
    </div>
  );
};

export default QuestionDetailPanel;
