import React, { useState, useEffect, useRef } from "react";
import "./CopyCode.scss";
import CopyImg from "../../../../utilities/images/refer&earn/copyImg.png";
import faceBook from "../../../../utilities/images/refer&earn/facebook.png";
import EmailIcon from "../../../../utilities/images/refer&earn/email.png";
import LinkedInIcon from "../../../../utilities/images/refer&earn/linkedin.png";
import clipboard from "../../../../utilities/images/refer&earn/fluent_copy.png";
import images from "../../../../utilities/images/images";
import { toast } from "react-toastify";

const CopyCode = ({ data }) => {
  const defaultCode = data?.referral_code;
  const shareContents = data?.shareContents;

  const [copied, setCopied] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);

  const dropdownRef = useRef(null);

  const handleCopyClick = () => {
    if (!copied) {
      navigator.clipboard.writeText(defaultCode);

      // Disable interactions during toast display
      setCopied(true);

      // Show a customized toast notification
      toast.success("Referral code copied successfully!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
        style: { marginTop: "70px" },
        onClose: () => {
          // Reset interactions after toast is closed
          setCopied(false);
        },
      });
    }
  };

  const handleEmailClick = () => {
    window.location = "mailto:yourmail@domain.com";
    setShowDropdown(false);
  };

  const handleLinkedInClick = () => {
    window.open("https://www.linkedin.com/messaging/", "_blank");
    setShowDropdown(false);
  };

  const handleShareFacebook = () => {
    window.open(
      `https://www.facebook.com/sharer/sharer.php?u=https://your-website-url.com&quote=${shareContents}`,
      "_blank"
    );
    setShowDropdown(false);
  };

  const handleShareWhatsapp = () => {
    handleCopyClick();
    const url = "https://wa.me/";
    window.open(url, "_blank");
  };

  const toggleDropdown = () => {
    console.log("Before toggle:", showDropdown);
    setShowDropdown((prevShowDropdown) => !prevShowDropdown);
    console.log("After toggle:", showDropdown);
  };

  return (
    <div className="copyCodeSection">
      <div className="copyCodeInnerSection">
        <div className="inputWithButton">
          <input
            type="text"
            value={defaultCode}
            readOnly
            className={copied ? "copied" : ""}
          />
          <button
            onClick={handleCopyClick}
            className={`copyButton ${copied ? "copied" : ""}`}
          >
            <img src={images.copyIcon} alt="copy" />
            {copied ? "Copied!" : "Copy"}
          </button>
        </div>

        <div className="shareOptions">
          <button onClick={handleShareWhatsapp}>
            <img src={images.whatsappIcon} alt="whatapp-icon" />
          </button>

          <button onClick={toggleDropdown} className="shareButton">
            <img src={images.shareIcon} alt="share-icon" />
          </button>
        </div>
      </div>

      <div className="shareButtonContainer">
        {showDropdown && (
          <div className="iconsdropdown" ref={dropdownRef}>
            <div className="platformButtons">
              <button onClick={handleShareFacebook}>
                <img src={faceBook} alt="Facebook" />
                <span>Facebook</span>
              </button>
              <button onClick={handleCopyClick}>
                <img src={clipboard} alt="Copy to Clipboard" />
                <span>Copy to Clipboard</span>
              </button>
              <button onClick={handleEmailClick}>
                <img src={EmailIcon} alt="Email" />
                <span>Email</span>
              </button>
              <button onClick={handleLinkedInClick}>
                <img src={LinkedInIcon} alt="LinkedIn" />
                <span>LinkedIn</span>
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CopyCode;
