import * as actionTypes from "./action_type";

const setMockTestsData = (payload) => {
  return {
    type: actionTypes.SET_MOCK_TESTS_DATA,
    payload,
  };
};

const setMockTestsResultData = (payload) => {
  return {
    type: actionTypes.SET_MOCK_TESTS_RESULT_DATA,
    payload,
  };
};

export default {
  setMockTestsData,
  setMockTestsResultData,
};
