import React, { useState, useEffect } from "react";
import axios from "axios";
import { connect } from "react-redux";
import uiActions from "../../../../redux/ui/action";
import { isEmpty } from "../../../../custom_utilities/custom_useful_functions";
import { Link } from "react-router-dom";
import HeaderComponent from "../../structure_components/desktop_screens/header_component/header_component";
import images from "../../../../utilities/images/images";
import { Modal } from "react-bootstrap";
import questionBankActions from "../../../../redux/question_bank/action";
import "./QuestionBankCreateForm.scss";

import {
  url_44,
  url_150,
  url_151,
  url_152,
} from "../../../../custom_utilities/api_services";
import LoaderPopUp from "../../../../components/loader/loader";

const noOfQuestions = [
  {
    numberOfQuestion: 5,
    q_bank_id: 11,
  },
  {
    numberOfQuestion: 10,
    q_bank_id: 12,
  },
  {
    numberOfQuestion: 15,
    q_bank_id: 13,
  },
  {
    numberOfQuestion: 20,
    q_bank_id: 14,
  },
  {
    numberOfQuestion: 25,
    q_bank_id: 15,
  },
  {
    numberOfQuestion: 30,
    q_bank_id: 16,
  },
  {
    numberOfQuestion: 35,
    q_bank_id: 17,
  },
  {
    numberOfQuestion: 40,
    q_bank_id: 18,
  },
  {
    numberOfQuestion: 45,
    q_bank_id: 19,
  },
];

const QuestionBankCreateForm = (props) => {
  const {
    startLoading,
    finishLoading,
    class_id,
    token,
    tokenType,
    course_id,
    setQuestionBankIdAction,
    setQuestionBankNameAction,
    loader,
  } = props;

  const [state, setState] = useState({
    testName: "",
    subjects: {},
    topics: {},
    difficultyLevels: {},
    noOfQuestions: "",
    selectedSubject: "",
    selectedTopics: [],
    selectedDifficultyLevel: "",
    selectedDifficultyLevels: [],
    showTopicModal: false,
    show: false,
    selectAll: false,
  });

  useEffect(() => {
    getSubjectsList();
    getDifficultyLevel();

    if (state.selectedSubject) {
      getTopicsList(state.selectedSubject.sub_id);
    }
  }, []);

  useEffect(() => {
    getTopicsList(state.selectedSubject.sub_id);
  }, [state.selectedSubject]);

  const handleShow = () => {
    setState((prevState) => ({
      ...prevState,
      show: true,
    }));
  };

  const handleClose = () => {
    setState((prevState) => ({
      ...prevState,
      show: false,
    }));
  };

  const getSubjectsList = async () => {
    const headers = {
      Authorization: `${tokenType} ${token}`,
    };

    const data = {
      class_id,
      course_id,
    };
    try {
      startLoading();
      const response = await axios.post(url_151, data, { headers });

      if (response.data.status === 200) {
        finishLoading();
        setState((prevState) => ({
          ...prevState,
          subjects: response.data.data,
          selectedSubject: response.data.data.subject[0],
        }));
      }
    } catch (error) {}
  };

  const getDifficultyLevel = async () => {
    const headers = {
      Authorization: `${tokenType} ${token}`,
    };

    try {
      const response = await axios.get(url_44, { headers });

      if (response.status === 200) {
        setState((prevState) => ({
          ...prevState,
          difficultyLevels: response.data.data,
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const renderDifficultyLevel = () => {
    return (
      <>
        {!isEmpty(state.difficultyLevels?.DifficultyLevel)
          ? state.difficultyLevels?.DifficultyLevel?.map((element) => {
              if (element.toughness !== "Tougher")
                return (
                  <div
                    className="multi_select_diffculty_level"
                    key={element.toughness}
                  >
                    <input
                      type="checkbox"
                      id={`difficulty_${element.toughness}`}
                      name={element.toughness_id}
                      value={element.toughness || ""}
                      onChange={(event) => handleDiffcultLevel(event)}
                      //   checked={
                      //     state.selectedDifficultyLevel == element.toughness
                      //   }
                      checked={state.selectedDifficultyLevels.find((e) => {
                        return e === element.toughness;
                      })}
                    />
                    <label htmlFor={`difficulty_${element.toughness}`}>
                      {element.toughness}
                    </label>
                  </div>
                );
            })
          : null}
      </>
    );
  };

  const getTopicsList = async (subject_id) => {
    const headers = {
      Authorization: `${tokenType} ${token}`,
    };

    const data = {
      subject_id,
      course_id,
    };
    try {
      const response = await axios.post(url_152, data, { headers });
      if (response.data.status === 200) {
        setState((prevState) => ({
          ...prevState,
          topics: response.data.data,
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubjectChange = (subject) => {
    setState((prevState) => ({
      ...prevState,
      selectedSubject: subject,
    }));
  };

  const handleDiffcultLevel = (event) => {
    let { value } = event.target;
    let selectedDifficultyLevels = [...state.selectedDifficultyLevels];
    let findedIndex = selectedDifficultyLevels.findIndex((element) => {
      return element === value;
    });

    if (findedIndex === -1) {
      selectedDifficultyLevels.splice(
        selectedDifficultyLevels.length + 1,
        0,
        value
      );
    } else {
      selectedDifficultyLevels.splice(findedIndex, 1);
    }

    setState((prevState) => ({
      ...prevState,
      selectedDifficultyLevels: selectedDifficultyLevels,
    }));
  };

  const handleNoOfQuestions = (event) => {
    setState((prevState) => ({
      ...prevState,
      noOfQuestions: event.target.value,
    }));
  };

  const selectSubjects = () => {
    const subjects = state?.subjects.subject;

    // we are filtering subjects according to is_show_others key in subject object, becas this api is used for somewher else also

    const filteredSubjects = subjects?.filter((subject) => {
      if (subject.is_show_others === 1) {
        return subject;
      }
    });

    return filteredSubjects?.map((subject, index) => {
      return (
        <div key={`subject${subject.sub_id}`} className="single_select_subject">
          <input
            type="radio"
            id={subject.sub_id}
            value={subject.sub_name}
            checked={state.selectedSubject.sub_name == subject.sub_name}
            onChange={() => handleSubjectChange(subject)}
          />
          <label htmlFor={subject.sub_id}>{subject.sub_name}</label>
        </div>
      );
    });
  };

  const selectNoOfQuestions = () => {
    return noOfQuestions.map((subject) => {
      return (
        <div
          key={`no_of_questns${subject.q_bank_id}`}
          className="single_select_subject"
        >
          <input
            type="radio"
            id={subject.q_bank_id}
            value={subject.numberOfQuestion}
            checked={state.noOfQuestions == subject.numberOfQuestion}
            onChange={handleNoOfQuestions}
          />
          <label htmlFor={subject.q_bank_id}>
            <span> {subject.numberOfQuestion} </span> Q's
          </label>
        </div>
      );
    });
  };

  const selectData = () => {
    const {
      testName,
      selectedSubject,
      selectedTopics,
      selectedDifficultyLevels,
      noOfQuestions,
    } = state;

    if (testName.length === 0) {
      alert("Please enter title of Question Bank");
      return 0;
    } else if (selectedSubject.length === 0) {
      alert("Please select Subject");
      return 0;
    } else if (selectedTopics.length === 0) {
      alert("Please select Topics");
      return 0;
    } else if (selectedDifficultyLevels.length === 0) {
      alert("Please select Difficulty Level");
      return 0;
    } else if (noOfQuestions.length === 0) {
      alert("Please select No of Questions");
      return 0;
    } else if (renderSelectedTopics().length > noOfQuestions) {
      alert("Please Reduce No of Topics");
      return 0;
    } else {
      return 1;
    }
  };

  const handleCreateTest = async () => {
    const result = await selectData();

    if (result) {
      const data = {
        name: state.testName,
        subject: state.selectedSubject.sub_id,
        topic: state.selectedTopics,
        toughness: state.selectedDifficultyLevels,
        no_of_question: state.noOfQuestions,
      };

      try {
        startLoading();
        const response = await axios.post(url_150, data);
        if (response.data.status === 200) {
          finishLoading();
          handleShow();
          setState((prevState) => ({
            ...prevState,
            questionBankId: response.data.question_bank_id,
          }));
          setQuestionBankIdAction(response.data.question_bank_id);
          setQuestionBankNameAction(state.testName);
        }
      } catch (e) {
        finishLoading();
        console.log(e);
      }
    }
  };

  const handleChangeTestName = (event) => {
    setState((prevState) => ({
      ...prevState,
      testName: event.target.value,
    }));
  };

  const handleSelectAllTopics = (event) => {
    let { checked } = event.target;
    let topicList = { ...state.topics };
    let topics = [];

    if (checked) {
      topicList.topic.forEach((element) => {
        topics.push(element.topic_id);
      });
      setState((prevState) => ({
        ...prevState,
        selectedTopics: topics,
        selectAll: checked,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        selectedTopics: [],
        selectAll: checked,
      }));
    }
  };

  const handleChangeTopic = (event) => {
    if (state.selectedSubject === "") {
      alert("Please Select Subject");
      return;
    }

    let { value } = event.target;
    let selectedTopics = [...state.selectedTopics];

    let findedIndex = selectedTopics.findIndex((element) => {
      return element === Number(value);
    });

    if (findedIndex === -1) {
      selectedTopics.splice(selectedTopics.length + 1, 0, Number(value));
    } else {
      selectedTopics.splice(findedIndex, 1);
    }

    setState((prevState) => ({
      ...prevState,
      selectedTopics: selectedTopics,
    }));
  };

  const renderSelectedTopics = () => {
    const newArr = [];
    state.selectedTopics?.forEach((element) => {
      state?.topics?.topic?.map((item) => {
        if (item.topic_id == element) {
          newArr.push(item.topic_name);
        }
      });
    });

    if (newArr.length > 0) {
      return newArr;
    }
  };

  const modalCheck = () => {
    setState((prevState) => ({
      ...prevState,
      showTopicModal: true,
    }));
  };

  return (
    <div>
      <div>
        <HeaderComponent />
        <div className="form_Wrapper">
          <div className="form_InrWrapper">
            <div className="container">
              <div className="form_contentWpr">
                <div className="form_header">
                  {" "}
                  <Link
                    className="backBtn"
                    to={{
                      pathname: "/question_bank",
                    }}
                  >
                    <img src={images.m2_backBtn} alt="backBtn" />
                  </Link>
                  <div className="form_heading">Create Own Practice Paper </div>
                </div>
                <div className="form">
                  <div className="qb_test_name">
                    <label className="qb_label">Test Name</label>
                    <div>
                      <input
                        type="text"
                        placeholder="Test Name"
                        value={state.testName}
                        onChange={handleChangeTestName}
                      />
                    </div>
                  </div>

                  <div className="qb_subjects">
                    <label className="qb_label">Select Subject</label>
                    <div className="qb_subjects_list">
                      {!isEmpty(state.subjects.subject?.length)
                        ? selectSubjects()
                        : null}
                    </div>
                  </div>

                  <div className="qb_topics" onClick={modalCheck}>
                    <label className="qb_label">Select Topics</label>
                    <p className="topic_para">You can select multiple Topics</p>
                    <button
                      type="button"
                      className="topic_content"
                      data-bs-toggle="modal"
                      data-bs-target="#staticBackdrop"
                    >
                      Select Topic
                      <img
                        className="down_arrow"
                        src={images.user_activity_36}
                      />
                    </button>
                  </div>

                  <div
                    class="modal fade"
                    id="staticBackdrop"
                    data-bs-backdrop="static"
                    data-bs-keyboard="false"
                    tabindex="-1"
                    aria-labelledby="staticBackdropLabel"
                    aria-hidden="true"
                  >
                    <div class="modal-dialog modal-dialog-scrollable">
                      <div class="modal-content">
                        <div class="modal-header">
                          <div className="modal-header-left">
                            <p
                              class="modal-title fs-5"
                              id="staticBackdropLabel"
                            >
                              Select multiple Topics
                            </p>
                            <p className="modal-title-sub">
                              {state.selectedSubject.sub_name}
                            </p>
                          </div>
                          <div className="modal-header-right">
                            <button
                              type="button"
                              class="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                            ></button>
                          </div>
                        </div>
                        <div class="modal-body">
                          <div>
                            {!isEmpty(state.topics.topic?.length) ? (
                              <div className="sub_content_wrapper_7 f-start">
                                <div className="text_content_wrapper">
                                  <div className="btn_container">
                                    <div className="single_btn_wrapper">
                                      <input
                                        type="checkbox"
                                        id="select_all"
                                        name="topics"
                                        value="all"
                                        onChange={(event) =>
                                          handleSelectAllTopics(event)
                                        }
                                        checked={state.selectAll}
                                      />
                                      <label htmlFor="select_all">
                                        Select All
                                      </label>
                                    </div>
                                  </div>

                                  <div className="topic_collection_wrapper">
                                    {state.topics.topic?.length
                                      ? state.topics.topic.map(
                                          (element, index) => {
                                            return (
                                              <div
                                                key={`topic_${element.topic_id}`}
                                                className="single_topic_wrapper"
                                              >
                                                <input
                                                  type="checkbox"
                                                  id={`topic_${element.topic_id}`}
                                                  name="topic"
                                                  value={element.topic_id}
                                                  onChange={(event) =>
                                                    handleChangeTopic(event)
                                                  }
                                                  checked={state.selectedTopics.find(
                                                    (e) => {
                                                      return (
                                                        e === element.topic_id
                                                      );
                                                    }
                                                  )}
                                                />
                                                <label
                                                  className="topic_label"
                                                  htmlFor={`topic_${element.topic_id}`}
                                                >
                                                  {element.topic_name}
                                                </label>
                                              </div>
                                            );
                                          }
                                        )
                                      : null}
                                  </div>
                                </div>
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div class="modal-footer">
                          <button
                            type="button"
                            className="done_btn"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          >
                            Done
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="selected_topics">
                    <div className="topics">
                      {state.selectedTopics?.length > 0 &&
                        renderSelectedTopics()?.map((item, index) => {
                          return (
                            <div
                              className="selected_topic"
                              key={`selected_topic_${index}`}
                            >
                              {item}
                            </div>
                          );
                        })}
                    </div>

                    <div className="qb_length">
                      <button
                        type="button"
                        className="topic_content"
                        data-bs-toggle="modal"
                        data-bs-target="#staticBackdro"
                      >
                        {renderSelectedTopics()?.length > 2 ? (
                          <>{renderSelectedTopics()?.length - 2}+</>
                        ) : (
                          <></>
                        )}
                      </button>
                    </div>

                    <div
                      class="modal fade"
                      id="staticBackdro"
                      data-bs-backdrop="static"
                      data-bs-keyboard="false"
                      tabindex="-1"
                      aria-labelledby="staticBackdropLabel"
                      aria-hidden="true"
                    >
                      <div class="modal-dialog modal-dialog-scrollable">
                        <div class="modal-content">
                          <div class="modal-header">
                            <div className="modal-header-left">
                              <p
                                class="modal-title fs-5"
                                id="staticBackdropLabel"
                              >
                                Topics
                              </p>
                            </div>
                            <div className="modal-header-right">
                              <button
                                type="button"
                                class="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                              ></button>
                            </div>
                          </div>
                          <div class="modal-body">
                            <div>
                              {renderSelectedTopics()?.map((element, index) => {
                                return (
                                  <div
                                    className="qb_topic"
                                    key={`modal_selected_topic${index}`}
                                  >
                                    {element}
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                          <div class="modal-footer">
                            <button
                              type="button"
                              className="done_btn"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                            >
                              Done
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <Modal
                    show={state.show}
                    onHide={handleClose}
                    className="qb_onSubmitModal"
                    centered
                  >
                    <Modal.Header closeButton></Modal.Header>
                    <Modal.Body>
                      <div className="qb_submit_Popup">
                        <h5>Test Paper Created</h5>
                        <p>Do you want to attempt test now?</p>
                        <div className="submitCancelBtn">
                          <Link to={{ pathname: "/question_bank" }}>
                            <button className="cancel" onClick={handleClose}>
                              Later
                            </button>
                          </Link>

                          <Link
                            to={{
                              pathname: "/question_bank_test",
                            }}
                          >
                            <button>Start Test</button>
                          </Link>
                        </div>
                      </div>
                    </Modal.Body>
                  </Modal>

                  <div className="qb_difficulty_level">
                    <label className="qb_label">Difficulty Level</label>
                    <p className="difficulty_level_para">
                      You can select multiple Levels
                    </p>
                    <div className="qb_difficulty_level_list">
                      {!isEmpty(state.difficultyLevels?.DifficultyLevel) &&
                        renderDifficultyLevel()}
                    </div>
                  </div>

                  <div className="qb_no_of_qtsns">
                    <label className="qb_label"> Select No. of Questions</label>

                    <div className="select_list">{selectNoOfQuestions()}</div>
                  </div>

                  <div className="qb_create_new_paper_button">
                    <button onClick={handleCreateTest}>Create new paper</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {loader && <LoaderPopUp />}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loader: state.ui.loader,
    token: state.auth.authToken,
    tokenType: state.auth.authType,
    class_id: state.auth.user.class_id,
    target_id: state.userPreference.targetDetails[0].target_id,
    course_id: state.userPreference.courseDetails[0].course_id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => {
      dispatch(uiActions.startLoading());
    },
    finishLoading: () => {
      dispatch(uiActions.finishLoading());
    },
    setQuestionBankIdAction: (payload) => {
      dispatch(questionBankActions.setQuestionBankId(payload));
    },

    setQuestionBankNameAction: (payload) => {
      dispatch(questionBankActions.setQuestionBankName(payload));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QuestionBankCreateForm);
