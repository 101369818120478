import { Fragment, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "./new_offerings_slider.scss";
import axios from "axios";
import { url_109 } from "../../../../../../custom_utilities/api_services";
import { isEmpty } from "../../../../../../custom_utilities/custom_useful_functions";
import images from "../../../../../../utilities/images/images";
import { BASE_URL } from "../../../../../../custom_utilities/baseURL";

//slick-carousel
import Slider from "react-slick";
import exam_cracker from "../../../../user_exercise_test/user_exam_cracker/exam_cracker";

const NewOfferingsSlider = () => {
  const [state, setState] = useState({});
  const history = useHistory();
  useEffect(() => {
    getSliderImg();
  }, []);
  const getSliderImg = async () => {
    try {
      const response = await axios.get(url_109, {});
      if (response.data.status === 200) {
        setState(response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleRedirect = (elem) => {
    switch (elem.name) {
      case "EXAM CRACKER":
        alert("Exam Cracker will be available soon");
        // history.push({
        //   pathname: "/pyq_cps_exam",
        //   state: { tab: "exam_cracker" },
        // });
        break;
      case "OTS - Online Test Series":
        alert("OTS will be available soon");
        // history.push({
        //   pathname: "/pyq_cps_exam",
        //   state: { tab: "ots" },
        // });
        break;
      case "PYQ":
        history.push({
          pathname: "/pyq",
          state: { tab: "pyq" },
        });
        break;
      case "CPS":
        history.push({
          pathname: "/cps",
          state: { tab: "cps" },
        });
        break;
      case "EXAMS":
        history.push({
          pathname: "/exam",
          state: { tab: "exam" },
        });
        break;
      case "exam cracker":
        history.push({
          pathname: "/exam_cracker",
          state: { tab: "exam_cracker" },
        });
        break;
    }
  };

  const settings = {
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
    slidesToShow: 2,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <Fragment>
      <div className="dashboard_offerings_slider_wrapper">
        <div className="dashboard_offerings_slider_inner_wrapper">
          <div className="new_offerings_header_text">
            <h4 className="text-center text-md-start text-lg-start">New Offerings</h4>
          </div>
          <Slider {...settings}>
            {!isEmpty(state.slidelist)
              ? state.slidelist.map((element, index) => {
                  return (
                    <div className="new_offerings_wrapper" key={element.id}>
                      <div className="new_offerings_inner_wrapper">
                        <div className="img_content">
                          <div className="new_offering_image">
                            {element.image ? (
                              <img
                                src={`${BASE_URL}${element.image}`}
                                alt="img"
                              />
                            ) : (
                              <img src={images.user_dashboard_11} alt="img" />
                            )}
                          </div>
                          <div className="new_offerings_content_wrapper">
                            <div className="new_offerings_title">
                              {element.name}
                            </div>
                            <div className="new_offerings_desc">
                              <p>{element.description}</p>
                            </div>
                          </div>
                        </div>
                        <div
                          className="new_offerings_button"
                          onClick={() => handleRedirect(element)}
                        >
                          <p>Start Practice</p>
                        </div>
                      </div>
                    </div>
                  );
                })
              : null}
          </Slider>
        </div>
      </div>
    </Fragment>
  );
};

export default NewOfferingsSlider;
