import { Fragment, useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "./user_create_doubt_content_component.scss";

import images from "../../../../utilities/images/images";
import { isEmpty } from "../../../../custom_utilities/custom_useful_functions";
import axios from "axios";
import {
  url_9,
  url_20,
  url_58,
} from "../../../../custom_utilities/api_services";

//Redux
import { connect } from "react-redux";
import uiActions from "../../../../redux/ui/action";
import { log } from "devtools-detector";
import { toastifyMessage } from "../../../../custom_utilities/toastify_config";

const UserCreateDoubtContentComponent = (props) => {
  let {
    history,
    userPreference,
    startLoading,
    finishLoading,
    slctSubjectId,
    handleClose,
    getQuestionList,
  } = props;
  let { courseDetails, classDetails, batchDetails } = userPreference;
  const [state, setState] = useState({
    doubtData: {
      subjectId: "",
      topicsId: "",
      topic_name: "",
      textualData: "",
      imagesDetails: {
        imageData: {},
        imagesLocalUrl: [],
      },
    },
    subjectCollection: {},
    subjectTopics: {},
  });
  console.log("dbt", state.doubtData);

  useEffect(() => {
    getSubjectList();
    getSubjectTopics();
  }, []);

  const getSubjectList = async () => {
    let data = {
      course_id: courseDetails[0].course_id,
      class_id: classDetails.class_id,
    };

    try {
      const response = await axios.post(url_9, data);
      // console.log(response);
      if (response.data.status === 200) {
        setState((prevState) => ({
          ...prevState,
          subjectCollection: response.data.data,
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };
  //getsubjectTopic
  const getSubjectTopics = async () => {
    let data = {
      course_id: courseDetails[0].course_id,
      subject_id: slctSubjectId,
    };

    try {
      const response = await axios.post(url_58, data);
      console.log(response);

      if (response.data.status === 200) {
        console.log(response);
        setState((prevState) => ({
          ...prevState,
          subjectTopics: response.data.data,
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleFormInputFields = (event) => {
    let { name, value } = event.target;
    let topicData = state.subjectTopics.topic.find(
      (topic) => topic.topic_id == value
    );
    switch (name) {
      case "topic":
        setState((prevState) => ({
          ...prevState,
          doubtData: {
            ...prevState.doubtData,
            topicsId: value,
            topic_name: topicData.topic_name,
          },
        }));
        break;
      case "textual_data":
        setState((prevState) => ({
          ...prevState,
          doubtData: {
            ...prevState.doubtData,
            textualData: value,
          },
        }));
        break;
      default:
        break;
    }
  };
  const handleImageChange = (event) => {
    let { files } = event.target;
    if (state.doubtData.imagesDetails.imagesLocalUrl.length <= 4) {
      let imageURL = createImageLocalURL(files[0]);
      // localImageURL.push(imageURL);
      //console.log("push", localImageURL);
      // for (let i = 0; i < files.length; i++) {
      //   let imageURL = createImageLocalURL(files[i]);
      //   localImageURL.push(imageURL);
      // }
      setState((prevState) => ({
        ...prevState,
        doubtData: {
          ...prevState.doubtData,
          imagesDetails: {
            ...prevState.doubtData.imagesDetails,
            imageData: files,
            imagesLocalUrl: [
              ...prevState.doubtData.imagesDetails.imagesLocalUrl,
              imageURL,
            ],
          },
        },
      }));
    } else {
      alert("Maximum 5 images");
      setState((prevState) => ({
        ...prevState,
        doubtData: {
          ...prevState.doubtData,
          imagesDetails: {
            ...prevState.doubtData.imagesDetails,
            imageData: {},
            // imagesLocalUrl: [],
          },
        },
      }));
    }
  };

  const createImageLocalURL = (imageDetails) => {
    let objectURL = URL.createObjectURL(imageDetails);
    return objectURL;
  };
  //imgdelete
  const deltImg = (element, index) => {
    let imgUrl = state.doubtData.imagesDetails.imagesLocalUrl;
    let imgInd = imgUrl.indexOf(element);
    if (imgInd !== -1) {
      imgUrl.splice(imgInd, 1);
    }
    setState((prevState) => ({
      ...prevState,
      doubtData: {
        ...prevState.doubtData,
        imagesDetails: {
          ...prevState.doubtData.imagesDetails,
          imageData: { ...prevState.doubtData.imageData },
          imagesLocalUrl: [...imgUrl],
        },
      },
    }));
  };
  console.log("aa", courseDetails[0].course_id);
  const handleSubmitForm = async (event) => {
    event.preventDefault();
    //     {
    //       course_id:14
    // class_id:2
    // batch_id:8
    // subject_id:2
    // question:firts chemestry question
    // files:   //type array accept jpeg png jpg
    // }
    let doubtData = { ...state.doubtData };
    console.log("doubts", state.doubtData);
    let formData = new FormData();
    formData.append("course_id", courseDetails[0].course_id);
    formData.append("class_id", classDetails.class_id);
    formData.append("batch_id", batchDetails.batch_id);
    formData.append("subject_id", parseInt(slctSubjectId.subjectId));
    formData.append("topic_id", parseInt(doubtData.topicsId));
    formData.append("topic_name", doubtData.topic_name);
    formData.append("question", doubtData.textualData);
    if (doubtData.imagesDetails.imagesLocalUrl.length) {
      for (let i = 0; i < doubtData.imagesDetails.imageData.length; i++) {
        formData.append("files[]", doubtData.imagesDetails.imageData[i]);
      }
    }

    // for (let pair of formData.entries()) {
    //   console.log(pair[0] + ", " + pair[1]);
    // }
    startLoading();
    const config = {
      headers: { "content-type": "multipart/form-data" },
    };
    try {
      const response = await axios.post(url_20, formData, config);
      console.log(response);
      if (response.data.status === 200) {
        finishLoading();
        handleClose();
        getQuestionList();
        toastifyMessage({
          status: response.data.status,
          message: "Your Doubt has Submitted Successfully!",
        });
        history.push("/doubts");
      } else {
        finishLoading();
        handleClose();
        toastifyMessage({
          status: response.data.status,
          message: response.data.message,
        });
      }
    } catch (error) {
      finishLoading();
      console.log(error.response);
      // if (error.response.status === 500) {
      //   alert(error.response.statusText);
      // }
    }
  };

  const handleBackBtn = () => {
    history.push("/doubts");
  };
  const renderSubject = () => {
    let selectedSubject;
    let subject = state.subjectCollection.subject;
    subject.map((element) => {
      if (element.sub_id == slctSubjectId?.subjectId) {
        selectedSubject = element.sub_name;
      }
    });
    return selectedSubject;
  };
  //subjectTopics
  console.log(state);
  return (
    <Fragment>
      <ToastContainer />

      <div className="userCreateDoubtContentComponent_wrapper">
        <div className="userCreateDoubtContentComponent_inner_wrapper">
          <div className="sec_1_wrapper">
            <div className="sec_1_inner_wrapper">
              {/* <div className="btn_wrapper">
                <button type="button" onClick={() => handleBackBtn()}>
                  <img src={images.user_activity_1} />
                </button>
              </div> */}
              <div className="text_content_wrapper">
                <p className="text_content">Create new doubts</p>
              </div>
            </div>
          </div>
          <div className="sec_2_wrapper">
            <div className="sec_2_inner_wrapper">
              <div className="content_wrapper">
                <div className="content_inner_wrapper">
                  <form onSubmit={(event) => handleSubmitForm(event)}>
                    <div className="select_subject">
                      <span>
                        {state.subjectCollection.subject && renderSubject()}
                      </span>
                    </div>
                    <div className="sub_content_wrapper_1">
                      <div className="select_wrapper">
                        <select
                          name="topic"
                          onChange={(event) => handleFormInputFields(event)}
                          required
                        >
                          <option value="">Select Topics</option>
                          {!isEmpty(state.subjectTopics)
                            ? state.subjectTopics.topic.length
                              ? state.subjectTopics.topic.map(
                                  (element, index) => {
                                    return (
                                      <option
                                        key={index}
                                        value={element.topic_id}
                                      >
                                        {element.topic_name}
                                      </option>
                                    );
                                  }
                                )
                              : null
                            : null}
                        </select>
                      </div>
                    </div>
                    <div className="sub_content_wrapper_2">
                      <div className="textarea_wrapper">
                        <textarea
                          name="textual_data"
                          value={state.doubtData.textualData}
                          rows="5"
                          placeholder="Write your question..."
                          onChange={(event) => handleFormInputFields(event)}
                          required
                        ></textarea>
                      </div>
                    </div>
                    <div className="sub_content_wrapper_3">
                      <div className="show_uploading_img_wrapper">
                        {state.doubtData.imagesDetails.imagesLocalUrl.length
                          ? state.doubtData.imagesDetails.imagesLocalUrl.map(
                              (element, index) => {
                                return (
                                  <div key={index} className="image_wrapper">
                                    <img src={element} />
                                    <span onClick={() => deltImg(element)}>
                                      x
                                    </span>
                                  </div>
                                );
                              }
                            )
                          : null}
                      </div>
                      <div className="input_wrapper">
                        <label className="custom_file_upload">
                          <input
                            type="file"
                            id="upload_image"
                            accept="image/png, image/jpeg, image/jpg"
                            multiple
                            onChange={(event) => handleImageChange(event)}
                            // value={state.doubtData.imagesDetails.imageData}
                          />
                          <div className="upload_content_action_wrapper">
                            Upload Image
                          </div>
                        </label>
                      </div>
                      <div className="multiple_img_upload"></div>
                    </div>
                    <div className="sub_content_wrapper_4">
                      <div className="btn_wrapper">
                        <button type="submit">POST</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => {
      dispatch(uiActions.startLoading());
    },
    finishLoading: () => {
      dispatch(uiActions.finishLoading());
    },
  };
};

export default connect(
  null,
  mapDispatchToProps
)(UserCreateDoubtContentComponent);
