import { reeedemCash } from "./ReferAndEarnModals/Api";

export const REFER_AND_EARN_TYPES = {
  REFER_AND_EARN_DATA: "refer_and_earn_data",
  TERMS_AND_CONDITION: "terms_and_condition",
  REFERAL_LIST: "referal_list",
};

export const REFER_AND_EARN_SIZE = {
  REFER_AND_EARN_SMALL: "refer_and_earn_small",
  REFER_AND_EARN_BIG: "refer_and_earn_big",
};

export const REFER_AND_EARN_MODALS = {
  INIT: "",
  UPI_DETAILS: "upi_details",
  BANK_DETAILS: "bank_details",
  VERIFY_DETAILS: "verify_details",
  SUCCESSFULL: "successfull",
  CANCEL_MODIFY: "cancel_modify",
  DETAILS_UPDATED: "details_updated",
};

export const renderButton = (buttonText) => {
  return <button> {buttonText}</button>;
};

export const setDetailsInLocalStorage = (key, value) => {
  return window.localStorage.setItem(key, JSON.stringify(value));
};

export const getDetailsFromLocalStorage = (key) => {
  return window.localStorage.getItem(key);
};

export const removeString = (data) => {
  return data.replace(/['"\\]/g, "");
};

export const handleReedem = (
  checkedRefferals,
  token,
  tokenType,
  setModalType,
  dispatch
) => {
  reeedemCash(checkedRefferals, token, tokenType, setModalType, dispatch);
  // setModalType(REFER_AND_EARN_MODALS.SUCCESSFULL);
};

export const checkType = (type) => {
  return type === "UPI";
};

export const handleModify = (setModalType) => {
  const response = getDetailsFromLocalStorage("TYPE");

  if (removeString(response) === "UPI") {
    setModalType(REFER_AND_EARN_MODALS.UPI_DETAILS);
  } else {
    setModalType(REFER_AND_EARN_MODALS.BANK_DETAILS);
  }
};

export const totalRewardCash = (arr) => {
  const initalValue = 0;

  const reduceARr = arr.reduce(
    (acc, curr) => acc + parseInt(curr?.reward_cash),
    initalValue
  );

  return Math.round(reduceARr);
};

export const checkDisable = (type, isRedeemable) => {
  if (isRedeemable == "false") {
    return "disable";
  }

  switch (type) {
    case "In Progress":
      return "disable";
      break;

    case "Requested":
      return "disable";
      break;

    case "Redeemed":
      return "disable";
      break;

    default:
      return "";
  }
};

export const isValidUpiId = (upiId) => {
  if (!upiId.includes("@")) {
    return false;
  }

  const [username, provider] = upiId.split("@");

  if (!username || !provider) {
    return false;
  }

  const validUpiIdPattern = /^[a-zA-Z0-9._]+@[a-zA-Z0-9.-]+$/;
  if (!validUpiIdPattern.test(upiId)) {
    return false;
  }

  if (upiId.length > 50) {
    return false;
  }

  return true;
};

export const bankDetailsIncorrectAlert = (messages) => {
  switch (messages) {
    case messages?.accountHolderName.length > 0:
      alert(messages.accountHolderName);
      break;

    case messages?.accountHolderName.length > 0:
      alert(messages.ifscCode);
      break;

    case messages?.accountHolderName.length > 0:
      alert(messages.accountNumber);
      break;

    case messages?.accountHolderName.length > 0:
      alert(messages.confirmAccountNumber);
      break;
  }
};

export const validateBankFields = (bankDetails, setValidationMessages) => {
  let isValid = true;
  const messages = {};

  // Validate Account Holder Name
  if (!bankDetails.accountHolderName) {
    messages.accountHolderName = "Account holder name is required.";
    isValid = false;
  }

  // Validate IFSC Code
  const ifscPattern = /^[A-Z]{4}0[A-Z0-9]{6}$/;
  if (!ifscPattern.test(bankDetails.ifscCode)) {
    messages.ifscCode = "Invalid IFSC code.";
    isValid = false;
  }

  // Validate Account Number
  const accountNumberPattern = /^\d{9,18}$/; // Adjust the pattern based on your requirements
  if (!accountNumberPattern.test(bankDetails.accountNumber)) {
    messages.accountNumber = "Invalid account number.";
    isValid = false;
  }

  // Validate Confirm Account Number
  if (bankDetails.accountNumber !== bankDetails.confirmAccountNumber) {
    messages.confirmAccountNumber = "Account numbers do not match.";
    isValid = false;
  }
  setValidationMessages(messages);

  // bankDetailsIncorrectAlert(messages);

  return isValid;
};
