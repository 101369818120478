import React from "react";
import "./QuestionListPanel.scss";
import QuestionStatusLabel from "./questionStatusLabel/QueLabel";

const QuestionListPanel = ({
  testQuestionsDetails,
  currentQuestion,
  handleQuestionJump,
  questionsCollectionRef,
  lang,
  handleShow,
  handleSaveQuestion,
  handleSol,
  handleSolution,
  setVideoShow,
}) => {
  return (
    <div className="questions-panel">
      <div className="questions-panel-header">
        <div className="header-text">All Questions</div>
        <button
          onClick={() => {
            handleSaveQuestion();
            handleShow();
          }}
        >
          Submit Test
        </button>
      </div>

      <div className="questions-panel-markings">
        <div className="question-marking">
          <label className="marking-icon marking-icon-completed"></label>
          <span className="marking-text">Completed</span>
        </div>
        <div className="question-marking">
          <label className="marking-icon marking-icon-pending"></label>
          <span className="marking-text">Pending</span>
        </div>
      </div>

      <div className="questions-list" ref={questionsCollectionRef}>
        {testQuestionsDetails?.length
          ? testQuestionsDetails?.map((element, index) => (
              <QuestionStatusLabel
                key={index}
                question={element}
                index={index}
                currentQuestion={currentQuestion}
                handleQuestionJump={handleQuestionJump}
                testQuestionsDetails={testQuestionsDetails}
                lang={lang}
                handleSolution={handleSolution}
                handleSol={handleSol}
                setVideoShow={setVideoShow}
              />
            ))
          : null}
      </div>
    </div>
  );
};

export default QuestionListPanel;
