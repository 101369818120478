import { Modal } from "react-bootstrap";
import ReactDOM from "react-dom";
import classes from "./CustomModal.module.css";

const Modals = ({ show, onHide, className, clsClass, children, ...rest }) => {
  return ReactDOM.createPortal(
    <Modal show={show} centered="true" onHide={onHide} {...rest}>
      <Modal.Body className={`${className} ${classes.modalbody}`}>
        <button
          type="button"
          class={`btn-close ${classes["close-btn"]} ${clsClass}`}
          onClick={onHide}
          aria-label="Close"
        ></button>
        {children}
      </Modal.Body>
    </Modal>,
    document.getElementById("modal")
  );
};

export default Modals;
