import { Fragment } from "react";
import "./user_bookmarks.scss";

///////////////////Desktop
import LeftSideBarComponent from "../structure_components/desktop_screens/left_sideBar_component/left_sideBar_component";
import HeaderComponent from "../structure_components/desktop_screens/header_component/header_component";

///////Mobile////////////////////
import MobileLeftSideBarComponent from "../structure_components/mobile_screens/mobile_left_sideBar_component/mobile_left_sideBar_component";
import MobileHeaderComponent from "../structure_components/mobile_screens/mobile_header_component/mobile_header_component";
import MobileRightSideBarComponent from "../structure_components/mobile_screens/mobile_right_sideBar_component/mobile__right_sideBar_component";

import UserBookmarksContentComponent from "./user_bookmarks_content_component/user_bookmarks_content_component";
import LoaderPopUp from "../../../components/loader/loader";
//Redux
import { connect } from "react-redux";
import Footer_component from "../structure_components/desktop_screens/footer_component/Footer_component";

const UserBookmarks = (props) => {
  let { loader, history, userData, userPreference } = props;

  const isMob = () => {
    if (window.innerWidth <= 480) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Fragment>
      <div className="user_bookmarks_wrapper">
        <div className="user_bookmarks_inner_wrapper">
          {isMob() ? (
            /////for Mobile Screens////////////////////
            <Fragment>
              <MobileLeftSideBarComponent />
              <MobileHeaderComponent
                userPreference={userPreference}
                userData={userData}
              />
              <MobileRightSideBarComponent history={history} />
              <UserBookmarksContentComponent history={history} />
            </Fragment>
          ) : (
            /////for Screens greater than Mobile Screens////////////////////
            <Fragment>
              <div className="user_bookmarks_right_wrapper">
                <HeaderComponent history={history} />
                <UserBookmarksContentComponent history={history} />
              </div>
            </Fragment>
          )}
        </div>
        <Footer_component />
      </div>
      {loader && <LoaderPopUp />}
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    loader: state.ui.loader,
    userData: state.auth.user,
    userPreference: state.userPreference,
  };
};

export default connect(mapStateToProps)(UserBookmarks);
