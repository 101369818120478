import React from "react";
import { connect } from "react-redux";
import CustomModal from "../../components/Modal/CustomModal";
import images from "../../utilities/images/images";
import "./BlockedtoAccessApp.scss";

const BlockedtoAccessApp = ({ isBlocked, remark }) => {
  return (
    <CustomModal
      show={isBlocked}
      clsClass="btn-hide"
      dialogClassName="restriction-modal"
    >
      <img src={images.prohibition} className="restriction-image" />
      <div className="modal-text">Your access is being Blocked due to</div>
      <div className="restrict-remark">{remark}</div>
      <div className="restrict-more-info">
        Please contact Admin for further assistance
      </div>
    </CustomModal>
  );
};

const mapStateToProps = (state) => {
  return {
    isBlocked: state.BlockUserSlice.isBlocked,
    remark: state.BlockUserSlice.remark,
  };
};

export default connect(mapStateToProps, null)(BlockedtoAccessApp);
