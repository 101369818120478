import React from "react";
import "./ImprovementsSuggestions.scss";
import { isEmpty } from "../../../../../../custom_utilities/custom_useful_functions";

const ImprovementsSuggestions = (props) => {
  let { improvements_suggestions } = props;

  return (
    <div className="improvementsSuggestions">
      <div className="section-heading">Improvements & suggestions</div>
      <div className="content">
        <div className="cards">
          <div className="card">
            <div className="text">Time Taken</div>
            <div className="num">
              {improvements_suggestions?.data?.time_taken} hr
            </div>
          </div>

          <div className="card">
            <div className="text">Attempted</div>
            <div className="num">
              {improvements_suggestions?.data?.attempted_qns} Q’s
            </div>
          </div>

          <div className="card">
            <div className="text">Avg Time</div>
            <div className="num">
              {improvements_suggestions?.data?.avg_time.toFixed(2)} hr
            </div>
          </div>

          <div className="card">
            <div className="text">Negative marks</div>
            <div className="num">
              {improvements_suggestions?.data?.negative_marks?.negative_marks}
            </div>
          </div>

          <div className="card">
            <div className="text">Tough</div>
            <div className="num">
              {improvements_suggestions?.data?.toughness}
            </div>
          </div>

          <div className="card">
            <div className="text">Easy</div>
            <div className="num">{improvements_suggestions?.data?.easy}</div>
          </div>
        </div>
        <div className="circles">
          <div className="circlesContent">
            <div className="circle cr1"></div>
            <div className="text">Better</div>
          </div>
          <div className="circlesContent">
            <div className="circle cr2"></div>
            <div className="text">Need Improvement</div>
          </div>
        </div>
        <div className="topics-to-focus-on">
          <div className="section-heading">Topics to Focus on</div>
          <div className="container">
            <div className="row">
              {!isEmpty(improvements_suggestions.subjects)
                ? improvements_suggestions.subjects.length
                  ? improvements_suggestions.subjects.map((element, index) => {
                      return (
                        <div key={index} className="col-sm column">
                          <div className="subject">{element.subject}</div>
                          <ul>
                            {element?.topics?.map((item, index) => (
                              <li key={index}>{item.topic}</li>
                            ))}
                          </ul>
                        </div>
                      );
                    })
                  : null
                : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImprovementsSuggestions;
