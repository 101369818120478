import React, { useMemo } from "react";
import "./QuestionVideoSolution.scss";
import ShakaSolutionViewVideoPlayer from "../../../../../../../../../custom_utilities/shakaVideoPlayer/ShakaSolutionViewVideoPlayer";

const QuestionVideoSolution = ({
  handleVideoShow,
  videoShow,
  currentQuestionDetails = {},
  lang,
  userId,
  getClicked,
}) => {
  const { video_id_english, video_id_hindi } = currentQuestionDetails;
  const isHindi = !lang && video_id_hindi;
  const selectedVideoId = isHindi ? video_id_hindi : video_id_english;

  const urlPattern = /^https:\/\/speed\.mogiio\.com\/embed3\/[0-9a-f]{24}$/;

  // Define the handleOnLoad function using useMemo to memoize it
  const handleOnLoad = useMemo(() => {
    return () => {
      const frame = document.getElementById("iframe");
      frame.contentWindow.postMessage(
        `{"activate": true, "userId": "${userId}"}`,
        "*"
      );

      // Handle message event for receiving video timing details
      const eventListener = (e) => {
        if (e.data && e.data.currentTime) {
          const videoTimingDetails = { currentTime: e.data.currentTime };
          sessionStorage.setItem(
            "videoTimingDetails",
            JSON.stringify(videoTimingDetails)
          );
        }
      };

      window.addEventListener("message", eventListener);

      // Cleanup the event listener when the component unmounts
      return () => {
        window.removeEventListener("message", eventListener);
      };
    };
  }, [userId]);

  // Check if selectedVideoId is empty
  if (!selectedVideoId) {
    return null;
  }

  // const buttonLabel = lang ? "View Video Solution" : "वीडियो समाधान देखें";
  const buttonLabel =
    videoShow && !lang
    ? "वीडियो समाधान बंद करें" // Close Video Solution
    : videoShow && lang
    ? "Close Video Solution"
    : !videoShow && !lang
    ? "वीडियो समाधान देखें" // View Video Solution
    : "View Video Solution";

  const isUrl = urlPattern.test(selectedVideoId);
  const isCryptId = /^[0-9_]+$/.test(selectedVideoId);

  return (
    <div className="videoWrapper">
      {((isUrl || isCryptId) && getClicked()) && (
        <button className="view_videoSolution_btn" onClick={handleVideoShow}>
          {buttonLabel}
        </button>
      )}

      {videoShow && isUrl && getClicked() ? (
        <iframe
          id="iframe"
          src={selectedVideoId}
          onLoad={handleOnLoad}
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      ) : videoShow && isCryptId ? (
        <ShakaSolutionViewVideoPlayer
          id_video={selectedVideoId}
          className="shakaPlayer"
        />
      ) : null}
    </div>
  );
};

export default QuestionVideoSolution;
