import * as actionTypes from "./action_type";

 const setDay = (payload) => {
    return { type: actionTypes.SET_DAY, payload};
};

const setSubject = (payload) => {
    console.log("setSubject action calling >>>>>>>>", payload)
    return { type: actionTypes.SET_SUBJECT, payload};
};

const setQuestionsList = (payload) => {
    return {
      type: actionTypes.SET_QUESTIONS_LIST,
      payload,
    };
};

const setPyqTestsResultData = (payload) => {
    return {
      type: actionTypes.SET_PYQ_TESTS_RESULT_DATA,
      payload,
    };
};

export default {
    setDay,
    setSubject,
    setQuestionsList,
    setPyqTestsResultData,
}
