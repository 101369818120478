import { createSlice } from "@reduxjs/toolkit";

const WatchedVideoDetailsSlice = createSlice({
  name: "WatchedVideoDetails",

  initialState: {
    watchedVideoDetails: {},
    watchedDuration: 0,
    lastPaused: 0,
    totalWatchTime: 0,
  },
  reducers: {
    setWatchedVideoDetails: (state, action) => {
      state.watchedVideoDetails = action.payload;
    },

    setTotalWatchTime: (state, action) => {
      console.log(
        "total-------------------------watched time------------------------------>",
        action.payload
      );
      state.totalWatchTime = action.payload;
    },

    setVideoLastPaused: (state, action) => {
      // console.log(
      //   "last paused------------------------------ reducer>",
      //   action.payload
      // );
      state.lastPaused = action.payload;
    },
  },
});

export const {
  setWatchedVideoDetails,
  setTotalWatchTime,
  setVideoLastPaused,
} = WatchedVideoDetailsSlice.actions;
export default WatchedVideoDetailsSlice.reducer;
