
import { Fragment } from "react";
import HeaderComponent from "../../../../../../structure_components/desktop_screens/header_component/header_component";
import BookmarkTestViewContentComponent from "./BookmarkTestViewContentComponent";
import LoaderPopUp from "../../../../../../../../components/loader/loader";
import { useLocation } from "react-router-dom";

// Redux
import { connect } from "react-redux";

const BookmarkTestViewComponent = (props) => {
  let { loader, history, userData } = props;

  let location = useLocation();

  return (
    <Fragment>
      <div className="bookmark_view_component_wrapper">
        <div className="bookmark_view_component_inner_wrapper">
          <div className="test_component_wrapper">
            <div className="test_component_inner_wrapper">
              <Fragment>
                <HeaderComponent history={history} />
                <BookmarkTestViewContentComponent
                  history={history}
                  userData={userData}
                />
              </Fragment>
            </div>
          </div>
        </div>
      </div>
      {loader && <LoaderPopUp />}
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    loader: state.ui.loader,
    userData: state.auth.user,
    userPreference: state.userPreference,
  };
};

export default connect(mapStateToProps)(BookmarkTestViewComponent);
