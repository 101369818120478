import React, { useState, useEffect } from "react";
import "./exam_cracker_payment_page.scss";
import HeaderComponent from "../../../structure_components/desktop_screens/header_component/header_component";
import LoaderPopUp from "../../../../../components/loader/loader";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import images from "../../../../../utilities/images/images";
import {
  url_59,
  url_19,
  url_8,
  url_127,
} from "../../../../../custom_utilities/api_services";
import { isEmpty } from "../../../../../custom_utilities/custom_useful_functions";
import { Modal, Button } from "react-bootstrap";
import { connect } from "react-redux";
import actions from "../../../../../redux/ui/action";
import authActions from "../../../../../redux/auth/action";
import { sha512 } from "js-sha512";
import CcAvenuePayment from "../../../../../custom_utilities/ccavence_payment/CcAvenuePayment";
import { setRefferalCode } from "../../../../../redux/packages_store/PackageSlice";
import { checkXSSAttackThroughInput } from "../../../../../custom_utilities/custom_useful_functions";

const ExamCrackerPaymentPage = (props) => {
  let {
    loader,
    history,
    userData,
    userPreference,
    token,
    tokenType,
    userId,
    setUser,
    setRefferalCode,
  } = props;

  const [packageDetails, setPackageDetails] = useState({});
  const [modalFlag, setModalFlag] = useState(false);
  const [cpnInput, setCpnInput] = useState("");
  const [couponDetails, setCouponDetails] = useState({});
  const [isDisableButton, setIsDisableButton] = useState(true);
  const [cpnAmt, setCpnAmt] = useState("");

  let location = useLocation();
  let package_name = location.state.packageData.package_name;
  let package_amount = location.state.packageData.amount;
  let amount = (package_amount * 2) / 2;
  let package_discount = location.state.packageData.discount;
  let package_duration = location.state.packageData.duration;
  let package_description = location.state.packageData.description;

  useEffect(() => {
    getPackageDetails();
  }, []);

  const handleClose = () => {
    setModalFlag(false);
  };

  const paymentShow = () => {
    setModalFlag(!modalFlag);
    setCpnAmt(package_amount);
  };

  const applyCouponMethod = async () => {
    const { package_id, amount } = packageDetails.planner_overview;

    const headers = {
      Authorization: `${tokenType} ${token}`,
    };

    const data = {
      coupon_code: cpnInput,
      user_id: userId,
      package_id,
      amount,
    };
    const response = await axios.post(url_59, data, { headers });
    if (response.data.status === 200) {
      setCouponDetails(response.data.data);
    } else {
      alert("Invalid Coupon");
    }
  };

  let orignalAmount = package_amount - package_discount;
  let couponDicountAmount = (couponDetails.discount / 100) * orignalAmount;
  let updatedAmount = orignalAmount - couponDicountAmount;

  const currentData = new Date();
  let date =
    currentData.getFullYear() +
    "-" +
    (currentData.getMonth() + 1) +
    "-" +
    currentData.getDate();
  let time =
    currentData.getHours() +
    ":" +
    currentData.getMinutes() +
    ":" +
    currentData.getSeconds();
  var dateTime = date + " " + time;

  const updateUser = async () => {
    // startLoading();
    try {
      const response = await axios.get(url_8);
      if (response.data.status === 200) {
        setUser(response.data.data);
        // finishLoading();
        return 1;
      } else {
        return 0;
      }
    } catch (error) {
      console.log(error);
      return 0;
    }
  };

  const activePackage = async (data) => {
    const headers = {
      Authorization: `${tokenType} ${token}`,
    };

    let dataDetails = {
      course_id: userPreference?.courseDetails[0].course_id,
      batch_id: userPreference?.batchDetails.batch_id,
      package_id: packageDetails?.planner_overview.package_id,
      txn_amount: packageDetails?.planner_overview.amount,
      txn_discount_amount: packageDetails?.planner_overview.discount,
      txn_amount: "0.00",
      //txn_discount_amount: "",
      txn_date: dateTime,
      package_duration: packageDetails?.planner_overview.duration,
      txnid: couponDetails?.CouponCode,
    };

    // startLoading();
    try {
      const response = await axios.post(url_19, dataDetails, { headers });
      if (response.data.status === 200) {
        // finishLoading();
        // alert(response.data.message);
        //let returnedResponse = await updateUser();
        history.push({
          pathname: "/exam_cracker",
          state: { tab: "exam_cracker" },
        });
      } else {
        alert(response.message);
      }
      // finishLoading();
    } catch (error) {
      console.log(error);
      // finishLoading();
    }
  };

  const saveUserTransactions = async (data) => {
    const headers = {
      Authorization: `${tokenType} ${token}`,
    };

    const dataDetails = {
      course_id: userPreference?.courseDetails[0].course_id,
      batch_id: userPreference?.batchDetails.batch_id,
      package_id: packageDetails?.planner_overview.package_id,
      txn_amount: packageDetails?.planner_overview.amount,

      txn_discount_amount: packageDetails?.planner_overview.discount,
      txn_amount: packageDetails?.planner_overview.amount,

      txn_date: data.response.addedon,
      package_duration: packageDetails?.planner_overview.duration,
      txnid: data.response.txnid,
    };

    try {
      const response = await axios.post(url_19, dataDetails, { headers });
      if (response.data.status === 200) {
        // finishLoading();
        // alert(response.data.message);
        let returnedResponse = await updateUser();
        if (returnedResponse) {
          history.push({
            pathname: "/exam_cracker",
            state: { tab: "exam_cracker" },
          }); // after successfull purchasing  should redirect to this page or route
        } else {
          history.push({
            pathname: "/exam_cracker",
            state: { tab: "exam_cracker" },
          }); // after successfull purchasing  should redirect to this page or route
        }
      }
    } catch (error) {
      console.log(error);
      // finishLoading();
    }
  };

  const getReference = () => {
    //you can put any unique reference implementation code here
    let text = "";
    let possible =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-.=";

    for (let i = 0; i < 15; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));

    return text;
  };

  const redirectPayUMoney = (data) => {
    //use window.bolt.launch if you face an error in bolt.launch
    window.bolt.launch(data, {
      responseHandler: function (response) {
        // your payment response Code goes here

        if (response.response.txnStatus.toLowerCase() === "success") {
          saveUserTransactions(response);
        } else {
          alert(response.response.txnMessage);
        }
      },
      catchException: function (response) {},
    });
  };

  const getPackageDetails = async () => {
    const headers = {
      Authorization: `${tokenType} ${token}`,
    };

    const data = {
      package_id: location.state.packageData.package_id,
    };

    try {
      const response = await axios.get(
        `${url_127}?package_id=${location.state.packageData.package_id}`,
        { headers }
      );

      if (response.data.status === 200) {
        setPackageDetails(response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const pricePurchaseBtn = (packageDetails) => {
    handlePaymentInformationPayBtn(packageDetails);
  };

  const handlePaymentInformationPayBtn = (packageDetails) => {
    let userDataDetails = { ...userData };
    if (userDataDetails?.email) {
      if (packageDetails?.planner_overview.amount) {
        let txnId = getReference();
        let merchantKey = "ObWj6L",
          salt = "jFCXe6Kt";

        let hashSequence = `${merchantKey}|${txnId}|${
          packageDetails?.planner_overview.amount -
          packageDetails?.planner_overview.discount
        }|${packageDetails?.planner_overview.package_name}|${
          userDataDetails?.name
        }|${userDataDetails?.email}|||||||||||${salt}`;

        let hashValue = sha512(hashSequence);

        let payURequestParams = {
          key: merchantKey, ///*** Merchant key from PayuMoney Dashboard ***/
          txnid: txnId, ///*** Unique Transaction ID***/
          // amount: packageDetails.planner_overview.amount, ///*** Amount to be paid ***/
          amount:
            packageDetails?.planner_overview.amount -
            packageDetails.planner_overview.discount,
          firstname: userDataDetails?.name, ///*** Name of the User ***/
          email: userDataDetails?.email, ///** Email Id of User **/
          phone: userDataDetails?.mobile, ///** Mobile number of User **/
          productinfo: packageDetails?.planner_overview.package_name, ///* Product name */
          surl: "dummyURL", ///* Success callback URL */
          furl: "dummyURL", ///* Failure callback URL */
          hash: hashValue,
        };

        redirectPayUMoney(payURequestParams);
      } else {
        alert("Amount is not proper");
        return;
      }
    } else {
      alert(
        "Please haven't update your email in your profile. Please update it"
      );
      history.push("/user_edit_profile");
    }
  };

  const handleInputchange = async (value) => {
    const response = await checkXSSAttackThroughInput(value);

    if (!response) {
      return;
    } else {
      setCpnInput(value);
    }
  };

  return (
    <>
      <div className="examCrackerPaymentPageWrapper">
        <div className="examCrackerPaymentPageInrWrpr">
          <HeaderComponent history={history} />

          <div className="examCrackerPaymentWrapper">
            <div className="container">
              <div className="examCrackerPaymentInner">
                <Link
                  className="backBtn"
                  to={{
                    pathname: "/exam_cracker",
                    state: { tab: "exam_cracker" },
                  }}
                >
                  <img src={images.m2_backBtn} alt="backBtn" />
                </Link>

                <header className="examCrackerHeading">{package_name}</header>

                {!isEmpty(packageDetails) ? (
                  <Modal
                    show={modalFlag}
                    onHide={handleClose}
                    className="coupon_modal"
                    centered
                  >
                    <Modal.Header
                      closeButton
                      className="mdl_hdr"
                    ></Modal.Header>
                    <Modal.Body className="mbd">
                      <div className="coupon_wrapper">
                        <div className="coupon_wraper_inr">
                          <h2>
                            <strike style={{ marginRight: "20px" }}>
                              {`₹ ${amount}`}
                            </strike>{" "}
                            <span style={{ color: "green" }}>{`₹ ${
                              package_amount - package_discount
                            }`}</span>
                          </h2>
                          <p>
                            If you have coupon code or discount code provided by
                            motion education
                          </p>
                          <div className="coupon_input">
                            <input
                              type="text"
                              placeholder="Coupon code"
                              value={cpnInput}
                              onChange={(e) =>
                                handleInputchange(e.target.value)
                              }
                            />
                            <button onClick={() => applyCouponMethod(cpnInput)}>
                              Apply Code
                            </button>
                          </div>

                          <div className="updated_amount">
                            Updated Amount
                            {!isEmpty(couponDetails) ? (
                              <span>₹ {updatedAmount.toFixed(0)}</span>
                            ) : (
                              <span>{`₹ ${orignalAmount}`}</span>
                            )}
                          </div>
                          {updatedAmount == 0 ? (
                            <div className="pay_now" closeButton>
                              <button
                                onClick={() => activePackage(packageDetails)}
                              >
                                Active
                              </button>
                            </div>
                          ) : (
                            <CcAvenuePayment
                              pricePurchaseBtn={pricePurchaseBtn}
                              packageDetails={packageDetails.planner_overview}
                              amount={updatedAmount || orignalAmount}
                            />
                          )}
                        </div>
                      </div>
                    </Modal.Body>
                  </Modal>
                ) : null}
                <div className="examCrackerPaymentContent">
                  <div className="examCrackerLeft">
                    <div className="examCrackerDesc">
                      {!isEmpty(packageDetails) ? (
                        <div>
                          <h3>Description</h3>
                          <p>{package_description}</p>
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div className="examCrackerRight">
                    <div className="priceDetails">
                      <div className="priceDetailsContent">
                        <h4>Price Details</h4>

                        <div className="durationBox">
                          <div className="durationName">Duration</div>
                          <div className="durationMonths">
                            <span>{package_duration} </span>
                            <span>months</span>
                          </div>
                        </div>

                        <div className="priceBox">
                          <div className="priceName">Price</div>
                          <div className="price">₹ {package_amount}</div>
                        </div>

                        <div className="discountBox">
                          <div className="dicountName">Discount</div>
                          <div className="dicountPrice">
                            ₹ {package_discount}
                          </div>
                        </div>

                        <div className="totalPriceContent">
                          <div className="totalPriceText">Total Price</div>
                          <div className="totalPrice">
                            Rs. {package_amount - package_discount}
                            /-
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="buyNowBtn"
                      onClick={() => {
                        paymentShow();
                        setRefferalCode(" ");
                      }}
                    >
                      <button>Buy Now</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loader && <LoaderPopUp />}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.authToken,
    tokenType: state.auth.tokenType,
    userId: state.auth.user.user_id,
    userPreference: state.userPreference,
    userData: state.auth.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => {
      dispatch(actions.startLoading());
    },

    finishLoading: () => {
      dispatch(actions.finishLoading());
    },

    setUser: (payload) => {
      dispatch(authActions.setUser(payload));
    },

    setRefferalCode: (payload) => {
      dispatch(setRefferalCode(payload));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExamCrackerPaymentPage);
