import { useEffect } from "react";

const useMathjax = (currentQuestionDetails, lang=null, solutionVar=null) => {
    useEffect(() => {
        const script = document.createElement('script');
      
        script.src = "https://cdn.jsdelivr.net/npm/mathjax@3/es5/tex-mml-chtml.js";
        script.async = true;
      
        script.onload = () => {
          window.MathJax.typeset();
        };
      
        document.body.appendChild(script);
      
        return () => {
          document.body.removeChild(script);
        };
      }, [currentQuestionDetails, lang, solutionVar]);
};

export default useMathjax;