import { React, useState } from "react";
import "./Difficulty_Level.scss";
import { useLocation } from "react-router-dom";

import {
  url_70,
  url_71,
  url_74,
} from "../../../../../../../../custom_utilities/api_services";
import ProgressBar from "react-bootstrap/ProgressBar";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import images from "../../../../../../../../utilities/images/images";
import { isEmpty } from ".././../../../../../../../custom_utilities/custom_useful_functions";
import HeaderComponent from "../../../../../../../logged_user_components/structure_components/desktop_screens/header_component/header_component";
import axios from "axios";
import { useEffect } from "react";
import LoaderPopUp from "../../../../../../../../components/loader/loader";
import { useHistory } from "react-router-dom";
//mobileDevice
import MobileLeftSideBarComponent from "../../../../../../structure_components/mobile_screens/mobile_left_sideBar_component/mobile_left_sideBar_component";
import MobileHeaderComponent from "../../../../../../structure_components/mobile_screens/mobile_header_component/mobile_header_component";
import MobileRightSideBarComponent from "../../../../../../structure_components/mobile_screens/mobile_right_sideBar_component/mobile__right_sideBar_component";
//Redux
import { connect } from "react-redux";
import uiActions from "../../../../.././../../../redux/ui/action";
import Difficult_Level_Circular_Progress from "./Difficult_Level_Circular_Progress";

const DifficultyLevel = (props) => {
  let { startLoading, finishLoading, loader, userData, userPreference } = props;
  let history = useHistory();
  const backBtn = () => {
    history.push("/test_result");
  };
  const location = useLocation();
  const [currentSubject, setCurrentSubject] = useState(null);
  const [state, setState] = useState({
    difficultyLevel: {},
    subjectList: {},
  });

  useEffect(() => {
    getDifficultyResult();
  }, [currentSubject]);

  useEffect(() => {
    getSubjectList();
    // getDifficultyResult();
  }, []);
  // let firstSbj = state.subjectList?.All_Subject?.[0].subject;
  //getSubjectList
  const getSubjectList = async () => {
    let data = {
      paper_id: location.state.reportDataDetails.PaperData.paper_id,
    };
    startLoading();
    try {
      const response = await axios.post(url_71, data);
      if (response.data.status === 200) {
        setState((preState) => ({
          ...preState,
          subjectList: response.data.data,
        }));
        response.data.data.All_Subject[0] &&
          setCurrentSubject(response.data.data.All_Subject[0]?.subject);
        finishLoading();
      }
    } catch (error) {
      console.log(error);
      finishLoading();
    }
  };
  //getDifficultyLevel
  const handleChangeSubject = (event) => {
    let { value } = event.target;
    getDifficultyResult();

    setCurrentSubject(value);
  };
  //topicWiseData
  const getDifficultyResult = async () => {
    let data = {
      paper_id: location.state.reportDataDetails.PaperData.paper_id,
      subject: currentSubject,
    };
    startLoading();
    try {
      const response = await axios.post(url_74, data);
      if (response.data.status === 200) {
        setState((prevState) => ({
          ...prevState,
          difficultyLevel: response.data.data,
        }));
      } else {
        setState((prevState) => ({
          ...prevState,
          difficultyLevel: "",
        }));
      }
      finishLoading();
    } catch (error) {
      console.log(error);
      finishLoading();
    }
  };
  //let progressCount = (100 / testQuestionsDetails.length) * currentQuestion + 1;
  const isMob = () => {
    if (window.innerWidth <= 480) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <>
      <div className="subjectWiseMarksWrapper">
        {isMob() ? (
          <>
            <MobileLeftSideBarComponent />
            <MobileHeaderComponent
              userPreference={userPreference}
              userData={userData}
            />
            <MobileRightSideBarComponent history={history} />
          </>
        ) : (
          <HeaderComponent />
        )}
        <div className="subjectWiseMarksInrWrapper">
          <div className="container">
            <div className="sbjtWiseMarksInr">
              <div className="backBtn" onClick={backBtn}>
                <img src={images.user_activity_1} />
              </div>

              <div className="difficultyLevel">
                <h3>Difficulty Levels</h3>
                <div className="difficultyLevelTab">
                  <div className="difficultyLevelTabBox">
                    {!isEmpty(state.subjectList)
                      ? state.subjectList.All_Subject.length
                        ? state.subjectList.All_Subject.map(
                            (element, index) => {
                              return (
                                <div
                                  key={index}
                                  className="single_select_subject"
                                >
                                  <input
                                    type="radio"
                                    id={element.subject}
                                    name="subject"
                                    value={element.subject}
                                    checked={element.subject === currentSubject}
                                    onChange={(event) => {
                                      handleChangeSubject(event);
                                    }}
                                  />
                                  <label htmlFor={element.subject}>
                                    {element.subject}
                                  </label>
                                </div>
                              );
                            }
                          )
                        : null
                      : null}
                  </div>
                  {loader && <LoaderPopUp />}
                  <div className="difficultyLevelcontent">
                    <div className="difficultyData">
                      {!isEmpty(state.difficultyLevel) ? (
                        state.difficultyLevel.Analysis.length ? (
                          <Difficult_Level_Circular_Progress
                            data={state.difficultyLevel.Analysis}
                          />
                        ) : null
                      ) : null}
                    </div>
                    <div className="difficultyData">
                      <ul>
                        <li className="title">
                          <span>Levels</span>
                          <span>Correct</span>
                          <span>Wrong</span>
                          <span>Unattempted</span>
                        </li>
                        {!isEmpty(state.difficultyLevel)
                          ? state.difficultyLevel.Analysis.length
                            ? state.difficultyLevel.Analysis.map(
                                (element, index) => {
                                  return (
                                    <li key={index} className="titleData">
                                      <span>{element.Toughness}</span>
                                      <span>{element.Correct}</span>
                                      <span>{element.WRONG}</span>
                                      <span>{element.Unattempted}</span>
                                    </li>
                                  );
                                }
                              )
                            : null
                          : null}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    loader: state.ui.loader,
    userData: state.auth.user,
    userPreference: state.userPreference,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => {
      dispatch(uiActions.startLoading());
    },
    finishLoading: () => {
      dispatch(uiActions.finishLoading());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DifficultyLevel);
