import { Fragment } from "react";
import "./AdaptiveCpsTest.scss";

import LoaderPopUp from "../../../../../../components/loader/loader";
import AdaptiveCpsTestContent from "./adaptiveCpsTestContent/AdaptiveCpsTestContent";
import uiActions from "../../../../../../redux/ui/action";
import AdaptiveActions from "../../../../../../redux/adaptive_cps/action";

//Redux
import { connect } from "react-redux";

const AdaptiveCps = (props) => {
  let {
    history,
    loader,
    userData,
    startLoading,
    finishLoading,
    userPreference,
    adaptiveCpsTestData,
    token,
    tokenType,
    AdaptiveCpsId,
    setAdaptiveCpsId,
  } = props;
  const isMob = () => {
    if (window.innerWidth <= 480) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Fragment>
      <div className="test_component_wrapper">
        <div className="test_component_inner_wrapper">
          {isMob() ? null : (
            <Fragment>
              <AdaptiveCpsTestContent
                history={history}
                userData={userData}
                startLoading={startLoading}
                finishLoading={finishLoading}
                token={token}
                tokenType={tokenType}
                AdaptiveCpsId={AdaptiveCpsId}
                adaptiveCpsTestData={adaptiveCpsTestData}
                userPreference={userPreference}
                setAdaptiveCpsId={setAdaptiveCpsId}
              />
            </Fragment>
          )}
        </div>
      </div>
      {loader && <LoaderPopUp />}
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    loader: state.ui.loader,
    userData: state.auth.user,
    testData: state.pyqTestsData.pyqTestData,
    pyqtopicData: state.userActivity.pyqtopicData,
    userPreference: state.userPreference,
    adaptiveCpsTestData: state.AdaptiveCpsReducer,
    token: state.auth.authToken,
    tokenType: state.auth.tokenType,
    AdaptiveCpsId: state.AdaptiveCpsReducer.AdaptiveCpsId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => {
      dispatch(uiActions.startLoading());
    },
    finishLoading: () => {
      dispatch(uiActions.finishLoading());
    },
    setAdaptiveCpsId: (payload) => {
      dispatch(AdaptiveActions.setAdaptiveCpsId(payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdaptiveCps);
