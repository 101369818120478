import React from "react";
import "./QuestionInfoSection.scss";
import images from "../../../../../../../../utilities/images/images";

const QuestionInfoSection = ({
  currentSolutionDetails,
  currentQuestion,
  lang,
  setLang,
  ConvertStringToHTML,
}) => {
  return (
    <div className="sub_sub_section_wrapper_2">
      <h2>Practice Test</h2>
      <div className="text_content_wrapper">
        <div className="typeLanguageWrapper">
          <span>Question type: {currentSolutionDetails?.answer_type}</span>
          <div className="language_chage_icon">
            {ConvertStringToHTML(currentSolutionDetails?.q_text_hindi) ? (
              <button onClick={() => setLang(!lang)}>
                <img src={images.m2_language} alt="" />
              </button>
            ) : (
              ""
            )}
          </div>
        </div>

        <div className="questionBox">
          <span className="text_content">
            {`${Number(currentQuestion) + 1}.`}
          </span>

          {currentSolutionDetails !== null &&
          currentSolutionDetails !== undefined ? (
            <p
              className="text_content"
              dangerouslySetInnerHTML={{
                __html: currentSolutionDetails.q_text,
              }}
            ></p>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default QuestionInfoSection;
