import React from "react";
import "./ProgressIndicator.scss";
import ProgressBar from "react-bootstrap/ProgressBar";

const ProgressIndicator = ({ currentQuestion, testQuestionsDetails }) => {
  let progressCount = (100 / testQuestionsDetails.length) * currentQuestion + 1;

  return (
    <div className="testProgressIndicator">
      <h4>Test Question progress Indicator</h4>
      <div className="ProgressBar_Line">
        <ProgressBar now={progressCount} className="progressBar" />
        <span style={{ left: progressCount - 1 + "%" }}>{`${
          currentQuestion + 1
        }`}</span>
      </div>
      <div className="questionPercentage">
        <span>1</span>
        <span>{testQuestionsDetails.length}</span>
      </div>
    </div>
  );
};

export default ProgressIndicator;
