import { Fragment } from "react";
import "./user_notifications.scss";

///////////////////Desktop
import LeftSideBarComponent from "../structure_components/desktop_screens/left_sideBar_component/left_sideBar_component";
import HeaderComponent from "../structure_components/desktop_screens/header_component/header_component";

///////Mobile////////////////////
import MobileLeftSideBarComponent from "../structure_components/mobile_screens/mobile_left_sideBar_component/mobile_left_sideBar_component";
import MobileHeaderComponent from "../structure_components/mobile_screens/mobile_header_component/mobile_header_component";
import MobileRightSideBarComponent from "../structure_components/mobile_screens/mobile_right_sideBar_component/mobile__right_sideBar_component";

import UserNotificationsContentComponent from "./user_notifications_content_component/user_notifications_content_component";

//Redux
import { connect } from "react-redux";

const UserNotifications = (props) => {
  let { history, userData, userPreference } = props;

  const isMob = () => {
    if (window.innerWidth <= 480) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Fragment>
      <div className="user_notifications_wrapper">
        <div className="user_notifications_inner_wrapper">
          {isMob() ? (
            /////for Mobile Screens////////////////////
            <Fragment>
              <MobileLeftSideBarComponent />
              <MobileHeaderComponent
                userPreference={userPreference}
                userData={userData}
              />
              <MobileRightSideBarComponent history={history} />
              <UserNotificationsContentComponent />
            </Fragment>
          ) : (
            /////for Screens greater than Mobile Screens////////////////////
            <Fragment>
              <div className="user_notifications_right_wrapper">
                <HeaderComponent history={history} />
                <UserNotificationsContentComponent />
              </div>
            </Fragment>
          )}
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.auth.user,
    userPreference: state.userPreference,
  };
};

export default connect(mapStateToProps)(UserNotifications);
