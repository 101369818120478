import React from "react";
import "./ControlPanel.scss";

const ControlPanel = ({ scale, zoomIn, pdfData, zoomOut, notesData }) => {
  const handleDownload = () => {
    const blob = new Blob([pdfData], { type: "application/pdf" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = `${notesData.notes_title}.pdf`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  return (
    <div
      className=""
      style={{
        display: "flex",
        justifyContent: "end",
        alignItems: "center",
        marginBottom: "10px",
      }}
    >
      {notesData.is_downloadable == 1 && (
        <div className="btn_wrapper1">
          <button onClick={handleDownload} style={{ marginRight: "10px" }}>
            Download PDF
          </button>
        </div>
      )}
      <i
        className={`fas fa-search-minus mx-3`}
        onClick={zoomOut}
        style={{ cursor: "pointer" }}
      />
      {/* <span>{(scale * 100).toFixed()}%</span> */}
      <i
        className={`fas fa-search-plus mx-3`}
        onClick={zoomIn}
        style={{ cursor: "pointer" }}
      />
    </div>
  );
};

export default ControlPanel;
