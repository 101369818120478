import { Fragment, useState, useEffect } from "react";
import "./header_right.scss";

import { Link } from "react-router-dom";

import images from "../../../../../../utilities/images/images";
import {
  isEmpty,
  sliceString,
} from "../../../../../../custom_utilities/custom_useful_functions";
import { url_37 } from "../../../../../../../src/custom_utilities/api_services";
import axios from "axios";
import Moment from "moment";
import HeaderTopright from "../header_left/header_left";

//Redux
import { connect } from "react-redux";
import uiActions from "../../../../../../redux/ui/action";
import authActions from "../../../../../../redux/auth/action";
import userPreferenceActions from "../../../../../../redux/user_preference/action";
import userActivityActions from "../../../../../../redux/user_activity/action";
import userTestsExercisesAction from "../../../../../../redux/user_tests_exercises/action";
import userTwilioDetailsAction from "../../../../../../redux/twilio_chat/action";
import userBookmarkAction from "../../../../../../redux/user_bookmark/action";
import { useLocation, useHistory } from "react-router-dom";
import GitBoxAnimation from "../../../../user_refer_earn/GiftBoxAnimation.json";
import Lottie from "lottie-react";

const HeaderRight = (props) => {
  let {
    // history,
    userData,
    userPreference,
    logoutUser,
    removeAnimation,
    removePreferenceData,
    removeActivityData,
    removeTestsExercisesData,
    removeTwilioDetails,
    removeBookmarkData,
  } = props;

  const history = useHistory();

  const location = useLocation();
  console.log("locationlocation", location);
  const handleUserLogout = () => {
    logoutUser();
    removeAnimation();
    removePreferenceData();
    removeActivityData();
    removeTestsExercisesData();
    removeTwilioDetails();
    removeBookmarkData();
    history.push("/");
  };
  const [openSearch, setOpenSearch] = useState(false);
  const addclass = () => {
    setOpenSearch(true);
  };
  //notification
  const [state, setState] = useState({
    notificationsList: [],
  });
  let {batchDetails } = userPreference;
  useEffect(() => {
    getNotificationsList();
  }, []);

  const getNotificationsList = async () => {
    try {
      const response = await axios.get(url_37);
      if (response.data.status == 200) {
        setState((prevState) => ({
          ...prevState,
          notificationsList: response.data.data,
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleClick = () => {
    history.push("/refer-and-earn");
  };

  return (
    <Fragment>
      <div className="headerRight_wrapper">
        <div className="headerRight_inner_wrapper">
          {location?.pathname === "/refer-and-earn" ? (
            ""
          ) : (
            <div className="referEarn_wrapper" onClick={handleClick}>
              <div className="animation-container">
                <Lottie
                  animationData={GitBoxAnimation}
                  loop={true}
                  renderer="svg" // Use the SVG renderer for  better quality
                  rendererSettings={{
                    preserveAspectRatio: "xMidYMid meet", // Show entire animation without cropping
                  }}
                  // rendererSettings={{
                  //   preserveAspectRatio: "xMidYMid slice", // Adjust to fit your animation aspect ratio
                  // }}
                />
              </div>
              <div className="text">Refer & Earn</div>
            </div>
          )}

          <div className="notifications_wrapper">
            <div className="notifications_inner_wrapper">
              <div className="dropdown notication_dropDown">
                <button
                  className="btn dropdown-toggle cstm_drpdown_btn"
                  type="button"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <div className="image_wrapper">
                    <Link to="/notifications">
                      <img src={images.notification} alt="img" />
                    </Link>
                  </div>
                </button>
                <div
                  className="dropdown-menu custom_dropdown_notification"
                  aria-labelledby="dropdownMenuButton1"
                >
                  <div className="notification_dataBox">
                    <div className="heading">
                      <h3>Notification</h3>
                    </div>
                    <div className="notification_list_wrpr">
                      {state.notificationsList.length ? (
                        state.notificationsList.map((element, index) => {
                          return (
                            <div key={index} className="notification_list">
                              <div className="ntf_title">
                                <h6>{element.notification_title}</h6>
                                <span>
                                  {/* {Moment(element.time).format("HH:MMm")} */}
                                  {element.time}
                                </span>
                              </div>
                              <div className="ntf_desc">
                                <p>{element.notification_description}</p>
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <div className="notDataFound">
                          <img src={images.M2Notification} alt="notification" />
                          <h6>No Notification</h6>
                          <p>
                            It's seems like you don't have any notification
                            right now!
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="userName_and_image_wrapper">
            <div className="userName_and_image_inner_wrapper">
              <div className="content_wrapper">
                {/* <div className="name_number_container">
                  <div className="text_content_wrapper_1">
                    <p className="text_content_1">
                      {!isEmpty(userData)
                        ? `${sliceString(userData.name, 0, 4)}...`
                        : "User"}
                    </p>
                  </div>
                  <div className="text_content_wrapper_2">
                    <p className="text_content_2">
                      {!isEmpty(userData)
                        ? `${sliceString(userData.mobile, 0, 4)}...`
                        : "987654"}
                    </p>
                  </div>
                  <div className="text_content_wrapper_3">
                    <p className="text_content_3">
                      {userData.is_subscribe === 1 ? (
                        <Fragment>
                          <img src={images.user_activity_12} /> Pre...
                        </Fragment>
                      ) : null}
                    </p>
                  </div>
                </div> */}
                <div className="dropdown image_container">
                  <button
                    className="btn dropdown-toggle custom_dropdown_toggle"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <span className="image_wrapper">
                      <span className="image_inner_wrapper">
                        {!isEmpty(userData) ? (
                          userData.image.length ? (
                            <img src={userData.image} />
                          ) : (
                            <img src={images.dummy_user_img} />
                          )
                        ) : (
                          <img src={images.dummy_user_img} />
                        )}
                      </span>
                    </span>
                    <span className="userdetail_wrapper">
                    <span className="userdetail_1">

                    {!isEmpty(userData) && userData.name}
                    </span>
                    <span className="userdetail_2">
                    {!isEmpty(batchDetails) && batchDetails.batch}

                    </span>
                    </span>
                  </button>
                  <div
                    className="dropdown-menu custom_dropdown_menu"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <div className="profile_option_wrapper">
                      <div className="profile_option_inner_wrapper">
                        <div className="content_wrapper_1">
                          <div className="userProfile">
                            <div className="image_container">
                              {!isEmpty(userData) ? (
                                userData.image.length ? (
                                  <img src={userData.image} />
                                ) : (
                                  <img src={images.dummy_user_img} />
                                )
                              ) : (
                                <img src={images.dummy_user_img} />
                              )}
                            </div>
                            <div className="text_content_wrapper_1">
                              <p className="text_content_1">
                                {!isEmpty(userData) ? userData.name : "User"}{" "}
                              </p>
                              <p className="text_content_2">
                                {!isEmpty(userData)
                                  ? userData.mobile
                                  : "9876543210"}
                              </p>
                            </div>
                          </div>
                          {userData.is_subscribe === 1 ? (
                            <p className="text_content_3">
                              <Fragment>
                                <img src={images.user_activity_12} />
                              </Fragment>
                            </p>
                          ) : null}
                        </div>
                        <div className="content_wrapper_2">
                          <div className="text_content_wrapper_1">
                          <div className="profile_link1">
                            <HeaderTopright
                              userPreference={userPreference}
                              userData={userData}
                            />
                            {/* <img src={images.right_Ar} /> */}
                            </div>
                            <Link
                              to="/user_edit_profile"
                              className="profile_link"
                            >
                              <p className="text_content_1">Profile</p>
                              <img src={images.right_Ar} />
                            </Link>
                            <Link to="/store" className="profile_link">
                              <p className="text_content_1">My Packages</p>
                              <img src={images.right_Ar} />
                            </Link>
                            <Link to="/wallet" className="profile_link">
                              <p className="text_content_1">My Wallet</p>
                              <img src={images.right_Ar} />
                            </Link>
                            <Link to="/transactions" className="profile_link">
                              <p className="text_content_1">Transaction</p>
                              <img src={images.right_Ar} />
                            </Link>
                            <div className="text_content_wrapper_2 profile_link">
                              <p
                                className="text_content_1"
                                onClick={() => handleUserLogout()}
                              >
                                <img src={images.header_2} /> Logout
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};


const mapDispatchToProps = (dispatch) => {
  return {
    logoutUser: () => {
      dispatch(authActions.logoutUser());
    },
    removeAnimation: () => {
      dispatch(uiActions.removeAnimation());
    },
    removeActivityData: () => {
      dispatch(userActivityActions.removeActivityData());
    },
    removePreferenceData: () => {
      dispatch(userPreferenceActions.removePreferenceData());
    },
    removeTestsExercisesData: () => {
      dispatch(userTestsExercisesAction.removeTestsExercisesData());
    },
    removeTwilioDetails: () => {
      dispatch(userTwilioDetailsAction.removeTwilioDetails());
    },
    removeBookmarkData: () => {
      dispatch(userBookmarkAction.removeBookmarkData());
    },
  };
};

export default connect(null, mapDispatchToProps)(HeaderRight);
