import React, { useMemo, Fragment } from "react";
import "./QueOptions.scss";

const QuestionOptions = ({
  questionData,
  currentQuestion,
  testQuestionsDetails,
}) => {
  const classEditor = (s, u) => {
    if (s) {
      return "single_option_wrapper classCorrect";
    } else if (!s && u) {
      return "single_option_wrapper classWrong";
    } else {
      return "single_option_wrapper";
    }
  };

  const renderOptionInitial = (index) => {
    let alphabetArr = [
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
      "M",
      "N",
      "O",
      "P",
      "Q",
      "R",
      "S",
      "T",
      "U",
      "V",
      "W",
      "X",
      "Y",
      "Z",
    ];

    return alphabetArr[index];
  };

  const renderOptionText = (optionText) => {
    return { __html: optionText };
  };

  const renderSingleSelect = (questionData, currentQuestion2) => {
    if (currentQuestion === currentQuestion2) {
      return (
        <Fragment>
          <div className="single_select_option_collection_wrapper">
            {questionData?.option.length
              ? questionData.option.map((element, index) => {
                  return (
                    <div key={index} className="single_select">
                      <input
                        type="checkbox"
                        id={element.option_id}
                        name={`current_question_${currentQuestion}`}
                        disabled
                      />
                      <label
                        htmlFor={element.option_id}
                        className={classEditor(
                          element.is_correct,
                          element.is_user_correct_ans
                        )}
                      >
                        <div className="option_initial">
                          <p className="text_content_2">{`${renderOptionInitial(
                            index
                          )}.`}</p>
                        </div>
                        <div className="option_final">
                          <p
                            className="text_content_3"
                            dangerouslySetInnerHTML={renderOptionText(
                              element.option
                            )}
                          ></p>
                        </div>
                      </label>
                    </div>
                  );
                })
              : null}
          </div>
        </Fragment>
      );
    }
  };

  const renderMultiSelect = (questionData, currentQuestion2) => {
    if (currentQuestion === currentQuestion2) {
      return (
        <Fragment>
          <div className="multi_select_option_collection_wrapper">
            {questionData?.option.length
              ? questionData.option.map((element, index) => {
                  return (
                    <div key={index} className="single_select">
                      <input
                        type="checkbox"
                        id={element.option_id}
                        name={`current_question_${currentQuestion}`}
                        disabled
                      />
                      <label
                        htmlFor={element.option_id}
                        className={classEditor(
                          element.is_correct,
                          element.is_user_correct_ans
                        )}
                      >
                        <div className="option_initial">
                          <p className="text_content_2">{`${renderOptionInitial(
                            index
                          )}.`}</p>
                        </div>
                        <div className="option_final">
                          <p
                            className="text_content_3"
                            dangerouslySetInnerHTML={renderOptionText(
                              element.option
                            )}
                          ></p>
                        </div>
                      </label>
                    </div>
                  );
                })
              : null}
          </div>
        </Fragment>
      );
    }
  };

  const renderInputField = (currentQuestion2) => {
    if (currentQuestion === currentQuestion2) {
      return (
        <Fragment>
          <div className="input_wrapper">
            <div className="input_inner_wrapper">
              <input
                key={currentQuestion}
                type="number"
                value={testQuestionsDetails[currentQuestion]?.integer_answer}
                disabled
              />
            </div>
          </div>
        </Fragment>
      );
    }
  };

  // Use useMemo for renderSingleSelect function
  const memoizedRenderSingleSelect = useMemo(
    () => renderSingleSelect(questionData, currentQuestion),
    [questionData, currentQuestion]
  );

  // Use useMemo for renderMultiSelect function
  const memoizedRenderMultiSelect = useMemo(
    () => renderMultiSelect(questionData, currentQuestion),
    [questionData, currentQuestion]
  );

  // Use useMemo for renderInputField function
  const memoizedRenderInputField = useMemo(
    () => renderInputField(currentQuestion),
    [currentQuestion]
  );

  if (questionData.answer_type.toLowerCase() === "single correct") {
    return (
      <div className="single_select_option_collection_wrapper">
        {memoizedRenderSingleSelect}
      </div>
    );
  } else if (questionData.answer_type.toLowerCase() === "integer correct") {
    return (
      <div className="single_select_option_collection_wrapper">
        {memoizedRenderInputField}
      </div>
    );
  } else {
    return (
      <div className="single_select_option_collection_wrapper">
        {memoizedRenderMultiSelect}
      </div>
    );
  }
};

export default QuestionOptions;
