import React, { useState, useEffect } from "react";
import axios from "axios";
import { isEmpty } from "../../../../../custom_utilities/custom_useful_functions";
import "./PredictCollegeContentComponent.scss";
import LoaderPopUp from "../../../../../components/loader/loader";
import images from "../../../../../utilities/images/images";

const PredictCollegeContentComponent = (props) => {
  const {
    startLoading,
    finishLoading,
    loader,
    allIndiaRank,
    expectedRank,
    target,
    parentTarget,
    studentTarget,
  } = props;

  const [state, setState] = useState({
    collegeDetails: [],
    states: [],
    stateSelected: "",
    categorySelected: "",
  });
  const { collegeDetails, states, stateSelected, categorySelected } = state;

  const categories = ["general", "obc", "sc", "st"];

  useEffect(() => {
    getCollegeDetails();
    getStates();
  }, []);

  useEffect(() => {
    getCollegeDetails(state.categorySelected, state.selectedState);
  }, [state.categorySelected, state.selectedState]);

  const getCollegeDetails = async (category, state) => {
    startLoading();

    // const cast = (await category) !== "" ? category : "general";

    const studentTargetId = studentTarget === 3 ? "NEET" : "JEE";

    console.log(
      "studentTargetId",
      studentTargetId,
      "parentTargetId",
      parentTarget
    );

    console.log("parentTargetIdparentTargetId", studentTargetId);

    const data = {
      // target: parentTarget?.length > 0 ? parentTarget : studentTargetId,
      target: parentTarget,
      rank: allIndiaRank,
      category,
      state,
    };

    console.log("tesing getCollegeDetails", data);

    try {
      const response = await axios.post(
        "https://learning.motion.ac.in/motioneducation/api/StudentCollegePredictor",
        data
      );

      if (response.data.status === 200) {
        setState((prevState) => ({
          ...prevState,
          collegeDetails: response.data.college_predictor,
        }));
        finishLoading();
      }
    } catch (error) {
      //   finishLoading();
      console.log(error);
    }
  };

  const getStates = async () => {
    try {
      const response = await axios.post(
        "https://learning.motion.ac.in/motioneducation/api/StateCollegePredictor"
      );
      if (response.data.status === 200) {
        setState((prevState) => ({
          ...prevState,
          states: [...response.data.state_college_predictor],
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangeStates = (event) => {
    const selectedState = event.target.value;
    setState((prevState) => ({
      ...prevState,
      stateSelected: selectedState,
    }));
  };

  const handleChangeCategories = (event) => {
    const selectedCategory = event.target.value;
    setState((prevState) => ({
      ...prevState,
      categorySelected: selectedCategory,
    }));
  };
  return (
    <div className="predictCollegeSection">
      <section className="cards">
        <div className="card">
          <div className="top">Expected Rank</div>
          <div className="btm">
            <div className="img">
              {" "}
              <img src={images.user_activity_49} alt="img" />
            </div>
            <div className="num expectedRank">{expectedRank}</div>
          </div>
        </div>
        <div className="card">
          <div className="top">All India Rank</div>
          <div className="btm">
            <div className="img">
              {" "}
              <img src={images.user_activity_50} alt="img" />
            </div>
            <div className="num all_india_rank">{allIndiaRank}</div>
          </div>
        </div>
      </section>
      <section className="clgList">
        <div className="left">
          <div className="section-Heading">College List</div>
          <p className="desc">Based on your performance</p>
        </div>
        <div className="right">
          <div className="stateDropDownWrpper">
            <div>Filter by:</div>
            <select
              id="stateDropdown"
              onChange={handleChangeStates}
              value={stateSelected}
            >
              <option value="" disabled>
                Select State
              </option>
              {!isEmpty(states) &&
                states.map((element, index) => (
                  <option key={index} value={element.state}>
                    {element.state}
                  </option>
                ))}
            </select>
          </div>

          <div className="categoriesDropdownWrpper">
            <div>Filter by:</div>
            <select
              id="categoriesDropdown"
              onChange={handleChangeCategories}
              value={categorySelected}
            >
              {!isEmpty(categories) &&
                categories.map((element, index) => (
                  <option key={index} value={element}>
                    {element.toUpperCase()}
                  </option>
                ))}
            </select>
          </div>
        </div>
      </section>
      <section className="table_section">
        <table style={{ width: "100%" }}>
          <thead className="table_heading">
            <tr>
              <th className="firstTd">S.No.</th>
              <th>College Name</th>
              <th>Eligible Rank</th>
            </tr>
          </thead>
          <tbody>
            {!isEmpty(collegeDetails) ? (
              collegeDetails.map((element, index) => (
                <tr key={index}>
                  <td className="num">{index + 1}.</td>
                  <td className="td-text num">{element.college_name}</td>
                  <td className="num">{element.rank}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="3" className="no-data">
                  No Data Found!
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </section>
       {loader && <LoaderPopUp />}
    </div>
  );
};

export default PredictCollegeContentComponent;
