import { React, useEffect, useState } from "react";
import "./PyqDailyChallengeContent.scss";
import images from "../../../utilities/images/images";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import { url_206 } from "../../../custom_utilities/api_services";
//Redux
import { connect } from "react-redux";
import uiActions from "../../../redux/ui/action";
import pyqDailyChallengeAction from "../../../redux/pyq_daily_challenge/action";

const PyqDailyChallengeContent = (props) => {
  let {
    startLoading,
    finishLoading,
    day,
    setSubject,
    token,
    tokenType,
  } = props;

  const [state, setState] = useState({
    topicItemList: {},
    testList: [],
    currentTest: [],
    modalOpen: false,
  });

  let history = useHistory();

  useEffect(() => {
    getTestList();
  }, []);

  const getTestList = async () => {
    startLoading();
    const data = {
      schedule: day,
    };
    const headers = {
      Authorization: `${tokenType} ${token}`,
    };
    try {
      const response = await axios.post(url_206, data, { headers });
      finishLoading();
      setState((prevState) => ({
        ...prevState,
        testList: response.data.data,
      }));
    } catch (error) {
      finishLoading();
    }
  };

  const handleModal = (card= state.currentTest) => {
    setState((prevState) => ({
        ...prevState,
        modalOpen: !prevState.modalOpen,
        currentTest: card,
    }));
  }

  const handleStart = (card= state.currentTest) => {
    setSubject(card);
    history.push("/Pyq_daily_test_component");
  };

  return (
    <>
      <div className="pyq_subjectTopicWrapper">
        <div className="container">
          <div className="pyq_subjectTopicInner">
            <div className="pyqSbjTpcListBox">
              <Link
                className="backBtn"
                to={{
                  pathname: "/pyq",
                  state: { tab: "pyq" },
                }}
              >
                <img src={images.m2_backBtn} alt="backBtn" />
              </Link>
              <div className="subject_name">Day - {day.slice(3)} Challenge</div>
              <div className="card_container">
                {state.testList.map((card) => {
                  return (
                    <div className="pyq_card">
                      <p className="sub_name">{card.subject}</p>
                      <p className="test_details">{card.title}</p>
                      <div className="btn_container">
                        <button onClick={() => {handleModal(card)}} className="syllabus_btn">View Syllabus</button>
                        {card.is_attempt ? <button className="start_btn" onClick={() => handleStart(card)}>Restart Practice</button> : <button className="start_btn" onClick={() => handleStart(card)}>Start Practice</button>}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={state.modalOpen ? `syllabus_modal open` : `syllabus_modal`}>
        <div className="modal_body text-center">
            <p className="modal_heading text-center">Test Syllabus</p>
            <button className="close_btn" onClick={() => {handleModal()}}><img src={images.close_btn} alt="close" /></button>
            <hr />
            <div className="modal_inner">
                <p className="modal_subject">{state.currentTest.subject}</p>
                <hr />
                <ul>
                    {state.currentTest.topic?.map((topic) => {
                        return (<li>{topic}</li>)
                    })}
                </ul>
            </div>
            <button className="done_btn" onClick={() => {handleModal()}}>Done</button>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    day: state.PyqDailyChallengeReducer.day,
    token: state.auth.authToken,
    tokenType: state.auth.tokenType,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => {
      dispatch(uiActions.startLoading());
    },
    finishLoading: () => {
      dispatch(uiActions.finishLoading());
    },
    
    setSubject: (payload) => {
      dispatch(pyqDailyChallengeAction.setSubject(payload));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PyqDailyChallengeContent);
