import React from "react";
import "./QuestionListPanel.scss";
import QuestionStatusLabel from "./questionStatusLabel/QuestionStatusLabel";

const QuestionListPanel = ({
  testQuestionsDetails,
  currentQuestion,
  handleQuestionJump,
  questionsCollectionRef,
}) => {
  return (
    // <div className="sub_section_1_wrapper">
    //   <div className="text_content_wrapper">
    //     <p className="text_content">All Questions</p>
    //   </div>
    //   <div className="sub_section_2_wrapper">
    //     <div className="question_marking_wrapper">
    //       <div className="single_marking">
    //         <label className="box_pic box_pick_background_1"></label>
    //         <label className="question_marking_text">Completed</label>
    //       </div>
    //       <div className="single_marking">
    //         <label className="box_pic box_pick_background_3"></label>
    //         <label className="question_marking_text">Pending</label>
    //       </div>
    //       <div className="single_marking">
    //         <label className="box_pic box_pick_background_4"></label>
    //         <label className="question_marking_text">Skipped</label>
    //       </div>
    //     </div>
    //   </div>
    //   <div
    //     className="questions_serial_number_collection"
    //     ref={questionsCollectionRef}
    //   >
    //     {testQuestionsDetails?.length
    //       ? testQuestionsDetails?.map((element, index) => (
    //           <QuestionStatusLabel
    //             key={index}
    //             question={element}
    //             index={index}
    //             currentQuestion={currentQuestion}
    //             handleQuestionJump={handleQuestionJump}
    //           />
    //         ))
    //       : null}
    //   </div>
    // </div>

    <div className="questions-panel">
      <div className="questions-panel-header">
        <p className="header-text">All Questions</p>
      </div>

      <div className="questions-panel-markings">
        <div className="question-marking">
          <label className="marking-icon marking-icon-completed"></label>
          <span className="marking-text">Completed</span>
        </div>
        <div className="question-marking">
          <label className="marking-icon marking-icon-pending"></label>
          <span className="marking-text">Pending</span>
        </div>
      </div>

      <div className="questions-list" ref={questionsCollectionRef}>
        {testQuestionsDetails?.length
          ? testQuestionsDetails?.map((element, index) => (
              <QuestionStatusLabel
                key={index}
                question={element}
                index={index}
                currentQuestion={currentQuestion}
                handleQuestionJump={handleQuestionJump}
              />
            ))
          : null}
      </div>
    </div>
  );
};

export default QuestionListPanel;
