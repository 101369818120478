import { Fragment, useEffect, useState } from "react";
import "./user_exercise_test_content_component.scss";

import images from "../../../../utilities/images/images";
import { isEmpty } from "../../../../custom_utilities/custom_useful_functions";
import { url_61 } from "../../../../custom_utilities/api_services";
import axios from "axios";
import moment from "moment";

//Redux
import { connect } from "react-redux";
import uiActions from "../../../../redux/ui/action";
import userTestsExercisesAction from "../../../../redux/user_tests_exercises/action";
import userActivityActions from "../../../../redux/user_activity/action";

const UserExerciseTestContentComponent = (props) => {
  let {
    history,
    userData,
    userPreference,
    startLoading,
    finishLoading,
    setUserTestsData,
    setUserTestsResultData,
    setExerciseData,
  } = props;

  const [state, setState] = useState({
    testType: 1,
    testsList: {},
    questionBankList: {},
  });
  useEffect(() => {
    getTestsList();
  }, [state.testType]);

  const getTestsList = async () => {
    let testType = state.testType;
    if (testType === 1) {
      getCompletedTestsList();
    } else if (testType === 2) {
      getUpcomingTestsList();
    } else if (testType === 3) {
      getAllTestsList();
    }
  };

  const getCompletedTestsList = async () => {
    let { courseDetails, classDetails, batchDetails } = userPreference;

    let data = {
      course_id: courseDetails[0].course_id,
      class_id: classDetails.class_id,
      batch_id: batchDetails.batch_id,
      test_type: 1,
      page: 1,
    };

    startLoading();
    try {
      const response = await axios.post(url_61, data);
      if (response.data.status === 200) {
        setState((prevState) => ({
          ...prevState,
          testsList: response.data.data,
        }));
        finishLoading();
      }
    } catch (error) {
      finishLoading();
      console.log(error);
    }
  };

  const getUpcomingTestsList = async () => {
    let { courseDetails, classDetails, batchDetails } = userPreference;

    let data = {
      course_id: courseDetails[0].course_id,
      class_id: classDetails.class_id,
      batch_id: batchDetails.batch_id,
      test_type: 2,
      page: 1,
    };

    startLoading();
    try {
      const response = await axios.post(url_61, data);
      if (response.data.status === 200) {
        setState((prevState) => ({
          ...prevState,
          testsList: response.data.data,
        }));
        finishLoading();
      }
    } catch (error) {
      finishLoading();
      console.log(error);
    }
  };

  const getAllTestsList = async () => {
    let { courseDetails, classDetails, batchDetails } = userPreference;

    let data = {
      course_id: courseDetails[0].course_id,
      class_id: classDetails.class_id,
      batch_id: batchDetails.batch_id,
      test_type: 3,
      page: 1,
    };

    startLoading();
    try {
      const response = await axios.post(url_61, data);
      if (response.data.status === 200) {
        setState((prevState) => ({
          ...prevState,
          testsList: response.data.data,
        }));
        finishLoading();
      }
    } catch (error) {
      finishLoading();
      console.log(error);
    }
  };
  const handleChangeTestType = (event) => {
    let { value } = event.target;

    switch (value) {
      case "completed_tests":
        setState((prevState) => ({ ...prevState, testType: 1 }));
        break;
      case "upcoming_tests":
        setState((prevState) => ({ ...prevState, testType: 2 }));
        break;
      case "all_tests":
        setState((prevState) => ({ ...prevState, testType: 3 }));
        break;
      default:
        break;
    }
  };
  const handleStoreData = (testData) => {
    if (testData.is_attempt) {
      if (testData.view_sol_time) {
        setUserTestsResultData(testData);
        history.push("/Question_paper_solution");
        // history.push("/test_result");
      } else {
        alert(`Solutions will be shown after ${testData.view_solution}`);
      }
    } else {
      if (testData.paper_type.toLowerCase() === "paid") {
        alert("You have to purchase a plan from store");
        history.push("/store");
      } else {
        setUserTestsData(testData);
        history.push({
          pathname: "/test",
          state: testData,
        });
      }
    }
  };
  const renderTestType = () => {
    let testType = state.testType;
    // 1 for all tests
    // 2 for completed
    // 3 for upcoming

    switch (testType) {
      case 1:
        return renderCompletedTestsView();
      case 2:
        return renderUpcomingTestsView();
      case 3:
        return renderAllTestsView();
      default:
        return null;
    }
  };

  const renderCompletedTestsView = () => {
    return (
      <Fragment>
        <div className="all_tests_wrapper">
          <div className="all_tests_inner_wrapper">
            <div className="all_tests_collection_wrapper">
              {!isEmpty(state.testsList)
                ? state.testsList.testlist.length
                  ? state.testsList.testlist.map((element, index) => {
                      //console.log("194", element);
                      return (
                        <div
                          key={index}
                          className="single_test_wrapper"
                          onClick={() => handleStoreData(element)}
                        >
                          <div className="sub_section_wrapper_1">
                            <label>{`Start at ${moment(
                              element.start_date
                            ).format("MMMM Do YYYY, h:mm:ss a")}`}</label>
                          </div>
                          <div className="sub_section_wrapper_2">
                            <div className="text_content_wrapper">
                              <p className="text_content">
                                {element.paper_name}
                              </p>
                            </div>
                          </div>
                          <div className="sub_section_wrapper_3">
                            <div className="sub_content_wrapper">
                              <div className="left_content_wrapper">
                                <label>{`${element.total_questions} Questions | ${element.duration_mins} Minutes | ${element.total_marks} Marks`}</label>
                              </div>
                              <div className="right_content_wrapper">
                                {element.paper_type.toLowerCase() === "paid" ? (
                                  <label className="lbl_1">Locked</label>
                                ) : (
                                  <div>
                                    {element.is_resume ? (
                                      <label className="lbl_2">
                                        Resume Test
                                      </label>
                                    ) : element.is_attempt ? (
                                      <label className="lbl_2">
                                        View Solution
                                      </label>
                                    ) : (
                                      <label className="lbl_2">Take Test</label>
                                    )}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  : null
                : null}
            </div>
          </div>
        </div>
      </Fragment>
    );
  };

  const renderUpcomingTestsView = () => {
    return (
      <Fragment>
        <div className="all_tests_wrapper">
          <div className="all_tests_inner_wrapper">
            <div className="all_tests_collection_wrapper">
              {!isEmpty(state.testsList)
                ? state.testsList.testlist.length
                  ? state.testsList.testlist.map((element, index) => {
                      return (
                        <div
                          key={index}
                          className="single_test_wrapper"
                          onClick={() => handleStoreData(element)}
                        >
                          <div className="sub_section_wrapper_1">
                            <label>{`Start at ${moment(
                              element.start_date
                            ).format("MMMM Do YYYY, h:mm:ss a")}`}</label>
                          </div>
                          <div className="sub_section_wrapper_2">
                            <div className="text_content_wrapper">
                              <p className="text_content">
                                {element.paper_name}
                              </p>
                            </div>
                          </div>
                          <div className="sub_section_wrapper_3">
                            <div className="sub_content_wrapper">
                              <div className="left_content_wrapper">
                                <label>{`${element.total_questions} Questions | ${element.duration_mins} Minutes | ${element.total_marks} Marks`}</label>
                              </div>
                              <div className="right_content_wrapper">
                                {element.paper_type.toLowerCase() === "paid" ? (
                                  <label className="lbl_1">Locked</label>
                                ) : (
                                  <div>
                                    {element.is_resume ? (
                                      <label className="lbl_2">
                                        Resume Test
                                      </label>
                                    ) : element.is_attempt ? (
                                      <label className="lbl_2">
                                        View Solution
                                      </label>
                                    ) : (
                                      <label className="lbl_2">Take Test</label>
                                    )}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  : null
                : null}
            </div>
          </div>
        </div>
      </Fragment>
    );
  };

  const renderAllTestsView = () => {
    return (
      <Fragment>
        <div className="all_tests_wrapper">
          <div className="all_tests_inner_wrapper">
            <div className="all_tests_collection_wrapper">
              {!isEmpty(state.testsList)
                ? state.testsList.testlist.length
                  ? state.testsList.testlist.map((element, index) => {
                      return (
                        <div
                          key={index}
                          className="single_test_wrapper"
                          onClick={() => handleStoreData(element)}
                        >
                          <div className="sub_section_wrapper_1">
                            <label>{`Start at ${moment(
                              element.start_date
                            ).format("MMMM Do YYYY, h:mm:ss a")}`}</label>
                          </div>
                          <div className="sub_section_wrapper_2">
                            <div className="text_content_wrapper">
                              <p className="text_content">
                                {element.paper_name}
                              </p>
                            </div>
                          </div>
                          <div className="sub_section_wrapper_3">
                            <div className="sub_content_wrapper">
                              <div className="left_content_wrapper">
                                <label>{`${element.total_questions} Questions | ${element.duration_mins} Minutes | ${element.total_marks} Marks`}</label>
                              </div>
                              <div className="right_content_wrapper">
                                {element.paper_type.toLowerCase() === "paid" ? (
                                  <label className="lbl_1">Locked</label>
                                ) : (
                                  <div>
                                    {element.is_resume ? (
                                      <label className="lbl_2">
                                        Resume Test
                                      </label>
                                    ) : element.is_attempt ? (
                                      <label className="lbl_2">
                                        View Solution
                                      </label>
                                    ) : (
                                      <label className="lbl_2">Take Test</label>
                                    )}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  : null
                : null}
            </div>
          </div>
        </div>
      </Fragment>
    );
  };
  const handleMoveForward = (details) => {
    // console.log(details);
    switch (details.qb_type) {
      case 1:
        details.redirectedFrom = "question_bank";
        details.exerciseType = "Test";
        setExerciseData(details);
        history.push("/evaluation");
        break;
      case 2:
        details.redirectedFrom = "question_bank";
        details.exerciseType = "Exercise";
        setExerciseData(details);
        history.push("/evaluation");
        break;
      case 3:
        details.redirectedFrom = "question_bank";
        details.exerciseType = "DPP";
        setExerciseData(details);
        history.push("/evaluation");
        break;
      default:
        break;
    }
  };

  return (
    <Fragment>
      <div className="userExerciseTestContentComponent_wrapper">
        <div className="userExerciseTestContentComponent_inner_wrapper">
          <div className="tests_tab_wrapper">
            <div className="outer_content_wrapper">
              <div className="content_wrapper">
                <div className="left_side_content_wrapper">
                  <div className="tests_type_collection_wrapper">
                    <div className="single_select_test_type">
                      <input
                        type="radio"
                        id="completed_tests"
                        name="test_type"
                        value="completed_tests"
                        onChange={(event) => handleChangeTestType(event)}
                        checked={state.testType === 1}
                      />
                      <label htmlFor="completed_tests">Completed</label>
                    </div>
                    <div className="single_select_test_type">
                      <input
                        type="radio"
                        id="upcoming_tests"
                        name="test_type"
                        value="upcoming_tests"
                        onChange={(event) => handleChangeTestType(event)}
                        checked={state.testType === 2}
                      />
                      <label htmlFor="upcoming_tests">Upcoming</label>
                    </div>
                    <div className="single_select_test_type">
                      <input
                        type="radio"
                        id="all_tests"
                        name="test_type"
                        value="all_tests"
                        onChange={(event) => handleChangeTestType(event)}
                        checked={state.testType === 3}
                      />
                      <label htmlFor="all_tests">All</label>
                    </div>
                  </div>
                </div>
                <div className="right_side_content_wrapper">
                  {renderTestType()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => {
      dispatch(uiActions.startLoading());
    },
    finishLoading: () => {
      dispatch(uiActions.finishLoading());
    },
    setUserTestsData: (payload) => {
      dispatch(userTestsExercisesAction.setUserTestsData(payload));
    },
    setUserTestsResultData: (payload) => {
      dispatch(userTestsExercisesAction.setUserTestsResultData(payload));
    },
    setExerciseData: (payload) => {
      dispatch(userActivityActions.setExerciseData(payload));
    },
  };
};

export default connect(
  null,
  mapDispatchToProps
)(UserExerciseTestContentComponent);
