import { Route } from "react-router-dom";
import RedirectRoute from "./redirect";

//Redux
import { connect } from "react-redux";

const PrivateRoute = ({
  component: Component,
  isAuthenticated,
  render,
  isBlocked,
  remark,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (isAuthenticated && (!isBlocked || !remark)) {
          return Component ? <Component {...props} /> : render(props);
        } else {
          return (
            <RedirectRoute
              isBlocked={isBlocked}
              remark={remark}
              Component={Component}
              props={props}
              render
            />
          );
        }
      }}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
  };
};

export default connect(mapStateToProps)(PrivateRoute);
