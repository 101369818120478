import { useRef } from "react";
import "./Pdf.scss";
import jsPDF from "jspdf";
import StudentReportPDF from "./StudentTestReportPdf";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { connect } from "react-redux";

function Pdf({ loginFromParent }) {
  let history = useHistory();

  const reportTemplateRef = useRef(null);

  const handleGeneratePdf = () => {
    const doc = new jsPDF({
      format: "a1",
      unit: "px",
    });

    let pageHeight = doc.internal.pageSize.height;
    // Adding the fonts.
    doc.setFont("Inter-Regular", "normal");
    let y = 500; // Height position of new content
    if (y >= pageHeight) {
      doc.addPage();
      y = 0; // Restart height position
    }
    doc.html(reportTemplateRef.current, {
      async callback(doc) {
        await doc.save("document");
      },
    });

    setTimeout(() => {
      if (loginFromParent) {
        history.push("/parent-dashboard");
      } else {
        history.push({
          pathname: "/student-tests",
          state: { step: 2 },
        });
      }
    }, 2000);
  };

  return (
    <div className="student_pdf">
      <div className="pdf_btn">
        <button className="button" onClick={handleGeneratePdf}>
          Download (PDF)
        </button>
      </div>
      <div ref={reportTemplateRef}>
        <StudentReportPDF />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    loginFromParent: state.parentrootReducer.parentAuthReducer.loginFromParent,
  };
};

export default connect(mapStateToProps, null)(Pdf);
