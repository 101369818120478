import { Fragment, useEffect, useState } from "react";
import "./user_doubts.scss";

///////////////////Desktop
import HeaderComponent from "../structure_components/desktop_screens/header_component/header_component";

///////Mobile////////////////////
import MobileLeftSideBarComponent from "../structure_components/mobile_screens/mobile_left_sideBar_component/mobile_left_sideBar_component";
import MobileHeaderComponent from "../structure_components/mobile_screens/mobile_header_component/mobile_header_component";
import MobileRightSideBarComponent from "../structure_components/mobile_screens/mobile_right_sideBar_component/mobile__right_sideBar_component";

import UserDoubtsContentComponent from "../user_doubts/user_doubts_content_component/user_doubts_content_component";
import LoaderPopUp from "../../../components/loader/loader";

//Redux
import { connect } from "react-redux";
import Footer_component from "../structure_components/desktop_screens/footer_component/Footer_component";

const UserDoubts = (props) => {
  let { loader, history, userData, userPreference } = props;

  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const setWidth = () => {
    setWindowWidth(window.innerWidth)
  }
  useEffect(() => {
    window.addEventListener( 'resize',setWidth)

    return (() => {
      window.removeEventListener('resize',setWidth)
    })
  },[windowWidth])
  return (
    <Fragment>
    {windowWidth <= 480 ? (
            /////for Mobile Screens////////////////////
            <Fragment>
              <MobileLeftSideBarComponent />
              <MobileHeaderComponent
                userPreference={userPreference}
                userData={userData}
              />
              <MobileRightSideBarComponent history={history} />
            </Fragment>
          ) : (
            /////for Screens greater than Mobile Screens////////////////////
            <Fragment>
              <div className="user_doubts_right_wrapper">
                <HeaderComponent history={history} />
              </div>
            </Fragment>
          )}
      <div className="user_doubts_wrapper">
        <div className="user_doubts_inner_wrapper">
        <UserDoubtsContentComponent
                  history={history}
                  userPreference={userPreference}
                />
        </div>
        <Footer_component/>
      </div>
      {loader && <LoaderPopUp />}
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    loader: state.ui.loader,
    userData: state.auth.user,
    userPreference: state.userPreference,
  };
};

export default connect(mapStateToProps)(UserDoubts);
