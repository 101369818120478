import { Fragment } from "react";
import "./live_web_chat_feature_content_component.scss";

import { isEmpty } from "../../../../../custom_utilities/custom_useful_functions";
import images from "../../../../../utilities/images/images";

///////Chat component///////////
import ChatComponent from "./chat_components/chat_components";

const LiveWebChatFeatureContentComponent = (props) => {
  let { history, location } = props;

  const handleBackBtn = () => {
    history.push("/live_stream");
  };

  return (
    <Fragment>
      <div className="liveWebChatStreamFeatureContentComponent_wrapper">
        <div className="liveWebChatStreamFeatureContentComponent_inner_wrapper">
          <div className="sec_1_wrapper">
            <div className="sec_1_inner_wrapper">
              <div className="btn_wrapper">
                <button type="button" onClick={() => handleBackBtn()}>
                  <img src={images.user_activity_1} />
                </button>
              </div>
            </div>
          </div>
          <div className="sec_2_wrapper">
            <div className="sec_2_inner_wrapper">
              <ChatComponent history={history} location={location} />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default LiveWebChatFeatureContentComponent;
