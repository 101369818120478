import { Fragment, useState, useEffect, useRef } from "react";
import "./PYQ_test_content_component.scss";
///////
import TestInstructionsView from "./test_instructions_view/test_instructions_view";
import TestQuestionsView from "./test_questions_view/test_questions_view";
import pyqActions from "../../../../../../redux/pyq_test_exercises/action";
import {
  url_81,
  url_39,
  url_77,
  url_174,
  url_175,
  url_143,
} from "../../../../../../custom_utilities/api_services";
import axios from "axios";
import { isEmpty } from "../../../../../../custom_utilities/custom_useful_functions";

//Redux
import { connect } from "react-redux";
import uiActions from "../../../../../../redux/ui/action";
import RewardModal from "../../../../../../components/RewardModal/RewardModal";
import CoinUsesModal from "../../../../../../components/CoinUsesModal/CoinUsesModal";

const TestContentComponent = (props) => {
  let {
    history,
    testData,
    setPyqTestsResultData,
    userPreference,
    startLoading,
    finishLoading,
    userData,
  } = props;
  const [state, setState] = useState({
    step: 1,
    testDetails: {},
    testSectionsDetails: {},
    currentOptionId: null,
    testQuestionsDetails: [],
    currentQuestionDetails: {},
    subjecSectiontWiseTestQuestions: {},
    checkQuestion: {},
    currentQuestion: 0,
    countDownTimer: {
      hours: 0,
      minutes: 0,
      seconds: 0,
    },
    initialTime: 0,
    useTime: 0,
    earnedCoins: 0,
    coinPopup: false,
    reason: "RewardModal",
    coinDetailsPopup: false,
    coinUsesPoints: [],
    textResultData: {},
  });
  const [selectedSubject, setSubject] = useState("");
  const [selectedSection, setSection] = useState("");

  const questionsRef = useRef([]);

  const handleSubjectChange = (subject) => {
    const section = Object.keys(
      state.subjecSectiontWiseTestQuestions[subject]
    )[0];
    setSubject(subject);
    setSection(section);
    const currentQuestion =
      state.subjecSectiontWiseTestQuestions[subject][section][0]["matchIndex"];
    setState((prevState) => ({
      ...prevState,
      currentQuestion,
    }));

    const { testQuestionsDetails } = state;
    let cq = currentQuestion;
    getCurrentQuestionDetails(
      testQuestionsDetails[cq].paper_id,
      testQuestionsDetails[cq].qid
    );

    // setTimeout(() => {
    //   scrollUp(currentQuestion);
    // }, 0);

    setTimeout(() => {
      scrollUp(currentQuestion, subject);
    }, 100);
  };

  const handleSectionChange = (section) => {
    setSection(section);
    const currentQuestion =
      state.subjecSectiontWiseTestQuestions[selectedSubject][section][0][
        "matchIndex"
      ];
    setState((prevState) => ({
      ...prevState,
      currentQuestion,
    }));

    const { testQuestionsDetails } = state;
    let cq = currentQuestion;
    getCurrentQuestionDetails(
      testQuestionsDetails[cq].paper_id,
      testQuestionsDetails[cq].qid
    );

    scrollUp(currentQuestion, section);
  };

  // useEffect(() => {
  //   subjectWiseData(state.testQuestionsDetails);
  // }, [state.testQuestionsDetails]);

  useEffect(() => {
    if (state.testQuestionsDetails.length) {
      setSubject(state.testQuestionsDetails[state.currentQuestion]["subject"]);
      setSection(
        state.testQuestionsDetails[state.currentQuestion]["section_name"]
      );
    }
  }, [state.currentQuestion, state.testQuestionsDetails]);

  useEffect(() => {
    getTestDetails();
    getTestSectionsDetails();
    coinUsesApi();
  }, []);

  useEffect(() => {
    if (state.step === 2) {
      createCountDownTimer(state.testDetails.duration_mins);
    }
    return () => {
      clearInterval(timeInterval);
    };
  }, [state.step]);

  let timeInterval;
  const createCountDownTimer = (duration) => {
    let useTime = state.useTime ?? 0;
    useTime = useTime * 1000;

    //duration is in minutes, first convert it to milliseconds
    let countdownTime = duration * 60 * 1000 + new Date().getTime();
    countdownTime = countdownTime - useTime;

    timeInterval = setInterval(() => {
      let now = new Date().getTime();
      let distance = countdownTime - now;
      if (distance < 0) {
        clearInterval(timeInterval);
        //hidemyend    //handleSubmitTest();
      }
      let hours = Math.floor(
        (distance % (1000 * 60 * 60 * 60)) / (1000 * 60 * 60)
      );
      let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      let seconds = Math.floor((distance % (1000 * 60)) / 1000);
      setState((prevState) => ({
        ...prevState,
        countDownTimer: {
          ...prevState.countDownTimer,
          hours,
          minutes,
          seconds,
        },
      }));
    }, 1000);
  };

  const getTestDetails = () => {
    setState((prevState) => ({ ...prevState, testDetails: testData }));
  };

  const getTestSectionsDetails = async () => {
    let data = {
      paper_id: testData.paper_id,
    };

    startLoading();
    try {
      // const response = await axios.post(url_29, data);
      const response = await axios.post(
        "https://learning.motion.ac.in/motioneducation/api/user/pyqPaperInstructions",
        data
      );

      if (response.data.status === 200) {
        setState((prevState) => ({
          ...prevState,
          testSectionsDetails: response.data.data,
        }));
        finishLoading();
      }
    } catch (error) {
      finishLoading();
      console.log(error);
    }
  };

  const getTestQuestionsList = async (testDetails) => {
    let data = {
      paper_id: testDetails.paper_id,
    };
    startLoading();
    try {
      // const response = await axios.post(url_80, data);
      const response = await axios.post(
        "https://learning.motion.ac.in/motioneducation/api/user/pyqquestionlist",
        data
      );
      if (response.data.status === 200) {
        finishLoading();
        return {
          result: 1,
          data: { apiData: response.data },
        };
      } else {
        finishLoading();
        return {
          result: 0,
          data: { apiData: {} },
        };
      }
    } catch (error) {
      console.log(error);
      finishLoading();
      return {
        result: 0,
        data: { apiData: {} },
      };
    }
  };

  const getCurrentQuestionDetails = async (paper_id, qid) => {
    // const headers = {
    //   Authorization: `${tokenType} ${token}`,
    // };

    const data = {
      paper_id,
      qid,
    };
    startLoading();

    try {
      const response = await axios.post(
        "https://learning.motion.ac.in/motioneducation/api/user/pyqsinglequestion",
        data
      );

      if (response.data.status === 200) {
        finishLoading();
        setState((prevState) => ({
          ...prevState,
          currentQuestionDetails: response.data.data[0],
        }));
      }
    } catch (error) {
      finishLoading();
      console.log(error);
    }
  };

  const handleCurrentOption = (optionId) => {
    setState((prevState) => {
      return {
        ...prevState,
        currentOptionId: optionId,
      };
    });
  };

  const subjectWiseData = (questions) => {
    const subjectSectionWiseData = {};
    for (let i = 0; i < questions.length; i++) {
      questions[i]["matchIndex"] = i;
      if (!subjectSectionWiseData[questions[i]["subject"]]) {
        subjectSectionWiseData[questions[i]["subject"]] = {
          [questions[i]["section_name"]]: [questions[i]],
        };
      } else if (
        subjectSectionWiseData[questions[i]["subject"]] &&
        !subjectSectionWiseData[questions[i]["subject"]][
          questions[i]["section_name"]
        ]
      ) {
        subjectSectionWiseData[questions[i]["subject"]][
          questions[i]["section_name"]
        ] = [questions[i]];
      } else {
        subjectSectionWiseData[questions[i]["subject"]][
          questions[i]["section_name"]
        ].push(questions[i]);
      }
    }
    return subjectSectionWiseData;
  };

  const getNowTime = () => {
    let d = new Date();
    return d.getTime();
  };

  const startBtn = async () => {
    //let lastOption = Number(localStorage.getItem("current"));
    let testSectionsDetails = { ...state.testSectionsDetails };
    let testDetails = { ...state.testDetails };
    if (!isEmpty(testSectionsDetails) && !isEmpty(testDetails)) {
      let returnedResponse = await getTestQuestionsList(testDetails);
      const _subjecSectiontWiseTestQuestions = subjectWiseData(
        returnedResponse.data.apiData.data
      );
      if (returnedResponse.result) {
        getCurrentQuestionDetails(
          returnedResponse.data.apiData.data[0].paper_id,
          returnedResponse.data.apiData.data[0].qid
        );

        returnedResponse.data.apiData.data.forEach((element) => {
          element.isReview = false;
          // element.isSkipped = false;
          element.isAttempted = false;
          element.isAnswerGuessed = false;
          element.totalTimeTaken = 0;
          element.isAnswerCorrect = 0;
          element.correctAnswerIdCollection = [];
          element.wrongAnswerIdCollection = [];
          element.userAnswerCollection = [];
          // element.q_text = element.q_text.replaceAll(
          //   "ckfinder",
          //   "http://quizmaster.motion.ac.in/ckfinder"
          // );
          element.q_text = element.q_text
            .replaceAll(
              'src="ckfinder',
              'src="http://quizmaster.motion.ac.in/ckfinder'
            )
            .replaceAll(
              'SRC="ckfinder',
              'SRC="http://quizmaster.motion.ac.in/ckfinder'
            )
            .replaceAll(
              'src="/ckfinder',
              'src="http://quizmaster.motion.ac.in/ckfinder'
            )
            .replaceAll(
              'SRC="/ckfinder',
              'SRC="http://quizmaster.motion.ac.in/ckfinder'
            )
            .replaceAll(
              'SRC="ckfinder',
              'SRC="http://quizmaster.motion.ac.in/ckfinder'
            )
            .replaceAll(
              'SRC="./ckfinder',
              'SRC="http://quizmaster.motion.ac.in/ckfinder'
            )
            .replaceAll(
              'SRC= "ckfinder',
              'SRC="http://quizmaster.motion.ac.in/ckfinder'
            );
          element.option?.forEach((opt) => {
            opt.option = opt.option
              .replaceAll(
                'src="ckfinder',
                'src="http://quizmaster.motion.ac.in/ckfinder'
              )
              .replaceAll(
                'SRC="ckfinder',
                'SRC="http://quizmaster.motion.ac.in/ckfinder'
              )
              .replaceAll(
                'src="/ckfinder',
                'src="http://quizmaster.motion.ac.in/ckfinder'
              )
              .replaceAll(
                'SRC="/ckfinder',
                'SRC="http://quizmaster.motion.ac.in/ckfinder'
              )
              .replaceAll(
                'SRC="ckfinder',
                'SRC="http://quizmaster.motion.ac.in/ckfinder'
              )
              .replaceAll(
                'SRC="./ckfinder',
                'SRC="http://quizmaster.motion.ac.in/ckfinder'
              )
              .replaceAll(
                'SRC= "ckfinder',
                'SRC="http://quizmaster.motion.ac.in/ckfinder'
              );
          });
          //
          //      "src=\"ckfinder", "src=\"http://quizmaster.motion.ac.in/ckfinder"
          //    )
          //    .replaceAll(
          //      "SRC=\"ckfinder", "SRC=\"http://quizmaster.motion.ac.in/ckfinder"
          //    )
          //    .replaceAll(
          //      "src=\"/ckfinder", "src=\"http://quizmaster.motion.ac.in/ckfinder"
          // )
          // .replaceAll(
          //      "SRC=\"/ckfinder", "SRC=\"http://quizmaster.motion.ac.in/ckfinder"
          //    )
          //    .replaceAll(
          //      "SRC=\"ckfinder", "SRC=\"http://quizmaster.motion.ac.in/ckfinder"
          //    )
          //    .replaceAll(
          //      "SRC=\"./ckfinder", "SRC=\"http://quizmaster.motion.ac.in/ckfinder"
          //    ).replaceAll(
          //      "SRC= \"ckfinder", "SRC=\"http://quizmaster.motion.ac.in/ckfinder"
          //    );
        });
        let time = getNowTime();
        setState((prevState) => ({
          ...prevState,
          step: 2,
          testQuestionsDetails: returnedResponse.data.apiData.data,
          subjecSectiontWiseTestQuestions: _subjecSectiontWiseTestQuestions,
          initialTime: time,
          useTime: returnedResponse.data.apiData.total_time,
        }));
        //handleQuestionJump(lastOption);
      } else {
        alert("No test questions found");
      }
    } else {
      alert("You don't have Test Details");
    }
  };

  const getCalculatedTime = (initialTime) => {
    let d = new Date();
    let time = d.getTime();

    let calculatedTime = Math.round((time - initialTime) / 1000);

    return calculatedTime;
  };

  const handlePreviousQuestionBtn = (cq) => {
    let {
      currentQuestion,
      initialTime,
      testQuestionsDetails,
      currentQuestionDetails,
    } = state;
    let calculatedTime = getCalculatedTime(initialTime);

    testQuestionsDetails[currentQuestion].totalTimeTaken = calculatedTime;

    if (
      testQuestionsDetails[currentQuestion - 1].isAttempted ||
      currentQuestionDetails?.option?.filter((m) => m.is_user_correct_ans)
        ?.length
    ) {
      testQuestionsDetails[currentQuestion - 1].isReview = false;
    } else {
      testQuestionsDetails[currentQuestion - 1].isReview = true;
    }

    // let time = getNowTime();
    if (currentQuestion >= 1) {
      setState((prevState) => ({
        ...prevState,
        currentQuestion: currentQuestion - 1,
        testQuestionsDetails: testQuestionsDetails,
        // initialTime: time,
      }));

      handleSaveQuestion();
      window.scrollTo(0, 0);
      getCurrentQuestionDetails(
        testQuestionsDetails[cq].paper_id,
        testQuestionsDetails[cq].qid
      );
    }
  };

  const scrollUp = (currentQuestion) => {
    const questionElements = document.getElementsByClassName(
      "single_question_number_wrapper"
    );
    const spaceAtTop = 30; // Adjust the value as needed

    if (
      questionElements.length > 0 &&
      currentQuestion > 0 &&
      currentQuestion < questionElements.length
    ) {
      const targetElement = questionElements[currentQuestion - 1];
      const offsetTop = targetElement.offsetTop - spaceAtTop;
      const containerElement = document.getElementsByClassName(
        "questions_serial_number_collection"
      )[0];
      containerElement.scrollTo({
        top: offsetTop,
        behavior: "smooth",
      });
    } else if (questionElements.length > 0 && currentQuestion === 0) {
      const containerElement = document.getElementsByClassName(
        "questions_serial_number_collection"
      )[0];
      containerElement.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  const handleSaveQuestion = async () => {
    let { currentQuestion, initialTime, testQuestionsDetails, testDetails } =
      state;
    let crntQuestionDetail = testQuestionsDetails[currentQuestion];
    let formatData =
      testDetails.format ===
      "MAIN PATTERN TEST  [20 SINGLE, 10 NUMERIC] EACH SUBJECT"
        ? "MAIN"
        : testDetails.format === "NEET NEW PATTERN 2021 OTS"
        ? "NEET"
        : "OTHER";

    let data = {};

    if (crntQuestionDetail.answer_type == "Integer Correct") {
      data = {
        negative_marks: crntQuestionDetail.negative_marks,
        option_int: testQuestionsDetails[currentQuestion]?.integer_answer
          ?.length
          ? testQuestionsDetails[currentQuestion].integer_answer
          : "",
        paper_id: crntQuestionDetail.paper_id,
        partial_marks: crntQuestionDetail.partial_marks,
        positive_marks: crntQuestionDetail.positive_marks,
        qid: crntQuestionDetail.qid,
        subject: crntQuestionDetail.subject,
        time: crntQuestionDetail.totalTimeTaken,
        topic_name: crntQuestionDetail.topic_name,
        toughness: crntQuestionDetail.toughness,
        format: formatData,
        section: crntQuestionDetail.section_name,
      };
    } else if (crntQuestionDetail.answer_type == "Multiple Correct") {
      data = {
        negative_marks: Number(crntQuestionDetail.negative_marks),
        option_id: crntQuestionDetail?.userAnswerCollection?.length
          ? crntQuestionDetail.userAnswerCollection.map((item) => ({
              option_id: item.option_id,
            }))
          : [],
        paper_id: crntQuestionDetail.paper_id,
        partial_marks: Number(crntQuestionDetail.partial_marks),
        positive_marks: Number(crntQuestionDetail.positive_marks),
        qid: crntQuestionDetail.qid,
        subject: crntQuestionDetail.subject,
        time: crntQuestionDetail.totalTimeTaken,
        topic_name: crntQuestionDetail.topic_name,
        toughness: crntQuestionDetail.toughness,
        format: formatData,
        section: crntQuestionDetail.section_name,
      };
    } else {
      data = {
        negative_marks: Number(crntQuestionDetail.negative_marks),
        option_id: [state.currentOptionId],
        paper_id: crntQuestionDetail.paper_id,
        partial_marks: Number(crntQuestionDetail.partial_marks),
        positive_marks: Number(crntQuestionDetail.positive_marks),
        qid: crntQuestionDetail.qid,
        subject: crntQuestionDetail.subject,
        time: crntQuestionDetail.totalTimeTaken,
        topic_name: crntQuestionDetail.topic_name,
        toughness: crntQuestionDetail.toughness,
        format: formatData,
        section: crntQuestionDetail.section_name,
      };
    }
    startLoading();
    const response = await axios.post(
      "https://learning.motion.ac.in/motioneducation/api/user/UserPYQDetailsNew",
      data
    );
    if (response.data.status === 200) {
      finishLoading();
    }
  };

  const handleNextQuestionBtn = (cq) => {
    let {
      currentQuestion,
      initialTime,
      testQuestionsDetails,
      currentQuestionDetails,
    } = state;
    let calculatedTime = getCalculatedTime(initialTime);
    testQuestionsDetails[currentQuestion].totalTimeTaken = calculatedTime;
    handleSaveQuestion();
    //newline add

    if (
      testQuestionsDetails[currentQuestion].isAttempted ||
      currentQuestionDetails?.option?.filter((m) => m.is_user_correct_ans)
        ?.length
    ) {
      testQuestionsDetails[currentQuestion].isReview = false;
    } else {
      testQuestionsDetails[currentQuestion].isReview = true;
    }
    let time = getNowTime();

    setState((prevState) => ({
      ...prevState,
      currentQuestion: currentQuestion + 1,
      testQuestionsDetails: testQuestionsDetails,
      initialTime: time,
    }));

    getCurrentQuestionDetails(
      testQuestionsDetails[cq].paper_id,
      testQuestionsDetails[cq].qid
    );

    window.scrollTo(0, 0);
  };

  const handleSingleSelectAnswer = (event, optionId) => {
    let { checked } = event.target;
    let { currentQuestion, testQuestionsDetails } = state;
    //localStorage.setItem("current", currentQuestion);
    if (checked) {
      testQuestionsDetails[currentQuestion].isAttempted = true;
      testQuestionsDetails[currentQuestion].userAnswerCollection[0] = {
        option_id: optionId,
      };

      if (
        testQuestionsDetails[currentQuestion].correctAnswerIdCollection.indexOf(
          optionId
        ) === -1
      ) {
        //if you not find the answer in backendCorrect answer array
        testQuestionsDetails[currentQuestion].wrongAnswerIdCollection[0] =
          Number(optionId);
        testQuestionsDetails[currentQuestion].correctAnswerIdCollection = [];
        testQuestionsDetails[currentQuestion].isAnswerCorrect = 0;
      } else {
        testQuestionsDetails[currentQuestion].correctAnswerIdCollection[0] =
          optionId;
        testQuestionsDetails[currentQuestion].wrongAnswerIdCollection = [];
        testQuestionsDetails[currentQuestion].isAnswerCorrect = 1;
      }
    } else {
      testQuestionsDetails[currentQuestion].isAttempted = false;
      testQuestionsDetails[currentQuestion].userAnswerCollection = [];
      testQuestionsDetails[currentQuestion].wrongAnswerIdCollection = [];
      testQuestionsDetails[currentQuestion].correctAnswerIdCollection = [];
      testQuestionsDetails[currentQuestion].isAnswerCorrect = 0;
    }

    setState((prevState) => ({ ...prevState, testQuestionsDetails }));
  };

  const handleMultiSelectAnswer = (_event, optionId) => {
    let { currentQuestion, testQuestionsDetails } = state;

    let findedIndex = testQuestionsDetails[
      currentQuestion
    ].userAnswerCollection.findIndex((element) => {
      return element.option_id === optionId;
    });

    if (findedIndex === -1) {
      let Obj = {
        option_id: optionId,
      };
      testQuestionsDetails[currentQuestion].userAnswerCollection.splice(
        testQuestionsDetails[currentQuestion].userAnswerCollection.length,
        0,
        Obj
      );
    } else {
      testQuestionsDetails[currentQuestion].userAnswerCollection.splice(
        findedIndex,
        1
      );
    }

    if (testQuestionsDetails[currentQuestion].userAnswerCollection.length) {
      testQuestionsDetails[currentQuestion].isAttempted = true;
    } else {
      testQuestionsDetails[currentQuestion].isAttempted = false;
    }

    if (
      testQuestionsDetails[currentQuestion].userAnswerCollection.indexOf(
        optionId
      ) === -1
    ) {
      if (
        testQuestionsDetails[currentQuestion].wrongAnswerIdCollection.indexOf(
          optionId
        ) === -1
      ) {
        testQuestionsDetails[currentQuestion].wrongAnswerIdCollection.splice(
          testQuestionsDetails[currentQuestion].wrongAnswerIdCollection.length,
          0,
          optionId
        );
      } else {
        testQuestionsDetails[currentQuestion].wrongAnswerIdCollection.splice(
          testQuestionsDetails[currentQuestion].wrongAnswerIdCollection.indexOf(
            optionId
          ),
          1
        );
      }
    } else {
      if (
        testQuestionsDetails[currentQuestion].correctAnswerIdCollection.indexOf(
          optionId
        ) === -1
      ) {
        testQuestionsDetails[currentQuestion].correctAnswerIdCollection.splice(
          testQuestionsDetails[currentQuestion].correctAnswerIdCollection
            .length,
          0,
          optionId
        );
      } else {
        testQuestionsDetails[currentQuestion].correctAnswerIdCollection.splice(
          testQuestionsDetails[
            currentQuestion
          ].correctAnswerIdCollection.indexOf(optionId),
          1
        );
      }
    }

    if (testQuestionsDetails[currentQuestion].wrongAnswerIdCollection.length) {
      testQuestionsDetails[currentQuestion].isAnswerCorrect = false;
    }

    setState((prevState) => ({ ...prevState, testQuestionsDetails }));
  };

  const handleInputFieldChange = (event) => {
    let { value } = event.target;
    // value = value.replace(/[^0-9]/g, "")
    let { currentQuestion, testQuestionsDetails } = state;
    testQuestionsDetails[currentQuestion].integer_answer = value.trim();

    if (testQuestionsDetails[currentQuestion].integer_answer.length) {
      testQuestionsDetails[currentQuestion].isAttempted = true;
    } else {
      testQuestionsDetails[currentQuestion].isAttempted = false;
    }

    setState((prevState) => ({ ...prevState, testQuestionsDetails }));
  };
  const reviewQuestion = async (currentQuestion) => {
    const { paper_id, qid, isAnswerGuessed } = currentQuestion;
    let data = {
      paper_id: paper_id,
      reference_id: qid,
      status: isAnswerGuessed ? 1 : 0,
      type: 3,
    };
    const response = await axios.post(url_77, data);
  };

  const handleReview = () => {
    let { currentQuestion, testQuestionsDetails } = state;

    testQuestionsDetails[currentQuestion].isAnswerGuessed =
      !testQuestionsDetails[currentQuestion].isAnswerGuessed;

    setState((prevState) => ({ ...prevState, testQuestionsDetails }));
    reviewQuestion(testQuestionsDetails[currentQuestion]);
  };

  const handleSaved = () => {
    let { currentQuestion, testQuestionsDetails, testDetails } = state;

    testQuestionsDetails[currentQuestion].is_saved =
      !testQuestionsDetails[currentQuestion].is_saved;

    setState((prevState) => ({ ...prevState, testQuestionsDetails }));
    if (testQuestionsDetails[currentQuestion].is_saved) {
      let data = {
        type: 3, // 3=>test
        reference_id: testQuestionsDetails[currentQuestion].qid,
        status: 1, // 1=> save
      };
      bookmarkApi(data);
    } else {
      let data = {
        type: 3, // 3=>test
        reference_id: testQuestionsDetails[currentQuestion].qid,
        status: 0, // 0=> unsave
      };
      bookmarkApi(data);
    }
  };

  const bookmarkApi = async (data) => {
    try {
      const response = await axios.post(url_39, data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleGuessedAnswer = (event) => {
    let { checked } = event.target;
    let { currentQuestion, testQuestionsDetails } = state;

    testQuestionsDetails[currentQuestion].isAnswerGuessed = checked;

    setState((prevState) => ({ ...prevState, testQuestionsDetails }));
  };

  const handleQuestionJump = (questionNumber) => {
    const { testQuestionsDetails } = state;

    //handleSaveQuestion();
    setState((prevState) => ({
      ...prevState,
      currentQuestion: questionNumber,
      // currentQuestion:0,
    }));

    getCurrentQuestionDetails(
      testQuestionsDetails[questionNumber].paper_id,
      testQuestionsDetails[questionNumber].qid
    );
  };

  const handleSubmitTest = async (currentQuestionDetails) => {
    let { testDetails, testQuestionsDetails } = state;

    let element = currentQuestionDetails;

    let questionArr = [];

    if (element.answer_type.toLowerCase() === "single correct") {
      let data = {
        qid: element.qid,
        type_id: element.type_id,
        is_saved: element.is_saved,
        total_time: element.totalTimeTaken,
        is_attempted: element.isAttempted,
        is_AnswerCorrect: element.isAnswerCorrect,
        is_GessTypeAnswer: element.isAnswerGuessed,
        paragraph_answer: element.paragraph_answer,
        integer_answer: element.integer_answer,
        positive_marks: element.positive_marks,
        negative_marks: element.negative_marks,
        partial_marks: element.partial_marks,
        CorrectAnswerId: element.correctAnswerIdCollection,
        WrongeAnswerId: element.wrongAnswerIdCollection,
        answer: element.userAnswerCollection,
        toughness_id: element.toughness_id,
        subject: element.subject,
        topic_id: element.topic_id,
      };
      if (!data.type_id) {
        data.type_id = 6;
      }
      questionArr.push(data);
    } else if (element.answer_type.toLowerCase() === "multiple correct") {
      let data = {
        qid: element.qid,
        type_id: element.type_id,
        is_saved: element.is_saved,
        total_time: element.totalTimeTaken,
        is_attempted: element.isAttempted,
        is_AnswerCorrect: element.isAnswerCorrect,
        is_GessTypeAnswer: element.isAnswerGuessed,
        paragraph_answer: element.paragraph_answer,
        integer_answer: element.integer_answer,
        positive_marks: element.positive_marks,
        negative_marks: element.negative_marks,
        partial_marks: element.partial_marks,
        CorrectAnswerId: element.correctAnswerIdCollection,
        WrongeAnswerId: element.wrongAnswerIdCollection,
        answer: element.userAnswerCollection,
        toughness_id: element.toughness_id,
        subject: element.subject,
        topic_id: element.topic_id,
      };
      if (!data.type_id) {
        data.type_id = 6;
      }
      questionArr.push(data);
    } else if (element.answer_type.toLowerCase() === "integer correct") {
      let data = {
        qid: element.qid,
        type_id: element.type_id,
        is_saved: element.is_saved,
        total_time: element.totalTimeTaken,
        is_attempted: element.isAttempted,
        paragraph_answer: element.paragraph_answer,
        integer_answer: element.integer_answer,
        is_AnswerCorrect: element.isAnswerCorrect,
        is_GessTypeAnswer: element.isAnswerGuessed,
        positive_marks: element.positive_marks,
        negative_marks: element.negative_marks,
        partial_marks: element.partial_marks,
        CorrectAnswerId: element.correctAnswerIdCollection,
        WrongeAnswerId: element.wrongAnswerIdCollection,
        answer: element.userAnswerCollection,
        toughness_id: element.toughness_id,
        subject: element.subject,
        topic_id: element.topic_id,
      };
      if (!data.type_id) {
        data.type_id = 6;
      }
      questionArr.push(data);
    }
    startLoading();
    let returnedResponse = await submitTestApi(
      userPreference,
      testDetails,
      questionArr
    );
    if (returnedResponse.result) {
      finishLoading();
      setPyqTestsResultData(testDetails);
      getPyqPracticeSummaryAndAddCoins();
      // history.push({
      //   pathname: "/pyq-summary",
      // });
    } else {
      finishLoading();
      alert(returnedResponse.data.message);
      // history.push("/user_exercise_test");
    }
  };

  const submitTestApi = async (_userPreference, testDetails, _questionArr) => {
    let requestPayload = {
      // course_id: userPreference.courseDetails[0].course_id,
      // class_id: userPreference.classDetails.class_id,
      // batch_id: userPreference.batchDetails.batch_id,
      paper_id: testDetails.paper_id,
      // time: state.countDownTimer.minutes,
      time: 84,
      // paper_duration: testDetails.duration_mins,
      // paper_marks: testDetails.total_marks,
      // question: questionArr,
    };

    // let requestPayload = {
    //   course_id: 36,
    //   class_id: 2,
    //   batch_id: 30,
    //   paper_id: testDetails.paper_id,
    //   paper_duration: testDetails.duration_mins,
    //   paper_marks: testDetails.total_marks,
    //   question: questionArr,
    // };

    try {
      const response = await axios.post(url_81, requestPayload);
      if (response.data.status === 200) {
        return {
          result: 1,
          data: {
            message: response.data.message,
          },
        };
      } else {
        return {
          result: 0,
          data: {
            message: response.data.message,
          },
        };
      }
    } catch (error) {
      console.log(error);
      return {
        result: 0,
        data: {
          message: "Something went wrong!!!",
        },
      };
    }
  };

  const handleRedirectBtn = () => {
    history.push({
      pathname: "/pyq",
      state: { tab: "pyq" },
    });
  };

  const getPyqPracticeSummaryAndAddCoins = async () => {
    let data = {
      paper_id: state.testDetails.paper_id,
    };
    startLoading();
    try {
      const response = await axios.post(url_143, data);
      if (response.data.status === 200) {
        setState((prev) => {
          return {
            ...prev,
            textResultData: response.data.data,
          };
        });
        console.log("resultData", response.data.data);
        addCoinsOnSubmission(
          response.data.data.correct_answer,
          response.data.data.incorrect_answer
        );
        finishLoading();
      }
    } catch (error) {
      console.log(error);
      finishLoading();
    }
  };
  const addCoinsOnSubmission = async (correct_ans, wrong_ans) => {
    const data = {
      type: "Attending_PYQ",
      wrong_ans: wrong_ans,
      correct_ans: correct_ans,
      paper_type: "PYQ",
      paper_id: state.testDetails.paper_id,
    };
    try {
      const response = await axios.post(url_174, data);
      if (response.data.status === 200) {
        if (response.data.data !== "MCoins Already Credited...!") {
          console.log(response.data);
          setState((prev) => {
            return {
              ...prev,
              earnedCoins: response.data.coins,
              coinPopup: response.data.coins > 0 ? true : false,
              reason: response.data.data.description,
            };
          });
          if (response.data.coins === 0) {
            history.push({
              pathname: "/pyq-summary",
            });
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const coinUsesApi = async () => {
    try {
      const response = await axios.get(url_175);
      if (response.data.status === 200) {
        console.log(response.data.Uses);
        setState((prevState) => {
          return {
            ...prevState,
            coinUsesPoints: response.data.data,
          };
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const renderView = (step) => {
    switch (step) {
      case 1:
        return (
          <TestInstructionsView
            testDetails={state.testDetails}
            testSectionsDetails={state.testSectionsDetails}
            startBtn={startBtn}
            handleRedirectBtn={handleRedirectBtn}
          />
        );
      case 2:
        return (
          <TestQuestionsView
            testQuestionsDetails={state.testQuestionsDetails}
            subjecSectiontWiseTestQuestions={
              state.subjecSectiontWiseTestQuestions
            }
            handleCurrentOption={handleCurrentOption}
            selectedSubject={selectedSubject}
            selectedSection={selectedSection}
            handleSubjectChange={handleSubjectChange}
            handleSectionChange={handleSectionChange}
            currentQuestion={state.currentQuestion}
            countDownTimer={state.countDownTimer}
            handlePreviousQuestionBtn={handlePreviousQuestionBtn}
            handleNextQuestionBtn={handleNextQuestionBtn}
            handleSaveQuestion={handleSaveQuestion}
            handleSingleSelectAnswer={handleSingleSelectAnswer}
            handleMultiSelectAnswer={handleMultiSelectAnswer}
            handleInputFieldChange={handleInputFieldChange}
            handleReview={handleReview}
            handleSaved={handleSaved}
            handleGuessedAnswer={handleGuessedAnswer}
            handleQuestionJump={handleQuestionJump}
            handleSubmitTest={handleSubmitTest}
            checkQuestion={state.checkQuestion}
            userId={userData.user_id}
            startLoading={startLoading}
            finishLoading={finishLoading}
            currentQuestionDetails={state.currentQuestionDetails}
          />
        );

      default:
        return null;
    }
  };

  return (
    <Fragment>
      <RewardModal
        show={state.coinPopup}
        onHide={() => {
          history.push({ pathname: "/pyq-summary" });
        }}
        earnMoreHandler={() =>
          setState((prev) => {
            return { ...prev, coinDetailsPopup: true, coinPopup: false };
          })
        }
        earnedCoins={state.earnedCoins}
        reason={state.reason}
      />
      <CoinUsesModal
        show={state.coinDetailsPopup}
        onHide={() => {
          history.push({ pathname: "/pyq-summary" });
        }}
        data={state.coinUsesPoints}
      />
      <div className="Pyq_test_content_component_wrapper">
        <div className="test_content_component_inner_wrapper">
          {renderView(state.step)}
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    testData: state.pyqTestsData.pyqTestData,
    userPreference: state.userPreference,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => {
      dispatch(uiActions.startLoading());
    },
    finishLoading: () => {
      dispatch(uiActions.finishLoading());
    },

    setPyqTestsResultData: (payload) => {
      dispatch(pyqActions.setPyqTestsResultData(payload));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TestContentComponent);
