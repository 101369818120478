import React, { useEffect, useState } from "react";

///////////////////Desktop
import HeaderComponent from "../../structure_components/desktop_screens/header_component/header_component";
import PyqCpsExamComponent from "../pyqCpsExamComponent/PyqCpsExamContentComponent/PyqCpsExamContentComponent";
//////Mobile////////////////////
import MobileLeftSideBarComponent from "../../structure_components/mobile_screens/mobile_left_sideBar_component/mobile_left_sideBar_component";
import MobileHeaderComponent from "../../structure_components/mobile_screens/mobile_header_component/mobile_header_component";
import MobileRightSideBarComponent from "../../structure_components/mobile_screens/mobile_right_sideBar_component/mobile__right_sideBar_component";

import LoaderPopUp from "../../../../components/loader/loader";
import { connect } from "react-redux";
import Footer_component from "../../structure_components/desktop_screens/footer_component/Footer_component";

const PyqCpsExam = (props) => {
  let { history, loader, userData, userPreference, location } = props;
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const setWidth = () => {
    setWindowWidth(window.innerWidth)
  }
  useEffect(() => {
    window.addEventListener( 'resize',setWidth)

    return (() => {
      window.removeEventListener('resize',setWidth)
    })
  },[windowWidth])
  return (
    <>
      <div className="pyqCpsExamWrapper">
        <div className="pyqCpsExamInrWrapper">
          {windowWidth <= 480 ? (
            <>
              <MobileLeftSideBarComponent />
              <MobileHeaderComponent
                userPreference={userPreference}
                userData={userData}
              />
              <MobileRightSideBarComponent history={history} />
            </>
          ) : (
            <HeaderComponent history={history} />
          )}
          <PyqCpsExamComponent
            history={history}
            userData={userData}
            userPreference={userPreference}
            location={location}
          />
        </div>
      </div>
      
        <Footer_component/>
      {loader && <LoaderPopUp />}
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    loader: state.ui.loader,
    userData: state.auth.user,
    userPreference: state.userPreference,
  };
};
export default connect(mapStateToProps)(PyqCpsExam);
