import { createSlice } from "@reduxjs/toolkit";

const MultipleLoginDetectSlice = createSlice({
  name: "multipleLogin",
  initialState: {
    isMultipleLogin: false,
    
  },
  reducers: {
    setMultipleLoginStatus: (state, action) => {
      state.isMultipleLogin = action.payload.isMultipleLogin;
    },
  },
});

export const { setMultipleLoginStatus } = MultipleLoginDetectSlice.actions;
export default MultipleLoginDetectSlice.reducer;
