// AdaptiveCpsTestInstructions.js

import React, { useState, useEffect } from "react";
// import AdaptiveCpstTestInstructionsView from "../../instruction-components/AdaptiveCpsTestInstructionView";
import { isEmpty } from "../../../../../../../../custom_utilities/custom_useful_functions";
import axios from "axios";
import AdaptiveCpstTestInstructionsView from "./adaptiveCpsTestInstructionView/AdaptiveCpsTestInstructionView";

const AdaptiveCpsTestInstructions = (props) => {
  console.log("AdaptiveCpsTestInstructions");
  const {
    handleRedirectBtn,
    left,
    history,
    getTestQuestionsList,
    userData,
    getNowTime,
    setAdaptiveCpsId,
    startLoading,
    finishLoading,
    adaptiveCpsTestData,
    tokenType,
    token,
    handleStep2,
    attemptCount,
  } = props;

  const [state, setState] = useState({
    testInstructionData: {},
  });

  useEffect(() => {
    getinstructionDetail();
  }, []);

  const getinstructionDetail = async () => {
    const headers = {
      Authorization: `${tokenType} ${token}`,
    };
    console.log("headers", tokenType, token);
    const data = {
      subject: adaptiveCpsTestData.selectedSubject,
    };
    startLoading();
    try {
      const response = await axios.post(
        "https://learning.motion.ac.in/motioneducation/api/user/UserAdaptiveCPS",
        data,
        { headers }
      );

      if (response.data.status === 200) {
        console.log("getInstrctionDetails", response.data.cps_id);
        setState((preState) => ({
          ...preState,
          testInstructionData: response.data,
        }));
        setAdaptiveCpsId(response.data.cps_id);
      } else if (response.data.status === 401) {
        alert(response.data.message);
        history.push({
          pathname: "/create-adaptive-cps",
          state: { tab: "adaptive_cps" },
        });
      } else if (response.data.status === 402) {
        alert(response.data.message);
        history.push({
          pathname: "/create-adaptive-cps",
          state: { tab: "adaptive_cps" },
        });
      } else {
        alert(response.data.message);
        history.push({
          pathname: "/create-adaptive-cps",
          state: { tab: "adaptive_cps" },
        });
      }

      finishLoading();
    } catch (error) {
      console.log(error);
    }
  };

  const startBtn = async () => {
    let testDetails = { ...state.testInstructionData };
    if (!isEmpty(testDetails)) {
      let returnedResponse = await getTestQuestionsList(testDetails);
      console.log("returnedResponsereturnedResponse", returnedResponse);
      if (returnedResponse.result) {
        returnedResponse.data.apiData.data.forEach((element) => {
          element.isReview = false;
          // element.isSkipped = false;
          element.isAttempted = false;
          element.isAnswerGuessed = false;
          element.totalTimeTaken = 0;
          element.isAnswerCorrect = 0;
          element.correctAnswerIdCollection = [];
          element.wrongAnswerIdCollection = [];
          element.userAnswerCollection = [];
          element.q_text = element.q_text
            .replaceAll(
              'src="ckfinder',
              'src="http://quizmaster.motion.ac.in/ckfinder'
            )
            .replaceAll(
              'SRC="ckfinder',
              'SRC="http://quizmaster.motion.ac.in/ckfinder'
            )
            .replaceAll(
              'src="/ckfinder',
              'src="http://quizmaster.motion.ac.in/ckfinder'
            )
            .replaceAll(
              'SRC="/ckfinder',
              'SRC="http://quizmaster.motion.ac.in/ckfinder'
            )
            .replaceAll(
              'SRC="ckfinder',
              'SRC="http://quizmaster.motion.ac.in/ckfinder'
            )
            .replaceAll(
              'SRC="./ckfinder',
              'SRC="http://quizmaster.motion.ac.in/ckfinder'
            )
            .replaceAll(
              'SRC= "ckfinder',
              'SRC="http://quizmaster.motion.ac.in/ckfinder'
            );
          element.sol_text = element.sol_text
            ?.replaceAll(
              'src="ckfinder',
              'src="http://quizmaster.motion.ac.in/ckfinder'
            )
            .replaceAll(
              'SRC="ckfinder',
              'SRC="http://quizmaster.motion.ac.in/ckfinder'
            )
            .replaceAll(
              'src="/ckfinder',
              'src="http://quizmaster.motion.ac.in/ckfinder'
            )
            .replaceAll(
              'SRC="/ckfinder',
              'SRC="http://quizmaster.motion.ac.in/ckfinder'
            )
            .replaceAll(
              'SRC="ckfinder',
              'SRC="http://quizmaster.motion.ac.in/ckfinder'
            )
            .replaceAll(
              'SRC="./ckfinder',
              'SRC="http://quizmaster.motion.ac.in/ckfinder'
            )
            .replaceAll(
              'SRC= "ckfinder',
              'SRC="http://quizmaster.motion.ac.in/ckfinder'
            );
          element.option?.forEach((opt) => {
            opt.option = opt.option
              .replaceAll(
                'src="ckfinder',
                'src="http://quizmaster.motion.ac.in/ckfinder'
              )
              .replaceAll(
                'SRC="ckfinder',
                'SRC="http://quizmaster.motion.ac.in/ckfinder'
              )
              .replaceAll(
                'src="/ckfinder',
                'src="http://quizmaster.motion.ac.in/ckfinder'
              )
              .replaceAll(
                'SRC="/ckfinder',
                'SRC="http://quizmaster.motion.ac.in/ckfinder'
              )
              .replaceAll(
                'SRC="ckfinder',
                'SRC="http://quizmaster.motion.ac.in/ckfinder'
              )
              .replaceAll(
                'SRC="./ckfinder',
                'SRC="http://quizmaster.motion.ac.in/ckfinder'
              )
              .replaceAll(
                'SRC= "ckfinder',
                'SRC="http://quizmaster.motion.ac.in/ckfinder'
              );
          });
        });
        let time = getNowTime();
        const testQuestionsDetails = returnedResponse.data.apiData.data;
        handleStep2(2, testQuestionsDetails);

        setState((prevState) => ({
          ...prevState,
          //   testQuestionsDetails: returnedResponse.data.apiData.data,
          //subjecSectiontWiseTestQuestions: _subjecSectiontWiseTestQuestions,
          initialTime: time,
          useTime: returnedResponse.data.apiData.total_time,
        }));
        //handleQuestionJump(lastOption);
      } else {
        alert("No test questions found");
      }
    } else {
      alert("You don't have Test Details");
    }
  };

  return (
    <AdaptiveCpstTestInstructionsView
      testInstructionData={state.testInstructionData}
      startBtn={startBtn}
      handleRedirectBtn={handleRedirectBtn}
      total_attempts={left}
      history={history}
      attemptCount={attemptCount}
    />
  );
};

export default AdaptiveCpsTestInstructions;
