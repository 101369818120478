import * as actionTypes from "./action_type";

const setPyqTestsData = (payload) => {
  return {
    type: actionTypes.SET_PYQ_TESTS_DATA,
    payload,
  };
};

const setPyqTestsResultData = (payload) => {
  return {
    type: actionTypes.SET_PYQ_TESTS_RESULT_DATA,
    payload,
  };
};

const setPyqJeeOrAdvance = (payload) => {
  return {
    type: actionTypes.SET_PYQ_JEE_MAINS_OR_ADVANCED,
    payload,
  };
};

export default {
  setPyqTestsData,
  setPyqJeeOrAdvance,
  setPyqTestsResultData,
};
