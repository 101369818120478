import * as actionTypes from "./action_type";

const setCpsTestsData = (payload) => {
  return {
    type: actionTypes.SET_CPS_TESTS_DATA,
    payload,
  };
};

const setCpsTestsResultData = (payload) => {
  return {
    type: actionTypes.SET_CPS_TESTS_RESULT_DATA,
    payload,
  };
};

export default {
  setCpsTestsData,
  setCpsTestsResultData,
};
