import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  storeCurrentPackageDetails: {},
  refferalCode: "",
};

const PackageSlice = createSlice({
  name: "packages",
  initialState,
  reducers: {
    setStoreCurrentPackageDetails: (state, action) => {
      state.storeCurrentPackageDetails = action.payload;
    },

    setRefferalCode: (state, action) => {
      state.refferalCode = action.payload;
    },
  },
});

export const { setStoreCurrentPackageDetails, setRefferalCode } =
  PackageSlice.actions;

export default PackageSlice.reducer;
