import React from "react";
import "./PyqCpsExamTestComponent.scss";
//
import TestInstructionView from "./test_Instruction_view/Test_Instruction_view";
import TestQuestionView from "./test_Question_View/Test_Question_View";

import axios from "axios";
import { isEmpty } from "../../../../../custom_utilities/custom_useful_functions";
//Redux
import { connect } from "react-redux";
import uiActions from "../../../../../redux/ui/action";

const PyqCpsExamTestComponent = () => {
  return <div></div>;
};

export default PyqCpsExamTestComponent;
