import { Fragment, useState } from "react";
import "./chat_container.scss";

import { isEmpty } from "../../../../../../../custom_utilities/custom_useful_functions";
import moment from "moment";

const ChatContainer = (props) => {
  let { accessTokenDetails, messages } = props;

  const renderChats = (messages) => {
    return messages.map((element, index) => {
      if (
        element.state.author.toLowerCase() ===
        accessTokenDetails.identity.toLowerCase()
      ) {
        return renderSenderChat(element, index);
      } else {
        return renderReceiverChat(element, index);
      }
    });
  };

  const renderSenderChat = (singleChat, index) => {
    let { state } = singleChat;
    return (
      <Fragment>
        <div key={index} className="sender_single_chat_wrapper">
          <div className="left_side_content_wrapper">
            {renderMessageType(state)}
            <div className="time_content_wrapper">
              <p className="time_content">
                {moment(state.timestamp).format("MMMM Do YYYY, h:mm:ss a")}
              </p>
            </div>
          </div>
          <div className="right_side_content_wrapper">
            <div className="initial_wrapper">
              <div className="text_content_wrapper">
                <p className="text_content">
                  {renderNameInitials(state.author)}
                </p>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  };
  const renderReceiverChat = (singleChat, index) => {
    let { state } = singleChat;
    return (
      <Fragment>
        <div key={index} className="receiver_single_chat_wrapper">
          <div className="left_side_content_wrapper">
            <div className="initial_wrapper">
              <div className="text_content_wrapper">
                <p className="text_content">
                  {renderNameInitials(state.author)}
                </p>
              </div>
            </div>
          </div>
          <div className="right_side_content_wrapper">
            {renderMessageType(state)}
            <div className="time_content_wrapper">
              <p className="time_content">
                {moment(state.timestamp).format("MMMM Do YYYY, h:mm:ss a")}
              </p>
            </div>
          </div>
        </div>
      </Fragment>
    );
  };

  const renderMessageType = (message) => {
    let messageType;
    if (message.type === "media") {
      messageType = 1;
    } else {
      messageType = 2;
    }

    switch (messageType) {
      case 1:
        return (
          <Fragment>
            <div className="image_wrapper">
              <img src={message.mediaURL} />
            </div>
          </Fragment>
        );
      case 2:
        return (
          <Fragment>
            <div className="text_content_wrapper">
              <p className="text_content">{message.body}</p>
            </div>
          </Fragment>
        );

      default:
        return null;
    }
  };

  const renderNameInitials = (text) => {
    let str = "";
    let splittedTextArr = text.split(" ");

    splittedTextArr.slice(0, 2).forEach((element) => {
      str = str + element[0].toUpperCase();
    });

    return str;
  };

  return (
    <Fragment>
      <div className="chat_container_wrapper">
        <div className="chat_container_inner_wrapper">
          <div className="upcoming_chats_wrapper" id="incoming_chats_wrapper">
            {renderChats(messages)}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ChatContainer;
