import React, { useState, useEffect } from "react";
import "./ExamTestSummary.scss";
import images from "../../../../../../utilities/images/images";
import { url_195 } from "../../../../../../custom_utilities/api_services";

import { Link } from "react-router-dom/cjs/react-router-dom.min";
import HeaderComponent from "../../../../structure_components/desktop_screens/header_component/header_component";
import uiActions from "../../../../../../redux/ui/action";
import { connect } from "react-redux";
import axios from "axios";
import { isEmpty } from "../../../../../../custom_utilities/custom_useful_functions";
import Header_component from "../../../../structure_components/desktop_screens/header_component/header_component";
import Mobile__right_sideBar_component from "../../../../structure_components/mobile_screens/mobile_right_sideBar_component/mobile__right_sideBar_component";
import Mobile_header_component from "../../../../structure_components/mobile_screens/mobile_header_component/mobile_header_component";
import MobileLeftSideBarComponent from "../../../../structure_components/mobile_screens/mobile_left_sideBar_component/mobile_left_sideBar_component";

const ExamTestSummary = (props) => {
  const { startLoading, finishLoading, testResultData, testData, token, tokenType, history, userData, userPreference } =
    props;
  const [examSummary, setExamSummary] = useState({});

  useEffect(() => {
    getExamSummary();
  }, []);

  const getExamSummary = async () => {
    const headers = {
      Authorization: `${tokenType} ${token}`,
    };

    let data = {
      // paper_id: testResultData.paper_id,
      paper_id: testData.paper_id,
    };

    startLoading();
    try {
      const response = await axios.post(url_195, data, { headers });
      if (response.data.status === 200) {
        setExamSummary(response.data.data);
        finishLoading();
      }
    } catch (error) {
      console.log(error);
      finishLoading();
    }
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const setWidth = () => {
    setWindowWidth(window.innerWidth)
  }
  useEffect(() => {
    window.addEventListener( 'resize',setWidth)

    return (() => {
      window.removeEventListener('resize',setWidth)
    })
  },[windowWidth])

  return (
    <>
      {/* <HeaderComponent /> */}
      {windowWidth <= 480 ? (
            /////for Mobile Screens////////////////////
            <>
              <MobileLeftSideBarComponent />
              <Mobile_header_component
                userPreference={userPreference}
                userData={userData}
              />
              <Mobile__right_sideBar_component history={history} />
            </>
          ) : (
            /////for Screens greater than Mobile Screens////////////////////
            <>
                <Header_component history={history} />
            </>
          )}
      <div className="examSummaryComponentWrapper">
        <div className="examSummaryComponentInnrWrapper">
          <div className="container">
            <div className="examSummaryContainer">
              <div class="examSummaryContent">
                <div class="examSummaryBackBtn">
                  <Link
                    className="backBtn"
                    to={{
                      pathname: "/exam",
                      state: { tab: "exam" },
                    }}
                  >
                    <img src={images.m2_backBtn} alt="backBtn" />
                  </Link>
                </div>

                <div class="section_heading">Summary</div>

                {!isEmpty(examSummary) ? (
                  <div className="cards">
                    <div class=" card total-questions">
                      <div class="num">
                        {examSummary.total_questions
                          ? examSummary.total_questions
                          : "0"}
                      </div>
                      <div class="text">Total Questions</div>
                    </div>

                    <div class="card correct-answer">
                      <div class="num">
                        {examSummary.correct_answer
                          ? examSummary.correct_answer
                          : "0"}
                      </div>
                      <div class="text">Correct answer</div>
                    </div>

                    <div class="card incorrect-answer">
                      <div class="num">
                        {examSummary.incorrect_answer
                          ? examSummary.incorrect_answer
                          : "0"}
                      </div>
                      <div class="text">Incorrect answer</div>
                    </div>

                    <div class="card total-marks">
                      <div class="num">
                        {examSummary.total_marks
                          ? examSummary.total_marks
                          : "0"}
                      </div>
                      <div class="text">Total Marks</div>
                    </div>

                    <div class="card score-percentage">
                      <div class="num">
                        {examSummary.score_percentage
                          ? examSummary.score_percentage
                          : "0"}
                      </div>
                      <div class="text">Score Percentage</div>
                    </div>

                    <div class="card score-percentage">
                      <div class="num">
                        {examSummary.partial_marks
                          ? examSummary.partial_marks
                          : "0"}
                      </div>
                      <div class="text">Partial Marks</div>
                    </div>
                  </div>
                ) : (
                  <div className="exam_no_analysis_found_wrapper">
                    <img src={images.m2_video_ntF} />
                    <p>There is no Summary yet!</p>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div class="back-to-tests">
            <Link
              className="backBtn"
              to={{
                pathname: "/exam",
                state: { tab: "exam" },
              }}
            >
              <button>Back to Tests</button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loader: state.ui.loader,
    testResultData: state.userTestsExercisesData.testResultData,
    testData: state.userTestsExercisesData.testData,
    token: state.auth.authToken,
    tokenType: state.auth.tokenType,
    userData: state.auth.user,
    userPreference: state.userPreference,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => {
      dispatch(uiActions.startLoading());
    },
    finishLoading: () => {
      dispatch(uiActions.finishLoading());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExamTestSummary);
