import React, { useEffect, useState } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";

const OtsDifficultyLevelCircularProgress = ({ data }) => {
  const [currentToughness, setCurrentToughnhess] = useState(data[0].Toughness);

  const handleChangeSubject = (event) => {
    setCurrentToughnhess(event.target.value);
  };

  return (
    <div className="type_list">
      <div className="list">
        {data?.map((element, index) => {
          return (
            <div key={index} className="single_select_subject">
              <input
                type="radio"
                id={index}
                value={element.Toughness}
                checked={element.Toughness == currentToughness}
                onChange={(event) => {
                  setCurrentToughnhess(event.target.value);
                }}
              />
              <label htmlFor={index}>{element.Toughness}</label>
            </div>
          );
        })}
      </div>

      {data
        ?.filter((element) => {
          if (element.Toughness == currentToughness) {
            return element;
          }
        })
        .map((element) => {
          return (
            <div className="progress_box_wrapper">
              <div className="progress_box">
                <div className="progress-circle">
                  <div className="progress-circle-top">
                    <div className="progress-circle-one">
                      <CircularProgressbar
                        styles={buildStyles({
                          textSize: "15px",
                          pathColor: "#FF933A",
                          borderRadius: "50%",
                          width: "150",
                          backgroundColor: "#ECC5A5",
                          border: "4px solid black",
                        })}
                        className="circular"
                        sqSize="200"
                        value={element.WRONG}
                        text={`${element.WRONG} %
                  `}
                        strokeWidth={5}
                        background="#ECC5A5"
                      />

                      <span>Incorrect</span>
                    </div>

                    <div className="progress-circle-two">
                      <CircularProgressbar
                        styles={buildStyles({
                          textSize: "15px",
                          backgroundColor: "#ACEECB",
                          pathColor: "#31D680",
                        })}
                        className="circular"
                        value={element.Unattempted}
                        text={`${element.Unattempted} %`}
                        strokeWidth={5}
                        background="#ACEECB"
                      />
                      <span>Unattempted</span>
                    </div>
                  </div>

                  <div className="progress-circle-bottom">
                    <div className="progress-circle-third">
                      <CircularProgressbar
                        styles={buildStyles({
                          textSize: "15px",
                          backgroundColor: "#BBCCF2",

                          pathColor: "#668DE1",
                        })}
                        className="circular"
                        value={element.Correct}
                        text={`${element.Correct} %`}
                        strokeWidth={5}
                        background="#BBCCF2"
                      />
                      <span className="progress-circle-text">Correct</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}

      {/* {loader && <LoaderPopUp />} */}
    </div>
  );
};

export default OtsDifficultyLevelCircularProgress;
