import { Fragment, useEffect, useState } from "react";
import "./user_store_content_component.scss";

//////Component List//////////
import PackageOfferingComponent from "./store_components/package_offering_component/package_offering_component";
import PaymentInformationComponent from "./store_components/payment_information_component/payment_information_component";
import PackageDetailsComponent from "./store_components/package_details_component/package_details_component";
import HandBooks from "./handBooks/HandBooks";

import axios from "axios";
import { sha512 } from "js-sha512";

import { Analytics } from "aws-amplify";
import {
  setStoreCurrentPackageDetails,
  setRefferalCode,
} from "../../../../redux/packages_store/PackageSlice";
import { setPackageDetailsForPayment } from "../../../../redux/payment_gateway/action";
import userProductList from "../../../../redux/new_store/action";

import {
  url_8,
  url_9,
  url_16,
  url_17,
  url_19,
  url_56,
  url_59,
  url_92,
  url_176,
  url_204,
} from "../../../../custom_utilities/api_services";

import jsSHA from "jssha";
//Redux
import { connect } from "react-redux";
import uiActions from "../../../../redux/ui/action";
import authActions from "../../../../redux/auth/action";
import images from "../../../../utilities/images/images";
import { useLocation } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { isEmpty } from "../../../../custom_utilities/custom_useful_functions";
import { toastifyMessage } from "../../../../custom_utilities/toastify_config";

const UserStoreContentComponent = (props) => {
  let {
    history,
    userData,
    userPreference,
    startLoading,
    finishLoading,
    setUser,
    setStoreCurrentPackageDetails,
    setRefferalCode,
    setPackageDetailsForPayment,
    orderId,
    token,
    tokenType,
    user_id,
    loginType,
    pageEqualToStorecourseDetails,
    setLoginType,
  } = props;

  console.log(
    "login-type---------------------------------------------->",
    loginType
  );

  const pageEqualToStorecourseDetails1 = {
    active: 1,
    advantages: null,
    allow_installment: "Yes",
    amount: "2199.00",
    batch: [],
    batch_id: null,
    bg_image: "/storage/package/course_banner/381.png",
    coin_discount_amt: "1099.00",
    course: "JEE 12th English",
    course_benifits:
      "Text and video solutions,Topic-wise tests,100+ Practice papers",
    course_id: 13,
    created_on: "2023-12-16 13:39:06",
    description: `Features:
    90+ Past year papers,
    34 Subtopic wise Minor test,
    9 Major test,
    17 Full Syllabus test,
    5 BITSAT test,
    3 WBJEE test,
    2 IAT test,
    One-stop solution for all engineering exams,
    Video and text solutions of each test,
    Detailed report and analysis after every test,
    Created and crafted by expert Kota faculties as per latest updated syllabus,
    Validity till JEE Advanced 2025,
    Use 50% motion coins for an extra discount.`,
    discount: "0.00",
    duration: "12 months",
    expiry_date: "2025-07-05",
    final_amount: "2199",
    icon:
      "https://learning.motion.ac.in/motioneducation//storage/package/app_icon.png",
    is_amirt: 0,
    is_delete: 0,
    is_referral: null,
    is_refundable: null,
    is_subscribe: false,
    package_id: 381,
    package_include_module: [
      { module: "Live Classess" },
      { module: "Video" },
      { module: "Zoom Classess" },
    ],
    package_name: "Motion Targeted Abhyas (MAT) - Phase 1.0",
    package_sequence: null,
    package_tag: "2",
    package_thumbnail: "/storage/package/thumbnail/381.png",
    package_type: 3,
    tags: null,
    txn_expire_date: "",
    u_programId: null,
    video_url: null,
    web_thumbnail: "/storage/package/web_thumbnail/381.png",
  };

  let location = useLocation();
  let tab = location?.state?.tab?.length
    ? location.state.tab
    : "online_learning";
  // let addToCart = location.state.addToCart;
  // let cartDetails = location.state.cartDetails.cartDetails;

  const [state, setState] = useState({
    step: 1,
    userPrefernces: {
      courseId: "",
      classId: "",
      batchId: "",
    },
    packagesCollection: {},
    packageDetails: {},
    facultyList: {},
    subjectList: {},
    facultyDetail: {},
    freePackagesCollection: {},
    couponDetails: {},
    isCourseId: false,
    selectPackageId: "",
    selectPackageAmt: "",
    coinData: {},
    coupanApplied: 0, // 0 means not applied, 1 means applied before others
    showBanner: true,
  });

  const [cpnAmt, setCpnAmt] = useState("");

  const [originalAmt, setOriginalAmt] = useState("");

  useEffect(() => {
    // if (loginType === "store") {
    
    if(loginType !== "dashboard")
    learnMoreBtn(pageEqualToStorecourseDetails1);
    setLoginType("dashboard");
    // }
  }, []);

  const selectPackage = (selectPackageId, selectPackageAmt) => {
    setState((prevState) => ({
      ...prevState,
      selectPackageId,
      selectPackageAmt,
    }));
  };
  const coinDataApi = async () => {
    startLoading();
    try {
      const response = await axios.get(url_176);
      if (response.data.status === 200) {
        finishLoading();
        console.log(response.data.consolidated_coins);
        setState((prevState) => {
          return {
            ...prevState,
            coinData: response.data.consolidated_coins,
          };
        });
      } else {
        finishLoading();
      }
    } catch (error) {
      console.log(error);
      finishLoading();
    }
  };

  useEffect(() => {
    getUserPreferences();
    coinDataApi();
  }, []);

  useEffect(() => {
    if (state.isCourseId) {
      getPackagesCollection();
      getFreePackagesCollection();
      getSubjectList();
    }
  }, [state.userPrefernces.courseId]);

  const currentData = new Date();
  let date =
    currentData.getFullYear() +
    "-" +
    (currentData.getMonth() + 1) +
    "-" +
    currentData.getDate();
  let time =
    currentData.getHours() +
    ":" +
    currentData.getMinutes() +
    ":" +
    currentData.getSeconds();
  var dateTime = date + " " + time;
  const getUserPreferences = () => {
    setState((prevState) => ({
      ...prevState,
      userPrefernces: {
        ...prevState.userPrefernces,
        courseId: userPreference.courseDetails[0].course_id,
        classId: userPreference.classDetails.class_id,
        batchId: userPreference.batchDetails.batch_id,
      },
      isCourseId: true,
    }));
  };

  const getPackagesCollection = async () => {
    let userPrefernces = { ...state.userPrefernces };
    startLoading();
    try {
      const response = await axios.get(
        `${url_16}?course_id=${userPrefernces.courseId}&batch_id=${userPrefernces.batchId}&source="Store"`
      );

      if (response.data.status === 200) {
        finishLoading();
        setState((prevState) => ({
          ...prevState,
          packagesCollection: response.data.data,
          isCourseId: false,
        }));
      }
    } catch (error) {
      finishLoading();
      console.log(error);
    }
  };
  const getFreePackagesCollection = async () => {
    // let userPrefernces = { ...state.userPrefernces };
    startLoading();
    let data = {
      search: "",
      target_id: userPreference.targetDetails[0].target_id,
    };

    try {
      const response = await axios.post(url_56, data);

      if (response.data.status === 200) {
        finishLoading();
        setState((prevState) => ({
          ...prevState,
          freePackagesCollection: response.data.data,
          isCourseId: false,
        }));
      }
    } catch (error) {
      finishLoading();
      console.log(error);
    }
  };
  const getUpdatedAmountFromBackend = async () => {
    try {
      const data = {
        user_id: user_id,
        product_id: state.packageDetails.package_id,
        order_id: orderId,
      };

      const response = await axios.get(
        `${url_204}?order_id=${orderId}&product_id=${state.packageDetails.package_id}&user_id=${user_id}`,
        data
      );

      console.log("response of updated amount from backend: " + response.data);
    } catch (err) {
      console.log(err.message);
    }
  };

  const couponCollection = async (cpnInput, applyCoinData) => {
    startLoading();

    await getUpdatedAmountFromBackend();

    let data = {
      coupon_code: cpnInput,
      user_id: userData.user_id,
      package_id: state.selectPackageId,
      amount: isEmpty(applyCoinData)
        ? state.selectPackageAmt
        : applyCoinData.updated_amount,
      order_id: orderId,
      is_coupon: cpnInput.length > 0 ? true : false,
    };

    try {
      const response = await axios.post(url_59, data);
      console.log("response of apply-coupon-web", response);
      if (response.data.data.status === 200) {
        finishLoading();
        Analytics.record({
          name: "web_coupon_applied click",
          attributes: {
            coupon_code: cpnInput,
            user_id: userData?.user_id,
            phone_number: userData?.mobile,
            course_id: state?.packageDetails?.course_id,
            course_name: state?.packageDetails?.course,
            package_id: state?.packageDetails?.package_id,
            package_name: state?.packageDetails?.package_name,
          },
        });
        setState((prevState) => ({
          ...prevState,
          couponDetails: response.data.data,
          isCourseId: false,
          coupanApplied: applyCoinData === 1 ? 2 : 1,
        }));
        toastifyMessage({ message: response.data.data.message, status: 200 });

        setCpnAmt(response.data.data.updated_amount);
      } else {
        finishLoading();
        // alert(response.data.message);
        toastifyMessage({ message: response.data.data.message, status: 201 });
      }
    } catch (error) {
      finishLoading();
      console.log(error);
    }
  };

  const getSubjectList = async () => {
    let userPrefernces = { ...state.userPrefernces };
    let data = {
      course_id: userPrefernces.courseId,
      class_id: userPrefernces.classId,
    };
    // startLoading();
    try {
      const response = await axios.post(url_9, data);

      if (response.data.status === 200) {
        // finishLoading();
        setState((prevState) => ({
          ...prevState,
          subjectList: response.data.data,
        }));
      }
    } catch (error) {
      // finishLoading();
      console.log(error);
    }
  };

  const handleStepNavigation = (stepValue, showBannerVal = false) => {
    setState((prevState) => ({
      ...prevState,
      step: stepValue,
      showBanner: showBannerVal,
    }));
  };

  const updateCouponDetails = () => {
    setState((prevState) => ({ ...prevState, couponDetails: {} }));
  };

  const pricePurchaseBtn = (packageDetails) => {
    // packageDetails.final_amount= state.couponDetails.updated_amount+1
    setState((prevState) => ({ ...prevState, packageDetails: packageDetails }));
    // handleStepNavigation(2);
    // handlePaymentInformationPayBtn(packageDetails);
  };

  const learnMoreBtn = async (packageDetails) => {
    setRefferalCode(" ");
    setState((prevState) => ({
      ...prevState,
      showBanner: false,
    }));
    Analytics.record({
      name: "web_product_overview_page",
      attributes: {
        user_id: userData.user_id,
        phone_number: userData.mobile,
        course_id: packageDetails?.course_id,
        course_name: packageDetails?.course,
        package_id: packageDetails?.package_id,
        package_name: packageDetails?.package_name,
      },
    });

    // if (parseInt(packageDetails.is_amirt) === 1) {
    //   setStoreCurrentPackageDetails(packageDetails);

    //   history.push({
    //     pathname: "/package_overview",
    //   });
    // }

    // else {
    setState((prevState) => ({
      ...prevState,
      packageDetails: packageDetails,
    }));
    let returnedResponse = await getFacultyList(packageDetails);
    if (returnedResponse.result === 1) {
      setState((prevState) => ({
        ...prevState,
        facultyList: returnedResponse.data.apiData,
      }));
      handleStepNavigation(3);
    }
    // }
  };

  const getFacultyList = async (packageDetails) => {
    console.log("packageDetails------------in-------->", packageDetails);
    startLoading();
    try {
      const response = await axios.get(
        `${url_17}?package_id=${packageDetails.package_id}`
      );

      if (response.data.status === 200) {
        finishLoading();
        return { result: 1, data: { apiData: response.data.data } };
      } else {
        return { result: 0, data: { apiData: {} } };
      }
    } catch (error) {
      finishLoading();
      console.log(error);
      return { result: 0, data: { apiData: {} } };
    }
  };
  const getFacultyDetail = async (element) => {
    let userPrefernces = { ...state.userPrefernces };
    let data = {
      user_id: element.user_id,
    };
    startLoading();
    try {
      const response = await axios.post(url_92, data);
      if (response.data.status === 200) {
        setState((prevState) => ({
          ...prevState,
          facultyDetail: response.data.data,
        }));
        finishLoading();
      }
      finishLoading();
    } catch (error) {
      finishLoading();
      console.log(error);
    }
  };

  const HMAC = (message, key) => {
    var hmac = "";
    var shaObj = new jsSHA("SHA-256", "TEXT");
    shaObj.setHMACKey(key, "HEX");
    shaObj.update(message);
    hmac = shaObj.getHMAC("HEX");
    // var hash = sha256.hmac.create(key);
    //     hash.update(message);
    //     hash.hex();
    // return hash
    return hmac;
  };

  const handlePaymentInformationPayBtn = (packageDetails) => {
    // let returnedResponse = purchaseCheck();
    if (!packageDetails.is_subscribe) {
      let userDataDetails = { ...userData };
      // let packageDetails = { ...state.packageDetails };
      if (userDataDetails.email) {
        if (packageDetails.final_amount) {
          let txnId = getReference();
          let merchantKey = "ObWj6L",
            salt = "jFCXe6Kt";
          //  key|txnid|amount|productinfo|firstname|email|udf1|udf2|udf3|udf4|udf5||||||salt

          let hashSequence = `${merchantKey}|${txnId}|${packageDetails.final_amount}|${packageDetails.package_name}|${userDataDetails.name}|${userDataDetails.email}|||||||||||${salt}`;
          // let hashSequence = `${merchantKey}|${txnId}|1|${packageDetails.package_name}|${userDataDetails.name}|${userDataDetails.email}|||||||||||${salt}`;
          let hashValue = sha512(hashSequence);
          // console.log(hashValue);

          let payURequestParams = {
            key: merchantKey, ///*** Merchant key from PayuMoney Dashboard ***/
            txnid: txnId, ///*** Unique Transaction ID***/
            amount: packageDetails.final_amount, ///*** Amount to be paid ***/
            // amount: "1", ///*** Amount to be paid ***/

            firstname: userDataDetails.name, ///*** Name of the User ***/
            email: userDataDetails.email, ///** Email Id of User **/
            phone: userDataDetails.mobile, ///** Mobile number of User **/
            productinfo: packageDetails.package_name, ///* Product name */
            surl: "dummyURL", ///* Success callback URL */
            furl: "dummyURL", ///* Failure callback URL */
            hash: hashValue,
          };

          redirectPayUMoney(payURequestParams);
        } else {
          alert("Amount is not proper");
          return;
        }
      } else {
        alert(
          "Please haven't update your email in your profile. Please update it"
        );
        history.push("/user_edit_profile");
      }
    } else {
      alert("You have already purchased this plan.");
    }
  };

  const createHiddenElement = (key, value) => {
    const hiddenField = document.createElement("input");
    hiddenField.setAttribute("name", key);
    hiddenField.setAttribute("value", value);
    hiddenField.setAttribute("type", "hidden");
    return hiddenField;
  };

  const getReference = () => {
    //you can put any unique reference implementation code here
    let text = "";
    let possible =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-.=";

    for (let i = 0; i < 15; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));

    return text;
  };

  const redirectPayUMoney = (data) => {
    //use window.bolt.launch if you face an error in bolt.launch
    window.bolt.launch(data, {
      responseHandler: function (response) {
        // console.log(response);
        // your payment response Code goes here
        if (response.response.txnStatus.toLowerCase() === "success") {
          saveUserTransactions(response);
        } else {
          alert(response.response.txnMessage);
        }
      },
      catchException: function (response) {
        // the code you use to handle the integration errors goes here
        // Make any UI changes to convey the error to the user
        // console.log(response);
        alert(response.response.txnMessage);
      },
    });
  };

  const activePackage = async (data) => {
    let packageDetails = { ...state.packageDetails };
    let userPreferences = { ...state.userPrefernces };
    let dataDetails = {
      course_id: userPreferences.courseId,
      batch_id: userPreferences.batchId,
      package_id: data.package_id,
      txn_amount: packageDetails.final_amount,
      //txn_discount_amount: packageDetails.discount,
      txn_amount: "0.00",
      //txn_discount_amount: "",
      txn_date: dateTime,
      package_duration: data.duration,
      txnid: state.couponDetails.CouponCode,
    };

    // console.log(dataDetails);
    startLoading();
    try {
      const response = await axios.post(url_19, dataDetails);
      if (response.data.status === 200) {
        finishLoading();
        // alert(response.data.message);
        //let returnedResponse = await updateUser();
        history.push("/user_dashboard");
        // if (returnedResponse) {
        // } else {
        //   history.push("/user_dashboard");
        // }
      } else {
        alert(response.message);
      }
      finishLoading();
    } catch (error) {
      console.log(error);
      finishLoading();
    }
  };

  const saveUserTransactions = async (data) => {
    let packageDetails = { ...state.packageDetails };
    let userPreferences = { ...state.userPrefernces };
    let dataDetails = {
      course_id: userPreferences.courseId,
      batch_id: userPreferences.batchId,
      package_id: packageDetails.package_id,
      txn_amount: packageDetails.final_amount,
      txn_discount_amount: packageDetails.discount,
      txn_amount: "1",
      txn_discount_amount: "",
      txn_date: data.response.addedon,
      package_duration: packageDetails.duration,
      txnid: data.response.txnid,
    };

    startLoading();
    try {
      const response = await axios.post(url_19, dataDetails);
      if (response.data.status === 200) {
        finishLoading();
        // alert(response.data.message);
        let returnedResponse = await updateUser();
        if (returnedResponse) {
          history.push("/user_dashboard");
        } else {
          history.push("/user_dashboard");
        }
      }
    } catch (error) {
      console.log(error);
      finishLoading();
    }
  };

  const updateUser = async () => {
    startLoading();
    try {
      const response = await axios.get(url_8);
      if (response.data.status === 200) {
        setUser(response.data.data);
        finishLoading();
        return 1;
      } else {
        return 0;
      }
    } catch (error) {
      console.log(error);
      return 0;
    }
  };

  const purchaseCheck = () => {
    let packagesCollection = { ...state.packagesCollection };
    // console.log(packagesCollection);
    let checkArr = [];
    packagesCollection.Package.forEach((element) => {
      checkArr.push(element.is_subscribe);
    });
    // console.log(checkArr);
    let dummyVar = checkArr.includes(true);
    return dummyVar;
  };

  const renderStepComponent = () => {
    let {
      step,
      packagesCollection,
      subjectList,
      packageDetails,
      facultyList,
      facultyDetail,
      freePackagesCollection,
      couponDetails,
      coupanApplied,
    } = state;

    switch (step) {
      case 1:
        return (
          <PackageOfferingComponent
            packagesCollection={packagesCollection}
            pricePurchaseBtn={pricePurchaseBtn}
            learnMoreBtn={learnMoreBtn}
            freePackagesCollection={freePackagesCollection}
            handlePackageSelect={(id, amount) => selectPackage(id, amount)}
            couponCollection={(cpnInput, applyCoinData) =>
              couponCollection(cpnInput, applyCoinData)
            }
            couponDetails={couponDetails}
            updateCoupon={updateCouponDetails}
            saveUserTransactions={activePackage}
            // availableCoins={state.coinData.current_coins}
            availableCoins={state.coinData.current_coins}
            // applyAvailableCoins={applyAvailableCoins}
            startLoading={startLoading}
            finishLoading={finishLoading}
            coupanApplied={coupanApplied}
            packageDetails={packageDetails}
            orderId={orderId}
            token={token}
            tokenType={tokenType}
            user_id={user_id}
            setPackageDetailsForPayment={setPackageDetailsForPayment}
            getUpdatedAmountFromBackend={getUpdatedAmountFromBackend}
            cpnAmt={cpnAmt}
            setCpnAmt={setCpnAmt}
            setOriginalAmt={setOriginalAmt}
            originalAmt={originalAmt}
          />
        );
      case 2:
        return (
          <PaymentInformationComponent
            handleStepNavigation={handleStepNavigation}
            packageDetails={packageDetails}
            handlePaymentInformationPayBtn={handlePaymentInformationPayBtn}
          />
        );
      case 3:
        return (
          <PackageDetailsComponent
            subjectList={subjectList}
            handleStepNavigation={handleStepNavigation}
            packageDetails={packageDetails}
            facultyList={facultyList}
            handlePaymentInformationPayBtn={handlePaymentInformationPayBtn}
            handlePackageSelect={(id, amount) => selectPackage(id, amount)}
            couponCollection={(cpnInput, applyCoinData) =>
              couponCollection(cpnInput, applyCoinData)
            }
            couponDetails={couponDetails}
            updateCoupon={updateCouponDetails}
            saveUserTransactions={activePackage}
            pricePurchaseBtn={pricePurchaseBtn}
            getFacultyDetail={getFacultyDetail}
            facultyDetail={facultyDetail}
            availableCoins={state.coinData.current_coins}
            startLoading={startLoading}
            finishLoading={finishLoading}
            coupanApplied={coupanApplied}
            userData={userData}
            setCpnAmt={setCpnAmt}
            cpnAmt={cpnAmt}
            setOriginalAmt={setOriginalAmt}
            originalAmt={originalAmt}
            getUpdatedAmountFromBackend={getUpdatedAmountFromBackend}
            orderId={orderId}
            history={history}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Fragment>
      <div className="userStoreContentComponent_wrapper">
        <div className="userStoreContentComponent_inner_wrapper">
          {/* <div className="container">
            <div className="store_firstSec">
              <div className="store_firstSec_inr">
                <div className="store_textLft">
                  <h2>
                    Welcome to the <span>Store</span>
                  </h2>
                  <p>
                    Check out our paid and free course packages from the below
                    lists
                  </p>
                </div>
                <div className="store_textRgt">
                  <div className="store_img">
                    <img src={images.m2_store} alt="store" />
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          <div className="sec_2_wrapper">
            <div className="container">
              {/* <div className="wallet_wrapper">
                <Row>
                  <Col lg={1} md={12} sm={12}></Col>
                  <Col
                    lg={6}
                    md={6}
                    sm={12}
                    className="d-flex align-items-center"
                  >
                    <div>
                      <h3 className="coinheading">Total Motion Coins :</h3>
                      <div className="d-flex gap-3 align-items-center my-3">
                        <img src={images.motionCoin} width={40} />
                        <p className="m-0 coinText">
                          {state.coinData.current_coins}
                        </p>
                      </div>
                      <p className="coinText1 mb-3">
                        Redeem Motion Coins by purchasing any Motion products.
                      </p>
                      <Link className="walletLink" to="/wallet">
                        My Motion Wallet
                      </Link>
                    </div>
                  </Col>
                  <Col lg={4} md={6} sm={12}>
                    <img className="img-fluid" src={images.motionCoinsStore} />
                  </Col>
                  <Col lg={1} md={12} sm={12}></Col>
                </Row>
              </div> */}
              {state.showBanner && (
                <div className="new_wallet_wrapper">
                  <Row>
                    <Col lg={1} md={12} sm={12}>
                      <img
                        className="wallet_image"
                        src={images.motionWalletNew}
                        alt="wallet"
                      />
                    </Col>
                    <Col className="d-flex align-items-center">
                      <div className="coinsBannerText">
                        <p>Total Motion coins :</p>
                        <img src={images.motionCoin} width={25} alt="coin" />
                        <span>{state.coinData.current_coins}</span>
                      </div>
                    </Col>
                    <Col className="d-flex justify-content-end align-items-center">
                      <Link className="coins_history" to="/wallet">
                        View Coins History
                      </Link>
                    </Col>
                  </Row>
                </div>
              )}
            </div>
          </div>
          <div className="tab_container">
            <div className="container">
              <div className="tabBox">
                <div className="tabcontent">
                  {state.showBanner && (
                    <div className="tab">
                      <ul
                        className="nav nav-tabs onlineLearning_handBook_Ul"
                        id="myTab"
                        role="tablist"
                      >
                        <li className="nav-item" role="presentation">
                          <button
                            className={
                              tab == "online_learning"
                                ? "nav-link active"
                                : "nav-link"
                            }
                            id="online_learning-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#online_learning"
                            type="button"
                            role="tab"
                            aria-controls="online_learning"
                            aria-selected="false"
                          >
                            Online Learning
                          </button>
                        </li>

                        <li className="nav-item" role="presentation">
                          <button
                            className={
                              tab == "hand_books"
                                ? "nav-link active"
                                : "nav-link"
                            }
                            id="hand_books-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#hand_books"
                            type="button"
                            role="tab"
                            aria-controls="hand_books"
                            aria-selected="false"
                          >
                            Hand Books
                          </button>
                        </li>
                      </ul>
                    </div>
                  )}
                  <div className="ol_hb_TabContent">
                    <div className="tab-content" id="myTabContent">
                      <div
                        className={
                          tab == "online_learning"
                            ? "tab-pane fade show active"
                            : "tab-pane fade"
                        }
                        id="online_learning"
                        role="tabpanel"
                        aria-labelledby="online_learning-tab"
                      >
                        <div className="course_packge">
                          <div className="container" style={{ padding: "0" }}>
                            {renderStepComponent()}
                          </div>
                        </div>
                      </div>

                      <div
                        className={
                          tab == "hand_books"
                            ? "tab-pane fade show active"
                            : "tab-pane fade"
                        }
                        id="hand_books"
                        role="tabpanel"
                        aria-labelledby="hand_books-tab"
                      >
                        <HandBooks />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    orderId: state.paymentReducer.orderId,
    token: state.auth.authToken,
    tokenType: state.auth.tokenType,
    user_id: state.auth.user.user_id,
    loginType: state.userStore.loginType,
    pageEqualToStorecourseDetails:
      state.userStore.pageEqualToStorecourseDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => {
      dispatch(uiActions.startLoading());
    },
    finishLoading: () => {
      dispatch(uiActions.finishLoading());
    },
    setUser: (payload) => {
      dispatch(authActions.setUser(payload));
    },

    setStoreCurrentPackageDetails: (payload) => {
      dispatch(setStoreCurrentPackageDetails(payload));
    },

    setRefferalCode: (payload) => {
      dispatch(setRefferalCode(payload));
    },

    setPackageDetailsForPayment: (payload) => {
      dispatch(setPackageDetailsForPayment(payload));
    },
    setLoginType: (payload) => {
      dispatch(userProductList.setLoginType(payload));
    },

    setPackageDetailsForPayment,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserStoreContentComponent);
