import { Fragment } from "react";

///////Canvas Chart//////////////////
import { CanvasJSChart } from "canvasjs-react-charts";

const BehaviourDoughnutChart = (props) => {
  let { dataPoints } = props;
  console.log("ddt", dataPoints);
  const options = {
    animationEnabled: true,
    backgroundColor: "transparent",
    height: 200,
    data: [
      {
        type: "doughnut",
        showInLegend: false,
        //indexLabel: "{name}: {y}",
        yValueFormatString: "#,###'%'",
        dataPoints: [
          {
            name: "Correct",
            y: dataPoints?.[`Correct Total Time`],
            color: "#31D680",
          },
          {
            name: "Wrong",
            y: dataPoints?.[`Incorrect Total Time`],
            color: "#F93030",
          },
          {
            name: "Unattempted",
            y: dataPoints?.[`Unattempted Total Time`],
            color: "#668DE1",
          },
        ],
      },
    ],
  };
  return (
    <Fragment>
      <div className="canvasDoughnutChart_wrapper">
        <CanvasJSChart options={options} />
      </div>
    </Fragment>
  );
};

export default BehaviourDoughnutChart;
