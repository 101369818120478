import { useTimer } from "react-timer-and-stopwatch";
import { useState, useEffect } from "react";

const useStopwatch = (
  currentQuestion,
  inputFieldVal,
  currentQuestionDetails = currentQuestion
) => {
  const [timeElapsedArr, setTimeElapsedArr] = useState([]);
  const [solutionClickedArr, setSolutionClickedArr] = useState([]);
  const [solDisabled, setSolDisabled] = useState(true);

  useEffect(() => {
    subtractTime({
      milliseconds: timeElapsed,
    });
    if (currentQuestion >= 0) {
      addTime({
        milliseconds: getTime(),
      });
    }
    if (inputFieldVal) {
      textInputSolution(inputFieldVal);
    } else {
      showSolution(currentQuestion);
    }
    resumeTimer();
  }, [currentQuestionDetails]);

  const timer = useTimer({
    create: {
      stopwatch: {},
    },
  });

  const { pauseTimer, resumeTimer, addTime, subtractTime, timeElapsed } = timer;

  const updateTime = () => {
    const index = timeElapsedArr.findIndex(
      (obj) => obj.questionNumber === currentQuestion
    );
    if (index !== -1) {
      const updatedArr = [...timeElapsedArr];
      updatedArr[index].timeElapsed = timeElapsed;
      setTimeElapsedArr(updatedArr);
    } else {
      setTimeElapsedArr([
        ...timeElapsedArr,
        {
          questionNumber: currentQuestion,
          timeElapsed: timeElapsed,
        },
      ]);
    }
  };

  // called when the solution button is clicked (updateSolution(true))
  const updateSolution = (solutionClicked = false) => {
    const index = solutionClickedArr.findIndex(
      (obj) => obj.questionNumber === currentQuestion
    );

    if (index !== -1) {
      const updatedArr = [...solutionClickedArr];
      updatedArr[index].solutionClicked = solutionClicked;
      setSolutionClickedArr(updatedArr);
    } else {
      setSolutionClickedArr((prevArr) => [
        ...prevArr,
        {
          questionNumber: currentQuestion,
          solutionClicked: solutionClicked,
        },
      ]);
    }
  };

  const showSolution = (current) => {
    const checkboxes = document.querySelectorAll(
      `input[name="current_question_${current}"]`
    );
    let isAtLeastOneChecked = false;
    for (const checkbox of checkboxes) {
      if (checkbox.checked) {
        isAtLeastOneChecked = true;
        break;
      }
    }
    // Set the solDisabled state based on the conditions and input type
    setSolDisabled((prevSolDisabled) => {
      if (isAtLeastOneChecked) {
        return false;
      } else {
        return true;
      }
    });
  };

  const textInputSolution = (inputFieldVal) => {
    setSolDisabled(() => {
      if (inputFieldVal) {
        return false;
      } else {
        return true;
      }
    });
  };

  const getTime = () => {
    const targetObject = timeElapsedArr.find(
      (obj) => obj.questionNumber === currentQuestion
    );
    let timeElapsedValue;
    if (targetObject) {
      timeElapsedValue = targetObject.timeElapsed;
    }
    return timeElapsedValue;
  };

  // called for conditional rendering and to disable answer classes
  const getClicked = () => {
    const solutionClickedObj = solutionClickedArr.find(
      (obj) => obj.questionNumber === currentQuestion
    );
    let solutionClickedValue;
    const checkboxes = document.querySelectorAll('input[type="checkbox"]');
    const textInputs = document.querySelectorAll(
      'input[placeholder="Enter your answer"]'
    );
    if (solutionClickedObj) {
      solutionClickedValue = solutionClickedObj.solutionClicked;
      pauseTimer();
      checkboxes.forEach((checkbox) => {
        checkbox.disabled = true;
      });
      textInputs.forEach((textInput) => {
        textInput.disabled = true;
      });
    } else {
      solutionClickedValue = false;
      checkboxes.forEach((checkbox) => {
        checkbox.disabled = false;
      });
      textInputs.forEach((textInput) => {
        textInput.disabled = false;
      });
    }
    return solutionClickedValue;
  };

  const removePrefix = (inputString) => {
    if (inputString.startsWith("00:")) {
      return inputString.substring(3);
    } else {
      return inputString;
    }
  };

  let stopwatchText = removePrefix(timer.timerText);

  return {
    stopwatchText,
    updateTime,
    updateSolution,
    getClicked,
    solDisabled,
    showSolution,
    textInputSolution,
    subtractTime,
    timeElapsed,
    addTime,
    getTime,
    resumeTimer,
  };
};

export default useStopwatch;
