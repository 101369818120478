import * as actionTypes from "./action_types";

const setExamCrackerTestsData = (payload) => {
  return {
    type: actionTypes.SET_EXAM_CRACKER_TESTS_DATA,
    payload,
  };
};

// const setUserTestsResultData = (payload) => {
//   return {
//     type: actionTypes.SET_USER_TESTS_RESULT_DATA,
//     payload,
//   };
// };


export default {
    setExamCrackerTestsData,
//   setUserTestsResultData,
};
