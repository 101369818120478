import React from "react";
import { Modal } from "react-bootstrap";
import images from "../../utilities/images/images";
import "./FreeWatchVideosModal.scss";

const FreeWatchVideosModal = ({ show, onHide, handleWatchNow }) => {
  return (
    <Modal show={show} onHide={onHide} dialogClassName="freewatch">
      <Modal.Header closeButton></Modal.Header>

      <Modal.Body>
        <div className="free-user-container">
          <img src={images.watchFreeVideos} alt="free-videos-icon" />
          <div className="free-header">Watch Videos Freely!</div>
          <div className="free-sub-header">
            Access up to 2 days of video content completely for free.{" "}
          </div>
          <div className="watchNowButton" onClick={handleWatchNow}>
            <button>Watch Now</button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default FreeWatchVideosModal;
