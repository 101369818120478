// SubjectSelection.js
import React from "react";
import "./SubjectSelection.scss";
import { isEmpty } from "../../../../../../../../custom_utilities/custom_useful_functions";

const SubjectSelection = ({
  topicSubjectList,
  currentSubject,
  handleChangeSubject,
}) => {
  return (
    <div className="AdaptiveCps_subject_collection_wrapper">
      {!isEmpty(topicSubjectList) &&
        topicSubjectList.map((element, index) => {
          const isChecked = topicSubjectList.length === 1;

          return (
            <div key={index} className="AdaptiveCps_single_select_subject">
              <input
                type="radio"
                id={`subject_${element.subject}`}
                name="subject"
                value={element.subject} // Use the subject as the value
                checked={currentSubject === element.subject}
                onChange={handleChangeSubject}
              />
              <label htmlFor={`subject_${element.subject}`}>
                <div
                  className={`subject_icon ${element.subject.toLowerCase()}`}
                >
                  <img src={element.icon} alt="icon" />
                </div>
                {element.subject}
              </label>
            </div>
          );
        })}
    </div>
  );
};

export default SubjectSelection;
