import { Fragment } from "react";
import "./user_profile_content_component.scss";

import images from "../../../../utilities/images/images";
import { isEmpty } from "../../../../custom_utilities/custom_useful_functions";

const UserProfileContentComponent = (props) => {
  let { userData, history } = props;

  const handleRedirect = () => {
    history.push("/user_edit_profile");
  };

  return (
    <Fragment>
      <div className="userProfileContentComponent_wrapper">
        <div className="userProfileContentComponent_inner_wrapper">
          <div className="sec_1_wrapper">
            <div className="sec_1_inner_wrapper">
              <div className="content_wrapper">
                <div className="left_side_content_wrapper">
                  <div className="image_background">
                    <div className="image_wrapper">
                      {!isEmpty(userData) ? (
                        userData.image.length ? (
                          <img src={userData.image} />
                        ) : (
                          <img src={images.signUp_1} />
                        )
                      ) : (
                        <img src={images.signUp_1} />
                      )}
                    </div>
                  </div>
                </div>
                <div className="right_side_content_wrapper">
                  <div className="content_wrapper">
                    <div className="text_content_wrapper_1">
                      <p className="text_content_1">Personal Information</p>
                    </div>
                    <div className="btn_wrapper">
                      <button onClick={() => handleRedirect()}>
                        <img src={images.user_profile_1} /> Edit
                      </button>
                    </div>
                  </div>
                  <div className="content_wrapper">
                    <div className="text_content_wrapper_2">
                      <p className="text_content_2">Name</p>
                    </div>
                    <div className="text_content_wrapper_3">
                      <p className="text_content_3">
                        {!isEmpty(userData) ? userData.name : null}
                      </p>
                    </div>
                  </div>
                  <div className="content_wrapper">
                    <div className="text_content_wrapper_2">
                      <p className="text_content_2">Contact no.</p>
                    </div>
                    <div className="text_content_wrapper_3">
                      <p className="text_content_3">
                        {!isEmpty(userData) ? userData.mobile : null}
                      </p>
                    </div>
                  </div>
                  <div className="content_wrapper">
                    <div className="text_content_wrapper_2">
                      <p className="text_content_2">DOB</p>
                    </div>
                    <div className="text_content_wrapper_3">
                      <p className="text_content_3">
                        {!isEmpty(userData) ? userData.dob : null}
                      </p>
                    </div>
                  </div>
                  <div className="content_wrapper">
                    <div className="text_content_wrapper_2">
                      <p className="text_content_2">Roll No</p>
                    </div>
                    <div className="text_content_wrapper_3">
                      <p className="text_content_3">
                        {!isEmpty(userData) ? userData.roll_no : null}
                      </p>
                    </div>
                  </div>
                  <div className="content_wrapper">
                    <div className="text_content_wrapper_2">
                      <p className="text_content_2">Email id</p>
                    </div>
                    <div className="text_content_wrapper_3">
                      <p className="text_content_3">
                        {!isEmpty(userData) ? userData.email : null}
                      </p>
                    </div>
                  </div>
                  <div className="content_wrapper">
                    <div className="text_content_wrapper_2">
                      <p className="text_content_2">Gender</p>
                    </div>
                    <div className="text_content_wrapper_3">
                      <p className="text_content_3">
                        {!isEmpty(userData) ? userData.gender : null}
                      </p>
                    </div>
                  </div>
                  <div className="content_wrapper">
                    <div className="text_content_wrapper_2">
                      <p className="text_content_2">State</p>
                    </div>
                    <div className="text_content_wrapper_3">
                      <p className="text_content_3">
                        {!isEmpty(userData) ? userData.address.state : null}
                      </p>
                    </div>
                  </div>
                  <div className="content_wrapper">
                    <div className="text_content_wrapper_2">
                      <p className="text_content_2">City</p>
                    </div>
                    <div className="text_content_wrapper_3">
                      <p className="text_content_3">
                        {!isEmpty(userData) ? userData.address.city : null}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="sec_2_wrapper">
            <div className="sec_2_inner_wrapper">
              <div className="text_content_wrapper">
                <p className="text_content">Package details</p>
              </div>
              <div className="package_collection_wrapper">
                <div className="single_package_wrapper">
                  <div className="left_side_content_wrapper">
                    <div className="image_wrapper">
                      <img src={images.user_profile_3} />
                    </div>
                  </div>
                  <div className="right_side_content_wrapper">
                    <div className="text_content_wrapper_1">
                      <p className="text_content_1">
                        IIT-JEE Advance crash course
                      </p>
                    </div>
                    <div className="text_content_wrapper_2">
                      <p className="text_content_2">Expiry at 12 Dec, 2020</p>
                    </div>
                    <div className="text_content_wrapper_3">
                      <p className="text_content_3">
                        <label>Gold</label>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </Fragment>
  );
};

export default UserProfileContentComponent;
