import React from "react";
import { useHistory } from "react-router";
import QuestionBankCreatePaper from "./QuestionBankCreatePaper/QuestionBankCreatePaper";
import QuestionBankCustomPapers from "./QuestionBankCustomPapers/QuestionBankCustomPapers";
import HeaderComponent from "../structure_components/desktop_screens/header_component/header_component";
import "./QuestionBank.scss";

import LoaderPopUp from "../../../components/loader/loader";
import { connect } from "react-redux";
import Footer_component from "../structure_components/desktop_screens/footer_component/Footer_component";

const QuestionBank = (props) => {
  let { loader } = props;

  const history = useHistory();
  return (
    <div>
      <HeaderComponent history={history} />
      <div className="question_bank_Wrapper">
        <div className="question_bank_InrWrapper">
          <div className="container">
            <div className="question_bank_contentWpr">
              <QuestionBankCreatePaper/>
              <QuestionBankCustomPapers history={history} />
            </div>
          </div>
        </div>
        <Footer_component/>
      </div>
      {loader && <LoaderPopUp />}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loader: state.ui.loader,
  };
};

export default connect(mapStateToProps)(QuestionBank);
