
import * as actionTypes from "./action_type";

const initialState = {
  bitSatTestData: {},
  bitSatResultData: {},
};

const bitSatTestsReducer = (state = initialState, { type, payload = null }) => {
  switch (type) {
    case actionTypes.SET_BIT_SAT_TESTS_DATA:
      return setBitSatTestsData(state, payload);

    case actionTypes.SET_BIT_SAT_TESTS_RESULT_DATA:
      return setBitSatTestsResultData(state, payload);
    default:
      return state;
  }
};

const setBitSatTestsData = (state, payload) => {
  state = { ...state, bitSatTestData: payload };
  return state;
};

const setBitSatTestsResultData = (state, payload) => {
  state = { ...state, bitSatResultData: payload };
  return state;
};

export default bitSatTestsReducer;
