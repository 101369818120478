import React from "react";
import "./YourOrderComponent.scss";
import { isEmpty } from "../../../../../../../custom_utilities/custom_useful_functions";

const YourOrderComponent = (props) => {
  let { cartDetails, singleProduct, couponDetails } = props;

  return (
    <div>
      {singleProduct ? (
        <div className="your_order">
          <div className="your_order_Content">
            <div className="header">
              <div className="text">Your Order</div>
              <div className="total_items">
                Total <span>(1 item)</span>
              </div>
            </div>

            <div className="itemsBox">
              <div className="item">
                <div className="item_left">
                  <img src={singleProduct.image} alt="item_img" />
                  <div className="item_content">
                    <div className="subName">{singleProduct.product_name}</div>
                    <small>Solution book</small>
                  </div>
                </div>
                <div className="item_right">
                  {parseInt(singleProduct.price).toLocaleString("en-US")}
                </div>
              </div>
            </div>

            <div className="delivery_discount">
              {/* <div className="deliveryBox">
                <div className="deliveryName">Delivery</div>
                <div className="deliveryCost">60(Fast)</div>
              </div> */}

              <div className="discountBox">
                <div className="dicountName">Discount</div>
                <div className="discountPrice">
                  {couponDetails?.coupon_discount_price ? (
                    <div>
                      -
                      {parseInt(
                        couponDetails.coupon_discount_price
                      ).toLocaleString("en-US")}
                    </div>
                  ) : (
                    <div>
                      -
                      {parseInt(
                        singleProduct.price - singleProduct.discounted_price
                      ).toLocaleString("en-US")}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="totalPriceContent">
              <div className="totalPriceText">Total Price</div>
              <div className="totalPrice">
                {couponDetails?.updated_amount ? (
                  <div>
                    Rs{" "}
                    {parseInt(couponDetails.updated_amount).toLocaleString(
                      "en-US"
                    )}
                  </div>
                ) : (
                  <div>
                    Rs{" "}
                    {parseInt(singleProduct.discounted_price).toLocaleString(
                      "en-US"
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="your_order">
          <div className="your_order_Content">
            <div className="header">
              <div className="text">Your Order</div>
              <div className="total_items">
                Total <span>({cartDetails.data.length} items)</span>
              </div>
            </div>
            <div className="itemsBox">
              {!isEmpty(cartDetails)
                ? cartDetails.data.length
                  ? cartDetails.data.map((element, index) => {
                      return (
                        <div key={index} className="item">
                          <div className="item_left">
                            <img src={element.image} alt="item_img" />
                            <div className="item_content">
                              <div className="subName">
                                {element.product_name}
                              </div>
                              <small>Solution book</small>
                            </div>
                          </div>
                          <div className="item_right">
                            {element.product_price.toLocaleString("en-US")}
                          </div>
                        </div>
                      );
                    })
                  : null
                : null}
            </div>

            <div className="delivery_discount">
              {/* <div className="deliveryBox">
                <div className="deliveryName">Delivery</div>
                <div className="deliveryCost">60(Fast)</div>
              </div> */}

              <div className="discountBox">
                <div className="dicountName">Discount</div>
                <div className="discountPrice">
                  -{cartDetails.discount_price.toLocaleString("en-US")}
                </div>
              </div>

              {couponDetails?.coupon_discount_price ? (
                <div className="discountBox">
                  <div className="dicountName">Discount Price</div>
                  <div className="discountPrice">
                    {couponDetails?.coupon_discount_price ? (
                      <div>
                        -
                        {couponDetails.coupon_discount_price.toLocaleString(
                          "en-US"
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>

            <div className="totalPriceContent">
              <div className="totalPriceText">Total Price</div>
              <div className="totalPrice">
                {couponDetails?.updated_amount ? (
                  <div>
                    ₹ {couponDetails.updated_amount.toLocaleString("en-US")}
                  </div>
                ) : (
                  <div>
                    {" "}
                    ₹ {cartDetails.total_price.toLocaleString("en-US")}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default YourOrderComponent;
