import { createSlice } from "@reduxjs/toolkit";

const BlockUserSlice = createSlice({
  name: "blocked",
  initialState: {
    isBlocked: false,
    remark: null,
  },
  reducers: {
    setBlockedStatus: (state, action) => {
      state.isBlocked = action.payload.isBlocked;
      state.remark = action.payload.remark;
    },
  },
});

export const { setBlockedStatus } = BlockUserSlice.actions;
export default BlockUserSlice.reducer;
