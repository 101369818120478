export const isEmpty = (value) => {
  return (
    value === undefined ||
    value === null ||
    (typeof value === "object" && Object.keys(value).length === 0) ||
    (typeof value === "string" && value.trim().length === 0)
  );
};

export const sliceString = (str, startIndex, endIndex) => {
  return str.slice(startIndex, endIndex);
};

export const splitString = (str, direction) => {
  let splittedString = str.split("-");
  if (direction === "start") {
    return splittedString[0];
  } else if (direction === "end") {
    return splittedString[splittedString.length - 1];
  }
};

export const generateRandomId = () => {
  const min = 10;
  const max = 100;
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const checkXSSAttackThroughInput = (value) => {
  const convertedToSmallCharacters = value.trim().toLowerCase();

  const XSSAttacksList = [
    "<",
    ">",
    "script",
    "alert",
    "img",
    "onerror",
    "onload",
    "javascript",
    "document",
    "eval",
    "src",
    "onmouseove",
    "onmouseent",
    "onmouselea",
    "onfocus",
    "onblur",
    "onclick",
    "ondblclick",
    "onchange",
    "onsubmit",
    "onreset",
    "html",
  ];

  for (let keyword of XSSAttacksList) {
    if (convertedToSmallCharacters.includes(keyword)) {
      alert(`You are not allowed to use "${keyword}" keyword`);
      return false; // Return false to indicate XSS attack
    }
  }

  return true; // Return true if no XSS attack detected
};

export const withRupeeSymbol = (value) => {
  if (Number.isInteger(value)) {
    return `₹${Math.round(value)}`;
  }
  return value === undefined || value === null ? "-" : `₹${value.toFixed(2)}`;
};
