import React, { useState, useEffect } from "react";
import "./TopicWiseAnalysisContentComponent.scss";
import axios from "axios";
import { isEmpty } from "../../../../../custom_utilities/custom_useful_functions";
import LoaderPopUp from "../../../../../components/loader/loader";

const TopicWiseAnalysisContentComponent = (props) => {
  const {
    parentUserId,
    paperId,
    plannerTestId,
    startLoading,
    finishLoading,
    loader,
  } = props;

  const [state, setState] = useState({
    subjectList: [],
    topicWiseData: [],
    curntSubject: null,
  });

  const { topicWiseData, subjectList, curntSubject } = state;

  useEffect(() => {
    getSubjectList();
  }, []);

  useEffect(() => {
    if (curntSubject) {
      getTopicWiseData();
    }
  }, [curntSubject]);

  const getSubjectList = async () => {
    startLoading();

    try {
      const response = await axios.post(
        "https://learning.motion.ac.in/motioneducation/api/StudentTestAnalysicsReport",
        {
          user_id: parentUserId,
          paper_id: paperId,
        }
      );

      if (response.data.status === 200) {
        setState((prevState) => ({
          ...prevState,
          subjectList: response.data.subjects,
          curntSubject: response.data.subjects[0]?.subject || null,
        }));
      }

      finishLoading();
    } catch (error) {
      console.log(error);
      finishLoading();
    }
  };

  const getTopicWiseData = async () => {
    startLoading();

    try {
      const response = await axios.post(
        "https://learning.motion.ac.in/motioneducation/api/gettopicwisedata",
        {
          subject: curntSubject,
          user_id: parentUserId,
          paper_id: paperId,
          planner_test_id: plannerTestId,
        }
      );

      if (response.data.status === 200) {
        setState((prevState) => ({
          ...prevState,
          topicWiseData: response.data.data.topic_wise,
        }));
      }

      finishLoading();
    } catch (error) {
      console.log(error);
      finishLoading();
    }
  };

  const handleChangeSubjects = (event) => {
    const { value } = event.target;
    setState((prevState) => ({
      ...prevState,
      curntSubject: value,
    }));
  };

  return (
    <div className="topicWiseAnalysisContentComponentWrapper">
      <div className="subjects_tab_wrapper">
        <div className="outer_content_wrapper">
          <div className="content_wrapper">
            <div className="left_side_content_wrapper">
              <div className="subject_type_collection_wrapper">
                {!isEmpty(subjectList) &&
                  subjectList.map((element, index) => (
                    <div
                      key={`${element.subject}s`}
                      className="single_select_subject_type"
                    >
                      <input
                        type="radio"
                        id={`${element.subject}s`}
                        name="subject_type"
                        value={element.subject}
                        checked={element.subject === curntSubject}
                        onChange={handleChangeSubjects}
                      />
                      <label htmlFor={`${element.subject}s`}>
                        {element.subject}
                      </label>
                    </div>
                  ))}
              </div>
            </div>

            <div className="right_side_content_wrapper">
              <section>
                <table id="customers">
                  <thead>
                    <tr>
                      <th>Topic</th>
                      <th>Total Questions</th>
                      <th>Correct</th>
                      <th>Wrong</th>
                      <th>Unattempted</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!isEmpty(topicWiseData) &&
                      topicWiseData.map((element, index) => (
                        <tr key={index}>
                          <td>{element.topic_name}</td>
                          <td className="num">{element.topicquestions}</td>
                          {/* unAttempted = Total questions - (Correct+Wrong) */}
                          <td className="num">{element.is_correct}</td>
                          <td className="num">{element.is_wrong}</td>
                          <td className="num">
                            {element.topicquestions -
                              (element.is_correct + element.is_wrong)}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </section>
            </div>
          </div>
          {loader && <LoaderPopUp />}
        </div>
      </div>
    </div>
  );
};

export default TopicWiseAnalysisContentComponent;
