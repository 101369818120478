import React, { useState } from "react";
import images from "../../../../../../../utilities/images/images";
import "./AdaptiveCpsCreatePaper.scss";

const AdaptiveCpsCreatePaper = ({ handleStep }) => {
  return (
    <div className="adaptiveCpsCreatePaperWrppr">
      <div className="adaptiveCpsCreatePaperInrWrppr">
        <div className="adaptiveCpsCreatePaperContent">
          <div className="heading">Adaptive CPS</div>
          <div className="imgWrppr">
            <img
              className="img"
              src={images.user_activity_65}
              alt="wrapperImg"
            />
          </div>
          <p className="adaptiveCpsCreatePaperContentPara">
            Generate <strong>personalized Adaptive CPS</strong> based on your
            performance so far and master your week topics.
          </p>
          <div className="create_new_button" onClick={() => handleStep(2)}>
            <p className="text_content">
              <img
                className="add_btn"
                src={images.user_activity_33}
                alt="add_btn"
              />
              Generate Adaptive CPS Now
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdaptiveCpsCreatePaper;
