import { Fragment } from "react";
import "./mobile_right_sideBar_component.scss";

import images from "../../../../../utilities/images/images";
import { NavLink, Link } from "react-router-dom";
import { mobileRightSideBarClose } from "../../../../../custom_utilities/sideBar_functions";
import { isEmpty } from "../../../../../custom_utilities/custom_useful_functions";

//Redux
import { connect } from "react-redux";
import uiActions from "../../../../../redux/ui/action";
import authActions from "../../../../../redux/auth/action";
import userPreferenceActions from "../../../../../redux/user_preference/action";
import userActivityActions from "../../../../../redux/user_activity/action";
import userTestsExercisesAction from "../../../../../redux/user_tests_exercises/action";
import userTwilioDetailsAction from "../../../../../redux/twilio_chat/action";
import userBookmarkAction from "../../../../../redux/user_bookmark/action";

const MobileRightSideBarComponent = (props) => {
  let {
    history,
    userData,
    logoutUser,
    removeAnimation,
    removePreferenceData,
    removeActivityData,
    removeTestsExercisesData,
    removeTwilioDetails,
    removeBookmarkData,
  } = props;

  const handleUserLogout = () => {
    logoutUser();
    removeAnimation();
    removePreferenceData();
    removeActivityData();
    removeTestsExercisesData();
    removeTwilioDetails();
    removeBookmarkData();
    history.push("/");
  };

  return (
    <Fragment>
      <div
        id="mobile_right_sideBar_wrapper"
        className="mobile_right_sideBar_wrapper"
        onClick={() => mobileRightSideBarClose()}
      >
        <div
          id="mobile_right_sideBar_inner_wrapper"
          className="mobile_right_sideBar_inner_wrapper"
        >
          <div className="profile_option_wrapper">
            <div className="profile_option_inner_wrapper">
              <div className="content_wrapper_1">
                <div className="image_container">
                  {!isEmpty(userData) ? (
                    userData.image.length ? (
                      <img src={userData.image} />
                    ) : (
                      <img src={images.dummy_user_img} />
                    )
                  ) : (
                    <img src={images.dummy_user_img} />
                  )}
                </div>
                <div className="text_content_wrapper_1">
                  <p className="text_content_1">
                    {!isEmpty(userData) ? userData.name : "User"}{" "}
                  </p>
                  <p className="text_content_2">
                    {!isEmpty(userData) ? userData.mobile : "9876543210"}
                  </p>
                  <p className="text_content_3">
                    {userData.is_subscribe === 1 ? (
                      <Fragment>
                        <img src={images.user_activity_12} /> Premium
                      </Fragment>
                    ) : null}
                  </p>
                </div>
              </div>
              <div className="content_wrapper_2">
                <div className="text_content_wrapper_1">
                  <NavLink to="/user_edit_profile">
                    <p className="text_content_1">
                      <img src={images.header_5} /> Profile
                    </p>
                  </NavLink>
                  <NavLink to="/notifications">
                    <p className="text_content_1">
                      <img className="ntf" src={images.notification} /> Notifications
                    </p>
                  </NavLink>
                  <NavLink to="/wallet">
                    <p className="text_content_1">
                      <img className="ntf" src={images.motionCoin} /> My Wallet
                    </p>
                  </NavLink>
                  <p
                    className="text_content_1"
                    onClick={() => handleUserLogout()}
                  >
                    <img src={images.header_2} /> Logout
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.auth.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logoutUser: () => {
      dispatch(authActions.logoutUser());
    },
    removeAnimation: () => {
      dispatch(uiActions.removeAnimation());
    },
    removeActivityData: () => {
      dispatch(userActivityActions.removeActivityData());
    },
    removePreferenceData: () => {
      dispatch(userPreferenceActions.removePreferenceData());
    },
    removeTestsExercisesData: () => {
      dispatch(userTestsExercisesAction.removeTestsExercisesData());
    },
    removeTwilioDetails: () => {
      dispatch(userTwilioDetailsAction.removeTwilioDetails());
    },
    removeBookmarkData: () => {
      dispatch(userBookmarkAction.removeBookmarkData());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MobileRightSideBarComponent);
