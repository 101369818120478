import * as actionTypes from "./action_types";

const initialState = {
  storeCartDetailsData: {},
  productList: [],
  wishList: [],
  pageEqualToStorecourseDetails: {
    active: 1,
    advantages: null,
    allow_installment: "Yes",
    amount: "2199.00",
    batch: [],
    batch_id: null,
    bg_image: "/storage/package/course_banner/381.png",
    coin_discount_amt: "1099.00",
    course: "JEE 12th English",
    course_benifits:
      "Text and video solutions,Topic-wise tests,100+ Practice papers",
    course_id: 13,
    created_on: "2023-12-16 13:39:06",
    description: `Features:
    90+ Past year papers,
    34 Subtopic wise Minor test,
    9 Major test,
    17 Full Syllabus test,
    5 BITSAT test,
    3 WBJEE test,
    2 IAT test,
    One-stop solution for all engineering exams,
    Video and text solutions of each test,
    Detailed report and analysis after every test,
    Created and crafted by expert Kota faculties as per latest updated syllabus,
    Validity till JEE Advanced 2025,
    Use 50% motion coins for an extra discount.`,
    discount: "0.00",
    duration: "12 months",
    expiry_date: "2025-07-05",
    final_amount: "2199",
    icon:
      "https://learning.motion.ac.in/motioneducation//storage/package/app_icon.png",
    is_amirt: 0,
    is_delete: 0,
    is_referral: null,
    is_refundable: null,
    is_subscribe: false,
    package_id: 381,
    package_include_module: [
      { module: "Live Classess" },
      { module: "Video" },
      { module: "Zoom Classess" },
    ],
    package_name: "Motion Targeted Abhyas (MAT) - Phase 1.0",
    package_sequence: null,
    package_tag: "2",
    package_thumbnail: "/storage/package/thumbnail/381.png",
    package_type: 3,
    tags: null,
    txn_expire_date: "",
    u_programId: null,
    video_url: null,
    web_thumbnail: "/storage/package/web_thumbnail/381.png",
  },
  loginType: "",
};

console.log("logintTTTTttttttttttttttypeeeee", initialState.loginType);

const storeReducer = (state = initialState, { type, payload = null }) => {
  switch (type) {
    case actionTypes.SET_STORE_CART_DETAILS_DATA:
      return setStoreCartDetailsData(state, payload);
    case actionTypes.SET_PRODUCT_LIST:
      return setProductList(state, payload);
    case actionTypes.SET_WISH_LIST:
      return setWishList(state, payload);
    case actionTypes.SET_LOGIN_TYPE:
      return setLoginType(state, payload);
    default:
      return state;
  }
};

const setStoreCartDetailsData = (state, payload) => {
  state = { ...state, storeCartDetailsData: payload };
  return state;
};

const setProductList = (state, payload) => {
  state = { ...state, productList: payload };
  return state;
};

const setWishList = (state, payload) => {
  state = { ...state, wishList: payload };
  return state;
};

const setLoginType = (state, payload) => {
  state = { ...state, loginType: payload };
  return state;
};

export default storeReducer;
