import { Fragment, useEffect, useState } from "react";
import "./subject_collection_view.scss";

import images from "../../../../../../utilities/images/images";
import axios from "axios";
import { isEmpty } from "../../../../../../custom_utilities/custom_useful_functions";
import { BASE_URL } from "../../../../../../custom_utilities/baseURL";
import { url_9 } from "../../../../../../custom_utilities/api_services";
//slick-carousel
import Slider from "react-slick";
//Redux
import { connect } from "react-redux";
import userActivityActions from "../../../../../../redux/user_activity/action";

const SubjectCollectionView = (props) => {
  let { history, userPreference, setSubjectData } = props;
  let { courseDetails, classDetails } = userPreference;

  const [state, setState] = useState({
    subjectList: {},
  });

  useEffect(() => {
    getSubjectList();
  }, []);

  const getSubjectList = async () => {
    let data = {
      course_id: courseDetails[0].course_id,
      class_id: classDetails.class_id,
    };
    // startLoading();
    try {
      const response = await axios.post(url_9, data);
      // const response = await axios.post(
      //   `${BASE_URL}/api/user/UserSubject`,
      //   data
      // );
      if (response.data.status === 200) {
        // finishLoading();
        setState((prevState) => ({
          ...prevState,
          subjectList: response.data.data,
        }));
      }
    } catch (error) {
      // finishLoading();
      console.log(error);
    }
  };

  const renderSubjectImage = (icon) => {
    if (icon) {
      return <img src={icon} />;
    } else {
      return <img src={images.user_dashboard_5} />;
    }
  };

  const handleRedirect = (type, data) => {
    switch (type.toLowerCase()) {
      case "all":
        history.push("/subject_component");
        break;
      case "subject":
        setSubjectData({
          subjectId: data.sub_id,
          subjectName: data.sub_name,
          subjectIcon: data.icon,
        });
        history.push("/subject_component");
        break;
      default:
        break;
    }
  };
const settings = {
  dots: false,
  infinite: false,
  autoplay: false,
  autoplaySpeed: 3000,
  arrows: true,
  slidesToShow: 4,
  slidesToScroll: 1,
  responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          // dots: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          autoplay: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          arrows: false,
        },
      },
    ],
};
  return (
    <Fragment>
      <div className="subjectCollectionView_wrapper">
        <div className="subjectCollectionView_inner_wrapper">
          <div className="container">
            <div className="subject_collection">
            <div className="subject_collection_desktop">
              <Slider {...settings}>
                {!isEmpty(state.subjectList)
                  ? state.subjectList.subject.length
                    ? state.subjectList.subject.map((element, index) => {
                      return (
                        <div className="single_subject_box" key={element.sub_id}>
                          <div
                            key={index}
                            className="single_subject"
                            onClick={() => handleRedirect("subject", element)}
                          >
                            <div className="image_wrapper">
                              {renderSubjectImage(element.icon)}
                              {/* <img src={element.icon} /> */}
                            </div>
                            <div className="text_content_wrapper">
                              <p className="text_content">{element.sub_name}</p>
                            </div>
                            <div className="arrow">
                              <img src={images.right_Ar} alt="arrow" />
                            </div>
                          </div>
                        </div>
                      );
                      })
                    : null
                  : null}
              </Slider>
              </div>
              <div className="subject_collection_mobile">
              {!isEmpty(state.subjectList)
                  ? state.subjectList.subject.length
                    ? state.subjectList.subject.map((element, index) => {
                      return (
                        <div className="single_subject_box" key={element.sub_id}>
                          <div
                            key={index}
                            className="single_subject"
                            onClick={() => handleRedirect("subject", element)}
                          >
                            <div className="image_wrapper">
                              {renderSubjectImage(element.icon)}
                              {/* <img src={element.icon} /> */}
                            </div>
                            <div className="text_content_wrapper">
                              <p className="text_content">{element.sub_name}</p>
                            </div>
                            {/* <div className="arrow">
                              <img src={images.right_Ar} alt="arrow" />
                            </div> */}
                          </div>
                        </div>
                      );
                      })
                    : null
                  : null}
              </div>
            </div>
            {/* <div className="viewAllTrigger_wrapper">
              <div className="btn_wrapper">
                <button
                  type="button"
                  onClick={() => handleRedirect("all", null)}
                >
                  View All
                </button>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    userPreference: state.userPreference,
  };
};

const mapDipatchToProps = (dispatch) => {
  return {
    setSubjectData: (payload) => {
      dispatch(userActivityActions.setSubjectData(payload));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDipatchToProps
)(SubjectCollectionView);
