import { Fragment } from "react";
import "./AdaptiveCpsTestInstructionView.scss";
import images from "../../../../../../../../../utilities/images/images";
import { isEmpty } from "../../../../../../../../../custom_utilities/custom_useful_functions";

import { Link } from "react-router-dom";

const AdaptiveCpsTestInstructionView = (props) => {
  let { startBtn, testInstructionData, attemptCount } = props;

  return (
    <Fragment>
      <div className="test_intructions_view_wrapper ">
        <div className="container">
          <div className="test_intructions_view_inner_wrapper">
            <div className="instructionScreenBox">
              <div className="screenHeader">
                <Link
                  className="backBtn"
                  to={{
                    pathname: "/create-adaptive-cps",
                    state: { tab: "adaptive_cps" },
                  }}
                >
                  <img src={images.m2_backBtn} alt="backBtn" />
                </Link>

                <div className="Instruction_title">Instruction Screen</div>
              </div>

              <div className="instruction_detailBox">
                <div className="topBox">
                  <div className="left">
                    <div className="subject">
                      Subject: <span>{testInstructionData.subject}</span>
                    </div>

                    <div className="weakTopics">
                      <p>Weak Topics:</p>
                      <ul>
                        {testInstructionData &&
                        testInstructionData.data &&
                        testInstructionData.data.length > 0 ? (
                          testInstructionData.data.map((topic, index) => (
                            <li key={index}>{topic}</li>
                          ))
                        ) : (
                          <li>No weak topics available</li>
                        )}
                      </ul>
                    </div>
                  </div>

                  <div className="right">
                    <div className="QuestionsNum">
                      No.of Questions:{" "}
                      <strong>{testInstructionData.total_question}</strong>
                    </div>

                    {!attemptCount === 0 && (
                      <div className="attemptsLeft">
                        Attempts Left: <strong>{attemptCount}</strong>
                      </div>
                    )}

                    
                  </div>
                </div>

                <div className="instructions">
                  <h3>Set of Instructions</h3>
                  <div className="instPoint">
                    <div className="img"></div>
                    <p>{testInstructionData.Instructions}</p>
                  </div>
                </div>

                <div className="startTestBtn">
                  <button onClick={() => startBtn()}>Start Test</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AdaptiveCpsTestInstructionView;
