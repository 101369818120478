import React from "react";

const QuestionSolution = ({ currentSolutionDetails, lang }) => {
  const ConvertStringToHTML = (str) => {
    if (!str) {
      return false;
    }
    const parser = new DOMParser();
    const doc = parser.parseFromString(str, "text/html");

    return doc.body.children.length > 0;
  };

  const renderSolution = (currentSolutionDetails, lang) => {
    if (lang === true) {
      return {
        __html: currentSolutionDetails?.sol_text.replaceAll(
          'src="ckfinder',
          'src="http://quizmaster.motion.ac.in/ckfinder'
        ),
      };
    } else if (lang === false) {
      const flag = ConvertStringToHTML(currentSolutionDetails.sol_text_hindi);

      if (flag) {
        return {
          __html: currentSolutionDetails?.sol_text_hindi.replaceAll(
            'src="ckfinder',
            'src="http://quizmaster.motion.ac.in/ckfinder'
          ),
        };
      } else {
        return {
          __html: currentSolutionDetails?.sol_text.replaceAll(
            'src="ckfinder',
            'src="http://quizmaster.motion.ac.in/ckfinder'
          ),
        };
      }
    } else {
      return {
        __html: currentSolutionDetails?.q_text.replaceAll(
          'src="ckfinder',
          'src="http://quizmaster.motion.ac.in/ckfinder'
        ),
      };
    }
  };

  return (
    <div className="QuestionSolution">
      <h6>Solution :</h6>
      <div>
        <p
          dangerouslySetInnerHTML={renderSolution(currentSolutionDetails, lang)}
        ></p>
      </div>
      <p></p>
    </div>
  );
};

export default QuestionSolution;
