import React from "react";
import "./QuestionInfoSection.scss";
import images from "../../../../../../../../../utilities/images/images";
// import { MathJax, MathJaxContext } from "better-react-mathjax";

const QuestionInfoSection = ({
  currentQuestionDetails,
  lang,
  ConvertStringToHTML,
  testQuestionsDetails,
  currentQuestion,
  setLang,
}) => {
  const config = {
    //loader: { load: ["[tex]/html"] },
    loader: {
      load: [
        "input/asciimath",
        "output/chtml",
        "ui/menu",
        "[mml]/mml3",
        "[tex]/html",
      ],
    },
  };

  const renderSingleQuestion = () => {
    const { q_text, q_text_hindi } = currentQuestionDetails || {};
    const isImage = q_text && q_text.includes("<img");
    const isTable = q_text && q_text.includes("<table");
    const isMTable = q_text && q_text.includes("<mtable");
    let imageSrc = "";

    if (isImage) {
      const imgTagRegex = /<img[^>]+src="?([^"\s]+)"?\s*\/>/g;
      const match = imgTagRegex.exec(q_text);
      if (match && match[1]) {
        imageSrc = match[1];
      }
    }

    if (lang) {
      return (
        <div>
          {q_text && (
            <p
              className={`${isImage ? "image-style" : ""} ${
                isTable ? "table-style" : ""
              } ${isMTable ? "mtable-style" : ""}`}
              dangerouslySetInnerHTML={{
                __html: q_text,
              }}
            ></p>
          )}

          {isTable && <table className="table-style"></table>}
          {isMTable && <mtable className="mtable-style"></mtable>}
        </div>
      );
    } else {
      if (ConvertStringToHTML(q_text_hindi)) {
        return (
          <div>
            {q_text_hindi && (
              <p
                className={`${isImage ? "image-style" : ""} ${
                  isTable ? "table-style" : ""
                } ${isMTable ? "mtable-style" : ""}`}
                dangerouslySetInnerHTML={{
                  __html: q_text_hindi,
                }}
              ></p>
            )}

            {isTable && <table className="table-style"></table>}
            {isMTable && <mtable className="mtable-style"></mtable>}
          </div>
        );
      } else {
        return (
          <div>
            {q_text && (
              <p
                className={`${isImage ? "image-style" : ""} ${
                  isTable ? "table-style" : ""
                } ${isMTable ? "mtable-style" : ""}`}
                dangerouslySetInnerHTML={{
                  __html: q_text,
                }}
              ></p>
            )}
            {isTable && <table className="table-style"></table>}
            {isMTable && <mtable className="mtable-style"></mtable>}
          </div>
        );
      }
    }
  };

  return (
    <div className="quesitonInfoSection">
      <h2>Practice Test</h2>
      <div className="text_content_wrapper">
        <div className="typeLanguageWrapper">
          <span>
            Question type:
            {testQuestionsDetails[currentQuestion]?.answer_type}
          </span>
          <div className="markReview">
            {testQuestionsDetails[currentQuestion] &&
            testQuestionsDetails[currentQuestion].q_text_hindi ? (
              <button onClick={() => setLang(!lang)}>
                <img src={images.m2_language} alt="" />
              </button>
            ) : (
              ""
            )}
          </div>
        </div>

        <div className="questionBox">
          <span className="text_content">
            {`${Number(currentQuestion) + 1}.`}
          </span>
          {renderSingleQuestion()}
        </div>
      </div>
    </div>
  );
};

export default QuestionInfoSection;
