// import { compose, createStore, applyMiddleware } from "redux";

// ///////////////
// import { persistReducer, persistStore } from "redux-persist";
// import storage from "redux-persist/lib/storage";
// /////////////
// import thunk from "redux-thunk";
// ///////////////
// import rootReducer from "../reducers";

// const persistConfig = {
//   key: "motion",
//   storage,
//   blacklist: ["ui"], // ui will not be persisted
// };

// const persistedReducer = persistReducer(persistConfig, rootReducer);

// const middlewares = [thunk];

// export const store = createStore(
//   persistedReducer,
//   compose(
//     applyMiddleware(...middlewares)
//     // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__() // Comment this line for build
//   )
// );

// export const persistor = persistStore(store);

import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import rootReducer from "../reducers";

const persistConfig = {
  key: "motion",
  storage,
  blacklist: ["ui"], // ui will not be persisted
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [...getDefaultMiddleware()],
  devTools: process.env.NODE_ENV !== "production", // Redux DevTools can be enabled this way
});

export const persistor = persistStore(store);
