


import EmojiPicker from 'emoji-picker-react';

function Emoji({openEmoji,selectedEmoji,setSelectedEmoji,setOpenEmoji,inputRef}) {

  return (
    <> 
      
        <EmojiPicker
      open={openEmoji}
        onEmojiClick={(emoji) => { 
          setSelectedEmoji((prevText) => prevText + emoji.emoji);
          setOpenEmoji(!openEmoji);
          inputRef.current.focus();
        }
        }
      emojiSize={32}
      perLine={10}
      style={{ width: "340px"}}
      
      />
      {selectedEmoji && <span style={{display:"flex",justifyContent:"center"
  }}>{selectedEmoji}</span>}
      
    
      </>
  );
}



export default Emoji;
