import { Fragment } from "react";
import images from "../../../../../../../utilities/images/images";
import { Link } from "react-router-dom";





const MockTestPracticeInstructionView = (props) => {
let { testDetails, startBtn } = props;
  return (
    <Fragment>
      <div className="mock_test_ppr_test_intructions_view_wrapper">
        <div className="container">
          <div className="test_intructions_view_inner_wrapper">
            <div className="instructionScreenBox">
              <Link
                className="backBtn"
                to={{
                  pathname: "/mock_test",
                  state: { tab: "mock_test" },
                }}
              >
                <img src={images.m2_backBtn} alt="backBtn" />
              </Link>
              <div className="instruction_title">{testDetails.paper_name}</div>
              <div className="instruction_detailBox">
                <div className="examTime">
                  <div className="examTitle">{testDetails.format}</div>
                </div>
                <div className="instructions">
                  <h3>Set of Instructions</h3>
                  <div className="instPoint">
                    <div className="img">
                      <img
                        src={`https://quizmaster.motion.ac.in${testDetails.instructions_image}`}
                        alt="instructions_image"
                      />
                    </div>
                    <p>This Section contains a set of questions for practice</p>
                    <p>
                      Users can go to previous question by clicking on the
                      previous button
                    </p>
                    <p>
                      Users can go to next question by clicking on the next
                      button
                    </p>
                    <h6>Best of luck</h6>
                  </div>
                </div>
                <div className="startTestBtn">
                  <button onClick={() => startBtn()}>Start Test</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default MockTestPracticeInstructionView;
