import { React } from "react";
import "./OtsTestSummaryPage.scss";

import images from "../../../../../../../utilities/images/images";
//
import ProgressBar from "react-bootstrap/ProgressBar";
import "react-circular-progressbar/dist/styles.css";
import LoaderPopUp from "../../../../../../../components/loader/loader";
import { useHistory } from "react-router-dom";
import { isEmpty } from "../../../../../../../custom_utilities/custom_useful_functions";

const OtsTestSummaryPage = (props) => {
  let { testPaperResultData, currentPaper } = props;

  console.log("testPaperResultData in OtsTestSummaryPage", testPaperResultData);

  let history = useHistory();

  const redirect = (step) => {
    if (Object.keys(testPaperResultData).length) {
      switch (step) {
        case 1:
          history.push({
            pathname: "/ots_subject_topic_wise",
          });
          break;

        case 2:
          history.push({
            pathname: "/ots_accuracy_attempted",
          });
          break;

        case 3:
          history.push({
            pathname: "/ots_difficultylevel",
          });
          break;

        case 4:
          history.push({
            pathname: "/ots_behaviour",
          });
          break;

        case 5:
          history.push({
            pathname: "/ots_question_paper_solution",
          });
          break;

        default:
          break;
      }
    } else {
      alert("Analytics or Solutions will be shown after Some Time");
    }
  };

  return (
    <>
      <div className="otsTestSummaryPageInrWrapper">
        <div className="test_summaryBox">
          <div className="rankMarksBox">
            <div className="rankMarksDiv">
              <div className="rankChartBox">
                <div className="expctd_AIR_Chart">
                  <div className="rankCard">
                    <div className="expectedRankImg">
                      <img src={images.user_activity_23} />
                    </div>

                    <div className="content">
                      <div class="text">Expected Rank</div>
                      <div class="num">
                        {testPaperResultData?.Expected_Rank || null}
                      </div>
                    </div>
                  </div>

                  <div className="rankCard">
                    <div class="AIRImg">
                      <img src={images.user_activity_57} />
                    </div>
                    <div className="content">
                      <div class="text">All India Rank</div>
                      <div class="num">{testPaperResultData?.AIR || null}</div>
                    </div>
                  </div>

                  <div className="rankCard">
                    <div class="RankImg">
                      <img src={images.user_activity_58} />
                    </div>
                    <div className="content">
                      <div class="text">Marks</div>
                      <div class="num">
                        {testPaperResultData?.Marks || null}
                      </div>
                    </div>
                  </div>

                  {/* </div> */}
                </div>

                <div className="AttemptAndAccuracyPercent">
                  <div className="progress-container">
                    <div className="accurateBar">
                      <div className="text">Attempt (in %)</div>

                      <ProgressBar
                        now={testPaperResultData?.Attempt || 0}
                        className="accuracy"
                      />
                      <span>{`${testPaperResultData?.Attempt || 0}`}</span>
                    </div>
                    <div className="accurateBar">
                      <div className="text">Accuracy (in %)</div>
                      <ProgressBar
                        now={testPaperResultData?.Accuracy || 0}
                        className="attempt"
                      />
                      <span>{`${testPaperResultData?.Accuracy || 0}`}</span>
                    </div>
                  </div>
                </div>
              </div>

              {currentPaper?.paper !== "P1+P2" ? (
                <div className="testTypeOption">
                  <div className="testResultOption" onClick={() => redirect(1)}>
                    <span>Subject & Topic Wise Marks</span>
                    <span className="arrow">
                      <img src={images.right_Ar} alt="arrow" />
                    </span>
                  </div>
                  <div className="testResultOption" onClick={() => redirect(2)}>
                    <span>Attempt and Accuracy</span>
                    <span className="arrow">
                      <img src={images.right_Ar} alt="arrow" />
                    </span>
                  </div>
                  <div className="testResultOption" onClick={() => redirect(3)}>
                    <span>Difficulty Levels</span>
                    <span className="arrow">
                      <img src={images.right_Ar} alt="arrow" />
                    </span>
                  </div>
                  <div className="testResultOption" onClick={() => redirect(4)}>
                    <span>Behavior</span>
                    <span className="arrow">
                      <img src={images.right_Ar} alt="arrow" />
                    </span>
                  </div>
                  <div className="testResultOption" onClick={() => redirect(5)}>
                    <span>Question Paper Sol.</span>
                    <span className="arrow">
                      <img src={images.right_Ar} alt="arrow" />
                    </span>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      {/* {loader && <LoaderPopUp />} */}
    </>
  );
};

export default OtsTestSummaryPage;
