// CustomPaperBox.js
import React from "react";
import "./CustomPaperBox.scss";

const CustomPaperBox = ({ qBank, handleViewSolution, handleTakeTest }) => {

  return (
    <div className="questionBankCustomPaperBox" key={qBank.question_bank_id}>
      <div className="questionBankCustomPaperName">
        <div className="paperName">{qBank.subject}</div>
        <div className="created_date">{qBank.created_on}</div>
      </div>
      <div className="questions_data_count">
        <ul>
          <li className="qb_unattempt">
            <span>{qBank.unattempt}</span> Unattempted |
          </li>{" "}
          <li className="qb_wrong">
            <span> {qBank.incorrect} </span>Wrong |
          </li>{" "}
          <li className="qb_correct">
            <span> {qBank.correct} </span>Correct
          </li>{" "}
        </ul>
      </div>

      <div className="no_of_quests_startBtn">
        <div className="no_of_questions">
          {/* {qBank.no_of_question}{" "}
          <span className="question_text">Questions</span> */}
        </div>
        <div className="startBtn">
          <div
            onClick={
              qBank.is_submit === 0
                ? () => handleViewSolution(qBank.acps_id)
                : () => handleTakeTest(qBank.subject)                                                                                                              
            }
            className="view_sol_btn"
          >                       
            {qBank.is_submit === 0 ? "View Solution" : "Take Test"}
          </div>{" "}
          <span>{">"}</span>
        </div>
      </div>
    </div>
  );
};

export default CustomPaperBox;
