import React from "react";
import "./HowItWorks.scss";

const HowItWorks = ({ works, referAndEarnData }) => {
  return (
    <div className="howItWorksContainer">
      <img
        src={referAndEarnData.webbanner_image2}
        alt="refer-and-earn-img"
        style={{
          width: "51.75rem",
          height: "26.3125rem",
          objectFit: "cover",
        }}
      />
    </div>
  );
};

export default HowItWorks;
