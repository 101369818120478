import { Fragment } from "react";
import "./pyq_test_component.scss";

import LoaderPopUp from "../../../../../components/loader/loader";

////////
import HeaderComponentTwo from "../../../structure_components/desktop_screens/header_component/header_top/Header_top";
import TestContentComponent from "./test_content_component/test_content_component";

//Redux
import { connect } from "react-redux";

const TestComponent = (props) => {
  let { history, loader ,userData} = props;
  return (
    <Fragment>
      <div className="test_component_wrapper">
        <div className="test_component_inner_wrapper">
          <Fragment>
              <TestContentComponent userData={userData} history={history} />
            </Fragment>
        </div>
      </div>
      {loader && <LoaderPopUp />}
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    loader: state.ui.loader,
    userData: state.auth.user,
  };
};

export default connect(mapStateToProps)(TestComponent);
