import { Fragment, useState, useEffect } from "react";
import images from "../../../../../../../utilities/images/images";
import { isEmpty } from "../../../../../../../custom_utilities/custom_useful_functions";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { url_121 } from "../../../../../../../custom_utilities/api_services";
import axios from "axios";
import { connect } from "react-redux";
import "./OtsTestInstructionsView.scss";
import RegistrationModal from "./modals/RegistrationModal";
import otsActions from "../../../../../../../redux/online-test_series/action";

import uiActions from "../../../../../../../redux/ui/action";

const TestInstructionsView = (props) => {
  const history = useHistory();
  let {
    startBtn,
    startLoading,
    finishLoading,
    otsTestsData,
    target_id,
    setOtsPremiumData,
    setOtsFreeData,
  } = props;
  console.log("otstests data in test instruction view", otsTestsData);

  const [instructionData, setInstructionData] = useState({
    instructionPara: "",
  });

  useEffect(() => {
    getInstructionsData();
    // handleShowRegistration();
    if (!isEmpty(otsTestsData)) {
      if (otsTestsData.is_show) {
        handleShowRegistration();
      }
    }
  }, []);

  const [showRegistration, setShowRegistration] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [formValues, setFormValues] = useState({
    rollNo: "",
    registrationNo: "",
    dob: "",
  });

  const isDisable =
    formValues.rollNo.length &&
    formValues.registrationNo.length &&
    formValues.dob.length
      ? false
      : true;

  const handleCloseRegistration = () => {
    if (isFormSubmitted) {
      setShowRegistration(false);
    }
  };

  const handleShowRegistration = () => setShowRegistration(true);

  const getInstructionsData = () => {
    startLoading();

    const data = {
      test_id: otsTestsData?.test_id,
      planner_id: otsTestsData?.planner_id,
    };
    try {
      axios.post(url_121, data).then((response) => {
        setInstructionData((prevState) => ({
          ...prevState,
          instructionPara: response.data.data.instructions,
        }));

        if (response.status === 200) {
          finishLoading();
        }
      });
    } catch (error) {
      finishLoading();
    }
  };

  const StripHtml = (htmlString) => {
    let tmp = document.createElement("p");
    tmp.innerHTML = htmlString;

    return tmp.textContent || tmp.innerText || "";
  };

  function testTime(minutes) {
    var h = Math.floor(minutes / 60);
    var m = minutes % 60;
    h = h < 10 ? "0" + h : h;
    m = m < 10 ? "0" + m : m;
    return h + ":" + m + " hrs";
  }

  function handleclearData() {
    setOtsPremiumData([]);
    setOtsFreeData([]);
  }

  return (
    <Fragment>
      <div className="Ots_ppr_test_intructions_view_wrapper">
        <div className="OtsContainer">
          <div className="ots_test_intructions_view_inner_wrapper">
            <div className="OtsInstructionScreenBox">
              <Link
                className="backBtn"
                onClick={handleclearData}
                to={{
                  pathname: "/ots_tests",
                }}
              >
                <img src={images.m2_backBtn} alt="backBtn" />
              </Link>

              <div className="ots_instruction_title">Instruction Screen</div>
              <div className="ots_instruction_detailBox">
                <div className="OtsExamTime">
                  <div className="OtsExamTitle">{otsTestsData.test_name}</div>
                  <div className="OtsExamDurations">
                    Duration :
                    {!isEmpty(otsTestsData)
                      ? `${testTime(otsTestsData.test_duration_min)}`
                      : null}
                  </div>
                </div>
                <div className="OtsInstructions">
                  <h3>Set of Instructions</h3>
                  <div className="instPoint">
                    <p>
                      {!isEmpty(instructionData)
                        ? !isEmpty(instructionData.instructionPara)
                          ? StripHtml(
                              instructionData.instructionPara
                                .test_instruction_eng
                            )
                          : null
                        : null}
                    </p>
                  </div>
                </div>
                <div className="OtsStartTestBtn">
                  <button onClick={() => startBtn()}>
                    {otsTestsData.is_submited == 0 ||
                    otsTestsData.is_submited == 3
                      ? "Resume Test"
                      : "Start Test"}{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showRegistration && (
        <RegistrationModal
          formValues={formValues}
          setFormValues={setFormValues}
          setIsFormSubmitted={setIsFormSubmitted}
          handleCloseRegistration={handleCloseRegistration}
          showRegistration={showRegistration}
          setShowRegistration={setShowRegistration}
          isDisable={isDisable}
          target={target_id}
        />
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    otsTestsData: state.otsTestsData.otsTestsData,
    target_id: state.userPreference.targetDetails[0].target_id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => {
      dispatch(uiActions.startLoading());
    },
    finishLoading: () => {
      dispatch(uiActions.finishLoading());
    },

    setOtsPremiumData: (payload) => {
      dispatch(otsActions.setOtsPremiumData(payload));
    },

    setOtsFreeData: (payload) => {
      dispatch(otsActions.setOtsFreeData(payload));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TestInstructionsView);
