import * as actionTypes from "./action_types";

const setStoreCartDetailsData = (payload) => {
  return {
    type: actionTypes.SET_STORE_CART_DETAILS_DATA,
    payload,
  };
};

const setProductList = (payload) => {
  return {
    type: actionTypes.SET_PRODUCT_LIST,
    payload,
  };
};

const setWishList = (payload) => {
  return {
    type: actionTypes.SET_WISH_LIST,
    payload,
  };
};
const setLoginType = (payload) => {
  return {
    type: actionTypes.SET_LOGIN_TYPE,
    payload,
  };
};

export default {
  setStoreCartDetailsData,
  setProductList,
  setWishList,
  setLoginType
};
