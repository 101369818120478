import { useEffect, useState } from "react";
import "./SubjectWiseDoughnut.scss";
import { isEmpty } from "../../../custom_utilities/custom_useful_functions";

///////Canvas Chart//////////////////
import { CanvasJSChart } from "canvasjs-react-charts";

const SubjectWiseDoughnut = (props) => {
  let { dataPoints } = props;
  const [subjectType, setSubjectType] = useState(dataPoints[0].type);
  const [array, setArray] = useState([]);

  useEffect(() => {
    filterTypeWise();
  }, [subjectType]);

  const filterTypeWise = () => {
    dataPoints?.filter((element) => {
      if (element.type === subjectType) {
        setArray([element]);
      }
    });
    return;
  };

  const options = {
    animationEnabled: true,
    backgroundColor: "#f5f4f8",

    subtitles: [
      {
        verticalAlign: "center",
        fontSize: 24,
        dockInsidePlotArea: true,
      },
    ],
    data: [
      {
        type: "doughnut",
        radius: "90%",
        innerRadius: "82%",
        indexLabel: "{name}: {y}",
        yValueFormatString: "#,###'%'",
        dataPoints: [
          {
            name: "Biology",
            y: array[0]?.Biology_percentage,
            color: "#6B8E23",
            indexLabelBackgroundColor: "#6B8E23",
          },
          {
            name: "Chemistry",
            y: array[0]?.Chemistry_percentage,
            color: "#31D680",
            indexLabelBackgroundColor: "#31D680",
          },
          {
            name: "Maths",
            y: array[0]?.Maths_percentage,
            color: "#668DE1",
            indexLabelBackgroundColor: "#668DE1",
          },
          {
            name: "Physics",
            y: array[0]?.Physics_percentage,
            color: "#FF933A",
            indexLabelBackgroundColor: "#FF933A",
          },
        ],
      },
    ],
  };

  const handleChange = (event) => {
    setSubjectType(event.target.value);
  };

  return (
    <div className="sec_1_inner_wrapper">
      <div className="heading-text">Subject Wise</div> <hr></hr>
      <div className="type_collection_wrapper">
        {!isEmpty(dataPoints)
          ? dataPoints.length
            ? dataPoints.map((element, index) => {
                return (
                  <div key={index} className="single_select_type">
                    <input
                      type="radio"
                      id={element.type}
                      // name="individual_test_subject"
                      value={element.type}
                      checked={element.type === subjectType}
                      onChange={handleChange}
                    />
                    <label htmlFor={element.type}>{element.type}</label>
                  </div>
                );
              })
            : null
          : null}
      </div>
      <CanvasJSChart
        // style={{ backgroundColor: "#ffffff",width: "10px",}}
        options={options}
      />
    </div>
  );
};

export default SubjectWiseDoughnut;
