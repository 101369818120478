import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  parentUser: {},
  studentProfileDetails: {},
  userId: "",
  mobile_number: "",
  // isParentAuthenticated: false,
  user: {},
  redirectDestination: "/",
  loginFromParent: false,
  parentTarget: "",
};

const parentAuthSlice = createSlice({
  name: "parentAuth",
  initialState,
  reducers: {
    inputParentContactNumber: (state, action) => {
      state.mobile_number = action.payload;
    },
    setParentUser: (state, action) => {
      state.user = action.payload;
    },
    // setParentAuth: (state, action) => {
    //   state.isParentAuthenticated = action.payload;
    // },
    setParentRedirectUrl: (state, action) => {
      state.redirectDestination = action.payload.path;
    },
    logoutParentUser: (state) => {
      // localStorage.removeItem("persist:motion");
      state.mobile_number = "";
      // state.authToken = "";
      // state.tokenType = "";
      state.user = {};
      state.redirectDestination = "/";
      state.studentProfileDetails = {};
      state.userId = "";

      return state; // Return the updated state
    },
    setUserId: (state, action) => {
      state.userId = action.payload;
    },
    setStudentProfileDetails: (state, action) => {
      state.studentProfileDetails = action.payload;
    },
    setLoginFromParentFlag: (state, action) => {
      state.loginFromParent = action.payload;
    },
    setParentTarget: (state, action) => {
      state.parentTarget = action.payload;
    },
  },
});
export const {
  inputParentContactNumber,
  setParentUser,
  setParentAuth,
  setParentRedirectUrl,
  logoutParentUser,
  setUserId,
  setStudentProfileDetails,
  setLoginFromParentFlag,
  setParentTarget,
} = parentAuthSlice.actions;

export default parentAuthSlice.reducer;
