import React from "react";
import images from "../../../../../../../../utilities/images/images";
import QuestionStatusLabel from "./questionStatusLabel/QuestionStatusLabel";
import "./QuestionListPanel.scss";

const QuestionListPanel = ({
  handleSaveQuestion,
  handleShow,
  questionsCollectionRef,
  testQuestionsDetails,
  currentQuestion,
  handleQuestionJump,
  handlePurticularQuestion2,
  lang,
  ConvertStringToHTML,
  toggleMenu,
  setToggleMenu
}) => {
  return (
    <div className={`allQuestionList ${
      toggleMenu ? "allQuestionListToggle" : ""
    }`}>
      <div className="sub_section_1_wrapper">
      <button
                      type="button"
                      class="btn-close closebtn"
                      aria-label="Close"
                      onClick={() => {
                        setToggleMenu(false);
                      }}
                    ></button>
        <div className="text_content_wrapper">
          <p className="text_content">All Questions</p>
          <button
            onClick={() => {
              handleSaveQuestion();
              handleShow();
            }}
          >
            Submit Test
          </button>
        </div>
        <div className="sub_section_2_wrapper">
          <div className="question_marking_wrapper">
            <div className="single_marking">
              <label className="box_pic box_pick_background_1"></label>
              <label className="question_marking_text">Completed</label>
            </div>
            <div className="single_marking">
              <label className="box_pic box_pick_background_3"></label>
              <label className="question_marking_text">Pending</label>
            </div>
          </div>
        </div>
        <div
          className="questions_serial_number_collection"
          ref={questionsCollectionRef}
        >
          {testQuestionsDetails.length
            ? testQuestionsDetails.map((element, index) => {
                return (
                  <div
                    key={index}
                    className="single_question_number_wrapper"
                    id="myDiv"
                  >
                    <QuestionStatusLabel
                      question={element}
                      index={index}
                      currentQuestion={currentQuestion}
                      handleQuestionJump={handleQuestionJump}
                      handlePurticularQuestion2={handlePurticularQuestion2}
                      ConvertStringToHTML={ConvertStringToHTML}
                      lang={lang}
                      toggleMenu={toggleMenu}
                      setToggleMenu={setToggleMenu}
                    />
                  </div>
                );
              })
            : null}
        </div>
      </div>
    </div>
  );
};

export default QuestionListPanel;
