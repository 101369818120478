import React, { useState, useEffect } from "react";
import "./AddedToCart.scss";
import images from "../../../../../../../utilities/images/images";
import YourOrderComponent from "../yourOrderComponent/YourOrderComponent";
import { Link } from "react-router-dom";
import { isEmpty } from "../../../../../../../custom_utilities/custom_useful_functions";
import {
  url_163,
  url_161,
  url_170,
} from "../../../../../../../custom_utilities/api_services";
import { connect } from "react-redux";
import axios from "axios";
import uiActions from "../../../../../../../redux/ui/action";
import LoaderPopUp from "../../../../../../../components/loader/loader";

const AddedToCart = (props) => {
  let {
    handleCheckOut,
    cartDetails,
    startLoading,
    finishLoading,
    loader,
    getCartDetails,
    getWishList,
    isProgressdisabled,
    token,
    tokenType,
  } = props;

  const [state, setState] = useState({
    deleteItem: {},
  });

  const deleteItem = async (p_id) => {
    startLoading();
    const headers = {
      Authorization: `${tokenType} ${token}`,
    };
    let data = {
      product_id: p_id,
    };
    try {
      const response = await axios.post(url_163, data, { headers });
      if (response.data.status === 200) {
        finishLoading();
        setState((prevState) => ({
          ...prevState,
          deleteItem: response.data.data,
        }));
      }
      getCartDetails();
    } catch (error) {
      finishLoading();
      console.log(error);
    }
  };

  const handleDeleteItem = (p_id) => {
    deleteItem(p_id);
  };

  const addWishlist = async (p_id) => {
    startLoading();
    const headers = {
      Authorization: `${tokenType} ${token}`,
    };
    let data = {
      product_id: p_id,
      type: 1,
    };
    try {
      const response = await axios.post(url_161, data, { headers });
      if (response.data.status === 200) {
        finishLoading();
      }
      getWishList();
      handleDeleteItem(p_id);
    } catch (error) {
      finishLoading();
      console.log(error);
    }
  };

  const userUpdateCart = async (p_id, qnty) => {
    startLoading();
    let data = {
      product_id: p_id,
      qty: qnty,
    };
    try {
      const response = await axios.post(url_170, data);
      if (response.data.status === 200) {
        finishLoading();
      }
      getCartDetails();
    } catch (error) {
      finishLoading();
      console.log(error);
    }
  };

  const handleIncrement = (p_id, qty) => {
    let qnty = qty + 1;
    userUpdateCart(p_id, qnty);
  };

  const handleDecrement = (p_id, qty) => {
    let qnty = qty - 1;
    if (qnty > 0) {
      userUpdateCart(p_id, qnty);
    }
  };

  return (
    <div>
      <div className="backBtn_content">
        <Link
          className="backBtn"
          to={{
            pathname: "/store",
            state: { tab: "hand_books", cartDetails: { cartDetails } },
          }}
        >
          <img src={images.m2_backBtn} alt="backBtn" />
        </Link>

        <div className="backBtn_text">Back to store</div>
      </div>

      <section className="added_to_cart_container">
        <div className="left">
          {!isEmpty(cartDetails)
            ? cartDetails.data.length
              ? cartDetails.data.map((element, index) => {
                  return (
                    <div key={index} class="card mb-3">
                      <div className="row g-0">
                        <div className="col-md-3 card_left">
                          <img src={element.image} alt="item_img" />
                        </div>
                        <div className="col-md-9 col-right">
                          <div className="card-body">
                            <h5 className="card-title">
                              {element.product_name}
                            </h5>
                            <p className="card-text">{element.product_url}</p>
                            <div className="card_text_actions">
                              <div className="actions">
                                <button
                                  className="delete action"
                                  onClick={() => handleDeleteItem(element.p_id)}
                                >
                                  Delete
                                </button>
                                |
                                <div
                                  className="saveforlater action"
                                  onClick={() => addWishlist(element.p_id)}
                                >
                                  Save for later
                                </div>{" "}
                                |
                                <div className="qnty action">
                                  Qty{" "}
                                  <div className="btns">
                                    <button
                                      onClick={() =>
                                        handleDecrement(
                                          element.p_id,
                                          element.qty
                                        )
                                      }
                                    >
                                      <img
                                        src={images.user_activity_43}
                                        alt="minus"
                                      />
                                    </button>
                                    <div className="qntyNo">{element.qty}</div>
                                    <button
                                      onClick={() =>
                                        handleIncrement(
                                          element.p_id,
                                          element.qty
                                        )
                                      }
                                    >
                                      <img
                                        src={images.user_activity_42}
                                        alt="plus"
                                      />
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="price">
                              <span className="price_text">Price</span>
                              <span className="cost">
                                {parseInt(element.price).toLocaleString(
                                  "en-US"
                                )}
                                /-
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              : null
            : null}

          {loader && <LoaderPopUp />}
        </div>

        <div className="right">
          <YourOrderComponent cartDetails={cartDetails} />
          <div className="checkOutBtn">
            <button disabled={isProgressdisabled} onClick={handleCheckOut}>
              Proceed to check out
            </button>
          </div>
        </div>
      </section>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loader: state.ui.loader,
    token: state.auth.authToken,
    tokenType: state.auth.tokenType,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => {
      dispatch(uiActions.startLoading());
    },
    finishLoading: () => {
      dispatch(uiActions.finishLoading());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddedToCart);
