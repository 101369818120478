import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loginCandidate: "student",
};

const LoginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    setLoginCandidate: (state, action) => {
      state.loginCandidate = action.payload;
    },
  },
});

export const { setLoginCandidate } =
  LoginSlice.actions;

export default LoginSlice.reducer;
