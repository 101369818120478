import { React, useState, useEffect } from "react";
import "./PyqCpsExamContentComponent.scss";
//

//
import Previous_question_paper from "./previous_question_paper/Previous_question_paper";
import Customized_practise_sheet from "./customized_practice_sheet/Customized_practise_sheet";
import User_Exercise_Test from "../../../user_exercise_test/user_exercise_test";

import User_ots_component from "../../../user_exercise_test/user_ots_test_series/ots_tests/OtsTestComponent";
import images from "../../../../../utilities/images/images";

import ExamCracker from "../../../user_exercise_test/user_exam_cracker/exam_cracker";
import Mock_test from "../../../user_exercise_test/user_mock_test/mock_test";
import BitSat from "../../../user_exercise_test/user_bit_sat/bit_sat";
// Redux
import { connect } from "react-redux";
import uiActions from "../../../../../redux/ui/action";
import AdaptiveCps from "../../../user_exercise_test/user_adaptive_cps/AdaptiveCps";
import { useHistory } from "react-router-dom";
import CommonBannerComponent from "./CommonBannerComponent";
const PyqCpsExamComponent = (props) => {
  let { history, userPreference, startLoading, finishLoading } = props;

  const navigate = useHistory();

  //let { courseDetails, classDetails, batchDetails } = userPreference;
  //location
  const {
    state: { tab },
  } = props.location;

  // const [tab, settab] = useState(tab ? tab : "exam");
  const handleTabClick = (tabId) => {
    // settab(tabId);
  };

  return (
    <>
      <div className="pyqCpsExam_wrapper">
        <div className="pyqCpsExam_InrWrapper">
          <div className="container">
            <div className="pyqCPsExam_contentWpr">
              <div className="pyqCpsExamTabBox">
                {/* <div className="pyqCpsExamTab">
                
                  <ul
                    class="nav nav-tabs pyqCpsExamUl"
                    id="myTab"
                    role="tablist"
                  >
                    <li class="nav-item" role="presentation">
                      <button
                        class={tab == "pyq" ? "nav-link active" : "nav-link"}
                        id="previous-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#previous"
                        type="button"
                        role="tab"
                        aria-controls="previous"
                        aria-selected="true"
                        onClick={() => handleTabClick("pyq")}
                      >
                        Previous Year Questions Paper
                      </button>
                    </li>
                    <li class="nav-item" role="presentation">
                      <button
                        class={tab == "cps" ? "nav-link active" : "nav-link"}
                        id="cstmPractise-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#cstmPractise"
                        type="button"
                        role="tab"
                        aria-controls="cstmPractise"
                        aria-selected="false"
                        onClick={() => handleTabClick("cps")}
                      >
                        {" "}
                        Conceptual Problem Sheet
                      </button>
                    </li>
                    <li class="nav-item" role="presentation">
                      <button
                        class={tab == "exam" ? "nav-link active" : "nav-link"}
                        id="exam-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#exam"
                        type="button"
                        role="tab"
                        aria-controls="exam"
                        aria-selected="false"
                        onClick={() => handleTabClick("exam")}
                      >
                        Exams
                      </button>
                    </li>
                    <li class="nav-item" role="presentation">
                      <button
                        class={tab == "ots" ? "nav-link active" : "nav-link"}
                        id="ots-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#ots"
                        type="button"
                        role="tab"
                        aria-controls="ots"
                        aria-selected="false"
                        onClick={() => handleTabClick("ots")}
                      >
                        All India Test Series (AITS)
                      </button>
                    </li>

                    {(userPreference?.targetDetails[0].target_id === 1 ||
                      userPreference?.targetDetails[0].target_id === 2 ||
                      userPreference?.targetDetails[0].target_id === 3) && (
                      <li class="nav-item" role="presentation">
                        <button
                          class={
                            tab == "exam_cracker"
                              ? "nav-link active"
                              : "nav-link"
                          }
                          id="exam_cracker-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#exam_cracker"
                          type="button"
                          role="tab"
                          aria-controls="exam_cracker"
                          aria-selected="false"
                          onClick={() => handleTabClick("exam_cracker")}
                        >
                          Exam Cracker
                        </button>
                      </li>
                    )}

                    <li class="nav-item" role="presentation">
                      <button
                        class={
                          tab == "bit_sat" ? "nav-link active" : "nav-link"
                        }
                        id="bit_sat-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#bit_sat"
                        type="button"
                        role="tab"
                        aria-controls="bit_sat"
                        aria-selected="false"
                        onClick={() => handleTabClick("bit_sat")}
                      >
                        BITSAT
                      </button>
                    </li>
                    <li class="nav-item" role="presentation">
                      <button
                        class={
                          tab == "mock_test" ? "nav-link active" : "nav-link"
                        }
                        id="mock_test-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#mock_test"
                        type="button"
                        role="tab"
                        aria-controls="mock_test"
                        aria-selected="false"
                        onClick={() => handleTabClick("mock_test")}
                      >
                        Mock tests
                      </button>
                    </li>

                    {(userPreference?.targetDetails[0].target_id === 1 ||
                      userPreference?.targetDetails[0].target_id === 2 ||
                      userPreference?.targetDetails[0].target_id === 3) && (
                      <li class="nav-item" role="presentation">
                        <button
                          class={
                            tab === "adaptive_cps"
                              ? "nav-link active"
                              : "nav-link"
                          }
                          id="adaptive_cps-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#adaptive_cps"
                          type="button"
                          role="tab"
                          aria-controls="adaptive_cps"
                          aria-selected="false"
                          onClick={() => handleTabClick("adaptive_cps")}
                        >
                          Adaptive CPS
                        </button>
                      </li>
                    )}
                  </ul>
                </div> */}
                {/* <button className="mt-3 ms-4 mb-0" onClick={navigate.goBack}>
                  <img src={images.m2_backBtn} alt="backBtn"  />
                  </button> */}
                <div className="heading">
                  {tab == "pyq" && "Previous Year Questions Paper"}
                  {tab == "cps" && "Conceptual Problem Sheet"}
                  {tab == "exam" && "Exams"}
                  {tab == "ots" && "Test Series"}
                  {tab == "exam_cracker" && "Exam Cracker"}
                  {tab == "bit_sat" && "BITSAT"}
                  {tab == "mock_test" && "Mock tests"}
                </div>

                <div className="pyqCpsExamTabContent">
                  <div class="tab-content" id="myTabContent">
                    {tab === "pyq" && (
                      <div
                        class={
                          tab == "pyq"
                            ? "tab-pane fade show active"
                            : "tab-pane fade"
                        }
                        id="previous"
                        role="tabpanel"
                        aria-labelledby="previous-tab"
                      >
                        <Previous_question_paper />
                      </div>
                    )}

                    {tab === "cps" && (
                      <div
                        class={
                          tab == "cps"
                            ? "tab-pane fade show active"
                            : "tab-pane fade"
                        }
                        id="cstmPractise"
                        role="tabpanel"
                        aria-labelledby="cstmPractise-tab"
                      >
                        <CommonBannerComponent tab={tab} />
                        <Customized_practise_sheet />
                      </div>
                    )}

                    {tab === "exam" && (
                      <div
                        class={
                          tab == "exam"
                            ? "tab-pane fade show active"
                            : "tab-pane fade"
                        }
                        id="exam"
                        role="tabpanel"
                        aria-labelledby="exam-tab"
                      >
                        <User_Exercise_Test history={history} />
                      </div>
                    )}

                    {tab === "ots" && (
                      <div
                        class={
                          tab == "ots"
                            ? "tab-pane fade show active"
                            : "tab-pane fade"
                        }
                        id="ots"
                        role="tabpanel"
                        aria-labelledby="ots-tab"
                      >
                        <User_ots_component history={history} />
                      </div>
                    )}

                    {tab === "exam_cracker" && (
                      <div
                        class={
                          tab == "exam_cracker"
                            ? "tab-pane fade show active"
                            : "tab-pane fade"
                        }
                        id="exam_cracker"
                        role="tabpanel"
                        aria-labelledby="exam_cracker-tab"
                      >
                        <ExamCracker />
                      </div>
                    )}

                    {tab === "bit_sat" && (
                      <div
                        class={
                          tab == "bit_sat"
                            ? "tab-pane fade show active"
                            : "tab-pane fade"
                        }
                        id="bit_sat"
                        role="tabpanel"
                        aria-labelledby="bit_sat-tab"
                      >
                        <BitSat />
                      </div>
                    )}

                    {tab === "mock_test" && (
                      <div
                        class={
                          tab == "mock_test"
                            ? "tab-pane fade show active"
                            : "tab-pane fade"
                        }
                        id="mock_test"
                        role="tabpanel"
                        aria-labelledby="mock_test-tab"
                      >
                        <Mock_test />
                      </div>
                    )}

                    {tab === "adaptive_cps" && (
                      <div
                        class={
                          tab == "adaptive_cps"
                            ? "tab-pane fade show active"
                            : "tab-pane fade"
                        }
                        id="adaptive_cps"
                        role="tabpanel"
                        aria-labelledby="adaptive_cps-tab"
                      >
                        <AdaptiveCps />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => {
      dispatch(uiActions.startLoading());
    },
    finishLoading: () => {
      dispatch(uiActions.finishLoading());
    },
  };
};
export default connect(null, mapDispatchToProps)(PyqCpsExamComponent);
