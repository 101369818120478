import React, { useState, useEffect } from "react";
import "./QuestionWiseAnalysisContentComponent.scss";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { isEmpty } from "../../../../../custom_utilities/custom_useful_functions";
import LoaderPopUp from "../../../../../components/loader/loader";
import SingleQuestionDetails from "./singleQuestionDetails/SingleQuestionDetails";

const QuestionWiseAnalysisContentComponent = (props) => {
  let {
    startLoading,
    finishLoading,
    plannerTestId,
    loader,
    parentUserId,
    paperId,
  } = props;

  const [state, setState] = useState({
    questionWiseData: {},
    singleQuestionData: {},
    page: 1,
    subjectList: [],
    currentSubject: null,
  });

  let {
    questionWiseData,
    singleQuestionData,
    page,
    subjectList,
    currentSubject,
  } = state;

  let history = useHistory();

  useEffect(() => {
    getSubjectList();
  }, []);

  useEffect(() => {
    if (currentSubject) {
      getQuestionWiseData();
    }
  }, [currentSubject]);

  useEffect(() => {
    if (currentSubject) {
      getSingleQuestion();
    }
  }, [currentSubject, page]);

  
  const getQuestionWiseData = async () => {
    startLoading();
    let data = {
      subject: currentSubject,
      user_id: parentUserId,
      paper_id: paperId,
      planner_test_id: plannerTestId,
    };

    try {
      const response = await axios.post(
        "https://learning.motion.ac.in/motioneducation/api/getquestionwisedata",
        data
      );

      if (response.data.status === 200) {
        setState((prevState) => ({
          ...prevState,
          questionWiseData: response.data.data,
        }));
        finishLoading();
      }
    } catch (error) {
      finishLoading();
      console.log(error);
    }
  };

  const getSingleQuestion = async () => {
    startLoading();

    let data = {
      subject: currentSubject,
      user_id: parentUserId,
      paper_id: paperId,
      planner_test_id: plannerTestId,
      page: page,
    };

    try {
      const response = await axios.get(
        `https://learning.motion.ac.in/motioneducation/api/getsinglequestion?subject=${data.subject}&paper_id=${data.paper_id}&planner_test_id=${data.planner_test_id}&user_id=${data.user_id}&page=${page}`
      );

      if (response.data.status === 200) {
        setState((prevState) => ({
          ...prevState,
          singleQuestionData: response.data.questions,
        }));
        finishLoading();
      }
    } catch (error) {
      finishLoading();
      console.log(error);
    }
  };

  const getSubjectList = async () => {
    // const headers = {
    //   Authorization: `${tokenType} ${token}`,
    // };

    let data = {
      user_id: parentUserId,
      paper_id: paperId,
    };

    // startLoading();
    try {
      const response = await axios.post(
        "https://learning.motion.ac.in/motioneducation/api/StudentTestAnalysicsReport",
        data
        // { headers }
      );
      console.log("testing getSubjectList", response);
      if (response.data.status === 200) {
        setState((preState) => ({
          ...preState,
          subjectList: [...response.data.subjects],
          currentSubject: response.data.subjects[0].subject,
        }));
        // response.data.subjects[0] &&
        //   setState((prevState) => ({
        //     ...prevState,
        //     currentSubject: response.data.subjects[0]?.subject,
        //   }));
        // finishLoading();
      }
    } catch (error) {
      console.log(error);
      // finishLoading();
    }
  };

  const handleChangeSubject = (event) => {
    let { value } = event.target;
    setState((prevState) => ({
      ...prevState,
      currentSubject: value,
      page: 1, // Reset page when subject changes
    }));
  };

  const handleNextBtn = () => {
    setState((prevState) => ({ ...prevState, page: page + 1 }));
  };

  const handlePrevBtn = () => {
    if (page > 1) {
      setState((prevState) => ({ ...prevState, page: page - 1 }));
    }
  };

  const renderQuestionWiseData = () => {
    return (
      <div>
        <section>
          <div className="cards">
            <div className="card">
              <div className="text">Marks</div>
              <div className="num">{questionWiseData.marks}</div>
            </div>

            <div className="card">
              <div className="text">Percentage</div>
              <div className="num">{questionWiseData.percentage}</div>
            </div>

            <div className="card">
              <div className="text">Rank</div>
              <div className="num">{questionWiseData.rank}</div>
            </div>
          </div>
        </section>

        <section className="table_section">
          <table style={{ width: "100%" }}>
            <thead>
              <tr className="table_heading">
                <th>Statistics</th>
                <th>Questions</th>
                <th>Percentage</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Total Questions</td>
                <td className="num">{questionWiseData.total_question}</td>
                <td className="num">-</td>
              </tr>

              <tr>
                <td>No. of Q’s Correct</td>
                <td className="num">{questionWiseData.correct}</td>
                <td className="num">{questionWiseData.correct_percentage}</td>
              </tr>

              <tr>
                <td>No. of Q’s Incorrect</td>
                <td className="num">{questionWiseData.wrong}</td>
                <td className="num">{questionWiseData.wrong_percentage}</td>
              </tr>

              <tr>
                <td>No. of Q’s Unattempted</td>
                <td className="num">{questionWiseData.unattempted}</td>
                <td className="num">
                  {questionWiseData.unattempted_percentage}
                </td>
              </tr>
            </tbody>
          </table>
        </section>

        <section className="questionDetails">
          <SingleQuestionDetails
            singleQuestionData={singleQuestionData}
            handleNextBtn={handleNextBtn}
            handlePrevBtn={handlePrevBtn}
            history={history}
            page={page}
            plannerTestId={plannerTestId}
            currentSubject={currentSubject}
            paperId={paperId}
          />
        </section>
      </div>
    );
  };

  return (
    <div className="QuestionWiseAnalysisContentComponentWrapper">
      <div className="subjects_tab_wrapper">
        <div className="outer_content_wrapper">
          <div className="content_wrapper">
            <div className="left_side_content_wrapper">
              <div className="subject_type_collection_wrapper">
                {!isEmpty(subjectList)
                  ? subjectList.length
                    ? subjectList.map((element, index) => {
                        return (
                          <div
                            key={element.subject}
                            className="single_select_subject_type"
                          >
                            <input
                              type="radio"
                              id={element.subject}
                              name="subject"
                              value={element.subject}
                              checked={element.subject === state.currentSubject}
                              onChange={(event) => {
                                handleChangeSubject(event);
                              }}
                            />
                            <label htmlFor={element.subject}>
                              {element.subject}
                            </label>
                          </div>
                        );
                      })
                    : null
                  : null}
              </div>
            </div>

            <div className="right_side_content_wrapper">
              {renderQuestionWiseData()}
            </div>
            {loader && <LoaderPopUp />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuestionWiseAnalysisContentComponent;
