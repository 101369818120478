import React, { useState, useEffect } from "react";
import "./TestWiseAnalysis.scss";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { Link } from "react-router-dom";
import { isEmpty } from "../../../custom_utilities/custom_useful_functions";
import images from "../../../utilities/images/images";
import { connect } from "react-redux";
import uiActions from "../../../redux/ui/action";
import LoaderPopUp from "../../../components/loader/loader";
import { setPaperId } from "../../redux/testDataSlice/TestDataSlice";

const TestWiseAnalysis = (props) => {
  let { handleStep2, loader, startLoading, finishLoading, roll_no } = props;
  let [state, setState] = useState({
    studentData: {},
    selected_type: "",
  });

  useEffect(() => {
    getStudentData();
  }, []);

  const getStudentData = async (Month) => {
    startLoading();
    let mont = Month?.length ? Month : "";
    let data = {
      roll_no: roll_no,
      month: mont,
      page: 1,
      device: "Web",
    };
    try {
      const response = await axios.post(
        "https://learning.motion.ac.in/motioneducation/api/SPR_StudentOverallSummary",
        data
      );

      if (response.data.status === 200) {
        setState((prevState) => ({
          ...prevState,
          studentData: response.data.student_data,
        }));

        finishLoading();
      }
    } catch (error) {
      finishLoading();
      console.log(error);
    }
  };

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const handleMonth = async (event) => {
    setState((prevState) => ({
      ...prevState,
      selected_type: event.target.value,
    }));
    getStudentData(event.target.value);
  };

  return (
    <>
      <div className="container">
        <section className="testWiseAnalysis">
          <div className="testWiseAnalysisHeading">
            <div className="left">
              <Link
                className="backBtn"
                to={{
                  pathname: "/parent-dashboard",
                }}
              >
                <img src={images.m2_backBtn} alt="backBtn" />
              </Link>
              <div className="section-heading">Test Analysis</div>
            </div>
            <div className="right">
              <div className="text">Sort by</div>
              <div>
                <select
                  id="months"
                  onChange={handleMonth}
                  value={state.selected_type}
                >
                  <option value="" disabled>
                    Select Month
                  </option>
                  {!isEmpty(months) &&
                    months.map((element, index) => (
                      <option key={index} value={element}>
                        {element}
                      </option>
                    ))}
                </select>
              </div>
            </div>
          </div>

          {!isEmpty(state.studentData) ? (
            state.studentData.data.length ? (
              state.studentData.data.map((element, index) => (
                <RbtsFullTest
                  key={index}
                  studentData={element}
                  handleStep2={handleStep2}
                  setPaperId={setPaperId}
                />
              ))
            ) : (
              <div className="no-data">
                <div className="img">
                  <img src={images.m2_video_ntF} alt="img" />
                  <div className="no-data-text">No Data Found!</div>
                </div>
              </div>
            )
          ) : null}
        </section>
      </div>
       {loader && <LoaderPopUp />} 
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loader: state.ui.loader,
  };
};

const mapDipatchToProps = (dispatch) => {
  return {
    startLoading: () => {
      dispatch(uiActions.startLoading());
    },
    finishLoading: () => {
      dispatch(uiActions.finishLoading());
    },

    setPaperId: (payload) => {
      dispatch(setPaperId(payload));
    },
  };
};

export default connect(mapStateToProps, mapDipatchToProps)(TestWiseAnalysis);

const RbtsFullTest = (props) => {
  let { studentData, handleStep2, setPaperId } = props;
  let history = useHistory();

  const handleRedirect = (paperId) => {
    setPaperId(paperId);
    history.push({
      pathname: "/student-report-pdf",
    });
  };

  const date = new Date(studentData.student_summary.to_be_held_on);
  const day = date.toLocaleString("en-US", { day: "2-digit" });
  const month = date.toLocaleString("en-US", { month: "short" });
  const year = date.toLocaleString("en-US", { year: "numeric" });

  const formattedDate = `${day}-${month}-${year}`;
  return (
    <>
      <div className="Card">
        <div className="card-body">
          <div className="card-title">
            <div className="text">RBTS Full Test</div>
            <div className="date">{formattedDate}</div>
          </div>

          <div className="card-contain">
            <div className="lft">
              <div className="lft-top">
                <ul>
                  <li>
                    <div className="TestReportCard">
                      <div className="text">
                        <div className="text-top">Marks</div>
                        <div>
                          <div className="text-btm">Out of 300</div>
                        </div>
                      </div>
                      <div className="num marks">
                        {studentData.student_summary.total_marks}
                      </div>
                    </div>
                  </li>

                  <li>
                    <div className="TestReportCard">
                      <div className="text">
                        <div className="text-top">Rank</div>
                        <div>
                          <div className="text-btm">600 Students</div>
                        </div>
                      </div>
                      <div className="num rank">
                        {studentData.student_summary.rank}
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="TestReportCard">
                      <div className="text">
                        <div className="text-top">Accuracy</div>
                      </div>
                      <div className="num">
                        {studentData.student_summary.Accuracy}
                      </div>
                    </div>
                  </li>

                  <li>
                    <div className="TestReportCard">
                      <div className="text">
                        <div className="text-top">Highest Marks</div>
                      </div>
                      <div className="num">
                        {studentData.student_summary.highest_marks}
                      </div>
                    </div>
                  </li>

                  <li>
                    <div className="TestReportCard">
                      <div className="text">
                        <div className="text-top">Average Marks</div>
                      </div>
                      <div className="num">
                        {studentData.student_summary.avg_score}
                      </div>
                    </div>
                  </li>
                </ul>
              </div>

              <div className="lft-btm">
                <div
                  className="percentageBox"
                  style={{
                    backgroundColor: studentData.student_summary.performance,
                  }}
                >
                  <div>
                    <div className="text">Percentile</div>
                    <div className="num">
                      {studentData.student_summary.percentile}
                    </div>
                  </div>
                  <div className="img">
                    <img src={images.user_activity_46} alt="img" />
                  </div>
                  <div>
                    <div className="text">Percentage</div>
                    <div className="num">
                      {studentData.student_summary.percentage}
                    </div>
                  </div>
                </div>

                <div className="subjs">
                  <ul>
                    {studentData.student_summary?.Physics?.user_score !==
                      null &&
                    studentData.student_summary?.Physics?.user_score !== 0 ? (
                      <li className="li-1">
                        <div className="text">Physics</div>
                        <div className="num">
                          {studentData.student_summary?.Physics?.rank}
                        </div>
                      </li>
                    ) : null}

                    {studentData.student_summary?.Chemistry?.user_score !==
                      null &&
                    studentData.student_summary?.Chemistry?.user_score !== 0 ? (
                      <li className="li-2">
                        <div className="text">Chemistry</div>
                        <div className="num">
                          {studentData.student_summary?.Chemistry?.rank}
                        </div>
                      </li>
                    ) : null}

                    {studentData.student_summary?.Maths?.user_score !== null &&
                    studentData.student_summary?.Maths?.user_score !== 0 ? (
                      <li className="li-3">
                        <div className="text">Maths</div>
                        <div className="num">
                          {studentData.student_summary?.Maths?.rank}
                        </div>
                      </li>
                    ) : null}

                    {studentData.student_summary?.Botany?.user_score !== null &&
                    studentData.student_summary?.Botany?.user_score !== 0 ? (
                      <li className="li-1">
                        <div className="text">Botany</div>
                        <div className="num">
                          {studentData.student_summary?.Botany?.rank}
                        </div>
                      </li>
                    ) : null}

                    {studentData.student_summary?.Zoology?.user_score !==
                      null &&
                    studentData.student_summary?.Zoology?.user_score !== 0 ? (
                      <li>
                        <div className="text">Zoology</div>
                        <div className="num">
                          {studentData.student_summary?.Zoology?.rank}
                        </div>
                      </li>
                    ) : null}
                  </ul>
                </div>
              </div>
            </div>

            <div className="rght">
              {/* <div className="type">obj Type</div> */}
              <div className="viewReport">
                <button
                  onClick={() =>
                    handleStep2(
                      studentData.paper_id,
                      studentData.planner_test_id
                    )
                  }
                >
                  <span className="text">View report</span>

                  <span>
                    <div className="img">
                      <img src={images.user_activity_47} alt="img" />
                    </div>
                  </span>
                </button>
              </div>
              <div
                className="downloadReport"
                onClick={() => handleRedirect(studentData.paper_id)}
              >
                <button>
                  <span className="text">Download report</span>
                  <span>
                    <div className="img">
                      <img src={images.user_activity_48} alt="img" />
                    </div>
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
