import { Fragment } from "react";
import "./preference_selection_modal.scss";

import { isEmpty } from "../../../../custom_utilities/custom_useful_functions";
import { createPortal } from "react-dom";

const PreferenceSelectionModal = (props) => {
  let {
    userPreference,
    targets,
    classes,
    courses,
    batches,
    handleCloseModal,
    userData,
  } = props;
  
  return createPortal(
    <Fragment>
      <div
        className="modal fade update_course_modal"
        id="preference_selection_modal"
        tabIndex="-1"
        aria-labelledby="preference_selection_modal_label"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5
                className="modal-title"
                id="course_and_subject_preference_modal_label"
              >
                Update your Package
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => handleCloseModal()}
              ></button>
            </div>
            <div className="modal-body">
              <div className="modal_body_wrapper">
                <section className="sec_1_wrapper">
                  <div className="text_content_wrapper">
                    <p className="text_content">Select Stream</p>
                  </div>
                  <div className="target_collection_wrapper">
                    {!isEmpty(targets)
                      ? targets.target.length
                        ? targets.target.map((element, index) => {
                            return (
                              <div
                                key={index}
                                className="single_target_wrapper"
                              >
                                <input
                                  type="radio"
                                  id={`target_${element.target_id}`}
                                  name="target"
                                  value={element.target_id}
                                  onChange={(event) =>
                                    props.handleChangeTarget(event)
                                  }
                                  checked={
                                    element.target_id ===
                                    userPreference.target_id
                                  }
                                />
                                <label htmlFor={`target_${element.target_id}`}>
                                  {element.target}
                                </label>
                              </div>
                            );
                          })
                        : null
                      : null}
                  </div>
                </section>
                {!isEmpty(classes) ? (
                  classes.classes.length ? (
                    <section className="sec_2_wrapper">
                      <div className="text_content_wrapper">
                        <p className="text_content">Current Class</p>
                      </div>
                      <div className="class_collection_wrapper">
                        {classes.classes.map((element, index) => {
                          return (
                            <div key={index} className="single_class_wrapper">
                              <input
                                type="radio"
                                id={`class_${element.class_id}`}
                                name="class"
                                value={element.class_id}
                                onChange={(event) =>
                                  props.handleChangeClass(event)
                                }
                                checked={
                                  element.class_id === userPreference.class_id
                                }
                              />
                              <label htmlFor={`class_${element.class_id}`}>
                                {element.class}
                              </label>
                            </div>
                          );
                        })}
                      </div>
                    </section>
                  ) : null
                ) : null}

                {!isEmpty(courses) ? (
                  courses.course.length ? (
                    <section className="sec_3_wrapper">
                      <div className="text_content_wrapper">
                        <p className="text_content">Select Program/Medium</p>
                      </div>
                      <div className="course_collection_wrapper">
                        {courses.course.map((element, index) => {
                          return (
                            <div key={index} className="single_course_wrapper">
                              <input
                                type="radio"
                                id={`course_${element.course_id}`}
                                name="course"
                                value={element.course_id}
                                checked={
                                  userPreference.course_id === element.course_id
                                }
                                onChange={(event) =>
                                  props.handleChangeCourse(event)
                                }
                              />
                              <label htmlFor={`course_${element.course_id}`}>
                                {element.course}
                              </label>
                            </div>
                          );
                        })}
                      </div>
                    </section>
                  ) : null
                ) : null}

                {!isEmpty(batches) ? (
                  batches.batch.length ? (
                    <section className="sec_4_wrapper">
                      <div className="text_content_wrapper">
                        <p className="text_content">{(userData.is_academic==1)? "Select Batch":""}</p>
                      </div>
                      <div className="batch_collection_wrapper">
                        {batches.batch.map((element, index) => {
                          return (
                           (userData.is_academic==1)?
                           <div
                           key={index}
                           className="single_batch_wrapper">
                           <input
                             type="radio"
                             id={`batch_${element.batch_id}`}
                             name="batch"
                             value={element.batch_id}
                             onChange={(event) =>
                               props.handleChangeBatch(event)
                             }
                             checked={
                              element.batch_id === userPreference.batch_id
                            }
                             
                           />
                           <label htmlFor={`batch_${element.batch_id}`}>
                             {element.batch}
                           </label>
                         </div>
                         :
                         <div
                         key={index}
                         className="single_batch_wrapper"
                         style={{display:"none"}}>
                         <input
                           type="radio"
                           id={`batch_${element.batch_id}`}
                           name="batch"
                           value={element.batch_id}
                           // onChange={(event) =>
                           //   props.handleChangeBatch(event)
                           // }
                           checked={
                             element.batch_id === userPreference.batch_id
                           }
                           disabled
                         />
                         <label htmlFor={`batch_${element.batch_id}`}>
                           {element.batch}
                         </label>
                       </div>
                          );
                        })}
                      </div>
                    </section>
                  ) : null
                ) : null}
                <section className="sec_5_wrapper">
                  <div className="text_content_wrapper">
                    {/* <p className="text_content">
                      Batch can't be changed by the user end, if you want to
                      change please contact support.
                    </p> */}
                  </div>
                </section>
              </div>
            </div>
            <div className="modal-footer">
              <div className="modal_footer">
                <button
                  type="button"
                  onClick={() => props.handleUpdateCourse()}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
    , document.getElementById('courseModal'))
    
  
};

export default PreferenceSelectionModal;
