import { Fragment, useState } from "react";
import "./live_stream_feature_content_component.scss";

import { isEmpty } from "../../../../../custom_utilities/custom_useful_functions";
import images from "../../../../../utilities/images/images";
import axios from "axios";
import { url_18 } from "../../../../../custom_utilities/api_services";

///////Chat component///////////
import ChatComponent from "./chat_components/chat_components";

//Redux
import { connect } from "react-redux";
import uiActions from "../../../../../redux/ui/action";
import twilioChatActions from "../../../../../redux/twilio_chat/action";

const LiveStreamFeatureContentComponent = (props) => {
  let {
    history,
    location,
    liveStreamData,
    userData,
    accessTokenDetails,
    setAccessTokenDetails,
    startLoading,
    finishLoading,
  } = props;

  const [state, setState] = useState({
    isChatWindowVisible: false,
  });

  const handleRedirect = () => {
    history.goBack();
  };

  const handleOnLoad = () => {
    let frame = document.querySelector("#iframe");
    frame.contentWindow.postMessage(
      `{"activate": true,"userId":"${userData.user_id}"}`,
      "*"
    );
  };

  const renderFrame = (data) => {
    switch (data.redirectedFrom) {
      case "schedule_class":
        return (
          <iframe
            id="iframe"
            src={`https://speed.mogiio.com/embedLive/${data.notes}`}
            onLoad={handleOnLoad}
            allow="accelerometer; autoplay; encrypted-media;gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        );
      case "activity":
        return (
          <iframe
            id="iframe"
            src={`https://speed.mogiio.com/embedLive/${data.stream_key}`}
            // src={`https://speed.mogiio.com/embed3/602bbc635edad16d72488ffd`}
            onLoad={handleOnLoad}
            allow="accelerometer; autoplay; encrypted-media;gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        );
      default:
        return null;
    }
  };

  const handleCreateTwilioAccessToken = async () => {
    let data = {
      identity: userData.name,
    };
    startLoading();
    try {
      const response = await axios.post(url_18, data);
      if (response.status === 200) {
        finishLoading();
        setAccessTokenDetails({
          identity: response.data.identity,
          accessToken: response.data.token,
        });
        setState((prevState) => ({ ...prevState, isChatWindowVisible: true }));
        window.scrollTo(0, document.body.scrollHeight);
      }
    } catch (error) {
      finishLoading();
      console.log(error);
    }
  };

  return (
    <Fragment>
      <div className="liveStreamFeatureContentComponent_wrapper">
        <div className="liveStreamFeatureContentComponent_inner_wrapper">
          <div className="sec_1_wrapper">
            <div className="sec_1_inner_wrapper">
              <div className="btn_wrapper">
                <button onClick={() => handleRedirect()}>
                  <img src={images.user_activity_1} />
                </button>
              </div>
              <div className="text_content_wrapper">
                <p className="text_content">Live Class</p>
              </div>
            </div>
          </div>
          <div className="video_wrapper">
            <div className="video_inner_wrapper">
              {!isEmpty(liveStreamData) ? renderFrame(liveStreamData) : null}
            </div>
          </div>
          {state.isChatWindowVisible ? (
            <div className="sec_2_wrapper">
              <div className="sec_2_inner_wrapper">
                <div className="text_content_wrapper">
                  <p className="text_content">Live Chat</p>
                </div>
                <ChatComponent history={history} location={location} />
              </div>
            </div>
          ) : (
            <div className="live_chat_action_wrapper">
              <div className="live_chat_action_inner_wrapper">
                <div
                  className="content_icon_wrapper bounce"
                  onClick={() => handleCreateTwilioAccessToken()}
                >
                  <div className="text_content_wrapper">
                    <p className="text_content">Live Chat</p>
                  </div>
                  <div className="image_wrapper">
                    <img src={images.user_chat_3} />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    accessTokenDetails: state.twilioChatData.accessTokenDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => {
      dispatch(uiActions.startLoading());
    },
    finishLoading: () => {
      dispatch(uiActions.finishLoading());
    },
    setAccessTokenDetails: (payload) => {
      dispatch(twilioChatActions.setAccessTokenDetails(payload));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LiveStreamFeatureContentComponent);
