import { Fragment } from "react";

///////Canvas Chart//////////////////
import { CanvasJSChart } from "canvasjs-react-charts";

const CanvasDoughnutChart3 = (props) => {
  let { dataPoints } = props;
  const options = {
    animationEnabled: true,
    exportEnabled: true,
    theme: "light2", //"light1", "dark1", "dark2"
    backgroundColor: "#F5F4F8",
    height: 300,
    title: {
      text: "",
    },
    axisY: {
      includeZero: true,
    },
    axisX: {},
    data: [
      {
        type: "column", //change type to bar, line, area, pie, etc
        //indexLabel: "{y}", //Shows y value on all Data Points
        indexLabelFontColor: "#5A5757",
        indexLabelPlacement: "outside",
        dataPoints: [
          {
            label: "Cut Off",
            color: "#668DE1",
            y: Number(dataPoints.Cut_Off),
          },
          {
            label: "You",
            color: "#31D680",
            y: Number(dataPoints.User_Marks),
          },
          {
            label: "Topper",
            color: "#FF933A",
            y: Number(dataPoints.Topper_Marks),
          },
        ],
      },
    ],
  };
  return (
    <Fragment>
      <div className="canvasDoughnutChart_wrapper">
        <CanvasJSChart options={options} />
      </div>
    </Fragment>
  );
};

export default CanvasDoughnutChart3;
