import { Fragment, useState, useEffect } from "react";
import "./header_left.scss";
import images from "../../../../../../utilities/images/images";

const HeaderLeft = () => {
  return (
    <Fragment>
      <div className="headerLeft_wrapper">
        <div className="headerLeft_inner_wrapper">
          <div className="logo_image_wrapper">
            <img src={images.motion_logo_svg} />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default HeaderLeft;
