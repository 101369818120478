import { Fragment, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { url_106,url_77 } from "../../../../../../../../custom_utilities/api_services";
import axios from "axios";
import { connect } from "react-redux";
import BookmarkTestViewQuestions from "./BookmarkTestViewQuestions";
import uiActions from "../../../../../../../../redux/ui/action";

const BookmarkTestViewContentComponent = (props) => {
  let { history, startLoading, finishLoading, userData } = props;

  const [state, setState] = useState({
    step: 1,
    testSectionsDetails: {},
    testQuestionsDetails: [],
    currentQuestion: 0,
  });

  const location = useLocation();
  console.log("location", location);
  let type = location.state.type;

  useEffect(() => {
    getTestQuestionsList();
  }, []);

  const getTestQuestionsList = async () => {
    startLoading();
    let data;
    if (type === "test") {
      data = {
        type: 3,
      };
    }
    try {
      const response = await axios.post(url_106, data);
      if (response.data.status === 200) {
        finishLoading();
        setState((prevState) => ({
          ...prevState,
          testQuestionsDetails: response.data.data.ExamList,
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handlePreviousQuestionBtn = () => {
    getTestQuestionsList();
    let { currentQuestion, testQuestionsDetails } = state;
    if (currentQuestion >= 1) {
      setState((prevState) => ({
        ...prevState,
        currentQuestion: currentQuestion - 1,
        testQuestionsDetails: testQuestionsDetails,
      }));
      window.scrollTo(0, 0);
    }
  };

  const handleNextQuestionBtn = () => {
    getTestQuestionsList();
    let { currentQuestion, testQuestionsDetails } = state;

    if (testQuestionsDetails.length === currentQuestion + 1) {
      history.push("/user_bookmarks");
    } else {
      setState((prevState) => ({
        ...prevState,
        currentQuestion: currentQuestion + 1,
        testQuestionsDetails: testQuestionsDetails,
      }));
      window.scrollTo(0, 0);
    }
  };

  const handleQuestionJump = (questionNumber) => {
    getTestQuestionsList();
    setState((prevState) => ({
      ...prevState,
      currentQuestion: questionNumber,
    }));
  };

  const handleUpdateTestQuestionDetails = (updatedDetails) => {
    setState((prevState) => ({
      ...prevState,
      testQuestionsDetails: updatedDetails,
    }));
  };

  const handleBookmarkTestQuestion = async (testQuestionDetails) => {
    const { testQuestionsDetails, currentQuestion } = state;
    const updatedTestQuestions = testQuestionsDetails.filter(
      (question) => question.qid !== testQuestionDetails.qid
    );

    setState((prevState) => ({
      ...prevState,
      testQuestionsDetails: updatedTestQuestions,
    }));

    let data = {
      paper_id: testQuestionDetails.paper_id,
      type: 3, // 1 for test
      reference_id: testQuestionDetails.qid,
      status: 0, // 0 => unsave
    };

    try {
      const response = await axios.post(url_77, data);
      console.log("response testing", response);
      // Handle the response if needed
    } catch (error) {
      console.log(error);
    }

    if (currentQuestion < updatedTestQuestions.length) {
      // If there are remaining questions after removal, update the currentQuestion index
      setState((prevState) => ({
        ...prevState,
        currentQuestion: currentQuestion,
      }));
    } else if (
      currentQuestion === updatedTestQuestions.length &&
      currentQuestion > 0
    ) {
      // If the removed question was the last question, move to the previous question
      setState((prevState) => ({
        ...prevState,
        currentQuestion: currentQuestion - 1,
      }));
    } else {
      // If the removed question was the only question, redirect to the desired route
      history.push("/user_bookmarks");
    }

    // Pass the updated testQuestionsDetails back to the parent component
    handleUpdateTestQuestionDetails(updatedTestQuestions);
  };





  const renderView = (step) => {
    switch (step) {
      case 1:
        return (
          <BookmarkTestViewQuestions
            testQuestionsDetails={state.testQuestionsDetails}
            currentQuestion={state.currentQuestion}
            handlePreviousQuestionBtn={handlePreviousQuestionBtn}
            handleNextQuestionBtn={handleNextQuestionBtn}
            handleQuestionJump={handleQuestionJump}
            userId={userData.user_id}
            startLoading={startLoading}
            finishLoading={finishLoading}
            handleBookmarkTestQuestion={handleBookmarkTestQuestion}
          />
        );

      default:
        return null;
    }
  };

  return (
    <Fragment>
      <div className="cps_test_content_component_wrapper">
        <div className="test_content_component_inner_wrapper">
          {renderView(state.step)}
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    userPreference: state.userPreference,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => {
      dispatch(uiActions.startLoading());
    },
    finishLoading: () => {
      dispatch(uiActions.finishLoading());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BookmarkTestViewContentComponent);
