import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import routes from "./routes_data";
import PrivateRoute from "./private";
import PublicRoute from "./public";
import InactivityLogout from "../custom_utilities/custom_logout/InactivityLogout";
import { connect } from "react-redux";
// import BlockedtoAccessApp from "../BlockedtoAccessApp";
import BlockedtoAccessApp from "../containers/blocking_access_to_app/BlockedtoAccessApp";

const basename = "/";

const Routes = ({ isBlocked, remark }) => {
  return (
    <>
      <Router basename={basename}>
        {/* <Suspense fallback={<Loading/>}> */}
        <InactivityLogout />

        <Switch>
          {routes.map((route, i) => {
            const RouteComponent = route.auth ? PrivateRoute : PublicRoute;

            return (
              <RouteComponent
                key={i}
                path={route.path}
                exact={route.exact}
                component={route.component}
                isBlocked={isBlocked} // Pass isBlocked to route components
                remark={remark}
              />
            );
          })}

          {isBlocked && remark && (
            <Route path="/block" component={BlockedtoAccessApp} />
          )}
        </Switch>
      </Router>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    isBlocked: state.BlockUserSlice.isBlocked,
    remark: state.BlockUserSlice.remark,
  };
};

export default connect(mapStateToProps, null)(Routes);
