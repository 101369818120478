import React from "react";
import ProgressBar from "react-bootstrap/ProgressBar";

const TestProgressIndicator = ({
  progressCount,
  currentQuestion,
  testQuestionsDetails,
}) => {
  return (
    <div className="testProgressIndicator">
      <h4>Test Question progress Indicator</h4>
      <div className="ProgressBar_Line">
        <ProgressBar now={progressCount} className="progressBar" />
        <span style={{ left: progressCount - 1 + "%" }}>{`${
          currentQuestion + 1
        }`}</span>
      </div>
      <div className="questionPercentage">
        <span>1</span>
        <span>{testQuestionsDetails?.length}</span>
      </div>
    </div>
  );
};

export default TestProgressIndicator;
