import HeaderComponent from "../../../../structure_components/desktop_screens/header_component/header_component";

import StudentAttendanceContent from "../user_attendance/StudentAttendanceContent";

import MobileLeftSideBarComponent from "../../../../structure_components/mobile_screens/mobile_left_sideBar_component/mobile_left_sideBar_component";
import MobileHeaderComponent from "../../../../structure_components/mobile_screens/mobile_header_component/mobile_header_component";
import MobileRightSideBarComponent from "../../../../structure_components/mobile_screens/mobile_right_sideBar_component/mobile__right_sideBar_component";

import { connect } from "react-redux";
import Footer_component from "../../../../structure_components/desktop_screens/footer_component/Footer_component";
import { useEffect, useState } from "react";

    const StudentAttendance = (props) => {
      let { history, loader, userData, userPreference, location } = props;
      const [windowWidth, setWindowWidth] = useState(window.innerWidth);
      const setWidth = () => {
        setWindowWidth(window.innerWidth);
      };
      useEffect(() => {
        window.addEventListener("resize", setWidth);

        return () => {
          window.removeEventListener("resize", setWidth);
        };
      }, [windowWidth]);

      return (
        <div className="attendance_wrapper">
          {windowWidth <= 480 ? (
            <>
              <MobileLeftSideBarComponent />
              <MobileHeaderComponent
                userPreference={userPreference}
                userData={userData}
              />
              <MobileRightSideBarComponent history={history} />
            </>
          ) : (
            <HeaderComponent history={history} />
          )}
          <StudentAttendanceContent />
          
          <Footer_component />
        </div>
      );
    };

const mapStateToProps = (state) => {
    return {
      loader: state.ui.loader,
      userData: state.auth.user,
      userPreference: state.userPreference,
    };
  };
  export default connect(mapStateToProps)(StudentAttendance);