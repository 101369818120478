import actionTypes from "./actionTypes";

const initialState = {
  selectedSubject: "",
  AdaptiveCpsId: "",
};

const AdaptiveCpsReducer = (state = initialState, { type, payload = null }) => {
  switch (type) {
    case actionTypes.SET_CPS_SUBJECT:
      return setAdaptiveCpsSubject(state, payload);
    case actionTypes.SET_ADAPTIVE_CPS_ID:
      return setAdaptiveCpsId(state, payload);
    default:
      return state;
  }
};

const setAdaptiveCpsSubject = (state, payload) => {
  return {
    ...state,
    selectedSubject: payload,
  };
};

const setAdaptiveCpsId = (state, payload) => {
  return {
    ...state,
    AdaptiveCpsId: payload,
  };
};

export default AdaptiveCpsReducer;
