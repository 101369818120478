import React, { Fragment, useState, useRef } from "react";
import "./AdaptiveCpsViewSolutionContent.scss";
// import QuestionListPanel from "../questionListPanel/QuestionListPanel";
import QuestionDetailPanel from "./questionDetailPanel/QuestionDetailPanel";
import QuestionListPanel from "./questionListPanel/QuestionListPanel";

const AdaptiveCpsViewSolutionContent = ({
  testQuestionsDetails,
  currentQuestion,
  handlePreviousQuestionBtn,
  handleNextQuestionBtn,
  handleQuestionJump,
  userData,
  handleVideoShow,
  videoShow,
}) => {
  const questionsCollectionRef = useRef(null);

  const [lang, setLang] = useState(true);

  const scrollUp = () => {
    const nextQuestionIndex = currentQuestion + 1;
    const questionElement =
      questionsCollectionRef.current.children[nextQuestionIndex];
    questionElement.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  const scrollUp2 = () => {
    const prevQuestionIndex = currentQuestion - 1;
    const questionElement =
      questionsCollectionRef.current.children[prevQuestionIndex];
    questionElement.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  const progressCount =
    (100 / testQuestionsDetails.length) * (currentQuestion + 1);

  return (
    <Fragment>
      <div className="main-wrapper">
        <div className="content-wrapper">
          {/* ... LeftSideContent ... */}
          <div className="left-panel">
            <div className="question-list-panel">
              <QuestionListPanel
                testQuestionsDetails={testQuestionsDetails}
                currentQuestion={currentQuestion}
                handleQuestionJump={handleQuestionJump}
                questionsCollectionRef={questionsCollectionRef}
              />
            </div>
          </div>

          {/* ... RightSideContent ... */}
          <div className="right-panel">
            <div className="question-detail-panel">
              <QuestionDetailPanel
                testQuestionsDetails={testQuestionsDetails}
                currentQuestion={currentQuestion}
                lang={lang}
                handlePreviousQuestionBtn={handlePreviousQuestionBtn}
                handleNextQuestionBtn={handleNextQuestionBtn}
                progressCount={progressCount}
                scrollUp={scrollUp}
                scrollUp2={scrollUp2}
                userData={userData}
                handleVideoShow={handleVideoShow}
                videoShow={videoShow}
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AdaptiveCpsViewSolutionContent;
