import { combineReducers } from "redux";

import uiReducer from "./ui/reducer";
import authReducer from "./auth/reducer";
import userPreferenceReducer from "./user_preference/reducer";
import userActivityReducer from "./user_activity/reducer";
import twilioChatReducer from "./twilio_chat/reducer";
import userTestsExercisesReducer from "./user_tests_exercises/reducer";
import pyqTestsReducer from "./pyq_test_exercises/reducer";
import cpsTestsReducer from "./cps_test_exercises/reducer";
import otsTestReducer from "./online-test_series/reducer";
import userBookmarkReducer from "./user_bookmark/reducer";
import examCrackerTestsReducer from "./exam_cracker_test_exercises/reducer";
import mockTestsReducer from "./mock_test_exercise/reducer";
import bitSatTestsReducer from "./bit_sat_test_exercises/reducer";
import paymentReducer from "./payment_gateway/reducer";
import storeReducer from "./new_store/reducer";
import handBookPaymentReducer from "./handbook_payment_gateway/reducer";
import QuestionBankReducer from "./question_bank/reducer";
import AdaptiveCpsReducer from "./adaptive_cps/reducer";
// import parentAuthReducer from "../parent/redux/auth/reducer";
import store from "../parent/redux/store/store";
import parentTestReducer from "../parent/redux/testData/reducer";
import PackageSliceReducer from "./packages_store/PackageSlice";
import RefundPackageSliceReducer from "./packages_store/refund/RefundPackageSlice";
import parentrootReducer from "../parent/redux/store/store";
import PyqDailyChallengeReducer from "./pyq_daily_challenge/reducer";
import LoginSlice from "./login/LoginSlice";
import BlockUserSlice from "./blocking_user/BlockUserSlice";
import LiveVideoStreamingSlice from "./live_video_streaming/LiveVideoStreamingSlice";
import MultipleLoginDetectSlice from "./multiple_login_detect/MultipleLoginDetectSlice";
import ReferAndEarnSlice from "./refer_earn/ReferAndEarnSlice";
import WatchedVideoDetailsSlice from "./watched_video_details/WatchedVideoDetailsSlice";

const appReducer = combineReducers({
  ui: uiReducer,
  auth: authReducer,
  userPreference: userPreferenceReducer,
  userActivity: userActivityReducer,
  twilioChatData: twilioChatReducer,
  userTestsExercisesData: userTestsExercisesReducer,
  pyqTestsData: pyqTestsReducer,
  mockTestsData: mockTestsReducer,
  cpsTestsReducer: cpsTestsReducer,
  otsTestsData: otsTestReducer,
  examCrackerTestsData: examCrackerTestsReducer,
  bitSatTestsData: bitSatTestsReducer,
  userBookmarkData: userBookmarkReducer,
  userStore: storeReducer,
  paymentReducer: paymentReducer,
  handBookPaymentData: handBookPaymentReducer,
  QuestionBank: QuestionBankReducer,
  AdaptiveCpsReducer: AdaptiveCpsReducer,
  // parentAuthReducer: parentAuthReducer,
  // parent: store,
  // parentTestReducer: parentTestReducer,
  PackageSliceReducer: PackageSliceReducer,
  RefundPackageSliceReducer: RefundPackageSliceReducer,
  parentrootReducer: parentrootReducer,
  PyqDailyChallengeReducer: PyqDailyChallengeReducer,
  LoginSlice: LoginSlice,
  BlockUserSlice: BlockUserSlice,
  LiveVideoStreamingSlice: LiveVideoStreamingSlice,
  MultipleLoginDetectSlice: MultipleLoginDetectSlice,
  ReferAndEarnSlice: ReferAndEarnSlice,
  WatchedVideoDetailsSlice: WatchedVideoDetailsSlice,
});

const rootReducer = (state, action) => {
  return appReducer(state, action);
};

export default rootReducer;
