const initialState = {
  orderId: "",
  transactionDetails: {},
  ccAvenueDetails: {},
  packageDetailsForPayment: {},
};

const paymentReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_ORDER_ID":
      return {
        ...state,
        orderId: action.payload,
      };
    case "SAVE_TRANSACTION_DETAILS":
      return {
        ...state,
        transactionDetails: action.payload,
      };
    case "SET_CC_AVENUE_DATA_IN_STORE":
      return {
        ...state,
        ccAvenueDetails: action.payload,
      };

    case "SET_PACKAGE_DETAILS_FOR_PAYMENT":
      return {
        ...state,
        packageDetailsForPayment: action.payload,
      };

    default:
      return state;
  }
};

export default paymentReducer;
