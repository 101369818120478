import { Fragment } from "react";
import "./header_component.scss";

import HeaderLeft from "./header_left_new/Header_left_new";
import HeaderTopright from "./header_left/header_left";
import HeaderRight from "./header_right/header_right";

/////React Toastify
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//Redux
import { connect } from "react-redux";
import Header_top from "./header_top/Header_top";

const HeaderComponent = (props) => {
  let { userPreference, userData, history } = props;
  return (
    <Fragment>
      <div className="header_component_wrapper">
        {/* <div className="headerTopMenuWrapper">
          <div className="container upper_layer">
            <div className="headerTopMenuLeft">
              <Header_top />
            </div>
            <div className="headerTopMenuLeft">
              <HeaderTopright
                userPreference={userPreference}
                userData={userData}
              />
            </div>
          </div>
        </div> */}

        <div className="headerWrapper">
          <div className="container-lg container-fluid">
            <div className="header_inner_wrapper">
              <div className="header_left_wrapper">
                <HeaderLeft
                  userPreference={userPreference}
                  userData={userData}
                />
              </div>
              <div className="header_right_wrapper">
                <HeaderRight userData={userData} history={history} userPreference={userPreference} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.auth.user,
    userPreference: state.userPreference,
  };
};

export default connect(mapStateToProps)(HeaderComponent);
