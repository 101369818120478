import React from "react";
import "./DateModalRange.scss";
import { Modal } from "react-bootstrap";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

const DateRangeModal = ({ open, onClose, range, setRange }) => {
  const handleModalClose = () => {
    onClose();
  };

  const handleDateRangeSubmit = () => {
    onClose();
  };

  const currentDate = new Date();
  // Calculate the date 15 days before and after the current date
  const dateBefore = new Date(currentDate.getTime() - 15 * 24 * 60 * 60 * 1000);
  const dateAfter = new Date(currentDate.getTime() + 15 * 24 * 60 * 60 * 1000);
  
  // Function to format a date as "Month Day, Year"
  const formatDate = (date) =>
    date.toLocaleDateString(undefined, { year: 'numeric', month: 'short', day: 'numeric' });
  
  return (
    <Modal
      show={open}
      onHide={handleModalClose}
      className="onSubmitModal dateModalRange"
      centered
    >
      <Modal.Header closeButton className="head">
        Select Custom Range
      </Modal.Header>
      <Modal.Body>
        <div className="dateRangeContainer">
          <div className="to">To</div>
          <DateRange
            onChange={(item) => setRange([item.selection])}
            editableDateInputs={true}
            moveRangeOnFirstSelection={false}
            ranges={range}
            months={1}
            startDatePlaceholder = {formatDate(dateBefore)}
            endDatePlaceholder = {formatDate(dateAfter)}
            direction="horizontal"
            className="calendarElement"
          />
        </div>
        <button className="subBtn" onClick={handleDateRangeSubmit}>
          Submit
        </button>
      </Modal.Body>
    </Modal>
  );
};

export default DateRangeModal;
