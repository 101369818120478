import { Fragment } from "react";
// import "./pyq_test_component.scss";

import LoaderPopUp from "../../../../../components/loader/loader";

////////
import MockTestContentComponent from "./mockTestContentComponent/mockTestContentComponent";

//Redux
import { connect } from "react-redux";

const MockTestComponent = (props) => {
  let { history, loader, userData } = props;

  return (
    <Fragment>
      <div className="mock_test_component_wrapper">
        <div className="mock_test_component_inner_wrapper">
          <Fragment>
            <MockTestContentComponent userData={userData} history={history} />
          </Fragment>
        </div>
      </div>
      {loader && <LoaderPopUp />}
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    loader: state.ui.loader,
    userData: state.auth.user,
  };
};

export default connect(mapStateToProps)(MockTestComponent);
