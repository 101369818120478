import React from "react";
import Routes from "../routes/routes";

import { setAuthToken } from "../custom_utilities/config";

import firebase from "../firebase";

//////////////////
import { toastifyMessage } from "../custom_utilities/toastify_config";
/////////////////////////

//Redux
import { connect } from "react-redux";

//Dev Tools Detector
// import * as devtools from 'devtools-detector';

const App = ({ auth }) => {
  let { isAuthenticated, tokenType, authToken } = auth;
  const devTools = require("browser-detect-devtools");
  const devToolsManager = devTools.Manager;

  devToolsManager.alwaysConsoleClear(false);

  //Freeze the debugger when opened in inspect element
  // devToolsManager.freezeWhenDevToolsOpened(true);

  if (isAuthenticated) {
    setAuthToken(tokenType, authToken);
  } else {
    setAuthToken();
  }

  if (firebase.messaging.isSupported()) {
    const messaging = firebase.messaging();

    messaging.onMessage((payload) => {
      toastifyMessage({
        status: 200,
        message: payload.notification,
      });
    });
  }

  // document.addEventListener('contextmenu', handelRightClick);

  // devToolsManager.startDevToolMonitoring((isOpened, orientation) => {
  //   // orientation : 'horizontal' / 'vertical' / 'separated-window'
  //   if(isOpened){

  //     return (<div><h1>you are not allowed</h1></div>);

  //   }
  // });

  return (
    <div>
      <Routes />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(App);
