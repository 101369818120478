
import React from "react";
import "./examCrackerTestComponent.scss";
import LoaderPopUp from "../../../../../components/loader/loader";
import ExamCrackerTestContentComponent from "./examCrackerTestContentComponent/examCrackerTestContentComponent";
import { connect } from "react-redux";
import uiActions from "../../../../../redux/ui/action";

const ExamCrackerTestComponent = ({
  history,
  loader,
  userData,
  target_id,
  userPreference,
  ExamCrackerTopicData,
  testData,
  startLoading,
  finishLoading,
}) => {
  const isMob = () => window.innerWidth <= 480;

  return (
    <>
      <div className="test_component_wrapper">
        <div className="test_component_inner_wrapper">
            <ExamCrackerTestContentComponent
              history={history}
              userData={userData}
              testData={testData}
              ExamCrackerTopicData={ExamCrackerTopicData}
              userPreference={userPreference}
              target_id={target_id}
              startLoading={startLoading}
              finishLoading={finishLoading}
            />
        </div>
      </div>

      {loader && <LoaderPopUp />}
    </>
  );
};

const mapStateToProps = (state) => ({
  loader: state.ui.loader,
  userData: state.auth.user,
  testData: state.examCrackerTestsData.examCrackerTestData,
  ExamCrackerTopicData: state.userActivity.ExamCrackerTopicData,
  userPreference: state.userPreference,
  target_id: state.userPreference?.targetDetails[0].target_id,
});

const mapDispatchToProps = (dispatch) => ({
  startLoading: () => dispatch(uiActions.startLoading()),
  finishLoading: () => dispatch(uiActions.finishLoading()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExamCrackerTestComponent);
