import React, { useState, useEffect } from "react";
import "./OtsSummary.scss";
import images from "../../../../../../utilities/images/images";
import { url_179 } from "../../../../../../custom_utilities/api_services";

import { Link } from "react-router-dom/cjs/react-router-dom.min";
import HeaderComponent from "../../../../structure_components/desktop_screens/header_component/header_component";
import uiActions from "../../../../../../redux/ui/action";
import { connect } from "react-redux";
import axios from "axios";
import { isEmpty } from "../../../../../../custom_utilities/custom_useful_functions";

const OtsSummary = (props) => {
  const { startLoading, finishLoading, otsTestsData } = props;
  const [otsSummary, setOtsSummary] = useState({});

  useEffect(() => {
    getOtsSummary();
  }, []);

  const getOtsSummary = async () => {
    let data = {
      paper_id: otsTestsData.paper_id,
      test_id: otsTestsData.test_id,
    };
    startLoading();
    try {
      const response = await axios.post(url_179, data);
      if (response.data.status === 200) {
        setOtsSummary(response.data.data);
        finishLoading();
      }
    } catch (error) {
      console.log(error);
      finishLoading();
    }
  };

  return (
    <div>
      <HeaderComponent />
      <div className="otsSummaryComponentWrapper">
        <div className="otsSummaryComponentInnrWrapper">
          <div className="container">
            <div className="otsSummaryContainer">
              <div class="otsSummaryContent">
                <div class="otsSummaryBackBtn">
                  <Link
                    className="backBtn"
                    to={{
                      pathname: "/ots",
                      state: { tab: "ots" },
                    }}
                  >
                    <img src={images.m2_backBtn} alt="backBtn" />
                  </Link>
                </div>

                <div class="section_heading">Summary</div>

                {!isEmpty(otsSummary) ? (
                  <div className="cards">
                    <div class=" card total-questions">
                      <div class="num">
                        {otsSummary.total_questions
                          ? otsSummary.total_questions
                          : "0"}
                      </div>
                      <div class="text">Total Questions</div>
                    </div>

                    <div class="card correct-answer">
                      <div class="num">
                        {otsSummary.correct_answer
                          ? otsSummary.correct_answer
                          : "0"}
                      </div>
                      <div class="text">Correct answer</div>
                    </div>

                    <div class="card incorrect-answer">
                      <div class="num">
                        {otsSummary.incorrect_answer
                          ? otsSummary.incorrect_answer
                          : "0"}
                      </div>
                      <div class="text">Incorrect answer</div>
                    </div>

                    <div class="card total-marks">
                      <div class="num">
                        {otsSummary.total_marks ? otsSummary.total_marks : "0"}
                      </div>
                      <div class="text">Total Marks</div>
                    </div>

                    <div class="card score-percentage">
                      <div class="num">
                        {otsSummary.score_percentage
                          ? otsSummary.score_percentage
                          : "0"}
                      </div>
                      <div class="text">Score Percentage</div>
                    </div>

                    <div class="card score-percentage">
                      <div class="num">
                        {otsSummary.partial_marks
                          ? otsSummary.partial_marks
                          : "0"}
                      </div>
                      <div class="text">Partial Marks</div>
                    </div>
                  </div>
                ) : (
                  <div className="ots_no_analysis_found_wrapper">
                    <img src={images.m2_video_ntF} />
                    <p>There is no Summary yet!</p>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div class="back-to-tests">
            <Link
              className="backBtn"
              to={{
                pathname: "/ots",
                state: { tab: "ots" },
              }}
            >
              <button>Back to Tests</button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loader: state.ui.loader,
    otsTestsData: state.otsTestsData.otsTestsData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => {
      dispatch(uiActions.startLoading());
    },
    finishLoading: () => {
      dispatch(uiActions.finishLoading());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OtsSummary);
