import { Fragment, useEffect, useState } from "react";
import "./OnlineTransactions.scss";
import { url_36 } from "../../../../../custom_utilities/api_services";
import axios from "axios";

const OnlineTransactions = () => {
  const [state, setState] = useState({
    onlineTransactionsList: [],
  });

  useEffect(() => {
    getOnlineTransactionsList();
  }, []);

  const getOnlineTransactionsList = async () => {
    try {
      const response = await axios.get(url_36);
      console.log(response);
      if (response.data.status == 200) {
        setState((prevState) => ({
          ...prevState,
          onlineTransactionsList: response.data.data,
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="onlineTransactionWrapper">
      <div className="online_transactions_history_collection_wrapper">
        {state.onlineTransactionsList.length
          ? state.onlineTransactionsList.map((element, index) => {
              return (
                <div key={index} className="single_transaction_wrapper">
                  <div className="section_wrapper_1">
                    <div className="left_side_content_wrapper">
                      <div className="text_content_wrapper">
                        <p className="text_content">{`Order Id: ${element.txnid}`}</p>
                      </div>
                    </div>
                    <div className="right_side_content_wrapper">
                      <div className="text_content_wrapper">
                        <p className="text_content">{`₹ ${element.txn_amount}`}</p>
                      </div>
                    </div>
                  </div>   



                  <div className="section_wrapper_2">
                    <div className="text_content_wrapper">
                      <p className="text_content_1">Discount</p>
                      <p className="text_content_2">
                        {element.txn_discount_amount}
                      </p>
                    </div>
                    <div className="text_content_wrapper">
                      <p className="text_content_1">Transaction Date & time</p>
                      <p className="text_content_2">{element.txn_date}</p>
                    </div>
                    <div className="text_content_wrapper">
                      <p className="text_content_1">Duration</p>
                      <p className="text_content_2">
                        {element.package_duration}
                      </p>
                    </div>
                    <div className="text_content_wrapper">
                      <p className="text_content_1">Expired</p>
                      <p className="text_content_2">
                        {element.txn_expire_date}
                      </p>
                    </div>
                  </div>
                </div>
              );
            })
          : "No Transactions"}
      </div>
    </div>
  );
};

export default OnlineTransactions;
