import React, { useState, useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import images from "../../../../../../../utilities/images/images";
import { connect } from "react-redux";
import HeaderComponent from "../../../../../structure_components/desktop_screens/header_component/header_component";
import { isEmpty } from "../../../../../../../custom_utilities/custom_useful_functions";
import "./OtsPaymentComponent.scss";
import { Modal, Button } from "react-bootstrap";
import { sha512 } from "js-sha512";
import authActions from "../../../../../../../redux/auth/action";
import {
  url_127,
  url_59,
  url_19,
  url_8,
  url_138,
} from "../../../../../../../custom_utilities/api_services";

import actions from "../../../../../../../redux/ui/action";
import { setRefferalCode } from "../../../../../../../redux/packages_store/PackageSlice";
import CcAvenuePayment from "../../../../../../../custom_utilities/ccavence_payment/CcAvenuePayment";
import { checkXSSAttackThroughInput } from "../../../../../../../custom_utilities/custom_useful_functions";

const PaymentComponent = (props) => {
  const {
    token,
    tokenType,
    userId,
    userPreference,
    userData,
    setUser,
    startLoading,
    finishLoading,
    setRefferalCode,
  } = props;
  const history = useHistory();
  const location = useLocation();

  const [packageDetails, setPackageDetails] = useState({});
  const [modalFlag, setModalFlag] = useState(false);
  const [cpnInput, setCpnInput] = useState("");
  const [couponDetails, setCouponDetails] = useState({});
  // const [isDisableButton, setIsDisableButton] = useState(true);
  const [termsAndConditions, setTermsAndConditions] = useState({
    termsAndConditionsData: {},
    termsAndConditionsFlag: false,
    termsAndConditionInputCheck: false,
  });

  useEffect(() => {
    getPackageDetails();
  }, []);

  const currentData = new Date();
  let date =
    currentData.getFullYear() +
    "-" +
    (currentData.getMonth() + 1) +
    "-" +
    currentData.getDate();
  let time =
    currentData.getHours() +
    ":" +
    currentData.getMinutes() +
    ":" +
    currentData.getSeconds();
  var dateTime = date + " " + time;

  const pricePurchaseBtn = (packageDetails) => {
    handlePaymentInformationPayBtn(packageDetails);
  };

  const getReference = () => {
    //you can put any unique reference implementation code here
    let text = "";
    let possible =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-.=";

    for (let i = 0; i < 15; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));

    return text;
  };

  const redirectPayUMoney = (data) => {
    //use window.bolt.launch if you face an error in bolt.launch
    window.bolt.launch(data, {
      responseHandler: function (response) {
        // your payment response Code goes here

        if (response.response.txnStatus.toLowerCase() === "success") {
          saveUserTransactions(response);
        } else {
          alert(response.response.txnMessage);
        }
      },
      catchException: function (response) {},
    });
  };

  const handlePaymentInformationPayBtn = (packageDetails) => {
    let userDataDetails = { ...userData };
    if (userDataDetails?.email) {
      if (packageDetails?.planner_overview.amount) {
        let txnId = getReference();
        let merchantKey = "ObWj6L",
          salt = "jFCXe6Kt";
        //  key|txnid|amount|productinfo|firstname|email|udf1|udf2|udf3|udf4|udf5||||||salt

        let hashSequence = `${merchantKey}|${txnId}|${packageDetails?.planner_overview.amount}|${packageDetails?.planner_overview.package_name}|${userDataDetails?.name}|${userDataDetails?.email}|||||||||||${salt}`;
        // let hashSequence = `${merchantKey}|${txnId}|1|${packageDetails.package_name}|${userDataDetails.name}|${userDataDetails.email}|||||||||||${salt}`;
        let hashValue = sha512(hashSequence);

        let payURequestParams = {
          key: merchantKey, ///*** Merchant key from PayuMoney Dashboard ***/
          txnid: txnId, ///*** Unique Transaction ID***/
          amount: packageDetails.planner_overview.amount, ///*** Amount to be paid ***/

          firstname: userDataDetails?.name, ///*** Name of the User ***/
          email: userDataDetails?.email, ///** Email Id of User **/
          phone: userDataDetails?.mobile, ///** Mobile number of User **/
          productinfo: packageDetails?.planner_overview.package_name, ///* Product name */
          surl: "dummyURL", ///* Success callback URL */
          furl: "dummyURL", ///* Failure callback URL */
          hash: hashValue,
        };

        redirectPayUMoney(payURequestParams);
      } else {
        alert("Amount is not proper");
        return;
      }
    } else {
      alert(
        "Please haven't update your email in your profile. Please update it"
      );
      history.push("/user_edit_profile");
    }
  };

  const getPackageDetails = async () => {
    const headers = {
      Authorization: `${tokenType} ${token}`,
    };

    try {
      const response = await axios.get(
        `${url_127}?package_id=${location.state.package_id}`,

        { headers }
      );

      if (response.data.status === 200) {
        setPackageDetails(response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const applyCouponMethod = async () => {
    const { package_id, amount, discount } = packageDetails.planner_overview;

    let finalAmont = parseInt(amount) - parseInt(discount);

    const headers = {
      Authorization: `${tokenType} ${token}`,
    };

    const data = {
      coupon_code: cpnInput,
      user_id: userId,
      package_id,
      finalAmont,
    };

    // export const url_59 = `${BASE_URL}/api/user/ApplyCoupon`;
    const response = await axios.post(url_59, data, { headers });

    if (response.data.status === 200) {
      setCouponDetails(response.data.data);
    } else {
      alert("Invalid Coupon");
    }
  };

  const saveUserTransactions = async (data) => {
    handleClose();
    startLoading();
    const headers = {
      Authorization: `${tokenType} ${token}`,
    };

    const dataDetails = {
      course_id: userPreference?.courseDetails[0].course_id,
      batch_id: userPreference?.batchDetails.batch_id,
      package_id: packageDetails?.planner_overview.package_id,
      txn_amount: packageDetails?.planner_overview.amount,

      txn_discount_amount: packageDetails?.planner_overview.discount,
      txn_amount: packageDetails?.planner_overview.amount,

      txn_date: data.response.addedon,
      package_duration: packageDetails?.planner_overview.duration,
      txnid: data.response.txnid,
    };

    try {
      const response = await axios.post(url_19, dataDetails, { headers });
      if (response.data.status === 200) {
        let returnedResponse = await updateUser();
        if (returnedResponse) {
          finishLoading();
          history.push({
            pathname: "/ots",
            state: { tab: "ots" },
          });
        } else {
          finishLoading();

          history.push({
            pathname: "/ots",
            state: { tab: "ots" },
          });
        }
      }
    } catch (error) {
      console.log(error);
      finishLoading();
    }
  };

  const updateUser = async () => {
    // startLoading();
    try {
      const response = await axios.get(url_8);
      if (response.data.status === 200) {
        setUser(response.data.data);
        // finishLoading();
        return 1;
      } else {
        return 0;
      }
    } catch (error) {
      console.log(error);
      return 0;
    }
  };

  const activePackage = async (data) => {
    const headers = {
      Authorization: `${tokenType} ${token}`,
    };

    let dataDetails = {
      course_id: userPreference?.courseDetails[0].course_id,
      batch_id: userPreference?.batchDetails.batch_id,
      package_id: packageDetails?.planner_overview.package_id,
      txn_amount: packageDetails?.planner_overview.amount,
      txn_discount_amount: packageDetails?.planner_overview.discount,
      txn_amount: "0.00",
      //txn_discount_amount: "",
      txn_date: dateTime,
      package_duration: packageDetails?.planner_overview.duration,
      txnid: couponDetails?.CouponCode,
    };

    startLoading();
    try {
      const response = await axios.post(url_19, dataDetails, { headers });
      if (response.data.status === 200) {
        finishLoading();
        // alert(response.data.message);
        //let returnedResponse = await updateUser();
        history.push({
          pathname: "/ots",
          state: { tab: "ots" },
        });
      } else {
        alert(response.message);
      }
      finishLoading();
    } catch (error) {
      console.log(error);
      finishLoading();
    }
  };

  const handleCouponModal = () => {
    setModalFlag(true);
  };

  const handleShow = () => {
    setModalFlag(true);
  };

  const handleClose = () => {
    setModalFlag(false);
  };

  const handleInputCheckBox = (e) => {
    console.log("handleInputCheckBox", e.target.checked);
    setTermsAndConditions((prevState) => ({
      ...prevState,
      termsAndConditionInputCheck:
        !termsAndConditions.termsAndConditionInputCheck,
    }));

    // setIsDisableButton(!isDisableButton);
  };

  const getTermsAndConditions = async () => {
    const headers = {
      Authorization: `${tokenType} ${token}`,
    };
    startLoading();

    try {
      const response = await axios.post(url_138, { headers });
      console.log("response--", response);

      if (response.data.status === 200) {
        finishLoading();
        setTermsAndConditions((prevState) => {
          return {
            ...prevState,
            termsAndConditionsData: response.data.data,
          };
        });
      }
    } catch (error) {
      finishLoading();

      console.log(error);
    }
  };

  const handleShowTermsConditionModal = () => {
    setTermsAndConditions((prevState) => {
      return {
        ...prevState,
        termsAndConditionsFlag: true,
      };
    });
    getTermsAndConditions();
  };

  const handleCloseTermsConditionModal = () => {
    setTermsAndConditions((prevState) => {
      return {
        ...prevState,
        termsAndConditionsFlag: false,
      };
    });
  };

  const handleInputchange = async (value) => {
    const response = await checkXSSAttackThroughInput(value);

    if (!response) {
      return;
    } else {
      setCpnInput(value);
    }
  };

  return (
    <>
      <div className="OtsTestListWrapper">
        <div className="OtsTestListInnerWrapper">
          <HeaderComponent history={history} />
          <div className="OtsTestList">
            <div className="container">
              <div className="ots_list_wrapper">
                <Link
                  className="backBtn"
                  to={{
                    pathname: "/ots",
                    state: { tab: "ots" },
                  }}
                >
                  <img src={images.m2_backBtn} alt="backBtn" />
                </Link>
                <div className="ots_payment_heading_text">
                  {" "}
                  {packageDetails?.planner_overview?.package_name}
                </div>
                <hr className="heading_line"></hr>
                {!isEmpty(packageDetails) ? (
                  <div className="ots_payment_wrapper">
                    <div className="pkg_overview_advantages_wrapper">
                      <div className="pkg_overview">
                        <h6>Plus Package overview(MPS)</h6>
                        <p>{packageDetails?.planner_overview.overview}</p>
                      </div>
                      <div className="pkg_advantages">
                        <h6>Advantages/learnings</h6>{" "}
                        <p>{packageDetails?.planner_overview.advantages}</p>{" "}
                      </div>
                    </div>
                    <div className="pkg_price_details">
                      <h6>Course Details</h6>
                      {/* <div>
                        <div className="course_duration">
                          Duration:{" "}
                          <span>
                            {packageDetails?.planner_overview.duration}months
                          </span>
                        </div>
                      </div> */}
                      <div>
                        <div className="course_details">
                          <span className="ots_course">Duration:</span>
                          <span className="course_duration">
                            {packageDetails?.planner_overview.duration} Months
                          </span>
                        </div>
                        <div className="course_details">
                          <span className="ots_course"> Price:</span>
                          <span className="course_amount">
                            Rs{" "}
                            {parseInt(packageDetails?.planner_overview.amount)}
                          </span>
                        </div>
                        <div className="course_details">
                          <span className="ots_course">Discount</span>
                          <span className="course_discount">
                            Rs{" "}
                            {parseInt(
                              packageDetails?.planner_overview.discount
                            )}
                          </span>
                        </div>
                        <div className="course_details">
                          <span className="ots_course">Final Price</span>
                          <span className="course_final_price">
                            Rs{" "}
                            {parseInt(packageDetails?.planner_overview.amount) -
                              parseInt(
                                packageDetails?.planner_overview.discount
                              )}
                          </span>
                        </div>
                      </div>
                      <hr className="line"></hr>
                      <div className="terms_condition">
                        <input
                          type="checkbox"
                          checked={
                            termsAndConditions.termsAndConditionInputCheck
                          }
                          onChange={(e) => handleInputCheckBox(e)}
                        />
                        <span>Accept</span>
                        <span
                          onClick={handleShowTermsConditionModal}
                          className="terms_condition_text"
                        >
                          {" "}
                          Terms & Conditions
                        </span>
                      </div>

                      {!termsAndConditions.termsAndConditionInputCheck ? (
                        <div className="pay_button">
                          <button
                            onClick={handleCouponModal}
                            disabled={
                              !termsAndConditions.termsAndConditionInputCheck
                            }
                          >
                            Rs{" "}
                            {parseInt(packageDetails?.planner_overview.amount) -
                              parseInt(
                                packageDetails?.planner_overview.discount
                              )}
                          </button>
                        </div>
                      ) : (
                        <div
                          className="pay_button_active"
                          onClick={() => {
                            setRefferalCode(" ");
                            handleCouponModal();
                          }}
                          disabled={
                            !termsAndConditions.termsAndConditionInputCheck
                          }
                        >
                          <button>
                            Rs{" "}
                            {parseInt(packageDetails?.planner_overview.amount) -
                              parseInt(
                                packageDetails?.planner_overview.discount
                              )}
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
      {!isEmpty(packageDetails) ? (
        <Modal
          show={modalFlag}
          onHide={handleClose}
          className="coupon_modal"
          centered
        >
          <Modal.Header closeButton className="mdl_hdr"></Modal.Header>
          <Modal.Body className="mbd">
            <div className="coupon_wrapper">
              <div className="coupon_wraper_inr">
                {!isEmpty(couponDetails) ? (
                  <div className="package_amount">
                    <h4 style={{ textDecoration: "line-through" }}>
                      {`₹ ${
                        parseInt(packageDetails.planner_overview.amount) -
                        parseInt(packageDetails.planner_overview.discount)
                      }`}{" "}
                    </h4>{" "}
                    <h2> {`₹ ${couponDetails.updated_amount}`}</h2>
                  </div>
                ) : (
                  <>
                    <h2 className="amt">{`₹ ${
                      parseInt(packageDetails.planner_overview.amount) -
                      parseInt(packageDetails.planner_overview.discount)
                    }`}</h2>
                  </>
                )}

                <p>
                  If you have coupon code or discount code provided by motion
                  education
                </p>
                <div className="coupon_input">
                  <input
                    type="text"
                    placeholder="Coupon code"
                    value={cpnInput}
                    onChange={(e) => handleInputchange(e.target.value)}
                  />
                  <button onClick={() => applyCouponMethod(cpnInput)}>
                    Apply Code
                  </button>
                </div>

                <div className="updated_amount">
                  Updated Amount
                  {!isEmpty(couponDetails) ? (
                    <span>{couponDetails.updated_amount}</span>
                  ) : (
                    <span>{`₹ ${
                      parseInt(packageDetails.planner_overview.amount) -
                      parseInt(packageDetails.planner_overview.discount)
                    }`}</span>
                  )}
                </div>
                {couponDetails.updated_amount == 0 ? (
                  <div className="pay_now" closeButton>
                    <button onClick={() => activePackage(packageDetails)}>
                      Active
                    </button>
                  </div>
                ) : (
                  <CcAvenuePayment
                    pricePurchaseBtn={pricePurchaseBtn}
                    packageDetails={packageDetails.planner_overview}
                    amount={
                      couponDetails.updated_amount ||
                      parseInt(packageDetails.planner_overview.amount) -
                        parseInt(packageDetails.planner_overview.discount)
                    }
                    buttonText={"Pay Now"}
                    buttonClass={"pay_now"}
                  />
                )}
              </div>
            </div>
          </Modal.Body>
        </Modal>
      ) : null}

      <Modal
        show={termsAndConditions.termsAndConditionsFlag}
        onHide={handleCloseTermsConditionModal}
        dialogClassName="my-modal"
        // centered
      >
        <Modal.Header closeButton>
          {" "}
          <h3>Terms And Conditions</h3>
        </Modal.Header>

        <Modal.Body className="modal-body">
          <div className="terms_condition_wrapper">
            <div className="terms_conditions_inner_wrapper">
              {!isEmpty(termsAndConditions) ? (
                !isEmpty(termsAndConditions.termsAndConditionsData) ? (
                  <p
                    dangerouslySetInnerHTML={{
                      __html:
                        termsAndConditions.termsAndConditionsData.terms.terms,
                    }}
                  ></p>
                ) : null
              ) : (
                "NO Data Available"
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.authToken,
    tokenType: state.auth.tokenType,
    userId: state.auth.user.user_id,
    userPreference: state.userPreference,
    userData: state.auth.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => {
      dispatch(actions.startLoading());
    },

    finishLoading: () => {
      dispatch(actions.finishLoading());
    },

    setUser: (payload) => {
      dispatch(authActions.setUser(payload));
    },

    setRefferalCode: (payload) => {
      dispatch(setRefferalCode(payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentComponent);
