import { React, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import images from "../../../../../../../../utilities/images/images";
import HeaderComponent from "../../../../../../structure_components/desktop_screens/header_component/header_component";
import { useHistory } from "react-router-dom";
//mobileDevice
import MobileLeftSideBarComponent from "../../../../../../structure_components/mobile_screens/mobile_left_sideBar_component/mobile_left_sideBar_component";
import MobileHeaderComponent from "../../../../../../structure_components/mobile_screens/mobile_header_component/mobile_header_component";
import MobileRightSideBarComponent from "../../../../../../structure_components/mobile_screens/mobile_right_sideBar_component/mobile__right_sideBar_component";
//Redux
import { connect } from "react-redux";
import uiActions from "../../../../../../../../redux/ui/action";
import OtsSubjectWise from "./ots_subject_wise/OtsSubjectWise";
import OtsTopicWise from "./ots_topic_wise/OtsTopicWise";

const OtsSubjectTopicWise = (props) => {
  let {
    startLoading,
    finishLoading,
    loader,
    userData,
    userPreference,
    testPaperResultData,
  } = props;
  let history = useHistory();
  const backBtn = () => {
    history.push("/ots_test_result");
  };
  const location = useLocation();

  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const setWidth = () => {
    setWindowWidth(window.innerWidth)
  }
  useEffect(() => {
    window.addEventListener( 'resize',setWidth)

    return (() => {
      window.removeEventListener('resize',setWidth)
    })
  },[windowWidth])

  return (
    <>
      <div className="subjectTopicWiseMarksWrapper">
        {windowWidth <= 480  ? (
          <>
            <MobileLeftSideBarComponent />
            <MobileHeaderComponent
              userPreference={userPreference}
              userData={userData}
            />
            <MobileRightSideBarComponent
              history={history}
              startLoading={startLoading}
              finishLoading={finishLoading}
              loader={loader}
            />
          </>
        ) : (
          <HeaderComponent />
        )}

        <div className="subjectTopicWiseMarksInrWrapper">
          <div className="container">
            <div className="sbjtTopicWiseMarksInr">
              <div className="backBtn" onClick={backBtn}>
                <img src={images.user_activity_1} />
              </div>

              <div className="subjectTopicDetailWrapper">
                <div className="subjectTopicTabBox">
                  <ul class="nav nav-tabs" id="myTab" role="tablist">
                    <li class="nav-item" role="presentation">
                      <button
                        class="nav-link active"
                        id="subjectwise-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#subjectwise"
                        type="button"
                        role="tab"
                        aria-controls="subjectwise"
                        aria-selected="true"
                      >
                        Subject wise
                      </button>
                    </li>
                    <li class="nav-item" role="presentation">
                      <button
                        class="nav-link"
                        id="topicwise-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#topicwise"
                        type="button"
                        role="tab"
                        aria-controls="topicwise"
                        aria-selected="false"
                      >
                        Topic Wise
                      </button>
                    </li>
                  </ul>
                </div>
                <div className="subjectTopicTabContent">
                  <div class="tab-content" id="myTabContent">
                    <div
                      class="tab-pane fade show active"
                      id="subjectwise"
                      role="tabpanel"
                      aria-labelledby="subjectwise-tab"
                    >
                      <OtsSubjectWise
                        location={location}
                        testPaperResultData={testPaperResultData}
                        startLoading={startLoading}
                        finishLoading={finishLoading}
                        loader={loader}
                      />
                    </div>
                    <div
                      class="tab-pane fade"
                      id="topicwise"
                      role="tabpanel"
                      aria-labelledby="topicwise-tab"
                    >
                      <OtsTopicWise
                        location={location}
                        testPaperResultData={testPaperResultData}
                        startLoading={startLoading}
                        finishLoading={finishLoading}
                        loader={loader}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loader: state.ui.loader,
    userData: state.auth.user,
    userPreference: state.userPreference,
    testPaperResultData: state.otsTestsData.otsPaperResultData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => {
      dispatch(uiActions.startLoading());
    },
    finishLoading: () => {
      dispatch(uiActions.finishLoading());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OtsSubjectTopicWise);
