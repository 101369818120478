import { Fragment } from "react";
import "./bookmark_view_component.scss";

///////////////////Desktop
import HeaderComponent from "../../../structure_components/desktop_screens/header_component/header_component";

import BookmarkViewContentComponent from "./bookmark_view_content_component/bookmark_view_content_component";
import LoaderPopUp from "../../../../../components/loader/loader";

// Redux
import { connect } from "react-redux";

const BookmarkViewComponent = (props) => {
  let { loader, history, userData, userPreference } = props;

  const isMob = () => {
    if (window.innerWidth <= 480) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Fragment>
      <div className="bookmark_view_component_wrapper">
        <div className="bookmark_view_component_inner_wrapper">
          <div className="test_component_wrapper">
            <div className="test_component_inner_wrapper">
              <Fragment>
                <HeaderComponent history={history} />
                <BookmarkViewContentComponent
                  history={history}
                  userData={userData}
                />
              </Fragment>
            </div>
          </div>
        </div>
      </div>
      {loader && <LoaderPopUp />}
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    loader: state.ui.loader,
    userData: state.auth.user,
    userPreference: state.userPreference,
  };
};

export default connect(mapStateToProps)(BookmarkViewComponent);
