import React from "react";
import "./QInfo.scss";
const QuestionInfoSection = ({
  lang,
  testQuestionsDetails,
  currentQuestion,
}) => {
  return (
    <div className="questionInfoText">
      <h2>Test</h2>
      <div className="text_content_wrapper">
        <div className="typeLanguageWrapper">
          <span>
            Question type: {testQuestionsDetails[currentQuestion]?.answer_type}
          </span>
          <div className="language_chage_icon"></div>
        </div>
        <div className="questionBox">
          <span className="text_content">{`${
            Number(currentQuestion) + 1
          }.`}</span>
          {testQuestionsDetails.length
            ? testQuestionsDetails.map((element, index) => (
                <>
                  <p
                    className={
                      index === currentQuestion && lang ? "show" : "hide"
                    }
                    key={element.qid + "en"}
                    dangerouslySetInnerHTML={{ __html: element.q_text }}
                  ></p>
                  <p
                    className={
                      index === currentQuestion && !lang ? "show" : "hide"
                    }
                    key={element.qid + "hi"}
                    dangerouslySetInnerHTML={{ __html: element.q_text_hindi }}
                  ></p>
                </>
              ))
            : null}
        </div>
      </div>
    </div>
  );
};

export default QuestionInfoSection;
