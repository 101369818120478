import React, { useEffect, useState } from "react";
import "./Chatbox.scss";
import axios from "axios";
import {
  url_199,
  url_200,
  url_201,
} from "../../../../../custom_utilities/api_services";
import InitialScreen from "./InitialScreen";
import SecondScreen from "./SecondScreen";

const Chatbox = ({ handleStepOne,handleStepTwo }) => {
  const [messages, setMessages] = useState([]);
  const [parents, setParents] = useState([]);
  const [childListings, setChildListings] = useState([]);
  const [currentScreen, setCurrentScreen] = useState("initial");
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedParentId, setSelectedParentId] = useState(null);
  const [inputMessage, setInputMessage] = useState("");
  const [showConfirmationMessage, setShowConfirmationMessage] = useState(false);
  const [selectedChildId, setSelectedChildId] = useState(null);
  const [selectedChildOption, setSelectedChildOption] = useState("");
  const [showCustomMessageInput, setShowCustomMessageInput] = useState(false);

  const [showkidly, setKidly] = useState("true");

  useEffect(() => {
    getParentList();
  }, []);

  const getParentList = async () => {
    try {
      const response = await axios.get(url_199);

      if (response.data.status === 200) {
        const parentsData = response.data.data.parents;
        setParents(parentsData);
      }
    } catch (err) {
      console.error("Error fetching data from the API:", err);
    }
  };

  const handleOptionSelect = (option, parentId, childId, childOption) => {
    setSelectedOption(option);
    setSelectedParentId(parentId);
    setSelectedChildId(childId);
    setSelectedChildOption(childOption);
    setMessages([...messages, { text: option }]);

    setCurrentScreen("second");

    console.log("parentId", parentId, "childId", childId);
    if (parentId && childId === null) {
      console.log("iff");
      getChildList(parentId);
    }
  };

  const getChildList = async (parentId) => {
    try {
      const response = await axios.get(url_200, {
        params: {
          parentid: parentId,
        },
      });


      if (response.data.status === 200) {
        const childListingsData = response.data.data.childlisting;
        setChildListings(childListingsData);
      }
    } catch (err) {
      console.error("Error fetching child listings data from the API:", err);
    }
  };

  const handleSendMessage = () => {
    if (inputMessage.trim() !== "") {
      messageSubmit(
        selectedParentId,
        selectedChildId,
        selectedChildOption,
        inputMessage
      );
      setMessages([...messages, { text: inputMessage }]);
      setInputMessage("");
      setShowConfirmationMessage(true);
    }
  };

  const messageSubmit = async (
    selectedParentId,
    selectedChildId,
    selectedChildOption,
    inputMessage
  ) => {
    try {
      await axios.get(url_201, {
        params: {
          support_id: selectedChildId,
          name: selectedChildOption,
          description: inputMessage,
          parent_id: selectedParentId,
        },
      });

      setKidly(false);
      setShowCustomMessageInput(true);
      setShowConfirmationMessage(true);
    } catch (err) {
      console.log("Error making API request:", err);
    }
  };

  return (
    <div className="chatbox">
      {currentScreen === "initial" && (
        <InitialScreen
          parents={parents}
          handleOptionSelect={handleOptionSelect}
        />
      )}

      {currentScreen === "second" && (
        <SecondScreen
          selectedOption={selectedOption}
          selectedParentId={selectedParentId}
          messages={messages}
          setMessages={setMessages}
          childListings={childListings}
          handleOptionSelect={handleOptionSelect}

          handleStepOne={handleStepOne}
          handleStepTwo={handleStepTwo}
          setCurrentScreen={setCurrentScreen}
          showConfirmationMessage={showConfirmationMessage}
          inputMessage={inputMessage}
          setInputMessage={setInputMessage}
          handleSendMessage={handleSendMessage}
          selectedChildId={selectedChildId}
          showCustomMessageInput={showCustomMessageInput}
          showkidly={showkidly}
        />
      )}
    </div>
  );
};

export default Chatbox;
