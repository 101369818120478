import React, { Fragment, useState } from "react";
import "./AdaptiveCpsTestContent.scss";
import AdaptiveCpsTestInstructions from "./adaptiveCpsTestInstructions/AdaptiveCpsTestInstructions";
import AdaptiveCpsTestQuestions from "./adaptiveCpsTestQuestions/AdaptiveCpsTestQuestions";
import axios from "axios";
import { useLocation } from "react-router";

const AdaptiveCpsTestContent = (props) => {
  const {
    startLoading,
    finishLoading,
    AdaptiveCpsId,
    history,
    userData,
    userPreference,
    setAdaptiveCpsId,
    adaptiveCpsTestData,
    token,
    tokenType,
  } = props;
  const location = useLocation();
  const attemptCount = location?.state?.attemptCount;

  const [state, setState] = useState({
    step: 1,
    testDetails: {},
    testInstructionData: {},
    testSectionsDetails: {},
    testQuestionsDetails: [],
    checkQuestion: {},
    currentQuestion: 0,
    initialTime: 0,
    useTime: 0,
    left: 0,
    is_paid: 0,
    hours: 72,
    minutes: 0,
    seconds: 0,
  });

  const getNowTime = () => {
    let d = new Date();
    //3 days from the time created
    const date_created = new Date(userData.created_on);
    const numOfHours = 72;
    date_created.setTime(date_created.getTime() + numOfHours * 60 * 60 * 1000);
    let diffInMilliseconds = Math.abs(Math.abs(date_created - d) / 1000);

    // let diffInMilliSeconds = Math.abs(dateFuture - dateNow) / 1000;

    // calculate days
    const days = Math.floor(diffInMilliseconds / 86400);

    // calculate hours
    const hours = 24 * days + (Math.floor(diffInMilliseconds / 3600) % 24);
    diffInMilliseconds -= hours * 3600;

    // calculate minutes
    const minutes = Math.floor(diffInMilliseconds / 60) % 60;
    // diffInMilliseconds -= minutes * 60;

    return d.getTime();
  };

  const getTestQuestionsList = async (testDetails) => {
    let data = {
      cps_id: testDetails?.cps_id,
    };

    startLoading();
    try {
      const response = await axios.post(
        "https://learning.motion.ac.in/motioneducation/api/user/GetUserAdaptiveCPS",
        data
      );
      if (response.data.status === 200) {
        finishLoading();
        return {
          result: 1,
          data: { apiData: response.data },
        };
      } else {
        finishLoading();
        return {
          result: 0,
          data: { apiData: {} },
        };
      }
    } catch (error) {
      console.log(error);
      finishLoading();
      return {
        result: 0,
        data: { apiData: {} },
      };
    }
  };

  const handleRedirectBtn = () => {
    history.push("/create-adaptive-cps");
  };

  const getCalculatedTime = (initialTime) => {
    let d = new Date();
    let time = d.getTime();
    let calculatedTime = Math.round((time - initialTime) / 1000);
    return calculatedTime;
  };

  const handleSaveQuestion = async () => {
    let { currentQuestion, initialTime, testQuestionsDetails } = state;
    let crntQuestionDetail = testQuestionsDetails[currentQuestion];
    let calculatedTime = getCalculatedTime(initialTime);

    let data = {};
    if (crntQuestionDetail.answer_type === "Integer Correct") {
      data = {
        cps_id: AdaptiveCpsId,
        negative_marks: parseInt(crntQuestionDetail.negative_marks),

        option_int: crntQuestionDetail.userAnswerCollection[0]
          ? crntQuestionDetail.userAnswerCollection[0]
          : crntQuestionDetail.answer_type === "Integer Correct"
          ? crntQuestionDetail.integer_answer
          : "",

        option_id: [],

        paper_id: crntQuestionDetail.paper_id,
        partial_marks: parseInt(crntQuestionDetail.partial_marks),
        positive_marks: parseInt(crntQuestionDetail.positive_marks),
        qid: crntQuestionDetail.qid,
        subject: crntQuestionDetail.subject,
        time: calculatedTime ? calculatedTime : 0,
        topic_name: crntQuestionDetail.topic_name,
        toughness: crntQuestionDetail.toughness,

        format: "",
        section: "",
      };
    } else {
      data = {
        cps_id: AdaptiveCpsId,
        negative_marks: parseInt(crntQuestionDetail.negative_marks),
        option_id: crntQuestionDetail.userAnswerCollection.length
          ? crntQuestionDetail.userAnswerCollection
          : [],
        paper_id: crntQuestionDetail.paper_id,
        partial_marks: parseInt(crntQuestionDetail.partial_marks),
        positive_marks: parseInt(crntQuestionDetail.positive_marks),
        qid: crntQuestionDetail.qid,
        subject: crntQuestionDetail.subject,
        time: calculatedTime ? calculatedTime : 0,
        topic_name: crntQuestionDetail.topic_name,
        toughness: crntQuestionDetail.toughness,

        format: "",
        section: "",
        topic_id: crntQuestionDetail.topic_id,
      };
    }

    startLoading();
    try {
      const response = await axios.post(
        "https://learning.motion.ac.in/motioneducation/api/user/UserACPSDetails",
        data
      );
      if (response.data.status === 200) {
        finishLoading();
      }
      finishLoading();
    } catch (error) {
      console.log(error);
    }
  };

  const handlePreviousQuestionBtn = () => {
    let { currentQuestion, initialTime, testQuestionsDetails } = state;
    let calculatedTime = getCalculatedTime(initialTime);

    testQuestionsDetails[currentQuestion].totalTimeTaken = calculatedTime;
    let time = getNowTime();

    handleSaveQuestion();
    //handleCheckQuestion();
    if (currentQuestion >= 1) {
      setState((prevState) => ({
        ...prevState,
        currentQuestion: currentQuestion - 1,
        testQuestionsDetails: testQuestionsDetails,
        initialTime: time,
      }));
      window.scrollTo(0, 0);
    }
    // if (state.testQuestionsDetails[state.currentQuestion].option.filter((opt) => {
    //   opt.
    // }))
  };

  const handleNextQuestionBtn = () => {
    let { currentQuestion, initialTime, testQuestionsDetails } = state;
    let calculatedTime = getCalculatedTime(initialTime);
    testQuestionsDetails[currentQuestion].totalTimeTaken = calculatedTime;
    handleSaveQuestion();
    //newline add

    if (
      testQuestionsDetails[currentQuestion].isAttempted ||
      testQuestionsDetails[currentQuestion].option.filter(
        (m) => m.is_user_correct_ans
      ).length
    ) {
      testQuestionsDetails[currentQuestion].isReview = false;
    } else {
      testQuestionsDetails[currentQuestion].isReview = true;
    }
    let time = getNowTime();

    setState((prevState) => ({
      ...prevState,
      currentQuestion: currentQuestion + 1,
      testQuestionsDetails: testQuestionsDetails,
      initialTime: time,
    }));
    window.scrollTo(0, 0);
  };

  const handleSingleSelectAnswer = (event, optionId) => {
    let { checked } = event.target;
    let { currentQuestion, testQuestionsDetails } = state;
    if (checked) {
      testQuestionsDetails[currentQuestion].isAttempted = true;

      testQuestionsDetails[currentQuestion].userAnswerCollection[0] = optionId;

      if (
        testQuestionsDetails[currentQuestion].correctAnswerIdCollection.indexOf(
          optionId
        ) === -1
      ) {
        //if you not find the answer in backendCorrect answer array
        testQuestionsDetails[currentQuestion].wrongAnswerIdCollection[0] =
          Number(optionId);
        testQuestionsDetails[currentQuestion].correctAnswerIdCollection = [];
        testQuestionsDetails[currentQuestion].isAnswerCorrect = 0;
      } else {
        testQuestionsDetails[currentQuestion].correctAnswerIdCollection[0] =
          optionId;
        testQuestionsDetails[currentQuestion].wrongAnswerIdCollection = [];
        testQuestionsDetails[currentQuestion].isAnswerCorrect = 1;
      }
    } else {
      testQuestionsDetails[currentQuestion].isAttempted = false;
      testQuestionsDetails[currentQuestion].userAnswerCollection = [];
      testQuestionsDetails[currentQuestion].wrongAnswerIdCollection = [];
      testQuestionsDetails[currentQuestion].correctAnswerIdCollection = [];
      testQuestionsDetails[currentQuestion].isAnswerCorrect = 0;
    }
    setState((prevState) => ({ ...prevState, testQuestionsDetails }));
  };

  const handleMultiSelectAnswer = (_event, optionId) => {
    let { currentQuestion, testQuestionsDetails } = state;

    let findedIndex = testQuestionsDetails[
      currentQuestion
    ].userAnswerCollection.findIndex((element) => {
      return element === optionId;
    });

    if (findedIndex === -1) {
      testQuestionsDetails[currentQuestion].userAnswerCollection.splice(
        testQuestionsDetails[currentQuestion].userAnswerCollection.length,
        0,
        optionId
      );
    } else {
      testQuestionsDetails[currentQuestion].userAnswerCollection.splice(
        findedIndex,
        1
      );
    }

    if (testQuestionsDetails[currentQuestion].userAnswerCollection.length) {
      testQuestionsDetails[currentQuestion].isAttempted = true;
    } else {
      testQuestionsDetails[currentQuestion].isAttempted = false;
    }

    if (
      testQuestionsDetails[currentQuestion].userAnswerCollection.indexOf(
        optionId
      ) === -1
    ) {
      if (
        testQuestionsDetails[currentQuestion].wrongAnswerIdCollection.indexOf(
          optionId
        ) === -1
      ) {
        testQuestionsDetails[currentQuestion].wrongAnswerIdCollection.splice(
          testQuestionsDetails[currentQuestion].wrongAnswerIdCollection.length,
          0,
          optionId
        );
      } else {
        testQuestionsDetails[currentQuestion].wrongAnswerIdCollection.splice(
          testQuestionsDetails[currentQuestion].wrongAnswerIdCollection.indexOf(
            optionId
          ),
          1
        );
      }
    } else {
      if (
        testQuestionsDetails[currentQuestion].correctAnswerIdCollection.indexOf(
          optionId
        ) === -1
      ) {
        testQuestionsDetails[currentQuestion].correctAnswerIdCollection.splice(
          testQuestionsDetails[currentQuestion].correctAnswerIdCollection
            .length,
          0,
          optionId
        );
      } else {
        testQuestionsDetails[currentQuestion].correctAnswerIdCollection.splice(
          testQuestionsDetails[
            currentQuestion
          ].correctAnswerIdCollection.indexOf(optionId),
          1
        );
      }
    }

    if (testQuestionsDetails[currentQuestion].wrongAnswerIdCollection.length) {
      testQuestionsDetails[currentQuestion].isAnswerCorrect = false;
    }

    setState((prevState) => ({ ...prevState, testQuestionsDetails }));
  };

  const handleInputFieldChange = (event) => {
    let { value } = event.target;
    // value = value.replace(/[^0-9]/g, "")
    let { currentQuestion, testQuestionsDetails } = state;
    testQuestionsDetails[currentQuestion].integer_answer = value.trim();

    if (testQuestionsDetails[currentQuestion].integer_answer.length) {
      testQuestionsDetails[currentQuestion].isAttempted = true;
    } else {
      testQuestionsDetails[currentQuestion].isAttempted = false;
    }

    setState((prevState) => ({ ...prevState, testQuestionsDetails }));
  };

  const handleQuestionJump = (questionNumber, handlePurticularQuestion) => {
    setState((prevState) => ({
      ...prevState,
      currentQuestion: questionNumber,
    }));
    handleSaveQuestion();
    handlePurticularQuestion(questionNumber);
  };

  const submitTestApi = async (_userPreference, testDetails, _questionArr) => {
    let requestPayload = {
      paper_id: testDetails.paper_id, //  paper_id is not sending in app
      time: 84,
      cps_id: AdaptiveCpsId,
    };

    try {
      const response = await axios.post(
        "https://learning.motion.ac.in/motioneducation/api/user/UserACPSSubmit",
        requestPayload
      );
      if (response.data.status === 200) {
        return {
          result: 1,
          data: {
            message: response.data.message,
          },
        };
      } else {
        return {
          result: 0,
          data: {
            message: response.data.message,
          },
        };
      }
    } catch (error) {
      console.log(error);
      return {
        result: 0,
        data: {
          message: "Something went wrong!!!",
        },
      };
    }
  };

  const handleSubmitTest = async () => {
    let { testDetails, testQuestionsDetails } = state;

    let questionArr = [];

    testQuestionsDetails.forEach((element) => {
      if (element.answer_type.toLowerCase() === "single correct") {
        let data = {
          qid: element.qid,
          type_id: element.type_id,
          is_saved: element.is_saved,
          total_time: element.totalTimeTaken,
          is_attempted: element.isAttempted,
          is_AnswerCorrect: element.isAnswerCorrect,
          is_GessTypeAnswer: element.isAnswerGuessed,
          paragraph_answer: element.paragraph_answer,
          integer_answer: element.integer_answer,
          positive_marks: element.positive_marks,
          negative_marks: element.negative_marks,
          partial_marks: element.partial_marks,
          CorrectAnswerId: element.correctAnswerIdCollection,
          WrongeAnswerId: element.wrongAnswerIdCollection,
          answer: element.userAnswerCollection,
          toughness_id: element.toughness_id,
          subject: element.subject,
          topic_id: element.topic_id,
        };
        if (!data.type_id) {
          data.type_id = 6;
        }
        questionArr.push(data);
      } else if (element.answer_type.toLowerCase() === "multiple correct") {
        let data = {
          qid: element.qid,
          type_id: element.type_id,
          is_saved: element.is_saved,
          total_time: element.totalTimeTaken,
          is_attempted: element.isAttempted,
          is_AnswerCorrect: element.isAnswerCorrect,
          is_GessTypeAnswer: element.isAnswerGuessed,
          paragraph_answer: element.paragraph_answer,
          integer_answer: element.integer_answer,
          positive_marks: element.positive_marks,
          negative_marks: element.negative_marks,
          partial_marks: element.partial_marks,
          CorrectAnswerId: element.correctAnswerIdCollection,
          WrongeAnswerId: element.wrongAnswerIdCollection,
          answer: element.userAnswerCollection,
          toughness_id: element.toughness_id,
          subject: element.subject,
          topic_id: element.topic_id,
        };
        if (!data.type_id) {
          data.type_id = 6;
        }
        questionArr.push(data);
      } else if (element.answer_type.toLowerCase() === "integer correct") {
        let data = {
          qid: element.qid,
          type_id: element.type_id,
          is_saved: element.is_saved,
          total_time: element.totalTimeTaken,
          is_attempted: element.isAttempted,
          paragraph_answer: element.paragraph_answer,
          integer_answer: element.integer_answer,
          is_AnswerCorrect: element.isAnswerCorrect,
          is_GessTypeAnswer: element.isAnswerGuessed,
          positive_marks: element.positive_marks,
          negative_marks: element.negative_marks,
          partial_marks: element.partial_marks,
          CorrectAnswerId: element.correctAnswerIdCollection,
          WrongeAnswerId: element.wrongAnswerIdCollection,
          answer: element.userAnswerCollection,
          toughness_id: element.toughness_id,
          subject: element.subject,
          topic_id: element.topic_id,
        };
        if (!data.type_id) {
          data.type_id = 6;
        }
        questionArr.push(data);
      }
    });
    startLoading();
    let returnedResponse = await submitTestApi(
      userPreference,
      testDetails,
      questionArr
    );
    if (returnedResponse.result) {
      finishLoading();
      history.push("/adaptive_cps_result");
    } else {
      finishLoading();
      alert(returnedResponse.data.message);
    }
  };

  const handleStep2 = (two, testQuestionsDetails) => {
    setState((prevState) => ({
      ...prevState,
      step: two,
      testQuestionsDetails: testQuestionsDetails,
    }));
  };

  return (
    <Fragment>
      <div className="adaptiveCps_test_content_component_wrapper">
        <div className="test_content_component_inner_wrapper">
          {state.step === 1 && (
            <AdaptiveCpsTestInstructions
              getTestQuestionsList={getTestQuestionsList}
              handleRedirectBtn={handleRedirectBtn}
              left={state.left}
              history={history}
              userData={userData}
              getNowTime={getNowTime}
              setAdaptiveCpsId={setAdaptiveCpsId}
              startLoading={startLoading}
              finishLoading={finishLoading}
              adaptiveCpsTestData={adaptiveCpsTestData}
              tokenType={tokenType}
              token={token}
              handleStep2={handleStep2}
              attemptCount={attemptCount}
            />
          )}
          {state.step === 2 && (
            <AdaptiveCpsTestQuestions
              testQuestionsDetails={state.testQuestionsDetails}
              currentQuestion={state.currentQuestion}
              handlePreviousQuestionBtn={handlePreviousQuestionBtn}
              handleNextQuestionBtn={handleNextQuestionBtn}
              handleSaveQuestion={handleSaveQuestion}
              handleSingleSelectAnswer={handleSingleSelectAnswer}
              handleMultiSelectAnswer={handleMultiSelectAnswer}
              handleInputFieldChange={handleInputFieldChange}
              handleQuestionJump={handleQuestionJump}
              handleSubmitTest={handleSubmitTest}
              checkQuestion={state.checkQuestion}
              userId={userData.user_id}
              startLoading={startLoading}
              finishLoading={finishLoading}
            />
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default AdaptiveCpsTestContent;
