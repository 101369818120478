import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const toastifyMessage = (notificationData) => {
  const { status, message } = notificationData;

  const toastOptions = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
  };

  if (status === 200) {
    toast.success(message, toastOptions);
  } else {
    toast.error(message, toastOptions);
  }
};
