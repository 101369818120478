import React from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import {
  REFER_AND_EARN_MODALS,
  renderButton,
  setDetailsInLocalStorage,
} from "../../utils";
import { cancelRequest } from "../Api";
import "./Cancel.scss";

const Cancel = ({ show, onCloseModal, setModalType, token, tokenType }) => {
  const cancelRequestData = useSelector(
    (state) => state.ReferAndEarnSlice.cancelRequestData
  );

  const paymentDetails = useSelector(
    (state) => state.ReferAndEarnSlice.paymentDetails
  );

  const handleModify1 = () => {
    const { enc_upi, enc_bank } = paymentDetails;
    const bankDetails = {
      accountHolderName: enc_bank?.acc_name,
      ifscCode: enc_bank?.ifsc_code,
      accountNumber: enc_bank?.acc_no,
      confirmAccountNumber: enc_bank?.cacc_no,
    };

    setDetailsInLocalStorage("upiDetails", enc_upi?.upi_id);
    localStorage.setItem("bankDetails", JSON.stringify(bankDetails));

    if (cancelRequestData.pay_mode === "UPI") {
      setModalType(REFER_AND_EARN_MODALS.UPI_DETAILS);
    } else {
      setModalType(REFER_AND_EARN_MODALS.BANK_DETAILS);
    }
  };

  const handleCancel = (id) => {
    const data = {
      id,
    };
    cancelRequest(data, token, tokenType, setModalType);
  };

  return (
    <Modal show={show} onHide={onCloseModal} dialogClassName="cancel_wrapper">
      <Modal.Header closeButton></Modal.Header>

      <Modal.Body>
        <div className="cancel_container">
          <h4>Cancel/Modify the request</h4>
          <h5>
            Are you sure? You want to Cancel the Redeem request or modify it.
          </h5>

          <div className="verify_details_button">
            <div
              className="cancel btnn"
              onClick={() => handleCancel(cancelRequestData?.id)}
            >
              {renderButton("Cancel")}
            </div>

            <div
              className="modify btnn"
              onClick={() => handleModify1(setModalType)}
            >
              {renderButton("Modify")}
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default Cancel;
