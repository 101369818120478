import { Modal } from "react-bootstrap";
import { isEmpty } from "../../../../custom_utilities/custom_useful_functions";
import "./RefundModal.scss";

const RefundModal = (props) => {
  const {
    closeRefundModal,
    isRefundModalOpen,
    refund,
    renderRefundOptions,
    renderRefundButtons,
    renderUserSelectedOptions,
    renderInputAndSubmit,
  } = props;

  const { isRenderRefundOptions, refundOptions, isUserSubmittedDescript } =
    refund;

  return (
    <>
      <Modal
        show={isRefundModalOpen}
        onHide={closeRefundModal}
        dialogClassName="refund-modal"
      >
        <Modal.Header closeButton>
          <h4>Ask Refund</h4>
        </Modal.Header>

        <Modal.Body>
          <div className="motion-assistant-msg">
            <div className="msg">
              Hi! Thank you for being part of Motion. We are sorry for any
              inconvenience caused.
            </div>
            <div className="msg">
              Please choose the options below due to which you are asking for
              “Refund”.
            </div>
          </div>

          {isRenderRefundOptions ? (
            !isEmpty(refundOptions) ? (
              renderRefundOptions()
            ) : null
          ) : (
            <>
              {renderUserSelectedOptions()}

              {isUserSubmittedDescript
                ? renderRefundButtons()
                : renderInputAndSubmit()}
            </>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default RefundModal;
