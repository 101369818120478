import { Fragment } from "react";
import "./test_instructions_view.scss";

import images from "../../../../../../../utilities/images/images";
import { isEmpty } from "../../../../../../../custom_utilities/custom_useful_functions";
import { NavLink, Link } from "react-router-dom";

const TestInstructionsView = (props) => {
  let { testDetails, testSectionsDetails, startBtn, handleRedirectBtn } = props;


  // function testTime(minutes) {
  //   var h = Math.floor(minutes / 60);
  //   var m = minutes % 60;
  //   h = h < 10 ? "0" + h : h;
  //   m = m < 10 ? "0" + m : m;
  //   return h + ":" + m + " hrs";
  // }

  
  return (
    <Fragment>
      <div className="Pyq_ppr_test_intructions_view_wrapper">
        <div className="container">
          <div className="test_intructions_view_inner_wrapper">
            <div className="instructionScreenBox">
              <Link className="backBtn" to={{
                pathname: "/pyq",
                state: { tab: "pyq" }
              }}>
                <img src={images.m2_backBtn} alt="backBtn" />
              </Link>
              <div className="instruction_title">Instruction Screen</div>
              <div className="instruction_detailBox">
                <div className="examTime">
                  <div className="examTitle">{testDetails?.paper_name}</div>
                    
                  {/* <div className="examDurations">
                    Duration :
                    {!isEmpty(testDetails)
                      ? `${testTime(testDetails.duration_mins)}`
                      : null}
                  </div> */}
                </div>
                <div className="instructions">
                  <h3>Set of Instructions</h3>
                  <div className="instPoint">
                    <div className="img">
                      <img
                        src={`https://quizmaster.motion.ac.in${testDetails.instructions_image}`}
                        alt="instructions_image"
                      />
                    </div>
                    <p>This Section contains a set of questions for practice</p>
                    <p>
                      Users can go to previous question by clicking on the
                      previous button
                    </p>
                    <p>
                      Users can go to next question by clicking on the next
                      button
                    </p>
                    <h6>Best of luck</h6>
                  </div>
                </div>
                <div className="startTestBtn">
                  <button onClick={() => startBtn()}>Start Test</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default TestInstructionsView;
