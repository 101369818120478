import { Fragment } from "react";
import "./RankDoughnutChart.scss";

///////Canvas Chart//////////////////
import { CanvasJSChart } from "canvasjs-react-charts";

const RankDoughnutChart = (props) => {
  let { dataPoints } = props;
  const options = {
    animationEnabled: true,
    backgroundColor: "transparent",
    height: 200,

    data: [
      {
        type: "doughnut",

        showInLegend: false,
        //indexLabel: "{name}: {y}",
        yValueFormatString: "#,###'%'",
        dataPoints: [
          {
            name: "Topper Marks",

            y: dataPoints?.Topper_Marks,
            //   y:22,
            color: "#668DE1",
          },
          {
            name: "Your Marks",
            y: dataPoints?.User_Marks,
            //   y: 12,
            color: "#FF933A",
          },
        ],
      },
    ],
    text: "23%",
  };
  return (
    <Fragment>
      <div className="canvasDoughnutChart_wrapper">
        <div class="my_rank">
          <span>My Rank </span>
          <div>21</div>
        </div>
        <div>  
          <CanvasJSChart options={options} />
          </div>
        <div className="marksIndicator">
          <div className="mrk_indc">
            <span className="you"></span>
            Your Marks
          </div>
          <div className="mrk_indc">
            <span className="topper"></span>
            Topper Marks
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default RankDoughnutChart;
