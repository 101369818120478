import React from "react";
import { Modal } from "react-bootstrap";
import images from "../../../../../utilities/images/images";
import { REFER_AND_EARN_TYPES, renderButton } from "../../utils";
import { getReferalLists } from "../Api";
import "./Successful.scss";

const Successful = ({
  show,
  onCloseModal,
  setReferAndEarnType,
  token,
  tokenType,
  dispatch,
  setCheckedReferalList,
  startLoading,
  finishLoading,
}) => {
  return (
    <Modal
      show={show}
      onHide={onCloseModal}
      dialogClassName="successful_wrapper"
    >
      <Modal.Header closeButton></Modal.Header>

      <Modal.Body>
        <div className="successful_container">
          <img src={images.successfulIcon} alt="success-icon" />
          <h4>Your Redemption is Successful!</h4>
          <h5>
            Your amount will be credited to your bank account within 48 working
            hours.
          </h5>
          <div
            className="ok_btn"
            onClick={() => {
              onCloseModal();
              setReferAndEarnType(REFER_AND_EARN_TYPES.REFER_AND_EARN_DATA);
              setCheckedReferalList([]);
              getReferalLists(
                token,
                tokenType,
                dispatch,
                startLoading,
                finishLoading
              );
            }}
          >
            {renderButton("Ok")}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default Successful;
