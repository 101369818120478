import React from "react";
import "./CoinUsesModal.scss";
import Modals from "../Modal/CustomModal";

const CoinUsesModal = ({ show, onHide, data, ...rest }) => {
  const renderContent = (value) => {
    return <p dangerouslySetInnerHTML={{ __html: value }}></p>;
  };

  return (
    <Modals show={show} onHide={onHide} className="coinDetailsPopup" {...rest}>
      <p className="coinHeading">How to earn Motion Coins?</p>
      <p>
        You can earn Motion Coins by performing various activities on Motion
        App.
      </p>
      <ul>
        {data.map((point) => {
          return <li key={point.id}>{renderContent(point.value)}</li>;
        })}
      </ul>
      <p className="text-center mb-0">Earn While You Learn!</p>
    </Modals>
  );
};

export default CoinUsesModal;
