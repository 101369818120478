import React from "react";
import "./AdaptiveCpsBanner.scss";

const AdaptiveCpsBanner = (props) => {
  const { attemptCount, handleRedirectToStore } = props;

  return (
    <div className="ACpsBannerWrapper">
      <div className="ACpsBannerInnrLeftWrppr">
        <div className="ACpsAttemptsLeft">
          <p>Only {attemptCount ? attemptCount : 0} attempts left</p>
        </div>

        <div className="AcpsDescription">
          <p>Get the adpative CPS to improve more on week areas</p>
        </div>
      </div>
      <div className="ACpsBannerInnrRigthWrppr">
        <div className="ACpsGetNowBtn">
          <button onClick={handleRedirectToStore}>Get Now</button>
        </div>
      </div>
    </div>
  );
};

export default AdaptiveCpsBanner;
