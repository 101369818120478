import React from "react";
import QuestionWiseAnalysisContentComponent from "./questionWiseAnalysisContentComponent/QuestionWiseAnalysisContentComponent";
import { connect } from "react-redux";
import uiActions from "../../../../redux/ui/action";
import { setPlannerId } from "../../../redux/testDataSlice/TestDataSlice";

const QuestionWiseAnalysis = (props) => {
  const {
    loader,
    parentUserId,
    paperId,
    plannerTestId,
    startLoading,
    finishLoading,
  } = props;
  return (
    <div>
      <QuestionWiseAnalysisContentComponent
        loader={loader}
        plannerTestId={plannerTestId}
        startLoading={startLoading}
        finishLoading={finishLoading}
        paperId={paperId}
        parentUserId={parentUserId}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loader: state.ui.loader,
    parentUserId: state.parentrootReducer.parentAuthReducer.userId,
    paperId: state.parentrootReducer.parentTestReducer.paperId,
    plannerTestId: state.parentrootReducer.parentTestReducer.plannerTestId,
  };
};

const mapDipatchToProps = (dispatch) => {
  return {
    startLoading: () => {
      dispatch(uiActions.startLoading());
    },
    finishLoading: () => {
      dispatch(uiActions.finishLoading());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDipatchToProps
)(QuestionWiseAnalysis);
