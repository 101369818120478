import "./Faqs.scss";

const Faqs = ({ faqsList }) => {
  return (
    <div className="faqsWrppr">
      <div className="faqsContainer">
        <div className="faqsTitle">FAQ’s</div>

        {faqsList?.length ? (
          <section id="tab_accordion_section" className="tab_accordion_section">
            <div className="tab_accordion_wrapper">
              <div className="accordion customAccordion" id="customAccordion">
                {faqsList?.map((element, index) => {
                  return (
                    <div className="accordion-item custom_accordion_item">
                      <h2
                        className="accordion-header custom_accordion_headerr"
                        id={`heading${index}`}
                      >
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target={`#collapse${index}`}
                          aria-expanded="false"
                          aria-controls={`collapse${index}`}
                        >
                          {element.label}
                        </button>
                      </h2>
                      <div
                        id={`collapse${index}`}
                        className="accordion-collapse collapse custom_accordion_collapse"
                        aria-labelledby={`heading${index}`}
                        data-bs-parent="#customAccordion"
                      >
                        <div className="accordion-body custom_accordion_body">
                          {element.value}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </section>
        ) : null}
      </div>
    </div>
  );
};

export default Faqs;
