// import { useState, useEffect } from "react";
// import { Alert } from "react-bootstrap";

// function useAlertHook(initialValue) {
//   const [showAlert, setShowAlert] = useState(initialValue);

//   const handleShowAlert = () => {
//     setShowAlert(true);
//   };

//   const handleCloseAlert = () => {
//     setShowAlert(false);
//   };

//   return (
//     <Alert
//       variant="success"
//       show={showAlert}
//       onClose={() => setShowAlert(false)}
//       dismissible
//     >
//       ( `Test will available at: $
//       {changeTestDateAndTimeFormat(testData.test_date)}` );
//     </Alert>
//   );
// }

// export default useAlertHook;

import { useState } from "react";
import { Alert } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

function useAlertHook(initialValue) {
  const [showAlert, setShowAlert] = useState(initialValue);

  const handleShowAlert = () => {
    setShowAlert(true);
  };

  const handleCloseAlert = () => {
    setShowAlert(false);
  };

  return { showAlert, handleShowAlert, handleCloseAlert };
}

export const CustomAlert = ({ message, variant, showAlert }) => {
  if (showAlert) {
    return <Alert variant={variant}>{message}</Alert>;
  }
};

export default useAlertHook;
