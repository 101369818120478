// import React, { Fragment, useEffect, useMemo } from "react";

// const QuestionOptions = ({
//   currentSolutionDetails,
//   lang,
//   currentQuestion,
//   testQuestionsDetails,
// }) => {
//   const memoizedClassEditor = useMemo(
//     () => (s, u) => {
//       if (s) {
//         return "single_option_wrapper classCorrect";
//       } else if (!s && u) {
//         return "single_option_wrapper classWrong";
//       } else {
//         return "single_option_wrapper";
//       }
//     },
//     []
//   );

//   const renderOptionInitial = (index) => {
//     let alphabetArr = [
//       "A",
//       "B",
//       "C",
//       "D",
//       "E",
//       "F",
//       "G",
//       "H",
//       "I",
//       "J",
//       "K",
//       "L",
//       "M",
//       "N",
//       "O",
//       "P",
//       "Q",
//       "R",
//       "S",
//       "T",
//       "U",
//       "V",
//       "W",
//       "X",
//       "Y",
//       "Z",
//     ];

//     return alphabetArr[index];
//   };

//   const renderOptionText = (optionText) => {
//     return { __html: optionText };
//   };

//   const renderSingleSelect = (questionData, lang) => {
//     return (
//       <Fragment>
//         <div className="single_select_option_collection_wrapper">
//           {questionData?.option.length
//             ? questionData.option.map((element, index) => {
//                 return (
//                   <div key={index} className="single_select">
//                     <input
//                       type="checkbox"
//                       id={element.option_id}
//                       name={`current_question_${currentQuestion}`}
//                       disabled
//                     />
//                     <label
//                       htmlFor={element.option_id}
//                       className={memoizedClassEditor(
//                         element.is_correct,
//                         element.is_user_correct_ans
//                       )}
//                     >
//                       <div className="option_initial">
//                         <p className="text_content_2">{`${renderOptionInitial(
//                           index
//                         )}.`}</p>
//                       </div>
//                       <div className="option_final">
//                         <p
//                           className="text_content_3"
//                           dangerouslySetInnerHTML={renderOptionText(
//                             element.option
//                           )}
//                         ></p>
//                       </div>
//                     </label>
//                   </div>
//                 );
//               })
//             : null}
//         </div>
//       </Fragment>
//     );
//   };

//   const renderMultiSelect = (questionData, lang) => {
//     return (
//       <Fragment>
//         <div className="multi_select_option_collection_wrapper">
//           {questionData?.option?.length
//             ? questionData.option?.map((element, index) => {
//                 return (
//                   <div key={index} className="single_select">
//                     <input
//                       type="checkbox"
//                       id={element.option_id}
//                       name={`current_question_${currentQuestion}`}
//                       disabled
//                     />
//                     <label
//                       htmlFor={element.option_id}
//                       className={memoizedClassEditor(
//                         element.is_correct,
//                         element.is_user_correct_ans
//                       )}
//                     >
//                       <div className="option_initial">
//                         <p className="text_content_2">{`${renderOptionInitial(
//                           index
//                         )}.`}</p>
//                       </div>
//                       <div className="option_final">
//                         <p
//                           className="text_content_3"
//                           dangerouslySetInnerHTML={renderOptionText(
//                             element.option
//                           )}
//                         ></p>
//                       </div>
//                     </label>
//                   </div>
//                 );
//               })
//             : null}
//         </div>
//       </Fragment>
//     );
//   };

//   const renderInputField = () => {
//     if (testQuestionsDetails?.data?.[currentQuestion]) {
//       return (
//         <Fragment>
//           <div className="input_wrapper">
//             <div className="input_inner_wrapper">
//               <input
//                 key={currentQuestion}
//                 type="number"
//                 placeholder="Enter your answer"
//                 value={
//                   testQuestionsDetails.data[currentQuestion]?.integer_answer
//                 }
//                 //onChange={(event) => handleInputFieldChange(event)}
//                 disabled
//               />
//             </div>
//           </div>
//         </Fragment>
//       );
//     } else {
//       return null; // Or return a loading indicator or some fallback content if needed
//     }
//   };

//   const memoizedRenderOptions = useMemo(
//     () => (currentSolutionDetails, lang) => {
//       if (currentSolutionDetails) {
//         let step,
//           type = currentSolutionDetails.answer_type;

//         if (type.toLowerCase() === "single correct") {
//           step = 1;
//         } else if (type.toLowerCase() === "integer correct") {
//           step = 2;
//         } else {
//           step = 3;
//         }

//         switch (step) {
//           case 1:
//             return renderSingleSelect(currentSolutionDetails, currentQuestion);
//           case 2:
//             return renderInputField(currentSolutionDetails, currentQuestion);
//           case 3:
//             return renderMultiSelect(currentSolutionDetails, currentQuestion);
//           default:
//             return null;
//         }
//       } else {
//         return null;
//       }
//     },
//     []
//   );

//   return (
//     <div className="sub_sub_section_wrapper_3">
//       {currentSolutionDetails !== null &&
//       currentSolutionDetails.option !== undefined
//         ? memoizedRenderOptions(currentSolutionDetails, lang)
//         : ""}
//     </div>
//   );
// };

// export default QuestionOptions;

import React, { Fragment, useEffect, useMemo } from "react";

const QuestionOptions = React.memo(
  ({ currentSolutionDetails, lang, currentQuestion, testQuestionsDetails }) => {
    const memoizedClassEditor = useMemo(
      () => (s, u) => {
        if (s) {
          return "single_option_wrapper classCorrect";
        } else if (!s && u) {
          return "single_option_wrapper classWrong";
        } else {
          return "single_option_wrapper";
        }
      },
      []
    );

    const renderOptionInitial = (index) => {
      let alphabetArr = [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
      ];

      return alphabetArr[index];
    };

    const renderOptionText = (optionText) => {
      return { __html: optionText };
    };

    const renderSingleSelect = (questionData, lang) => {
      return (
        <Fragment>
          <div className="single_select_option_collection_wrapper">
            {questionData?.option.length
              ? questionData.option.map((element, index) => {
                  return (
                    <div key={index} className="single_select">
                      <input
                        type="checkbox"
                        id={element.option_id}
                        name={`current_question_${currentQuestion}`}
                        disabled
                      />
                      <label
                        htmlFor={element.option_id}
                        className={memoizedClassEditor(
                          element.is_correct,
                          element.is_user_correct_ans
                        )}
                      >
                        <div className="option_initial">
                          <p className="text_content_2">{`${renderOptionInitial(
                            index
                          )}.`}</p>
                        </div>
                        <div className="option_final">
                          <p
                            className="text_content_3"
                            dangerouslySetInnerHTML={renderOptionText(
                              element.option
                            )}
                          ></p>
                        </div>
                      </label>
                    </div>
                  );
                })
              : null}
          </div>
        </Fragment>
      );
    };

    const renderMultiSelect = (questionData, lang) => {
      return (
        <Fragment>
          <div className="multi_select_option_collection_wrapper">
            {questionData?.option?.length
              ? questionData.option?.map((element, index) => {
                  return (
                    <div key={index} className="single_select">
                      <input
                        type="checkbox"
                        id={element.option_id}
                        name={`current_question_${currentQuestion}`}
                        disabled
                      />
                      <label
                        htmlFor={element.option_id}
                        className={memoizedClassEditor(
                          element.is_correct,
                          element.is_user_correct_ans
                        )}
                      >
                        <div className="option_initial">
                          <p className="text_content_2">{`${renderOptionInitial(
                            index
                          )}.`}</p>
                        </div>
                        <div className="option_final">
                          <p
                            className="text_content_3"
                            dangerouslySetInnerHTML={renderOptionText(
                              element.option
                            )}
                          ></p>
                        </div>
                      </label>
                    </div>
                  );
                })
              : null}
          </div>
        </Fragment>
      );
    };

    const renderInputField = () => {
      if (testQuestionsDetails?.data?.[currentQuestion]) {
        return (
          <Fragment>
            <div className="input_wrapper">
              <div className="input_inner_wrapper">
                <input
                  key={currentQuestion}
                  type="number"
                  placeholder="Enter your answer"
                  value={
                    testQuestionsDetails.data[currentQuestion]?.integer_answer
                  }
                  //onChange={(event) => handleInputFieldChange(event)}
                  disabled
                />
              </div>
            </div>
          </Fragment>
        );
      } else {
        return null; // Or return a loading indicator or some fallback content if needed
      }
    };

    const memoizedRenderOptions = useMemo(
      () => (currentSolutionDetails, lang) => {
        if (currentSolutionDetails) {
          let step,
            type = currentSolutionDetails.answer_type;

          if (type.toLowerCase() === "single correct") {
            step = 1;
          } else if (type.toLowerCase() === "integer correct") {
            step = 2;
          } else {
            step = 3;
          }

          switch (step) {
            case 1:
              return renderSingleSelect(
                currentSolutionDetails,
                currentQuestion
              );
            case 2:
              return renderInputField(currentSolutionDetails, currentQuestion);
            case 3:
              return renderMultiSelect(currentSolutionDetails, currentQuestion);
            default:
              return null;
          }
        } else {
          return null;
        }
      },
      [currentSolutionDetails, currentQuestion]
    );

    return (
      <div className="sub_sub_section_wrapper_3">
        {currentSolutionDetails !== null &&
        currentSolutionDetails.option !== undefined
          ? memoizedRenderOptions(currentSolutionDetails, lang)
          : ""}
      </div>
    );
  }
);

export default QuestionOptions;
