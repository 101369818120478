import { connect } from "react-redux";
import "./StudentAttendanceContent.scss";
import uiActions from "../../../../../../redux/ui/action";
import axios from "axios";
import { url_219 } from "../../../../../../custom_utilities/api_services";
import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const StudentAttendanceContent = (props) => {
    let {rollNo, startLoading, finishLoading} = props;
    const [startDate, setStartDate] = useState(new Date(new Date().getTime() - 24 * 60 * 60 * 1000));
    const [endDate, setEndDate] = useState(new Date());

    const[state, setState] = useState({
        attendanceDetails: {},
    });

    useEffect(() => {
        console.log(startDate.toLocaleDateString('en-GB'))
    },[startDate]);

    useEffect(() => {
        getAttendanceDetails();
    },[]);

    const getAttendanceDetails = async () => {
        const data = {
            method_name:"attendance_report",
            s_roll_no:`${rollNo}`,
            start_date:`${startDate.toLocaleDateString('en-US')}`,
            end_data:`${endDate.toLocaleDateString('en-US')}`,
        }
        startLoading();
        try {
            const response = await axios.post(url_219, data);
            console.log(response.data.data);
            setState((prevState) => ({
                ...prevState,
                attendanceDetails: response.data,
            }))
        } catch (error) {
          finishLoading();
          console.log(error);
        }
      };
  return (
    <div className="attendance__content_wrapper">
        <div className="grey_box">
            <h1>Attendance</h1>

            <div className="range_div">
                <div className="left">
                    <p>Data showing from</p>
                    <p>Search by specific date</p>
                </div>
                <div className="right">
                    <div className="date">
                        <p>From</p>
                        {/* <p>01 April 2023</p> */}
                        <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
                    </div>
                    <div className="date">
                        <p>To</p>
                        {/* <p>01 April 2023</p> */}
                        <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} />
                    </div>
                    <button onClick={() => getAttendanceDetails()}>Continue</button>
                </div>
            </div>

            <h2>Overall attendance record</h2>
            
            <div className="record_cards_wrapper">
                <div className="record_card">
                    <p>Total Classes</p>
                    <p>{state.attendanceDetails?.total_working_days}</p>
                </div>
                <div className="record_card">
                    <p>Absent</p>
                    <p className="red">{state.attendanceDetails?.total_absent_days}</p>
                </div>
                <div className="record_card">
                    <p>Present</p>
                    <p className="green">{state.attendanceDetails?.toal_present_days}</p>
                </div>
                <div className="record_card">
                    <p>Mis punch</p>
                    <p className="yellow">{state.attendanceDetails?.total_miss_punch}</p> 
                </div>
            </div>
            <h2>Attendance details</h2>
            <div className="details_div">
                <div className="heading">
                    <span>Date</span>
                    <span>In-Time</span>
                    <span>Out-Time</span>
                    <span>Status</span>
                </div>
                {state.attendanceDetails.data?.map((element) => {
                    return (
                        <div className="details">
                            <span>{element.datetime}</span>
                            <span>{element.in_punch_time}</span>
                            <span>{element.out_punch_time}</span>
                            <span>{element.presance_status}</span>
                        </div>
                    )
                })}
            </div>
        </div>
    </div>
  )
}

const mapStateToProps = (state) => {
    return {
      rollNo: state.auth.user.roll_no,
    };
  };

  const mapDispatchToProps = (dispatch) => {
    return {
      startLoading: () => {
        dispatch(uiActions.startLoading());
      },
      finishLoading: () => {
        dispatch(uiActions.finishLoading());
      },
    };
  };
export default connect(mapStateToProps, mapDispatchToProps)(StudentAttendanceContent);