import { Fragment } from "react";
import "./header_right.scss";

import { Link } from "react-router-dom";

import images from "../../../../../../utilities/images/images";
import {
  isEmpty,
  sliceString,
} from "../../../../../../custom_utilities/custom_useful_functions";

//Redux
import { connect } from "react-redux";
import uiActions from "../../../../../../redux/ui/action";
import authActions from "../../../../../../redux/auth/action";
import userPreferenceActions from "../../../../../../redux/user_preference/action";
import userActivityActions from "../../../../../../redux/user_activity/action";
import userTestsExercisesAction from "../../../../../../redux/user_tests_exercises/action";
import userTwilioDetailsAction from "../../../../../../redux/twilio_chat/action";
import userBookmarkAction from "../../../../../../redux/user_bookmark/action";

const HeaderRight = (props) => {
  let {
    history,
    userData,
    logoutUser,
    removeAnimation,
    removePreferenceData,
    removeActivityData,
    removeTestsExercisesData,
    removeTwilioDetails,
    removeBookmarkData,
  } = props;

  const handleUserLogout = () => {
    logoutUser();
    removeAnimation();
    removePreferenceData();
    removeActivityData();
    removeTestsExercisesData();
    removeTwilioDetails();
    removeBookmarkData();
    history.push("/");
  };

  return (
    <Fragment>
      <div className="headerRight_wrapper">
        <div className="headerRight_inner_wrapper">
          <div className="userName_and_image_wrapper">
            <div className="userName_and_image_inner_wrapper">
              <div className="content_wrapper">
                <div className="name_number_container">
                  <div className="text_content_wrapper_1">
                    <p className="text_content_1">
                      {!isEmpty(userData) ? userData.name : "User"}
                    </p>
                  </div>
                  <div className="text_content_wrapper_2">
                    <p className="text_content_2">
                      {!isEmpty(userData) ? userData.mobile : "987654"}
                    </p>
                  </div>
                  <div className="text_content_wrapper_3">
                    <p className="text_content_3">
                      {userData.is_subscribe === 1 ? (
                        <Fragment>
                          <img src={images.user_activity_12} /> Premium
                        </Fragment>
                      ) : null}
                    </p>
                  </div>
                </div>
                <div className="dropdown image_container">
                  <button
                    className="btn dropdown-toggle custom_dropdown_toggle"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <span className="image_wrapper">
                      <span className="image_inner_wrapper">
                        {!isEmpty(userData) ? (
                          userData.image.length ? (
                            <img src={userData.image} />
                          ) : (
                            <img src={images.dummy_user_img} />
                          )
                        ) : (
                          <img src={images.dummy_user_img} />
                        )}
                      </span>
                    </span>
                  </button>
                  <div
                    className="dropdown-menu custom_dropdown_menu"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <div className="profile_option_wrapper">
                      <div className="profile_option_inner_wrapper">
                        <div className="content_wrapper_1">
                          <div className="image_container">
                            {!isEmpty(userData) ? (
                              userData.image.length ? (
                                <img src={userData.image} />
                              ) : (
                                <img src={images.dummy_user_img} />
                              )
                            ) : (
                              <img src={images.dummy_user_img} />
                            )}
                          </div>
                          <div className="text_content_wrapper_1">
                            <p className="text_content_1">
                              {!isEmpty(userData) ? userData.name : "User"}{" "}
                            </p>
                            <p className="text_content_2">
                              {!isEmpty(userData)
                                ? userData.mobile
                                : "9876543210"}
                            </p>
                            <p className="text_content_3">
                              {userData.is_subscribe === 1 ? (
                                <Fragment>
                                  <img src={images.user_activity_12} /> Premium
                                </Fragment>
                              ) : null}
                            </p>
                          </div>
                          <div className="text_content_wrapper_2">
                            <p
                              className="text_content_1"
                              onClick={() => handleUserLogout()}
                            >
                              <img src={images.header_2} /> Logout
                            </p>
                          </div>
                        </div>
                        <div className="content_wrapper_2">
                          <div className="text_content_wrapper_1">
                            <Link to="/user_profile">
                              <p className="text_content_1">
                                <img src={images.header_5} /> Profile
                              </p>
                            </Link>
                            <Link to="/notifications">
                              <p className="text_content_1">
                                <img src={images.header_3} /> Notifications
                              </p>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    logoutUser: () => {
      dispatch(authActions.logoutUser());
    },
    removeAnimation: () => {
      dispatch(uiActions.removeAnimation());
    },
    removeActivityData: () => {
      dispatch(userActivityActions.removeActivityData());
    },
    removePreferenceData: () => {
      dispatch(userPreferenceActions.removePreferenceData());
    },
    removeTestsExercisesData: () => {
      dispatch(userTestsExercisesAction.removeTestsExercisesData());
    },
    removeTwilioDetails: () => {
      dispatch(userTwilioDetailsAction.removeTwilioDetails());
    },
    removeBookmarkData: () => {
      dispatch(userBookmarkAction.removeBookmarkData());
    },
  };
};

export default connect(null, mapDispatchToProps)(HeaderRight);
